export const state = {
  durationId: null,
  scopes: null,
};

export const getters = {
  getDurationId: (state) => state.durationId,
  getScopes: (state) => state.scopes,
};

export const mutations = {
  setDurationId(state, data) {
    state.durationId = data;
  },
  updateScopes(state, data) {
    state.scopes = data;
  },
};

export const actions = {
  selectDuration({ commit }, data) {
    commit('setDurationId', data);
  },
  setScopes({ commit }, data) {
    commit('updateScopes', data);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
