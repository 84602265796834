const dataScopeLayer = {
  scope1: {
    pattern1: {
      method: 'طرق الوقود'
    },
    pattern2: {
      method: 'طريقة طن كيلومتر',
    },
    pattern3: {
      method: 'طريقة استهلاك الوقود',
    },
  },
  scope3: {
    category4: {
      pattern2: {
        method: '荷主責任報告値を用いる方法',
        sub_method: ''
      },
      pattern4: {
        method: '輸送',
        sub_method: '燃料法・配送料金で算定'
      },
      pattern5: {
        method: '輸送',
        sub_method: 'tkm法で算定'
      },
      pattern6: {
        method: '輸送',
        sub_method: '燃費法で算定'
      },
      pattern7: {
        method: '拠点',
        sub_method: '燃料'
      },
      pattern8: {
        method: '拠点',
        sub_method: '電気'
      }
    },
    category6: {
      pattern1: {
        method: '旅客人キロから算定'
      },
      pattern2: {
        method: '燃料法で算定',
      },
      pattern3: {
        method: '燃費法で算定',
      },
      pattern4: {
        method: '支給額から算定',
      },
      pattern5: {
        method: '宿泊数から算定',
      },
      pattern6: {
        method: '出張日数から算定',
      },
      pattern7: {
        method: '従業員数から算定',
      }
    },
    category7: {
      pattern1: {
        method: '旅客人キロから算定'
      },
      pattern2: {
        method: '燃料法で算定'
      },
      pattern3: {
        method: '燃費法で算定'
      },
      pattern4: {
        method: '支給額から算定'
      },
      pattern5: {
        method: '従業員数・営業日数から算定'
      },
    },
    category9: {
      pattern1: {
        method: '輸送',
        sub_method: '燃料法・配送料金で算定'
      },
      pattern2: {
        method: '輸送',
        sub_method: 'tkm法で算定'
      },
      pattern3: {
        method: '輸送',
        sub_method: '燃費法で算定'
      },
      pattern4: {
        method: '拠点',
        sub_method: '燃料'
      },
      pattern5: {
        method: '拠点',
        sub_method: '電気'
      },
    },
    category11: {
      pattern1: {
        method: '直接使用段階の排出量から算定',
        sub_method: 'エネルギー使用製品'
      },
      pattern2: {
        method: '直接使用段階の排出量から算定',
        sub_method: '燃料・フィードストック'
      },
      pattern3: {
        method: '直接使用段階の排出量から算定',
        sub_method: 'GHG含有製品で、使用時にGHGを排出するもの'
      },
      pattern4: {
        method: '間接使用段階の排出量から算定',
      },
    },
    category15: {
      pattern1: {
        method: '株式投資'
      },
      pattern2: {
        method: 'プロジェクトファイナンスによる融資',
      },
      pattern3: {
        method: '債券投資',
      },
      pattern4: {
        method: '管理投資他',
      },
    }
  }
}

export const getDataLayer = (scope, category, pattern_id) => {
  let dataLayer = {};
  scope = parseInt(scope)
  category = parseInt(category)
  if(scope === 1) {
    dataLayer = dataScopeLayer['scope' + scope]?.['pattern' + pattern_id]
  } else {
    dataLayer = dataScopeLayer['scope' + scope]?.['category' + category]?.['pattern' + pattern_id]
  }

  return dataLayer
}

export const getScopeName = (scope) => {
  if(scope <= 3) {
    return 'نطاق ' + scope
  } else {
    return 'その他'
  }
}

export const getCategoryName = (scope, category) => {
  if(scope <= 3) {
    if(category === 16) {
      return 'その他';
    }
    if(scope <= 2) {
      return ''
    }
    return 'فئة ' + category;
  } else {
    return '';
  }
}

export const handleResizeGrid = (theGrid, listColumn, listWidth, callBack) => {
  let paddingCell = 14;
  listColumn.forEach((column, index) => {
    let width = listWidth[index];

    if(width < column.minWidth) {
      width = column.minWidth;
      if(column.dataMap) {
        width = column.minWidth;
      }
    } else if (width > column.maxWidth) {
      width = column.maxWidth;
    }
    callBack(theGrid, index,  width + paddingCell);
  })
}