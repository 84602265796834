import api from '../api/approval';


const state = {
    listApprovers: [],
};

const actions = {
    async getApproversList({ commit }) {
        commit("aproversList", await api.getApproversListFromApi());
    },
};
const mutations = {
    aproversList: (state, data) => {
        state.listApprovers = data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
