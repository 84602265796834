<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="title-close history-comment"
    >
      <v-card>
        <v-card-title>
          <span>تعليق</span>
          <img class="icon-close" @click="close" :src="getIcon"  @mouseover="changeTooltipImage($event)" @mouseleave="changeTooltipImage($event, 'mouseleave')" />
        </v-card-title>
        <v-card-text>
            <v-list-item
              v-for="(item, index) in contents"
              :key="index"
            >
              <v-list-item-content>
                <div class="item-content-header">
                  <div class="item-content-header-right">{{ item.date }}</div>
                  <div class="item-content-header-left">{{ item.name }}</div>
                </div>
                <v-list-item-subtitle v-text="item.content"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isHover: true
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    getIcon() {
      if(this.isHover) {
        return require(`@/assets/images/dialog/close.svg`);
      } else {
        return require(`@/assets/images/dialog/close_active.svg`);
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        this.isHover = true;
      } else {
        this.isHover = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
