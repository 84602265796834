<template>
  <div>
    <v-dialog
      v-model="getDialog"
      fullscreen
      ref="dialog"
      :persistent="true"
      content-class="modal-fullscreen width-100pc"
      @click:outside="handleClickOutSite"
    >
      <div class="content-main">
        <div class="head-category-popup">
          <span class="log-confirm_text mr-auto">データのエクスポート</span>
          <div class="list-emission-close" @click="getDialog = false">
            <img
              class="head-image-dialog-popup mr-0 log-confirm--btn close"
              src="@/assets/images/dialog/close.svg"
            />
            <img
              class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
              src="@/assets/images/dialog/close_active.svg"
            />
            <div class="list-emission-close__text">閉じる</div>
          </div>
        </div>
        <div class="head-emission-category-border"></div>
        <div class="page-header">{{ getTitle }}</div> 
        <div class="export-report-popup">
          <div class="title-btn">
            <span>算定方法</span>
            <div class="title-require">必須</div>
          </div>
          <div class="selection__item">
            <export-pulldown :dataList="dataList.methods" :key="reRenderKey.methods" :selectedData.sync="dataExport.methods" :placeholder="'算定方法を選択'" :itemCount="46" :isCollapsedProp="false" :disabled="disabledLayer" class="box-shadow-pull-down"/>
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>期間</span>
            <div class="title-require">必須</div>
          </div>
          <div class="export-pull-down">
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="monthList"
                v-model="dataExport.startAt"
                item-text="text"
                item-value="text"
                label="年月を選択"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export' }"
              />
            </div>
            <div class="subtracted-divider">ー</div>
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="monthList"
                v-model="dataExport.endAt"
                item-text="text"
                item-value="text"
                label="年月を選択"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export' }"
              />
            </div>
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>絞り込み</span>
          </div>
          <div class="export-pull-down margin-pull-down">
            <export-pulldown :key="reRenderKey.organizational" :dataList="dataList.organizational" :selectedData.sync="dataExport.organizational" :itemCount="dataList.organizational.length - 1" :placeholder="'組織区分'" :arrowDownBtn="true" class="box-shadow-pull-down"/>
          </div>
          <div class="export-pull-down">
            <export-pulldown :key="reRenderKey.companies" :dataList="dataList.companies" :selectedData.sync="dataExport.companies" :itemCount="dataList.companies.length - 1" :placeholder="'法人を選択'" :hasSearch="true" class="box-shadow-pull-down"/>
          </div>
          <hr style="width: 100%; margin: 48px 0 32px;color: #4d4d4d; opacity: .4;">
          <div class="warp-submit">
            <common-button
            class="submit-btn"
            label="エクスポート"
            type="colored"
            v-ripple="false"
            :disabled="validateData()"
            @action="handlerExportData"
          />
          </div> 
        </div>
      </div>
    </v-dialog>
    <error-popup
      :dialog="errorDialog"
      :message="errorMessage"
      @submit="errorDialog = false"
    />
  </div>
</template>
<script>
import { scopeCategoriesData, GET_ALL } from '@/constants/export-report.js'
import CommonButton from '@/components/utils/button.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import ExportPulldown from '@/components/pulldown/exportReport';
import { SCOPE, CATEGORY } from '@/constants/registerData'
import { DESCRIPTIONS } from '@/constants/descriptions';
import { getExportReport } from '@/api/export-report';
import moment from "moment";
export default {
  components: { CommonButton, ErrorPopup, ExportPulldown },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    method: {
      type: Number,
      default: 1,
    },
    methodLayer2: {
      type: Number,
      default: 1,
    },
    monthYearSelectionList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      scope: 1,
      category: 1,
      workFlowData: {},
      monthList: [],
      dataExport: {
        startAt: null,
        endAt: null,
        methodExport: 1,
        companies: [],
        organizational: [],
        methods: [],
      },
      dataList: {
        organizational: [],
        companies: [],
        methods: [],
        companyBranchIds: [],
      },
      msgSelectDuration: '',
      isCollapsed: [],
      isCollapsedLayer1: [],
      showPullDown: false,
      selectList: [],
      checked: false,
      getSelectedData: [],
      getAllText: 'すべて選択',
      errorDialog: false,
      errorMessage: '',
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
      },
      disabledLayer: false,
      currentSelectMonth: '',
    };
  },
  mounted() {
    this.monthList = this.monthYearSelectionList;
    this.scope = parseInt(this.$route.params.scope);
    this.category = parseInt(this.$route.params.category);
    this.defaultData();
    this.convertData();
    this.selectList = []
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      }
    },
    getTitle() {
      if (this.scope > SCOPE.SCOPE_2) {
        if (this.category === CATEGORY.CATEGORY_16) {
          return `「Scope ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」のデータを出力します。`;
        } else if (this.scope === SCOPE.SCOPE_4) {
          return `「その他 > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」のデータを出力します。`;
        } else {
          return (
            `「Scope ${this.scope} > ${this.category}. ` +
            DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」のデータを出力します。`
          );
        }
      } else {
        return `「Scope ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」のデータを出力します。`;
      }
    },
  },
  watch:{
    'dataExport.organizational': {
      handler() {
        this.addFilterIndex('organizational');
        if (this.dataExport.organizational.length === 0) {
          this.addFilterIndex('organizational', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.companies': {
      handler() {
        this.addFilterIndex('company');
        if (this.dataExport.companies.length === 0) {
          this.addFilterIndex('company', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.startAt': {
      handler() {
        this.currentSelectMonth = 'startAt';
        this.checkDuration();
      }
    },
    'dataExport.endAt': {
      handler() {
        this.currentSelectMonth = 'endAt';
        this.checkDuration();
      }
    }
  },
  methods: {
    validateData() {
      return !(this.dataExport.methods.length && this.dataExport.startAt !== null && this.dataExport.endAt !== null);
    },
    handleClickOutSite() {
      this.getDialog = false;
    },
    checkDuration() {
      if(this.dataExport.startAt !== null && this.dataExport.endAt !== null) {
        const dateStart = this.dataExport.startAt.split('年').join('-').split('月').join('-').split('-');
        const dateEnd = this.dataExport.endAt.split('年').join('-').split('月').join('-').split('-');
        const dateStartAt = new Date(`${dateStart[0]}-${dateStart[1]}-01`);
        const dateEndAt = new Date(`${dateEnd[0]}-${dateEnd[1]}-01`);
        if (dateStartAt > dateEndAt) {
          this.errorMessage = this.currentSelectMonth === 'startAt' ? "終了年月以前の月を選択してください。" : "開始年月以降の月を選択してください。";
          this.errorDialog = true;
          return false;
        }
        return true;
      }
      return false;
    },
    defaultData() {
      this.dataList.organizational.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.$store.state.registerData.listBranch.organizational_division.filter(item => item.status === true)?.map(item => {
      this.dataList.organizational.push({
        text: item.value,
        hasCheckbox: true,
        branch_ids: item.branch_ids,
      })
    });
      this.dataList.companies.push({
        text: GET_ALL,
        hasCheckbox: true,
      });
      this.$store.state.registerData.listBranch.company_name.filter(item => item.status === true)?.map(item => {
        this.dataList.companies.push({
          text: item.value,
          hasCheckbox: true,
        })
      });
      this.$store.state.registerData.listBranch.companyBranchIds?.map(item => {
        this.dataList.companyBranchIds.push({
          organizational_division: item.organizational_division,
          company_name: item.company_name,
          branch_ids: item.branch_ids,
        })
      });
    },
    convertData(){
      let newScopeCategories = scopeCategoriesData.find(item => item.scope === this.scope && item.categories === this.category);
      this.dataList.methods = [
        {
          text: this.getAllText,
          hasCheckbox: true,
        }
      ]
      this.dataList.methods.push(newScopeCategories);
      if (!newScopeCategories?.methods) {
        this.disabledLayer = true;
        this.dataExport.methods.push(`${newScopeCategories.text}`);
      } else {
        this.dataList.methods.map(item => {
          let existItemLayer1 = item?.methods?.find(method => method.method === this.method);
          if (this.methodLayer2) {
            existItemLayer1 = item?.methods?.find(method => method.method === this.method && method.methodLayer2 === this.methodLayer2);
          }
          if (existItemLayer1) {
            this.dataExport.methods.push(`${item.text}_${existItemLayer1.text}`);
          } else {
            item?.methods?.map(itemLayer1 => {
              let existItemLayer2 = itemLayer1?.methods?.find(method => method.method === this.method)
              if (this.methodLayer2) {
                existItemLayer2 = itemLayer1?.methods?.find(method => method.method === this.method && method.methodLayer2 === this.methodLayer2)
              }
              if (existItemLayer2) {
                this.dataExport.methods.push(`${item.text}_${itemLayer1.text}_${existItemLayer2.text}`);
              }
            })
          }
        })
      }
      this.reRenderKey.methods++;
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    getMethodLayer1(index) {
      return this.dataList.methods[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.dataList.methods[index]?.methods[layer1Index]?.methods;
    },
    formatDuration(time) {
      return moment(time, 'YYYY-M').format('YYYY-MM');
    },
    async handlerExportData() {
      if (!this.checkDuration() || this.dataExport.methods.length === 0) return
      let payload = {
        contractor_id : this.$store.state.userData.contractor,
        start_at : this.formatDuration(this.dataExport.startAt),
        end_at : this.formatDuration(this.dataExport.endAt),
        organizational_division_branch_ids : [],
        company_name_branch_ids: [],
        list_row_num_pattern : [],
      }
      payload.list_row_num_pattern = this.dataExport.methods.map(item => {
        const splitItem = item.split('_');
        let data = scopeCategoriesData.find(method => method.text === splitItem[0]);
        for(let i = 1; i < splitItem.length; i++) {
          data = data?.methods.find(method => method.text === splitItem[i]);
        }
        return data?.row_num_pattern;
      })
      if (this.dataExport.companies.length === 0 && this.dataExport.organizational.length !== 0) {
        this.dataExport.organizational.map(item => {
          const branchIds = this.dataList.organizational.find(organi => organi.text === item);
          payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(branchIds?.branch_ids);
        })
      } else if (this.dataExport.organizational.length === 0 && this.dataExport.companies.length !== 0) {
        this.dataExport.companies.map(item => {
          this.dataList.companyBranchIds.filter(company => company.company_name === item)
          .map(branchId => {
            payload.company_name_branch_ids = payload.company_name_branch_ids.concat(branchId?.branch_ids);
          })
        }) 
      } else {
        if (this.filterIndex.length > 0) {
          if (this.filterIndex[0] === 'organizational') {
            let newArray = [...this.dataList.companyBranchIds];
            let newOrgari = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newOrgari.push(newArray[i].organizational_division);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newOrgari.includes(item.organizational_division)) {
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(item.branch_ids);
              }
            })
          } else {
            let newArray = [...this.dataList.companyBranchIds];
            let newCompaies = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newCompaies.push(newArray[i].company_name);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.companies.includes(newArray[i].company_name)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newCompaies.includes(item.company_name)) {
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(item.branch_ids);
              }
            })
          }
        }
      }
      let urlPayload = '?';
      const arrayKey = ['organizational_division_branch_ids', 'company_name_branch_ids', 'list_row_num_pattern'];
      for (let key in payload) {
        if (arrayKey.includes(key)) {
          if (payload[key].length) {
            if (key === 'list_row_num_pattern') {
              urlPayload += `${key}=[${payload[key]}]`;
            } else {
              urlPayload += `${key}=[${payload[key]}]&`;
            }
          }
        } else {
          urlPayload += `${key}=${payload[key]}&`;
        }
      }
      if (urlPayload.charAt(urlPayload.length - 1) === '&') { // Remove & if position is last string
        urlPayload = urlPayload.slice(0, -1);
      }
      this.getDialog = false;
      await getExportReport(urlPayload, true, payload).then(res => {
        this.getCsvExport(res, payload);
      }).catch(error => {
        console.log(error);
      });
    },
    getCsvExport(content, payload) {
      const fullDate  = moment().format("YYYYMMDD");
      let excelName = `排出量データ_${fullDate}.zip`;
      if (this.dataExport.methods.length === 1) {
        const listTextMethod = this.dataExport.methods.toString().split('_');
        let textCsv = "";
        
        let nameScope = "";
        if(payload.list_row_num_pattern[0] === 45) {
          nameScope = "Scope 3-16. その他のGHG排出量";
        } else if (payload.list_row_num_pattern[0] === 46) {
          nameScope = "Scope 4. 国内外の認証排出削減量";
        } else {
          nameScope = listTextMethod[0];
        }

        textCsv = nameScope.replace(/-|\.\s/g, (match) => {
          if (match === "-") {
            return "_";
          } else {
            return "-";
          }
        });

        if(listTextMethod.length > 2) {
            textCsv = textCsv + '_' + listTextMethod[2];
        } else if (listTextMethod.length == 2) {
            textCsv = textCsv + '_' + listTextMethod[1];
        }
        excelName = `${textCsv}_${fullDate}.csv`;
      }
      let url = window.URL.createObjectURL(new Blob([content]))
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        if (this.filterIndex[0] === type) {
          this.dataExport.companies.length = 0;
          this.dataExport.organizational.length = 0;
          this.filterIndex.length = 0;
          this.dataList.companyBranchIds.map(item => {
            this.addOrganiToList(item);
            this.addCompanyToList(item);
          })
          this.organiListSort();
          type === 'organizational' ? this.reRenderKey.companies++ : this.reRenderKey.organizational++;
        } else {
          this.filterIndex.splice(existIndex, 1);
        }
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    updateFilter() {
      if (this.filterIndex.length === 0) {
        this.dataList.companyBranchIds.map(item => {
          this.addOrganiToList(item);
          this.addCompanyToList(item);
        })
        this.organiListSort();
      } else {
        if (this.filterIndex[0] === 'organizational') {
          this.dataList.companies.length = 0;
          this.dataList.companies.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.organizational.map(organi => {
            this.dataList.companyBranchIds.filter(companyItem => companyItem.organizational_division === organi)
            .map(item => {
              this.addCompanyToList(item);
            })
          })
          const companies = this.dataList.companies.map(company => company.text);
          this.dataExport.companies.map((item, index) => {
            if (!companies.includes(item)) {
              this.dataExport.companies.splice(index, 1);
            }
          })
        } else { // this.filterIndex[0] === 'company'
          this.dataList.organizational.length = 0;
          this.dataList.organizational.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.companies.map(company => {
            this.dataList.companyBranchIds.filter(organiItem => organiItem.company_name === company)
            .map(item => {
              this.addOrganiToList(item);
            })
          })
          const organiDivisions = this.dataList.organizational.map(organi => organi.text);
          this.dataExport.organizational.map((item, index ) => {
            if (!organiDivisions.includes(item)) {
              this.dataExport.organizational.splice(index, 1);
            }
          })
          this.organiListSort();
        }
      }
    },
    addOrganiToList(item) {
      const existIndex = this.dataList.organizational.findIndex(organi => organi.text === item.organizational_division);
      if (existIndex < 0) {
        this.dataList.organizational.push({
          text: item.organizational_division,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
        })
      }
    },
    addCompanyToList(item) {
      const existIndex = this.dataList.companies.findIndex(company => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        })
      }
    },
    toggleDropdown() {
      this.showPullDown = !this.showPullDown;
    },
    organiListSort() {
      const sorter = '単体子会社関連会社その他関連会社';
      this.dataList.organizational.sort((a, b) => {
        const indexA = sorter.indexOf(a.text);
        const indexB = sorter.indexOf(b.text);

        return indexA - indexB;
      })
    },
  },
};
</script>
<style lang="scss">
.modal-fullscreen {
  background: $bgExtraLight;
  .listMenu-btn-text {
    .v-text-field.v-text-field--solo .v-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoMid;
    }
  }
}
</style>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;
  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }
  .content-main {
    .head-category-popup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 20px;

      .log-confirm_text {
        height: 32px;
        font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }
      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #F7F7F2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;
        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }
        .close-active {
          display: none;
        }
        .close {
          display: block;
        }
        &:hover {
          background: $goldLight;
          .close-active {
            display: block;
          }
          .close {
            display: none;
          }
          .list-emission-close__text  {
            color: $monoWhite;
          }
        }
        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;
          .close-active {
            display: none;
          }
          .close {
            display: block;
          }
          .list-emission-close__text  {
            color: $monoBlack;
          }
        }
      }
    }
    .head-emission-category-border {
      width: 100%;
      height: 1px;
      background: rgba(121, 134, 134, 0.12);
    }
    .page-header {
      margin-top: 48px;
      margin-bottom: 48px;
      color: $monoBlack;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      padding: 0 40px;
    }
    .export-report-popup {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      //height: 592px;
      margin: 0 auto;
      padding: 32px 0;
      background: $bgLight;
      .selection__item {
        width: 100%;
      }
      .description-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 32px;
        font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        background: $monoOffWhite;
        position: absolute;
        .selected-count {
          color: $monoMid;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .dialog-title {
    font-size: 18px;
  }
  .tooltip-none {
    font-size: 14px;
    padding: 20px 0px;
    title {
      .title-icon {
        display: none;
      }
    }
  }
  .selection__item {
    padding: 0 32px;
    .select-item .v-input__slot .v-input__append-inner {
      display: none;
    }
    .pull-down-text-color {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      transition: 0.2s ease-in-out;

      .item {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        .input-checkbox {
          margin-right: 8px;
        }
      }
    }
  }
  .title-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin: 0 32px 8px;
    .title-require {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 4px 2px;
      width: 31px;
      height: 20px;
      background: $goldMid;
      border-radius: 4px;
      color: $monoWhite;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.03em;
    }
  }
  .export-pull-down {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 32px;
    &.margin-pull-down {
      margin-bottom: 16px;
    }
    .subtracted-divider {
      color: $monoMid;
      margin: 0 16px;
    }
  }
  .err-item {
      color: $redMid;
      font-size: 13px;
      margin: 0;
    }
  .export-icon {
    width: 36px;
    margin: 24px auto;
    display: flex;
    justify-items: center;
  }
  .warp-submit{
    width: 100%;
    padding: 0 32px;
    .submit-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      background: $goldMid;
      width: 100%;
      height: 40px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      color: $monoWhite;
      &:hover {
        /* Gold/Light */
  
        background: $goldLight;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
          0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }
      &:focus-within {
        border: 2px solid $blueMid;
        padding: 6px 0px 16px;
        color: $monoWhite;
      }
    }

  }
  .strikethrough {
    position: absolute;
    left: 0;
    right: 0;
    color: #f7f7f2;
    opacity: .5;
  }
}
@include desktop {
  .modal-fullscreen {
    .content-main {
      .head-category-popup {
        padding: 16px 40px;
      }
    }
  }
}
</style>
