export const maximum128 = [
  'company_name',
  'energy_type',
  'wsu_source',
  'supplier_name',
  'service_name',
  'processing_method',
  // 'industrial_waste_item',
  'wsu_type',
  'wsu_source',
  // 'transportation_facilities',
  'lease_asset_name',
  'activity_name',
  'activity_source',
  'intermediate_product',
  'ides_name_company',
  'activity',
  'identification_number',
  'kinds',
  'db_customize_id',
  // 'ir_calculation_unit',
  'dir_date',
  'db_customize_id_1',
  'db_id',
  'unit_source1',
  'unit_source2',
];

export const maximum20 = [
  'organizational_division',
  'wsu_value',
  'wsu_unit',
  'qd_unit',
  'cpa_unit',
  'energy_unit',
  'item_unit',
  'wsu_unit',
  'travel_unit',
  'te_unit',
  'iss_unit',
  'iss_value',
  'energy_unit',
  'sales_volume_unit',
  'scale_index_unit',
  'ie_company_unit',
  'le_unit',
  'ir_owned',
  'dir_value',
  'dir_unit',
  'ir_ratio',
  'ir_investment_on_total',
  'wsu_unit_activity',
  'unit',
  'value_source1',
  'value_source2',
];

export const maximum10 = [
  // 'fuel_efficiency', // s1c1m3
];

export const maximum10WithoutRequired = [
  'loading_data_rate',
  'fuel_efficiency'
];


export const maximum20WithoutRequired = [
  'value',
  'loading_data_maximum',
  'distance',
  'qd_value',
  'cpa_value',
  'energy_value',
  'distance_value',
  'energy_saving_value',
  'scope12_overlap',
  'transport_weight_value',
  'sea_distance_value',
  'industrial_waste_value',
  'travel_value',
  'te_value',
  'employees_number_value',
  'employees_number_unit',
  'working_days_number_value',
  'working_days_number_unit',
  'sales_volume_value',
  'scale_index_value',
  'ie_company_value',
  'ie_year',
  'le_value',
  'pj_investment_ratio',
  'ir_total_investment',
  'ir_internal_investment',
  'ir_calculation_numerator',
  'ir_calculated_denominator',
  'ir_company_hold',
  'ir_release_quantity'
];

export const maximum128WithoutRequired = [
  'business_name',
  'target_model',
  'qd_source',
  'business_content',
  'cpa_source',
  'energy_source',
  'product_name',
  'destination_country_export',
  'transport_weight_source',
  'sea_distance_source',
  'item_source',
  'travel_source',
  'te_source',
  'source',
  'sales_volume_source',
  'procurement',
  'scale_index_source',
  'ides_industry',
  'ie_scope',
  'investment_destination',
  'activity',
  'industrial_waste_item'
];

export const listNumbers = [
  'industrial_waste_value',
  'te_value',
  'employees_number_value',
  'working_days_number_value',
  'loading_data_maximum',
  'loading_data_rate',
  'distance',
  'fuel_efficiency',
  'energy_value',
  'sales_volume_value',
  'scale_index_value',
  // 'dir_value',
  'qd_value',
  'energy_saving_value',
  'scope12_overlap',
  'aoa_value',
  'value',
  'cpa_value',
  'transport_weight_value',
  'sea_distance_value',
  'distance_value',
  // 'value_source1',
  // 'value_source2',
  'ir_company_hold',
  'ir_release_quantity',
  'ie_company_value',
  'pj_investment_ratio',
  'le_value',
  'travel_value',
  'ir_total_investment',
  'ir_internal_investment',
  'ir_calculation_numerator',
  'ir_calculated_denominator',
  'emissions'
  // 'wsu_value'
];

export const BLANK_ID = 'Blank';

export const MESSAGE_NOT_NULL = 'هذا إلزامي.';
export const MESSAGE_MAX_10_CHARACTOR = '10文字以内で入力してください。';
export const MESSAGE_MAX_20_CHARACTOR = '20文字以内で入力してください。';
export const MESSAGE_MAX_128_CHARACTOR = '128文字以内で入力してください。';
export const MESSAGE_IS_NUMBER = '数字で入力してください。';
export const MESSAGE_POSITIVE_NUMBER = '正の値で入力してください。';
export const MESSAGE_INCORRECT_DATE = '日付形式（YYYY/M/D）で入力してください。';
export const MESSAGE_INCORRECT_YEAR = '日付形式（YYYY）で入力してください。';

export const FILTER_DATA = {
  ORGANIZATIONAL: 'organizational_division',
  COMPANY: 'company_name',
  BUSINESS: 'business_name',
  COUNTRY: 'country',
  LAYER3: 'layer_3',
  LAYER4: 'layer_4',
  LAYER5: 'layer_5',
  LAYER6: 'layer_6',
}

export const ORGANIZATIONAL_DATA = {
  1: 'وحدة',
  2: 'الشركات التابعة',
  3: 'شركة مرتبطة',
  4: 'الشركات التابعة الأخرى',
}

export const MESSAGE_NOT_COMPARE_DATA = '入力された組織区分/法人名/事業名の組み合わせが存在しません。'


export const energyTypes = [
  {
    key: '自動車輸送 ガソリンの使用',
    value: '自動車輸送 ガソリンの使用'
  },
  {
    key: '自動車輸送 軽油の使用',
    value: '自動車輸送 軽油の使用'
  }
]

export const sources = [
  {
    type: 'gasoline',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '環境省DB2021[2]輸送 燃費法 「ガソリン」',
    value_source: '2.32166'
  },
  {
    type: 'oil',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '環境省DB2021[2]輸送 燃費法 「軽油」',
    value_source: '2.58496333333333'
  },
]

export const APPROVAL_TYPE = {
  APPROVE: 1,
  REJECT: 2,
}

export const APPROVAL_STATUS = {
  APPROVER_RETURN: 2,
  RETURN : 3,
  APPROVER_APPROVAL : 4,
  APPROVAL: 5,
}

export const ACTION_HEADER_TABLE = {
  SAVE_TEMPRORA: 'save-temprora',
  SAVE_TEMPLATE: 'save-template',
  DELETE_DATA: 'delete-data',
  SUBMIT_DATA: 'submit',
  OVERRIDE_TEMPLATE: 'override template',
  CANCEL_SUBMITTED: 'cancelSubmit',
  CLONE_TEMPLATE: 'clone template',
  REVIEWING_DATA: 'reviewing data',
  ACCEPT_CANCEL_SUBMITTED: 'approveCancel',
  UPDATE_APPROVED_DATA: 'update approved data',
};

export const SCOPE = {
  SCOPE_1: 1,
  SCOPE_2: 2,
  SCOPE_3: 3,
  SCOPE_4: 4,
};

export const CATEGORY = {
  CATEGORY_1: 1,
  CATEGORY_2: 2,
  CATEGORY_3: 3,
  CATEGORY_4: 4,
  CATEGORY_5: 5,
  CATEGORY_6: 6,
  CATEGORY_7: 7,
  CATEGORY_15: 15,
  CATEGORY_16: 16,
}

export const SCOPE_1 = 1;
export const SCOPE_2 = 2;
export const SCOPE_3 = 3;
export const SCOPE_4 = 3;
export const CATEGORY_1 = 1;
export const CATEGORY_2 = 2;
export const CATEGORY_3 = 3;
export const CATEGORY_4 = 4;
export const CATEGORY_5 = 5;
export const CATEGORY_6 = 6;
export const CATEGORY_7 = 7;
export const CATEGORY_15 = 15;
export const CATEGORY_16 = 16;
export const METHOD_3 = 3;

export const CANCEL_TYPE = {
  ACCEPT: 1,
  REJECT: 0
}

export const REGISTER_PULLDOWN = {
  CREATE_NEW: 'أدخل يدويا',
  CREATE_FROM_TEMPLATE: 'التسجيل من النموذج',
  CREATE_FROM_CSV: 'استيراد ملف CSV',
}

export const LIST_PULLDOWN = [
  {
    text: 'أدخل يدويا',
    icon: 'csv-input/manually.svg'
  },
  {
    text: 'التسجيل من النموذج',
    icon: 'csv-input/template.svg'
  },
  {
    text: 'CSV استيراد ملف',
    icon: 'csv-input/csv-import.svg'
  }
]
