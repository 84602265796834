import api from '../api/newRegisterData';

const state = {
    apiResponseObject: {},
    isLoadingSubmitting: false,
    dataPattern: {},
    isApproved: false,
    workFollowDataId: null,
    methodLayer1Id: null,
    methodLayer2Id: null,
    patternId: null,
};

const getters = {
    isLoadingSubmitting: state => state.isLoadingSubmitting,
    apiResponseObject: state => state.apiResponseObject,
    dataPattern: state => state.dataPattern,
};

const actions = {
    async addRegisterDataWorkFollow({ commit }, payload) {
        try {
            commit("setLoading", true);
            commit("setApiResponse", await api.addRegisterDataWorkFollowApi(payload));
            commit("setLoading", false);
        } catch (error) {
            commit("setLoading", false);
            commit("setApiResponse", error);
        }
    },

    async getListDataPattern({ commit }, workFollowDataId) {
        // commit("setListDataPattern", await api.getListDataPatternApi(workFollowDataId));
        return await api.getListDataPatternApi(workFollowDataId);
    },
    setListDataPatternInit({ commit }, workFollowData) {
        commit("setListDataPattern", workFollowData);
    },
    async updateRegisterDataWorkFollow({commit}, payload) {
      try {
        commit("setLoading", true);
        commit("setApiResponse", await api.updateRegisterDataWorkFollowApi(payload));
        commit("setLoading", false);
    } catch (error) {
        commit("setLoading", false);
        commit("setApiResponse", error);
    }
    },
    updateIsApproved({commit}, value) {
      commit('setIsApproved', value);
    },
    updateWorkFlowDataId({commit}, value) {
      commit('setWorkFollowDataId', value);
    },
    updateMethodLayer1Id({commit}, value) {
      commit('updateMethodLayer1Id', value);
    },
    updateMethodLayer2Id({commit}, value) {
      commit('updateMethodLayer2Id', value);
    },
    updatePatternId({commit}, value) {
      commit('updatePatternId', value);
    }
};
const mutations = {
  setLoading(state, isLoading) {
      state.isLoadingSubmitting = isLoading;
  },
  setApiResponse(state, response) {
      state.apiResponseObject = response;
  },
  setListDataPattern(state, response) {
      state.dataPattern = response;
  },
  setIsApproved(state, value) {
    state.isApproved = value;
  },
  setWorkFollowDataId(state, value) {
    state.workFollowDataId = value;
  },
  updateMethodLayer1Id(state, value) {
    state.methodLayer1Id = value;
  },
  updateMethodLayer2Id(state, value) {
    state.methodLayer2Id = value;
  },
  updatePatternId(state, value) {
    state.patternId = value;
  }
}

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions,
};
