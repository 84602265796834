export const ROUTES = {
  DASHBOARD                 : "/",
  AUTH                      : "/auth",
  LOGIN                     : "login",
  REGISTER                  : "register",
  RESET_PASSWORD            : "reset-password",
  FORGOT_PASSWORD           : "forgot-password",
  REFRESH_TOKEN             : "refresh-token",
  CHANGE_PASSWORD_USER      : "/change-password",
  SETTING                   : "/setting",
  USER_MANAGEMENT           : "/managements/users",
  VIEWER_MANAGEMENT         : "/managements/viewer",
  FACILITY_MANAGEMENT       : "/managements/facility",
  EMISSIONS                 : "/emissions",
  LIST_EMISSIONS            : "/emissions/list",
  CREATE_DATA_CUSTOMIZE     : "/emissions/create-data-customize",
  REGISTER_DATA             : "register/:scope/:category",
  LIST_EMISSION_SUBMITTED   : "list-submitted",
  LIST_EMISSION_TEMPLATES   : "list-templates",
  LIST_EMISSION_REGISTER    : "list-menu",
  LIST_EMISSION_TEMPORARY   : "list-temporary",
  LIST_APPROVAL             : "approval",
  APPROVAL                  : "/approval",
  VIEW_DATA                 : "view/:scope/:category",
  HOME_DASHBOARD            : "/",
  CREATE_DB_CUSTOMIZE       : "create-db-customize",
  OTHERS                    : "/others",
  OFFSET_TRANSACTION        : "offset-transaction",
  PRIVACY                   : "privacy",
  TERM_OF_USER              : "term-of-use",
  CALCULATION_TERM          : "/emissions/calculation-term",
  EMISSIONS_BY_BASE_CATEGORY: "emission-by-base-category",
  EMISSIONS_BY_BASE_PERIOD  : "emission-by-base-period",
  CONTACT                   : "/contact",
  LIST_MENU_EMISSION        : "list-menu",
  CASBEE: '/emissions/casbee',
  MONITORING:'/emissions/casbee/monitoring',
  CASBEE_DETAIL: '/emissions/casbee/view/:id',
  EXPORT_REPORT: '/export-report',
};
