<template>
  <div class="padding-top-layout mr-md-40-px">
    <page-title class="padding-left-layout mb-20-px mt-header-title" title="لوحة القيادة"/>
    <div class="dashboard-home">
      <div class="dashboard-filter">
        <dashboard-filter/>
      </div>
      <div class="emission-by-period padding-left-layout">
        <emission-by-period/>
      </div>
      <div class="emission-by-category padding-left-layout">
        <emission-by-category/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.emission-by-period {
  .period-header {
    margin-top: 40px;
  }
}

.mt-header-title{
  margin-top: 76px;
}

.mb-20-px {
  margin-bottom: 20px;
}

.mr-md-40-px {
  margin-right: 0;
}

.emission-category {
  display: flex;
  justify-content: center;
}

@include desktop {
  .mt-header-title{
    margin-top: 40px;
  }
  .emission-by-period {
    .period-header {
      margin-top: 48px;
    }
  }
  .mr-md-40-px {
    margin-right: 40px;
  }
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DashboardFilter from './components/dashboard-filter/index.vue';

import EmissionByPeriod from './components/emissions-by-period/index.vue';
import EmissionByCategory from './components/emissions-by-category/index.vue';

export default {
  components: {
    PageTitle,
    DashboardFilter,
    EmissionByPeriod,
    EmissionByCategory,
  },
  data() {
    return {
      items: [
        {
          text    : 'الصفحة الرئيسية',
          disabled: true,
          href    : '/',
        },
      ],
    };
  },
  async mounted() {
    this.updateBreadCrumb(this.items);
    this.$store.dispatch('dashboard/changeQueryParams', {
      contractorId: this.contractor,
    });

    await this.$store.dispatch('dashboard/requestEmissionByPeriod');
    await this.$store.dispatch('dashboard/requestEmissionByCategory');
  },
  computed: {
    ...mapGetters({
      contractor: 'userData/getContractor',
    }),
    isShowEmissionCategoryMobile() {
      return this.windowWidth < 1024;
    },
  },
  methods : {
    ...mapActions('commonApp', ['updateBreadCrumb']),
  },
};
</script>
