<template>
  <div class="export-page padding-top-layout padding-right-layout list-menu">
    <div class="list-menu-title">
      <page-title title="レポート出力" />
    </div>
    <p class="list-menu--title">
      出力したいデータ種類を選択してください。
    </p>
    <div class="listMenus-btn">
      <div class="title-btn">
        <span>出力形式</span>
        <div class="title-require">必須</div>
      </div>
      <div class="listMenu-btn mb-0">
        <div class="listMenu-btn-text">
          <v-select
            solo
            flat
            :items="methodsExport"
            v-model="dataExport.methodExport"
            label="選択してください"
            class="select-type list-menu--select select-item methods-export"
            :menu-props="{ contentClass: 'select-menu' }"
          />
        </div>
      </div>
      <div v-if="dataExport.methodExport === 2" class="title-btn mt-6">
        <span>スコープ / カテゴリ / 算定方法</span>
        <div class="title-require">必須</div>
      </div>
      <div v-if="dataExport.methodExport === 2" class="filter listMenu-btn filter-methods mb-0">
        <export-pulldown :key="reRenderKey.methods" :dataList="dataList.methods" :selectedData.sync="dataExport.methods" :itemCount="46" :placeholder="'スコープ/カテゴリ/算定方法を選択'" :showFullText="true" />
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn">
        <span>期間</span>
        <div class="title-require">必須</div>
      </div>
      <div class="listMenu-btn">
        <div class="listMenu-btn-text option-duration">
          <v-select
            solo
            flat
            :items="monthList"
            v-model="dataExport.startAt"
            item-text="text"
            item-value="text"
            label="年月を選択"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu' }"
          />
          <div style="width: 15px;">ー</div>
          <v-select
            solo
            flat
            :items="monthList"
            v-model="dataExport.endAt"
            item-text="text"
            item-value="text"
            label="年月を選択"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu' }"
          />
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn">
        <span>絞り込み</span>
      </div>
      <div class="filter listMenu-btn filter-organization">
        <export-pulldown :key="reRenderKey.organizational" :dataList="dataList.organizational" :selectedData.sync="dataExport.organizational" :itemCount="dataList.organizational.length - 1" :placeholder="'組織区分'" :arrowDownBtn="true" />
      </div>
      <div class="filter filter-methods mt-4">
        <export-pulldown :key="reRenderKey.companies" :dataList="dataList.companies" :selectedData.sync="dataExport.companies" :itemCount="dataList.companies.length - 1" :placeholder="'法人を選択'" :hasSearch="true" />
      </div>
      <div v-if="dataExport.methodExport === 1" class="filter filter-methods mt-4">
        <export-pulldown :key="reRenderKey.methods" :dataList="dataList.methods" :selectedData.sync="dataExport.methods" :itemCount="46" :placeholder="'スコープ/カテゴリ/算定方法を選択'" :showFullText="true" />
      </div>
    </div>
    <div class="list-menu-registration">
      <common-button
        class="submit-register-btn"
        label="データを出力"
        :disabled="validateData()"
        type="colored"
        @action="exportData()"
        v-ripple="false"
      />
    </div>
    <div class="return__button-responsive">
      <div class="registration-responsive">
        <common-button
          class="submit-register-btn"
          label="データを出力"
          :disabled="validateData()"
          type="colored"
          @action="exportData()"
          v-ripple="false"
        />
      </div>
    </div>
    <error-popup
      :dialog="errorDialog"
      :message="errorMessage"
      @submit="handleClose"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { ROUTES } from "@/router/constants";
import PageTitle from "@/components/pageTitle/pageTitle";
import CommonButton from "@/components/utils/button.vue";
import { getDurations } from "@/api/duration";
import { scopeCategoriesData, GET_ALL } from '@/constants/export-report';
import ExportPulldown from '@/components/pulldown/exportReport';
import { getListDetailBranch } from '@/api/registerData';
import { getExportReport } from '@/api/export-report';
import {getScopesAndCategories} from '@/api/emissionCalculations';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import moment from "moment";
export default {
  name      : "ExportReportPage",
  components: {
    PageTitle,
    CommonButton,
    ExportPulldown,
    ErrorPopup
  },
  data() {
    return {
      breadcrumb             : [
        {
          text    : "ホーム",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "レポート出力",
          disabled: true,
          href    : ROUTES.EXPORT_REPORT
        }
      ],
      monthList           : [],
      errorDialog                : false,
      dataExport: {
        startAt: null,
        endAt: null,
        methodExport: 1,
        organizational: [],
        companies: [],
        methods: [],
      },
      methodsExport: [
        { value: 1, text: '排出量レポートを出力' },
        { value: 2, text: 'スコープ/カテゴリ毎の登録済みデータ一覧を出力' },
      ],
      monthList: [],
      dataList: {
        organizational: [],
        companies: [],
        methods: scopeCategoriesData,
        companyBranchIds: [],
      },
      selectedDuration: null,
      selectedYearDuration: null,
      errorMessage: "",
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
      },
      currentSelectMonth: '',
    };
  },
  async mounted() {
    this.updateBreadCrumb(this.breadcrumb);
    await this.getDuration();
    await this.getScopeStatus();
    await getListDetailBranch();
    this.defaultData();
  },
  watch: {
    'dataExport.organizational': {
      handler() {
        if (this.dataList.organizational.length <= 1) return
        this.addFilterIndex('organizational');
        if (this.dataExport.organizational.length === 0) {
          this.addFilterIndex('organizational', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.companies': {
      handler() {
        if (this.dataList.companies.length <= 1) return
        this.addFilterIndex('company');
        if (this.dataExport.companies.length === 0) {
          this.addFilterIndex('company', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.methodExport': {
      handler() {
        this.dataExport.methods.length = 0;
        this.reRenderKey.methods++;
        this.reRenderKey.organizational++;
        this.reRenderKey.organizational++;
        this.reRenderKey.companies++;
        this.reRenderKey.companies++;
      },
      deep: true
    },
    'dataExport.startAt': {
      handler() {
        this.currentSelectMonth = 'startAt';
        this.checkDuration();
      }
    },
    'dataExport.endAt': {
      handler() {
        this.currentSelectMonth = 'endAt';
        this.checkDuration();
      }
    }
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    defaultData() {
      this.dataList.organizational.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.$store.state.registerData.listBranch.organizational_division.filter(item => item.status === true)?.map(item => {
        this.dataList.organizational.push({
          text: item.value,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
        })
      });
      this.dataList.companies.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.$store.state.registerData.listBranch.company_name.filter(item => item.status === true)?.map(item => {
        this.dataList.companies.push({
          text: item.value,
          hasCheckbox: true,
        })
      });
      this.$store.state.registerData.listBranch.companyBranchIds?.map(item => {
        this.dataList.companyBranchIds.push({
          organizational_division: item.organizational_division,
          company_name: item.company_name,
          branch_ids: item.branch_ids,
        })
      });
    },
    gotoListRegistered(){
      return this.$router.push({
        path: `${ROUTES.EMISSIONS}`
      });
    },
    formatDuration(time) {
      return moment(time, 'YYYY-M').format('YYYY-MM');
    },
    async exportData() {
      if (!this.checkDuration()) return;
      let payload = {
        contractor_id : this.$store.state.userData.contractor,
        start_at : this.formatDuration(this.dataExport.startAt),
        end_at : this.formatDuration(this.dataExport.endAt),
        organizational_division_branch_ids : [],
        company_name_branch_ids: [],
        list_row_num_pattern : [],
      }
      payload.list_row_num_pattern = this.dataExport.methods.map(item => {
        const splitItem = item.split('_');
        let data = scopeCategoriesData.find(method => method.text === splitItem[0]);
        for(let i = 1; i < splitItem.length; i++) {
          data = data?.methods.find(method => method.text === splitItem[i]);
        }
        return data?.row_num_pattern;
      })
      if (this.dataExport.companies.length === 0 && this.dataExport.organizational.length !== 0) {
        this.dataExport.organizational.map(item => {
          const branchIds = this.dataList.organizational.find(organi => organi.text === item);
          payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(branchIds?.branch_ids);
        })
      } else if (this.dataExport.organizational.length === 0 && this.dataExport.companies.length !== 0) {
        this.dataExport.companies.map(item => {
          this.dataList.companyBranchIds.filter(company => company.company_name === item)
          .map(branchId => {
            payload.company_name_branch_ids = payload.company_name_branch_ids.concat(branchId?.branch_ids);
          })
        }) 
      } else {
        if (this.filterIndex.length > 0) {
          if (this.filterIndex[0] === 'organizational') {
            let newArray = [...this.dataList.companyBranchIds];
            let newOrgari = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newOrgari.push(newArray[i].organizational_division);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newOrgari.includes(item.organizational_division)) {
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(item.branch_ids);
              }
            })
          } else {
            let newArray = [...this.dataList.companyBranchIds];
            let newCompaies = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newCompaies.push(newArray[i].company_name);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.companies.includes(newArray[i].company_name)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newCompaies.includes(item.company_name)) {
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(item.branch_ids);
              }
            })
          }
        }
      }
      let urlPayload = '?';
      const arrayKey = ['organizational_division_branch_ids', 'company_name_branch_ids', 'list_row_num_pattern'];
      for (let key in payload) {
        if (arrayKey.includes(key)) {
          if (payload[key].length) {
            if (key === 'list_row_num_pattern') {
              urlPayload += `${key}=[${payload[key]}]`;
            } else {
              urlPayload += `${key}=[${payload[key]}]&`;
            }
          }
        } else {
          urlPayload += `${key}=${payload[key]}&`;
        }
      }
      if (urlPayload.charAt(urlPayload.length - 1) === '&') { // Remove & if position is last string
        urlPayload = urlPayload.slice(0, -1);
      }
      const isCsv = this.dataExport.methodExport === 1 ? false : true;
      await getExportReport(urlPayload, isCsv, payload).then(res => {
        this.getCsvExport(res, isCsv, payload);
      }).catch(error => {
        console.log(error);
      });
    },
    getCsvExport(content, isCsv, payload) {
      let fullDate  = moment().format("YYYYMMDDHHmmss");
      let excelName = `CARBONIX_GHG Report-${this.selectedYearDuration}_${fullDate}.xlsx`;
      let url  = window.URL.createObjectURL(
        new Blob([content], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      if (isCsv) {
        fullDate  = moment().format("YYYYMMDD");
        if (this.dataExport.methods.length === 1) {
          const listTextMethod = this.dataExport.methods.toString().split('_');
          let textCsv = "";
          
          let nameScope = "";
          if(payload.list_row_num_pattern[0] === 45) {
            nameScope = "Scope 3-16. その他のGHG排出量";
          } else if (payload.list_row_num_pattern[0] === 46) {
            nameScope = "Scope 4. 国内外の認証排出削減量";
          } else {
            nameScope = listTextMethod[0];
          }

          textCsv = nameScope.replace(/-|\.\s/g, (match) => {
            if (match === "-") {
              return "_";
            } else {
              return "-";
            }
          });

          if(listTextMethod.length > 2) {
              textCsv = textCsv + '_' + listTextMethod[2];
          } else if (listTextMethod.length == 2) {
              textCsv = textCsv + '_' + listTextMethod[1];
          }
          excelName = `${textCsv}_${fullDate}.csv`;
        } else {
          excelName = `排出量データ_${fullDate}.zip`;
        }
        url = window.URL.createObjectURL(new Blob([content]))
      }
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    async getScopeStatus() {
      await getScopesAndCategories({duration_id: this.selectedDuration})
        .then((response) => {
          let isEnableScope3 = response.find((object) => {
            return object.hasOwnProperty('display_scope_3')
          })
          this.dataList.methods = isEnableScope3.display_scope_3 === true ? scopeCategoriesData : scopeCategoriesData.filter(method => method.scope !== 3);
          this.reRenderKey.methods++;
        })
        .catch(() => {
        });
    },
    async getDuration() {
      await getDurations().then((res) => {
        const selectedDuration = res.data.find((item) => item.is_selected === true);
        if (selectedDuration) {
          this.selectedDuration = selectedDuration.id;
          this.selectedYearDuration = selectedDuration.year;
          this.getMonthList(selectedDuration.start_at, selectedDuration.end_at);
        }
      });
    },
    openScopePopup() {
      this.errorDialog = true;
    },
    handleClose() {
      this.errorDialog = false;
    },
    validateData() {
      if (this.dataExport.startAt === null || this.dataExport.endAt === null) {
        return true
      }
      if (this.dataExport.methodExport === 2 && this.dataExport.methods.length === 0) {
        return true
      }
      return false
    },
    getMonthList(startAt, endAt) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat = durationStartAt.toLocaleString('ja-JP', { month: 'long', year: 'numeric' });
      this.monthList = [
        {
          text: timeFormat,
          value: durationStartAt.getMonth() + 1,
        },
      ];

      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = durationStartAt.toLocaleString('ja-JP', { month: 'long', year: 'numeric' });
        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1,
        };
        this.monthList.push(currentMonthYear);
      }
    },
    checkDuration() {
      if(this.dataExport.startAt !== null && this.dataExport.endAt !== null) {
        const dateStart = this.dataExport.startAt.split('年').join('-').split('月').join('-').split('-');
        const dateEnd = this.dataExport.endAt.split('年').join('-').split('月').join('-').split('-');
        const dateStartAt = new Date(`${dateStart[0]}-${dateStart[1]}-01`);
        const dateEndAt = new Date(`${dateEnd[0]}-${dateEnd[1]}-01`);

        if (dateStartAt > dateEndAt) {
          this.errorMessage = this.currentSelectMonth === 'startAt' ? "終了年月以前の月を選択してください。" : "開始年月以降の月を選択してください。";
          this.openScopePopup();
          return false;
        }
        return true;
      }
      return false;
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        if (this.filterIndex[0] === type) {
          this.dataExport.companies.length = 0;
          this.dataExport.organizational.length = 0;
          this.filterIndex.length = 0;
          this.dataList.companyBranchIds.map(item => {
            this.addOrganiToList(item);
            this.addCompanyToList(item);
          })
          this.organiListSort();
          type === 'organizational' ? this.reRenderKey.companies++ : this.reRenderKey.organizational++;
        } else {
          this.filterIndex.splice(existIndex, 1);
        }
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    updateFilter() {
      if (this.filterIndex.length === 0) {
        this.dataList.companyBranchIds.map(item => {
          this.addOrganiToList(item);
          this.addCompanyToList(item);
        })
        this.organiListSort();
      } else {
        if (this.filterIndex[0] === 'organizational') {
          this.dataList.companies.length = 0;
          this.dataList.companies.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.organizational.map(organi => {
            this.dataList.companyBranchIds.filter(companyItem => companyItem.organizational_division === organi)
            .map(item => {
              this.addCompanyToList(item);
            })
          })
          const companies = this.dataList.companies.map(company => company.text);
          this.dataExport.companies.map((item, index) => {
            if (!companies.includes(item)) {
              this.dataExport.companies.splice(index, 1);
            }
          })
        } else { // this.filterIndex[0] === 'company'
          this.dataList.organizational.length = 0;
          this.dataList.organizational.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.companies.map(company => {
            this.dataList.companyBranchIds.filter(organiItem => organiItem.company_name === company)
            .map(item => {
              this.addOrganiToList(item);
            })
          })
          const organiDivisions = this.dataList.organizational.map(organi => organi.text);
          this.dataExport.organizational.map((item, index ) => {
            if (!organiDivisions.includes(item)) {
              this.dataExport.organizational.splice(index, 1);
            }
          })
          this.organiListSort();
        }
      }
    },
    addOrganiToList(item) {
      const existIndex = this.dataList.organizational.findIndex(organi => organi.text === item.organizational_division);
      if (existIndex < 0) {
        this.dataList.organizational.push({
          text: item.organizational_division,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
        })
      }
    },
    addCompanyToList(item) {
      const existIndex = this.dataList.companies.findIndex(company => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        })
      }
    },
    organiListSort() {
      const sorter = '単体子会社関連会社その他関連会社';
      this.dataList.organizational.sort((a, b) => {
        const indexA = sorter.indexOf(a.text);
        const indexB = sorter.indexOf(b.text);

        return indexA - indexB;
      })
    },
  }
};
</script>
<style lang="scss">
.select-type .v-input__slot {
  background: #f7f7f2 !important;
}
.list-menu {
  .select-method {
    &.disabled {
      .v-input__slot {
      background: rgba(121, 134, 134, 0.12) !important;
      input {
        background: unset !important;
      }
    }
    }
  }
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%
}
.listMenu-scope.has-scope  .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope .v-btn__content div{
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
</style>
<style lang="scss" scoped>
.custom-table {
  padding-left: 20px !important;
}
.export-page {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;
    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 15px 20px;
    /* Background/Light */
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px 0px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      flex-direction: column;
      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 10px;
      width: 100%;
      height: max-content !important;
      &.has-scope {
        color: $monoBlack;
      }
      &:hover {
        color: $monoWhite;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: 31px;
        height: 20px;
        margin-top: 8px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
    .filter {
      max-width: 100%;
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }
  .title-btn-select-scope {
    margin-bottom: 12px;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    //padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}
.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;
      .return-active {
        display: none;
      }
    }
  &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
  &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn  {
    width: 100%;
  }
}

.filter-organization {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
.option-duration {
  display: flex;
  flex-flow: row !important;
  justify-content: space-between;
  gap: 16px;
}

@include desktop {
  .return__button-responsive {
    display: none;
  }
  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }
  .export-page {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px 32px;

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .submit-register-btn {
      // background: #948810 !important;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 48px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }
      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }

    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }

      }
    }
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}
</style>
