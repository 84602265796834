<template>
  <div>
    <wj-flex-pie
      class="chart-wrapper"
      header=""
      bindingName="brand"
      binding="sales"
      :itemsSource="scopeData"
      :palette="[scope1Color, scope2Color, scope3Color]"
      :startAngle="90"
      :tooltipContent="tooltipContent"
    >
      <wj-flex-pie-data-label :content="getLabelContent"></wj-flex-pie-data-label>
      <wj-flex-chart-legend :position="'None'"></wj-flex-chart-legend>
    </wj-flex-pie>
  </div>
</template>

<style lang="scss" scoped>
.chart-wrapper {
  &.wj-flexchart {
    border: 0;
    width: 124px;
    height: 124px;

    .wj-data-label {
      fill: #fff;
    }
  }
}
</style>

<style lang="scss">
.chart-wrapper {
  &.wj-flexchart {
    .wj-data-label {
      fill: #fff;
      font-size: 10px;
      line-height: 14px;
    }
  }
}
</style>
<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import { SCOPE_1_COLOR, SCOPE_2_COLOR, SCOPE_3_COLOR } from '../../../chart-color';

const getData = () => {
  return [
    { brand: 'Scope 1', sales: 0 },
    { brand: 'Scope 2', sales: 0 },
    { brand: 'Scope 3', sales: 0 },
  ];
};

export default {
  props: {
    scopeData: {
      type: Array,
      default: getData,
    },
  },
  computed: {
    scope1Color() {
      return this.scopeData[0]?.sales < 0 ? '#404D50' : SCOPE_1_COLOR;
    },
    scope2Color() {
      return this.scopeData[1]?.sales < 0 ? '#99A6A9' : SCOPE_2_COLOR;
    },
    scope3Color() {
      return this.scopeData[2]?.sales < 0 ? '#7C898C' : SCOPE_3_COLOR;
    }
  },
  methods: {
    getLabelContent(ht) {
      return `${ht.value}`;
    },
    tooltipContent(hti) {
      let item = hti.item;
      return `<b>${item.brand}</b><br>${item.sales}`;
    }
  },
};
</script>
