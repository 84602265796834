<template>
  <v-app :id="$vuetify.breakpoint.width < 1024 ? 'inspire-mobile' : ''">
    <div class="wrap">
      <router-view />
      <footer class="wrap__footer">
        <div class="copyright">
          <p>©︎2022 Sustech Inc.</p>
          <div v-if="$vuetify.breakpoint.width < 1024" class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
        </div>
        <div class="wrap__footer--right">
          <a href="https://www.sustech-inc.com/" target="_blank">اتصل بنا</a>
          <a :href="link.termOfUse" target="_blank">شروط الاستخدام</a>
          <a :href="link.privacy" target="_blank">خصوصية</a>
          <div v-if="$vuetify.breakpoint.width >= 1024" class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
        </div>
      </footer>
    </div>
  </v-app>
</template>

<script>
import webStorage from '@/utils/webStorage';
import { ROUTES } from '@/router/constants';
export default {
  mounted() {
    if (["/auth/", "/auth"].includes(this.$route.fullPath)) {
      if (webStorage.getToken()) {
        return this.$router.push({ path: '/' });
      }
      return this.$router.push({path: "/auth/login"});
    }
  },
  data() {
    return {
      link: {
        termOfUse: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
        privacy: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
      },
    };
  },
  methods: {
    toSustech() {
      window.open("https://www.sustech-inc.com");
    },
    handleToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    linkToTrusty() {
      window.open("https://www.truste.or.jp/hssl/validate/01632.php");
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/auth/LoginStyle/index.scss';
.wrap__footer {
  .wrap__footer--right {
    a {
      color: #7c898c;
      background: #132d39;
      cursor: pointer;
      text-decoration: none;
      padding: 0 8px;
      &:hover {
        color: $monoLight;
        background: $bgDark;
      }
    }
  }
  .copyright {
    justify-content: space-between;
    .just-logo{
      height: 35px;
      padding-right: 5px;
      .truste-logo {
        height: 100%;
      }
    }
  }
}
.just-logo{
  cursor: pointer;
}
@include desktop {
  .wrap__footer {
    .wrap__footer--right {
      margin-right: unset;
      margin-left: unset;
    }
  }
}
</style>
