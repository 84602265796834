import request from './base/request';
import store from '@/store';

async function getApproversListFromApi() {
  const contractorId = store.state.userData.contractor;
  return request
    .get(`/?contractor_id=${contractorId}&is_approve=1`)
    .then((resonse) => {
      const listApproval = resonse.data?.map((approvalItem) => {
        return {
          id: approvalItem.id,
          name: approvalItem.name,
        };
      });
      return listApproval;
    })
    .catch((error) => {
      throw error;
    });
}

export default {
  getApproversListFromApi,
};
