export const CASBEE = {
  CASBEE: 'CASBEE',
  HOME: '戸建（新築）',
  ARCHITECTURE: '建築（新築）',
}

export const HOME = {
  CATEGORY_1: 'カテゴリ1',
  CATEGORY_11_13: 'カテゴリ11&13', 
}

export const ARCHITECTURE = {
  CATEGORY_1: 'カテゴリ1',
  CATEGORY_11_13: 'カテゴリ11&13',
}

export const INPUT_TYPE = {
  CHOOSE_CONDITION: '条件選択式',
  ENTER_FREELY: '1次エネルギー消費量自由入力',
}

export const PURPOSE_USING = {
  APARTMENT: '集合住宅',
  OUTSIDE: '集合住宅以外',
}

export const DBCUSTOMIZE_TYPE = {
  CASBEE: 0,
}

export const CASBEE_TYPE = {
  HOME: 1,
  HOME_CHOOSE_CONDITION: 2,
  HOME_ENTER_FREELY: 3,
  ARCHITECTURE: 4,
  ARCHITECTURE_APARTMENT_CHOOSE_CONDITION: 5,
  ARCHITECTURE_APARTMENT_ENTER_FREELY: 6,
  ARCHITECTURE_OUTSIDE_CHOOSE_CONDITION: 7,
  ARCHITECTURE_OUTSIDE_ENTER_FREELY: 8,
}