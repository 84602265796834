<template>
  <div class="chart-axis-y-wrapper">
    <div class="items">
      <span v-for="(item, index) in axisItems" :key="index" class="item "
        :class="{ unit: index === 6 }"
        :style="{left: `${item.percent}%`}"
      >
        {{ item.text }}
      </span>
    </div>

    <div class="line"></div>
  </div>
</template>

<style lang="scss" scoped>
.chart-axis-y-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 52px;
  justify-content: space-between;

  .items {
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    position: relative;
    margin-left: 8px;

    .item {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0.03em;
      color: #948810;
      word-break: break-all;
      position: absolute;
      height: 18px;

      &.unit {
        color: #99a6a9;
        min-width: 35px;
        left: 0 !important;
        right: 0;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(42, 42, 48, 0.1);
    // margin-left: 3px;
  }
}

@include desktop {
  .chart-axis-y-wrapper {
    .items {
      margin-left: unset;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getAxisYByCategory: 'dashboard/getAxisYByCategory',
    }),
    axisItems() {
      return this.getAxisYByCategory.values;
    },
    max() {
      return this.getAxisYByCategory.max;
    },
  },
};
</script>
