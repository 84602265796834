<template>
  <div class="setting-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title title="設定" />
    <div class="settings-btn">
      <router-link class="setting-btn" v-for="(item, index) in items" :key="index" :to="item.path">
        <div class="setting-btn-text">
          <img :src="getSettingIcon(item.image)" alt="" />
          <div class="setting-text">{{ item.text }}</div>
        </div>
        <div class="arrow-svg"><img src="@/assets/images/setting/arrow.svg" alt="" /></div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import {ROLE} from '@/constants/role';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
  name: 'Setting',
  components: {
    PageTitle,
  },
  data() {
    return {
      breadcrum: [
        {
          text: 'ホーム',
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: '設定',
          disabled: true,
          href: ROUTES.SETTING,
        },
      ],
      isHidden: false,
      items: [
        { text: 'ユーザー管理', path: ROUTES.USER_MANAGEMENT, image: 'user.svg' },
        { text: '拠点管理', path: ROUTES.FACILITY_MANAGEMENT, image: 'facility.svg' },
        { text: '閲覧権限管理', path: ROUTES.VIEWER_MANAGEMENT, image: 'viewer.svg' },
        { text: '登録期間設定', path: ROUTES.CALCULATION_TERM, image: 'calculation.svg' },
        {
          text: 'カスタマイズDB管理',
          path: ROUTES.CREATE_DATA_CUSTOMIZE,
          image: 'db-customize.svg',
        },
        { text: 'パスワード変更', path: ROUTES.CHANGE_PASSWORD_USER, image: 'change-pass.svg' },
      ],
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    this.updateItems();
  },
  computed: {
    ...mapGetters(['getRoles']),
    ...mapState('userData', ['currentUser']),
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    getSettingIcon(image) {
      return require(`@/assets/images/setting/${image}`);
    },
    redirectPage(url) {
      return this.$router.push({ path: url });
    },
    updateItems() {
      if (this.currentUser.user.role_id === ROLE.APPROVAL || this.currentUser.user.role_id === ROLE.USER) {
        this.items.splice(0, 4);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-page {
  .settings-btn {
    display: flex;
    flex-flow: column;
    .setting-btn {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $monoOffWhite;
      cursor: pointer;
      box-shadow: 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 8px 8px 8px 12px;
      .setting-btn-text {
        display: flex;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
        }
        div {
          padding-left: 12px;
          color: $monoBlack;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
        }
      }
      &:hover {
        background-color: $monoWhite;
      }
      &:active {
        border: 2px solid $blueMid;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }
  }
  .page-title {
    margin-bottom: 80px;
  }
}

@include desktop {
  .setting-page {
    .settings-btn {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 632px;
      margin: 0 auto;
      padding-top: 32px;
      .setting-btn {
        width: 304px;
        margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: unset;
        &:hover {
          cursor: pointer;
        }
        .arrow-svg {
          display: none;
        }
        .setting-btn-text {
          display: flex;
          flex-flow: column;
          padding: 26px 0 22px;
          img {
            margin-bottom: 16px;
          }
          .setting-text {
            padding-left: 0;
          }
        }
        &:nth-child(odd) {
          margin-left: unset;
        }
        &:nth-child(even) {
          margin-right: unset;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#adminSetting {
  height: 584px;
}
@include desktop {
  #adminSetting {
    height: unset;
  }
}
</style>
