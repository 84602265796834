<template>
  <div class="offset-transaction-page padding-left-layout padding-top-layout">
    <page-title title="オフセット取引" />
    <div class="item">
      <div class="page-header">
        <h2 class="header-text">オフセット取引とは？</h2>
        <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
      </div>
      <div class="graphic-wrapper">
        <div class="graphic-header">
          事業活動によって排出されたGHG（温室効果ガス）排出量を算出・把握・管理し、排出量の削減策を講じた後、どうしても削減が困難な排出量の全部または一部を他の場所における排出削減・吸収量で埋め合わせ（オフセット）をすることです。
        </div>
        <div class="graphic-images">
          <img src="@/assets/images/offset-transaction/graphic1.svg" alt="" />
          <img
            v-if="$vuetify.breakpoint.width < 1024"
            class="second-image"
            src="@/assets/images/offset-transaction/graphic2-mobile.svg"
            alt=""
          />
          <img v-else class="second-image" src="@/assets/images/offset-transaction/graphic2.svg" alt="" />
          <img src="@/assets/images/offset-transaction/graphic3.svg" alt="" />
        </div>
        <div class="graphic-footer">
          <div class="graphic-footer-text">1. 事業全体におけるGHG排出量を把握</div>
          <div class="graphic-footer-text">2. 省エネ活動等による排出量の削減努力</div>
          <div class="graphic-footer-text">3. 削減が困難な排出量を把握</div>
          <div class="graphic-footer-text">4. 森林保護活動を実施・他のGHG削減活動へ資金支援</div>
          <div class="graphic-footer-text">
            5. 活動や支援で得られるクレジットで削減困難な排出量と埋め合わせ（オフセット）
          </div>
        </div>
      </div>
    </div>
    <div class="item" style="padding-top: 0 !important">
      <div class="page-header" id="bottom-title" style="padding-bottom: 0">
        <h2 class="header-text">取引のご相談とサポートの流れ</h2>
        <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
      </div>
      <div class="steps-wrapper">
        <div class="step step1-wrapper">
          <div class="step-header">
            <div>
              <div class="step-image">
                <h3>STEP</h3>
                <h3>1</h3>
              </div>
              <h3 class="step-header-text">ご相談内容についてCARBONIXヘメールを送る</h3>
            </div>
            <div class="divider" />
          </div>
          <div class="step-content">
            <div class="step-content-header">
              オフセット取引を検討されている方は、下の「相談メールを送る」ボタンからEメールにて下記情報をご記入の上、CARBONIXお客様窓口までご連絡ください。
            </div>
            <div class="step-content-wrapper">
              <div class="header">必要なお客様情報</div>
              <div class="email-information">
                <div class="email-step">
                  <h4 class="step-title">1. 希望するクレジットの種類</h4>
                  <ul class="step-content">
                    <li>J-クレジット</li>
                    <li>グリーン電力証書</li>
                    <li>非化石証書</li>
                    <li>未定または不明</li>
                  </ul>
                </div>
                <div class="email-step">
                  <h4 class="step-title">2. クレジットの用途</h4>
                  <div class="step-content">
                    <div>温対法省エネ法報告、カーボンオフセットへの活用など</div>
                  </div>
                </div>
                <div class="email-step">
                  <h4 class="step-title">3. 調達希望量</h4>
                  <div class="step-content">
                    <div>※t-CO2またはkWh単位</div>
                  </div>
                </div>
                <div class="email-step">
                  <h4 class="step-title">4. 調達希望時期</h4>
                </div>
                <div class="email-step">
                  <h4 class="step-title">5. その他のご質問など</h4>
                </div>
              </div>
              <a class="submit-button" href="mailto:offset@carbonix.ai">
                <img src="@/assets/images/offset-transaction/letter.svg" alt="" />
                <div>オフセット取引の相談メールを送る</div>
              </a>
            </div>
          </div>
        </div>
        <div class="step step2-wrapper">
          <div class="step-header">
            <div>
              <div class="step-image">
                <h3>STEP</h3>
                <h3>2</h3>
              </div>
              <h3 class="step-header-text">CARBONIXからご連絡</h3>
            </div>
            <div class="divider" />
          </div>
          <div class="step-content">
            <div>お送りいただいた内容について、CARBONIX担当者から3営業日以内にご連絡いたします。</div>
            <div>ご希望のクレジット種類によっては発電種類など追加の必要情報を追加で確認させていただきます。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import { ROUTES } from '@/router/constants';
import { mapActions } from 'vuex';
export default {
  components: { PageTitle },
  data() {
    return {
      breadcrum: [
        {
          text: 'ホーム',
          disabled: false,
          href: ROUTES.DASHBOARD,
        },
        {
          text: 'オフセット取引',
          disabled: true,
          href: ROUTES.OTHERS + '/' + ROUTES.OFFSET_TRANSACTION,
        },
      ],
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
  },
};
</script>
<style lang="scss" scoped>
.offset-transaction-page {
  .page-title {
    margin-top: 40px;
  }
  .page-header {
    padding: 32px 0 48px 0;
    display: flex;
    flex-flow: column;
    img {
      width: 44px;
      height: 8px;
      margin-top: 16px;
    }
    .header-text {
      color: $monoBlack;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
    }
  }
  .graphic-wrapper {
    .graphic-header {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
    .graphic-images {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 48px 0;
      img {
        width: 286px;
        height: 141px;
        margin-bottom: 32.5px;
        &:first-child {
          width: 220px;
          height: 288px;
        }
        &:last-child {
          width: 221px;
          height: 288px;
          margin-bottom: unset;
        }
      }
    }
    .graphic-footer {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
  }
  .item {
    &:last-child {
      .page-header {
        //padding-top: 80px;
      }
      margin-bottom: 128px;
    }
  }
  .steps-wrapper {
    .step {
      .step-header {
        margin-bottom: 24px;
        .step-image {
          width: 73px;
          height: 70px;
          border: 1px solid $goldMid;
          border-radius: 8px;
          padding: 16px 20px 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $goldMid;
          h3 {
            font-family: 'Century Gothic Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.15em;
            &:last-child {
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0.1em;
              margin-top: 4px;
            }
          }
        }
        .step-header-text {
          margin-top: 20px;
          color: $monoBlack;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.1em;
        }
      }
      .step-content {
        .step-content-header {
          color: $monoBlack;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
          margin-bottom: 48px;
        }
        .step-content-wrapper {
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: $monoBlack;
            margin-bottom: 7px;
          }
          .email-information {
            display: flex;
            flex-flow: column;
            margin-bottom: 39px;
            .email-step {
              border: 1px solid $dividerBlack;
              margin: 10px 0;
              padding: 16px 20px;
              color: $goldMid;
              font-style: normal;
              line-height: 24px;
              .step-title {
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.03em;
              }
              .step-content {
                font-weight: 400;
                font-size: 13px;
                letter-spacing: 0.05em;
                margin-top: 16px;
              }
              &:first-child {
                margin-top: unset;
              }
              &:last-child {
                margin-bottom: unset;
              }
            }
          }
          a.submit-button {
            background: $goldMid;
            padding: 14px 0 18px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: $monoWhite !important;
            text-decoration: unset !important;
            &:hover {
              background: #a4a184;
              box-shadow: 0px 4px 14px rgb(160 181 186 / 17%), 0px 1.20588px 4.22059px rgb(160 181 186 / 24%),
                0px 0.500862px 1.75302px rgb(160 181 186 / 31%), 0px 0.181152px 0.634032px rgb(160 181 186 / 48%);
            }
            div {
              color: $monoWhite;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.03em;
              margin-left: 8px;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        ul {
          padding-left: 20px;
          li {
            &::marker {
              font-size: 10px;
            }
          }
        }
      }
      &.step1-wrapper {
        margin-bottom: 96px;
        margin-top: 48px;
      }
      &.step2-wrapper {
        .step-header {
          margin-bottom: 24px;
        }
        .step-content {
          color: $monoBlack;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
        }
      }
      .divider {
        border: 1px solid $dividerBlack;
        margin-top: 22px;
      }
    }
  }
  #bottom-title {
    padding-top: 80px;
  }
}
@include desktop {
  .offset-transaction-page {
    .item {
      width: 600px;
      margin: 0 auto;
      padding: unset !important;
      padding-top: 32px !important;
      .page-header {
        margin-bottom: 48px;
        padding: unset;
      }
      .graphic-images {
        padding: unset;
      }
      &:last-child {
        padding: 0 265px;
        margin-top: 80px;
        .page-header {
          padding-bottom: 48px;
        }
      }
      .graphic-wrapper {
        .graphic-images {
          flex-flow: row;
          justify-content: center;
          align-items: unset;
          padding-top: 24px;
          .second-image {
            margin: 0 52px;
            width: 200px;
            height: 192px;
          }
          img {
            margin-bottom: 24px;
          }
        }
      }
      .steps-wrapper {
        .step {
          .step-header {
            display: flex;
            flex-flow: column;
            & > div:first-child {
              display: flex;
              div {
                margin-left: 24px;
              }
            }
            .divider {
              width: 100%;
            }
          }
          .step-image {
            margin-left: unset !important;
            margin-right: 24px;
          }
        }
        .email-step {
          margin: 8px 0 !important;
          display: flex;
          .step-title,
          .step-content {
            width: 272px;
          }
          .step-content {
            margin-top: unset !important;
            margin-left: 16px;
          }
        }
      }
    }
    .page-title {
      margin-top: 0;
    }
    #bottom-title {
      padding-top: 0px;
    }
  }
}
</style>
