<template>
  <div class="filter-dashboard fix-top" id="headFix" v-if="isGotData">
    <div class="container pl-40-px list-filter" id="listFilter">
      <div class="row filter-items">
        <mix-label-pull-down-menu
          v-model="organizational_select"
          label="فئات المنظمة"
          :items="organizationalDivisions"
          :initialValue="initialValueOrganizationalDivision"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-left border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          label="اسم الشركة"
          v-model="company_select"
          :items="companies"
          :initialValue="initialValueCompanyName"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('business_name')"
          :label="listLayers[listLayersVisible.indexOf('business_name')].layer_name ? listLayers[listLayersVisible.indexOf('business_name')].layer_name : '&nbsp;'"
          v-model="business_select"
          :items="business"
          :initialValue="initialValueBusiness"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('country')"
          :label="listLayers[listLayersVisible.indexOf('country')].layer_name ? listLayers[listLayersVisible.indexOf('country')].layer_name : '&nbsp;'"
          v-model="country_select"
          :items="countries"
          :initialValue="initialValueCountry"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('layer_3')"
          :label="listLayers[listLayersVisible.indexOf('layer_3')].layer_name ? listLayers[listLayersVisible.indexOf('layer_3')].layer_name : '&nbsp;'"
          v-model="layer3_select"
          :items="listLayer3"
          :initialValue="initialValueLayer3"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('layer_4')"
          :label="listLayers[listLayersVisible.indexOf('layer_4')].layer_name ? listLayers[listLayersVisible.indexOf('layer_4')].layer_name : '&nbsp;'"
          v-model="layer4_select"
          :items="listLayer4"
          :initialValue="initialValueLayer4"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('layer_5')"
          :label="listLayers[listLayersVisible.indexOf('layer_5')].layer_name ? listLayers[listLayersVisible.indexOf('layer_5')].layer_name : '&nbsp;'"
          v-model="layer5_select"
          :items="listLayer5"
          :initialValue="initialValueLayer5"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
        <mix-label-pull-down-menu
          v-if="listLayersVisible.includes('layer_6')"
          :label="listLayers[listLayersVisible.indexOf('layer_6')].layer_name ? listLayers[listLayersVisible.indexOf('layer_6')].layer_name : '&nbsp;'"
          v-model="layer6_select"
          :items="listLayer6"
          :initialValue="initialValueLayer6"
          :isSelectFirst="true"
          placeHolderText="الجميع"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MixLabelPullDownMenu from "@/components/pulldown/MixLabelPullDownMenu";
import { getDashboardBranch } from "@/api/branch";
import { FILTER_DATA, ORGANIZATIONAL_DATA } from "@/constants/dashboard";
import { mapGetters } from "vuex";

export default {
  components: { MixLabelPullDownMenu },
  data() {
    return {
      organizational_select             : "",
      company_select                    : "",
      business_select                   : "",
      country_select                    : "",
      layer3_select                     : "",
      layer4_select                     : "",
      layer5_select                     : "",
      layer6_select                     : "",
      organizationalDivisions           : [],
      companies                         : [],
      business                          : [],
      countries                         : [],
      listLayer3                        : [],
      listLayer4                        : [],
      listLayer5                        : [],
      listLayer6                        : [],
      initialValueOrganizationalDivision: "",
      initialValueCompanyName           : "",
      initialValueBusiness              : "",
      initialValueCountry               : "",
      initialValueLayer3                : "",
      initialValueLayer4                : "",
      initialValueLayer5                : "",
      initialValueLayer6                : "",
      filterData                        : [],
      selectedFirst                     : null,
      filterIndex                       : [],
      filterTypes                       : [
        FILTER_DATA.ORGANIZATIONAL,
        FILTER_DATA.COUNTRY,
        FILTER_DATA.COMPANY,
        FILTER_DATA.BUSINESS,
        FILTER_DATA.LAYER_3,
        FILTER_DATA.LAYER_4,
        FILTER_DATA.LAYER_5,
        FILTER_DATA.LAYER_6
      ],
      flag                              : true,
      listLayersVisible                 : [],
      listLayers                        : [],
      isGotData                         : false,
      parentScrolled                    : false
    };
  },
  async mounted() {
    this.flag           = false;
    const contractor_id = this.$store.state.userData.contractor;
    await this.getDashboardBranch(contractor_id);
    const listFilter = document.getElementById("listFilter");
    let self         = this;
    let timer        = null;
    listFilter.addEventListener("scroll", function() {
      self.parentScrolled = true;
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        self.parentScrolled = false;
      }, 50);
    });
  },
  watch   : {
    organizational_select: {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("organizational_select");
        this.updateFilter("organizational_select");
        if (val === "") {
          this.addFilterIndex("organizational_select", true);
        }
      }
    },
    company_select       : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("company_select");
        this.updateFilter("company_select");
        if (val === "") {
          this.addFilterIndex("company_select", true);
        }
      }
    },
    business_select      : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("business_select");
        this.updateFilter("business_select");
        if (val === "") {
          this.addFilterIndex("business_select", true);
        }
      }
    },
    country_select       : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("country_select");
        this.updateFilter("country_select");
        if (val === "") {
          this.addFilterIndex("country_select", true);
        }
      }
    },
    layer3_select        : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("layer3_select");
        this.updateFilter("layer3_select");
        if (val === "") {
          this.addFilterIndex("layer3_select", true);
        }
      }
    },
    layer4_select        : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("layer4_select");
        this.updateFilter("layer4_select");
        if (val === "") {
          this.addFilterIndex("layer4_select", true);
        }
      }
    },
    layer5_select        : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("layer5_select");
        this.updateFilter("layer5_select");
        if (val === "") {
          this.addFilterIndex("layer5_select", true);
        }
      }
    },
    layer6_select        : {
      handler(val) {
        if (!this.flag) return;
        this.addFilterIndex("layer6_select");
        this.updateFilter("layer6_select");
        if (val === "") {
          this.addFilterIndex("layer6_select", true);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getQueryParams: "dashboard/getQueryParams"
    })
  },
  methods : {
    async getDashboardBranch(contractor_id) {
      await getDashboardBranch(contractor_id).then((response) => {
        this.prepareFilterData(response.data);
        this.isGotData         = true;
        this.listLayers        = response.layer;
        this.listLayersVisible = response.layer.map((item) => item.layer_field);
      });
    },
    async prepareFilterData(data) {
      this.resetFilter([
        FILTER_DATA.ORGANIZATIONAL,
        FILTER_DATA.COUNTRY,
        FILTER_DATA.COMPANY,
        FILTER_DATA.BUSINESS,
        FILTER_DATA.LAYER_3,
        FILTER_DATA.LAYER_4,
        FILTER_DATA.LAYER_5,
        FILTER_DATA.LAYER_6
      ]);
      let dataIdSort = [];
      let dataSort   = [];
      data.forEach((organiItems) => {
        this.addOrganizational(organiItems);
        organiItems.child?.forEach((company) => {
          company?.child.forEach((companyItem) => {
            if (companyItem?.child === undefined) {
              dataIdSort.push(companyItem.id);
              dataSort.push(companyItem);
            }
            companyItem?.child?.forEach((businessItem) => {
              if (businessItem?.child === undefined) {
                dataIdSort.push(businessItem.id);
                dataSort.push(businessItem);
              }
              businessItem?.child?.forEach((layer3Item) => {
                if (layer3Item?.child === undefined) {
                  dataIdSort.push(layer3Item.id);
                  dataSort.push(layer3Item);
                }
                layer3Item?.child?.forEach((layer4Item) => {
                  if (layer4Item?.child === undefined) {
                    dataIdSort.push(layer4Item.id);
                    dataSort.push(layer4Item);
                  }
                  layer4Item?.child?.forEach((layer5Item) => {
                    if (layer5Item?.child === undefined) {
                      dataIdSort.push(layer5Item.id);
                      dataSort.push(layer5Item);
                    }
                    layer5Item?.child?.forEach((layer6Item) => {
                      if (layer6Item?.child === undefined) {
                        dataIdSort.push(layer6Item.id);
                        dataSort.push(layer6Item);
                      }
                      layer6Item?.child?.forEach((item) => {
                        dataIdSort.push(item.id);
                        dataSort.push(item);
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
      dataIdSort
        .sort((a, b) => {
          return a - b;
        })
        .forEach((id) => {
          dataSort.forEach((item, key) => {
            if (item.id === id) {
              this.addCompany({ company: item.company_name });
              this.addBusiness({ business_name: item.business_name });
              this.addCountry({ country: item.country });
              this.addLayer3({ layer_3: item.layer_3 });
              this.addLayer4({ layer_4: item.layer_4 });
              this.addLayer5({ layer_5: item.layer_5 });
              this.addLayer6({ layer_6: item.layer_6 });
              this.filterData.push(item);
              dataSort.splice(key, 1);
            }
          });
        });
      if (this.getQueryParams.organizationalDivision) {
        const existsOrganizationalDivision = await this.organizationalDivisions.find(
          (item) => item.value === this.getQueryParams.organizationalDivision
        );
        if (existsOrganizationalDivision) {
          // this.initialValueOrganizationalDivision = existsOrganizationalDivision.value;
          this.organizational_select = existsOrganizationalDivision.value;
        }
      }
      if (this.getQueryParams.companyName) {
        const existCompany = await this.companies.find((item) => item.value === this.getQueryParams.companyName);
        if (existCompany) {
          this.company_select = existCompany.value;
          // this.initialValueCompanyName = existCompany.value;
        }
      }
      if (this.getQueryParams.branchId) {
        const existBranch = await this.business.find((item) => item.value === this.getQueryParams.branchId);
        if (existBranch) {
          this.business_select = existBranch.value;
          // this.initialValueBusiness = existBranch.value;
        }
      }
      if (this.getQueryParams.country) {
        const existCountry = await this.countries.find((item) => item.value === this.getQueryParams.country);
        if (existCountry) {
          this.country_select = existCountry.value;
          // this.initialValueCountry = existCountry.value;
        }
      }
      if (this.getQueryParams.layer_3) {
        const existLayer3 = await this.listLayer3.find((item) => item.value === this.getQueryParams.layer_3);
        if (existLayer3) {
          // this.initialValueLayer3 = existLayer3.value;
          this.layer3_select = existLayer3.value;
        }
      }
      if (this.getQueryParams.layer_4) {
        const existLayer4 = await this.listLayer4.find((item) => item.value === this.getQueryParams.layer_4);
        if (existLayer4) {
          // this.initialValueLayer4 = existLayer4.value;
          this.layer4_select = existLayer4.value;
        }
      }
      if (this.getQueryParams.layer_5) {
        const existLayer5 = await this.listLayer5.find((item) => item.value === this.getQueryParams.layer_5);
        if (existLayer5) {
          // this.initialValueLayer5 = existLayer5.value;
          this.layer5_select = existLayer5.value;
        }
      }
      if (this.getQueryParams.layer_6) {
        const existLayer6 = await this.listLayer6.find((item) => item.value === this.getQueryParams.layer_6);
        if (existLayer6) {
          // this.initialValueLayer6 = existLayer6.value;
          this.layer6_select = existLayer6.value;
        }
      }
      this.flag = true;
    },
    addOrganizational(organiItems) {
      if (this.selectedFirst === "organizational_select") return;
      // check organizational exist
      const existOrganizationalIndex = this.organizationalDivisions.findIndex((item) => {
        return item.value === organiItems.id;
      });
      if (existOrganizationalIndex < 0) {
        this.organizationalDivisions.push({
          name : organiItems.organizational_division,
          value: organiItems.id
        });
      }
    },
    addCountry(country) {
      if (this.selectedFirst === "country_select") return;

      this.countries.push({
        name : country.country || "(فارغ)",
        value: country.country || "null"
      });

      // Make country  unique and sort item empty to bottom
      this.countries = this.sortItemToBottom(this.countries);
    },
    addLayer3(layer_3) {
      if (this.selectedFirst === "layer3_select") return;

      this.listLayer3.push({
        name : layer_3.layer_3 || "(فارغ)",
        value: layer_3.layer_3 || "null"
      });

      // Make layer 3 unique and sort item empty to bottom
      this.listLayer3 = this.sortItemToBottom(this.listLayer3);

    },
    addLayer4(layer_4) {
      if (this.selectedFirst === "layer4_select") return;

      this.listLayer4.push({
        name : layer_4.layer_4 || "(فارغ)",
        value: layer_4.layer_4 || "null"
      });

      // Make layer 4 unique and sort item empty to bottom
      this.listLayer4 = this.sortItemToBottom(this.listLayer4);
    },
    addLayer5(layer_5) {
      if (this.selectedFirst === "layer5_select") return;

      this.listLayer5.push({
        name : layer_5.layer_5 || "(فارغ)",
        value: layer_5.layer_5 || "null"
      });

      // Make layer 5 unique and sort item empty to bottom
      this.listLayer5 = this.sortItemToBottom(this.listLayer5);
    },
    addLayer6(layer_6) {
      if (this.selectedFirst === "layer6_select") return;

      this.listLayer6.push({
        name : layer_6.layer_6 || "(فارغ)",
        value: layer_6.layer_6 || "null"
      });

      // Make layer 6 unique and sort item empty to bottom
      this.listLayer6 = this.sortItemToBottom(this.listLayer6);
    },

    addCompany(company) {
      if (this.selectedFirst === "company_select") return;
      // check company exist
      const existCompanyIndex = this.companies.findIndex((item) => {
        return item.value === company.company;
      });
      if (existCompanyIndex < 0) {
        this.companies.push({
          name : company.company,
          value: company.company
        });
      }
    },
    addBusiness(business) {
      if (this.selectedFirst === "business_select") return;

      this.business.push({
        name : business?.business_name || "(فارغ)",
        value: business?.business_name || "null"
      });
      // Make business unique and sort item empty to bottom
      this.business = this.sortItemToBottom(this.business);
    },

    sortItemToBottom(data) {
      return [...new Map(data.map((item) => [item["value"], item])).values()].sort((a, b) => {
        return (a.value === "null") - (b.value === "null");
      });
    },
    updateFilter(type) {
      if (
        this.organizational_select === "" &&
        this.company_select === "" &&
        this.business_select === "" &&
        this.country_select === "" &&
        this.layer3_select === "" &&
        this.layer4_select === "" &&
        this.layer5_select === "" &&
        this.layer6_select === ""
      ) {
        this.updateGetAllData();
        this.selectedFirst = null;
        return;
      }
      this.filterCondition(type);
      if (!this.selectedFirst) {
        this.selectedFirst = type;
      }
    },
    resetFilter(items, isRemove = false) {
      if (isRemove) {
        items = this.filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
      }
      let firstSelectItem = {
        name : "الجميع",
        value: ""
      };
      items.forEach((key) => {
        if (key === FILTER_DATA.ORGANIZATIONAL && this.selectedFirst !== "organizational_select") {
          this.organizationalDivisions = [];
          this.organizationalDivisions.push(firstSelectItem);
        }
        if (key === FILTER_DATA.COUNTRY && this.selectedFirst !== "country_select") {
          this.countries = [];
          this.countries.push(firstSelectItem);
        }
        if (key === FILTER_DATA.COMPANY && this.selectedFirst !== "company_select") {
          this.companies = [];
          this.companies.push(firstSelectItem);
        }
        if (key === FILTER_DATA.BUSINESS && this.selectedFirst !== "business_select") {
          this.business = [];
          this.business.push(firstSelectItem);
        }
        if (key === FILTER_DATA.LAYER_3 && this.selectedFirst !== "layer3_select") {
          this.listLayer3 = [];
          this.listLayer3.push(firstSelectItem);
        }
        if (key === FILTER_DATA.LAYER_4 && this.selectedFirst !== "layer4_select") {
          this.listLayer4 = [];
          this.listLayer4.push(firstSelectItem);
        }
        if (key === FILTER_DATA.LAYER_5 && this.selectedFirst !== "layer5_select") {
          this.listLayer5 = [];
          this.listLayer5.push(firstSelectItem);
        }
        if (key === FILTER_DATA.LAYER_6 && this.selectedFirst !== "layer6_select") {
          this.listLayer6 = [];
          this.listLayer6.push(firstSelectItem);
        }
      });
    },
    async updateGetAllData() {
      this.resetFilter([
        FILTER_DATA.ORGANIZATIONAL,
        FILTER_DATA.COUNTRY,
        FILTER_DATA.COMPANY,
        FILTER_DATA.BUSINESS,
        FILTER_DATA.LAYER_3,
        FILTER_DATA.LAYER_4,
        FILTER_DATA.LAYER_5,
        FILTER_DATA.LAYER_6
      ]);
      this.filterData.forEach((item) => {
        this.addOrganizational({
          id                     : item.organizational_division,
          organizational_division: ORGANIZATIONAL_DATA[item.organizational_division]
        });
        this.addCompany({ company: item.company_name });
        this.addCountry({ country: item.country });
        this.addBusiness({ business_name: item.business_name });
        this.addLayer3({ layer_3: item.layer_3 });
        this.addLayer4({ layer_4: item.layer_4 });
        this.addLayer5({ layer_5: item.layer_5 });
        this.addLayer6({ layer_6: item.layer_6 });
      });
      await this.updateFilterDataToStore();
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        this.filterIndex.splice(existIndex, 1);
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    filterOrganizational(item) {
      return (
        (this.organizational_select !== "" && this.organizational_select === item.organizational_division) ||
        this.organizational_select === ""
      );
    },
    filterCompanies(item) {
      return (this.company_select !== "" && this.company_select === item.company_name) || this.company_select === "";
    },
    filterBusiness(item) {
      return (
        (this.business_select !== "" && this.business_select === item.business_name) ||
        this.business_select === "" ||
        (this.business_select === "null" && !item.business_name)
      );
    },
    filterCountries(item) {
      return (
        (this.country_select !== "" && this.country_select === item.country) ||
        this.country_select === "" ||
        (this.country_select === "null" && !item.country)
      );
    },
    filterListLayer3(item) {
      return (
        (this.layer3_select !== "" && this.layer3_select === item.layer_3) ||
        this.layer3_select === "" ||
        (this.layer3_select === "null" && !item.layer_3)
      );
    },
    filterListLayer4(item) {
      return (
        (this.layer4_select !== "" && this.layer4_select === item.layer_4) ||
        this.layer4_select === "" ||
        (this.layer4_select === "null" && !item.layer_4)
      );
    },
    filterListLayer5(item) {
      return (
        (this.layer5_select !== "" && this.layer5_select === item.layer_5) ||
        this.layer5_select === "" ||
        (this.layer5_select === "null" && !item.layer_5)
      );
    },
    filterListLayer6(item) {
      return (
        (this.layer6_select !== "" && this.layer6_select === item.layer_6) ||
        this.layer6_select === "" ||
        (this.layer6_select === "null" && !item.layer_6)
      );
    },

    async filterCondition(type) {
      const selectedIndex = this.filterIndex.findIndex((item) => item === type);
      if (selectedIndex < 0) return;
      switch (selectedIndex) {
        case 0:
          this.resetFilter([type], true);
          this.filterData.forEach((item) => {
            this.filterLayer1(type, item);
          });
          break;
        case 1:
          this.resetFilter([this.filterIndex[0], type], true);
          this.filterData.forEach((item) => {
            this.filterLayer2(type, item);
          });
          break;
        case 2:
          this.resetFilter([this.filterIndex[0], this.filterIndex[1], type], true);
          this.filterData.forEach((item) => {
            this.filterLayer3(type, item);
          });
          break;
        case 3:
          this.resetFilter([this.filterIndex[0], this.filterIndex[1], this.filterIndex[2], type], true);
          this.filterData.forEach((item) => {
            this.filterLayer4(type, item);
          });
          break;
        case 4:
          this.resetFilter(
            [this.filterIndex[0], this.filterIndex[1], this.filterIndex[2], this.filterIndex[3], type],
            true
          );
          this.filterData.forEach((item) => {
            this.filterLayer5(type, item);
          });
          break;
        case 5:
          this.resetFilter(
            [
              this.filterIndex[0],
              this.filterIndex[1],
              this.filterIndex[2],
              this.filterIndex[3],
              this.filterIndex[4],
              type
            ],
            true
          );
          this.filterData.forEach((item) => {
            this.filterLayer6(type, item);
          });
          break;
        case 6:
          this.resetFilter(
            [
              this.filterIndex[0],
              this.filterIndex[1],
              this.filterIndex[2],
              this.filterIndex[3],
              this.filterIndex[4],
              this.filterIndex[5],
              type
            ],
            true
          );
          this.filterData.forEach((item) => {
            this.filterLayer7(type, item);
          });
          break;
        case 7:
          await this.updateFilterDataToStore();
          break;
      }
    },
    filterLayer1(filterItem, item) {
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop([filterItem], item);
      this.resetSelectedData([filterItem]);
    },
    filterLayer2(filterItem, item) {
      if (!this.filterDataByIndex(this.filterIndex[0], item)) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop([this.filterIndex[0], filterItem], item);
      this.resetSelectedData([this.filterIndex[0], filterItem]);
    },
    filterLayer3(filterItem, item) {
      if (!this.filterDataByIndex(this.filterIndex[0], item) || !this.filterDataByIndex(this.filterIndex[1], item)) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop([this.filterIndex[0], this.filterIndex[1], filterItem], item);
      this.resetSelectedData([this.filterIndex[0], this.filterIndex[1], filterItem]);
    },
    filterLayer4(filterItem, item) {
      if (
        !this.filterDataByIndex(this.filterIndex[0], item) ||
        !this.filterDataByIndex(this.filterIndex[1], item) ||
        !this.filterDataByIndex(this.filterIndex[2], item)
      ) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop([this.filterIndex[0], this.filterIndex[1], this.filterIndex[2], filterItem], item);
      this.resetSelectedData([this.filterIndex[0], this.filterIndex[1], this.filterIndex[2], filterItem]);
    },
    filterLayer5(filterItem, item) {
      if (
        !this.filterDataByIndex(this.filterIndex[0], item) ||
        !this.filterDataByIndex(this.filterIndex[1], item) ||
        !this.filterDataByIndex(this.filterIndex[2], item) ||
        !this.filterDataByIndex(this.filterIndex[3], item)
      ) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(
        [this.filterIndex[0], this.filterIndex[1], this.filterIndex[2], this.filterIndex[3], filterItem],
        item
      );
      this.resetSelectedData([
        this.filterIndex[0],
        this.filterIndex[1],
        this.filterIndex[2],
        this.filterIndex[3],
        filterItem
      ]);
    },
    filterLayer6(filterItem, item) {
      if (
        !this.filterDataByIndex(this.filterIndex[0], item) ||
        !this.filterDataByIndex(this.filterIndex[1], item) ||
        !this.filterDataByIndex(this.filterIndex[2], item) ||
        !this.filterDataByIndex(this.filterIndex[3], item) ||
        !this.filterDataByIndex(this.filterIndex[4], item)
      ) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(
        [
          this.filterIndex[0],
          this.filterIndex[1],
          this.filterIndex[2],
          this.filterIndex[3],
          this.filterIndex[4],
          filterItem
        ],
        item
      );
      this.resetSelectedData([
        this.filterIndex[0],
        this.filterIndex[1],
        this.filterIndex[2],
        this.filterIndex[3],
        this.filterIndex[4],
        filterItem
      ]);
    },
    filterLayer7(filterItem, item) {
      if (
        !this.filterDataByIndex(this.filterIndex[0], item) ||
        !this.filterDataByIndex(this.filterIndex[1], item) ||
        !this.filterDataByIndex(this.filterIndex[2], item) ||
        !this.filterDataByIndex(this.filterIndex[3], item) ||
        !this.filterDataByIndex(this.filterIndex[4], item) ||
        !this.filterDataByIndex(this.filterIndex[5], item)
      ) {
        return;
      }
      switch (filterItem) {
        case "organizational_select":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_select":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_select":
          if (!this.filterBusiness(item)) return;
          break;
        case "country_select":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3_select":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4_select":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5_select":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6_select":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(
        [
          this.filterIndex[0],
          this.filterIndex[1],
          this.filterIndex[2],
          this.filterIndex[3],
          this.filterIndex[4],
          this.filterIndex[5],
          filterItem
        ],
        item
      );
      this.resetSelectedData([
        this.filterIndex[0],
        this.filterIndex[1],
        this.filterIndex[2],
        this.filterIndex[3],
        this.filterIndex[4],
        this.filterIndex[5],
        filterItem
      ]);
    },
    async resetSelectedData(type) {
      let types = [];
      types     = this.filterTypes.filter((item) => !type.includes(item)); // get elements not in filterTypes
      types.forEach((type) => {
        switch (type) {
          case "organizational_select":
            this.organizational_select = "";
            break;
          case "company_select":
            this.company_select = "";
            break;
          case "business_select":
            this.business_select = "";
            break;
          case "country_select":
            this.country_select = "";
            break;
          case "layer3_select":
            this.layer3_select = "";
            break;
          case "layer4_select":
            this.layer4_select = "";
            break;
          case "layer5_select":
            this.layer5_select = "";
            break;
          case "layer6_select":
            this.layer6_select = "";
            break;
        }
      });
      await this.updateFilterDataToStore();
    },
    filterDataByIndex(type, item) {
      switch (type) {
        case "organizational_select":
          return this.filterOrganizational(item);
        case "company_select":
          return this.filterCompanies(item);
        case "business_select":
          return this.filterBusiness(item);
        case "country_select":
          return this.filterCountries(item);
        case "layer3_select":
          return this.filterListLayer3(item);
        case "layer4_select":
          return this.filterListLayer4(item);
        case "layer5_select":
          return this.filterListLayer5(item);
        case "layer6_select":
          return this.filterListLayer6(item);
        default:
          return true;
      }
    },
    addFilterDataLoop(type, item) {
      let types = [];
      types     = this.filterTypes.filter((item) => !type.includes(item)); // get elements not in filterTypes
      types.forEach((type) => {
        switch (type) {
          case "organizational_select":
            this.addOrganizational({
              id                     : item.organizational_division,
              organizational_division: ORGANIZATIONAL_DATA[item.organizational_division]
            });
            break;
          case "company_select":
            this.addCompany({ company: item.company_name });
            break;
          case "business_select":
            this.addBusiness({ business_name: item.business_name });
            break;
          case "country_select":
            this.addCountry({ country: item.country });
            break;
          case "layer3_select":
            this.addLayer3({ layer_3: item.layer_3 });
            break;
          case "layer4_select":
            this.addLayer4({ layer_4: item.layer_4 });
            break;
          case "layer5_select":
            this.addLayer5({ layer_5: item.layer_5 });
            break;
          case "layer6_select":
            this.addLayer6({ layer_6: item.layer_6 });
            break;
        }
      });
    },
    async updateFilterDataToStore() {
      await this.$store.dispatch("dashboard/changeQueryParams", {
        organizationalDivision: this.organizational_select || null,
        companyName           : this.company_select || null,
        branchId              : this.business_select || null,
        country               : this.country_select || null,
        layer_3               : this.layer3_select || null,
        layer_4               : this.layer4_select || null,
        layer_5               : this.layer5_select || null,
        layer_6               : this.layer6_select || null
      });
      await this.$store.dispatch("dashboard/clearDataEmissionByPeriod");
      await this.$store.dispatch("dashboard/requestEmissionByPeriod");
      await this.$store.dispatch("dashboard/requestEmissionByCategory");
      await this.$store.dispatch("dashboard/requestEmissionsByCategoryDetail");
      await this.$store.dispatch("dashboard/requestEmissionsByCategoryDetailTop5");
    }
  }
};
</script>

<style lang="scss" scoped>
#headFix {
  transition: 0.1s ease-out;
  transition-duration: 0.1s;
}

#headFix.fix-top {
  display: block;
  z-index: 99;
  position: fixed;
  top: 84px;
  background: $monoOffWhite;
  left: 0;
  max-width: 100%;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
  0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
  0 65px 110px 0 rgba(160, 181, 186, 0.08);

  .border-right {
    border-right: 1px solid rgba(42, 42, 48, 0.1);
  }

  .list-filter {
    overflow: auto;
    white-space: nowrap;
  }

  .list-filter::-webkit-scrollbar {
    height: 0px;
  }

  .list-filter::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background: #bfd4d9;
  }

  /* Track */
  .list-filter::-webkit-scrollbar-track:horizontal {
    background: #e3eded;
  }

  /* Handle on hover */
  .list-filter::-webkit-scrollbar-thumb:horizontal:hover {
    background: #7a8b8d;
  }

  .filter-items {
    display: inline-block;
  }

  .one-line {
    display: inline-block;
  }
}

@include desktop {
  #headFix.fix-top {
    left: 236px;
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .pl-40-px {
    padding-left: 40px;
  }

  .list-filter::-webkit-scrollbar {
    height: 7px !important;
  }

  .list-filter {
    overflow: hidden !important;

    &:hover {
      overflow-x: auto !important;
    }
  }
}
</style>
