export const FLOOR_AREA = {
    TYPE_1: "300㎡未満",
    TYPE_2: "300㎡以上2,000㎡未満",
    TYPE_3: "2000㎡以上1万㎡未満",
    TYPE_4: "1万㎡以上3万㎡未満",
    TYPE_5: "3万㎡以上",
}

export const MONITORING = {
    LEVEL_BLANK: "空欄",
    LEVEL_4: "レベル4",
    LEVEL_5: "レベル5"
}

export const NATURAL_ENERGY_LEVEL_IN_SCHOOL = {
    LEVEL_1: "影響なし、または影響が50%未満",
    LEVEL_2: "建物の50%以上に影響",
    LEVEL_3: "建物の80%以上に影響"
}

export const NATURAL_ENERGY_LEVEL_OUT_SCHOOL = {
    LEVEL_1: "採用なし",
    LEVEL_2: "利用量15MJ/㎡・年未満",
    LEVEL_3: "利用量15MJ/㎡・年以上"
}
export const MONITORING_VALUE = [
    {
        type: '空欄',
        value: 1
    },
    {
        type: 'レベル4',
        value: 0.975
    },
    {
        type: 'レベル5',
        value: 0.95
    }
]
export const NATURAL_ENERGY_LEVEL = [
    {
        type: '影響なし、または影響が50%未満',
        value: 0
    },
    {
        type: '建物の50%以上に影響',
        value: 1
    },
    {
        type: '建物の80%以上に影響',
        value: 15
    },
    {
        type: '採用なし',
        value: 0
    },
    {
        type: '利用量15MJ/㎡・年未満',
        value: 1
    },
    {
        type: '利用量15MJ/㎡・年以上',
        value: 15
    }
]

export const ENERGY_CONSUMPTION = {
    "事務所": [
        {
            type: "300㎡未満",
            value: 1250
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 1250
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 1550
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 1850
        },
        {
            type: "3万㎡以上",
            value: 2150
        }
    ],
    "官公庁": [
        {
            type: "300㎡未満",
            value: 1050
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 1050
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 1050
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 1050
        },
        {
            type: "3万㎡以上",
            value: 1050
        }
    ],
    "研究施設": [
        {
            type: "300㎡未満",
            value: 2350 
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 2350 
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 2350 
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2350 
        },
        {
            type: "3万㎡以上",
            value: 2350 
        }
    ],
    "幼稚園・保育園": [
        {
            type: "300㎡未満",
            value: 540
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 540
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 540
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 540
        },
        {
            type: "3万㎡以上",
            value: 540
        }
    ],
    "小・中学校 北海道": [
        {
            type: "300㎡未満",
            value: 510
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 510
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 510
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 510
        },
        {
            type: "3万㎡以上",
            value: 510
        }
    ],
    "小・中学校 その他": [
        {
            type: "300㎡未満",
            value: 320
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 320
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 320
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 320
        },
        {
            type: "3万㎡以上",
            value: 320
        }
    ],
    "高校": [
        {
            type: "300㎡未満",
            value: 360
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 360
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 360
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 360
        },
        {
            type: "3万㎡以上",
            value: 360
        }
    ],
    "大学・専門学校": [
        {
            type: "300㎡未満",
            value: 860
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 860
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 860
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 860
        },
        {
            type: "3万㎡以上",
            value: 1100
        }
    ],
    'デパート・スーパー': [
        {
            type: "300㎡未満",
            value: 7250
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 7250
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 5000
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2950
        },
        {
            type: "3万㎡以上",
            value: 2950 
        }
    ],
    "コンビニエンスストア": [
        {
            type: "300㎡未満",
            value: 12900
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 12900
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 12900
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 12900
        },
        {
            type: "3万㎡以上",
            value: 12900 
        }
    ],
   
    "家電量販店": [
        {
            type: "300㎡未満",
            value: 2850
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 2850
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 2850
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2850
        },
        {
            type: "3万㎡以上",
            value: 2850
        }
    ],
    "その他物販": [
        {
            type: "300㎡未満",
            value: 2300
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 2300
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 2300
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2300
        },
        {
            type: "3万㎡以上",
            value: 2300
        }
    ],
    "飲食店": [
        {
            type: "300㎡未満",
            value: 19350
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 11950
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 3150
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 3150
        },
        {
            type: "3万㎡以上",
            value: 3150
        }
    ],
    "劇場・ホール": [
        {
            type: "300㎡未満",
            value: 1350
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 1350
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 1350
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 1400
        },
        {
            type: "3万㎡以上",
            value: 1400
        }
    ],
    "展示施設": [
        {
            type: "300㎡未満",
            value: 1100
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 1100
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 1100
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 1100
        },
        {
            type: "3万㎡以上",
            value: 1100
        }
    ],
    "スポーツ施設": [
        {
            type: "300㎡未満",
            value: 1850
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 1850
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 1850
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 1850
        },
        {
            type: "3万㎡以上",
            value: 1850
        }
    ],
    "工場": [
        {
            type: "300㎡未満",
            value: 500
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 500
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 500
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 500
        },
        {
            type: "3万㎡以上",
            value: 500
        }
    ],
    "病院": [
        {
            type: "300㎡未満",
            value:  2200
        },
        {
            type: "300㎡以上2,000㎡未満",
            value:  2200
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 2200
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2450
        },
        {
            type: "3万㎡以上",
            value: 2950
        }
    ],
    "ホテル・旅館": [
        {
            type: "300㎡未満",
            value: 2450
        },
        {
            type: "300㎡以上2,000㎡未満",
            value: 2450
        },
        {
            type: "2000㎡以上1万㎡未満",
            value: 2450
        },
        {
            type: "1万㎡以上3万㎡未満",
            value: 2750
        },
        {
            type: "3万㎡以上",
            value: 2750
        }
    ]
}