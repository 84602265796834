import Cookies from 'js-cookie';
import _isString from 'lodash/isString';
import { ACCESS_TOKEN } from '@/constants/configs';
import { TIMEOUT_KEY } from '@/constants/refreshToken';
import { NOT_ACTIVE_TIMEOUT_KEY } from '@/constants/refreshSession';
import store from '@/store';
export default {
  set(key, rawValue, option) {
    const value = _isString(rawValue) ? rawValue : JSON.stringify(rawValue);

    Cookies.set(key, value, option);
  },

  get(key) {
    const value = Cookies.get(key);

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  },

  remove(key) {
    Cookies.remove(key);
  },

  removeAll() {
    localStorage.removeItem('vuex');
    store.dispatch('userData/actionUpdateAuthentication', false);
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(TIMEOUT_KEY);
    Cookies.remove(NOT_ACTIVE_TIMEOUT_KEY);
  },

  setToken(value, option) {
    this.set(ACCESS_TOKEN, value, option);
  },

  getToken() {
    return this.get(ACCESS_TOKEN);
  },

  getRoleId() {
    return store.state.userData?.currentUser?.user?.role_id;
  }
};
