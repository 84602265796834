<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite()"
      @keydown="handleKeydownClick"
      ref="dialog"
      max-width="464px"
    >
      <v-card>
        <v-card-title>
          <span class="">خطأ.</span>
          <img class="" :src="getImageHeader()" />
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <common-button type="colored" class="btn-displayed-one" @action="submit" :label="confirmText"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {KEYS_CODE} from '@/constants/keyboard'
import CommonButton from '@/components/commonApp/Button.vue';

export default {
  components: { CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'اوكي',
    },
    message: {
      type: String,
      required: true,
    },
    isErrorImg: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('submit');
      this.$emit('close');
    },
    getImageHeader() {
      // if(this.isErrorImg) {
      return require(`@/assets/images/dialog/error-red.svg`);
      // }
      // return require(`@/assets/images/dialog/error.svg`);
    },
    handleKeydownClick(event) {
      if(event.keyCode === KEYS_CODE.ESCAPE) {
        this.submit()
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
  .v-card__title {
    direction: rtl;
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
  }
</style>
