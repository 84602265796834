<template>
  <div class="chart-wrapper">
    <div class="chart-axis-y-mobile">
      <chart-axis-y></chart-axis-y>
    </div>

    <div class="scope-1">
      <div class="header-wrapper">
        <div class="header">
          <img src="img/icons/s1.svg" alt="Scope 1"/>
        </div>

        <div class="header-chart-axis">
          <div class="axis-y">
            <chart-axis-y></chart-axis-y>
          </div>
        </div>
      </div>

      <div class="content-bg">
        <div class="content">
          <div
            class="item"
            v-for="(v, k) in scope1"
            :key="k + '-scope-1'"
            @click="selectedCategory(v, k, '-scope-1')"
            :class="{ active: k + '-scope-1' === selectedCategoryIndex }"
          >
            <div class="select-category" :class="{ active: k + '-scope-1' === selectedCategoryIndex }"></div>

            <div class="item-info">
              <div class="tooltip-method">
                <v-tooltip min-width="250px" max-width="300px" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="question" v-bind="attrs" v-on="on">
                      <img
                        @mouseover="changeTooltipImage($event)"
                        @mouseleave="changeTooltipImage($event, 'mouseleave')"
                        @click.stop="changeTooltipImage($event)"
                        src="img/icons/question.svg"
                        alt=""
                      />
                    </div>
                  </template>

                  <span v-html="v.tooltip"></span>
                </v-tooltip>
              </div>
              <div class="content-method">
                <div class="icon">
                  <img :src="v.icon" :alt="k"/>
                </div>
                <div class="item-info__title" :class="{ active: k + '-scope-1' === selectedCategoryIndex }">
                  {{ v.title }}
                </div>
              </div>
            </div>
            <div class="item-chart">
              <div class="axis-x" style="border: unset;"></div>

              <div class="chart-data">
                <!-- <div class="chart-value" :style="{ width: v.period + '%' }"></div> -->
                <!-- <button class="btn-action" @click="selectedCategory(v, k, '-scope-1')">詳細</button> -->
                <div v-if="v.value <= 0" class="chart-value negative-value" :style="{ width: v.period + '%', right: (100 - v.zero_value_percent) + '%' }"></div>
                <div class="zero-crossline" :style="{ left: v.zero_value_percent + '%' }" :class="{'zero-value': v.value === 0}"></div>
                <div v-if="v.value >= 0" class="chart-value positive-value" :style="{ width: v.period + '%', left: v.zero_value_percent + '%', maxWidth: 100 - v.zero_value_percent + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scope-2">
      <div class="header-wrapper">
        <div class="header">
          <img src="img/icons/s2.svg" alt="Scope 2"/>
        </div>

        <div class="header-chart-axis">
          <div class="axis-x" style="border: unset;"></div>
        </div>
      </div>

      <div class="content-bg">
        <div class="content">
          <div
            class="item"
            v-for="(v, k) in scope2"
            :key="k + '-scope-2'"
            @click="selectedCategory(v, k, '-scope-2')"
            :class="{ active: k + '-scope-2' === selectedCategoryIndex }"
          >
            <div class="select-category" :class="{ active: k + '-scope-2' === selectedCategoryIndex }"></div>

            <div class="item-info">
              <div class="tooltip-method">
                <v-tooltip min-width="250px" max-width="300px" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="question" v-bind="attrs" v-on="on">
                      <img
                        @mouseover="changeTooltipImage($event)"
                        @mouseleave="changeTooltipImage($event, 'mouseleave')"
                        @click.stop="changeTooltipImage($event)"
                        src="img/icons/question.svg"
                        alt=""
                      />
                    </div>
                  </template>

                  <span v-html="v.tooltip"></span>
                </v-tooltip>
              </div>
              <div class="content-method">
                <div class="icon">
                  <img :src="v.icon" :alt="k"/>
                </div>
                <div class="item-info__title" :class="{ active: k + '-scope-2' === selectedCategoryIndex }">
                  {{ v.title }}
                </div>
              </div>
            </div>
            <div class="item-chart">
              <div class="axis-x" style="border: unset;"></div>

              <div class="chart-data">
                <!-- <div class="chart-value" :style="{ width: v.period + '%' }"></div> -->
                <!-- <button class="btn-action" @click="selectedCategory(v, k, '-scope-2')">詳細</button> -->
                <div v-if="v.value <= 0" class="chart-value negative-value" :style="{ width: v.period + '%', right: (100 - v.zero_value_percent) + '%' }"></div>
                <div class="zero-crossline" :style="{ left: v.zero_value_percent + '%' }" :class="{'zero-value': v.value === 0}"></div>
                <div v-if="v.value > 0" class="chart-value positive-value" :style="{ width: v.period + '%', left: v.zero_value_percent + '%', maxWidth: 100 - v.zero_value_percent + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scope-3" v-if="isEnableScope3">
      <div class="header-wrapper">
        <div class="header">
          <img class="header__title" src="img/icons/s3.svg" alt="Scope 3"/>
        </div>

        <div class="header-chart-axis">
          <div class="axis-x" style="border: unset;"></div>
        </div>
      </div>

      <div class="content-bg">
        <div class="content">
          <div
            class="item"
            v-for="(v, k) in scope3"
            :key="k + '-scope-3'"
            @click="selectedCategory(v, k, '-scope-3')"
            :class="{ active: k + '-scope-3' === selectedCategoryIndex }"
          >
            <div class="select-category" :class="{ active: k + '-scope-3' === selectedCategoryIndex }"></div>

            <div class="item-info">
              <div class="tooltip-method">
                <v-tooltip min-width="250px" max-width="300px" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="question" v-bind="attrs" v-on="on">
                      <img
                        @mouseover="changeTooltipImage($event)"
                        @mouseleave="changeTooltipImage($event, 'mouseleave')"
                        @click.stop="changeTooltipImage($event)"
                        src="img/icons/question.svg"
                        alt=""
                      />
                    </div>
                  </template>

                  <span v-html="v.tooltip"></span>
                </v-tooltip>
              </div>
              
              <div class="content-method">
                <div class="">
                  <img :src="v.iconPrefix" :alt="k + 1"/>
                </div>
                <div class="icon">
                  <img width="30" height="30" :src="v.icon" :alt="k"/>
                </div>
                <div class="item-info__title" :class="{ active: k + '-scope-3' === selectedCategoryIndex }">
                  {{ v.title }}
                </div>
              </div>
            </div>
            <div class="item-chart">
              <div class="axis-x" style="border: unset;"></div>

              <div class="chart-data">
                <!-- <div class="chart-value" :style="{ width: v.period + '%' }"></div> -->
                <!-- <button class="btn-action" @click="selectedCategory(v, k, '-scope-3')">詳細</button> -->
                <div v-if="v.value <= 0" class="chart-value negative-value" :style="{ width: v.period_negative + '%', right: (100 - v.zero_value_percent) + '%' }"></div>
                <div class="zero-crossline" :style="{ left: v.zero_value_percent + '%' }" :class="{'zero-value': v.value === 0}"></div>
                <div v-if="v.value > 0" class="chart-value positive-value" :style="{ width: v.period + '%', left: v.zero_value_percent + '%', maxWidth: 100 - v.zero_value_percent + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
@import 'large-desktop';
.chart-data {
  position: relative;
  .negative-value, .positive-value {
    position: absolute;
  }
  .zero-crossline {
    position: absolute;
    right: 0;
    border-left: 1px solid rgba(42, 42, 48, 0.1);
    height: 46px;
    width: 1px;
  }
}
@include desktop {
  .scope-2 {
    .chart-data {
      .zero-crossline {
        height: 147px;
      }
    }
  }
}
</style>

<script>
import {TABLET_WIDTH} from '@/constants/screen-size';
import ChartAxisY from './chart-axis.vue';
import {mapGetters} from 'vuex';

export default {
  components: {ChartAxisY},
  data() {
    return {
      selectedCategoryIndex: '',
      isFirstChange        : true,
    };
  },
  mounted() {
    this.selectedExistOrFirstCategory();
  },
  computed: {
    ...mapGetters({
      scope1                                  : 'dashboard/getEmissionsByCategoryWithYearlyScope1',
      scope2                                  : 'dashboard/getEmissionsByCategoryWithYearlyScope2',
      scope3                                  : 'dashboard/getEmissionsByCategoryWithYearlyScope3',
      scope4                                  : 'dashboard/getEmissionsByCategoryWithYearlyScope4',
      getSelectedEmissionsByCategoryWithYearly: 'dashboard/getSelectedEmissionsByCategoryWithYearly',
      isEnableScope3                          : 'dashboard/getIsEnableScope3',
    }),
  },
  watch   : {
    scope1(val) {
      // selected first category
      this.selectedExistOrFirstCategory();
    },
  },
  methods : {
    selectedCategory(category, index, prefix) {
      this.selectedCategoryIndex = index + prefix;

      this.$emit('onSelectCategory', category);

      if (window.outerWidth < TABLET_WIDTH) {
        this.$emit('selectedCategory', category);
        return;
      }
    },
    selectedExistOrFirstCategory() {
      if (this.selectedCategoryIndex !== '') {
        this.$store.dispatch('dashboard/requestEmissionsByCategoryDetailTop5');
        return;
      }

      if (
        this.getSelectedEmissionsByCategoryWithYearly &&
        Object.prototype.hasOwnProperty.call(this.getSelectedEmissionsByCategoryWithYearly, 'scope') &&
        Object.prototype.hasOwnProperty.call(this.getSelectedEmissionsByCategoryWithYearly, 'category')
      ) {
        const scope                = this.getSelectedEmissionsByCategoryWithYearly.scope;
        const categoryIndex        = this.getSelectedEmissionsByCategoryWithYearly.category - 1;
        this.selectedCategoryIndex = `${categoryIndex}-scope-${scope}`;

        let scopesData = [];

        switch (scope) {
          case 1:
            scopesData = this.scope1;
            break;
          case 2:
            scopesData = this.scope2;
            break;
          case 3:
            scopesData = this.scope3;
            break;
          case 4:
            scopesData = this.scope4;
            break;
        }

        const selectedCategory = scopesData[categoryIndex];

        if (selectedCategory && Object.prototype.hasOwnProperty.call(selectedCategory, 'original')) {
          this.$emit('onSelectCategory', selectedCategory);
        }
      } else if (Object.prototype.hasOwnProperty.call(this.scope1[0], 'original')) {
        this.selectedCategoryIndex = 0 + '-scope-1';
        this.$emit('onSelectCategory', this.scope1[0]);
      }
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = 'img/icons/question.svg';
      } else {
        event.target.src = 'img/icons/question-active.svg';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-method {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #7c898c;
    .icon {
      margin-left: 7px;
    }
  }
</style>
