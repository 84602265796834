<template>
  <div class="container-fluid">
    <div class="chart-legend">
      <div class="scope1">
        <div class="box-color" :style="{ 'background-color': SCOPE_1_COLOR }"></div>
        <div class="text">Scope 1</div>
      </div>

      <div class="scope2">
        <div class="box-color" :style="{ 'background-color': SCOPE_2_COLOR }"></div>
        <div class="text">Scope 2</div>
      </div>

      <div class="scope3" v-if="isEnableScope3">
        <div class="box-color" :style="{ 'background-color': SCOPE_3_COLOR }"></div>
        <div class="text">Scope 3</div>
      </div>
    </div>
    <wj-flex-chart
      @click="handleClickChart"
      class="chart-wrapper"
      header=""
      ref="yearlyChartEl"
      bindingX="time_range"
      stacking="Stacked"
      :initialized="initialized"
      :legend="null"
      :itemsSource="data"
      :gotFocus="gotFocus"
      :tooltipContent="tooltipContent"
      :options="{
        groupWidth: 28.93,
      }"
      :palette="[SCOPE_1_COLOR, SCOPE_2_COLOR, SCOPE_3_COLOR]"
    >
      <wj-flex-chart-series name="Scope 1" binding="emission_scope1"></wj-flex-chart-series>
      <wj-flex-chart-series name="Scope 2" binding="emission_scope2"></wj-flex-chart-series>
      <wj-flex-chart-series name="Scope 3" binding="emission_scope3" v-if="isEnableScope3"></wj-flex-chart-series>
      <wj-flex-chart-legend :position="'None'"></wj-flex-chart-legend>
      <wj-flex-chart-axis
        wjProperty="axisY"
        :axisLine="true"
        :itemFormatter="itemFormatterAxisY"
        :minorGrid="false"
        :majorGrid="false"
        :min="min"
        :max="max"
        :actualMax="max"
        :actualMin="min"
        :labels="true"
        :itemsSource="axisYItemsSource"
        :labelPadding="LABEL_PADDING"
      ></wj-flex-chart-axis>
      <wj-flex-chart-axis
        wjProperty="axisX"
        :axisLine="true"
        :labelPadding="LABEL_PADDING"
        :itemFormatter="itemFormatterAxisX"
        :majorTickMarks="false"
      ></wj-flex-chart-axis>
      <wj-flex-chart-animation></wj-flex-chart-animation>
      <div
        class="selected-bar"
        :class="{ hidden: isHiddenSelected }"
        :style="{ width: activeBar.width, height: activeBar.height, top: activeBar.top, left: activeBar.left }"
      ></div>

      <div class="btn-action next" :class="{ disabled: isDisabledNext }" @click="nextChartItem">
        <button>
          <img :src="nextIcon" alt="Next"/>
        </button>
      </div>

      <div class="btn-action prev" :class="{ disabled: isDisabledPrev }" @click="prevChartItem">
        <button>
          <img :src="prevIcon" alt="Prev"/>
        </button>
      </div>
    </wj-flex-chart>
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  display: none;
}

@import 'mobile';
@import 'desktop';
</style>

<style lang="scss">
@include large-desktop {
  .chart-wrapper {
    &.wj-control svg {
      width: 100%;
    }
  }
  .btn-action {
    cursor: pointer;

    button:disabled,
    button[disabled] {
      background-color: #e4e6e7;
      color: #99a6a9;
      font-size: 14px;
    }
  }

  .btn-action:hover:enabled {
    background: #a4a184 !important;
  }
  .btn-action:focus {
    border: 2px solid #008fcc;
  }
}
</style>

<script>
import {mapGetters} from 'vuex';
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';

import {
  AXIS_X_LABEL,
  AXIS_Y_LABEL,
  AXIS_Y_T_CO2,
  SCOPE_1_COLOR,
  SCOPE_2_COLOR,
  SCOPE_3_COLOR,
} from '../../../chart-color';
import {prepareChartAxisY} from '@/concerns/chart';

const MAX_BAR_ITEM  = 5;
const LABEL_PADDING = 20;

import {LARGE_DESKTOP} from '@/constants/screen-size';

export default {
  data: () => {
    return {
      originalData    : [],
      data            : [],
      axisYItemsSource: [],
      firstIndex      : 0,
      min             : 0,
      max             : 100,
      selectedIndex   : null,
      isHiddenSelected: false,
      hoverItemIndex  : null,
      activeBar       : {
        width : 0,
        height: 0,
        top   : 0,
        left  : 0,
      },
      theChart        : null,
      resizeTimer     : null,
      isDisabledNext  : true,
      isDisabledPrev  : false,
    };
  },
  async created() {
    await this.transformChart();
    this.originalData = this.getEmissionsByPeriodWithMonthly;
    if (this.firstIndex !== this.originalData.length - MAX_BAR_ITEM) {
      this.isDisabledNext = false;
    }

    if (this.firstIndex === 0) {
      this.isDisabledPrev = true;
    }
  },
  mounted() {
    this.onWindowResize();
  },
  destroyed() {
    this.offClickChart();
    this.offWindowResize();
  },
  computed: {
    ...mapGetters({
      getEmissionsByPeriodWithMonthly: 'dashboard/getEmissionsByPeriodWithMonthly',
      isEnableScope3                 : 'dashboard/getIsEnableScope3',
    }),
    chartPosition() {
      const el = this.$refs.yearlyChartEl;
      return el.$el.getBoundingClientRect();
    },
    nextIcon() {
      if (this.isDisabledNext) {
        return 'img/icons/next-disabled.svg';
      }
      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        return 'img/icons/prev-disabled.svg';
      }
      return 'img/icons/prev.svg';
    },
    SCOPE_1_COLOR: () => SCOPE_1_COLOR,
    SCOPE_2_COLOR: () => SCOPE_2_COLOR,
    SCOPE_3_COLOR: () => SCOPE_3_COLOR,
    LABEL_PADDING: () => LABEL_PADDING,
  },
  watch   : {
    getEmissionsByPeriodWithMonthly() {
      this.isHiddenSelected = true;
      this.transformChart();
    },
  },
  methods : {
    transformChart() {
      const summary     = this.getEmissionsByPeriodWithMonthly.map(
        (item) => item.emission_scope1 + item.emission_scope2 + item.emission_scope3,
      );
      this.originalData = this.getEmissionsByPeriodWithMonthly;

      const findMonth = (ele) =>
        ele.original.month === this.$store.state.dashboard.selectedEmissionsByPeriod.month &&
        ele.original.year === this.$store.state.dashboard.selectedEmissionsByPeriod.year;
      const index     = this.originalData.findIndex(findMonth);
      // show chart with MAX_BAR_ITEM latest
      if (index > this.originalData.length - MAX_BAR_ITEM) {
        this.firstIndex = this.originalData.length - MAX_BAR_ITEM;
      } else {
        this.firstIndex = index;
      }

      const max                                   = Math.max(...summary);
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(max);

      this.prepareChartData();

      if (this.originalData.length) {
        // auto selected latest bar
        if (index > this.originalData.length - MAX_BAR_ITEM) {
          this.selectedIndex = index - (this.originalData.length - MAX_BAR_ITEM);
        } else {
          this.selectedIndex = 0;
        }

        this.$emit('onUpdateStateDetail', this.data[this.selectedIndex]);

        if (window.outerWidth >= LARGE_DESKTOP) {
          // only run for desktop mode
          this.isHiddenSelected = false;
          this.updateSelected();
          return;
        }
      }
    },
    prepareChartData() {
      let index  = 0;
      const data = [];

      do {
        data.push(this.originalData[index + this.firstIndex]);
        index++;
      } while (index < MAX_BAR_ITEM);

      this.data = data;
    },
    nextChartItem() {
      if (this.firstIndex + MAX_BAR_ITEM >= this.originalData.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext = false;
      this.firstIndex++;
      if (this.firstIndex + MAX_BAR_ITEM >= this.originalData.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartData();

      if (this.firstIndex > 0) {
        this.isDisabledPrev = false;
      }

      if (this.selectedIndex !== null && this.selectedIndex !== undefined) {
        if (this.selectedIndex <= 0) {
          this.isHiddenSelected = true;
        } else {
          this.isHiddenSelected = false;
        }
        this.selectedIndex--;
        this.updateSelected();
      }
    },
    prevChartItem() {
      if (this.firstIndex <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;
      this.firstIndex--;
      if (this.firstIndex <= 0) {
        this.isDisabledPrev = true;
      }
      this.prepareChartData();

      if (this.firstIndex + MAX_BAR_ITEM < this.originalData.length) {
        this.isDisabledNext = false;
      }

      if (this.selectedIndex !== null && this.selectedIndex !== undefined) {
        if (this.selectedIndex + 1 >= MAX_BAR_ITEM) {
          this.isHiddenSelected = true;
        } else {
          this.isHiddenSelected = false;
        }
        this.selectedIndex++;
        this.updateSelected();
      }
    },
    initialized(event) {
      this.theChart = event;

      this.handleEventChart();
    },
    itemFormatterAxisY(engine, label) {
      label.cls = null; // no default CSS
      if (label.val >= this.max) {
        engine.textFill = AXIS_Y_T_CO2; // red text
        label.text      = 't-CO2';
      } else {
        engine.textFill = AXIS_Y_LABEL;
      }

      return label;
    },
    itemFormatterAxisX(engine, label) {
      label.cls       = null;
      engine.textFill = AXIS_X_LABEL;
      return label;
    },
    gotFocus: (_) => {
      //
    },
    tooltipContent(event) {
      if (this.hoverItemIndex !== event.pointIndex) {
        this.hoverItemIndex = event.pointIndex;
      }

      // hidden tooltip
      return '';
    },
    selectionChanged(_) {
      //
    },
    onClickChart() {
      const chartEl = this.theChart.hostElement;

      chartEl.addEventListener('click', this.handleClickChart);
    },
    offClickChart() {
      const chartEl = this.theChart.hostElement;

      if (chartEl) {
        chartEl.removeEventListener('click', this.handleClickChart);
      }
    },
    handleClickChart(e) {
      const ht = this.theChart.hitTest(e);
      if (ht.pointIndex === null || ht.pointIndex === undefined || ht.name.indexOf('Exception') >= 0) {
        console.debug(ht);
      }

      if (ht.chartElement !== 0) {
        return;
      }

      const oldSelectedIndex = this.selectedIndex;
      this.selectedIndex     = ht.pointIndex;
      this.$emit('onUpdateStateDetail', this.data[this.selectedIndex]);

      if (window.outerWidth < LARGE_DESKTOP) {
        this.isHiddenSelected = true;
        this.openDialogStateDetail();
        return;
      }

      this.isHiddenSelected = false;

      if (oldSelectedIndex !== ht.pointIndex) {
        this.updateSelected();
      }
    },
    openDialogStateDetail() {
      this.$emit('openDialogStateDetail', this.data[this.selectedIndex]);
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);

      this.resizeTimer = setTimeout(() => {
        if (this.theChart) {
          this.theChart.refresh(true);

          this.updateSelected();
        }
      }, 100);
    },
    handleEventChart() {
      this.onClickChart();
    },
    updateSelected() {
      if (!this.$refs.yearlyChartEl || !this.theChart.axisX._axrect) {
        return;
      }
      const chartWidth      = this.theChart.axisX._axrect.width;
      const barWidth        = chartWidth / MAX_BAR_ITEM;
      const width           = 120;
      const plus            = (barWidth - width) / 2;
      const chartClientRect = this.$refs.yearlyChartEl.$el.getBoundingClientRect();
      const margin          = chartClientRect.width - chartWidth;

      this.activeBar = {
        width : width + 'px',
        height: chartClientRect.height + 'px',
        top   : 0 + 'px',
        left  : barWidth * this.selectedIndex + margin / 2 + plus + LABEL_PADDING / 2 + LABEL_PADDING + 'px',
      };
    },
  },
};
</script>
