<template>
  <div class="apartment-condition mt-12">
    <div class="facility mt-12">
      <div class="facility_item">
        <div class="facility_item--label">電力の排出係数</div>
        <div class="facility_item--description">電気の排出係数を変更したい場合は、数値を入力してください。</div>
        <div class="facility-input">
          <!-- <input type="number" @focus="factorError = false" class="input-elic my-0" v-model="factor" /> -->
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="factor"
            :value="factor"
            :label="'t-CO2/kWh'"
            width="95"
            :errorMessage="inputErrors.factor"
            :disabled="isDisable"
            @removeError="inputErrors.factor = ''"
          />
        </div>
      </div>
    </div>

    <div class="facility mt-12">
      <div class="facility_item">
        <div class="facility_item--label">1㎡あたり1次エネルギー消費量</div>
        <div class="facility_item--description">以下の項目に、1次エネルギー消費量を直接入力してください。</div>
        <div class="facility-input">
          <!-- <input type="number" @focus="consumptionError = false" class="input-elic my-0" v-model="consumption" /> -->
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="consumption"
            :value="consumption"
            :label="'MJ/年㎡'"
            width="85"
            :disabled="isDisable"
            :errorMessage="inputErrors.consumption"
            @removeError="inputErrors.consumption = ''"
          />
        </div>
      </div>
    </div>

    <common-button
      v-if="!isDisable"
      class="mt-12 casbee-btn"
      v-ripple="false"
      type="colored"
      :label="'入力した値で計算'"
      :disabled="isDisable"
      @action="handlerCalculationData()"
    />
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CasbeeInput from '@/components/casbee/input';

import {
  emissionFor1m2,
  emissionForHourUnit,
  emissionContinuousOperation,
} from '@/constants/casbee/apartment-free-input';
import { floor } from 'mathjs';
import { CASBEE_TYPE } from '@/constants/casbee/';
import { validateInputFieldCasbee } from '@/utils/validate';

export default {
  components: { CommonButton, DefaultPullDownMenu, CasbeeInput },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      calcData: {
        total: 0,
        source: '',
      },
      factor: 0.00043,
      factorError: false,
      consumption: 0,
      consumptionError: false,
      label: '【専有部】1㎡あたりCO2排出量',
      labelInside: '【屋外廊下】1㎡あたりCO2排出量',
      labelOutside: '【屋内廊下】1㎡あたりCO2排出量',
      labelSource: `原単位を計算する際に、「出典」の内容ごとに計算結果を表示します。\n同じCASBEEファミリーの原単位を追加する場合でも、出典の内容は分けて記載してください。`,
      validForm: true,
      inputErrors: {
        factor: '',
        consumption: ''
      },
    };
  },
  mounted() {
    this.prepareDetailData();
  },
  methods: {
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
      if(!this.isValidData) {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
        return
      }
      const emissionsIn1m2 = floor(emissionFor1m2(this.factor, this.consumption),4);
      const emissionsForHourUnit = floor(emissionForHourUnit(this.factor),4);
      const emissionsContinuousOperation = floor(emissionContinuousOperation(this.factor),4);
      const source = `CASBEE 建築(新築) カテゴリ11&13 集合住宅 自由入力`;
      const sourceInside = `CASBEE 建築(新築) カテゴリ11&13 集合住宅 自由入力`;
      const sourceOutside = `CASBEE 建築(新築) カテゴリ11&13 集合住宅 自由入力`;

      this.calcData = {
        total: emissionsIn1m2,
        totalInside: emissionsForHourUnit,
        totalOutside: emissionsContinuousOperation,
        source: source,
        sourceInside: sourceInside,
        sourceOutside: sourceOutside,
        label: this.label,
        labelInside: this.labelInside,
        labelOutside: this.labelOutside,
        labelSource: this.labelSource,
        patternType: CASBEE_TYPE.ARCHITECTURE_APARTMENT_ENTER_FREELY,
        content_json: {
          factor: this.factor,
          consumption: this.consumption
        }
      };
      this.$emit('onHandlerCalculationData', this.calcData);
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
  },
  watch: {
    factor: {
      handler() {
        this.factorError = false;
        this.consumptionError = false;
      },
    },
    consumption: {
      handler() {
        this.factorError = false;
        this.consumptionError = false;
      },
    },
  },
  computed: {
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    isDisable() {
      return this.detailData?.success
    }
  },
};
</script>
<style lang="scss" scoped>
.apartment-condition {
  .facility {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    &_item {
      .facility-input {
        display: flex;
        align-items: baseline;
        gap: 8px;
        .input-elic {
          width: 80px;
          height: 32px;
          padding: 0 8px;
          text-align: right;
          &.has-error {
            border: 1px solid $redMid;
          }
        }
      }
      &--label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        margin-bottom: 8px;
      }
      &--description {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: $monoBlack;
        letter-spacing: 0.05em;
        margin-bottom: 8px;
      }
    }

  }
  .options {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
  .casbee-btn {
    width: 100%;
  }
}
@include desktop {
  .apartment-condition {
    .facility {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
      &_item {
        .facility-input {
          display: flex;
          align-items: baseline;
          gap: 8px;
          .input-elic {
            width: 80px;
            height: 32px;
            padding: 0 8px;
            text-align: right;
            &.has-error {
              border: 1px solid $redMid;
            }
          }
        }
        &--label {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          margin-bottom: 12px;
        }
        &--description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $monoBlack;
          margin-bottom: 12px;
        }
      }
    }
    .options {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
    }
    .casbee-btn {
      width: 250px;
    }
  }
}
</style>