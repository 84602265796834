<template>
  <div class="category-wrapper">
    <div class="category-header">
      <span class="category__title">
        <span> <img src="img/icons/time-2.svg" alt="time" /> </span>
        <h2 class="category__txt">بالتصنيف</h2>
      </span>

      <span class="category__icon"><img src="@/assets/icons/multiple.svg" /></span>
    </div>
    <div class="tabs-header">
      <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
        <v-tab>سنويا</v-tab>
        <v-tab>شهريا</v-tab>
      </v-tabs>
    </div>

    <div class="period-combobox" v-if="selectedTab === 0">
      <default-pull-down-menu
        v-model="selectedDuration"
        :initialValue="defaultDurationId"
        :items="getDurationByCategoryWithYearly"
        suffixInOption="مدة التسجيل"
        :defaultIsSelected="defaultIsSelected"
        width="100%"
      />
    </div>

    <div class="tabs-content">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <yearly></yearly>
        </v-tab-item>

        <v-tab-item>
          <duration></duration>
          <monthly></monthly>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Yearly from './yearly/index.vue';
import Monthly from './monthly/index.vue';
import Duration from './duration/index.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { DASHBOARD_TYPES } from '@/constants/dashboard';

const tabsMapping = {
  0: DASHBOARD_TYPES.duration,
  1: DASHBOARD_TYPES.month,
};

export default {
  components: { Yearly, Monthly, Duration, DefaultPullDownMenu },
  data() {
    return {
      selectedTab: 0,
      selectedDuration: 0,
      // items: [],
      windowWidth: window.innerWidth,
      defaultDurationId: 0,
      defaultIsSelected: false,
    };
  },
  mounted() {
    this.defaultDurationId =
      this.getKeepSelectedDurationByCategoryWithYearly >= 0 ? this.getKeepSelectedDurationByCategoryWithYearly : 0;
    this.selectedDuration =
      this.getKeepSelectedDurationByCategoryWithYearly >= 0 ? this.getKeepSelectedDurationByCategoryWithYearly : 0;

    this.defaultIsSelected = Array.from(this.getDurationByCategoryWithYearly).some((item) => {
      return item.is_selected === true && item.value === this.getKeepSelectedDurationByCategoryWithYearly;
    });

    this.$store.dispatch('dashboard/requestDurationByCategory');

    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  created() {
    this.selectedTab = this.getSelectedTypeForEmissionsByCategory;
  },
  watch: {
    selectedTab(newVal) {
      const type = tabsMapping[newVal];
      this.$store.dispatch('dashboard/changeSelectedTypeForEmissionsByCategory', type);
      this.$store.dispatch('dashboard/requestEmissionByCategory');
    },
    selectedDuration() {
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithYearly', this.selectedDuration);

      this.$store.dispatch('dashboard/changeQueryParams', {
        duration_id: this.selectedDuration,
      });
      this.$store.dispatch('dashboard/requestEmissionByCategory');
    },
    getDurationByCategoryWithYearly() {
      if (this.getDurationByCategoryWithYearly.length) {
        this.defaultSelectedFirstDuration();
      }
    },
  },
  computed: {
    ...mapGetters({
      getSelectedTypeForEmissionsByCategory: 'dashboard/getSelectedTypeForEmissionsByCategory',
      getDurationByCategoryWithYearly: 'dashboard/getDurationByCategoryWithYearly',
      getKeepSelectedDurationByCategoryWithYearly: 'dashboard/getKeepSelectedDurationByCategoryWithYearly',
    }),
  },
  methods: {
    defaultSelectedFirstDuration() {
      if (this.getKeepSelectedDurationByCategoryWithYearly > 0) {
        this.defaultDurationId = this.getKeepSelectedDurationByCategoryWithYearly;
        this.selectedDuration = this.getKeepSelectedDurationByCategoryWithYearly;
      } else {
        const value = Array.from(this.getDurationByCategoryWithYearly).find((item) => {
          return item.is_selected === true;
        });
        this.defaultDurationId = value.value;
        this.selectedDuration = value.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}

.category-header {
  margin-top: 40px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;

  .category__title {
    font-size: 16px;
    display: flex;
    margin-bottom: 17px;
    justify-content: flex-end;
    flex-flow: row-reverse;

    .category__txt {
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      color: #404d50;
      padding-right: 16.5px;
      display: flex;
      align-items: center;
    }
  }
  .category__icon {
    display: flex;
  }
}

.tabs-header {
  margin-bottom: 16px;
}

.period-combobox {
  width: 100%;
  margin-bottom: 48px;
}

@include desktop {
  .category-header {
    margin: 48px 0px;
  }

  .tabs-header {
    margin-bottom: 16px;
  }

  .period-combobox {
    width: 300px;
    margin-bottom: 48px;
    margin-left: 0;
  }
}
</style>
