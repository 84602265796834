<template>
  <div class="arc-cat-one">
    <div class="selection-group">
      <div class="selection-item">
        <div class="label">用途</div>
        <default-pull-down-menu
          v-model="purpose"
          :items="purposeTypeOptions"
          :initialValue="purpose"
          :disabled="isViewMode"
          width="120px"
          height="32px"
          isCasbeePulldown
          class="pulldown"
        />
      </div>
      <div class="selection-item">
        <div class="label">省資源の取組み</div>
        <default-pull-down-menu
          v-model="effort"
          :items="effortTypeOptions"
          :initialValue="effort"
          :disabled="isViewMode"
          width="280px"
          height="32px"
          isCasbeePulldown
          class="pulldown"
        />
      </div>
      <div class="selection-item">
        <div class="label">構造</div>
        <default-pull-down-menu
          v-model="frame"
          :items="frameTypeOptions"
          :initialValue="frame"
          :disabled="isViewMode"
          width="83px"
          height="32px"
          isCasbeePulldown
          class="pulldown"
        />
      </div>
      <div class="selection-item" v-if="isEnableLifeSpan">
        <div class="label">躯体材料の耐用年数</div>
        <default-pull-down-menu
          v-model="lifeSpan"
          :items="lifeSpanTypeOptions"
          :initialValue="lifeSpan"
          :disabled="isViewMode"
          width="170px"
          height="32px"
          isCasbeePulldown
          class="pulldown"
        />
      </div>
    </div>
    <div class="ratio-group" v-if="isEnableFrameRatio && isHiddenRatioField">
      <div class="label" for="ratio">延べ床面積における木造割合（％）</div>
      <CasbeeInput
        id="ratio"
        class="ratio-input"
        v-model="ratio"
        label="%"
        width="64"
        :disabled="isViewMode"
        :errorMessage="inputErrors.ratio ? errorMessage.ratio : ''"
        @removeError="inputErrors.ratio = false"
      />
    </div>
    <div class="source-group" v-if="isEnableSources">
      <div class="source-description">
        <div class="label">代表的な資材のCO2原単位</div>
        <div class="label-des">自社調達を反映したい場合、躯体部分の排出量を控除したい場合に入力してください。</div>
      </div>
      <div class="source-inputs">
        <div class="source-item" v-if="isEnableOrdinaryConcrete">
          <div class="label-sub" for="ordinaryConcrete">普通コンクリート</div>
          <CasbeeInput
            class="item-input"
            id="ordinaryConcrete"
            v-model="ordinaryConcrete"
            label="kg-CO2/㎥"
            :disabled="isViewMode"
            :errorMessage="inputErrors.ordinaryConcrete ? errorMessage.ordinaryConcrete : ''"
            @removeError="inputErrors.ordinaryConcrete = false"
          />
        </div>
        <div class="source-item" v-if="isEnableBlastFurnaceCementConcrete">
          <div class="label-sub" for="blastFurnaceCementConcrete">高炉セメントコンクリート</div>
          <CasbeeInput
            class="item-input"
            id="blastFurnaceCementConcrete"
            v-model="blastFurnaceCementConcrete"
            label="kg-CO2/㎥"
            :disabled="isViewMode"
            :errorMessage="inputErrors.blastFurnaceCementConcrete ? errorMessage.blastFurnaceCementConcrete : ''"
            @removeError="inputErrors.blastFurnaceCementConcrete = false"
          />
        </div>
        <div class="source-item">
          <div class="label-sub" for="steelFrame">鉄骨</div>
          <CasbeeInput
            class="item-input"
            id="steelFrame"
            v-model="steelFrame"
            label="kg-CO2/kg"
            :disabled="isViewMode"
            :errorMessage="inputErrors.steelFrame ? errorMessage.steelFrame : ''"
            @removeError="inputErrors.steelFrame = false"
          />
        </div>
        <div class="source-item">
          <div class="label-sub" for="rebar">鉄筋</div>
          <CasbeeInput
            class="item-input"
            id="rebar"
            v-model="rebar"
            label="kg-CO2/kg"
            :disabled="isViewMode"
            :errorMessage="inputErrors.rebar ? errorMessage.rebar : ''"
            @removeError="inputErrors.rebar = false"
          />
        </div>
      </div>
    </div>
    <div class="action-group">
      <common-button
        v-if="!isViewMode"
        class="action-calcucalte"
        v-ripple="false"
        type="colored"
        :isDisable="isViewMode"
        :label="'入力した値で計算'"
        @action="calculateTotal()"
      />
    </div>
  </div>
</template>
<script>
import {
  PURPOSES_TYPE,
  LEVEL,
  EFFORT_TYPE,
  CO2_DATA,
  FRAME_TYPE,
  LIFE_SPAN_TYPE,
  ENVIRONMENTAL_IMPACT,
  CO2_DISCOUNT_DATA,
  LIFE_SPANS_AGE,
} from '@/constants/casbee/architectureCategory1.js';
import { CASBEE_TYPE } from '@/constants/casbee.js';
import { bignumber, evaluate, ceil } from 'mathjs';

import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CommonButton from '@/components/utils/button.vue';
import CasbeeInput from '@/components/casbee/input.vue';
import isEmpty from 'lodash/isEmpty';
import { validateInputFieldCasbee } from '@/utils/validate';

export default {
  components: { DefaultPullDownMenu, CommonButton, CasbeeInput },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      purpose: PURPOSES_TYPE.office.value,
      effort: EFFORT_TYPE.empty.value,
      frame: FRAME_TYPE.steel.value,
      lifeSpan: LIFE_SPAN_TYPE.complex30.value,
      discount: null,
      ratio: 0,
      ordinaryConcrete: 0,
      blastFurnaceCementConcrete: 0,
      steelFrame: 0,
      rebar: 0,
      inputErrors: {
        ordinaryConcrete: false,
        blastFurnaceCementConcrete: false,
        steelFrame: false,
        rebar: false,
        ratio: false,
      },
      errorMessage: {
        ordinaryConcrete: '',
        blastFurnaceCementConcrete: '',
        steelFrame: '',
        rebar: '',
        ratio: '',
      },
    };
  },
  computed: {
    isEnableSources() {
      const isSelectWood = this.frame !== FRAME_TYPE.wood.value;
      const isSelectedCurrentEffort = this.effort === EFFORT_TYPE.current.value;
      return !(!isSelectWood || isSelectedCurrentEffort);
    },
    isEnableOrdinaryConcrete() {
      return this.effort === EFFORT_TYPE.empty.value;
    },
    isEnableBlastFurnaceCementConcrete() {
      return this.effort === EFFORT_TYPE.recycling.value;
    },
    isSelectedWood() {
      return this.frame === FRAME_TYPE.wood.value;
    },
    isEnableFrameRatio() {
      return !(this.frame === FRAME_TYPE.wood.value);
    },
    isHiddenRatioField() {
      return !(this.effort === EFFORT_TYPE.current.value && this.frame === FRAME_TYPE.steel.value);
    },
    isEnableLifeSpan() {
      return this.purpose === PURPOSES_TYPE.complex.value;
    },
    purposeTypeOptions() {
      const arr = [];
      Object.keys(PURPOSES_TYPE).forEach((type) => {
        arr.push(PURPOSES_TYPE[type]);
      });
      return arr;
    },
    effortTypeOptions() {
      const arr = [];
      Object.keys(EFFORT_TYPE).forEach((type) => {
        arr.push(EFFORT_TYPE[type]);
      });
      return arr;
    },
    frameTypeOptions() {
      const arr = [];
      Object.keys(FRAME_TYPE).forEach((type) => {
        arr.push(FRAME_TYPE[type]);
      });
      return arr;
    },
    lifeSpanTypeOptions() {
      const arr = [];
      Object.keys(LIFE_SPAN_TYPE).forEach((type) => {
        arr.push(LIFE_SPAN_TYPE[type]);
      });
      return arr;
    },
    level() {
      if (this.purpose !== PURPOSES_TYPE.complex.value) return 'level3';
      return this.lifeSpan;
    },
    lifeAge() {
      if (this.purpose === PURPOSES_TYPE.complex.value) {
        switch (this.lifeSpan) {
          case LIFE_SPAN_TYPE.complex30.value:
            return LIFE_SPANS_AGE.complex30;
          case LIFE_SPAN_TYPE.complex60.value:
            return LIFE_SPANS_AGE.complex60;
          case LIFE_SPAN_TYPE.complex90.value:
            return LIFE_SPANS_AGE.complex90;
          default:
            return LIFE_SPANS_AGE.complex30;
        }
      } else return LIFE_SPANS_AGE[this.purpose];
    },
    co2Value() {
      const frameIndex = FRAME_TYPE[this.frame].index;
      const levelIndex = frameIndex * Object.keys(EFFORT_TYPE).length + LEVEL[this.level];
      const purposeData = CO2_DATA[this.purpose][this.effort][levelIndex];
      return purposeData;
    },
    co2ValueForB() {
      const levelIndex = LEVEL[this.level];
      const purposeData = CO2_DATA[this.purpose][this.effort][levelIndex];
      return purposeData;
    },
    co2DiscountValue() {
      const lineData = CO2_DISCOUNT_DATA[this.purpose];
      const data = {};
      Object.keys(lineData).forEach((key) => {
        const frameIndex = FRAME_TYPE[this.frame].index;
        data[key] = lineData[key][frameIndex];
      });
      return data;
    },
    total() {
      return evaluate(`(${this.totalA} + ${this.totalB}) / 1000`);
    },
    totalA() {
      const ratioValue = evaluate(`1 - ${this.tValue}`);
      const aValue = evaluate(`${this.co2Value} * ${ratioValue}`);

      let bSum = bignumber(0);
      if (this.isEnableSources) {
        Object.keys(ENVIRONMENTAL_IMPACT).forEach((key) => {
          if(this.isEnableOrdinaryConcrete && key === 'blastFurnaceCementConcrete') {
            return
          }
          
          if(this.isEnableBlastFurnaceCementConcrete && key === 'ordinaryConcrete') {
            return
          }

          let keyVal = bignumber(0);
          keyVal = evaluate(`(${this[key]} - ${ENVIRONMENTAL_IMPACT[key]}) * ${this.co2DiscountValue[key]}`);
          if (['steelFrame', 'rebar'].includes(key)) keyVal = evaluate(`${keyVal} * 1000`);
          bSum = evaluate(`${bSum} + ${keyVal || 0}`);
        });
      }
      // cr: remove (1-T) on selected wood
      if (this.isSelectedWood) return evaluate(`${bSum} / ${this.lifeAge}`);
      return evaluate(`${aValue} + (${bSum} / ${this.lifeAge}) * ${ratioValue}`);
    },
    tValue() {
      return this.isEnableFrameRatio ? evaluate(`${this.ratio} / 100`) : 1;
    },
    /**
     * B = N * T
     */
    totalB() {
      return evaluate(`${this.co2ValueForB} * ${this.tValue}`);
    },
    sourceResult() {
      const frameText = this.isEnableFrameRatio ? `${this.ratio}%` : '';
      return {
        total: ceil(bignumber(this.total), 4),
        source: `CASBEE 建築(新築) カテゴリ1 ${PURPOSES_TYPE[this.purpose].name} ${
          EFFORT_TYPE[this.effort].name
        } 木造${frameText}`,
        label: '削減努力反映後CO2排出原単位（全体）',
        labelSource: null,
        patternType: CASBEE_TYPE.ARCHITECTURE,
        content_json: this.contentJson,
      };
    },
    isValidData() {
      return !(Object.values(this.inputErrors).indexOf(true) > -1);
    },
    contentJson() {
      return {
        purpose: this.purpose,
        effort: this.effort,
        frame: this.frame,
        lifeSpan: this.lifeSpan,
        discount: this.discount,
        ratio: this.ratio,
        ordinaryConcrete: this.ordinaryConcrete,
        blastFurnaceCementConcrete: this.blastFurnaceCementConcrete,
        steelFrame: this.steelFrame,
        rebar: this.rebar,
      };
    },
    isViewMode() {
      return !isEmpty(this.detailData);
    },
  },
  mounted() {
    this.prepareDetailData();
  },
  methods: {
    calculateTotal() {
      this.validateInputDigit();
      if (this.isValidData) {
        this.$emit('onHandlerCalculationData', this.sourceResult);
      } else {
        Object.keys(this.inputErrors).forEach((key) => {
          this[key] < 0 ? (this[key] = null) : this[key];
        });
        return;
      }
    },
    validateInputDigit() {
      Object.keys(this.inputErrors).forEach((key) => {
        const isError = Boolean(validateInputFieldCasbee(this[key]));
        this.inputErrors[key] = isError;
        this.errorMessage[key] = validateInputFieldCasbee(this[key]);
        if (isError) this[key] = null;
      });
    },
    prepareDetailData() {
      if (this.detailData?.dataDetail?.hasOwnProperty('content_json')) {
        const preData = this.detailData.dataDetail.content_json;
        Object.keys(preData).forEach((key) => {
          this[key] = preData[key];
        });
        this.calculateTotal();
      }
    },
  },
  watch: {
    isEnableSources() {
      if (this.isViewMode) return;
      //reset source
      this.ordinaryConcrete = 0;
      this.blastFurnaceCementConcrete = 0;
      this.steelFrame = 0;
      this.rebar = 0;
      //reset error
      this.inputErrors.ordinaryConcrete = false;
      this.inputErrors.blastFurnaceCementConcrete = false;
      this.inputErrors.steelFrame = false;
      this.inputErrors.rebar = false;
    },
    isEnableOrdinaryConcrete() {
      if (this.isViewMode) return;
      this.ordinaryConcrete = 0;
      this.inputErrors.ordinaryConcrete = false;
    },
    isEnableBlastFurnaceCementConcrete() {
      if (this.isViewMode) return;
      this.blastFurnaceCementConcrete = 0;
      this.inputErrors.blastFurnaceCementConcrete = false;
    },
    isEnableFrameRatio() {
      if (this.isViewMode) return;
      this.ratio = 0;
      this.inputErrors.ratio = false;
    },
    isEnableLifeSpan() {
      if (this.isViewMode) return;
      this.lifeSpan = LIFE_SPAN_TYPE.complex30.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.arc-cat-one {
  margin-bottom: 48px;
  .selection-group {
    display: flex;
    gap: 48px;
    .selection-item {
      .pulldown {
        margin-top: 12px;
      }
    }
  }
  .ratio-group {
    margin-top: 48px;
    .ratio-input {
      margin-top: 12px;
    }
  }
  .source-group {
    margin-top: 48px;
    .source-inputs {
      display: flex;
      gap: 40px;
      margin-top: 20px;
      .source-item {
        .item-input {
          margin-top: 12px;
        }
      }
    }
  }
  .action-group {
    margin-top: 48px;
    .action-calcucalte {
      width: 240px;
      height: 56px !important;
    }
  }
  .selection-group,
  .source-group,
  .ratio-group,
  .action-group {
    .label {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
    .label-sub {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: $monoDark;
    }
    .label-des {
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
      color: $monoBlack;
      &.bolder {
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1023px) {
  .arc-cat-one {
    .selection-group {
      flex-direction: column;
      gap: 48px;
      .selection-item {
        .pulldown {
          margin-top: 8px;
        }
      }
    }
    .ratio-group {
      .ratio-input {
        margin-top: 8px;
      }
    }
    .source-group {
      .source-inputs {
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
        .source-item {
          .item-input {
            margin-top: 8px;
          }
        }
      }
    }
    .action-group {
      .action-calcucalte {
        width: 100%;
      }
    }
    .selection-group,
    .source-group,
    .ratio-group,
    .action-group {
      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      .label-sub {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $monoDark;
      }
      .label-des {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        margin-top: 8px;
        color: $monoBlack;
        &.bolder {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
