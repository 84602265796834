<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      :persistent="true"
      ref="dialog"
      max-width="464px"
      content-class="new-raido-popup"
    >
    <v-card>
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="radioGroup" hide-details>
          <v-list-item tabindex="1" v-for="item in items" :key="item.id" @keyup.13="selectMethod(item)" @keyup.32="selectMethod(item)" :class="{'show-dot': isAtiveMethods(item.value, item.text) }">
            <v-list-item-action>
              <v-radio :ripple="false" :label="item.name" :value="item.id" color="success" class="">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="sub-title">{{ item.name }}</div>
                    <div v-if="showDescription" class="sub-description">{{ item.description }}</div>
                  </div>
                </template>
              </v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <common-button tabindex="2" @action="close" label="يلغي"></common-button>
        <common-button tabindex="3" @action="submit" label="يختار" type="colored"></common-button>
      </v-card-actions>
    </v-card>
      
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    radioType: {
      type: String,
      default: 'Scopes',
    },
    items: {
      type: Array,
      default: () => [],
    },
    method: {
      type: Number,
      default: null,
    },
    currentSelect: {
      type: Number,
      default: null,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    listMethodsAtive: {
      type: Array,
      default:()=>[]
    },
    methodListLayer2: {
      type: Array,
      default:()=>[]
    },
    scope: {
      type: Number,
      default: null,
    },
    category: {
      type: Number,
      default: null,
    }
  },
  computed: {
    radioGroup: {
      get() {
        return this.currentSelect;
      },
      set(val) {
        this.$emit('update:currentSelect', val);
      },
    },
    title: {
      get() {
        if (this.radioType == 'Scopes') {
          return 'スコープを選択';
        }
        return 'اختر فئة';
      },
    },
  },

  methods: {
    isAtiveMethods(methods, text){
      if(this.scope === 3 && this.category === 4){
        const methods1 = this.listMethodsAtive.filter(item => [4,5,6].includes(item));
        const methods2 = this.listMethodsAtive.filter(item =>[7,8].includes(item));
        if(methods1.length) {
          if(methods === 1 || methods1.includes(methods)) {
            return true;
          }
        }

        if(methods2.length) {
          if(text === '拠点' || methods2.includes(methods)) {
            return true;
          }
        }

        if(this.listMethodsAtive.includes(2) ) {
          if(text === '荷主責任報告値を用いる方法') {
            return true
          }
        };
      };
      if(this.scope === 3 && this.category === 9) {
        if(this.listMethodsAtive.includes(1) ) {
          if(text === '燃料法・配送料金で算定' || text === '輸送') {
            return true;
          }
        }
        if(this.listMethodsAtive.includes(2) ) {
          if(text === 'tkm法で算定' || text === '輸送') {
            return true;
          }
        }
        if(this.listMethodsAtive.includes(3) ) {
          if(text === '燃費法で算定' || text === '輸送') {
            return true;
          }
        }

        const methods2 = this.listMethodsAtive.filter(item =>[4,5].includes(item));
        if(methods2.length) {
          if(methods === 2 || methods2.includes(methods)) {
            return true;
          }
        }
      };
      if(this.scope === 3 && this.category === 11) {
        if(this.listMethodsAtive.includes(1) ) {
          if(text === 'エネルギー使用製品' || text === '直接使用段階の排出量から算定') {
            return true;
          }
        }
        if(this.listMethodsAtive.includes(2) ) {
          if(text === '燃料・フィードストック' || text === '直接使用段階の排出量から算定') {
            return true;
          }
        }
        if(this.listMethodsAtive.includes(3) ) {
          if(text === 'GHG含有製品で、使用時にGHGを排出するもの' || text === '直接使用段階の排出量から算定') {
            return true;
          }
        }

        if(methods === 2 && this.listMethodsAtive.includes(4)){
            return true
        };
    };
    if(this.methodListLayer2.length === 0 && !([11,9,4].includes(this.category))){
      return this.listMethodsAtive.includes(methods)
    }
    },
    selectMethod(method){
      this.radioGroup = method.value
    },
    close() {
      this.$emit('close');
      this.radioGroup = this.method;
    },
    handleClickOutSite() {
      this.$emit('close');
      this.radioGroup = this.method;
    },
    submit() {
      this.$emit('onChange', this.radioGroup);
      this.$emit('submit');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
  .d-flex  {
    &.flex-column {
      width: 100%;
      direction: rtl;
      align-items: flex-start;
    }
  }
</style>
