<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <span>拠点管理</span>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div v-if="showTooltip" class="helper-icon tooltip" v-html="'サプライチェーン排出量の対象とする拠点を管理します。'"></div>
          </div>
        </div>
      </div>
      <div class="wrap__status">
        <div class="wrap__status__item">
          <img src="@/assets/icons/clock.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Last Update</p>
            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
          </div>
        </div>
        <div class="wrap__status__item">
          <img src="@/assets/icons/userTable.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Updated By</p>
            <p>{{ statusUpdate.user || "" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table facility-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        v-show="isGetLayout"
        :data-source="branchList"
        :init-grid="initializeGrid"
        :grid-columns="gridColumns"
        :totalData="totalData"
        :rowFocus="2"
        :isHasData="isHasData"
        :cellInput="cellInput"
        @changeFullScreen="onChangeFullScreen"
        @onHandleActionTable="checkValidationData"
      />
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="dialogMessage"
      @submit="() => checkboxHandler(false)"
      @close="closePopup"
    />
    <error-popup :dialog="dialogErrorPopup" :message="errorMessage" @submit="handleCloseDialogError" />
    <QuestionPopup :dialog="isShowUsedFacilityPopup" :message="usedFacility" @close="handleClosePopupUsedFacility" @submit="handleSubmitFacility" />
    <notification-popup :dialog="dialogNotification" :message="'変更内容を保存しました。'" @submit="dialogNotification = false" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  getListBranchApi,
  updateBranchApi,
  createBranchApi,
  updateBranchLayerApi,
  getListOrganizationalApi,
  updateBranchAttributeApi
} from "@/api/facility";
import { Tooltip, PopupPosition, CollectionView } from "@grapecity/wijmo";
import * as wjGrid from "@grapecity/wijmo.grid";
import * as wjcCore from "@grapecity/wijmo";
import { AutoComplete, ComboBox } from "@grapecity/wijmo.input";
import DialogPopup from "@/components/dialogs/question-popup";
import ErrorPopup from "@/components/dialogs/error-popup.vue";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { validateMaximumCharactor, validateBlankRowData } from "@/utils/validate";
import { formatDateTime } from "@/utils/datetimeFormat";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import debounce from "lodash/debounce";
import { UndoStack } from "@grapecity/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { batchReplacementViewCollection, emptyPromise } from "@/concerns/registerData/wijmo.helper";
import QuestionPopup from '@/components/dialogs/question-popup.vue';

export default {
  data() {
    return {
      items               : [
        {
          text    : "ホーム",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "設定",
          disabled: false,
          href    : ROUTES.SETTING
        },
        {
          text    : "拠点管理",
          disabled: true,
          href    : ROUTES.FACILITY_MANAGEMENT
        }
      ],
      flex                : null,
      gridColumns         : [],
      dialogPopup         : false,
      dialogMessage       : "この拠点・事業を算定対象から外します。\n よろしいですか？",
      dialogCancelBtn     : false,
      check_status        : null,
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      branchList          : null,
      branchData          : [],
      listOrganizational  : [],
      idsUpdate           : [],
      dataRedo            : [],
      statusUpdate        : {
        time: null,
        user: null
      },
      totalData           : null,
      restoreSelection    : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      showTooltip         : false,
      listLayers          : [],
      layersEnable        : [],
      layerObject         : null,
      isGetLayout         : false,
      isEdit              : false,
      temp                : null,
      text                : null,
      currentlyCol        : {},
      limitBranch         : null,
      layersNotVisible    : [],
      dialogErrorPopup    : false,
      errorMessage        : "",
      listOriginalData    : [],
      dialogNotification: false,
      cellInput: [
        'organizational_division',
        'business_name',
        'company_name',
        'country',
        'layer_3',
        'layer_4',
        'layer_5',
        'layer_6',
      ],
      celEnabel: [],
      isShowUsedFacilityPopup: false,
      submitDataError: {},
      usedFacility: `この拠点を算定対象から外すと、一時保存/承認プロセス中/テンプレート保存/されている登録データが使えなくなったり、承認されている登録データが確認できなくなります。本当にこの拠点を算定対象から外しますか？`,
      listOriginData: []
    };
  },

  components: {
    DataTable,
    DialogPopup,
    ErrorPopup,
    NotificationPopup,
    QuestionPopup
  },

  computed: {
    ...mapState("userData", ["currentUser"]),
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand", "statusAction"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("actionsTable", ["dataAction"]),
    ...mapState("registerData", ["isFullScreen"]),

    isHasData() {
      return this.canUndo || this.canRedo;
    },
  },

  async mounted() {
    this.updateBreadCrumb(this.items);
    this.gridColumns                    = this.getGridColumns();
    const listOrganizationalApiResponse = await getListOrganizationalApi();
    this.getListOrganization(listOrganizationalApiResponse.data);
    this.isGetLayout = false;
    this.getBranchList();
    this.createUndoStack();
    this.celEnabel = ['organizational_division', 'company_name']
  },

  watch: {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    }
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "updateStatusAction"]),
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen"]),
    getWidthByText(string) {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width      = "auto";
      text.style.position   = "absolute";
      text.style.whiteSpace = "no-wrap";
      text.style.font       = "Source Han Sans";
      text.style.fontSize   = 13 + "px";
      text.innerHTML        = string;
      const width           = text.clientWidth;
      document.body.removeChild(text);
      return width + 30 + 24 + 34 + 24; // width of button in header
    },
    handleRowActionType({
                          grid,
                          attribute,
                          layer,
                          showHideRow
                          // editRow,
                        }) {
      grid.hostElement.addEventListener("click", (e) => {
        let action    = e.target.getAttribute(attribute);
        let ht        = grid.hitTest(e);
        const payload = {
          contractor_id: layer.contractor_id,
          status       : layer.status,
          id           : layer.id,
          layer_name   : layer.layer_name?.length > 255 ? layer.layer_name.slice(0, 255) : layer.layer_name,
          layer_index  : layer.layer_index
        };

        if (
          (!action && ht.panel.cellType === wjGrid.CellType.Cell && this.isEdit) ||
          (!action && ht.panel.cellType === wjGrid.CellType.RowHeader && this.isEdit)
        ) {
          this.branchList.beginUpdate();
          if (attribute === this.currentlyCol?.layer_index && !(this.text === null || this.text.match(/^ *$/) !== null)) {
            grid.columns[this.temp].header = this.text;
            grid.columns[this.temp].width  = this.getWidthByText(this.text);

            const index = this.listLayers.findIndex(item => item.id === this.layerObject.id);

            this.listLayers[index].layer_name = this.text;

            this.updateBranchLayer({
              ...this.layerObject,
              layer_name: this.text,
              status    : this.layerObject.status ? 1 : 0
            });
            this.text = null;
          }
          this.branchList.endUpdate();
        } else if (!action) {
          return;
        }

        if (ht.panel.cellType == wjGrid.CellType.ColumnHeader && action === "editRow") {
          let htCol         = ht.col;
          let cell          = grid.columnHeaders.getCellElement(ht.row, ht.col);
          this.currentlyCol = payload;
          this.temp         = ht.col;
          this.isEdit       = true;
          this.layerObject  = payload;
          grid.columns[ht.col].edit = true,

            grid.refresh();
          let inputEle = cell.querySelector("input");
          if (inputEle) {
            inputEle.focus();
            inputEle.addEventListener("blur", (e) => {
              grid.columns[ht.col].header = inputEle.value;

              if (!/^ *$/.test(inputEle.value)) {
                const newVal = inputEle.value?.length > 255 ? inputEle.value.slice(0, 255) : inputEle.value;
                this.updateBranchLayer({
                  ...payload,
                  layer_name: newVal,
                  status    : payload.status ? 1 : 0
                });
                grid.columns[htCol].width         = this.getWidthByText(newVal);
                grid.columns[htCol].header        = newVal;
                const index                       = this.listLayers.findIndex(item => item.id === payload.id);
                this.listLayers[index].layer_name = newVal;
                const item                        = this.listLayers.find(item => item.id === payload.id && item.layerInvisible);
                if (item) {
                  const existIndex                             = this.layersNotVisible.findIndex(item => item.id === payload.id);
                  this.layersNotVisible[existIndex].layer_name = newVal;
                }
              } else {
                const index                = this.listLayers.findIndex(item => item.id === payload.id);
                grid.columns[htCol].header = this.listLayers[index].layer_name;
                grid.columns[htCol].width  = this.getWidthByText(this.listLayers[index].layer_name);
              }

              grid.columns[htCol].edit = false;
              grid.refresh();
            });
          }
        } else if (action === "showHideRow") {
          var data = grid.rows[ht.row].dataItem;
          this.updateBranchLayer({
            ...payload,
            status: payload.status ? 0 : 1
          });
          showHideRow && showHideRow(data, payload);
        }
      });
    },
    handleRowActionTypeLayerNotVisible({
                                         grid,
                                         attribute,
                                         layer,
                                         showHideRowLayerNotVisible
                                         // editRow,
                                       }) {
      grid.hostElement.addEventListener("click", (e) => {
        let action    = e.target.getAttribute(attribute);
        let ht        = grid.hitTest(e);
        const payload = {
          contractor_id: layer.contractor_id,
          status       : layer.status,
          id           : layer.id,
          layer_name   : layer.layer_name?.length > 255 ? layer.layer_name.slice(0, 255) : layer.layer_name,
          layer_index  : layer.layer_index
        };

        if (
          (!action && ht.panel.cellType === wjGrid.CellType.Cell && this.isEdit) ||
          (!action && ht.panel.cellType === wjGrid.CellType.RowHeader && this.isEdit)
        ) {
          this.branchList.beginUpdate();
          if (attribute === this.currentlyCol?.layer_index && !(this.text === null || this.text.match(/^ *$/) !== null)) {
            grid.columns[this.temp].header = this.text;
            grid.columns[this.temp].width  = this.getWidthByText(this.text);

            const index = this.layersNotVisible.findIndex(item => item.id === this.layerObject.id);

            this.layersNotVisible[index].layer_name = this.text;
            this.updateBranchLayer({
              ...this.layerObject,
              layer_name: this.text,
              status    : this.layerObject.status ? 1 : 0
            });
            this.text = null;
          }
          this.branchList.endUpdate();
        } else if (!action) {
          return;
        }

        if (ht.panel.cellType == wjGrid.CellType.ColumnHeader && action === "editRow") {
          let htCol         = ht.col;
          let cell          = grid.columnHeaders.getCellElement(ht.row, ht.col);
          this.currentlyCol = payload;
          this.temp         = ht.col;
          this.isEdit       = true;
          this.layerObject  = payload;
          grid.columns[ht.col].edit = true,
            grid.refresh();
          let inputEle = cell.querySelector("input");
          if (inputEle) {
            inputEle.focus();
            inputEle.addEventListener("blur", (e) => {
              grid.columns[ht.col].header = inputEle.value;

              if (!/^ *$/.test(inputEle.value)) {
                const newVal = inputEle.value?.length > 255 ? inputEle.value.slice(0, 255) : inputEle.value;
                this.updateBranchLayer({
                  ...payload,
                  layer_name: newVal,
                  status    : payload.status ? 1 : 0
                });
                grid.columns[htCol].width         = this.getWidthByText(newVal);
                grid.columns[htCol].header        = newVal;
                const index                       = this.listLayers.findIndex(item => item.id === payload.id);
                this.listLayers[index].layer_name = newVal;
                const item                        = this.listLayers.find(item => item.id === payload.id && item.layerInvisible);
                if (item) {
                  const existIndex                             = this.layersNotVisible.findIndex(item => item.id === payload.id);
                  this.layersNotVisible[existIndex].layer_name = newVal;
                }
              } else {
                const index                = this.listLayers.findIndex(item => item.id === payload.id);
                grid.columns[htCol].header = this.listLayers[index].layer_name;
                grid.columns[htCol].width  = this.getWidthByText(this.listLayers[index].layer_name);
              }
              grid.columns[htCol].edit = false;
              grid.refresh();
            });
          }
        } else if (action === "showHideRow") {
          var data = grid.rows[ht.row].dataItem;
          this.updateBranchLayer({
            ...payload,
            status: payload.status ? 0 : 1
          });
          showHideRowLayerNotVisible && showHideRowLayerNotVisible(data, payload);
        }
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      flexgrid.scrollPositionChanged.addHandler(
        debounce((s, e) => {
          if (!this.$store.state.registerData.isFullScreen) {
            return;
          }

          if (s.viewRange.bottomRow >= s.rows.length - 1) {
            s.deferUpdate(() => {
              const lastClientId = flexgrid.itemsSource.itemCount;

              for (let index = 1; index <= 100; index++) {
                s.itemsSource.addNew(this.blankData(lastClientId + index));
              }

              s.itemsSource.commitNew();
              s.itemsSource.clearChanges();
            });
          }
        }, 100)
      );
      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 2);
              flexgrid.selection     = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (flexgrid.selection.row === flexgrid.rows.length - 1) {
              const lastClientId = flexgrid.itemsSource.itemCount;

              flexgrid.deferUpdate(() => {
                flexgrid.itemsSource.addNew(this.blankData(lastClientId + 1));
                flexgrid.itemsSource.commitNew();
                flexgrid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );

      flexgrid?.cellEditEnded.addHandler((s, e) => {
        let oldValue = e.data;
        let newValue = s.getCellData(e.row, e.col);

        if (oldValue !== newValue) {
          flexgrid?.autoSizeRows(0, 0, true);
          flexgrid?.autoSizeColumns();
        }

        const { row } = e.range;
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};
        if(this.isItemActive(currentItem)) {
          currentItem.status = true
        }

        if (
          s.columns[e.col].binding !== "organizational_division" ||
          s.columns[e.col].binding !== "organizational_scope_name"
        ) {
          return;
        }
      });

      flexgrid?.beginningEdit.addHandler((s, e) => {
        let column        = s.columns[e.col];
        let rowValue      = s.rows[e.row]._data;
        this.check_status = rowValue;
        if (column.cssClass?.includes("auto-increment") || column.binding === "add_column") {
          e.cancel = true;
        }
        if (column.binding !== "status") {
          return;
        }

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        this.handleCheckBox(rowValue);
        e.cancel = true;
      });

      flexgrid?.formatItem.addHandler((s, e) => {
        const colBinding             = e.panel.columns[e.col].binding;
        const keyBinding             = this.listLayers.map((layer) => layer.binding);
        const layersInvisibleBinding = this.layersNotVisible.map((layer) => layer.binding);
        const arr                    = [
          "company_name",
          "business_name",
          "name",
          "organizational_division",
          "country",
          ...keyBinding,
          ...layersInvisibleBinding,
          "status",
          "add_column"
        ];
        if (e.panel == s.columnHeaders) {
          if (!arr.includes(colBinding)) {
            return null;
          }
          if (colBinding === "company_name") {
            toolTipCustom(e, "t1", "GHG排出量の算定対象とする法人名を登録します。", this.hdrTips);
          }
          if (colBinding === "organizational_division") {
            toolTipCustom(
              e,
              "t3",
              "契約者（自社）から見た資本関係（単体、子会社、関連会社、その他関連会社）を登録します。",
              this.hdrTips
            );
          }
          if (colBinding === "status") {
            toolTipCustom(
              e,
              "t10",
              "リストとして登録した拠点・事業について、GHG排出量の算定対象に含めるかを指定します。",
              this.hdrTips
            );
          }
          if (colBinding === "add_column") {
            e.cell.innerHTML = "<div class='add-layer'></div>" + e.cell.innerHTML;
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-filter"></span>', '');
          }
          this.listLayers.map((layer) => {
            if (colBinding === layer.binding && !layer?.layerInvisible) {
              const findIsEnableRow = this.layersEnable.find((f) => f.id === layer.id);
              toolTipCustom(
              e,
              "t5",
              "",
              this.hdrTips
            );
              e.cell.innerHTML =
              `<div class="icon-right">
                <img class="edit-layer-name" src="${require("@/assets/icons/facility/editLayerName.svg")}" ${
                  layer.layer_index
                }="editRow" />
                <img class="switch-layer" src="${require(`@/assets/icons/facility/${
                  findIsEnableRow?.status ? "eyeOn.svg" : "eyeOff.svg"
                }`)}" ${layer.layer_index}="showHideRow"/>
              </div>${e.cell.innerHTML}`
            }
          });
          this.layersNotVisible.map((layer) => {
            if (colBinding === layer.binding) {
              toolTipCustom(
              e,
              "t5",
              "",
              this.hdrTips
            );
              e.cell.innerHTML =
              `<div class="icon-right">
                <img class="edit-layer-name" src="${require("@/assets/icons/facility/editLayerName.svg")}" ${
                  layer.layer_index
                }="editRow" />
                <img class="switch-layer" src="${require(`@/assets/icons/facility/${
                  layer?.status ? "eyeOn.svg" : "eyeOff.svg"
                }`)}" ${layer.layer_index}="showHideRow"/>
              </div>${e.cell.innerHTML}`
            }
          });

          if (e.panel !== s.columnHeaders) {
            return;
          }
          if (flexgrid.columns[e.col].edit) {
            let col           = e.panel.columns[e.col];
            let c             = e.col;
            s.columns[c].edit = false;
            let element       = document.createElement("div");
            element.setAttribute(
              "style",
              `width: 100%`
            );
            let comboBox = new ComboBox(element);

            comboBox.text = col.header;
            comboBox.textChanged.addHandler((s) => {
              this.text = comboBox._oldText?.length > 255 ? comboBox._oldText.slice(0, 255) : comboBox._oldText;
            });
            e.cell.innerHTML = "";
            e.cell.appendChild(comboBox.hostElement);
            wjcCore.addClass(e.cell, "my-header-cell");
          }
        }
      });

      flexgrid.hostElement.addEventListener("click", (e) => {
        if (e.target.className === "add-layer" && this.limitBranch > this.listLayers.length && this.statusAction) {
          this.updateStatusAction(false);
          let attributeBranchList = [];
          const layerIndex        = this.listLayers.length + 1;
          const attributeBranch   = {
            contractor_id: this.currentUser.user.contractor_id,
            layer_name   : `階層${layerIndex}`,
            layer_index  : layerIndex,
            status       : "1"
          };
          attributeBranchList.push(attributeBranch);
          updateBranchAttributeApi({ data: Object.values(attributeBranchList) }).then((res) => {
            const recordInsert = res.record_insert[0];
            let binding        = `layer_${layerIndex}`;
            if (layerIndex === 1) {
              binding = "business_name";
            } else if (layerIndex === 2) {
              binding = "country";
            }
            this.listLayers.push({
              ...recordInsert,
              cssClass      : "",
              isReadOnly    : false,
              binding       : binding,
              layerInvisible: true
            });
            this.layersEnable.push({
              id    : recordInsert.id,
              status: recordInsert.status
            });
            flexgrid.columns.getColumn(binding).visible = true;
            const getIndex                              = this.layersNotVisible.findIndex((layer) => layer.binding === binding);
            if (getIndex >= 0) {
              this.layersNotVisible[getIndex].id = recordInsert.id;
            }
          }).catch((error) => {
            console.log(error);
          });
        }
        if (e.target.className === "add-layer" && this.limitBranch <= this.listLayers.length) {
          this.dialogErrorPopup = true;
          this.errorMessage = "これ以上階層を追加できません。";
        }
      });
      flexgrid.pasted.addHandler((s, e) => {
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);

        const { row, row2 } = e.range;
        const dataPasted = s.collectionView.sourceCollection.filter((item, index) => index >= row && index <= row2);
        dataPasted.forEach(rowItem => {
          if(this.isItemActive(rowItem)) {
            rowItem.status = true
          }
        })
      });
      flexgrid.autoSizeColumns();
    },

    onEditRow(data, layer) {
      this.gridColumns = this.getGridColumns();
    },

    onShowHideRow(data, layer) {
      const index                       = this.listLayers.findIndex(item => item.id === layer.id);
      this.listLayers[index].cssClass   = layer.status ? "auto-increment" : "";
      this.listLayers[index].status     = !layer.status;
      this.listLayers[index].isReadOnly = layer.status;
      this.layersEnable                 = this.layersEnable.map((currentLayer) => {
        if (layer.id === currentLayer.id) {
          return {
            ...currentLayer,
            status: !currentLayer.status
          };
        }
        return currentLayer;
      });
      this.branchList.beginUpdate();
      const headerIndex                         = this.flex.columns.findIndex(item => item.index === layer.layer_index + 3);
      this.flex.columns[headerIndex].isReadOnly = layer.status;
      this.flex.columns[headerIndex].cssClass   = layer.status ? "auto-increment" : "";
      this.branchList.endUpdate();
    },

    onEditRowLayerNotVisible(data, layer) {
      this.gridColumns = this.getGridColumns();
    },

    onShowHideRowLayerNotVisible(data, layer) {
      const index                             = this.layersNotVisible.findIndex(item => item.id === layer.id);
      this.layersNotVisible[index].cssClass   = layer.status ? "auto-increment" : "";
      this.layersNotVisible[index].status     = !layer.status;
      this.layersNotVisible[index].isReadOnly = layer.status;
      this.branchList.beginUpdate();
      const headerIndex                         = this.flex.columns.findIndex(item => item.index === layer.layer_index + 3);
      this.flex.columns[headerIndex].isReadOnly = layer.status;
      this.flex.columns[headerIndex].cssClass   = layer.status ? "auto-increment" : "";
      this.branchList.endUpdate();
    },

    async updateBranchLayer(item) {
      try {
        const data        = [].concat(item);
        let res           = await updateBranchLayerApi({ data });
        this.statusUpdate = {
          time: res.latest_record.updated_at_latest,
          user: res.latest_record.user_updated
        };
      } catch (err) {
        console.log("[UPDATE ERR:]", { ...err });
      }
    },

    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },

    emptyCell(item) {
      if (item === undefined || item === null) {
        return "هذا إلزامي.";
      }
      return null;
    },

    getError(item, propName) {
      if (validateBlankRowData(item, ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6'])) {
        return null;
      }
      switch (propName) {
        case "organizational_division":
          return this.emptyCell(item[propName]);
        case "company_name":
          return validateMaximumCharactor(item[propName], 128);
        case "country":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
        case "business_name":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
        case "layer_3":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
        case "layer_4":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
        case "layer_5":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
        case "layer_6":
          return item[propName] && validateMaximumCharactor(item[propName], 128);
      }
      return null;
    },

    getGridColumns() {
      const mapLayers    = this.listLayers.map((layer) => ({
        header      : layer.layer_name,
        binding     : layer.binding,
        minWidth    : 217,
        maxWidth    : 980,
        allowSorting: false,
        isRequired  : false,
        cssClass    : layer.cssClass,
        isReadOnly  : layer.isReadOnly,
        wordWrap    : true,
        cssClassAll : "single-row"
      }));
      const hiddenLayers = this.layersNotVisible.map((layer) => ({
        header      : layer.layer_name,
        binding     : layer.binding,
        minWidth    : 217,
        maxWidth    : 980,
        allowSorting: false,
        isRequired  : false,
        cssClass    : layer.cssClass,
        isReadOnly  : layer.isReadOnly,
        visible     : layer.visible,
        wordWrap    : true,
        cssClassAll : "single-row"
      }));
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "契約者名",
          binding     : "contractor_name",
          allowSorting: false,
          isRequired  : false,
          visible     : false
        },
        {
          header      : "組織区分",
          binding     : "organizational_division",
          minWidth    : 130,
          maxWidth    : 217,
          allowSorting: false,
          dataMap     : new wjGrid.DataMap(this.listOrganizational, "id", "value"),
          isRequired  : false,
          editor      : new AutoComplete(document.createElement("div"), {
            itemsSource      : this.listOrganizational,
            selectedValuePath: "id",
            displayMemberPath: "value"
          }),
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : "法人名",
          binding     : "company_name",
          minWidth    : 515,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        ...mapLayers,
        ...hiddenLayers,
        {
          header: " ",
          binding: 'add_column',
          minWidth    : 160,
          maxWidth    : 217,
          allowSorting: false,
          cssClass : "auto-increment"
        },
        {
          header      : "算定対象に含む",
          binding     : "status",
          minWidth    : 160,
          maxWidth    : 217,
          allowSorting: false,
          dataType    : "Boolean",
          wordWrap    : true,
          cssClassAll : "single-row"
        }
      ];
    },
    handleCheckBox(value) {
      if (value?.status) {
        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },

    handleCloseDialogError() {
      try {
        this.dialogErrorPopup = false;
        getListBranchApi({
          contractor_id: this.contractorId
        }).then((response) => {
          this.undoStack.clear();
          this.branchList = new CollectionView([...response.data], {
            trackChanges: true,
            getError    : this.getError
          });
          this.addBlankItemsToView(10);
          this.actionUpdateIsFullScreen(false);
          this.branchList.moveCurrentToPosition(-1);
        });
      } catch (error) {
        this.dialogErrorPopup = true;
      }
    },

    checkboxHandler(check) {
      if (this.check_status.id === undefined || !this.check_status.organizational_division || !this.check_status.company_name) {
        return;
      }
      this.branchList.beginUpdate();
      this.branchList.currentItem.status = check;
      this.branchList.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
      this.closePopup();
    },

    closePopup() {
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },

    getListOrganization(data) {
      data.forEach((item) => {
        this.listOrganizational.push({
          id   : item.id,
          value: item.type
        });
      });
    },

    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },

    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    blankData(clientRowId) {
      return {
        clientRowId            : clientRowId,
        id                     : BLANK_ID,
        company_name           : null,
        business_name          : null,
        layer_3                : null,
        layer_4                : null,
        layer_5                : null,
        layer_6                : null,
        organizational_division: null,
        country                : null,
        contractor_id          : null,
        status                 : null
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = this.branchList.itemCount;
      for (let index = 1; index <= count; index++) {
        this.branchList.addNew(this.blankData(lastClientId + index));
      }

      this.branchList.commitNew();
      this.branchList.clearChanges();
    },
    scrollToTop() {
      if (!this.flex) {
        return;
      }

      let rc                   = this.flex.cells.getCellBoundingRect(0, 0, true);
      this.flex.scrollPosition = new wjcCore.Point(this.flex.scrollPosition.x, -rc.top);
    },
    initialView() {
      if (this.branchList) {
        this.branchData = this.branchList.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.branchList       = new CollectionView([...this.branchData], {
        trackChanges: true
      });
      this.addBlankItemsToView(10);
      this.listOriginalData = JSON.parse(JSON.stringify(this.branchList.items));

      this.flex.columnGroups = this.gridColumns;
    },
    // TODO
    isValidFacility(item) {
      return !(!item?.company_name ||
        !item?.organizational_division ||
        item?.company_name?.length > 128 ||
        item?.country?.length > 128 ||
        item?.business_name?.length > 128);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.addBlankItemsToView(100);
        this.scrollToTop();
      } else {
        // this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;

      if (!flexgrid) {
        return;
      }
      this.canUndo = false;
      this.canRedo = false;

      const stateChanged = (e) => {
        this.canUndo = e.canUndo;
        this.canRedo = e.canRedo;
      };

      this.undoStack = new UndoStack(flexgrid, stateChanged);
    },
    getListLayerNotVisible(layerList) {
      const layerArr = ["business_name", "country", "layer_3", "layer_4", "layer_5", "layer_6"];
      layerArr.splice(0, layerList.length);
      const layerDataList = [];
      layerArr.map((layer, index) => {
        let binding = layer;
        let name    = `階層${Number(layerList.length) + Number(index) + 1}`;
        layerDataList.push({
          contractor_id: this.currentUser.user.contractor_id,
          cssClass     : "",
          isReadOnly   : false,
          status       : true,
          visible      : false,
          layer_index  : Number(layerList.length) + Number(index) + 1,
          layer_name   : name,
          binding
        });
      });
      return layerDataList;
    },
    handleHeaderEvent() {
      // handle show/hide columns
      var editRow     = this.onEditRow.bind(this);
      var showHideRow = this.onShowHideRow.bind(this);
      var listLayers  = this.listLayers;
      listLayers.map((layer) => {
        this.handleRowActionType({
          grid     : this.flex,
          attribute: layer.layer_index,
          layer,
          editRow,
          showHideRow
        });
      });
      var editRowLayerNotVisible     = this.onEditRowLayerNotVisible.bind(this);
      var showHideRowLayerNotVisible = this.onShowHideRowLayerNotVisible.bind(this);
      this.layersNotVisible.map((layer) => {
        this.handleRowActionTypeLayerNotVisible({
          grid     : this.flex,
          attribute: layer.layer_index,
          layer,
          editRowLayerNotVisible,
          showHideRowLayerNotVisible
        });
      });
    },
    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    checkValidationData() {
      this.branchList.getError = this.getError;
      this.submitData();
    },
    submitData() {
      let edited      = {};
      let editedBlank = {};
      let added       = {};

      let self = this;
      let indexBlank = 0;
      self.listOriginalData.forEach(function(item) {
        let newItemIndex = self.branchList.items.findIndex(currentItem => currentItem.id === item.id);
        const indexWithBlankId = newItemIndex + indexBlank;
        let branch       = self.branchList.items[indexWithBlankId];
        if (branch?.status !== item?.status ||
          branch?.company_name !== item?.company_name ||
          branch?.business_name !== item?.business_name ||
          branch?.organizational_division !== item?.organizational_division ||
          branch?.country !== item?.country ||
          branch?.layer_3 !== item?.layer_3 ||
          branch?.layer_4 !== item?.layer_4 ||
          branch?.layer_5 !== item?.layer_5 ||
          branch?.layer_6 !== item?.layer_6 ||
          branch?.contractor_id !== item?.contractor_id
        ) {
          let prepareBranch = {
            id                     : branch?.id,
            company_name           : branch?.company_name,
            business_name          : branch?.business_name || null,
            organizational_division: branch?.organizational_division,
            country                : branch?.country || null,
            layer_3                : branch?.layer_3 || null,
            layer_4                : branch?.layer_4 || null,
            layer_5                : branch?.layer_5 || null,
            layer_6                : branch?.layer_6 || null,
            contractor_id          : branch?.contractor_id,
            status                 : branch?.status === null ? 1 : branch?.status ? 1 : 0
          };
          if (!self.isValidFacility(prepareBranch)) {
            return;
          }
          if (branch.id === BLANK_ID) {
            delete prepareBranch.id;
            prepareBranch.contractor_id = null;
            editedBlank[indexWithBlankId] = prepareBranch;
            indexBlank += 1
          } else {
            edited[indexWithBlankId] = prepareBranch;
          }
        }
      });

      this.branchList.itemsAdded.forEach((branch, index) => {
        let itemCustom = {
          organizational_division: branch.organizational_division,
          company_name           : branch.company_name,
          business_name          : branch?.business_name || null,
          layer_3                : branch?.layer_3 || null,
          layer_4                : branch?.layer_4 || null,
          layer_5                : branch?.layer_5 || null,
          layer_6                : branch?.layer_6 || null,
          contractor_id          : this.contractor,
          country                : branch?.country || null,
          status                 : branch.status === false ? 0 : 1
        };
        if (!this.isValidFacility(itemCustom)) {
          return;
        }
        added[index] = itemCustom;
      });

      // call api
      this.submitDataError = { data: Object.values(edited), always_update: true }
      const editedPromise = Object.values(edited).length ? updateBranchApi({ data: Object.values(edited) }).then(() => {
        this.listOriginalData  = JSON.parse(JSON.stringify(this.branchList.items));
        this.dialogNotification = true;
        this.undoStack.clear();
      })
      .catch((error) => {
        const messageError = error.errors[0][0]
        if(this.usedFacility === messageError) {
          this.isShowUsedFacilityPopup = true
        }
      }) : emptyPromise();

      const editedBlankPromise = Object.values(editedBlank).length ? createBranchApi({ data: Object.values(editedBlank) }).then((res) => {
        this.afterDataSuccess(this.branchList.itemsEdited, editedBlank, res);
      }).catch((error) => {
        console.warn(error);
        this.showLimitFacilities(error);
      }) : emptyPromise();

      const addedPromise = Object.values(added).length ? createBranchApi({ data: Object.values(added) }).then((res) => {
        this.afterDataSuccess(this.branchList.itemsAdded, added, res);
      }).catch((error) => {
        console.warn(error);
        this.showLimitFacilities(error);
      }) : emptyPromise();

      Promise.all([editedPromise, editedBlankPromise, addedPromise]);
    },
    afterDataSuccess(collection, items, response) {
      const addedReplacement = {
        id    : "id",
        status: "status"
      };
      this.statusUpdate      = {
        time: response.latest_record.updated_at_latest,
        user: response.latest_record.user_updated
      };
      this.branchList.deferUpdate(() => {
        batchReplacementViewCollection(collection, response.record_insert, Object.keys(items), addedReplacement);
        collection.length = 0;
      });
      this.listOriginalData  = JSON.parse(JSON.stringify(this.branchList.items));
      this.dialogNotification = true;
      this.undoStack.clear();
    },
    showLimitFacilities(error) {
      if (error.code === "拠点登録の上限に達したため、拠点を追加できません。") {
        this.dialogErrorPopup = true;
        this.errorMessage = "拠点登録の上限に達したため、拠点を追加できません。";
      }
    },
    isItemActive(item) {
      return this.celEnabel.every(key => item[key]) && (!item.id || item.id === BLANK_ID)
    },
    handleSubmitFacility() {
      this.isShowUsedFacilityPopup = false
      updateBranchApi(this.submitDataError).then(() => {
        this.listOriginalData  = JSON.parse(JSON.stringify(this.branchList.items));
        this.dialogNotification = true;
        this.undoStack.clear();
      })
    },
    async getBranchList() {
      await getListBranchApi({
        contractor_id: this.currentUser.user.contractor_id
      }).then((responseApiListBranch) => {
        this.listLayers       = [];
        this.listLayers       = responseApiListBranch?.layer.map((layer) => {
          let binding = `layer_${layer.layer_index}`;
          if (layer.layer_index === 1) {
            binding = "business_name";
          } else if (layer.layer_index === 2) {
            binding = "country";
          }
          return {
            ...layer,
            cssClass  : !layer.status ? "auto-increment" : "",
            isReadOnly: !layer.status,
            binding
          };
        }).sort((firstLayer, nextLayer) => firstLayer.id - nextLayer.id);
        this.layersEnable     = responseApiListBranch.layer?.map((layer) => ({
          status: layer.status,
          id    : layer.id
          // cssClass: !layer.status ? 'auto-increment' : ''
        }));
        this.limitBranch      = Number(responseApiListBranch.number_limit_branch);
        this.layersNotVisible = [];
        this.layersNotVisible = this.getListLayerNotVisible(responseApiListBranch?.layer);
        this.isGetLayout      = true;
        this.branchData       = responseApiListBranch.data?.map((item, index) => item && {
          ...item,
          index: index + 1
        });
        this.totalData        = this.branchData.length;
        this.statusUpdate     = {
          time: responseApiListBranch.updated_at_latest,
          user: responseApiListBranch.user_updated
        };
      }).catch(() => {
        this.isGetLayout = true;
      });
      this.defineTable(this.branchData);
      this.handleHeaderEvent();
    },
    async handleClosePopupUsedFacility() {
      this.isShowUsedFacilityPopup = false
      this.branchData = null
      this.branchList = null
      this.isGetLayout = false
      this.getBranchList()
    }
  }
};
</script>

<style scoped lang="scss">
@import './styles/index.scss';
</style>
<style lang="scss">
.add-layer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 24px;
  height: 24px;
  background-image: url('../../assets/icons/facility/addLayer.svg');
  &:hover {
    cursor: pointer;
    background-image: url('../../assets/icons/facility/hoverLayer.svg');
  }
}
</style>
