export const LONGVITY = {
    LONGVITY_1: '30',
    LONGVITY_2: '60',
    LONGVITY_3: '90'
}
export const CASBEE_DATA = {
    wood:[
        { name: '木造', value: '30', number: 6.13},
        { name: '木造', value: '60', number: 3.06},
        { name: '木造', value: '90', number: 2.04},
    ],
    SType:[
        { name: 'S造', value: '30', number: 13.56},
        { name: 'S造', value: '60', number: 6.78},
        { name: 'S造', value: '90', number: 4.52}
    ],
    RCType:[
        { name: 'RC造', value: '30', number: 13.28},
        { name: 'RC造', value: '60', number: 6.64},
        { name: 'RC造', value: '90', number:4.43}
    ]
}
export const CO2X_DISCOUNT_DATA = {
    wood:[
        { name: '空欄', value: 0, source:'', number: 0},
        { name: '基礎用コンクリートに高炉セメントB種を使用', value: 1, source:'基礎用コンクリートに高炉セメントB種を使用', number: 0.04},
        { name: '構造用木材（合板、集成材を含む）にバイオマス乾燥木材または天然乾燥木材を使用（過半に採用）', value: 2, source:'構造用木材（合板、集成材を含む）にバイオマス乾燥木材または天然乾燥木材を使用（過半に採用）', number: 0.02},
        { name: '構造用木材（合板、集成材を含む）にバイオマス乾燥木材または天然乾燥木材を使用（概ねすべてに採用）', value: 3, source:'構造用木材（合板、集成材を含む）にバイオマス乾燥木材または天然乾燥木材を使用（概ねすべてに採用）', number: 0.04}
    ],
    SType: [
        { name: '空欄', value: 0, source:'', number: 0},
        { name: '基礎用コンクリートに高炉セメントB種を使用', value: 1, source:'基礎用コンクリートに高炉セメントB種を使用', number: 0.04},
        { name: '軽量鉄骨造の場合', value: 2, source:'軽量鉄骨造の場合', number: 0.09},
    ],
    RCType: [
        { name: '空欄', value: 0, source:'', number: 0},
        { name: '基礎用コンクリートに高炉セメントB種を使用', value: 1, source:'基礎用コンクリートに高炉セメントB種を使用', number: 0.03},
        { name: '上部躯体用コンクリートに高炉セメントB種を使用', value: 2, source:'上部躯体用コンクリートに高炉セメントB種を使用', number: 0.07},
    ]
}
export const INPUT_DATA = {
    non_equipment:'太陽光発電システム搭載なし',
    equipment: '太陽光発電システム搭載あり'
}
