import { numberFormat } from '@/filters/number';
import { number } from 'mathjs';
const AXIS_Y_ITEM_COUNT = 6;

export const prepareChartAxisY = (maxValue, minValue) => {
  const chartAxisY = [];
  if (maxValue === 0 && minValue === 0) {
    maxValue = 60;
  }
  maxValue = maxValue === 0 ? (maxValue - minValue) / AXIS_Y_ITEM_COUNT : maxValue;
  if (minValue === 0 && maxValue < 12) {
    maxValue = 12;
  }
  if (minValue >= 0 && maxValue >= 0) {
    minValue = 0;
  }
  let min = minValue;
  let max = maxValue;
  let axisYStep = (maxValue - minValue) / AXIS_Y_ITEM_COUNT;
  let minAxisYStep = Math.ceil(Math.abs(minValue) / axisYStep);
  let maxAxisYStep = Math.ceil(Math.abs(maxValue) / axisYStep);
  axisYStep = axisYStep * (minAxisYStep + maxAxisYStep) / AXIS_Y_ITEM_COUNT;
  do {
    minAxisYStep = Math.ceil(Math.abs(minValue) / axisYStep);
    maxAxisYStep = Math.ceil(Math.abs(maxValue) / axisYStep);
    axisYStep = axisYStep * (minAxisYStep + maxAxisYStep) / AXIS_Y_ITEM_COUNT;
  } while(minAxisYStep + maxAxisYStep > 6)
  min = minValue < 0 ? -Math.ceil(Math.abs(minValue / axisYStep)) * axisYStep : Math.ceil(Math.abs(minValue / axisYStep)) * axisYStep;
  max = Math.ceil(Math.abs(maxValue / axisYStep)) * axisYStep;
  let i = 0;
  if (minValue === 0) {
    chartAxisY.push({
      value: 0,
      text: "0",
      percent: 0,
    });
  }
  do {
    if (i === AXIS_Y_ITEM_COUNT) {
      chartAxisY.push({
        value: Number.isInteger(max) ? max : max.toFixed(2),
        text: 't-CO2',
        percent: 98,
      });
    } else {
      if ((Math.floor(min + i * axisYStep) >= 0) && !findValueZero(chartAxisY)) {
        chartAxisY.push({
          value: 0,
          text: "0",
          percent: Math.round(Math.abs(min) / (max - min) * 100),
        });
      } else {
        const percent = Math.round(Math.abs(i * axisYStep) / (max - min) * 100);
        chartAxisY.push({
          value: min + i * axisYStep,
          text: min + i * axisYStep === 0 ? "0" : numberFormat(Math.floor(min + i * axisYStep)),
          percent: percent,
        });
      }
    }
    i++;
  } while (i <= AXIS_Y_ITEM_COUNT);
  return [removeDuplicate(chartAxisY, 'value'), min, max];
};

const findValueZero = (chartAxisY) => {
  const existValueZero = chartAxisY.find(item => item.value === 0);
  return existValueZero ? true : false;
}

export const calcCategoryPercent = (item, max, min) => {
  const zeroValuePercent = Math.abs(Number(min) / (max - min)) * 100;
  return {
    ...item,
    period: (Math.abs((Number(item.value) / (max - min))) * 100).toFixed(2),
    zero_value_percent: zeroValuePercent,
    period_negative: (Math.abs((Number(item.value) / (max - min))) * 100).toFixed(2),
  }
}

const removeDuplicate = (array, key) => {
  return array.filter((obj, index, self) => 
    index === self.findIndex((t) => (
      t[key] === obj[key]
    ))
  )
};