<template>
  <div class="">
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      @keydown="handleKeydownClick"
      ref="dialog"
      max-width="720px"
      content-class="example-evalued-dialog"
    >
      <div class="content-dialog-popup">
        <div class="title-popup">自然エネルギー活用レベル 評価する取組みの例</div>
        <table class="table mt-5" cellspacing="0">
          <thead>
            <th class="table-head">評価項目</th>
            <th class="table-head">評価内容</th>
            <th class="table-head">採用手法</th>
          </thead>
          <tbody class="table-body">
            <tr>
              <td class="description">1. 採光利用</td>
              <td class="description">照明設備に代わり、太陽光を利用した、自然採光システムが計画されていること。</td>
              <td class="description">
                <p class="br-mobile">ライトシェルフ</p>
                <p class="br-mobile">トップライト</p>
                <p class="br-mobile">ハイサイドライト</p>
                <p class="br-mobile">その他</p>
              </td>
            </tr>
            <tr>
              <td class="description">2. 通風利用</td>
              <td class="description">
                空調設備に代わり、冷房負荷低減に有用な自然通風・自然換気システムが計画されていること。
              </td>
              <td class="description">
                <p class="br-mobile">自然ダンパや手動の開閉口または開閉窓（運用管理方法を計画したもの）</p>
                <p class="br-mobile">ナイトパージ</p>
                <p class="br-mobile">アトリウムと連携した換気システム</p>
                <p class="br-mobile">その他換気塔ソーラーチムニー</p>
                <p class="br-mobile">2方向以上へに開口</p>
                <p class="br-mobile">その他</p>
              </td>
            </tr>
            <tr>
              <td class="description">3. 地熱利用</td>
              <td class="description">
                熱源や空調設備に代わり、冷暖房負荷低減に有効な地熱利用システムが計画されていること。
              </td>
              <td class="description">
                <p class="br-mobile">クール＆ヒートチューブ・ピット</p>
                <p class="br-mobile">その他</p>
              </td>
            </tr>
            <tr>
              <td class="description">4. その他</td>
              <td class="description">その他、自然を活用した有効なシステムが計画されていること。</td>
              <td class="description"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-dialog>
  </div>
</template>
<script>
/** USE GUIDE:
 *  <button @click="dialog = !dialog">ExampleEvaluedDialog</button>
 *  <ExampleEvaluedDialog :dialog="dialog" @close="dialog = false"  />
 */
import { KEYS_CODE } from '@/constants/keyboard';
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    handleKeydownClick(event) {
      if (event.keyCode === KEYS_CODE.ESCAPE) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
    border-radius: 0px;
}

.content-dialog-popup {
  overflow: auto;
  padding: 20px;
  display: block;
  color: black;
  background-color: white;
  .title-popup {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }
  .table {
    .table-head {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      background-color: $bgLight;
      padding: 8px 19px;
      white-space: nowrap;
      min-width: 120px;
      text-align: left;
      border-top: 1px solid $antiFlashWhite;
      &:not(:last-of-type) {
        border-right: 1px solid $antiFlashWhite;
      }
    }
    .table-body {
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        padding: 8px 19px;
        border-bottom: 1px solid $antiFlashWhite;
        vertical-align: top;
        &:not(:last-of-type) {
          border-right: 1px solid $antiFlashWhite;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .content-dialog-popup {
    .table {
      .table-head {
        min-width: 85px;
      }
      .table-body {
        .description {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          padding: 8px 19px;
          border-bottom: 1px solid $antiFlashWhite;
          border-right: 1px solid $antiFlashWhite;
          &:first-of-type {
            border-left: 1px solid $antiFlashWhite;
          }
          &:last-of-type {
             padding: unset;
          }
          .br-mobile {
            padding: 8px 19px;
            margin: 0;
            &:not(:last-of-type) {
              border-bottom: 1px solid $antiFlashWhite;
            }
          }
        }
      }
    }
  }
}
</style>
