<template>
  <div class="contact-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title title="お問い合わせ" />
    <div v-if="linkToPage === 'index'" class="form-data">
      <div class="page-header">
        <span>
          各種お問い合わせは以下フォームをご利用下さい。
        </span>
        <span>
          2～3営業日以内に、担当者よりご連絡させていただきます。
        </span>
      </div>
      <div class="contact-wrapper">
        <div class="contact-form-type">
          <div class="header">
            <span>お問い合わせ種類</span>
            <div class="required-text">
              <span>必須</span>
            </div>
          </div>
          <default-pull-down-menu
            v-model="contactForm.selectedContactType"
            :items="contactType"
            :initialValue="contactForm.selectedContactType"
            placeHolderText="種類を選択してください"
            width="100%"
            class="contact-pulldown"
            :class="{ 'error-pulldown': isFormError && contactForm.selectedContactType === '' }"
          />
          <div v-if="isFormError && contactForm.selectedContactType === ''" class="error-message">{{ defaultErrorMessage }}</div>
        </div>
        <div class="contact-form-input">
          <div class="header">
            <span>お問い合わせ内容</span>
            <div class="required-text">
              <span>必須</span>
            </div>
          </div>
          <div class="form-input">
            <textarea
              v-model="contactForm.contactData"
              name="form-input"
              cols="30"
              rows="10"
              :class="{ 'error-textarea': isFormError && contactForm.contactData === '' }"
            >
            </textarea>
          </div>
          <div v-if="isFormError && contactForm.contactData === ''" class="error-message">{{ defaultErrorMessage }}</div>
        </div>
        <div class="contact-form-checkbox">
          <div class="checkbox-container">
            <div @click="contactForm.isCheck = !contactForm.isCheck" class="checkbox">
              <img v-if="contactForm.isCheck" src="@/assets/icons/checkbox.svg" alt="" class="checkmark">
            </div>
          </div>
          <a href="/others/privacy" class="link-to-page" target="_blank">
            <span>プライバシーポリシー</span>
            <img src="@/assets/icons/linkToNewPage.svg" alt="" />
          </a>
          <div class="agree-text">に同意する</div>
        </div>
        <div class="contact-submit-btn" @click="validateFormData" :class="!contactForm.isCheck && 'disabled'">
          <span>入力内容を確認する</span>
        </div>
      </div>
    </div>
    <confirm-page
      v-if="linkToPage === 'confirm'"
      :link-to-page.sync="linkToPage"
    />
    <success-page
      v-if="linkToPage === 'success'"
      :link-to-page.sync="linkToPage"
      :contact-form.sync="contactForm"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import ConfirmPage from '@/views/contact/confirm.vue';
import SuccessPage from '@/views/contact/success.vue';
export default {
  components: { PageTitle, DefaultPullDownMenu, ConfirmPage, SuccessPage },
  data() {
    return {
      breadcrum: [
        {
          text: 'ホーム',
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: 'お問い合わせ',
          disabled: true,
          href: ROUTES.CONTACT,
        },
      ],
      contactType: [
        {
          name: '排出量データの登録について',
          value: '排出量データの登録について'
        },
        {
          name: 'カスタマイズDBについて',
          value: 'カスタマイズDBについて'
        },
        {
          name: '排出量算定結果について',
          value: '排出量算定結果について'
        },
        {
          name: '算定結果のレポート出力について',
          value: '算定結果のレポート出力について'
        },
        {
          name: 'オフセット取引について',
          value: 'オフセット取引について'
        },
        {
          name: '上記以外のCARBONIXご利用上のご質問について',
          value: '上記以外のCARBONIXご利用上のご質問について'
        },
        {
          name: 'その他',
          value: 'その他'
        },
      ],
      isFormError: false,
      contactForm: {
        selectedContactType: '',
        isCheck: false,
        contactData: '',
        isConfirmPage: false,
      },
      linkToPage: 'index',
      defaultErrorMessage: '項目を選択してください',
      hoverPolicy: false,
    }
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    if (this.contactFormStore.isConfirmPage) {
      this.contactForm.selectedContactType = this.contactFormStore.selectedContactType;
      this.contactForm.contactData = this.contactFormStore.contactData;
      this.contactForm.isConfirmPage = true;
      this.contactForm.isCheck = true;
      this.linkToPage = 'confirm';
    }
  },
  computed: {
    contactFormStore() {
      return this.$store.state.userData.contactForm;
    }
  },
  watch: {
    linkToPage: {
      handler() {
        if (this.linkToPage === 'index') {
          this.breadcrum = [
            {
              text: 'ホーム',
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: 'お問い合わせ',
              disabled: true,
              href: ROUTES.CONTACT,
            },
          ];
        } else if (this.linkToPage === 'confirm') {
          this.breadcrum = [
            {
              text: 'ホーム',
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: 'お問い合わせ',
              disabled: true,
              href: ROUTES.CONTACT,
            },
            {
              text: '入力内容の確認',
              disabled: true,
              href: ROUTES.CONTACT,
            }
          ];
        } else {
          this.breadcrum = [
            {
              text: 'ホーム',
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: 'お問い合わせ',
              disabled: true,
              href: ROUTES.CONTACT,
            },
            {
              text: '完了',
              disabled: true,
              href: ROUTES.CONTACT,
            }
          ];
        }
        this.updateBreadCrumb(this.breadcrum);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('userData', ['actionupdateContactForm']),

    validateFormData() {
      if (this.contactForm.isCheck) {
        if (this.contactForm.selectedContactType !== '' && this.contactForm.contactData !== '') {
          this.linkToPage = 'confirm';
          this.contactForm.isConfirmPage = true;
          this.actionupdateContactForm(this.contactForm);
        } else {
          this.isFormError = true;
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './styles/index.scss';
</style>
<style lang="scss">
.contact-pulldown {
  .selection-box-bridge {
    height: 40px;
    padding-bottom: unset !important;
    .input-box {
      line-height: 24px;
    }
  }
  &.error-pulldown {
    border: 2px solid $redMid;
  }
  &.active {
    border: 2px solid $blueMid !important;
  }
  .selection-pulldown {
    margin-top: 4px;
  }
}
</style>
