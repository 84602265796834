import * as wjGrid from "@grapecity/wijmo.grid";
import { updateListData, addListData, deleteListData } from "@/api/registerData";
import store from "@/store";
import { AutoComplete } from "@grapecity/wijmo.input";
import { KEYS_CODE } from "@/constants/keyboard";
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_POSITIVE_NUMBER
} from "@/constants/registerData";
import {
  validateMaximumCharactorOnlyFacility,
  validateDate
} from "@/utils/validate";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { batchReplacementViewCollection, emptyPromise, formatValue } from "./wijmo.helper";
import { CellEditEndingEventArgs, CellRange } from "@grapecity/wijmo.grid";
import {
  handlerFilterData,
  handlerAddFilterData,
  addFilterByPasted,
  getColumnIndexByBinding,
  listeningEventWhenPasted,
  getBranchId,
  checkFilterData
} from "@/concerns/utils/filter-data";

const patternUrl       = "/pattern-s4-c1/";
export const blankData = (clientRowId) => {
  return {
    clientRowId            : clientRowId,
    id                     : BLANK_ID,
    organizational_division: null,
    company_name           : null,
    business_name          : null,
    emissions              : null,
    kinds                  : null,
    identification_number  : null,
    dir_date               : null,
    dir_remark             : null,
    dir_value              : null,
    dir_unit               : null,
    ait_remark             : null,
    duration_id            : null,
    month                  : null,
    year                   : null,
    type                   : null,
    memo_1                 : null,
    memo_2                 : null,
    country                : null,
    layer_3                : null,
    layer_4                : null,
    layer_5                : null,
    layer_6                : null,
    invalid_dir_date_source: null,
    dir_source             : null
  };
};
const calcEmissions    = (dirValue) => {
  dirValue = Number(formatValue(dirValue));

  if (!dirValue) {
    return 0;
  }

  return dirValue;
};

const validateNumberField = (input) => {
  if (input) {
    let numberData = input.replace(/[^\d.]/g, "");

    if (Number(input) < 0) {
      return MESSAGE_POSITIVE_NUMBER;
    }
    if (input.length > 20) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
    if (numberData && input.length - numberData.length == 0) {
      return null;
    } else {
      return MESSAGE_IS_NUMBER;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
};

const notNullInView = [
  "dir_unit"
];

const notNullList = [
  "organizational_division",
  "company_name",
  "kinds",
  "identification_number",
  "dir_date",
  "dir_value",
  "dir_unit"
];

const max20Charactor = [
  "dir_value",
  "dir_unit"
];

const max128Charactor = [
  "company_name",
  "business_name",
  "kinds",
  "identification_number",
  "dir_date",
  "dir_remark",
  "ait_remark",
  "memo_1",
  "memo_2",
  "invalid_dir_date_source",
  "dir_source"
];

const numberField = [
  "dir_value"
];

const dateField = [
  "dir_date"
];

const isValidData = (item) => {
  if (notNullList.some(field => !item[field] && item[field] !== 0 && !notNullInView.includes(field))) {
    return false;
  }
  if (max128Charactor.some(field => item[field]?.length > 128)) {
    return false;
  }
  if (max20Charactor.some(field => item[field]?.length > 20)) {
    return false;
  }
  if (numberField.some(field => validateNumberField(String(item[field])) !== null)) {
    return false;
  }
  if (dateField.some(field => validateDate(String(item[field])))) {
    return false;
  }
  return true;
};

let isReverted = false;

const filterColumns = ["organizational_division", "company_name", "business_name", "country", "layer_3", "layer_4", "layer_5", "layer_6"];

const partern27 = {
  initializeGrid(flexgrid) {
    let filterIndex                = {};
    let selectedFirst              = null;
    let previousCellData           = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return;
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount;

          for (let index = 1; index <= 100; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100));

    document.addEventListener("keydown", (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener("keydown", (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
          flexgrid.selection     = cellRange;

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange;
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
          flexgrid.selection     = cellRange;
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
          flexgrid.selection     = cellRange;
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange        = new wjGrid.CellRange(currentSelection.row, 1);
          flexgrid.selection     = cellRange;
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount;

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }

      if ((e.keyCode === KEYS_CODE.BACKSPACE || e.keyCode === KEYS_CODE.DELETE) && !flexgrid.selection.isSingleCell){
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      }
    }, false);

    flexgrid.pasted.addHandler((s, e) => {
      const {
              col,
              col2,
              row,
              row2
            }      = e.range;
      const view   = s.collectionView;
      const source = view.sourceCollection;
      if (row2 === row) {
        s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);
      }
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === "undefined") {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted);
        store.dispatch("registerData/updateListErrorFilterDataMessage", errorMessage);
        checkFilterDataAfterPasted = {};
      }
    });

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column        = s.columns[e.col];
      const {
              row,
              col
            }           = e.range;
      const cellData    = s.getCellData(row, col, false);
      const view        = s.collectionView;
      const source      = view.sourceCollection;
      const currentItem = source[row] || {};

      // update emissions when  dir_value change
      if (column.binding === "dir_value") {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s, "emissions"), calcEmissions(cellData), false, true);
        });
      }
      s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item   = s.rows[e.row].dataItem;

      if (!item.dir_unit) {
        item.dir_unit = "t-CO2";
      }
      if (column.binding === "emissions" || column.binding === "dir_unit") {
        e.cancel = true;
      }

      const {
              row,
              col
            }           = e.range;
      const cellData    = s.getCellData(row, col, false);
      const view        = s.collectionView;
      const source      = view.sourceCollection;
      const currentItem = source[row] || {};

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData);
      }
    });
    flexgrid.pasted.addHandler(() => {
      flexgrid.autoSizeColumns();
      flexgrid.autoSizeRows(0, 0, true);
    });
    flexgrid.autoSizeColumns();
  },

  header(registerData, listLayout) {
    let companyMap              = new wjGrid.DataMap(registerData.listBranch.company_name, "value", "value");
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    let businessMap              = new wjGrid.DataMap(registerData.listBranch.business_name, "value", "value");
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key  : item.value
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key  : item.value
        };
      });
    };

    return [
      {
        header      : "連番",
        binding     : "id", // id
        minWidth    : 40,
        maxWidth    : 65,
        allowSorting: false,
        isReadOnly  : true,
        visible     : false
      },
      {
        header       : "組織区分",
        binding      : "organizational_division", // phan loai to chuc
        minWidth     : 96,
        maxWidth     : 140,
        allowResizing: true,
        allowSorting : false,
        wordWrap     : true,
        isRequired   : false,
        dataMap      : new wjGrid.DataMap(registerData.listType, "key", "value"),
        editor       : new AutoComplete(document.createElement("div"), {
          itemsSource      : registerData.listType,
          selectedValuePath: "key",
          displayMemberPath: "value",
          maxItems         : 1000,
          minLength        : 1,
          selectedIndex    : -1
        })
      },
      {
        header      : "法人名",
        binding     : "company_name",
        minWidth    : 85,
        maxWidth    : 980,
        allowSorting: false,
        isRequired  : false,
        wordWrap    : true,
        dataMap     : companyMap,
        editor      : new AutoComplete(document.createElement("div"), {
          itemsSource      : registerData.listBranch?.company_name,
          selectedValuePath: "value",
          displayMemberPath: "value",
          maxItems         : 1000,
          minLength        : 1,
          selectedIndex    : -1
        })
      },
      ...listLayout,
      {
        header      : "種別", // phan loai
        binding     : "kinds",
        minWidth    : 70,
        maxWidth    : 980,
        allowSorting: false,
        isRequired  : false,
        wordWrap    : true,
        multiLine   : true
      },
      {
        header      : "識別番号等", // so id
        binding     : "identification_number",
        minWidth    : 109,
        maxWidth    : 980,
        allowSorting: false,
        isRequired  : false,
        wordWrap    : true,
        multiLine   : true
      },
      {
        header      : "備考1",
        binding     : "memo_1",
        minWidth    : 90,
        maxWidth    : 980,
        allowSorting: false,
        wordWrap    : true,
        isRequired  : false,
        multiLine   : true
      },
      {
        header      : "備考2",
        binding     : "memo_2",
        minWidth    : 90,
        maxWidth    : 980,
        allowSorting: false,
        wordWrap    : true,
        isRequired  : false,
        multiLine   : true
      },
      {
        header : "無効化又は移転の日",
        align  : "center",
        columns: [
          {
            header      : "日付", // Date of invalidation or relocation (date)
            binding     : "dir_date",
            minWidth    : 95,
            maxWidth    : 150,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true
          },
          {
            header      : "ソース", // Date of invalidation or relocation (remark)
            binding     : "invalid_dir_date_source",
            minWidth    : 83,
            maxWidth    : 980,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            multiLine   : true
          }
        ]
      },
      {
        header : "無効化又は移転の量",
        align  : "center",
        columns: [
          {
            header      : "数値", // Date of invalidation or relocation (numerical value)
            binding     : "dir_value",
            minWidth    : 70,
            maxWidth    : 200,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            inputType   : "float",
            dataType    : "Number"
          },
          {
            header      : "単位", // Date of invalidation or relocation (unit )
            binding     : "dir_unit",
            minWidth    : 70,
            maxWidth    : 200,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            cssClass    : "auto-increment"
          },
          {
            header      : "ソース", // Amount of invalidation or transfer (remarks)
            binding     : "dir_source",
            minWidth    : 83,
            maxWidth    : 980,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            multiLine   : true
          }
        ]
      },
      {
        header      : "合計量",
        binding     : "emissions",
        cssClass    : "auto-increment",
        cssClassAll : "emission",
        align       : "right", // === Date of invalidation or relocation (numerical value)
        minWidth    : 90,
        maxWidth    : 200,
        allowSorting: false,
        isRequired  : false
      }
    ];
  },

  handleEvent(view, bindingLayout, theGrid = null) {
    let isHandlingCollectionChange = false;
    let isSuccessAdd               = true;
    let isSuccessEditBlank         = true;

    view.collectionChanged.addHandler(throttle(async() => {
      isReverted    = true;
      let isSyncing = new Date().getTime();
      if (isHandlingCollectionChange) {
        return;
      }
      const indexEdit = view._idx + 1;
      let indexAdd    = indexEdit + view.itemsEdited.length;
      if (view.itemsEdited.length <= 0) {
        indexAdd = view.itemCount - view.itemsAdded.length + 1;
      }

      isHandlingCollectionChange = true;
      setTimeout(() => {
        isHandlingCollectionChange = false;
      }, 100);

      // handle add data
      let added              = {};
      const addedReplacement = {
        id         : "id",
        duration_id: "duration_id",
        month      : "month",
        year       : "year",
        type       : "type",
        emissions  : "emissions"
      };

      // handle update data
      let edited      = {};
      let editedBlank = {};

      view.itemsEdited.forEach((itemEdited, index) => {

        if (itemEdited.isSyncing) {
          return;
        }
        itemEdited.dir_value = itemEdited.dir_value && formatValue(itemEdited.dir_value);
        itemEdited.emissions = calcEmissions(itemEdited.dir_value);
        itemEdited.dir_unit  = "t-CO2";
        // will be change when api fixed

        // auto set data for layout
        const layoutsData = {};
        bindingLayout.forEach(key => {
          layoutsData[key] = itemEdited[key] || null;
        });
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemEdited.organizational_division,
          company_name           : itemEdited.company_name,
          ...layoutsData
        });


        let itemCustom = {
          id                     : itemEdited.id,
          organizational_division: itemEdited?.organizational_division,
          company_name           : itemEdited?.company_name,
          emissions              : itemEdited.emissions === null ? "0" : itemEdited.emissions,
          kinds                  : itemEdited.kinds,
          identification_number  : itemEdited.identification_number,
          dir_date               : itemEdited.dir_date,
          dir_remark             : itemEdited.dir_remark,
          dir_value              : itemEdited.dir_value === 0 ? "0" : itemEdited.dir_value,
          dir_unit               : itemEdited.dir_unit,
          ait_remark             : itemEdited.ait_remark,
          duration_id            : store.state.registerData.duration, // from store
          month                  : store.state.registerData.month, // from store
          year                   : store.state.registerData.yearSelected,
          type                   : 0,
          index                  : indexEdit + index,
          memo_1                 : itemEdited.memo_1 || null,
          memo_2                 : itemEdited.memo_2 || null,
          invalid_dir_date_source: itemEdited.invalid_dir_date_source || null,
          dir_source             : itemEdited.dir_source || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };
        // checkk before call api
        if (
          !isValidData(itemCustom)
        ) {
          return;
        }

        if (itemCustom.id === BLANK_ID) {
          delete itemCustom.id;
          editedBlank[index] = itemCustom;
        }
        if (itemCustom.id && itemCustom.id !== BLANK_ID) {
          edited[index] = itemCustom;
        }

        itemEdited.isSyncing = isSyncing;
      });

      if (Object.values(edited).length || Object.values(editedBlank).length) {
        isSuccessEditBlank = false;
        isReverted         = true;

        try {
          const editedPromise      = Object.values(edited).length ? updateListData(patternUrl, Object.values(edited)) : emptyPromise();
          let _                    = await editedPromise;
          const editedBlankPromise = Object.values(editedBlank).length ? addListData(patternUrl, Object.values(editedBlank)) : emptyPromise();
          let editedBlankRes       = await editedBlankPromise;
          isSuccessEditBlank       = true;
          if (Object.keys(editedBlank).length) {
            let itemEdited = view.itemsEdited.filter((item) => item.isSyncing === isSyncing);
            batchReplacementViewCollection(itemEdited, editedBlankRes.record_insert, Object.keys(editedBlank), addedReplacement, true);
          }

          view.itemsEdited.forEach((item) => item.isSyncing = false);
          store.dispatch("commonApp/actionUpdateIsSuccess", true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch("commonApp/updateStatusAction", false, { root: true });
          }
          view.itemsEdited.length = 0;
        } catch (error) {
          view.itemsEdited.forEach((item) => item.isSyncing = false);
          view.itemsEdited.length = 0;
          isReverted              = false;
        }
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }


      view.itemsAdded.forEach((itemAdded, index) => {

        if (itemAdded.dir_date) {
          let date = new Date(itemAdded.dir_date);
          if (!!isNaN(date.getTime())) {
            itemAdded.dir_date = null;
          } else {
            let datestring     = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
            itemAdded.dir_date = datestring;
          }
        }
        itemAdded.dir_value = itemAdded.dir_value && formatValue(itemAdded.dir_value);
        itemAdded.emissions = calcEmissions(itemAdded.dir_value);

        // auto set data for layout
        const layoutsData = {};
        bindingLayout.forEach(key => {
          layoutsData[key] = itemAdded[key] || null;
        });
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemAdded.organizational_division,
          company_name           : itemAdded.company_name,
          ...layoutsData
        });


        let itemCustom = {
          organizational_division: itemAdded?.organizational_division,
          company_name           : itemAdded?.company_name,
          emissions              : itemAdded.emissions === null ? "0" : itemAdded.emissions,
          kinds                  : itemAdded.kinds,
          identification_number  : itemAdded.identification_number,
          dir_date               : itemAdded.dir_date,
          dir_remark             : itemAdded.dir_remark,
          dir_value              : itemAdded.dir_value === 0 ? "0" : itemAdded.dir_value,
          dir_unit               : itemAdded.dir_unit,
          ait_remark             : itemAdded.ait_remark,
          duration_id            : store.state.registerData.duration, // from store
          month                  : store.state.registerData.month, // from store
          year                   : store.state.registerData.yearSelected,
          type                   : 0,
          index                  : indexAdd + index,
          memo_1                 : itemAdded.memo_1 || null,
          memo_2                 : itemAdded.memo_2 || null,
          invalid_dir_date_source: itemAdded.invalid_dir_date_source || null,
          dir_source             : itemAdded.dir_source || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };
        // check before call api
        if (!isValidData(itemCustom)) {
          return;
        }
        added[index] = itemCustom;
      });

      if (Object.values(added).length) {
        view.itemsAdded.length = 0;
        isSuccessAdd           = false;
        isReverted             = true;
        addListData(patternUrl, Object.values(added)).then(res => {
          batchReplacementViewCollection(view.itemsAdded, res.record_insert, Object.keys(added), addedReplacement);
          // clear items added
          // view.itemsAdded.length = 0
          isSuccessAdd = true;

          store.dispatch("commonApp/actionUpdateIsSuccess", true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch("commonApp/updateStatusAction", false, { root: true });
          }
        });
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }
      // handle delete data
      let removed = {};
      view.itemsRemoved.forEach((itemRemoved, index) => {
        if (itemRemoved.id && itemRemoved.id !== BLANK_ID) {
          removed[index] = itemRemoved.id;
        }
      });

      if (Object.values(removed).length) {
        const ids = Object.values(removed);
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
        deleteListData(`${patternUrl}?id[]=${ids.join("&id[]=")}`).then(() => {
          view.itemsRemoved.length = 0;
        }).catch((_) => {
          view.itemsRemoved.length = 0;
        });
      }

      // revert
      if (Array.isArray(view.itemsRevertDelete)) {
        let reverted = {};
        view.itemsRevertDelete.forEach((itemReverted, index) => {
          if (!isValidData(itemReverted)) {
            return;
          }
          reverted[index] = itemReverted;
        });

        if (Object.values(reverted).length) {
          view.itemsRevertDelete.length = 0;
          isReverted                    = true;
          updateListData(patternUrl, Object.values(reverted), true).then(() => {
            isReverted = false;
          });
        }
      }
    }, 300));
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount;
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    "organizational_division",
    "company_name",
    "business_name",
    "kinds",
    "identification_number",
    "dir_remark",
    "dir_unit",
    "ait_remark"
  ],

  getError(item, propName) {

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === "" || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      } else {
        return validateNumberField(String(item[propName]));
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      if (dateField.includes(propName)) {
        return validateDate(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null;
  }
};

export default partern27;
