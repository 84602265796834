<template>
  <div>
    <div class="chart-monthly">
      <div class="monthly">
        <chart-monthly-2
          @openDialogStateDetail="openDialogStateDetail"
          @onUpdateStateDetail="onUpdateStateDetail"
        ></chart-monthly-2>
      </div>

      <div class="monthly-detail">
        <chart-detail-monthly :isShowContent="isShowChartDetailMonthly"></chart-detail-monthly>
      </div>
    </div>

    <div class="dialog-state-detail">
      <chart-detail-monthly-dialog :dialog="isShowChartDetailDialog" @close="closeDialog"></chart-detail-monthly-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-monthly {
  .monthly-detail {
    display: none;
  }
}

.dialog-state-detail {
  display: none;
}

// desktop
@include desktop {
  .dialog-state-detail {
    display: block;
  }

  .chart-monthly {
    display: flex;

    .monthly {
      display: block;
      flex: 0 0 53.39%;
    }

    .monthly-detail {
      display: block;
      flex: 0 0 46.61%;
    }

    .monthly-detail {
      background: $monoWhite;
      border-radius: 8px;
      padding: 24px;
      padding-bottom: 15px;
    }
  }
}
</style>
<script>
import ChartMonthly from './chart/index.vue';
import ChartDetailMonthly from './detail-chart/index.vue';
import ChartDetailMonthlyDialog from './detail-chart-dialog/index.vue';
import ChartMonthly2 from './chart-2/index.vue';

export default {
  components: {
    ChartMonthly,
    ChartDetailMonthly,
    ChartDetailMonthlyDialog,
    ChartMonthly2
  },
  data() {
    return {
      isShowChartDetailDialog: false,
      windowWidth            : window.outerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (!window) {
      return;
    }
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isShowChartDetailMonthly() {
      return this.windowWidth >= 1024;
    },
  },
  methods : {
    onResize() {
      this.windowWidth = window.outerWidth;
    },
    openDialogStateDetail(_) {
      this.isShowChartDetailDialog = true;
    },
    closeDialog() {
      this.isShowChartDetailDialog = false;
    },
    async onUpdateStateDetail($event) {
      if (!$event) {
        return;
      }
      await this.$store.dispatch('dashboard/changeSelectedEmissionsByPeriod', $event.original);
      await this.$store.dispatch('dashboard/requestEmissionsByPeriodDetail');
    },
  },
};
</script>
