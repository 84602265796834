<template>
  <div>
    <div class="period-wrapper">
      <div class="period-header">
        <div class="period-title">
          <img src="img/icons/time.svg" alt="time" />
          <h2 class="period__title">انبعاثات غازات الدفيئة</h2>
        </div>
        <span class="period-icon"><img src="@/assets/icons/multiple.svg" /></span>
      </div>

      <div class="tabs-header">
        <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
          <v-tab>سنويا</v-tab>
          <v-tab>شهريا</v-tab>
        </v-tabs>
      </div>

      <div class="tabs-content">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <yearly></yearly>
          </v-tab-item>

          <v-tab-item>
            <monthly></monthly>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import Yearly from './yearly/index.vue';
import Monthly from './monthly/index.vue';
import { DASHBOARD_TYPES } from '@/constants/dashboard';

const tabsMapping = {
  0: DASHBOARD_TYPES.duration,
  1: DASHBOARD_TYPES.month,
};

export default {
  components: { Yearly, Monthly },
  data() {
    return {
      selectedTab: 0,
      windowWidth: window.innerWidth,
    };
  },
  created() {
    this.selectedTab = this.getSelectedTypeForEmissionsByPeriod;
    this.$store.dispatch('dashboard/requestEmissionByPeriod');
  },
  computed: {
    ...mapGetters({
      getSelectedTypeForEmissionsByPeriod: 'dashboard/getSelectedTypeForEmissionsByPeriod',
    }),
  },
  watch: {
    selectedTab(newVal) {
      const type = tabsMapping[newVal];
      this.$store.dispatch('dashboard/changeSelectedTypeForEmissionsByPeriod', type);
      this.$store.dispatch('dashboard/requestEmissionByPeriod');
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style lang="scss" scoped>
.period-header {
  margin-top: 40px;
  margin-bottom: 48px;

  .period-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin-bottom: 16px;
    justify-content: flex-end;
    flex-flow: row-reverse;

    .period__title {
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      color: #404d50;
      padding-right: 16.5px;
    }
  }

  .period-icon {
    display: flex;
  }
}

.tabs-header {
  margin-bottom: 48px;
}
@include large-desktop {
  .period-header {
    margin: 48px 0px;
  }
}
</style>
