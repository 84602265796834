<template>
  <div class="scope-component" :class="'component-scope-' + scopes.scope">
    <div v-if="scopes.scope === '1' || scopes.scope === '3' || scopes.scope === '4'" class="scope-header">
      {{ header }}
    </div>
    <div class="scope-wrapper" :class="'scope-' + scopes.scope">
      <div v-if="scopes.scope_icon !== ''" class="scope-icon">
        <img :src="getSettingIcon(scopes.scope_icon)" alt="" />
      </div>
      <div class="scope-category-wrapper">
        <div class="scope-category" v-for="(category, index) in scopes.categories" :key="index" :class="(!isEnableScope3 && scopes.scope === '4' && category.id === 16)?'disabled':''">
          <div @click="selectedScope(scopes, category)" class="category-name">
            <div v-if="scopes.scope === '3'" class="category-index">
              <img
                v-if="scopes.align === 'left'"
                :src="getSettingIcon('scope' + scopes.scope + '/index/index' + parseInt(index + 1) + '.svg')"
                alt=""
              />
              <img
                v-else
                :src="getSettingIcon('scope' + scopes.scope + '/index/index' + parseInt(index + 9) + '.svg')"
                alt=""
              />
            </div>
            <img
              v-if="scopes.align === 'right'"
              :src="getSettingIcon('scope' + scopes.scope + '/c' + parseInt(index + 9) + '.svg')"
              alt=""
            />
            <img v-else :src="getSettingIcon('scope' + scopes.scope + '/c' + parseInt(index + 1) + '.svg')" alt="" />
            <div class="name">{{ category.name }}</div>
          </div>
          <div
            class="helper-icon"
            :class="{ right: scopes.align === 'right' }"
            @click="addTooltip($event)"
            @mouseover="addTooltip($event)"
          >
            <img
              :src="getSettingIcon('helper.svg')"
              @mouseover="changeTooltipImage($event, index)"
              @mouseleave="changeTooltipImage($event, index, 'mouseleave')"
              alt=""
            />
            <div v-show="hoverIndex === index" class="helper-icon tooltip" v-html="category.tooltip"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name : "ScopeComponent",
  props: {
    header: {
      type   : String,
      default: "This is default header"
    },
    scopes: {
      type   : Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      focusElement: null,
      hoverIndex  : null,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.removeTooltip);
  },
  computed: {
    ...mapState("registerData", ["startMonth"]),
    ...mapState("dashboard", ["isEnableScope3"]),
  },
  methods : {
    ...mapActions("registerData", ["updateDataMonth", "updateStartMonth"]),
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/images/emissions/${image}`);
      }
      return "";
    },
    selectedScope(scopes, category) {
      const scopeSelect = scopes.scope
      if(scopeSelect === '3' && !this.isEnableScope3 || (scopeSelect === '4' && category.id === 16 && !this.isEnableScope3)) return

      this.updateDataMonth(this.startMonth);
      const cateId = category.id;
      const scope  = cateId === 16 ? 3 : scopes.scope;
      this.$emit("selectedScope", {
          scope : parseInt(scope),
          cateId: cateId
        },
        category.name);
    },
    removeTooltip() {
      if (this.focusElement) {
        this.focusElement.classList.remove("tooltip-icon");
      }
    },
    addTooltip(event) {
      this.focusElement = event.target.parentElement;
      this.focusElement.classList.add("tooltip-icon");
    },
    changeTooltipImage(event, index, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper.svg");
        this.hoverIndex  = null;
      } else {
        event.target.src = this.getSettingIcon("helper-active.svg");
        this.hoverIndex  = index;
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.removeTooltip);
  }
};
</script>

<style lang="scss" scoped>
.scope-component {
  background: $bgLight;
  border-radius: 8px;
  padding: 8px;

  .scope-header {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    padding: 16px 0;
    margin: 0 auto;
  }

  .scope-wrapper {
    &.scope-1 {
      background-color: $blueDark;

      .scope-category-wrapper {
        background: linear-gradient(180deg, #0072a3 0%, #c8deea 100%);
        padding-bottom: 4px;
      }
    }

    &.scope-2 {
      background-color: $redMid;

      .scope-category-wrapper {
        background: linear-gradient(180deg, #e45b58 0%, #eacac8 100%);
        padding-bottom: 4px;
      }
    }

    &.scope-3 {
      background-color: $greenMid;

      .scope-category-wrapper {
        background: linear-gradient(180deg, #80bc62 0%, #c7e6bc 100%);
        padding-bottom: 4px;
      }
    }

    &.scope-4 {
      width: 100%;
    }

    border-radius: 4px;

    .scope-icon {
      height: 65px;
      display: flex;

      img {
        width: 80px;
        height: 25px;
        margin: 24px auto 16px;
      }
    }

    .scope-category-wrapper {
      display: flex;
      flex-flow: column;
      border-radius: 0px 0px 4px 4px;

      .scope-category {
        display: flex;
        align-items: center;
        background-color: $monoOffWhite;
        border-radius: 4px;
        margin: 4px 8px;
        cursor: pointer;
        height: 46px;
        flex-direction: row-reverse;
        .category-name {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 13px 8px;
          z-index: 1;
          flex-direction: row-reverse;

          img {
            width: 20px;
            height: 20px;
          }

          .name {
            color: $monoBlack;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            margin: 0 8px;
            direction: rtl;
          }

        }

        .helper-icon {
          width: 40px;
          height: 46px;
          display: inline-block;
          position: relative;

          img {
            z-index: 1;
            width: 40px;
            height: 46px;
          }

          &.tooltip {
            display: block;
            justify-content: left;
            background: $monoWhite;
            border-radius: 4px;
            color: $monoBlack;
            margin: 0 auto;
            font-size: 15px;
            padding: 10px;
            height: unset;
            width: 315px;
            max-width: max-content;
            position: absolute;
            right: 0px;
            left: unset;
            bottom: 50px;
            z-index: 9999;
            box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
            0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
            0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          }
        }

        .category-index {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 8px;
        }

        &:hover {
          background-color: $monoWhite;
        }
        &.disabled {
          opacity: 0.4;
          cursor: unset;
          .category-name {
            .name {
              color: #7c898c;
            }
          }

          &:hover {
            background: #f7f7f2;
          }
        }
      }
    }
  }
}

.component-scope-1 {
  padding-bottom: unset;
  border-radius: 4px 4px 0px 0px;
}

.component-scope-2 {
  border-radius: 0px 0px 4px 4px;
}

.component-scope-4 {
  margin-top: 48px;
}

@include large-desktop {
  .tooltip-icon {
    &.right {
      &:hover:before {
        right: 15px !important;
        left: unset !important;
      }
    }

    &:hover:before {
      left: 15px !important;
      right: unset !important;
    }
  }
}

@include desktop {
  .component-scope-4 {
    display: flex;
    align-items: center;
    width: 100%;

    .scope-header {
      width: calc(100% / 3);
      padding: 14px 0;
    }

    .scope-wrapper {
      width: calc(100% / 3 * 2);

      .scope-category-wrapper {
        display: flex;
        flex-flow: row !important;

        .scope-category {
          width: 50%;
          margin: unset;

          &:first-child {
            margin-right: 8px;
          }

          > .tooltip-icon {
            &:last-child {
              &:hover:before {
                right: 15px !important;
                left: unset !important;
              }
            }

            &:hover:before {
              left: 15px !important;
              right: unset !important;
            }
          }
        }
      }
    }
  }
}
</style>
