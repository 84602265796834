  
export const HOT_EQUIQMENT ={
  HOT_EQUIQMENT_1: "A: 単位住戸全体を暖房する方式",
  HOT_EQUIQMENT_2: "B: 居室のみを暖房する方式（連続運転）",
  HOT_EQUIQMENT_3: "C: 居室のみを暖房する方式（間歇運転）",
}

export const REFRIGERATION_EQUIQMENT={
  REFRIGERATION_EQUIQMENT_1:"a: 単位住戸全体を冷房する方式",
  REFRIGERATION_EQUIQMENT_2:"b: 居室のみを冷房する方式（間歇運転）",
}

export const BEI_NUMBER = {
  NUMBER_1:'1.0',
  NUMBER_2:'1.2以上'
}

export const AREA_NUMBER = {
  AREA_NUMBER1:1,
  AREA_NUMBER2:2,
  AREA_NUMBER3:3,
  AREA_NUMBER4:4,
  AREA_NUMBER5:5,
  AREA_NUMBER6:6,
  AREA_NUMBER7:7,
  AREA_NUMBER8:8,
}

export const LEVEL_RATING_OF_WATER = {
  LEVEL_1:'水消費に係るCO2排出量を考慮しない',
  LEVEL_2:'0',
  LEVEL_3:'1',
  LEVEL_4:'2',
  LEVEL_5:'3',
}
export const WATER_SAVING_MECHANISM = [
  {
    type: '0',
    value: 115,
  },
  {
    type: '1',
    value: 100,
  },
  {
    type: '2',
    value: 85,
  },
  {
    type: '3',
    value: 70,
  },
  {
    type: '水消費に係るCO2排出量を考慮しない',
    value: 0,
  },
]
export const  VALUE_CALCULATION_DEFAULT = {
  GAS_VALUE : 0.0499,
  LPG_VALUE : 0.059,
  FUEL_VALUE : 0.0678,
  HEART_VALUE : 0.057,
  VALUE_1000 : 1000,
  VALUE_976 : 9.76,
  VALUE_091 : 0.91,
}
export const COEFFICIENT = {
  ELECTRIC: 49.8/100,
  GAS: 22.3/100,
  LPG: 11.2/100,
  FUEL: 16.2/100,
  HEART: 0.1/100
}

export const  HOME_CHOOSE_CONDITION = [
  {
    typeHeadEquipment: 'A: 単位住戸全体を暖房する方式',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.2以上',
    area1:1777,
    area2:1542,
    area3:1325,
    area4:1543,
    area5:1393,
    area6:1308,
    area7:1147,
    area8:1089,
  },
  {
    typeHeadEquipment: 'A: 単位住戸全体を暖房する方式',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.0',
    area1:1510,
    area2:1315,
    area3:1134,
    area4:1316,
    area5:1190,
    area6:1119,
    area7:985,
    area8:937,
  },
  {
    typeHeadEquipment: 'A: 単位住戸全体を暖房する方式',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.2以上',
    area1:1755,
    area2:1523,
    area3:1279,
    area4:1455,
    area5:1295,
    area6:1076,
    area7:852,
    area8:595,
  },
  {
    typeHeadEquipment: 'A: 単位住戸全体を暖房する方式',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.0',
    area1:1492,
    area2:1299,
    area3:1096,
    area4:1242,
    area5:1109,
    area6:926,
    area7:740,
    area8:525,
  },
  {
    typeHeadEquipment: 'B: 居室のみを暖房する方式（連続運転）',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.2以上',
    area1:1467,
    area2:1376,
    area3:1248,
    area4:1426,
    area5:1260,
    area6:1261,
    area7:1122,
    area8:1089,
  },
  {
    typeHeadEquipment: 'B: 居室のみを暖房する方式（連続運転）',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.0',
    area1:1252,
    area2:1176,
    area3:1069,
    area4:1218,
    area5:1080,
    area6:1081,
    area7:965,
    area8:937,
  },
  {
    typeHeadEquipment: 'B: 居室のみを暖房する方式（連続運転）',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.2以上',
    area1:1444,
    area2:1357,
    area3:1202,
    area4:1338,
    area5:1163,
    area6:1029,
    area7:828,
    area8:595,
  },
  {
    typeHeadEquipment: 'B: 居室のみを暖房する方式（連続運転）',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.0',
    area1:1233,
    area2:1160,
    area3:1031,
    area4:1144,
    area5:998,
    area6:887,
    area7:720,
    area8:525,
  },
  {
    typeHeadEquipment: 'C: 居室のみを暖房する方式（間歇運転）',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.2以上',
    area1:1113,
    area2:1051,
    area3:972,
    area4:1073,
    area5:940,
    area6:1009,
    area7:983,
    area8:1089,
  },
  {
    typeHeadEquipment: 'C: 居室のみを暖房する方式（間歇運転）',
    typeRefrigerationEquipment: 'a: 単位住戸全体を冷房する方式',
    bei:'1.0',
    area1:957,
    area2:905,
    area3:839,
    area4:924,
    area5:813,
    area6:870,
    area7:848,
    area8:937,
  },
  {
    typeHeadEquipment: 'C: 居室のみを暖房する方式（間歇運転）',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.2以上',
    area1:1091,
    area2:1031,
    area3:926,
    area4:985,
    area5:843,
    area6:777,
    area7:689,
    area8:595,
  },
  {
    typeHeadEquipment: 'C: 居室のみを暖房する方式（間歇運転）',
    typeRefrigerationEquipment: 'b: 居室のみを冷房する方式（間歇運転）',
    bei:'1.0',
    area1:939,
    area2:889,
    area3:801,
    area4:850,
    area5:732,
    area6:677,
    area7:603,
    area8:525,
  }
]
