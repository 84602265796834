<template>
    <div>
      <v-dialog
        v-model="getDialog"
        @click:outside="handleClickOutSite"
        ref="dialog"
        max-width="464px"
        content-class="new-raido-popup "
      >
        <v-card>
          <v-card-title>
            <span>申請取消を審査</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-radio-group v-model="actionAcceptCancel" hide-details>
            <v-list-item tabindex="1" v-for="item in items" :key="item.id">
              <v-list-item-action>
                <v-radio :ripple="false" :label="item.name" :value="item.id" color="success" class="">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      <div class="sub-title">{{ item.name }}</div>
                <div class="sub-description">{{ item.description }}</div>
                    </div>
                  </template>
                </v-radio>
              </v-list-item-action>
            </v-list-item>
          </v-radio-group>
          <v-divider></v-divider>
          <div class="dialog-comment">
            <div class="input-label">コメント</div>
            <v-textarea
              no-resize
              v-model="comment"
              hide-details
            ></v-textarea>
          </div>
          <v-card-actions>
            <common-button class="left-button-popup" @action="close" :label="`キャンセル`"/>
            <common-button type="redColor" @action="submit" :label="confirmText"/>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
import CommonButton from '@/components/commonApp/Button.vue';
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'OK',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      itemSelect: null,
      actionAcceptCancel: 1,
      comment: null
    };
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.comment = null;
      this.actionAcceptCancel = null;
    },
    submit() {
      const data = {
        status: this.actionAcceptCancel,
        comment: this.comment
      }
      this.$emit('submit', data);
      this.comment = null;
      this.actionAcceptCancel = null;
    },
    handleClickOutSite() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
