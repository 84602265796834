export const PURPOSES_TYPE = {
  office: {
    name: '事務所',
    value: 'office',
    index: 0,
  },
  school: {
    name: '学校',
    value: 'school',
    index: 1,
  },
  merchan: {
    name: '物販店',
    value: 'merchan',
    index: 2,
  },
  restaurant: {
    name: '飲食店',
    value: 'restaurant',
    index: 3,
  },
  meetingPlace: {
    name: '集会所',
    value: 'meetingPlace',
    index: 4,
  },
  factory: {
    name: '工場',
    value: 'factory',
    index: 5,
  },
  hospital: {
    name: '病院',
    value: 'hospital',
    index: 6,
  },
  hotel: {
    name: 'ホテル',
    value: 'hotel',
    index: 7,
  },
  complex: {
    name: '集合住宅',
    value: 'complex',
    index: 8,
  },
};
export const EFFORT_TYPE = {
  empty: {
    name: '空欄',
    value: 'empty',
    index: 0,
  },
  current: {
    name: '既存建築躯体 100％利用',
    value: 'current',
    index: 1,
  },
  recycling: {
    name: 'リサイクル材（高炉セメント）100％',
    value: 'recycling',
    index: 2,
  },
};
export const FRAME_TYPE = {
  steel: {
    name: 'S造',
    value: 'steel',
    index: 0,
  },
  wood: {
    name: '木造',
    value: 'wood',
    index: 0,
  },
  RCType: {
    name: 'RC造',
    value: 'RCType',
    index: 1,
  },
  SRCType: {
    name: 'SRC造',
    value: 'SRCType',
    index: 2,
  },
};
export const LIFE_SPAN_TYPE = {
  complex30: {
    name: '30年',
    value: 'level3',
  },
  complex60: {
    name: '60年',
    value: 'level4',
  },
  complex90: {
    name: '90年',
    value: 'level5',
  },
};
export const LEVEL = {
  level3: 0,
  level4: 1,
  level5: 2,
};

export const ENVIRONMENTAL_IMPACT = {
  ordinaryConcrete: 266.71,
  blastFurnaceCementConcrete: 216.57,
  steelFrame: 1.28,
  rebar: 0.51,
};

export const LIFE_SPANS_AGE = {
  office: 60,
  school: 60,
  merchan: 30,
  restaurant: 30,
  meetingPlace: 60,
  factory: 30,
  hospital: 60,
  hotel: 60,
  complex30: 30,
  complex60: 60,
  complex90: 90,
};

export const CO2_DATA = {
  /**
   * S造/ dầm thép	木造/loại gỗ	|	RC造/cấu trúc RC	|	SRC造/cấu trúc SRC
   * Level 3/レベル３	Level 4/レベル４	Level 5/レベル５ |	Level 3/レベル３	Level 4/レベル４	Level 5/レベル５  | Level 3/レベル３	Level 4/レベル４	Level 5/レベル５
   * empty: [14.005, 14.005, 14.005, 13.23, 13.23, 13.23, 13.998, 13.998, 13.998]
   *14.005, 14.005, 14.005 | 13.23, 13.23, 13.23 | 13.998, 13.998, 13.998
   */
  office: {
    empty: [840.30, 840.30, 840.30, 793.80, 793.80, 793.80, 839.88, 839.88, 839.88],
    current: [387.12, 387.12, 387.12, 396.06, 396.06, 396.06, 390.90, 390.90, 390.90],
    recycling: [805.20, 805.20, 805.20, 744.96, 744.96, 744.96, 796.32, 796.32, 796.32],
  },
  school: {
    empty: [628.38, 628.38, 628.38, 705.78, 705.78, 705.78, 840.12, 840.12, 840.12],
    current: [313.68, 313.68, 313.68, 322.14, 322.14, 322.14, 316.68, 316.68, 316.68],
    recycling: [606.60, 606.60, 606.60, 650.76, 650.76, 650.76, 780.30, 780.30, 780.30],
  },
  merchan: {
    empty: [497.16, 497.16, 497.16, 671.58, 671.58, 671.58, 508.83, 508.83, 508.83],
    current: [252.12, 252.12, 252.12, 257.97, 257.97, 257.97, 254.55, 254.55, 254.55],
    recycling: [476.01, 476.01, 476.01, 615.36, 615.36, 615.36, 489.66, 489.66, 489.66],
  },
  restaurant: {
    empty: [497.16, 497.16, 497.16, 671.58, 671.58, 671.58, 508.83, 508.83, 508.83],
    current: [252.12, 252.12, 252.12, 257.97, 257.97, 257.97, 254.55, 254.55, 254.55],
    recycling: [476.01, 476.01, 476.01, 615.36, 615.36, 615.36, 489.66, 489.66, 489.66],
  },
  meetingPlace: {
    empty: [692.10, 692.10, 692.10, 748.26, 748.26, 748.26, 784.74, 784.74, 784.74],
    current: [327.12, 327.12, 327.12, 334.68, 334.68, 334.68, 330.24, 330.24, 330.24],
    recycling: [670.68, 670.68, 670.68, 692.04, 692.04, 692.04, 730.92, 730.92, 730.92],
  },
  factory: {
    empty: [586.86, 586.86, 586.86, 675.00, 675.00, 675.00, 709.50, 709.50, 709.50],
    current: [299.55, 299.55, 299.55, 309.06, 309.06, 309.06, 299.10, 299.10, 299.10],
    recycling: [564.33, 564.33, 564.33, 624.36, 624.36, 624.36, 666.96, 666.96, 666.96],
  },
  hospital: {
    empty: [624.30, 624.30, 624.30, 735.66, 735.66, 735.66, 822.24, 822.24, 822.24],
    current: [377.94, 377.94, 377.94, 386.70, 386.70, 386.70, 381.60, 381.60, 381.60],
    recycling: [604.68, 604.68, 604.68, 687.12, 687.12, 687.12, 771.48, 771.48, 771.48],
  },
  hotel: {
    empty: [667.14, 667.14, 667.14, 766.20, 766.20, 766.20, 811.80, 811.80, 811.80],
    current: [333.42, 333.42, 333.42, 341.16, 341.16, 341.16, 336.60, 336.60, 336.60],
    recycling: [640.20, 640.20, 640.20, 702.96, 702.96, 702.96, 760.86, 760.86, 760.86],
  },
  complex: {
    empty: [469.23, 469.26, 469.26, 588.60, 588.48, 588.51, 671.34, 671.28, 671.31],
    current: [272.82, 272.76, 272.79, 264.99, 264.96, 264.87, 262.41, 262.38, 262.35],
    recycling: [449.22, 449.28, 449.19, 544.47, 544.38, 544.41, 626.70, 626.64, 626.58],
  },
};

/**
 * S造/ dầm thép,	木造/loại gỗ | RC造/cấu trúc RC | SRC造/cấu trúc SRC
 * 再利用なし/không tái sử dụng | 再利用なし/không tái sử dụng | 再利用なし/không tái sử dụng
 */
export const CO2_DISCOUNT_DATA = {
  office: {
    ordinaryConcrete: [0.567, 0.772, 0.696],
    blastFurnaceCementConcrete: [0.567, 0.772, 0.696],
    steelFrame: [0.136, 0.038, 0.1],
    rebar: [0.07, 0.103, 0.078],
  },
  school: {
    ordinaryConcrete: [0.352, 0.865, 0.958],
    blastFurnaceCementConcrete: [0.352, 0.865, 0.958],
    steelFrame: [0.105, 0.005, 0.078],
    rebar: [0.045, 0.112, 0.11],
  },
  merchan: {
    ordinaryConcrete: [0.342, 0.888, 0.307],
    blastFurnaceCementConcrete: [0.342, 0.888, 0.307],
    steelFrame: [0.072, 0.017, 0.071],
    rebar: [0.024, 0.118, 0.053],
  },
  restaurant: {
    ordinaryConcrete: [0.342, 0.888, 0.307],
    blastFurnaceCementConcrete:  [0.342, 0.888, 0.307],
    steelFrame: [0.072, 0.017, 0.071],
    rebar: [0.024, 0.118, 0.053],
  },
  meetingPlace: {
    ordinaryConcrete: [0.345, 0.888, 0.862],
    blastFurnaceCementConcrete: [0.345, 0.888, 0.862],
    steelFrame: [0.139, 0.017, 0.059],
    rebar: [0.04, 0.118, 0.1],
  },
  factory: {
    ordinaryConcrete: [0.354, 0.77, 0.669],
    blastFurnaceCementConcrete: [0.354, 0.77, 0.669],
    steelFrame: [0.088, 0.01, 0.077],
    rebar: [0.031, 0.108, 0.08],
  },
  hospital: {
    ordinaryConcrete: [0.317, 0.766, 0.812],
    blastFurnaceCementConcrete: [0.317, 0.766, 0.812],
    steelFrame: [0.074, 0.012, 0.66],
    rebar: [0.034, 0.096, 0.089],
  },
  hotel: {
    ordinaryConcrete: [0.436, 0.999, 0.816],
    blastFurnaceCementConcrete: [0.436, 0.999, 0.816],
    steelFrame: [0.103, 0.004, 0.084],
    rebar: [0.034, 0.111, 0.093],
  },
  complex: {
    ordinaryConcrete: [0.323, 0.734, 0.75],
    blastFurnaceCementConcrete: [0.323, 0.734, 0.75],
    steelFrame: [0.048, 0.012, 0.052],
    rebar: [0.019, 0.1, 0.136],
  },
};

