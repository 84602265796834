<template>
  <div class="category-table" id="category-table">
    <wj-flex-grid-search ref="theSearch" placeholder="検索語句を入力" id="search-input">
      <v-btn class="search-btn">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </wj-flex-grid-search>
    <div
      v-if="isShowToolbar"
      id="grid-group"
      class="grid-group"
      :class="[isFullScreen && 'grid-fullscreen', isSettingScreen && 'grid-setting-screen', needCompress && 'compressed', isHasDataRegister && 'is-view-data']">
      <div class="top-side">
        <div class="left">
          <div class="toolbar-fullscreen">
          <div class="fullscreen-item">
            <img src="@/assets/icons/icon-fullscreen.svg" alt="">
            <p class="mb-0 fullscreen-text">تكبير الشاشة</p>
          <v-switch class="mt-0 pt-0 fullscreen-switch-btn" v-model="isFullScreens" color="#008FCC"></v-switch>
           </div>
        </div>
        </div>
        <div class="right">
          <undo-redo-comment-data-table />
        </div>
        <div v-if="!hideHeaderRegisterData() && !isFullScreen" class="comment-btn">
          <comment-data-table
            @onHandleShowCommentHistory="onHandleShowCommentHistory" 
            :totalComment="totalComment" 
            :isRegisterData="!hideHeaderRegisterData()" 
          />
        </div>
      </div>
      <div class="comment-side" v-if="!hideHeaderRegisterData() && isFullScreen" >
        <comment-data-table
            @onHandleShowCommentHistory="onHandleShowCommentHistory" 
            :totalComment="totalComment" 
            :isRegisterData="!hideHeaderRegisterData()" 
          />
      </div>
      <div class="duration-side fullmode-duration" v-if="isFullScreen && !isSettingScreen">
          <div class="durations-pulldown-table">
            <label class="durations-label">登録期間</label>
            <div v-if="!isGetAllData" class="duration-text disabled">
              <span>{{ selectedDurationText }}</span>
            </div>
            <select-item
              v-if="isGetAllData"
              class="durations-select-item duration-header"
              label="登録期間"
              :items="durationList"
              v-model="fullModeSelectDurationChange"
              :selectEport= true
              :isFullScreens = "isFullScreens"
            />
          </div>
          <div class="durations-pulldown-table">
            <label class="durations-label">登録年月</label>
            <v-select
              v-if="!isCreateNewData()"
              :items="monthYearSelectionList"
              item-text="text"
              item-value="value"
              v-model="fullModeSelectMonthYear"
              label="選択してください"
              placeholder="選択してください"
              hide-details
              dense
              solo
              class="durations-select-item select-item select-month new-register duration-header"
              :disabled="isCreateNewData()"
              :menu-props="{ contentClass: 'selecting select-durations fullmode' }"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.text }}
                <v-spacer></v-spacer>
                <span v-if="isHasDataRegister && data.item.duration_registered" class="data-is-registered"></span>
              </template>
            </v-select>
            <div v-else class="duration-text disabled"><span>{{ selectedMonthText }}</span></div>
          </div>
      </div>
      <div class="mid-side" v-if="!hideHeaderRegisterData()">
        <search-data-table
          v-if="!hideHeaderRegisterData()" 
          :dataTitle.sync="dataTitle" 
          :isReadOnly="isReadOnlyTitle" 
      />
      </div>
      <div class="bottom-side">
        <action-header-table 
          @onHandleActionTable="onHandleActionTable"
          @needCompressed="onNeedCompressed"
          @onHandleShowExportPopup="onHandleShowExportPopup"
          :workFlowData="dataPattern && Object.keys(dataPattern).length ? dataPattern.workflow_data : {}"
          :isHasData="isHasData"
          :unCheckPermission="dataPattern && Object.keys(dataPattern).length ? dataPattern.unCheckPermission : false"
          :isEditedData="isEditedData"/>
      </div>
    </div>
    <div id="temp-table">
      <div id="undoable-table">
        <wj-flex-grid
          :isDisabled="isDisabled"
          ref="theGrid"
          class="wrap-table column-flexible"
          :style="getStyleTable"
          :allowDragging="false"
          :showMarquee="true"
          :initialized="initGrid"
          :itemsSource="dataSource"
          :allowAddNew="allowAddNew"
          :allowDelete="allowDelete"
          :columnGroups="isSetting ? gridColumns : null"
          :autoRowHeights="false"
          :allowResizing="false"
          keyActionTab="CycleOut"
          :allowMerging="'Cells'"
          showSelectedHeaders="All"
          :quickAutoSize="true"
          :isReadOnly="isReadOnly"
          :autoGenerateColumns="false"
          :imeEnabled=true
          :selectionChanged="changeSelectionCell"
          :loadedRows="loadedRows"
          :cellEditEnded="autoSizeColumn"
          :pasted="handlePasted"
        >
          <wj-flex-grid-filter
            :filterColumns="filterColumns"
            :showSortButtons="false"
            :filterApplied="onFilterApplied"
            :filterChanged="onFilterChanged"
          ></wj-flex-grid-filter>
        </wj-flex-grid>
      </div>
    </div>

    <button-custom
      v-if="showFocus"
      @action="handleFocus"
      className="override-btn"
      icon="focus.svg"
      iconHover="focusHover.svg"
    >تسجيل جديد
    </button-custom>
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
    
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import "@grapecity/wijmo.input";
import "@grapecity/wijmo.vue2.grid.filter";
import * as wjcGrid from "@grapecity/wijmo.grid";
import "@grapecity/wijmo.vue2.grid.detail";
import "@grapecity/wijmo.cultures/wijmo.culture.ja";
import * as wjcCore from "@grapecity/wijmo";
import { mapActions, mapState } from "vuex";
import "@grapecity/wijmo.vue2.grid.search";
import { Control } from "@grapecity/wijmo";
import ButtonCustom from "@/components/commonApp/ButtonCustom";
import ActionHeaderTable from "@/components/commonApp/ActionTable";
import SearchDataTable from "@/components/commonApp/ActionTable/SearchDataTable";
import UndoRedoCommentDataTable from "@/components/commonApp/ActionTable/UndoRedoCommentDataTable";
import CommentDataTable from "@/components/commonApp/ActionTable/CommentDataTable";
import SelectItem from '@/components/utils/selectItem.vue';
import { ROUTES } from '@/router/constants';
import "@grapecity/wijmo.cultures/wijmo.culture.ar-AE.js";
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import { getListCommentHistory } from '@/api/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat'
import { handleResizeGrid } from '@/utils/registerData'

export default {
  data() {
    return {
      isFullScreens: false,
      startIndex: 0,
      endIndex: 0,
      sourceCollection: null,
      theSearch: null,
      theGrid: null,
      dataTable: null,
      idLast: null,
      currentSelection: null,
      flexGrid: null,
      selectionMode: null,
      windowWidth: window.innerWidth,
      isClickFilter: false,
      dataTitle: null,
      commentPopup    : false,
      commentHistoryList: [],
      totalComment: 0,
      needCompress: false,
      heightToolBar: 0,
      listWidth: [],
      listColumns: []
    };
  },
  components: {
    ButtonCustom,
    ActionHeaderTable,
    SearchDataTable,
    UndoRedoCommentDataTable,
    CommentDataTable,
    CommentLogPopup,
    SelectItem
  },
  props: {
    selectMonthYearChange: {
      default: null,
    },
    selectDurationChange: {
      default: null,
    },
    isCreateNewData: {
      type: Function,
      default: () => {
      }
    },
    isGetAllData: {
      type: Boolean,
      default: true
    },
    durationList: {
      type: Array,
      default: () => []
    },
    monthYearSelectionList: {
      type: Array,
      default: () => []
    },
    selectedDurationText: {
      type: String,
      default: '',
    },
    selectedMonthText: {
      type: String,
      default: '',
    },
    dataPattern: {
      type: Object,
      default: () => {
      }
    },
    dataSource: {
      type: Object,
      default: () => {
      }
    },
    initGrid: {
      type: Function,
      default: () => {
      }
    },
    gridColumns: {
      type: Array,
      default: () => []
    },
    filterColumns: {
      type: Array,
      default: () => []
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    totalData: {
      type: Number,
      default: 0
    },
    rowFocus: {
      type: Number,
      default: 0
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    showFocus: {
      type: Boolean,
      default: true
    },
    lastedRow: {
      type: Object,
      default: () => {
      }
    },
    isSetting: {
      type: Boolean,
      default: false
    },
    isHasData: {
      type: Boolean,
      default: false
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    isEditedData: {
      type: Number,
      default: 0
    },
    isReadOnlyTitle: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: Object,
      default: () => {}
    },
    itemsInView: {
      type: Array,
      default: () => []
    },
    cellInput: {
      type: Array,
      default: () => []
    },
    isResizeCustome: {
      type: Boolean,
      default: false
    },
  },
  // connect search box and grid
  mounted() {
    wijmo.culture.FlexGridFilter.null = "(فارغ)";
    this.theGrid = Control.getControl(this.$refs.theGrid.$el);
    this.$emit("flexGridInitialDone", this.theGrid);
    this.theSearch = Control.getControl(this.$refs.theSearch.$el);
    this.actionRemoveListOfEditedCells([]);
    this.theSearch.grid = this.theGrid;
    // focus invalidate cell
    this.theGrid.validateEdits = false;
    this.setRowIndex(this.theSearch.grid);
    this.actionStartUpdate({
      undo: false,
      redo: false
    });
    this.actionUpdateStatusBtn({
      undo: false,
      redo: false
    });
    this.updateActionDelete(false);
    this.actionUpdateDataAction({});
    this.actionUpdate({});
    this.actionUpdateFilter(false);
    // this.actionUpdateIsFullScreen(false);
    this.isFullScreens = this.isFullScreen;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.hideHeaderRegisterData();
    this.dataTitle = this.dataPattern?.workflow_data?.title || sessionStorage.getItem("title");
    this.totalComment = this.dataPattern?.workflow_data?.comment_count || 0;
    window.addEventListener('resize', this.handlerCalcHeight);
  },
  watch: {
    // set width for larger screens 2000
    gridColumns: function(newValue) {
      if (this.windowWidth > 2000) {
        newValue.forEach((ele) => {
          ele.width = "*";
          if (ele.columns) {
            ele.columns.forEach((k) => {
              k.width = "*";
            });
          }
        });
      }
    },

    isFullScreens: function(newState) {
      // waiting DOM updated => calc height
      this.$nextTick(() => {
        this.handlerCalcHeight()
      });
      
     
      this.actionUpdateIsFullScreen(newState);
      this.$emit("changeFullScreen", newState);
    },

    dataSource: function() {
      this.setRowIndex(this.theSearch.grid);
    },

    isError: function() {
      if (!this.isError) {
        this.setRowIndex(this.theSearch.grid);
      }
    },

    isFullScreen: function(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.isFullScreens = this.isFullScreen;
        setTimeout(() => {
          this.handleFocus()
        }, 100)
      }
    }
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter"]),
    ...mapState("registerData", ["listOfEditedCells", "isError", "isFullScreen"]),
    isHasDataRegister() {
      // Handle condition to display dot
      return this.$route.fullPath.includes('/emissions/view/');
    },
    fullModeSelectMonthYear: {
      get() {
        return this.selectMonthYearChange;
      },
      set(newOption) {
        this.$emit("update:value", newOption);
      }
    },
    fullModeSelectDurationChange: {
      get() {
        return this.selectDurationChange;
      },
      set(newOption) {
        this.$emit("update:input", newOption);
      }
    },
    isSettingScreen() {
      return [
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD,
        ROUTES.USER_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE].includes(this.$route.path);
    },
    getStyleTable() {
      if(!this.isFullScreens){
        return ''
      } else {
        return `max-height: calc(100vh - (88px + ${this.heightToolBar}px)) !important`
      }
    }
  },
  methods: {
    ...mapActions("commonApp", ["loadMoreAction", "actionUpdateFilter"]),
    ...mapActions("actionsTable", ["actionStartUpdate"]),
    ...mapActions("actionsTable", ["actionUpdateDataAction"]),
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn", "updateActionDelete"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen", "actionRemoveListOfEditedCells"]),
    onNeedCompressed(needCompress){
      this.needCompress= needCompress;
    },
    getWidthByTextContent(string, isHeader = false){
      let padding = 23
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = "Source Han Sans";
      text.style.fontSize = isHeader ? 13 : 14 + "px";
      if(isHeader) {
        text.style.fontWeight = "500";
      }
      text.innerHTML = string;
      const width = text.clientWidth;
      document.body.removeChild(text);
      return width + padding;
    },
    hideHeaderRegisterData() {
      return this.$route.name !== "NewRegisterData";
    },
    async onHandleShowCommentHistory() {
      this
      if(Object.keys(this.dataPattern).length) {
        const payload = {
        id: this.dataPattern.workflow_data.id
      }
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
          return {
            ...commentHistory,
            date: formatDateTimeDataTable(commentHistory.created_at),
            name: commentHistory.user.name,
            content: commentHistory.content,
          }
        })
      }
      this.commentPopup    = true;
    },
    onHandleActionTable(action, checkHasUserAccessTemplateDetail) {
      this.$emit("onHandleActionTable", action, this.dataTitle, checkHasUserAccessTemplateDetail);
    },
    setWidthForColumns(theGrid, index, width) {
      theGrid.columnHeaders.columns[index].width = width;
      theGrid.columnHeaders.columns[index].minWidth = width;
    },
    getMaxWidthByContent(columns) {
      let resultArrayWidth = [];
      columns.forEach((column) => {
        let indexMaxLengthOfColumn = this.dataSource._view.reduce((indexMaxLengthOfColumn, rowData, index) => {
          return rowData?.[column.binding]?.toString()?.length > this.dataSource._view[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length ? index : indexMaxLengthOfColumn
        }, 0);

        const widthOfCellMaxlengh = this.getWidthByTextContent(this.dataSource._view[indexMaxLengthOfColumn]?.[column.binding])
        resultArrayWidth.push(widthOfCellMaxlengh)
      })
      return resultArrayWidth
    },
    handleResizeAll(theGrid) {
      let listColumn = []
      this.gridColumns.forEach(column => {
        if(column?.columns) {
          column.columns.forEach(subColumn => {
            listColumn.push(subColumn)
          })
        } else {
          listColumn.push(column)
        }
      })
      this.listColumns = listColumn
      const listWidth = this.pattern && this.pattern?.getMaxWidthByContent(this.dataSource._view, listColumn, this.getWidthByTextContent, theGrid)
      this.listWidth = listWidth;
      //this.pattern && this.pattern.handleResizeGrid(theGrid, listColumn, listWidth, this.setWidthForColumns)
      this.pattern && handleResizeGrid(theGrid, listColumn, listWidth, this.setWidthForColumns)
    },
    onHandleShowExportPopup(value) {
      this.$emit('onHandleShowExportPopup', value);
    },
    loadedRows: function(theGrid) {
      setTimeout(() => {
        const self = this;
        // handleResizeAll for s3c1 & s3c4m1-3
        if(this.isResizeCustome) {
          this.handleResizeAll(theGrid)
        } else {
          self.resetAutoSizingGrid(theGrid);
        }
      }, 100)

    },
    resetAutoSizingGrid(theGrid) {
      theGrid.autoSizeColumns();
      theGrid.autoSizeRows(0, 0, true);
      theGrid.columnHeaders.rows.minSize = 34;
    },
    autoSizeColumn: function(theGrid) {
      let self = this;
      setTimeout(() => {
        if(self.isResizeCustome) {
          this.handleResizeAll(theGrid)
        } else {
          self.resetAutoSizingGrid(theGrid);
        }
      }, 50)
    },
    handlePasted(theGrid, event) {
      let self = this;
      setTimeout(() => {
        if(self.isResizeCustome) {
          // self.pattern && self.pattern.handleResizeWhenPasted(theGrid, event, this.listColumns, self.getWidthByTextContent, self.setWidthForColumns)
          this.handleResizeAll(theGrid)
        }
      }, 50)
    },
    changeSelectionCell() {
      this.$emit("onChangeSelected");
    },
    getRowFocusRegisterPage() {
      if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
        const DEFAULT_INDEX = -1;
        const FIRST_INDEX = 0;
        const itemsInView = this.pattern.cellInputInView(this.itemsInView);
        let curentIndex = DEFAULT_INDEX;
        this.dataSource._view.some((item, index) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          if (!everyColumnsAreNull) {
            curentIndex = index
          }
        });
        return curentIndex === DEFAULT_INDEX ? FIRST_INDEX : curentIndex + 1  // +1 for next row
      } else {
        return 0
      }
    },
    getRowFocusListSettingPage() {
      const DEFAULT_INDEX = -1;
      const FIRST_INDEX = 0;
      let curentIndex = DEFAULT_INDEX;
      this.dataSource._view.some((item, index) => {
        const everyColumnsAreNull = this.cellInput.every(
          (field) => item[field] === null || item[field] === '' || item[field] === undefined,
        );
        if (!everyColumnsAreNull) {
          curentIndex = index
        }
      });
      return curentIndex === DEFAULT_INDEX ? FIRST_INDEX : curentIndex + 1  // +1 for next row
    },
    handleFocus() {
      let index = 0;
      let exists = false;
      while (this.dataSource.itemCount > index && !exists) {
        if (this.dataSource.items[index].id === "Blank") {
          exists = true;
        } else {
          index++;
        }
      }

      const listNameRegisterDataRoute = ['NewRegisterData', 'RegisterData']
      const curentIndexFocus = listNameRegisterDataRoute.includes(this.$route.name) ? this.getRowFocusRegisterPage() : this.getRowFocusListSettingPage();
      this.theGrid.selection = new wjcGrid.CellRange(curentIndexFocus, this.rowFocus);
      this.theGrid.startEditing(true, curentIndexFocus, this.rowFocus);
      return

      if (index > 0) {
        this.theGrid.selection = new wjcGrid.CellRange(index, this.rowFocus);
        this.theGrid.startEditing(true, index, this.rowFocus);
        this.scrollTo(index);
        return;
      }

      this.theGrid.selection = new wjcGrid.CellRange(this.dataSource._view.length + 1, this.rowFocus);
      const isHasAddNewRow = this.dataSource._view.filter((record) => {
        return record.id === undefined && record.type !== "lasted-row";
      });
      // only add 1 new row
      if (isHasAddNewRow.length > 0) {
        return;
      }
      this.theGrid.startEditing(true, this.dataSource._view.length, this.rowFocus);
    },

    scrollTo(index) {
      // get cell rect, adjust scrollPosition.y to bring it to the top
      let rc = this.theGrid.cells.getCellBoundingRect(index, 0, true);
      this.theGrid.scrollPosition = new wjcCore.Point(this.theGrid.scrollPosition.x, -rc.top);
    },

    onFilterApplied(s, _) {
      let isFiltering = !s._filters.every((column) => !column.isActive);
      this.actionUpdateFilter(isFiltering);
      if (this.isClickFilter) {
        this.theGrid.scrollIntoView(0, -1); // scroll to first row
      }
    },

    setRowIndex(s) {
      s.itemFormatter = (panel, r, c, cell) => {
        if (panel.cellType === wjcGrid.CellType.Cell && r === 5 && !this.isFilter && !this.isClickFilter) {
        }
        if (panel.cellType === wjcGrid.CellType.RowHeader && !this.isFilter) {
          cell.textContent = (r + 1).toString();
          cell.draggable = true;
        }
        if (panel.cellType === wjcGrid.CellType.RowHeader && this.isFilter) {
          cell.style.justifyContent = "center";
          cell.textContent = (r + 1).toString();
        }

      };
    },

    onFilterChanged() {
      if (this.theGrid.collectionView !== null) {
        this.isClickFilter = true;
      }
    },

    handlerCalcHeight() {
      const element = document.getElementById('grid-group');
      this.heightToolBar = element.offsetHeight - 5; // 5 is top css
      console.log(this.heightToolBar);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handlerCalcHeight)
  },
  destroyed() {
    sessionStorage.setItem("title", "");
    sessionStorage.setItem("duration_id", "");
    sessionStorage.setItem("pattern_id", "");
  }
};
</script>

<style lang="scss">
$table: 1024px;

.fullmode-duration {
  display: flex;
  //TODO
  // justify-content: space-between;
  gap: 8px;
  align-items: center;

  .durations-pulldown-table {
    display: flex;
    flex-direction: row;

    .durations-select-item {
      &.new-register {
        height: 28px;
      }
    }
    align-items: center;
    padding: 0px;
    gap: 8px;

    .durations-label {
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      letter-spacing: 0.03em;
      color: $monoBlack;
    }

    .data-is-registered::after {
      content: '';
      width: 8px;
      height: 8px;
      background: #bfd4d9;
      border-radius: 50%;
      position: relative;
      display: inline-block;
    }

    .duration-text {
      &.disabled {
        border: 1px solid rgba(42, 42, 48, 0.1);
      }
      border-radius: 4px;
      // width: 100%;
      //
      height: 28px;
      display: flex;
      align-items: center;

      span {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        /* identical to box height, or 164% */

        letter-spacing: 0.03em;

        /* Mono/Black */

        color: #404D50;
        &.item-selected {
          color: $red;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
.select-item .v-input__slot {
  height: 28px !important;
}
.data-is-registered {
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background: #bfd4d9;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }
}
/* Tooltip */
.tooltip-custom {
  width: 18px;
  height: 18px;
  background: rgba(121, 134, 134, 0.12) !important;
  border-radius: 50%;
  text-align: center;
  margin-left: 6px !important;

  &:hover {
    background: $goldMid !important;
    color: #fff;
    cursor: pointer;
  }
}

.edit-layer-name,
.switch-layer {
  &:hover {
    color: #fff;
    cursor: pointer;
  }
}
.icon-right {
  margin-left: auto;
  padding-top: 3px;
}

.edit-layer-name {
  width: 20px !important;
  height: 20px !important;
  margin-right: 3px !important;
}
.switch-layer {
  width: 27px !important;
  height: 16px !important;
}


#s3c11 {
  position: absolute;
  top: -25px;
  left: 210px;
}

#s3c10 {
  position: absolute;
  top: -25px;
  left: 220px;
}

.wj-cell.wj-header.wj-header-alt.wj-wrap.wj-filter-off {
  border-top: none !important;
}

.wj-clipboard {
  top: -150% !important;
}

#category-table {
  padding-bottom: -10px !important;
  box-shadow: 0px 23.8132px 27.991px rgba(160, 181, 186, 0.1), 0px 12.7317px 14.9653px rgba(160, 181, 186, 0.15),
  0px 7.13728px 8.38944px rgba(160, 181, 186, 0.22056), 0px 3.79056px 4.45557px rgba(160, 181, 186, 0.265976),
  0px 1.57734px 1.85406px rgba(160, 181, 186, 0.37);
}

.override-btn {
  bottom: 84px;
  right: 40px;

  @media (max-width: $tablet) {
    bottom: 151px;
    right: 37px;
  }

  @media (max-width: $desktop) {
    bottom: 150px;
    right: 20px;
  }
}

#btn-focus {
  right: 20px;
}

.loadClass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

  span {
    display: none !important;
  }
}

:root {
  --width-table: 1366px;
}

.wj-listbox {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }
}

.wj-state-selected {
  background: none !important;
}

.wj-listbox[wj-part='dropdown'] {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
  max-height: 323px !important;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
    min-height: 50px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  background: $monoOffWhite !important;

  .wj-state-selected {
    background: $bgLight !important;
    color: $monoBlack !important;
  }

  .wj-listbox-item {
    max-width: 100% !important;
    border-top: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    min-height: 40px !important;
    padding-top: 6px !important;
  }
}

.drop-down-custom {
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;

  .wj-listbox-item {
    border-top: 1px solid $dividerBlack !important;
    border-right: 1px solid $dividerBlack !important;
  }

  .wj-state-selected,
  .wj-state-last-selected {
    background: #e3eded !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #404d50;
  }
}

.category-table {
  width: calc(100% + 40px);

  #load {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $goldMid;
    cursor: pointer;
    height: 100%;
    margin-top: -5px !important;
    z-index: 5;

    &:hover {
      color: $goldLight;
    }
  }

  ::-webkit-scrollbar {
    height: 15px;
    background: #e3eded;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 8px;
    z-index: 1000;
    border: 3px solid #e3eded;
    border-right: 4px solid #e3eded;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  ::-webkit-scrollbar-corner {
    background: $bgLight !important;
  }

  ::-webkit-scrollbar-button:horizontal:start:decrement {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBbY0hDoNAEEXnD00xreoFUL1AVRUkcCkcDkW4B0dAYzkCARQKiyDsLgvJEiA7ZpL/X94HWS5Jc4/k8sIlLIrn0nQBmHVJNc60ZOErIncPdPkwtGDh3fU8ta0mMdu2D22cZV8W6seK3kbrmLIqy/Efhb0j4QL4kKIBNt1m2f4Klm0sPajfNPkAAAAASUVORK5CYII=');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAwTDAgNEw2IDhWMFoiIGZpbGw9IiM3Qzg5OEMiLz4KPC9zdmc+Cg==');
    }
  }

  ::-webkit-scrollbar-button:horizontal:start:increment {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    border-left: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBbY3NCYRADIUzY5Bl/wrYAqzDBrYVYWGPHjypIJ7EZrx5twgb8CKIDJJEPIzMgB8EAnn5HmR5HR0DF2hS8qJA4rSo4qxtn97RLkqriJf161rQTYqAtXzwfR80XCLhRLR5nwxgKAz68vcfPa0wj/Pj1jdJYs5OQjGM2Nm0yw7wKzFL5K8QXQAAAABJRU5ErkJggg==');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC43NjgzN2UtMDcgOEw2IDRMMS4xMzI1MWUtMDYgLTguMjY2ODZlLTA4TDQuNzY4MzdlLTA3IDhaIiBmaWxsPSIjN0M4OThDIi8+Cjwvc3ZnPgo=');
    }
  }

  /* Apply for checkbox & button */
  .wj-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wj-cell-check {
    margin: unset !important;
  }

  .wj-header.wj-colgroup.wj-align-center {
    border-bottom: none !important;

    &::before {
      content: none !important;
    }
  }

  .wi-cell {
    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }

  .wj-header.wj-colgroup {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }

    &::before {
      content: none !important;
    }
  }

  .wj-header.wj-align-right {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }
  }

  .wj-topleft {
    .wj-cell.wj-header {
      &::before {
        content: none;
      }

      &:hover {
        &::before {
          content: none;
        }
      }

      &.wj-header-alt {
        border-top: unset !important;
      }
    }
  }

  .wj-cell.wj-header {
    background-color: $bgLight !important;
    color: #7c898c !important;
    font-weight: 500 !important;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border-left: none !important;
    border-top: 1px solid $dividerBlack !important;
    overflow: unset;

    &.no-tooltip {
      &::before {
        content: unset;
      }
    }

    .wj-elem-filter {
      margin-left: -4px !important;
      margin-right: 3px !important;
    }

    &.right-to-left-child, &.right-to-left-number {
      .wj-elem-filter {
        margin-left: 3px !important;
        margin-right: 0 !important;
      }
    }

    &.right-to-left {
      .wj-elem-filter {
        margin-left: 3px !important;
        margin-right: 0 !important;
      }
    }


    &.wj-colgroup.wj-filter-on {
      height: 68px !important;
      padding: 0 8px 8px !important;

      .wj-glyph-filter {
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
  }

  .wj-cell.wj-header:not(.single-row) {
    align-items: end !important;
  }

  .single-row {
    align-items: center !important;
  }

  .wj-cell.wj-header[draggable] {
    display: flex;
    justify-content: center;
    border-top: none !important;

    &.wj-state-multi-selected {
      border-right: 2px solid $blueMid !important;
      pointer-events: none;
    }

    &::before {
      content: none !important;
    }
  }

  #temp-table {
    overflow: hidden;
    border: none !important;
  }

  .wrap-table.wj-flexgrid [wj-part='root'] {
    overflow-y: scroll !important;
    max-height: 465px !important;
  }

  .wrap-table {
    border: none !important;
    border-radius: 0 !important;
    background: transparent;
    position: relative;
    overflow: hidden;
    max-height: 465px !important;
    top: 5px;

    .wj-glyph-filter {
      background-image: url('../../assets/icons/filterDefault.svg');
      background-position: center;
      margin-top: 2px;
      width: 20px !important;
      height: 20px !important;
      border: none !important;

      &:after {
        display: none;
      }

      &:hover {
        background-image: url('../../assets/icons/filterHover.svg');
      }
    }

    .wj-filter-on {
      .wj-glyph-filter {
        // transform: scale(1.25);
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
  }

  .custom-colors {
    .wj-glyph-filter {
      color: red;
      font-size: 125%;
    }
  }

  #search-input {
    display: none;
    margin: 15px 0;
    position: relative;

    .search-btn {
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 99;
      background: transparent;
    }

    .wj-input-group-btn {
      display: none !important;
    }
  }

  .wj-cell.wj-header.wj-state-invalid {
    background: $redExtraLight !important;
  }

  .wj-rowheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          border-top: unset !important;
        }
      }
    }
  }
}

.wj-flexgrid {


  .wj-colheaders {
    .wj-cell {
      padding: 0 8px 8px !important;
    }
  }

  .wj-glyph-down {
    border-top: 10px solid $monoDark;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    width: 16px !important;
    height: 16px !important;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  input[type='checkbox']:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    text-align: center;
    width: 16px !important;
    height: 16px !important;
    background: #008fcc;
    border-radius: 4px;
  }

  input[type='checkbox']:checked:after {
    content: url('../../assets/icons/checkedbox.svg');
    color: white;
  }

  .wj-state-invalid[aria-required] {
    /* border: none !important; */
    color: $redMid !important;
  }

  .wj-state-invalid {
    border: none !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .wj-marker {
    width: 1000px !important;
    background-color: rgb(1, 255, 65) !important;
  }

  .wj-cell {
    background: $monoOffWhite !important;
    color: $monoBlack !important;
    font-size: 14px;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
    align-items: center;

    &.cell-disable {
      background: #eef0f0 !important;
      color: #99a6a9 !important;
    }

    &.is-admin-read-only {
      background: $monoExtraLight !important;
      &.wi-header {
        background: $bgLight !important
      }
      &.wj-state-active {
        background: $monoExtraLight !important;
      }

      .wj-cell-check {
        background: rgba(0, 0, 0, 0.38) !important;
      }
    }


    // white-space: pre-wrap;
  }

  .wj-cell-maker {
    display: flex;
    max-height: 23px;
    justify-content: center;
    background: $monoLight;
    border-radius: 4px;
    padding: 1px 6px 3px;
    text-align: center;
    font-size: 13px;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    position: unset !important;
    cursor: pointer;

    button {
      text-align: center;
    }

    &:hover {
      color: $monoWhite;
      background: #0072a3;
    }
  }

  .wj-cell[aria-readonly] {
    background: $monoExtraLight !important;
    color: $monoMid !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;

    &.btn-db {
      display: flex;
      justify-items: center;
      align-items: flex-start;
      background: $monoOffWhite !important;
      color: $monoBlack !important;
      padding: 4px !important;
    }

    &.btn-disabled {
      color: $monoMid !important;
      pointer-events: none;
    }

    &.loadClass {
      background: #f7f7f2 !important;
    }

    &.wj-state-active {
      background: $monoExtraLight !important;
      border: unset !important;
      border-right: 1px solid $dividerBlack !important;
      border-bottom: 1px solid $dividerBlack !important;

      &::after {
        background: unset;
      }
    }

    &.wj-has-changed {
      background: $monoExtraLight !important;
      color: #B28220 !important;
    }

    &.casbee-detail {
      background: $monoOffWhite !important;
      .wj-cell-maker {
        .casbee-btn_custom {
          width: 100%;
          color: $goldMid;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
        }
        &:hover {
          background: #0072A3;
          border: unset;
          .casbee-btn_custom {
            color: $monoWhite;
          }
        }
      }
    }
  }

  .wj-cells .wj-state-active {
    background: #ffffff !important;
    color: #000;
    border: 1px solid #008fcc;
    // bug 5871
    &.auto-increment {
      // background: #eef0f0 !important;
      // border: unset !important;
      // outline: unset !important;
      // border-right: 1px solid rgba(42, 42, 48, 0.1) !important;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
    }
  }

  .custom-btn {
    padding: 2px 10px;
    background: #e4e6e7;
    border-radius: 4px;
  }

  .wj-cell:not(.wj-has-changed, .wj-duplicated-data) {
    padding: 5px 8px !important;

    &.empty {
      .wj-btn {
        display: none !important;
      }
    }

    &.auto-increment {
      background: $monoExtraLight !important;
      color: $monoMid !important;
    }

    &.emission {
      background: $redUltraLight !important;
      color: $goldMid !important;
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }
  .wj-cell.wj-has-changed {
    padding: 5px 8px !important;

    &.empty {
      .wj-btn {
        display: none !important;
      }
    }

    &.auto-increment {
      background: $monoExtraLight !important;
      color: #B28220 !important;
    }

    &.emission {
      background: $redUltraLight !important;
      color: $goldMid !important;
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }
}

.facility-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.center-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.wj-flexgrid {
  overflow: invisible !important;

  .wj-colheaders .wj-header.wj-state-multi-selected {
    box-sizing: padding-box !important;
    border-bottom: 2px solid #008fcc !important;
  }

  .wj-cells .wj-cell .wj-state-active {
    background: transparent;
    color: #000;
    border: 2px solid rgb(15, 228, 69);

    &::after {
      content: '';
      width: 5px;
      height: 5px;
      background: rgb(7, 115, 204);
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .wj-colheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          border-bottom: unset !important;
          &.right-to-left {
            justify-content: right !important;
            flex-direction: row-reverse !important;
            background: #e3eded !important;
            &.emission {
              background: #ebe0e0 !important;
            }
            &.wj-colgroup {
              justify-content: center !important;
            }
          }
          &.right-to-left-number {
            justify-content: right !important;
            flex-direction: row !important;
            background: #e3eded !important;
            &.wj-colgroup {
              justify-content: center !important;
            }
          }
        }
      }
    }
  }

  // style for table readonly
  &.wj-state-readonly {
    .wj-colheaders .wj-header.wj-state-multi-selected {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
    }
  }
}

.table-toolbar {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  background: #e3eded;
  margin-bottom: -5px;
  justify-content: space-between;
  gap: 8px;

  .button-action-table-first {
    display: flex;
  }

  .toolbar-fullscreen {
    .fullscreen-item {
      display: flex;
      gap: 4px;
      margin-right: 10px;

      .fullscreen-text {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #404d50;
      }

      .fullscreen-switch-btn {
        height: 16px;

        .v-input__control {
          height: 16px;

          .v-input--selection-controls__input {
            height: 20px !important;
            margin-right: 0 !important;
            width: 27px !important;

            input {
              height: 16px !important;
            }

            .v-input--switch__track {
              width: 27px !important;
              background: #7C898C !important;
            }

            .v-input--switch__thumb {
              width: 16px !important;
              height: 16px !important;
              margin-top: 2px !important;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
            }
          }
        }

        &.v-input--is-label-active {
          .v-input__control {
            .v-input--selection-controls__input {
              .v-input--switch__track {
                background: #008FCC !important;
              }

              .v-input--switch__thumb {
                margin-left: -4px !important;
              }
            }
          }
        }
      }

      &:first-child {
        padding: 4px 6px 4px 4px;
        background: #f7f7f2;
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 4px;
        width: 140px;
      }
    }
  }

  .toolbar-undo {
    // width: calc(100% - 140px);
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1023px) {
  .category-table {
    margin-left: -20px !important;
    overflow: hidden;
  }
}

@include desktop {
  .table-toolbar {
    flex-direction: row;
    padding: 8px 40px;
    height: 44px;
  }
  .category-table {
    width: 100%;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 40px;
}
.duration-header {
  &.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 28px;
  }
}

.wj-cell.wj-has-changed {
  color: #B28220 !important;
}

.wj-cell.wj-has-changed-mark, .wj-cell.wj-has-unread-mark {
  overflow: hidden !important;
}

.wj-cell.wj-has-changed-mark:after, .wj-cell.wj-has-unread-mark:after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  top: -10px;
  border: 10px solid transparent;
  border-left: 10px solid #E0A329;
}

.wj-cell.wj-has-unread-mark:after {
  border-left: 10px solid #132D39;
}

.wj-tooltip:has(.wj-cell-changed) {
  padding: 2px 10px 4px;
  background: #E0A329;
  color: #FFF;
  border: 1px solid #B28220;
  box-shadow: unset;
}

.wj-cell.wj-has-changed[aria-readonly] {
  background: $monoExtraLight !important;
  color: #B28220 !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 24px;
  border-right: unset !important;
  border-bottom: unset !important;
  align-items: center;
  gap: 6px;
  padding: 5px 8px 5px !important;
  border-right: 1px solid $dividerBlack !important;
  border-bottom: 1px solid $dividerBlack !important;

  &.btn-db {
    display: flex;
    justify-items: center;
    align-items: flex-start;
    background: $monoOffWhite !important;
    color: $monoBlack !important;
    padding: 4px !important;
  }

  &.btn-disabled {
    color: $monoMid !important;
    pointer-events: none;
  }

  &.loadClass {
    background: #f7f7f2 !important;
  }

  &.wj-state-active {
    background: $monoExtraLight !important;
    border: unset !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;

    &::after {
      background: unset;
    }
  }
}
// duplicate data
.wj-cell {
  &.wj-duplicated-data {
    color: $redMid !important;
    &.auto-increment {
      color: $redMid !important;
    }
  }
  &.wj-duplicated-data[aria-readonly] {
    background: $monoExtraLight !important;
    color: $redMid !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
  }
}
 
.grid-group{
  grid-gap: 32px;
  &.grid-fullscreen {
    grid-gap: 8px;
    .top-side {
      .right {
      margin-right: 24px;
    }
    }
  }
  background: $bgLight;
  padding: 8px 20px;
  margin-bottom: -5px;
  display: grid;
    grid-template-areas: 
    "top top top"
    "mid"
    "bot bot";
  .top-side{
    // grid-area: top;
    display: flex;
    // TODO
    grid-gap: 8px;
    // justify-content: space-between;
    .left{
      min-width: 140px;
      .fullscreen-item {
        display: flex;
        gap: 4px;

        .fullscreen-text {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: $monoBlack; 
          white-space: nowrap;
        }

        .fullscreen-switch-btn {
          height: 16px;

          .v-input__control {
            height: 16px;

            .v-input--selection-controls__input {
              height: 20px !important;
              margin-right: 0 !important;
              width: 27px !important;

              input {
                height: 16px !important;
              }

              .v-input--switch__track {
                width: 27px !important;
                background: $monoDark !important;
              }

              .v-input--switch__thumb {
                width: 16px !important;
                height: 16px !important;
                margin-top: 2px !important;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
              }
            }
          }

          &.v-input--is-label-active {
            .v-input__control {
              .v-input--selection-controls__input {
                .v-input--switch__track {
                  background: $blueMid !important;
                }

                .v-input--switch__thumb {
                  margin-left: -4px !important;
                }
              }
            }
          }
        }

        &:first-child {
          padding: 4px 6px 4px 4px;
          background: $monoOffWhite;
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-radius: 4px;
          width: 140px;
        }
      }
    }
    .right{
      //TODO
      // margin-right: 24px;
      // flex-grow: 1;
      display: flex;
      justify-content: space-between;
    }
  }
  .mid-side{
    grid-area: mid;
  }
  .bottom-side{ 
    grid-area: bot;
    display: flex;
    justify-content: space-between;

  }
  .comment-side {
    margin-left: auto;
  }
  .duration-side {
    grid-area: duration;
  }
}

@media (max-width: 1023px) {
.fullmode-duration {
  .durations-pulldown-table {
    width: 50%;
    .durations-label {
      display: none;
    }
    .durations-select-item {
      width: 100%;
    }
    .duration-text {
      width: 100%;
    }
  }
  .duration-header {
    height: 28px;
  }
}
    .grid-group{
      &.compressed{
        grid-template-areas: 
        "top comment bot"
        "mid mid mid";
        grid-template-columns: 1fr auto;
        .bottom-side{ 
          display: flex;
          justify-content: flex-end;
          grid-gap: 8px;
        }
      }
      grid-gap: 8px;
      grid-template-areas: 
        "top top"
        "mid mid"
        "bot bot";
      grid-template-columns: 1fr auto;
      .comment-btn {
        margin-left: auto;
      }
      .bottom-side {
          display: flex;
          justify-content: flex-end;
          grid-gap: 8px;
      }
      &.grid-fullscreen {
        grid-template-areas: 
          "top top top"
          "duration duration duration"
          "mid mid mid"
          "bot bot bot";
        &.compressed {
          grid-template-areas: 
            "top comment"
            "duration duration duration"
            "mid mid mid"
            "bot bot bot";
            &.is-view-data {
              grid-template-areas: 
              "top top top"
              "duration duration duration"
              "bot bot bot";
            }
          }
      }
      &.grid-setting-screen {
      grid-template-areas: 
        "top mid bot"
    }
    }
}

@media (min-width: 1024px) {
  .fullmode-duration {
    .durations-pulldown-table {
      .duration-header {
        height: 28px;
      }
      height: 28px;
      .duration-text {
        width: 271px;
        min-width: 271px;
      }
      .durations-select-item {
        width: 271px;
        &.new-register {
          height: 28px;
        }
      }
    }
  }
  .grid-group{
    grid-template-areas: "top mid bot";
    grid-template-columns: auto 1fr auto;
    &.grid-fullscreen {
      grid-template-areas: 
      "top duration comment"
      "mid mid mid"
      "bot bot bot";
      &.compressed {
        grid-template-areas: 
        "top duration comment"
        "mid mid mid mid"
        "bot bot bot";
        grid-gap: 4px;
        // &.is-view-data {
        //   grid-template-areas: 
        //   "top duration"
        //   "bot bot";
        // }
      }
    }
    &.grid-setting-screen {
      grid-template-areas: 
        "top mid bot"
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1276px) {
  .grid-group {
    &.grid-fullscreen {
      &.is-view-data {
        grid-template-areas: 
          "top"
          "duration"
          "bot";
      }
    }
  }
  .fullmode-duration {
    .durations-pulldown-table {
      .durations-select-item {
        width: 140px;
      }
      .duration-text {
        width: 140px;
      }
    }
  }
  .is-view-data {
    .fullmode-duration {
    .durations-pulldown-table {
      .durations-select-item {
        width: 300px;
      }
      .duration-text {
        width: 300px;
      }
    }
  }
  }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .fullmode-duration {
    display: flex;
    flex-direction: column;
    .durations-pulldown-table {
      width: 100%;
    }
}
}
</style>
