<template>
  <div class="listMenu-page padding-top-layout padding-right-layout list-menu">
    <div class="list-menu-title">
      <page-title title="سجل بيانات الانبعاثات الجديدة" />
      <common-button v-ripple="false" class="return__header" @action="gotoListRegistered()" label="العودة إلى قائمة التسجيل">
        <div class="list-emission-return"
        >
          <img
            class="mr-0 return"
            src="@/assets/images/dialog/return.svg"
          />
          <img
            class="mr-0 return-active"
            src="@/assets/images/dialog/return_active.svg"
          />
        </div>
      </common-button>
    </div>
    <p class="list-menu--title">
      أدخل المعلومات الأساسية لتسجيل بيانات الانبعاثات الجديدة.
      <br />
      يمكن تغيير جميع العناصر بعد التسجيل باستثناء "النطاق / الفئة".
    </p>
    <div class="listMenus-btn">
      <div class="title-btn">
        <span>عنوان</span>
      </div>
      <div class="listMenu-btn-text">
        <input id="title" class="input-elic mb-0 input-rtl" v-model="title" />
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn title-btn-select-scope">
        <span>النطاق / الفئة</span>
        <div class="title-require">هذا إلزامي</div>
      </div>
      <common-button
        class="listMenu-scope"
        :class="(scope_select && scope_select.scope) && 'has-scope'"
        @action="openScopePopup"
        :label ="labelScopeCategory"
        v-model="scope_select"
        v-ripple="false"
      />
      <div class="csv-description-download mt-2" v-if="isCsvCreate && methodList.length === 0 && labelScopeCategory !== '選択してください'" @click="handleDownloadCsvSample">CSVテンプレートをダウンロード</div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <template>
        <div class="title-btn">
          <span>طرق الحساب</span>
          <div class="title-require">هذا إلزامي</div>
        </div>
        <div class="listMenu-btn mb-0">
          <div class="listMenu-btn-text">
            <v-select
              solo
              flat
              :label="!isShowMethod ? '該当なし' : 'اختر من فضلك'"
              v-model="methods_select"
              :items="methodList"
              :class="{'disabled': methodList.length === 0}"
              :disabled="methodList.length === 0"
              :menu-props="{ contentClass: 'select-menu' }"
              class="select-type select-item list-menu--select mb-4 select-method"
            />
            <v-select
              v-if="isShowMethod"
              solo
              flat
              label="اختر من فضلك"
              v-model="methods2_select"
              :items="methodList2"
              :class="{'disabled': methodList2.length === 0}"
              :disabled="methodList2.length === 0"
              :menu-props="{ contentClass: 'select-menu' }"
              class="select-type select-item list-menu--select select-method"
            />
          </div>
          <div
            class="csv-description-download mt-2" 
            v-if="isCsvCreate && ((methods_select !== '' && methodList2.length === 0) || (methodList2.length > 0 && methods2_select !== '')) && labelScopeCategory !== '選択してください'" 
            @click="handleDownloadCsvSample"
          >
            CSVテンプレートをダウンロード
          </div>
        </div>

        <div class="listMenu-icon">
          <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
        </div>
      </template>

      <div class="title-btn">
        <span>تاريخ التسجيل</span>
        <div class="title-require">هذا إلزامي</div>
      </div>
      <div class="listMenu-btn">
        <div class="listMenu-btn-text">
          <v-select
            solo
            flat
            :items="monthList"
            v-model="date_month_select"
            label="اختر من فضلك"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu' }"
          ></v-select>
        </div>
      </div>
      <div v-if="isCsvCreate" class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div v-if="isCsvCreate" class="title-btn">
        <span>CSVファイル</span>
        <div class="title-require">هذا إلزامي</div>
      </div>
      <div class="csv-file">
        <div v-if="isCsvCreate" class="csv-input"
          v-on:dragenter="dragEnter"
          v-on:dragover="dragOver"
          v-on:dragleave="dragLeave"
          v-on:drop="dropEvent">
          <div class="import-csv">
            <img :src="getIcon('upload')" alt="" class="upload-icon" />
            <div class="csv-input-wrapper">
              <input type="file" accept=".csv" id="csv" ref="csvInput" class="d-none" @change="handleAddFile"> 
              <div class="csv-input-text">ファイルをここにドラッグアンドドロップ</div>
            </div>
          </div>
          <common-button
            class="button-import"
            label="ファイルを選択"
            type="colored"
            @action="$refs.csvInput.click()"
            v-ripple="false"
          />
        </div>
        <div v-if="isCsvCreate && csvFilename !== ''" class="csv-filename">
          <span class="csv-filename-detail">{{ csvFilename }}</span>
          <div class="remove-icon">
            <img class="remove-icon-detail" :src="getIcon('remove')" alt=""  @click="handleDeleteFile" /> 
          </div>
        </div>
      </div>
      <div class="csv-download">
        <div class="csv-description" v-if="isCsvCreate">最大アップロードサイズ: 5MB</div>
        <!-- <div class="csv-description-download" :class="isDisableDownLoad() && 'disable'" v-if="isCsvCreate" @click="handleDownloadCsvSample">CSVテンプレートをダウンロード</div> -->
      </div>
      
      <scope-category-popup
        :dialog="isPopup"
        @close="closePopup"
        @selectedScope="selectedScope"
      />
    </div>
    <div class="list-menu-registration">
      <common-button
        class="submit-register-btn"
        label="يسجل"
        v-model="scope_select"
        :disabled="submitRegisterHandler()"
        type="colored"
        @action="redirectToNewRegisterData()"
        v-ripple="false"
      />
    </div>
    <div class="return__button-responsive">
      <common-button v-ripple="false" class="return__header-responsive" @action="gotoListRegistered()" label="العودة إلى قائمة التسجيل">
        <div class="list-emission-return-responsive"
        >
          <img
            class="mr-0 return"
            src="@/assets/images/dialog/return.svg"
          />
          <img
            class="mr-0 return-active"
            src="@/assets/images/dialog/return_active.svg"
          />
        </div>
      </common-button>
      <div class="registration-responsive">
        <common-button
          class="submit-register-btn"
          label="يسجل"
          v-model="scope_select"
          :disabled="submitRegisterHandler()"
          type="colored"
          @action="redirectToNewRegisterData()"
          v-ripple="false"
        />
      </div>
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="dialogNotification = false"/>
    <ErrorPopup :dialog="dialogCsv" isErrorImg :message="messageNotification" @submit="handleClosePopupCsv"/>
    <ErrorPopup v-if="limit !== messageLimit" :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false"/>
    <notification-popup v-if="limit === messageLimit" :dialog="dialogLimit" :message="messageLimit" @submit="dialogLimit = false"/>

  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ROUTES } from "@/router/constants";
import { SCOPE, CATEGORY } from '@/constants/registerData'
import PageTitle from "@/components/pageTitle/pageTitle";
import CommonButton from "@/components/utils/button.vue";
import { getDurations } from "@/api/duration";
import ScopeCategoryPopup from "@/components/dialogs/scope-category-popup";
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import chardet from 'chardet';
import { scopeCategoriesData } from '@/constants/export-report';
import { getExportTemplateCsv } from "@/api/export-report";
import moment from "moment";

export default {
  name      : "EmissionHomeRegistration",
  components: {
    PageTitle,
    CommonButton,
    ScopeCategoryPopup,
    NotificationPopup,
    ErrorPopup
  },
  data() {
    return {
      breadcrumb             : [
        {
          text    : "الصفحة الرئيسية",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "تسجيل الانبعاثات",
          disabled: false,
          href    : ROUTES.EMISSIONS
        },
        {
          text    : "سجل بيانات الانبعاثات الجديدة",
          disabled: true,
          href    : ROUTES.EMISSIONS
        }
      ],
      method                 : null,
      labelScopeCategory     : "اختر من فضلك",
      currentSelectedLayer2  : null,
      isHidden               : false,
      radioDialog            : false,
      radioDialogCategory    : false,
      radioType              : "選択してください",
      dialogCancelBtn        : false,
      currentSelected        : 1,
      currentSelectedCategory: 1,
      items                  : [{
        text : "申請状況確認",
        path : ROUTES.LIST_EMISSION_SUBMITTED,
        image: "user.svg"
      }],
      monthList           : [],
      listVisible            : [],
      methodList             : [],
      methodList2            : [],
      title                  : "",
      scope_select           : {
        cateId: "",
        scope : ""
      },
      category_select        : "",
      methods_select         : "",
      methods2_select        : "",
      date_month_select      : "",
      dataPageEdit           : false,
      dataPageTemplateList   : false,
      isPopup                : false,
      dialogNotification: false,
      messageNotification: 'データに不具合があるため、取り込みできませんでした。\nCSVファイルの中身を確認してください。',
      dialogLimit: false,
      messageLimit: 'このファイルはアップロードサイズ上限を超えているため、取り込みできませんでした。\n\n※最大アップロードサイズ: 5MB',
      csvFilename: '',
      csvFileData: '',
      dialogCsv: false,
      categoryName: '',
      limitSize: false,
      limit: 'このファイル形式には対応しておりません。\n.csvの拡張子のファイルをアップロードしてください。'
    };
  },
  async mounted() {
    await this.updateBreadCrumb(this.breadcrumb);
    await this.getDuration();

    if(this.$route.query?.isErrorCsv) {
      const dataQuery = this.$route.query;
      this.scope_select = dataQuery.scope_select;
      this.date_month_select = parseInt(dataQuery.month);
      this.title = dataQuery.title;
      this.selectedScope(this.scope_select, dataQuery.categoryName)
      this.methods_select = dataQuery.methods;
      this.methods2_select = dataQuery.methods2;
      this.dialogCsv = true;
    }
  },

  computed: {
    ...mapState("userData", ["currentUser"]),

    isCsvCreate() {
      return this.$route.query?.csv === 'true' ? true : false;
    },

    isShowMethod() {
      if (!this.$route.query?.csv) return true

      if(!this.scope_select?.scope ) return true

      if(this.scope_select && this.methodList.length === 0) return false

      return true
    },
  },
  watch   : {
    methods_select(value) {
      this.methodList2 = [];
      if (value === "輸送") {
        this.methodList2 = ["燃料法・配送料金で算定", "tkm法で算定", "燃費法で算定"];
      }
      if (value === "拠点") {
        this.methodList2 = ["燃料", "電気"];
      }
      if (value === "直接使用段階の排出量から算定") {
        this.methodList2 = ["エネルギー使用製品", "燃料・フィードストック", "GHG含有製品で、使用時にGHGを排出するもの"];
      }
      this.methods2_select = this.$route.query?.methods2 || ""
    },
    scope_select() {
      this.methods_select = this.$route.query?.methods || ""
      this.methods2_select = this.$route.query?.methods2 || ""
    }
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("registerData", ["updateDataMonth", "updateDuration", "updateYearSelected"]),
    ...mapActions("newRegisterData", ["updateMethodLayer1Id", "updateMethodLayer2Id"]),
    gotoListRegistered(){
      return this.$router.push({
        path: `${ROUTES.EMISSIONS}`
      });
    },
    async redirectToNewRegisterData() {
      sessionStorage.setItem("title", this.title);
      sessionStorage.setItem("duration_id", this.selectedDuration);
      sessionStorage.setItem("pattern_id", this.methods_select);
      const existSelected = this.monthList.find(item => item.value === this.date_month_select);
      sessionStorage.setItem("csvImportEvent", this.csvFileData);
      await Promise.all([
        this.updateDuration(this.selectedDuration),
        this.updateDataMonth(this.date_month_select),
        this.updateYearSelected(existSelected.text.split('年')[0]),
        this.getMethodsId(),
      ]);
      if(this.isCsvCreate) {
        return this.$router.push({
          path: `${ROUTES.EMISSIONS}/${ROUTES.REGISTER}/${this.scope_select.scope}/${this.scope_select.cateId}`,
          query: {
            import: true,
            title: this.title,
            scope_select: this.scope_select,
            methods: this.methods_select,
            methods2: this.methods2_select,
            month: this.date_month_select,
            categoryName: this.categoryName
          }
        });
      } else {
        return this.$router.push({
          path: `${ROUTES.EMISSIONS}/${ROUTES.REGISTER}/${this.scope_select.scope}/${this.scope_select.cateId}`,
          query: { import: true }
        });
      }
    },
    selectedScope(getScopesAndCategories, categoryName) {
      this.categoryName = categoryName;
      this.labelScopeCategory = this.getLabelScopeCategory(getScopesAndCategories, categoryName);
      this.scope_select       = getScopesAndCategories;

      //check scope, category
      if (this.scope_select.scope === 1) {
        this.methodList = ["طرق الوقود", "طريقة طن كيلومتر", "طريقة استهلاك الوقود"];
      }
      if ([2, 4].includes(this.scope_select.scope)) {
        this.methodList  = [];
        this.methodList2 = [];
      }
      if (this.scope_select.scope === 3) {
        if ([1, 2, 3, 5, 8, 10, 12, 13, 14, 16].includes(this.scope_select.cateId)) {
          this.methodList  = [];
          this.methodList2 = [];
        }
        if (this.scope_select.cateId === 4) {
          this.methodList = ["輸送", "拠点", "荷主責任報告値を用いる方法"];
          if (this.methods_select === "輸送") {
            this.methodList2 = ["燃料法・配送料金で算定", "tkm法で算定", "燃費法で算定"];
          }
          if (this.methods_select === "拠点") {
            this.methodList2 = ["燃料", "電気"];
          }
        }
        if (this.scope_select.cateId === 6) {
          this.methodList = [
            "旅客人キロから算定",
            "燃料法で算定",
            "燃費法で算定",
            "支給額から算定", //s3c6 old
            "宿泊数から算定",
            "出張日数から算定",
            "従業員数から算定"
          ];
        }
        if (this.scope_select.cateId === 7) {
          this.methodList = [
            "旅客人キロから算定",
            "燃料法で算定",
            "燃費法で算定",
            "支給額から算定",
            "従業員数・営業日数から算定"
          ];
        }
        if (this.scope_select.cateId === 15) {
          this.methodList = ["株式投資", "プロジェクトファイナンスによる融資", "債券投資", "管理投資他"];
        }
        if (this.scope_select.cateId === 9) {
          this.methodList = ["輸送", "拠点"];
          if (this.methods_select === "輸送") {
            this.methodList2 = ["燃料法・配送料金で算定", "tkm法で算定", "燃費法で算定"];
          }
          if (this.methods_select === "拠点") {
            this.methodList2 = ["燃料", "電気"];
          }
        }
        if (this.scope_select.cateId === 11) {
          this.methodList = ["直接使用段階の排出量から算定", "間接使用段階の排出量から算定"];
          if (this.methods_select === "直接使用段階の排出量から算定") {
            this.methodList2 = ["エネルギー使用製品", "燃料・フィードストック", "GHG含有製品で、使用時にGHGを排出するもの"];
          }
        }
      }

      this.isPopup = false;
    },
    getLabelScopeCategory(scopeAndCategory, categoryName) {
    if (scopeAndCategory.scope > SCOPE.SCOPE_2) {
      if (scopeAndCategory.cateId === CATEGORY.CATEGORY_16) {
        return `Scope ${scopeAndCategory.scope} > ` + categoryName;
      } else if (scopeAndCategory.scope === SCOPE.SCOPE_4) {
        return `その他 > ` + categoryName;
      } else {
        return (
          `Scope ${scopeAndCategory.scope} > ${scopeAndCategory.cateId}. ` +
          categoryName
        );
      }
    } else {
      return `Scope ${scopeAndCategory.scope} > ` + categoryName;
    }
  },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    async getDuration() {
      await getDurations().then((res) => {
        const selectedDuration = res.data.find((item) => item.is_selected === true);
        if (selectedDuration) {
          this.selectedDuration = selectedDuration.id;
          this.getMonthList(selectedDuration.start_at, selectedDuration.end_at);
        }
      });
    },
    openScopePopup() {
      this.isPopup = true;
    },
    closePopup() {
      this.isPopup = false;
    },
    submitRegisterHandler() {
      return this.date_month_select === "" ||
        this.scope_select.cateId === "" ||
        this.scope_select.scope === "" ||
        (this.methodList.length > 0 && this.methods_select === "") ||
        (this.methodList2.length > 0 && this.methods2_select === "") ||
        (this.isCsvCreate && !this.csvFileData);
    },
    getMonthList(startAt, endAt) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat =  `${moment.utc( durationStartAt).format("YYYY/M")}`;

      this.monthList = [{
          text: timeFormat,
          value: durationStartAt.getMonth() + 1
      }];

      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = `${moment.utc( durationStartAt).format("YYYY/M")}`;
        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1
        }
        this.monthList.push(currentMonthYear);
      }
    },
    getMethodsId() {
      let methodLayer1Id = null;
      let methodLayer2Id = null;
      if (this.scope_select.scope === 1) {
        const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
        methodLayer1Id = indexMethod + 1;
      } else if (this.scope_select.scope === 3) {
        if (this.scope_select.cateId === 4) {
          if (this.methods_select === "輸送") {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 4;
          } else if (this.methods_select === "拠点") {
            methodLayer1Id = 2;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 7;
          } else {
            methodLayer1Id = 3;
          }
        } else if ([6, 7, 15].includes(this.scope_select.cateId)) {
          const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
          methodLayer1Id = indexMethod + 1;
        } else if (this.scope_select.cateId === 9) {
          if (this.methods_select === "輸送") {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 1;
          }
          if (this.methods_select === "拠点") {
            methodLayer1Id = 2;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 4;
          }
        } else if (this.scope_select.cateId === 11) {
          if (this.methods_select === "直接使用段階の排出量から算定") {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 1;
          } else {
            methodLayer1Id = 2;
          }
        }
      } else {
        methodLayer1Id = 1;
      }

      this.updateMethodLayer1Id(methodLayer1Id);
      this.updateMethodLayer2Id(methodLayer2Id);
    },
    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},
    hasNonAsciiCharacters(inputText) {
      const nonAsciiRegex = /[^\x00-\x7F]/;
      return nonAsciiRegex.test(inputText);
    },
    handleAddFile(event) {
      if (event.target.files[0]) {
        console.log(event.target.files[0]);
        const fileName = event.target.files[0].name
        const fileSize = event.target.files[0].size
        const fileType = fileName.split('.')[1]
        if (fileType !== 'csv') {
          this.limitSize = true
          this.messageLimit = 'このファイル形式には対応しておりません。\n.csvの拡張子のファイルをアップロードしてください。';
          this.dialogLimit = true;
          return;
        } else if (fileSize > 5000000) {
          this.limitSize = true
          this.messageLimit = 'このファイルはアップロードサイズ上限を超えているため、取り込みできませんでした。\n\n※最大アップロードサイズ: 5MB';
          this.dialogLimit = true;
          return;
        } else {
          this.csvFilename = fileName;
          const reader = new FileReader();
          reader.onload = () => {
            const encoding = chardet.detect(new Uint8Array(reader.result));
            const decoder = new TextDecoder(encoding);
            this.csvFileData = decoder.decode(new Uint8Array(reader.result));
          };
          reader.readAsArrayBuffer(event.target.files[0]);
          this.$refs.csvInput.value = ''
        }
        
      }
    },
    async dropEvent(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file.type !== "text/csv") {
        this.limitSize = true
        this.messageLimit = 'このファイル形式には対応しておりません。\n.csvの拡張子のファイルをアップロードしてください。';
        this.dialogLimit = true;
        return;
      } else if (file?.size > 5000000) {
        this.limitSize = true
        this.messageLimit = 'このファイルはアップロードサイズ上限を超えているため、取り込みできませんでした。\n\n※最大アップロードサイズ: 5MB';
        this.dialogLimit = true;
        return;
      } else {
        this.csvFilename = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const encoding = chardet.detect(new Uint8Array(reader.result));
          const decoder = new TextDecoder(encoding);
          this.csvFileData = decoder.decode(new Uint8Array(reader.result));
        };
        reader.readAsArrayBuffer(event.dataTransfer.files[0]);
        this.$refs.csvInput.value = ''
        // TODO: call api check csv file
      }
    },
    handleClosePopupCsv() {
      this.dialogCsv = false;
      this.$router.replace({ path: '/emissions/list-menu/?csv=true'})
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
    },
    isDisableDownLoad() {
      return  this.scope_select.cateId === "" ||
        this.scope_select.scope === "" ||
        (this.methodList.length > 0 && this.methods_select === "") ||
        (this.methodList2.length > 0 && this.methods2_select === "")
    },
    handleDownloadCsvSample() {
      if(this.isDisableDownLoad()) return

      let pattern = scopeCategoriesData.find(item => item.scope === parseInt(this.scope_select.scope) && item.categories ===  parseInt(this.scope_select.cateId))
      if(this.methods_select) {
        pattern = pattern.methods.find(method => method.text === this.methods_select)
      }
      if(this.methods2_select) {
        pattern = pattern.methods.find(method2 => method2.text === this.methods2_select)
      }

      const row_num_pattern = pattern.row_num_pattern
      //todo: getExportTemplateCsv(num, contractor)
      getExportTemplateCsv(row_num_pattern, this.currentUser.user?.contractor_id).then((res) => {
        let filename = ''
        const SCOPE3_CATEGORY16 = 45
        const SCOPE4 = 46

        if(row_num_pattern === SCOPE3_CATEGORY16) {
          filename = 'Scope 3_16-その他のGHG排出量'
        } else if(row_num_pattern === SCOPE4) {
          filename = 'Scope 4_国内外の認証排出削減量'
        } else {
          const names = this.labelScopeCategory.split(' > ')
          filename = `${names[0].trim()}_${names[1].trim()}`
          filename = filename.replace('. ', '-')
          const method2 = this.methods2_select
          const method = this.methods_select
          if(method && !method2) {
            filename += `_${method}`
          }
          if(method2) {
            filename += `_${method2}`
          }
        }
        const time = moment().format("YYYYMMDD");
        filename += `_${time}.csv`

        const url = window.URL.createObjectURL(
          new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }
};
</script>
<style lang="scss">
.select-type .v-input__slot {
  background: #f7f7f2 !important;
}
.list-menu {
  .select-method {
    &.disabled {
      .v-input__slot {
      background: rgba(121, 134, 134, 0.12) !important;
      input {
        background: unset !important;
      }
    }
    }
  }
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-label {
          &.theme--light {
            left: auto !important;
            right: 40px !important;
            position: absolute;
          }
        }
        .v-select__selections{
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
  display: flex;
  justify-content: flex-end;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%
}
.listMenu-scope.has-scope  .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope .v-btn__content div{
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
.select-menu {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: #F7F7F2;
  }
  &::-webkit-scrollbar-thumb {
    background: #BFD4D9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 180px !important;
  margin-top: 45px !important;
  .theme--light.v-list{
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #E3EDED;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-table {
  padding-left: 20px !important;
}
.listMenu-page {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;
    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 0px;
    /* Background/Light */
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;
      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 10px;
      width: 100%;
      height: max-content !important;
      &.has-scope {
        color: $monoBlack;
      }
      &:hover {
        color: $monoWhite;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: 60px;
        height: 20px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .listMenus-btn {
    padding: 15px 20px;
  }

  .csv-file {
    background: #F7F7F2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    margin-top: 10px;
  }
  .csv-input {
    background: $monoOffWhite;
    // box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    // border-radius: 4px;
    height: 252px;
    // margin-top: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    .import-csv {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 164px;
      width: 100%;
      border: 1px dashed #C8DEEA;
      padding: 32px 8px;
      gap: 8px;
      .upload-icon {
        width: 50px;
        height: 64px;
      }
      .csv-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .csv-input-text {
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.05em;
          color: $monoMid;
        }
      }
    }
  }
  .csv-filename {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $dividerBlack;
    &-detail {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 0px 0px 16px;
      gap: 8px;
    }
    .remove-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 60px;
      height: 60px;
      &-detail {
        cursor: pointer;
      }
    }
  }
  .csv-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 56px;
    margin-top: 8px;
  }
  .csv-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
  .csv-description-download {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: $goldMid;
    text-align: center;
    cursor: pointer;
    &.disable {
      cursor: text;
      color: $monoBlack;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}
.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;
      .return-active {
        display: none;
      }
    }
  &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
  &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn  {
    width: 100%;
  }
}

@include desktop {
  .return__button-responsive {
    display: none;
  }
  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }
  .listMenu-page {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px;
      

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .submit-register-btn {
      // background: #948810 !important;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 50px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }
      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 215px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }

    }

    .list-menu--title {
      padding-left: 40px;
      display: flex;
      direction: rtl;
      justify-content: flex-end;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }

      }
    }
    .csv-download {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      height: 29px;
      margin-top: 8px;
    }
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}
</style>
