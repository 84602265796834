<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      scrollable
      fullscreen
      :persistent="true"
      max-width="1024"
      ref="dialog"
      content-class="home-dialog"
    >
      <v-card>
        <v-card-text>
          <div class="card-content"><state-detail :isShowContent="dialog" /></div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text class="btn-close" @click="handleClickOutSite">
            <img src="img/icons/close.svg" alt="Close" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.card-content {
  margin: 40px 0;
}

.v-card__actions > .btn-close {
  width: 50px;
  height: 50px;
  border: 1px solid #948810;
  padding: 0;
  min-width: 50px;
}
</style>

<script>
import StateDetail from '../detail-chart/index.vue';

export default {
  components: { StateDetail },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickOutSite() {
      this.$emit('close');
    },
  },
};
</script>
