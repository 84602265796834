<template>
  <div class="contact-wrapper confirm-page success-page">
    <div class="page-header">
      <h2>お問い合わせ完了</h2>
      <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
    </div>
    <div class="success-wrapper">
      <div class="success-text">
        <div class="item first">
          <span>お問い合わせいただだき、ありがとうございました。</span>
          <span>内容を確認させていただき、ご登録のメールアドレスに返信させていただきます。</span>
        </div>
        <div class="item">
          <span>1週間経過しても返答が無い場合は、正しく受信できなかった可能性がありますので、大変恐れ入りますが再度のご連絡をお願いいたします。</span>
        </div>
      </div>
      <div class="contact-submit-btn back-to-index" @click="backToIndex()">
        <span>トップ画面に戻る</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    linkToPage: {
      type: String,
      default: 'index',
    },
    contactForm: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getLinkToPage: {
      get() {
        return this.linkToPage;
      },
      set(value) {
        this.$emit('update:linkToPage', value);
      },
    },
    getContactForm: {
      get() {
        return this.contactForm;
      },
      set(value) {
        this.$emit('update:contactForm', value);
      }
    }
  },
  methods: {
    backToIndex() {
      this.getLinkToPage = 'index';
      this.getContactForm.selectedContactType = '';
      this.getContactForm.isCheck = false;
      this.getContactForm.contactData = '';
      this.getContactForm.isConfirmPage = false;
      this.$router.push({path: '/'});
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styles/index.scss';
</style>
