<template>
  <div v-if="$vuetify.breakpoint.width < 1024" class="footer footer-sp" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-sp" @click="$router.push({ path: '/' })">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
      </div>
      <div class="to-top-sp">
        <img @click="handleToTop" class="logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
      </div>
    </v-row>
    <v-row class="footer-content-sp">
      <v-col>
        <p>
          <a href="https://www.sustech-inc.com" target="_blank" class="router-footer">  موقع إلكتروني CARBONIX</a>
        </p>
        <p>
          <a href="https://www.sustech-inc.com/" target="_blank" class="router-footer">اتصل بنا</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.TERM_OF_USER" target="_blank" class="router-footer">شروط الاستخدام </a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.PRIVACY" target="_blank" class="router-footer"
            >خصوصية
          </a>
        </p>
        <p>
          <a href="https://www.truste.or.jp/hssl/validate/01632.php" target="_blank" class="router-footer"
            >موقع موثوق به TRUSTe
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr" />
    <v-row class="footer-end-sp"> ©︎2022 Sustech Inc. </v-row>
  </div>
  <div v-else class="footer footer-pc" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-pc" @click="$router.push({ path: '/' })">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
      </div>
      <div class="to-top-pc">
        <span class="text-to-top-pc">الصفحة الأولى</span>
        <img @click="handleToTop" class="toTop-leave logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
        <img
          @click="handleToTop"
          class="toTop-hover logo-img back-to-top"
          src="@/assets/icons/to-top-leave.svg"
          alt=""
        />
      </div>
    </v-row>
    <v-row class="footer-title-pc">
      <v-col>
        <p>Tools</p>
      </v-col>
      <v-col>
        <p>Settings</p>
      </v-col>
      <v-col></v-col>
      <v-col>
        <p>Others</p>
      </v-col>
    </v-row>
    <div class="hr-short" />
    <v-row class="footer-content-pc">
      <v-col>
        <p><a href="/" @click.prevent="negative('/')" class="router-footer">لوحة القيادة</a></p>
        <p>
          <a href="/emissions/list" @click.prevent="negative('/emissions/list')" class="router-footer">مخطط الانبعاثات</a>
        </p>
        <p><a href="/emissions" @click.prevent="negative('/emissions')" class="router-footer">تسجيل</a></p>
        <p>
          <a
            href="/emissions/list-templates"
            @click.prevent="negative('/emissions/list-templates')"
            class="router-footer"
            >نموذج</a
          >
        </p>
        <p>
          <a
            href="/emissions/list-submitted"
            @click.prevent="negative('/emissions/list-submitted')"
            class="router-footer"
            >حالة التسجيل</a
          >
        </p>
        <p v-if="!isUserRole">
          <a href="/approval" @click.prevent="negative('/approval')" class="router-footer">موافقة</a>
        </p>
        <p>
          <a
            href="/others/offset-transaction"
            @click.prevent="negative('/others/offset-transaction')"
            class="router-footer"
            >تداول الأوفست</a
          >
        </p>
        <div @click.prevent="exportReport()"><p class="router-footer-end">تقرير</p></div>
      </v-col>
      <v-col v-if="!isApprovalRole && !isUserRole">
        <p>
          <a :href="routers.USER_MANAGEMENT" @click.prevent="negative(routers.USER_MANAGEMENT)" class="router-footer"
            >إدارة المستخدم
          </a>
        </p>
        <p>
          <a
            :href="routers.FACILITY_MANAGEMENT"
            @click.prevent="negative(routers.FACILITY_MANAGEMENT)"
            class="router-footer"
            >إدارة الموقع
          </a>
        </p>
        <p>
          <a
            :href="routers.VIEWER_MANAGEMENT"
            @click.prevent="negative(routers.VIEWER_MANAGEMENT)"
            class="router-footer"
            >إدارة الهيئة
          </a>
        </p>
        <p>
          <a :href="routers.CALCULATION_TERM" @click.prevent="negative(routers.CALCULATION_TERM)" class="router-footer"
            >تحديد مدة التسجيل
          </a>
        </p>
      </v-col>
      <v-col>
        <p>
          <a
            :href="routers.CREATE_DATA_CUSTOMIZE"
            @click.prevent="negative(routers.CREATE_DATA_CUSTOMIZE)"
            class="router-footer"
            >تخصيص إعداد قاعدة البيانات</a
          >
        </p>
        <p>
          <a
            :href="routers.CHANGE_PASSWORD_USER"
            @click.prevent="negative(routers.CHANGE_PASSWORD_USER)"
            class="router-footer"
            >تغيير كلمة المرور</a
          >
        </p>
        <div @click="logout()"><p class="router-footer-end">تسجيل خروج</p></div>
        <p></p>
      </v-col>
      <v-col v-if="!isAdminRole"></v-col>
      <v-col>
        <p><a href="https://www.sustech-inc.com" target="_blank" class="router-footer">  موقع إلكتروني CARBONIX</a></p>
        <p>
          <a href="https://www.sustech-inc.com/" target="_blank" class="router-footer">اتصل بنا</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.TERM_OF_USER" target="_blank" class="router-footer">شروط الاستخدام</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.PRIVACY" target="_blank" class="router-footer"
            >خصوصية</a
          >
        </p>
        <p>
          <a href="https://www.truste.or.jp/hssl/validate/01632.php" target="_blank" class="router-footer"
            >موقع موثوق به TRUSTe
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr last-child" />
    <v-footer class="footer-section">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">© 2022 Sustech Inc.</v-col>
    </v-footer>
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { logoutApi } from '@/api/auth';
import { getExportReport } from '@/api/exportLog';
import { ROUTES } from '@/router/constants';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import CommonButton from '@/components/utils/button.vue';
import moment from 'moment';
import { getDurations } from '@/api/duration';
import { ROLE } from '@/constants/role';
export default {
  name: 'Footer',
  components: { ErrorPopup, CommonButton },
  data() {
    return {
      showLogo: ['/home', '/managements/company', '/managements/contractors'],
      dialog: false,
      linkTexts: ['اتصل بنا', 'شروط الاستخدام', 'خصوصية'],
      routers: ROUTES,
      errorPopup: false,
      errorMessage: '',
    };
  },

  props: {
    drawer: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('userData', ['contractor', 'currentUser']),
    ...mapState('registerData', ['isFullScreen']),
    isAdminRole() {
      return ROLE.ADMIN === this.currentUser?.user?.role_id;
    },
    isApprovalRole() {
      return ROLE.APPROVAL === this.currentUser?.user?.role_id;
    },
    isUserRole() {
      return ROLE.USER === this.currentUser?.user?.role_id;
    },
  },

  methods: {
    handleToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleLogout() {
      logoutApi().then(() => this.$router.push({ path: '/auth/login' }));
    },

    isShowLogo() {
      return this.showLogo.includes(this.$route.path) ? true : false;
    },
    redirectToEmissionMenu() {
      this.$router.push({ path: ROUTES.LIST_MENU_EMISSION }).catch((err) => {});
    },

    redirectToTemplate() {
      this.$router.push({ path: ROUTES.LIST_EMISSION_TEMPLATES }).catch((err) => {});
    },
    async exportReport() {
      return this.$router.push({path: ROUTES.EXPORT_REPORT})
      // await getExportReport(this.contractor)
      //   .then(() => {
      //     getExportReport(this.contractor, true).then((res) => {
      //       this.getCsvExport(res);
      //     });
      //   })
      //   .catch((error) => {
      //     this.errorPopup = true;
      //     this.errorMessage = error.message;
      //   });
    },
    async getCsvExport(content) {
      await getDurations().then((res) => {
        const duration = res.data.find((item) => item.is_selected === true);
        const fullDate = moment().format('YYYYMMDDHHmmss');
        const excelName = `CARBONIX_GHG Report-${duration.year}_${fullDate}.xlsx`;
        const url = window.URL.createObjectURL(
          new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    logout() {
      this.$emit('logout');
    },
    negative(url) {
      this.handleToTop();
      if (this.$route.path === url) {
        this.$router.go();
      } else {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'footerStyles/index';

.back-to-top {
  cursor: pointer;
}

.footer-sp {
  height: max-content;
  display: block;
  margin-top: 80px;
  .logo-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: unset;
    padding: 40px 35px 0 20px;
  }
  .logo-app-sp {
    img {
      width: 140px;
      height: 18px;
    }
  }
  .logo-app-sp,
  .to-top-sp {
    display: flex;
    align-items: flex-start;
  }

  .footer-content-sp {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-top: 36px;
    margin-bottom: 25px;
    margin-left: 0;
    padding: 0 20px;
    .col {
      padding: unset;
      p {
        margin-bottom: 17px;
      }
    }
  }

  .footer-end-sp {
    justify-content: center;
    padding: 9px 0px 11px;
    margin: 0;
    color: #99a6a9;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
  .hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.router-footer {
  color: #7c898c;
  text-decoration: none;
}

.router-footer:hover {
  cursor: pointer;
  color: #e4e6e7;
  background: $bgDark;
}

.router-footer-end:hover {
  color: #e4e6e7;
  background: $bgDark;
  padding: 0px 8px 0px;
  margin-left: -8px;
  cursor: pointer;
}

.back-to-top {
  cursor: pointer;
}
.footer-pc {
  height: auto;
  display: block;
  margin-top: 80px;

  .logo-wrapper {
    padding: 43px 40px 0;
    margin: unset;
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    flex-direction: row-reverse;
  }
  .logo-app-pc {
    cursor: pointer;
    img {
      width: 140px;
      height: 18px;
    }
  }

  .to-top-pc {
    display: flex;
    align-items: center;
    img {
      margin-left: 16px;
    }
    .toTop-hover {
      display: none;
    }
  }
  .to-top-pc:hover {
    .toTop-hover {
      display: block;
    }
    .toTop-leave {
      display: none;
    }
  }

  .text-to-top-pc {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: $goldMid;
  }

  .footer-title-pc {
    width: 87.17%;
    color: $goldLight;
    font-family: 'Century Gothic Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: unset;
    padding: 0 0px 12px 40px;
    .col {
      padding: unset;
      display: flex;
      justify-content: flex-end;
      P {
        margin-bottom: unset;
        font-family: 'Century Gothic Pro';
      }
    }
  }

  .footer-content-pc {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    width: 87.17%;
    padding: 16px 0px 0 40px;
    &.row {
      margin: unset;
      .col {
        padding: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 42px;
          }
        }
      }
    }
  }
  .hr {
    margin: 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
  .hr-short {
    margin: 0 144px 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
}
.footer-section {
  background: #132d39;
  color: #99a6a9;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding: 10px;
  height: 44px;
}

#customers th {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  color: #a4a184;
}

#customers td {
  color: #7c898c;
}

.footer {
  position: relative !important;
}
.text-right {
  height: 57px;
  text-align: right;
  padding: 0;
}
</style>
