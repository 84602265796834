<template>
  <div class="setting-page padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title padding-left-layout">排出量の承認</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html="'排出量の申請を審査してください。承認した排出量データは、排出量一覧に掲載されます。'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="approvalGrid"
        :grid-columns="approvalColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>

<script>
import DataTable from "@/components/category/data-table";
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import LogHistoryPopup from "@/components/dialogs/history-popup.vue";

import { getListCommentHistory, getListLogHistory, getListWorkflowApi } from "@/api/registerData";
import { getUserInfo } from "@/api/auth";
import { ROUTES } from "@/router/constants";
import { STATUS_FIELD } from "@/constants/status";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE } from "@/constants/registerData";
import { getColorStatusById, getStatusNameById } from "@/utils/status";
import { getCategoryName, getDataLayer, getScopeName } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";
import { mapActions, mapState } from "vuex";
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";
import { UndoStack } from "@/concerns/utils/undo-stack";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import * as wjGrid from "@grapecity/wijmo.grid";
import throttle from "lodash/throttle";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { cancelSubmittedData } from '@/api/newRegisterData';

export default {
  name: "ApprovalPage",
  components: {
    DataTable,
    CommentLogPopup,
    LogHistoryPopup,
  },
  data() {
    return {
      approvalGrid: null,
      flexGrid: null,
      approvalData: [],
      approvalColumn: [],
      dialogChangeStatus: false,
      dialogComment: false,
      listStatusApproval: [],
      showTooltip: false,
      breadCrumb: [
        {
          text: "ホーム",
          disabled: false,
          href: ROUTES.HOME_DASHBOARD
        },
        {
          text: "排出量の承認",
          disabled: true,
          href: ROUTES.EMISSIONS
        }
      ],
      commentContents: [],
      filterColumns: [],
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      userName: ""
    };
  },
  async mounted() {
    if(this.$route.query?.is_allow_cancel) {
      await this.handleApprovalCancleSubmit();
    }
    await this.checkUserInfo();
    await this.handleGetDataApproval();
    this.updateBreadCrumb(this.breadCrumb);
    this.listStatusApproval = [
      {
        name: "承認",
        description: "申請を承認します。"
      },
      {
        name: "申請差戻",
        description: "申請を却下し、申請者に差し戻します。"
      }
    ];
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration"]),
    ...mapActions("newRegisterData", ["updateIsApproved", "updatePatternId"]),
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.userName = res.name;
        })
        .catch(() => {
        });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    initGrid(grid) {
      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );
      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];
        const listColumnNotDisable = [
          "unique_id",
          "log_history"
        ];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
      });
      grid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    async handleGetDataApproval() {
      const payload = {
        action: "listApproval"
      };
      const approvalResponse = await getListWorkflowApi(payload);
      if (approvalResponse.data.length > 0) {
        this.approvalData = approvalResponse.data.map((approvalItem) => {
          let status = getStatusNameById(approvalItem.status);

          if (approvalItem.status === STATUS_FIELD.STATUS_SUBMIT) {
            status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
          }

          if (approvalItem.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
            status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          }

          return {
            ...approvalItem,
            status: status,
            approver_info: approvalItem?.approved_by ? approvalItem?.approver?.name : approvalItem?.selected_approver.name,
            submitter: approvalItem?.submitter?.name,
            log_history: "ログ表示",
            scope: getScopeName(parseInt(approvalItem.scope)),
            category: getCategoryName(parseInt(approvalItem.scope), parseInt(approvalItem.category)),
            layer1: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.method,
            layer2: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.sub_method,
            lasted_update: formatDateTimeDataTable(approvalItem.updated_at),
            month_year_registration: `${approvalItem.year}/${String(approvalItem.month).padStart(2, "0")}`,
            iconComment: approvalItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : "",
            scope_id: parseInt(approvalItem.scope),
            category_id: parseInt(approvalItem.category),
            date_submit: formatDateTimeDataTable(approvalItem.submitted_at),
            date_approval: formatDateTimeDataTable(approvalItem.approved_at),
            contractor_name: approvalItem.contractor?.name,
            duration_id: approvalItem.duration_id,
            approved_by: approvalItem.approved_by,
            curent_user: this.userName
          };
        });
      }
      this.defineTable();
    },
    handleEvent() {
      let isHandlingCollectionChange = false;
      this.approvalGrid.collectionChanged.addHandler(
        throttle(async() => {
          if (isHandlingCollectionChange) {
            return;
          }

          isHandlingCollectionChange = true;
          setTimeout(() => {
            isHandlingCollectionChange = false;
          }, 100);

          let edited = {};
          this.approvalGrid.itemsEdited.forEach((approvalItem, approvalIndex) => {
            if (approvalItem.title) {
              // prepare data: title, id, ...
              edited[approvalIndex] = {
                id: approvalItem.id,
                title: approvalItem.title
              };
            }
          });

          if (Object.values(edited).length) {
            try {
              this.approvalGrid.itemsEdited.length = 0;
            } catch (error) {
              this.approvalGrid.itemsEdited.length = 0;
            }
          }
        }, 300)
      );
    },
    initialView() {
      if (this.approvalGrid) {
        this.approvalData = this.approvalGrid.items.filter(
          (approvalItem) => approvalItem.id && approvalItem.id !== BLANK_ID
        );
      }
      this.approvalGrid = new CollectionView([...this.approvalData], {
        trackChanges: true
      });
      this.handleEvent();
      this.flexGrid.columnGroups = this.getApprovalColumns();
      this.markUnreadRows();
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    getApprovalColumns() {
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: " ",
          binding: "unique_id",
          minWidth: 46,
          maxWidth: 46,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeButton({
            text: "詳細",
            click: (event, context) => this.onIdClicking(context)
          }),
          cssClassAll: "height34 approval-cell_custom hide-filter"
        },
        {
          header: "ステータス",
          binding: "status",
          minWidth: 105,
          maxWidth: 150,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClass: "btn-db",
          cellTemplate: "<span class=\"${(item.status === \"代理承認済み\" || item.status === \"承認済み\") ? \"mono-text\" : (item.status === \"申請取消中\" || item.status === \"申請中\" || item.status === \"申請取消要請\") ? \"blue-text\" : (item.status === \"申請差戻\" || item.status === \"代理申請差戻\" || item.status === \"承認待ち\") ? \"red-text\" : \"normal\"}\">${item.status}</span>",
          cssClassAll: "height34 approval-cell_status"
        },
        {
          header: " ",
          binding: "iconComment",
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll: "approval-cell_comment hide-filter comment-icon",
          multiLine: true,
          wordWrap: true
        },
        {
          header: "申請者",
          binding: "submitter",
          minWidth: 100,
          maxWidth: 220,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "承認者",
          binding: "approver_info",
          minWidth: 128,
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: "approver_info",
          cellTemplate: "<span class=\"${(item.approver_info !== item.curent_user) && \"not-same-name\"}\">${item.approver_info}</span>"
        },
        {
          header: "申請日",
          binding: "date_submit",
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "承認日",
          binding: "date_approval",
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "タイトル",
          binding: "title",
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: "readOnly-approved"
        },
        {
          header: "法人名",
          binding: "contractor_name",
          minWidth: 85,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "スコープ",
          binding: "scope",
          minWidth: 120,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "カテゴリ",
          binding: "category",
          minWidth: 120,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "算定方法",
          align: "center",
          columns: [
            {
              header: "1段目",
              binding: "layer1",
              minWidth: 74,
              maxWidth: 400,
              allowSorting: false,
              isReadOnly: false,
              wordWrap: true
            },
            {
              header: "2段目",
              binding: "layer2",
              minWidth: 74,
              maxWidth: 400,
              allowSorting: false,
              isReadOnly: false,
              wordWrap: true
            }
          ]
        },
        {
          header: "登録年月",
          binding: "month_year_registration",
          minWidth: 92,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          cssClass: "btn-db",
          multiLine: true,
          wordWrap: true
        },
        {
          header: "変更履歴",
          binding: "log_history",
          minWidth: 72,
          maxWidth: 72,
          allowSorting: false,
          cssClass: "btn-db",
          cssClassAll: "cell_comment-confirm hide-filter",
          multiLine: true,
          wordWrap: true,
          cellTemplate: CellMaker.makeButton({
            text: "${text? text : \"選択\" }",
            click: (e, ctx) => this.onHistoryClicking(ctx)
          })
        }
      ];
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      await updateDataBeforeRedirect(rowData.item);
      // return this.$router.push({path: `/emissions/view/${rowData.item.scope_id}/${rowData.item.category_id}`});
      this.$router.push({ path: `/emissions/register/approval/${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.updated_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
        let status = getStatusNameById(listHistoryChanged.status);
        let colorStatus = getColorStatusById(listHistoryChanged.status);

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_SUBMIT) {
          status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
        }

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
          status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
        }

        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.created_at),
          name: listHistoryChanged.content,
          status: status,
          color: colorStatus
        };
      });
    },
    defineTable() {
      this.approvalColumn = this.getApprovalColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
      this.canUndo = false;
      this.canRedo = false;
      const stateChanged = (event) => {
        this.canUndo = event.canUndo;
        this.canRedo = event.canRedo;
      };
      this.undoStack = new UndoStack(flexgrid, stateChanged);
    },
    markUnreadRows() {
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.approvalGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread = currentItem !== undefined && currentItem["unread_flg"] !== undefined ? currentItem["unread_flg"] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add("wj-has-unread-mark");
            }
          }
        }
      });
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          dataWorkflow: {
            id: parseInt(this.$route.query.workflow_data_id),
            comment: "",
          },
          is_allow_cancel: CANCEL_TYPE.ACCEPT
        }
        await cancelSubmittedData(payload)
        this.$router.replace({ path: '/approval'})
      } catch (error) {
        this.$router.replace({ path: '/approval'})
      }
    }
  },
  computed: {
    ...mapState("commonApp", ["isExpand"]),
    ...mapState("registerData", ["isFullScreen"])
  }
};
</script>
<style lang="scss">
.wj-flexgrid .readOnly-approved.wj-cell[aria-readonly] {
  background: #f7f7f2 !important;
  color: #404d50 !important;
}
</style>
<style scoped lang="scss">
@import './styles/index.scss';
</style>
