export const PERPOSE_USING_BUILDING = {
    OFFICE: "事務所",
    GOVERMNET_OFFICE: "官公庁",
    RESEARCH_INSTITUTE: "研究施設",
    PRE_SCHOOL: "幼稚園・保育園",
    JUNIOR_HIGHT_SCHOOL_HOKKAIDO: "小・中学校 北海道",
    JUNIOR_HIGHT_SCHOOL_OTHER: "小・中学校 その他",
    HIGHT_SCHOOL: "高校",
    UNIVERSITY: "大学・専門学校",
    SHOPPING_MALL: "デパート・スーパー",
    GROCERY_STORE: "コンビニエンスストア",
    HOME_ELECTRICAL_STORE: "家電量販店",
    OTHER_STORE: "その他物販",
    EATERY: "飲食店",
    THEATER: "劇場・ホール",
    EXHIBITION_FACLITIES: "展示施設",
    STADIUM: "スポーツ施設",
    FACTORY: "工場",
    HOSPITAL: "病院",
    HOTEL: "ホテル・旅館"
}

export const VALUE_CALCULATION_DEFAULT = {
    CALC_ELECTRIC: 9.76,
    CALC_GAS: 0.0498,
    CALC_OTHERS: 0.0686,
    ROUND_NUMBER: 4,
    CALC_TOTAL: 1000
}



export const DATA_FOR_CALCULATION = [
    {
        type: '事務所',
        electricity: 78,
        gas: 6,
        others: 16
    },
    {
        type: '官公庁',
        electricity: 77,
        gas: 9,
        others: 14
    },
    {
        type: '研究施設',
        electricity: 63,
        gas: 8,
        others: 29
    },
    {
        type: '幼稚園・保育園',
        electricity: 70,
        gas: 12,
        others: 18
    },
    {
        type: '小・中学校 北海道',
        electricity: 67,
        gas: 20,
        others: 13
    },
    {
        type: '小・中学校 その他',
        electricity: 74,
        gas: 12,
        others: 14
    },
    {
        type: '高校',
        electricity: 74,
        gas: 7,
        others: 19
    },
    {
        type: '大学・専門学校',
        electricity: 66,
        gas: 11,
        others: 23
    },
    {
        type: 'デパート・スーパー',
        electricity: 73,
        gas: 4,
        others: 23
    },
    {
        type: 'コンビニエンスストア',
        electricity: 100,
        gas: 0,
        others: 0
    },
    {
        type: '家電量販店',
        electricity: 71,
        gas: 1,
        others: 28
    },
    {
        type: 'その他物販',
        electricity: 89,
        gas: 3,
        others: 8
    },
    {
        type: '飲食店',
        electricity: 51,
        gas: 33,
        others: 16
    },
    {
        type: '劇場・ホール',
        electricity: 73,
        gas: 13,
        others: 14
    },
    {
        type: '展示施設',
        electricity: 77,
        gas: 7,
        others: 16
    },
    {
        type: 'スポーツ施設',
        electricity: 69,
        gas: 16,
        others: 15
    },
    {
        type: '工場',
        electricity: 100,
        gas: 0,
        others: 0
    },
    {
        type: '病院',
        electricity: 48,
        gas: 13,
        others: 39
    },
    {
        type: 'ホテル・旅館',
        electricity: 42,
        gas: 14,
        others: 44
    }
];