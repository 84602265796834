<template>
  <div class="emissions-page padding-left-layout padding-top-layout">
    <page-title title="排出量一覧"/>
    <div class="page-header">各カテゴリを選択して、登録が完了した排出量を表示します。</div>
    <div class="summary">
      <!-- <div class="duration-wrapper">
        <div class="duration">
          <div class="calculation-status-wrapper">
            <div class="calculation-page">
              <div class="duration-text">現在登録中の期間</div>
              <div class="duration-time">{{ duration }}</div>
            </div>
            <common-button v-ripple="false" v-if="isAdminRole" label="変更" @action="redirectToCalculationTerm()"/>
          </div>
        </div>
      </div> -->
      <div class="summary-area" :class="{ disabled: durationId === null }">
        <div class="summary-area__scope">
          <div class="summary-area__scope-left" :class="isEnableScope3?'':'disabled'">
            <scope-component v-if="isEnableScope3" :scopes="leftScopeContainer" :header="header[0]"/>
            <scope-component-disabled v-else :scopes="leftScopeContainer" :header="header[0]"/>
          </div>
          <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt=""/></div>
          <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt=""/></div>
          <div class="summary-area__scope-middle">
            <scope-component :scopes="middleScope1Container" :isCategory="false" :header="header[1]"/>
            <scope-component :scopes="middleScope2Container" :isCategory="false" :header="header[1]"/>
          </div>
          <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt=""/></div>
          <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt=""/></div>
          <div class="summary-area__scope-right" :class="isEnableScope3?'':'disabled'">
            <scope-component v-if="isEnableScope3" :scopes="rightScopeContainer" :header="header[2]"/>
            <scope-component-disabled v-else :scopes="rightScopeContainer" :header="header[2]"/>
          </div>
        </div>
        <div class="summary-area__scope">
          <scope-component :isEnableScope3="isEnableScope3" :scopes="bottomScopeContainer" :header="header[3]"/>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import ScopeComponent from '@/components/emissionScope/ScopeComponent.vue';
import ScopeComponentDisabled from '@/components/emissionScope/ScopeComponentDisabled.vue';
import {getDurations} from '@/api/duration';
import {getScopesAndCategories} from '@/api/emissionCalculations';
import {mapActions, mapState} from 'vuex';
import {ROUTES} from '@/router/constants';
import moment from 'moment';
import CommonButton from "@/components/utils/button.vue";
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import webStorage from '@/utils/webStorage';
import {ROLE} from '@/constants/role';

export default {
  name      : 'Home',
  components: {
    ScopeComponent,
    ScopeComponentDisabled,
    CommonButton,
    PageTitle,
  },
  data() {
    return {
      breadcrum            : [
        {
          text    : 'ホーム',
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD,
        },
        {
          text    : '排出量一覧',
          disabled: false,
          href    : ROUTES.EMISSIONS,
        },
      ],
      duration             : '',
      durationId           : null,
      header               : ['上流', '自社', '下流', 'その他'],
      leftScopeContainer   : {},
      middleScope1Container: {},
      middleScope2Container: {},
      rightScopeContainer  : {},
      bottomScopeContainer : {},
      windowWidth          : window.innerWidth,
      focusElement         : null,
      isEnableScope3       : false,
      totalMonthInDuration : null,
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    this.fetchDurations();
    document.addEventListener('scroll', this.removeTooltip);
  },
  watch  : {
    windowWidth: {
      handler() {
        this.windowWidth = window.innerWidth;
      },
    },
  },
  methods: {
    ...mapActions('emission', ['selectDuration', 'setScopes']),
    ...mapActions('registerData', ['updateDuration', 'updateListDuration']),
    ...mapActions('commonApp', ['updateBreadCrumb']),
    redirectToCalculationTerm() {
      return this.$router.push({path: ROUTES.CALCULATION_TERM});
    },
    countTotalMonthInDuration(durationStartAt, durationEndAt) {
      return (
        durationEndAt.getMonth() -
        durationStartAt.getMonth() +
        12 * (durationEndAt.getFullYear() - durationStartAt.getFullYear())
      );
    },
    async fetchDurations() {
      await getDurations()
        .then((response) => {
          this.updateListDuration(response.data);
          const selectedDuration = response.data.find((item) => item.is_selected === true);
          const startAt          = moment(selectedDuration.start_at).format('YYYY/M');
          const endAt            = moment(selectedDuration.end_at).format('YYYY/M');
          this.duration          = `${startAt} - ${endAt}`;
          this.durationId        = selectedDuration.id;
          let durationStartAt = new Date(selectedDuration.start_at);
          let durationEndAt   = new Date(selectedDuration.end_at);
          this.totalMonthInDuration = this.countTotalMonthInDuration(durationStartAt, durationEndAt) + 1;
          webStorage.set('duration', this.durationId);
          this.getScopeStatus();
        })
        .catch(() => {
        });
    },
    async getScopeStatus() {
      await getScopesAndCategories({duration_id: this.durationId})
        .then((response) => {
          let isEnableScope3 = response.find((object) => {
            return object.hasOwnProperty('display_scope_3')
          })
          this.isEnableScope3 = isEnableScope3.display_scope_3
          this.updateContainer(response);
        })
        .catch(() => {
        });
    },
    updateContainer(scopes) {
      const leftScope3           = scopes[2].categories.filter((category) => category.id < 9);
      const rightScope3          = scopes[2].categories.filter((category) => category.id > 8 && category.id < 16);
      this.middleScope1Container = {
        scope     : '1',
        scope_icon: 'scope1.svg',
        categories: [
          {
            id     : '1',
            name   : scopes[0].description,
            status : scopes[0].status,
            tooltip: this.tooltipContent()[0],
            total_month_is_register_data: scopes[0].total_month,
            total_month_in_duration: this.totalMonthInDuration
          },
        ],
      };
      this.middleScope2Container = {
        scope     : '2',
        scope_icon: 'scope2.svg',
        categories: [
          {
            id     : '1',
            name   : scopes[1].description,
            status : scopes[1].status,
            tooltip: this.tooltipContent()[1],
            total_month_is_register_data: scopes[1].total_month,
            total_month_in_duration: this.totalMonthInDuration
          },
        ],
      };
      this.bottomScopeContainer  = {
        scope     : '4',
        scope_icon: '',
        categories: [
          {
            id     : scopes[2].categories[15].id,
            name   : scopes[2].categories[15].name,
            status : scopes[2].categories[15].status,
            tooltip: this.tooltipContent()[17],
            total_month_is_register_data: scopes[2].categories[15].total_month,
            total_month_in_duration: this.totalMonthInDuration
          },
          {
            id     : '1',
            name   : scopes[3].categories[0].name,
            status : scopes[3].categories[0].status,
            tooltip: this.tooltipContent()[18],
            total_month_is_register_data: scopes[3].categories[0].total_month,
            total_month_in_duration: this.totalMonthInDuration
          },
        ],
      };
      this.leftScopeContainer    = {
        scope     : '3',
        scope_icon: 'scope3.svg',
        categories: this.formatScopeData(leftScope3),
        align     : 'left',
      };
      this.rightScopeContainer   = {
        scope     : '3',
        scope_icon: 'scope3.svg',
        categories: this.formatScopeData(rightScope3),
        align     : 'right',
      };
    },
    formatScopeData(data) {
      let formatData = [];
      data.map((category) => {
        formatData.push({
          id     : category.id,
          name   : category.name,
          status : category.status,
          tooltip: this.tooltipContent()[category.id + 1],
          total_month_is_register_data: category.total_month,
          total_month_in_duration: this.totalMonthInDuration
        });
      });
      return formatData;
    },
    tooltipContent() {
      return [
        '<u><b>燃料の燃焼や工業プロセスなど</b></u>を通じた、事業者自らによる温室効果ガスの「直接排出」を登録します。',
        '<u><b>他社から供給された電気、熱・蒸気の使用</b></u>に伴う温室効果ガスの「間接排出」を登録します。',
        '<u><b>原材料・部品、容器・包装等が製造されるまでの活動</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>自社の資本財の建設・製造</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>調達している燃料の上流工程（採掘、精製等）、調達している電力等の上流工程（発電に使用する燃料の採掘、精製等）</b></u>に伴う温室効果ガスの排出を登録します。',
        '①報告対象年度に購入した製品・サービスの、<u><b>サプライヤーから自社への物流（輸送、荷役、保管）</b></u>に伴う排出、及び②報告対象年度に購入した<u><b>①以外の物流サービス（輸送、荷役、保管）（＝自社が費用負担している物流に伴う支出）</b></u>に伴う温室効果ガスの排出を登録します。',
        '自社で発生した<u><b>廃棄物の輸送、処理</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>従業員の出張</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>従業員が通勤する際の移動</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>自社が賃借しているリース資産の操業</b></u>に伴う温室効果ガスの排出を登録します。（ただしScope1,2で算定する場合を除く）',
        '自社が<u><b>販売した製品の最終消費者までの物流（輸送、荷役、保管、販売）</b></u>に伴う温室効果ガスの排出を登録します。（ただし、自社が費用負担していないものに限ります。）',
        '<u><b>事業者による中間製品の加工</b></u>に伴うに温室効果ガスの排出を登録します。',
        '<u><b>使用者（消費者・事業者）による製品の使用</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>使用者（消費者・事業者）による製品の廃棄時の処理</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>自社が賃貸事業者として所有し</b></u>、他者に賃貸しているリース資産の運用に伴う温室効果ガスの排出を登録します。（ただしScope1,2で算定する場合を除く）',
        '<u><b>フランチャイズ加盟者</b></u>における温室効果ガスの排出を登録します。',
        '<u><b>投資の運用</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>【任意】従業員や消費者の日常生活</b></u>に伴う温室効果ガスの排出を登録します。',
        '<u><b>非化石証書やJクレジット、グリーン電力証書</b></u>等の認証された排出削減貢献量を登録します。',
      ];
    },
    addTooltip(event) {
      this.focusElement = event.target.parentElement;
      this.focusElement.classList.add('tooltip-icon');
    },
    removeTooltip() {
      if (this.focusElement) {
        this.focusElement.classList.remove('tooltip-icon');
      }
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('question.svg');
      } else {
        event.target.src = this.getSettingIcon('question-active.svg');
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/images/emissions/status/${image}`);
      }
      return '';
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.removeTooltip);
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    isAdminRole() {
      return this.currentUser?.user?.role_id === ROLE.ADMIN;
    },
  },
};
</script>
<style lang="scss" scoped>
.emissions-page {
  // .duration-wrapper {
  //   .duration-status {
  //     display: flex;
  //     align-items: center;
  //     width: max-content;
  //     margin: 0 auto;
  //     margin-top: 33px;
  //     height: 56px;
  //     position: relative;

  //     .status {
  //       display: flex;

  //       div {
  //         font-weight: 400;
  //         font-size: 13px;
  //         line-height: 24px;
  //         letter-spacing: 0.05em;
  //         color: $monoBlack;
  //         margin-left: 5px;
  //         margin-right: 17px;
  //       }
  //     }
  //   }

  //   .calculation-status-wrapper {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: space-between;
  //     padding: 8px 8px 8px 16px;
  //     background-color: $bgLight;
  //     border-radius: 4px;
  //     .calculation-page {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: flex-start;
  //       .duration-text {
  //         color: $monoBlack;
  //         font-style: normal;
  //         font-weight: 700;
  //         font-size: 11px;
  //         line-height: 18px;
  //         letter-spacing: 0.03em;

  //       }
  //       .duration-time {
  //         font-style: normal;
  //         font-weight: 700;
  //         font-size: 14px;
  //         line-height: 24px;
  //         letter-spacing: 0.03em;
  //         color: $goldMid;
  //       }
  //     }
  //   }

  //   .tooltip-icon {
  //     &:hover:before {
  //       display: flex;
  //       justify-content: left;
  //       background: $monoWhite;
  //       border-radius: 4px;
  //       color: $monoBlack;
  //       content: attr(tooltip);
  //       margin: 0 auto;
  //       font-size: 15px;
  //       padding: 10px;
  //       width: 400px;
  //       max-width: max-content;
  //       position: absolute;
  //       right: 15px;
  //       left: unset;
  //       bottom: 40px;
  //       z-index: 999;
  //     }
  //   }
  // }

  .summary-area {
    margin-top: 48px;

    .summary-area__scope {
      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;

        &.desktop {
          display: none;
        }
      }
    }

    &.disabled {
      display: none;
    }
  }

  .page-header {
    margin-bottom: 48px;
    color: $monoBlack;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
  }
}
.disabled {
  opacity: 0.3;
}
@media (min-width: 1131px) {
  .emissions-page {
    padding-right: 40px;

    // .duration-wrapper {
    //   display: flex;
    //   justify-content: space-between;

    //   .duration {
    //     width: 100%;


    //   }

    //   .duration-status {
    //     margin: unset;

    //     &.mobile {
    //       display: none;
    //     }
    //   }
    // }

    .summary-area {
      margin-top: 48px;

      .summary-area__scope {
        display: flex;

        .arrow {
          padding: 0 16px;

          &.mobile {
            display: none;
          }

          &.desktop {
            display: flex;
          }
        }

        .summary-area__scope-left,
        .summary-area__scope-middle,
        .summary-area__scope-right {
          width: calc((100% - 106px) / 3);
        }

        .summary-area__scope-middle,
        .summary-area__scope-right {
          background-color: $bgLight;
        }
      }
    }
  }
}

@media (max-width: 615px) {
  .emissions-page {
    // .duration-wrapper {
    //   .tooltip-icon {
    //     &:hover {
    //       &:before {
    //         width: unset;
    //       }
    //     }
    //   }
    // }
  }
}
@include desktop {
  .calculation-status-wrapper {
    width: 315.33px;
  }
}
</style>
