import request from './base/request';

const NEW_REGISTER_DATA_SERVICE_URL = '/workflow-data';
const LIST_DATA_PATTERN = '/data-pattern';
const DATA_PATTERN = '/data-pattern';

const preparePayload = (payload, isUpdate = false) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
  body.append('data', payloadBlob);
  if (isUpdate) {
    body.append('_method', 'put');
  }
  return body
}

async function addRegisterDataWorkFollowApi(payload) {
  return await request.post(NEW_REGISTER_DATA_SERVICE_URL, preparePayload(payload));
}

async function getListDataPatternApi(workFollowDataId) {
    let params = { params : workFollowDataId }
    return await request.get(LIST_DATA_PATTERN, params);
}

async function updateRegisterDataWorkFollowApi(payload) {
  return await request.put(NEW_REGISTER_DATA_SERVICE_URL, (payload));
}

export const getListDataPattern = (workFollowDataId) => {
  let params = { params : workFollowDataId }
  return request.get(LIST_DATA_PATTERN, params)
    .then((res) => {
    return res;
  })
  .catch((error) => {
    throw error;
  });;
}

export const updateTemplateData = (payload) => {
  return request.put(DATA_PATTERN, (payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export const cancelSubmittedData = (payload) => {
  return request.put(NEW_REGISTER_DATA_SERVICE_URL, (payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export const deleteTemplate = (workFollowDataId) => {
  let params = { params : workFollowDataId }
  return request.delete(DATA_PATTERN, params).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export const approvalDataAction = (payload) => {
  return request.post(NEW_REGISTER_DATA_SERVICE_URL + '/approve', (payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export const saveDraftAction = (payload) => {
  return request.post(NEW_REGISTER_DATA_SERVICE_URL, preparePayload(payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export const submitDataAction = (payload) => {
  return request.post(NEW_REGISTER_DATA_SERVICE_URL, preparePayload(payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}

export default {
    addRegisterDataWorkFollowApi,
    getListDataPatternApi,
    updateRegisterDataWorkFollowApi
};
