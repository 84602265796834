<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      :persistent="true"
      ref="dialog"
      max-width="464px"
    >
      <v-card>
        <v-card-title>
          <img class="" src="@/assets/images/dialog/notification.svg" />
          <span class="">يلاحظ</span>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <common-button class="btn-displayed-one" type="colored" @action="submit" :label="confirmText"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/commonApp/Button.vue';

export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'اوكي',
    },
    message: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
