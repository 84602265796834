<template>
  <div class="container-fluid register-page">
    <div class="title-block">
      <description class="title-block--detail" :scope="scope" :category="category" :workFlowData="workFlowData" :class="!isDisplayDescription && 'has-opacity'" />
      <div class="container update-block" v-if="isHasLatestRecord">
        <div class="item d-flex align-center">
          <img class="item-icon" src="@/assets/images/registerData/lastUpdateIcon.svg" />
          <div class="item-detail">
            <div class="item-title">Last Update</div>
            <p class="item-content">{{ getLastTimeUpdate }}</p>
          </div>
        </div>
        <div class="item d-flex align-center">
          <img class="item-icon" src="@/assets/images/registerData/updateByIcon.svg" />
          <div class="item-detail">
            <div class="item-title">Updated By</div>
            <p class="item-content">{{ latestRecord.user_updated }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container selections">
      <div>
        <div class="durations-pulldown">
          <label class="durations-label">مدة التسجيل</label>
          <div v-if="!isGetAllData" class="duration-text">
            <span>{{ getSelectedDurationText() }}</span>
          </div>
          <select-item
            v-if="isGetAllData"
            class="durations-select-item"
            label="登録期間"
            :items="durationList"
            v-model="selectDurationChange"
          />
        </div>
        <div class="durations-pulldown month-year-pulldown">
          <label class="durations-label">تاريخ التسجيل</label>
          <v-select
            v-if="!isCreateNewData()"
            :items="monthYearSelectionList"
            item-text="text"
            item-value="value"
            v-model="selectMonthYearChange"
            label="اختر من فضلك"
            placeholder="اختر من فضلك"
            dense
            solo
            class="durations-select-item select-item select-month new-register"
            :disabled="isCreateNewData()"
            :menu-props="{ contentClass: 'selecting select-durations' }"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.text }}
              <v-spacer></v-spacer>
              <span v-if="isHasDataRegister && data.item.duration_registered" class="data-is-registered"></span>
            </template>
          </v-select>
          <div v-else class="duration-text"><span>{{ getSelectedMonthText() }}</span></div>
        </div>
      </div>

      <div class="total-result" :class="methodList.length === 1 && 'max-content'">
        <div class="emissions">
          <div class="total-label" v-if="$route.params.scope === '4'">オフセット量総計</div>
          <div class="total-label" v-else>{{ getSelectedMonthText() }} انبعاثات غازات الدفيئة</div>
          <div class="total-emission" v-if="!isLoading">{{ formatNumber(totalEmissions) || 0 }} <span>t-CO2</span></div>
          <v-progress-circular
            v-if="isLoading"
            class="total-emission"
            indeterminate
            color="#948810"
          ></v-progress-circular>
        </div>
        <div class="method w-100" v-if="methodList.length > 1">
          <div class="total-label method-label">طرق الحساب</div>
          <common-button v-if="displayGetMethodName" class="has-elipsis" @action="openSelectPopup" :label="getMethodName"></common-button>
          <div v-else class="method-name"><span>{{getMethodName}}</span></div>
        </div>
      </div>
    </div>
    <div>
      <div class="total-treatment-list mb-12" v-if="category === 5 || category === 12">
        <div class="treatment-item">
          <div class="treatment-label">排出量（合計）焼却</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.incinerator) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">排出量（合計）埋立</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.landfill) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">排出量（合計）リサイクル</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.recycling) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">排出量（合計）不明</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.unknown) || 0 }}<span>t-CO2</span></div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        v-if="pattern"
        :data-source="dataTable"
        :init-grid="initTheTable"
        :allowDelete="!isReadOnlyDataTable"
        :grid-columns="pattern.header($store.state.registerData, layer)"
        :key="key"
        :rowFocus="1"
        :totalData="isReadOnlyDataTable ? totalData - 1 : totalData"
        :showFocus="!isReadOnlyDataTable"
        :isReadOnly="isReadOnlyDataTable || isReadOnly"
        :allowAutoWidth="pattern.allowWidthAuto ? pattern.allowWidthAuto() : false"
        :lastedRow="lastedRow"
        :allowAddNew="allowAddNew"
        :isHasData="isHasDataTable"
        :dataPattern="dataPattern"
        :isEditedData="isEditData"
        :isReadOnlyTitle="isReadOnlyTitle"
        :pattern="pattern"
        :itemsInView="itemsInView"
        :value.sync="selectMonthYearChange"
        :selectMonthYearChange="selectMonthYearChange"
        :input.sync="selectDurationChange"
        :selectDurationChange="selectDurationChange"
        :isCreateNewData="isCreateNewData"
        :isGetAllData="isGetAllData"
        :selectedDurationText="getSelectedDurationText()"
        :selectedMonthText="getSelectedMonthText()"
        :durationList="durationList"
        :monthYearSelectionList="monthYearSelectionList"
        :isResizeCustome="isResizeCustome"
        @onHandleActionTable="onHandleActionTable"
        @onHandleShowExportPopup="onHandleShowExportPopup"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
      />
      <submit-data-popup
        v-if="pattern"
        :dialog="submitDataDialogPopup"
        :listApprovers="listApprovers"
        :isLoadingSubmitting="isLoadingSubmitting"
        :dataPattern="dataPattern"
        :title="dataTitle"
        @submitHandler="submitRegisterDataHandler"
        @closeDialogSubmitData="closeDialogSubmitData"
      />
      <save-template-data-popup
        :dialog="saveTemplateDataDialogPopup"
        :isCloneTemplate="isCloneTemplate"
        @submitHandler="submitTemplateAction"
        @closeDialogSaveTemplate="closeDialogSaveTemplate"
      />
      <error-popup
        :dialog="warningTemplateDataDialogPopup"
        :message="messageWarningTemplate"
        :isErrorImg="true"
        @submit="closeDialogWarningSaveTemplate"
      />
      <approval-popup
        :dialog="dialogApproval"
        :items="listStatusApproval"
        :confirmText="'決定'"
        @submit="approvalDataHandler"
        @close="dialogApproval = false"
      />
      <notification-popup
        :dialog="dialogNotification"
        :message="messageNotification"
        @submit="redirectAfterClickBtn(redirectUrl)"
        classes="register"
      />
      <question-popup
        :dialog="dialogQuestion"
        :confirm="true"
        :message="messageQuestion"
        :confirmText="confirmQuestionText"
        :closeText="closeText"
        :isCloneTemplate="isCloneTemplate"
        :isSaveTemplateButton="isSaveTemplateButton"
        @close="closeQuestionPopUp()"
        classes="register"
        @submit="submitAction()"
      />
      <accept-cancel-popup
        :dialog="dialogAcceptCancel"
        :items="listStatusCancel"
        :confirmText="'決定'"
        @submit="handleAcceptRequestCancelSubmit"
        @close="dialogAcceptCancel = false"
      />
    </div>

    <radio-popup
      :scope="scope" 
      :category="category"
      :dialog="radioDialog"
      :radioType="radioType"
      :items="methodList"
      :method="method"
      :method-layer2="methodLayer2"
      :currentSelect.sync="currentSelected"
      :listMethodsAtive="listMethodsAtive"
      @onChange="onChangeRadio"
      @close="closeRadioPopup"
      @submit="submitRadioPopup"
    />
    <radio-popup
      :scope="scope" 
      :category="category"
      :listMethodsAtive="listMethodsAtive"
      :dialog="radioDialogLayer2"
      :radioType="radioType"
      :items="methodListLayer2"
      :method="methodLayer2"
      :currentSelect.sync="currentSelectedLayer2"
      @onChange="onChangeRadio"
      @close="backtoLayer1"
      @submit="submitLayer2RadioPopup"
    />
    <error-popup
      :dialog="isShowPopup"
      message="一般廃棄物と産業廃棄物のどちらか一方のみ入力してください。"
      @submit="handleClosePopup"
    />
    <error-popup
      :dialog="isShowPopupUnCheckPermission"
      message="現在、承認が要求されている登録データを閲覧する権限が付与されておりません。管理者に権限の付与を依頼してください。"
      @submit="handleClosePopupError"
    />
    <error-popup :dialog="isErrorFilterData" :message="getErrorFilterData" @submit="handleCloseFilterData" />
    <notification-popup
      :dialog="isShowPopupCsv"
      :message="messagePopupCsv"
      @submit="isShowPopupCsv = false"
      classes="register"
    />
    <ExportReportPopup
      :key="exportDialogKey"
      :dialog.sync="exportDialog"
      :method="method"
      :methodLayer2="methodLayer2"
      :monthYearSelectionList="monthYearSelectionList"
    />
  </div>
</template>
<script>
// import pattern
import partern1 from '@/concerns/newRegisterData/scope1category1';
import partern2 from '@/concerns/newRegisterData/scope1category1method2';
import partern3 from '@/concerns/newRegisterData/scope1category1method3';
import partern4 from '@/concerns/newRegisterData/scope2method1';
import partern5 from '@/concerns/newRegisterData/scope3category1';
import partern6 from '@/concerns/newRegisterData/scope3category2';
import partern7 from '@/concerns/newRegisterData/scope3category3';
import partern8method1 from '@/concerns/newRegisterData/scope3category4method4';
import partern8method2 from '@/concerns/newRegisterData/scope3category4method5';
import partern8method3 from '@/concerns/newRegisterData/scope3category4method6';
import partern9method1 from '@/concerns/newRegisterData/scope3category4method7';
import partern9method2 from '@/concerns/newRegisterData/scope3category4method8';
import partern9method3 from '@/concerns/newRegisterData/scope3category4method2';
import partern11 from '@/concerns/newRegisterData/scope3category5';
import partern12 from '@/concerns/registerData/scope3category6method4';
import partern12method1 from '@/concerns/newRegisterData/scope3category6method1';
import partern12method2 from '@/concerns/newRegisterData/scope3category6method2';
import partern12method3 from '@/concerns/newRegisterData/scope3category6method3';
import partern12method4 from '@/concerns/newRegisterData/scope3category6method4';
import partern12method5 from '@/concerns/newRegisterData/scope3category6method5';
import partern12method6 from '@/concerns/newRegisterData/scope3category6method6';
import partern12method7 from '@/concerns/newRegisterData/scope3category6method7';
import partern13 from '@/concerns/newRegisterData/scope3category7method1';
import partern14 from '@/concerns/newRegisterData/scope3category7method2';
import partern14Method3 from '@/concerns/newRegisterData/scope3category7method3';
import partern14Method4 from '@/concerns/newRegisterData/scope3category7method4';
import partern14Method5 from '@/concerns/newRegisterData/scope3category7method5';
import partern15 from '@/concerns/newRegisterData/scope3category8';
import partern16 from '@/concerns/newRegisterData/scope3category9method1';
import partern16method2 from '@/concerns/newRegisterData/scope3category9method2';
import partern16method3 from '@/concerns/newRegisterData/scope3category9method3';
import partern16method4 from '@/concerns/newRegisterData/scope3category9method4';
import partern16method5 from '@/concerns/newRegisterData/scope3category9method5';
import partern17 from '@/concerns/newRegisterData/scope3category10';
import partern18 from '@/concerns/registerData/scope3category11';
import partern18method1 from '@/concerns/newRegisterData/scope3category11method1';
import partern18method2 from '@/concerns/newRegisterData/scope3category11method2';
import partern18method3 from '@/concerns/newRegisterData/scope3category11method3';
import partern18method4 from '@/concerns/newRegisterData/scope3category11method4';
import partern19 from '@/concerns/newRegisterData/scope3category12';
import partern20 from '@/concerns/newRegisterData/scope3category13';
import partern21 from '@/concerns/newRegisterData/scope3category14';
import partern26 from '@/concerns/newRegisterData/scope3category16';
import partern22 from '@/concerns/newRegisterData/scope3category15method1';
import partern23 from '@/concerns/newRegisterData/scope3category15method2';
import partern24 from '@/concerns/newRegisterData/scope3category15method3';
import partern25 from '@/concerns/newRegisterData/scope3category15method4';
import partern27 from '@/concerns/newRegisterData/scope4category1';
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.grid';
import '@grapecity/wijmo.input';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import DataTable from '@/components/category/data-table';
import { validateMaximumCharactor, validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import { UndoStack } from '@grapecity/wijmo.undo';
import {
  maximum128,
  maximum20,
  maximum10,
  listNumbers,
  maximum20WithoutRequired,
  maximum128WithoutRequired,
  maximum10WithoutRequired,
  ACTION_HEADER_TABLE,
  SCOPE,
  CATEGORY,
  METHOD_3,
  BLANK_ID,
  APPROVAL_TYPE,
  APPROVAL_STATUS,
  CANCEL_TYPE,
  ORGANIZATIONAL_DATA
} from '@/constants/registerData';
import webStorage from '@/utils/webStorage';
import { makeNewId } from '@/concerns/utils/master-or-customize-db';
import { getDefaultMethods } from "@/utils/updateDataBeforeRedirect";
import store from '@/store';
import * as wjGrid from '@grapecity/wijmo.grid';
import { AutoComplete } from '@grapecity/wijmo.input';
import CustomTable from '@/components/newRegisterData/pattern-table';
import description from '@/components/newRegisterData/description';
import ExportReportPopup from '@/components/dialogs/export-report-popup.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import SaveTemplateDataPopup from '@/components/dialogs/ActionHeaderTable/save-template-data-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import AcceptCancelPopup from '@/components/dialogs/accept-cancel-popup';
import SelectItem from '@/components/utils/selectItem.vue';
import CommonButton from '@/components/utils/button.vue';
import MonthSelectionPc from '@/components/utils/monthSelectionPc.vue';
import RadioPopup from '@/components/dialogs/radio-popup';
import { DESCRIPTIONS } from '@/constants/descriptions';
import { ROUTES } from '@/router/constants';
import {
  getListDetailBranch,
  getListData,
  getListDbCustomize,
  getListMasterDb,
  updateListData,
} from '@/api/registerData';
import { getDurations } from '@/api/duration';
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { mapGetters, mapActions, mapState } from 'vuex';
import { formatDateTime } from '@/utils/datetimeFormat';
import { STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ROLE } from '@/constants/role';
import { KEYS_CODE } from '@/constants/keyboard';
import moment from 'moment';
import {
  updateTemplateData,
  deleteTemplate,
  approvalDataAction,
  cancelSubmittedData,
  saveDraftAction,
  submitDataAction,
} from '@/api/newRegisterData';
import _ from 'lodash';
import { getErrorMessge } from '@/utils/messageHandle';
import { MESSAGE_NOT_NULL, MESSAGE_INCORRECT_YEAR, MESSAGE_POSITIVE_NUMBER } from '@/constants/registerData';
import { formatValue, math, formatBigNumber, $_helper_isNumberType} from '@/concerns/newRegisterData/wijmo.helper';
import { CellEditEndingEventArgs, CellRange } from '@grapecity/wijmo.grid';
import { convertCsvToJson, convertCsvToJsonNew } from '@/utils/csvImportHandler';
export default {
  components: {
    description,
    CustomTable,
    SelectItem,
    CommonButton,
    RadioPopup,
    MonthSelectionPc,
    ErrorPopup,
    DataTable,
    SubmitDataPopup,
    SaveTemplateDataPopup,
    NotificationPopup,
    QuestionPopup,
    ApprovalPopup,
    AcceptCancelPopup,
    ExportReportPopup
  },
  data() {
    return {
      isRegisterNew:false,
      isUpdated: false,
      currentSelected: 1,
      flex: null,
      data: [],
      gridColumns: [],
      countries: [],
      isHasMethod: true,
      status: 0,
      idStatus: null,
      statusList: [
        {
          value: 0,
          text: '未入力',
        },
        {
          value: 1,
          text: '入力途中',
        },
        {
          value: 2,
          text: '入力済み',
        },
        {
          value: 3,
          text: '該当なし',
        },
      ],
      exportDialog: false,
      method: null,
      listMethodsAtive:[],
      methodSize: 1,
      methodList: [],
      scope: 1,
      category: 1,
      partern: null,
      year: 2022,
      curentYear: null,
      curentMonth: null,
      // month: null,
      message: '入力内容が間違っています。\n 内容を確認して再度入力をお願いします。',
      selectedDuration: null,
      selectDurationChange: null,
      startYear: null,
      endYear: null,
      startMonth: 1,
      endMonth: null,
      yearSelected: null,
      curentIndexDuration: 0,
      durationList: [],
      selectMonthYearChange: null,
      monthYearSelectionList: [],
      radioDialog: false,
      radioType: '算出方法を選択',
      isUpdateMonthToStartMonth: false,
      radioDialogLayer2: false,
      methodListLayer2: [],
      methodLayer2: null,
      currentSelectedLayer2: null,
      isErrorFilterData: false,
      registerWorkflow: {},
      isHasData: false,
      dataPattern: {},
      workFlowData: {},
      layerData: [],
      selectedDurationText: null,
      // pattern-table variables
      listDataPattern: [],
      header: null,
      pattern: null,
      dataTable: null,
      init: () => {},
      perPage: 5,
      order: 'id',
      listNumberItems: [
        'industrial_waste_value',
        'te_value',
        'value',
        'employees_number_value',
        'working_days_number_value',
        'loading_data_maximum',
        'loading_data_rate',
        'distance',
        'fuel_efficiency',
        'energy_value',
        'sales_volume_value',
        'scale_index_value',
        'dir_value',
        'qd_value',
        'energy_saving_value',
        'scope12_overlap',
        'aoa_value',
        'cpa_value',
        'transport_weight_value',
        'sea_distance_value',
        'travel_value',
        'iss_value',
        'ir_internal_investment',
        'ir_total_investment',
        'ie_company_value',
        'ir_calculated_denominator',
        'ir_calculation_numerator',
      ],
      scope_has_wsu_number: [3],
      energyTypes_list: [
        {
          id: 1,
          name: 'ガソリン',
          unit: 'KL',
          wsu_value: 2.5,
          wsu_unit: 't-CO2/KL',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 2,
          name: '経由',
          unit: 'KL',
          wsu_value: 2,
          wsu_unit: 't-CO2/KL',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 3,
          name: '都市ガス',
          unit: '千㎥',
          wsu_value: 3,
          wsu_unit: 't-CO2/千㎥',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 4,
          name: 'type test',
          unit: '千㎥',
          wsu_value: 4,
          wsu_unit: 't-CO2/千㎥',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
      ],
      key: 0,
      isConvert: false,
      listDataActions: [],
      undoStack: null,
      canUndo: false,
      canRedo: false,
      actionCount: 0,
      timeoutUndoStack: false,
      lastedRow: null,
      flexgrid: null,
      originalData: [],
      listRowFields: [
        'business_name',
        'company_name',
        'db_master_id',
        'duration_id',
        'memo_1',
        'memo_2',
        'month',
        'organizational_division',
        'qd_source',
        'qd_unit',
        'qd_value',
        'supplier_name',
        'type',
        'wsu_source',
        'wsu_unit',
        'wsu_value',
        'year',
      ],
      layer: [],
      layerIndex: {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      },
      submitDataDialogPopup: false,
      saveTemplateDataDialogPopup: false,
      warningTemplateDataDialogPopup: false,
      dialogNotification: false,
      isShowPopupUnCheckPermission: false,
      messageNotification: '',
      isHasDataTable: false,
      itemsInView: [],
      dataTitle: null,
      isHasErrorData: false,
      dialogQuestion: false,
      messageQuestion: 'احذف بيانات التسجيل هذه. هل الامور على ما يرام؟',
      confirmQuestionText: 'يحفظ',
      isSaveTemplateButton: false,
      dialogApproval: false,
      dialogCancelSubmit: false,
      listStatusApproval: [],
      redirectUrl: '',
      isCloneTemplate: false,
      totalEmissions: 0,
      isReadOnly: false,
      durationText: '',
      totalTreatment: {
        incinerator: 0,
        landfill: 0,
        recycling: 0,
        unknown: 0,
      },
      isGetAllData: false,
      templateTitle: null,
      isSubmitData: false,
      approvalForm: {},
      isReloadPage: true,
      messageWarningTemplate: '現在、共有メンバーがこのテンプレートを開いているため共有を解除できません。',
      isDisplayDescription: false,
      listStatusCancel: [],
      dialogAcceptCancel: false,
      isEditDataTable: false,
      messagePopupCsv: '',
      isShowPopupCsv: false,
      lengthCsv: 0,
      itemErrorCount: 0,
      latestRecord: {},
      exportDialogKey: 0,
      closeText: ''
    };
  },
  async mounted() {
    const dataImportFromSession = sessionStorage.getItem('csvImportEvent');
    const isHasFlagImport = this.$route.query?.import;
    if(!dataImportFromSession && isHasFlagImport) {
      this.$router.replace({ path: this.$route.path }) // clear flag import in router
    }
    this.listNumberItems = [
      'value', 'loading_data_maximum', 'loading_data_rate',
      'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
      'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
      'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
      'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
      'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
      'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
      'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','wsu_value', 'le_value','pj_investment_ratio','emissions'
    ];
    if (this.$route.fullPath.includes('/emissions/view/')) {
      this.isGetAllData = true;
      this.method = this.$store.state.newRegisterData.methodLayer1Id;
      this.methodLayer2 = this.$store.state.newRegisterData.methodLayer2Id;
      
    }
    if (
      [
        ROUTES.LIST_EMISSION_SUBMITTED,
        ROUTES.LIST_EMISSION_TEMPLATES,
        ROUTES.LIST_EMISSION_TEMPORARY,
        ROUTES.LIST_APPROVAL,
        ROUTES.LIST_MENU_EMISSION,
      ].includes(this.$route.params?.scope)
    ) {
      let workFollowDataId = { id: this.$route.params?.category };
      // CALL API GET scope and category, title, pattern_id by data Id
      try {
        this.isHasData = true;
        await this.getListDataPattern(workFollowDataId).then((res) => {
          // this.dataPattern = res;
          if (!this.isApproved) {
            this.dataPattern.data = res.data;
            this.dataPattern.latest_record = res.latest_record;
            this.latestRecord = res.latest_record;
          }
          this.dataPattern.unCheckPermission = res?.check_permission;
          this.dataPattern.workflow_data = res.workflow_data;
          this.dataPattern.layer = res.layer;
          let dataWorkFollow = res.workflow_data;
          this.workFlowData = dataWorkFollow;
          this.scope = parseInt(dataWorkFollow.scope);
          this.category = parseInt(dataWorkFollow.category);
          this.registerWorkflow = {
            scope: this.scope,
            category: this.category,
            pattern_id: dataWorkFollow.pattern_id,
            title: dataWorkFollow.title,
            contractor_id: this.currentUser.user.contractor_id,
            data_id: dataWorkFollow.data_id,
            id: dataWorkFollow.id,
          };
          if (this.currentUser?.user?.role_id !== ROLE.USER && (dataWorkFollow.status === STATUS_SUBMIT || dataWorkFollow.status === STATUS_CANCEL_SUBMMITED)) {
            this.isShowPopupUnCheckPermission = this.dataPattern.unCheckPermission;
          }
          if (
            (dataWorkFollow.unread_flg &&
              dataWorkFollow.is_approver === 1 &&
              [STATUS_SUBMIT, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) || // cal API if user is approver
            (dataWorkFollow.unread_flg &&
              dataWorkFollow.is_owner === 1 &&
              [STATUS_RETURN, STATUS_REPRENSENT_RETURN].includes(dataWorkFollow.status)) // call API if user is owner
          ) {
            if ([STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) {
              const params = {
                id: dataWorkFollow.id,
              };
              this.setNotification(params).then(() => {
                this.getNotification();
              });
            }
          }
        });
      } catch (error) {
        switch (this.$route.params?.scope) {
          case ROUTES.LIST_EMISSION_TEMPLATES:
            this.$router.push({ path: '/emissions/list-templates' });
            break;
          case ROUTES.LIST_MENU_EMISSION:
            this.$router.push({ path: '/emissions' });
            break;
          case ROUTES.LIST_EMISSION_SUBMITTED:
            this.$router.push({ path: '/emissions/list-submitted' });
            break;
          case ROUTES.LIST_APPROVAL:
            this.$router.push({ path: '/approval' });
            break;
          default:
            break;
        }
      }
    } else if (isNaN(parseInt(this.$route.params?.scope))) {
      return this.$router.push('/404');
    } else {
      // TODO: Remove hard code by send in query
      // Payload in register data by click new register button
      this.scope = parseInt(this.$route.params?.scope) || 1;
      this.category = parseInt(this.$route.params?.category) || 1;
      this.isRegisterNew= true;
      this.registerWorkflow = {
        scope: this.scope,
        category: this.category,
        pattern_id: 1,
        title: 'Title create new',
        contractor_id: this.currentUser.user.contractor_id,
      };
      // Reset register data empty
      await this.$store.dispatch('newRegisterData/setListDataPatternInit', {});
    }
    this.$store.dispatch('registerData/updateListDbCustomize', [], { root: true });
    if (this.$route.fullPath.includes('/emissions/register/approval') || this.$route.fullPath.includes('/emissions/register/list-menu')) {
      let interval = setInterval(async () => {
        if ((this.$route.params?.scope === ROUTES.LIST_APPROVAL ||
          this.$route.params?.scope === ROUTES.LIST_MENU_EMISSION) &&
        this.isApproved) {
          const month = this.$store.state.registerData.month;
          const duration = this.$store.state.registerData.duration;
          if (month && duration) {
            clearInterval(interval);
            await this.getUrlFromScopeCategory({ id: this.$route.params?.category });
            this.getListDurations();
            this.getHeaderFromScope();
            this.getDataFromScope()
          }
        }
      }, 100);
    }
    this.isDisplayDescription = true
    await this.getListDurations();
    // Call API check disable scope 3 or not
    if (this.$route.query?.duration) {
      this.selectDurationChange = parseInt(this.$route.query?.duration)     
    }
    await getScopesAndCategories({ duration_id: this.selectDurationChange, display_scope_3_flg: 1 })
        .then((response) => {
          if (!response.display_scope_3 && this.scope === 3) {
            return this.$router.push('/404');
          }
        })
        .catch(() => {});

    if(!this.selectMonthYearChange) {
      await getListDetailBranch().then((res) => {
        this.layerData = res.layer;
      });
    } 

    this.getApproversList();
    this.actionShowPopup(false);
    this.actionUpdateIsFullScreen(false);
    this.isUpdated = false;

    this.curentYear = new Date().getFullYear();
    this.curentMonth = new Date().getMonth() + 1;

    this.getMethodList(this.scope, this.category);

    if (!this.methodLayer1Id && !this.methodLayer2Id) {
      await getDefaultMethods(this.scope, this.category);
    }

    this.method = this.methodLayer1Id;
    this.currentSelected = this.method;
    this.getMethodListLayer2();
    this.methodLayer2 = this.methodLayer2Id;
    this.currentSelectedLayer2 = this.methodLayer2;
    this.updateBreadCrumb(this.breadcrumbOption);
    if (this.dataPattern?.workflow_data?.id && this.dataPattern?.workflow_data?.template_type !== null) {
      this.selectMonthYearChange = null;
    } else {
      this.getSelectedMonth();
    }
    this.updateMonthYearSelected();
    this.isErrorFilterData = false;
    this.$store.dispatch('registerData/updateListErrorFilterDataMessage', '', { root: true });
    // pattern-table
    this.actionUpdateIsClearUndoStack(false);
    this.$emit('updateIsUpdated', false);
    this.updateIsUpdated(false);
    this.updateActionName(''); // update action name
    this.actionUpdateStatusBtn({ undo: false, redo: false });
    this.updateUndoRedoClick('');
    this.updateUndoStack(null);
    this.updateUndoRedoStackData({
      undo: [],
      redo: [],
    });
    this.$store.dispatch('registerData/updateDataList', []);
    this.$store.dispatch('registerData/updateStatusIsUpdateData', false);
    this.$store.dispatch('registerData/updateTotalEmission', 0);
    this.$store.dispatch('registerData/updateTotalDurationEmission', 0);
    this.$store.commit('registerData/updateEnergyType', this.energyTypes_list);
    // this.$store.dispatch('registerData/updateLatestRecord', {});
    this.actionAllowAddNew(true);
    

    const listRouteGetData = [
      'emissions/register/approval',
      'emissions/register/list-menu',
    ]
    const isNotApprovalOrMenu = listRouteGetData.every(path => !this.$route.path?.includes(path))
    if (!this.isGetAllData && isNotApprovalOrMenu || !this.isApproved) {
      this.getListDurations();
      this.getHeaderFromScope();
      this.getDataFromScope();
    }
    if(!this.$route.query?.import) {
      this.isHasDataTable = this.isHasData
    }
    webStorage.remove('duration');
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('emission', ['selectDuration']),
    ...mapActions('registerData', [
      'updateDataScope',
      'updateDataCategory',
      'updateDataMonth',
      'updateDataMethod',
      'updateDuration',
      'updateYearSelected',
      'updateStartMonth',
      'updateIsUpdated',
      'actionUpdateIsFullScreen',
      'actionShowPopup',
      'updateDataMethodLayer2',
      // pattern-table
      'updateUndoRedoClick',
      'actionUpdateIsClearUndoStack',
      'updateUndoStack',
      'actionAllowAddNew',
      'updateUndoRedoStackData',
      'updateOrderNumberDontHavePermissions',
    ]),
    ...mapActions('newRegisterData', [
      'getListDataPattern',
      'addRegisterDataWorkFollow',
      'updateMethodLayer1Id',
      'updateMethodLayer2Id',
      'updateIsEditData'
    ]),
    ...mapActions('workflowData', ['setNotification', 'getNotification']),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions('actionsTable', ['actionUpdate', 'actionUpdateStatusBtn', 'updateActionDelete', 'updateActionName']),
    convertFuelOption(fuel){
      if(!fuel) return null;
      const listOldFuel= [
        {scope: 1, category: 1, method: 2 },
        {scope: 3, category: 4, method: 1, methodLayer2: 5 },
        {scope: 3, category: 9, method: 1, methodLayer2: 2 }
      ]
      const isNoReplace= listOldFuel.find(pattern=>
      {
        if(this.scope == 1) {
          return pattern.scope == this.scope && pattern.category == this.category && pattern.method == this.method 
        }else if( this.scope==3){
          return pattern.scope == this.scope && pattern.category == this.category && pattern.method == this.method && pattern.methodLayer2 == this.methodLayer2 
        }else{
          return false
        }
    
      })
      const isOldFuelLabel= ['ガソリン', '軽油', 'その他'].includes(fuel)
      if(isOldFuelLabel && isNoReplace) {
        return  fuel
      }else 
        return ['ガソリン', '自動車輸送 ガソリンの使用'].includes(fuel) ?
          "自動車輸送 ガソリンの使用" 
          :"自動車輸送 軽油の使用"
    },
    getSubstringRange(columnName){
      return ['fuel_efficiency', 'loading_data_rate', 'pj_investment_ratio'].includes(columnName) ? 10: 20
    },
    initTheTable(flexGrid) {
      this.pattern.initializeGrid(flexGrid);
      flexGrid.hostElement?.addEventListener(
        'keydown',
        (event) => {
          if (
            (event.keyCode === KEYS_CODE.BACKSPACE || event.keyCode === KEYS_CODE.DELETE) &&
            !flexGrid.selection.isSingleCell
          ) {
            flexGrid.autoSizeColumns();
            flexGrid.autoSizeRows(0, 0, true);
          }
        },
        false,
      );
      flexGrid.pasted.addHandler(() => {
        flexGrid.autoSizeColumns();
        flexGrid.autoSizeRows(0, 0, true);
      });
      flexGrid.autoSizeColumns();
    },
    handleClosePopup() {
      this.actionShowPopup(false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
    },
    handleClosePopupError() {
      this.isShowPopupUnCheckPermission = false;
    },
    updateIsUpdate() {
      this.updateIsUpdated(true);
    },
    formatNumber(num) {
      return formatBigNumber(num).replace(/[^0-9\.\-]+/g, '').replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); // replace "" to null; example "0" -> 0;
    },
    closeRadioPopup() {
      this.radioDialog = false;
      this.radioDialogLayer2 = false;
    },
    backtoLayer1() {
      this.radioDialogLayer2 = false;
      this.radioDialog = true;
    },
    onChangeRadio(newValue) {
      // this.method = newValue;
      this.closeRadioPopup();
    },
    async getListDurations(value) {
      let realMethod = this.method;
      if(this.scope === 3 && this.category === 4) {
        if(!this.methodLayer2 && this.method === 3) {
          realMethod = 2
        }
      }
      if (this.methodLayer2) {
        realMethod = this.methodLayer2
      }
      if (this.scope === 3 && this.category === 4) {
        if (this.method === 3) {
          realMethod = 2;
        }
      }
      if (this.scope === 3 && this.category === 11) {
        if (this.method === 2) {
          realMethod = 4;
        }
      }
      let durationLists = await getDurations({
        scope: this.scope,
        category: this.category,
        method: realMethod,
        has_approved_data: 1,
      });
      await durationLists?.data.forEach((item) => {
        let duration = {
          ...item,
          value: item.id,
          text: `${moment(item.start_at).format('YYYY/M')} - ${moment(item.end_at).format('YYYY/M')}`,
        };
        this.durationList.push(duration);
        if(this.isCreateNewData()) {
          this.selectDurationChange = this.dataPattern?.workflow_data?.duration_id;
          if(item.id === this.dataPattern?.workflow_data?.duration_id) {
            this.getMonthSelectionList(item.start_at, item.end_at, item.duration_registered);
          }
        } else {
          if (parseInt(this.$route.query?.duration) === item.id || item.is_selected === true) {
            this.selectedDuration = item.id;
            if (value) {
              if(!this.$route.query?.duration) {
                this.selectDurationChange = Number(value);
              }

              if(item.id === Number(value)) {
                this.getMonthSelectionList(item.start_at, item.end_at, item.duration_registered);
              }
            } else {
              if(!this.$route.query?.duration) {
                this.selectDurationChange = item.id;
              }
              this.getMonthSelectionList(item.start_at, item.end_at, item.duration_registered);
            }
          }
        }
      });
    },
    getSelectedDurationText() {
      const selectedDuration = this.durationList.find((duration) => duration.id === this.selectDurationChange);
      this.selectedDurationText = selectedDuration?.start_at;
      const startAt = new Date(selectedDuration?.start_at);
      const endAt = new Date(selectedDuration?.end_at);
      return `${moment(startAt).format('YYYY/M')} - ${moment(endAt).format('YYYY/M')}`;
    },
    getSelectedMonthText() {
      if (this.selectMonthYearChange) {
        const selectedMonth = this.monthYearSelectionList.find((month) => month.value === this.selectMonthYearChange);
        return selectedMonth?.text;
      }
      return '----年--月';
    },
    getMonthSelectionList(startAt, endAt, duration_registered = []) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat = `${moment.utc( durationStartAt).format("YYYY/M")}`;

      let newRegisterDuration = duration_registered.map((yearMonth) => `${yearMonth.year}年${yearMonth.month}月`);

      this.monthYearSelectionList = [
        {
          text: timeFormat,
          value: durationStartAt.getMonth() + 1,
          duration_registered: newRegisterDuration.find((yearMonth) => yearMonth === timeFormat),
        },
      ];

      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = `${moment.utc( durationStartAt).format("YYYY/M")}`;

        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1,
          duration_registered: newRegisterDuration.find((yearMonth) => yearMonth === localeString),
        };
        this.monthYearSelectionList.push(currentMonthYear);
      }
    },
    getMethodList(scope, category) {
      this.methodSize = 1;
      this.methodList = [];
      let listMethodName = [];
      let description = [];

      if (scope === 1) {
        this.methodSize = 3;
        listMethodName = ['طرق الوقود', 'طريقة طن كيلومتر', 'طريقة استهلاك الوقود'];
        description = [
          'محسوب على أساس كمية الوقود المستخدمة',
          'يعتمد الحساب على نسبة التحميل لكل طن - كيلومتر ، وأنواع وقود المركبات ، وقدرة التحميل القصوى.',
          'يعتمد الحساب على استهلاك الوقود للمركبة والمسافة المقطوعة',
        ];
      }
      if (scope === 3) {
        if (category === 4) {
          this.methodSize = 3;
          listMethodName = ['輸送', '拠点', '荷主責任報告値を用いる方法'];
          description = [
            '輸送のエネルギー使用による排出量を、算定報告公表制度における特定荷主の算定方法を適用して算定。',
            '物流拠点や販売拠点での荷役・保管・販売について、対象拠点におけるエネルギー使用に伴う排出を算定。',
            '改正省エネ法の値。',
          ];
        }
        if (category === 6) {
          this.methodSize = 7;
          listMethodName = [
            '旅客人キロから算定',
            '燃料法で算定',
            '燃費法で算定',
            '支給額から算定', //s3c6 old
            '宿泊数から算定',
            '出張日数から算定',
            '従業員数から算定',
          ];
          description = [
            '各交通機関を通じた移動距離と移動人数から算定します。',
            '移動のための消費された燃料使用量から算定します。',
            '各交通機関を通じた移動距離と、移動の燃費から算定します。',
            '各交通機関を通じた交通費支給額から算定します。',
            '出張者の宿泊に伴う排出量を算定する場合に利用します。',
            '出張日数から算定します。',
            '常時使用する従業員数から算定します。',
          ];
        }
        if (category === 7) {
          this.methodSize = 5;
          listMethodName = [
            '旅客人キロから算定',
            '燃料法で算定',
            '燃費法で算定',
            '支給額から算定',
            '従業員数・営業日数から算定',
          ];
          description = [
            '各交通機関を通じた移動距離と移動人数から算定します。',
            '移動のための消費された燃料使用量から算定します。',
            '各交通機関を通じた移動距離と、移動の燃費から算定します。',
            '各交通機関を通じた交通費支給額から算定します。',
            '常時使用する従業員数及び年間の営業日数から算定します。',
          ];
        }
        if (category === 15) {
          this.methodSize = 4;
          listMethodName = ['株式投資', 'プロジェクトファイナンスによる融資', '債券投資', '管理投資他'];
          description = [
            '自社が保有する株式に関する排出量を算定。',
            'プロジェクトファイナンスによる融資分の排出量を算定。',
            '債権投資による排出量を算定。',
            '管理投資による排出量を算定。',
          ];
        }
        if (category === 9) {
          this.methodSize = 2;
          listMethodName = ['輸送', '拠点'];
          description = [
            '輸送のエネルギー使用による排出量を、算定報告公表制度における特定荷主の算定方法を適用して算定。',
            '物流拠点や販売拠点での荷役・保管・販売について、対象拠点におけるエネルギー使用に伴う排出を算定。',
          ];
        }
        if (category === 11) {
          this.methodSize = 2;
          listMethodName = ['直接使用段階の排出量から算定', '間接使用段階の排出量から算定'];
          description = [
            '家電製品等、製品使用時の電気・燃料・熱等の使用に伴う排出量、またはエアコン等使用時に6.5ガスを直接排出する製品における排出量を算定します。',
            '衣類（洗濯・乾燥が必要）、食料（調理・冷蔵・冷凍が必要）等、製品使用時に間接的に電気・燃料・熱を使用する製品の排出量を算定します。',
          ];
        }
      }

      for (let index = 1; index <= this.methodSize; index++) {
        let method = {
          text: listMethodName[index - 1],
          value: index,
          id: index,
          name: listMethodName[index - 1],
          description: description[index - 1],
        };
        this.methodList.push(method);
      }
    },
    getMethodListLayer2() {
      if (this.scope === 3 && this.category === 4 && this.currentSelected === 1) {
        this.methodListLayer2 = [];
        let listMethodName = [];
        let description = [];
        this.methodSize = 6;
        listMethodName = ['燃料法・配送料金で算定', 'tkm法で算定', '燃費法で算定'];
        description = [
          '燃料の使用量を把握して算定',
          '積載率と車両の燃料種類、最大積載量別の輸送トンキロから算定',
          '車両の燃費と輸送距離を把握して算定',
        ];
        for (let index = 4; index <= this.methodSize; index++) {
          let method = {
            text: listMethodName[index - 4],
            value: index,
            id: index,
            name: listMethodName[index - 4],
            description: description[index - 4],
          };
          this.methodListLayer2.push(method);
        }
      } else if (this.scope === 3 && this.category === 4 && this.currentSelected === 2) {
        this.methodListLayer2 = [];
        let listMethodName = [];
        let description = [];
        this.methodSize = 8;
        listMethodName = ['燃料', '電気'];
        description = [
          '物流拠点や販売拠点での荷役・保管・販売について、対象拠点における燃料使用に伴う排出を算定。',
          '物流拠点や販売拠点での荷役・保管・販売について、対象拠点におけるエネルギー使用に伴う排出を算定。',
        ];
        for (let index = 7; index <= this.methodSize; index++) {
          let method = {
            text: listMethodName[index - 7],
            value: index,
            id: index,
            name: listMethodName[index - 7],
            description: description[index - 7],
          };
          this.methodListLayer2.push(method);
        }
      } else if (this.scope === 3 && this.category === 9 && this.currentSelected === 1) {
        this.methodListLayer2 = [];
        let listMethodName = [];
        let description = [];
        this.methodSize = 3;
        listMethodName = ['燃料法・配送料金で算定', 'tkm法で算定', '燃費法で算定'];
        description = [
          '燃料の使用量を把握して算定',
          '積載率と車両の燃料種類、最大積載量別の輸送トンキロから算定',
          '車両の燃費と輸送距離を把握して算定',
        ];
        for (let index = 1; index <= this.methodSize; index++) {
          let method = {
            text: listMethodName[index - 1],
            value: index,
            id: index,
            name: listMethodName[index - 1],
            description: description[index - 1],
          };
          this.methodListLayer2.push(method);
        }
      } else if (this.scope === 3 && this.category === 9 && this.currentSelected === 2) {
        this.methodListLayer2 = [];
        let listMethodName = [];
        let description = [];
        this.methodSize = 5;
        listMethodName = ['燃料', '電気'];
        description = [
          '物流拠点や販売拠点での荷役・保管・販売について、対象拠点における燃料使用に伴う排出を算定。',
          '物流拠点や販売拠点での荷役・保管・販売について、対象拠点におけるエネルギー使用に伴う排出を算定。',
        ];
        for (let index = 4; index <= this.methodSize; index++) {
          let method = {
            text: listMethodName[index - 4],
            value: index,
            id: index,
            name: listMethodName[index - 4],
            description: description[index - 4],
          };
          this.methodListLayer2.push(method);
        }
      } else if (this.scope === 3 && this.category === 11 && this.currentSelected === 1) {
        this.methodListLayer2 = [];
        let listMethodName = [];
        let description = [];
        this.methodSize = 3;
        listMethodName = ['エネルギー使用製品', '燃料・フィードストック', 'GHG含有製品で、使用時にGHGを排出するもの'];
        description = ['自動車、エンジン、家電等', '石炭、石油、都市ガス等', 'ドライアイス、消化器、肥料等'];
        for (let index = 0; index < this.methodSize; index++) {
          let method = {
            text: listMethodName[index],
            value: index + 1,
            id: index + 1,
            name: listMethodName[index],
            description: description[index],
          };
          this.methodListLayer2.push(method);
        }
      }
    },
    submitRadioPopup() {
      this.radioDialog = false;
      this.method = this.currentSelected;
      if (
        (this.scope === 3 && this.category === 4 && this.currentSelected === 1) ||
        (this.scope === 3 && this.category === 4 && this.currentSelected === 2) ||
        (this.scope === 3 && this.category === 9) ||
        (this.scope === 3 && this.category === 11 && this.currentSelected === 1)
      ) {
        this.isUpdated = false;
        this.radioDialogLayer2 = true;
        this.getMethodListLayer2();
      } else {
        this.isUpdated = true;
      }
    },
    submitLayer2RadioPopup() {
      if (!this.currentSelectedLayer2) {
        this.currentSelectedLayer2 = this.methodListLayer2[0].value;
      }
      this.isUpdated = true;
      this.radioDialog = false;
      this.radioDialogLayer2 = false;
      this.methodLayer2 = this.currentSelectedLayer2;
    },
    handleClose() {
      this.$store.dispatch('registerData/updateIsErrorStatus', false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
      this.$store.dispatch('registerData/updateListErrorMessage', []);
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    openSelectPopup() {
      if (this.dataPattern?.workflow_data?.id) {
        return;
      }
      this.radioDialog = true;
      this.getMethodList(this.scope, this.category);
    },
    handleCloseFilterData() {
      this.isErrorFilterData = false;
      this.$store.dispatch('registerData/updateListErrorFilterDataMessage', '', { root: true });
      this.$store.dispatch('registerData/updateIsErrorStatus', false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
      this.$store.dispatch('registerData/updateListErrorMessage', []);
    },
    async getMasterDb() {
      if(this.scope === 1 && this.method === 1) {
        await getListMasterDb({
          db_types: [1],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: this.method,
          status: true,
        });
      }

      if(this.scope === 1 && (this.method === 2 || this.method === 3)) {
        await getListMasterDb ({
          db_types: [3],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: this.method,
          status: true,
        });
      }

      if(this.scope === 2) {
        await getListMasterDb({
          db_types: [1, 2],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: 0,
          status: true,
        });
      }
      if (this.scope === 3) {
        switch (this.category) {
          case 1:
          case 2:
          case 3:
            await getListMasterDb({
              db_types: [3],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 4:
            if(this.methodLayer2 === 4 || this.methodLayer2 === 7) {
              await getListMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 5 || this.methodLayer2 === 6) {
              await getListMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 8 ) {
              await getListMasterDb({
                db_types: [2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }
            break;
          case 6:
            if(this.method === 2) {
              await getListMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.method,
                status: true,
              });
            } else {
              await getListMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.method,
                status: true,
              });
            }
            break;
          case 7:
            if(this.method === 2) {
              await getListMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.method,
                status: true,
              });
            } else {
              await getListMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.method,
                status: true,
              });
            }
            break;
          case 9:
            if(this.methodLayer2 === 1 || this.methodLayer2 === 4) {
              await getListMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 5) {
              await getListMasterDb({
                db_types: [2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 2 || this.methodLayer2 === 3) {
              await getListMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }
            break;
          case 10:
            await getListMasterDb({
              db_types: [1, 2],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 5:
          case 12:
            await getListMasterDb({
              db_types: [3, 4],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 8:
          case 13:
            await getListMasterDb({
              db_types: [1, 2, 3],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 15:
          case 16:
            await  await this.getDataWithDbCustomize();
            break;
          case 11:
            if(this.methodLayer2 === 1) {
              await getListMasterDb({
                db_types: [1, 2, 3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 2) {
              await getListMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 3) {
              await getListDbCustomize({
                contractor_id: this.currentUser.user.contractor_id,
                category_id: this.category,
                scope_id: this.scope,
                status: true,
              });
            }

            if(this.method === 2) {
              await getListMasterDb({
                db_types: [1, 2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: 4,
                status: true,
              });
            }

            if (this.methodLayer2 === 3) {
              await this.getDataWithDbCustomize();
            }
          default:
            break;
        }
      }
    },
    updateMonthYearSelected() {
      if (!this.selectMonthYearChange) {
        this.updateDataMonth(null);
        this.yearSelected = null;
        return;
      }
      const selectedMonth = this.monthYearSelectionList.find((month) => month.value === this.selectMonthYearChange);
      this.updateDataMonth(this.selectMonthYearChange);
      this.yearSelected = selectedMonth?.text?.split('/')[0];
    },
    getSelectedMonth() {
      if (this.dataPattern?.workflow_data?.id && this.dataPattern?.data && this.dataPattern?.data.length > 0) {
        this.selectMonthYearChange = this.dataPattern?.data[0]?.month;
      } else {
        this.selectMonthYearChange = this.month;
      }
    },
    async getUrlFromScopeCategory(workflowDataId = null) {
      let scope = this.$store.state.registerData.newScope;
      let category = this.$store.state.registerData.category;
      const month = this.$store.state.registerData.month;
      const duration = this.$store.state.registerData.duration;
      let method = this.$store.state.newRegisterData.methodLayer1Id;
      let methodLayer2 = this.$store.state.newRegisterData.methodLayer2Id;
      let params = `duration_id=${duration}&month=${month}&order=id`;
      if (workflowDataId?.id) {
        params += `&workflow_data_id=${workflowDataId?.id}`;
      } else {
        scope = this.scope;
        category = this.category;
        method = this.method;
        methodLayer2 = this.methodLayer2;
      }
      let url = null;
      switch (scope) {
        case 1:
          url = `/pattern-s${scope}/${method || 1}?${params}`;
          break;
        case 2:
          url = `/pattern-s${scope}?${params}`;
          break;
        case 3:
          switch (category) {
            case 4:
              if (method === 3) {
                url = `/pattern-s3-c4/2?${params}`;
              } else {
                url = `/pattern-s3-c4/${methodLayer2 || 1}?${params}`;
              }
              break;
            case 6:
            case 7:
              url = `/pattern-s${scope}-c${category}/${method || 1}?${params}`;
              break;
            case 9:
              url = `/pattern-s3-c9/${methodLayer2 || 1}?${params}`;
              break;
            case 11:
              if (method === 1) {
                url = `/pattern-s3-c11/${methodLayer2 || 1}?${params}`;
              } else {
                url = `/pattern-s3-c11/4?${params}`;
              }
              break;
            case 15:
              url = `/pattern-s3-c15/${method || 1}?${params}`;
              break;
            case 16:
              url = `/pattern-s3-c16?${params}`;
              break;
            default:
              url = `/pattern-s${scope}-c${category}?${params}`;
              break;
          }
          break;
        case 4:
          url = `/pattern-s${scope}-c1?${params}`;
          break;
        default:
          url = `/pattern-s${scope}/${this.patternId}?${params}`;
          break;
      }

      await getListData(url).then((res) => {
        this.dataPattern.data = res.data;
        this.dataPattern.latest_record = res.latest_record;
        this.latestRecord = res.latest_record;
        this.listMethodsAtive = [];
        if(res.hasOwnProperty('method_exits_data')){
          for(const exitData in res.method_exits_data) {
            if(res.method_exits_data[exitData]) {
              this.listMethodsAtive.push(parseInt(exitData))
            }
          }
        }
      });
    },
    // pattern-table function
    onHandleActionTable(action, dataTitle, checkHasUserAccessTemplateDetail) {
      this.dataTitle = dataTitle;
      if (this.scope !== 3) {
        delete this.registerWorkflow.category;
        this.registerWorkflow.pattern_id = this.method ? this.method : 1;
      } else {
        if (this.category === 4) {
          if (this.method === 3) {
            this.registerWorkflow.pattern_id = 2;
          }
          if (this.methodLayer2 !== null) {
            this.registerWorkflow.pattern_id = this.methodLayer2;
          }
        } else if ([6, 7, 15].includes(this.category)) {
          this.registerWorkflow.pattern_id = this.method;
        } else if (this.category === 9) {
          this.registerWorkflow.pattern_id = this.methodLayer2;
        } else if (this.category === 11) {
          if (this.method === 2) {
            this.registerWorkflow.pattern_id = 4;
          }
          if (this.methodLayer2 !== null) {
            this.registerWorkflow.pattern_id = this.methodLayer2;
          }
        } else {
          this.registerWorkflow.pattern_id = 1;
        }
      }
      // return;
      this.closeText = 'يلغي'
      switch (action) {
        case ACTION_HEADER_TABLE.SAVE_TEMPRORA:
          if (this.yearSelected === null && this.month === null) {
            this.dialogNotification = true;
            this.messageNotification =
              '排出量の登録/申請に必要な情報が入力されていないため、登録/申請を完了できません。登録年月、タイトルに未入力箇所がないかをご確認ください。';
            return;
          }
          if (
            this.dataPattern.workflow_data?.id &&
            this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
          ) {
            // clone template from draft
            this.dialogQuestion = true;
            this.messageQuestion = '編集内容を排出量の新規登録データとして保存します。よろしいですか？';
            this.confirmQuestionText = '登録';
          } else {
            this.saveDraftDataHandler();
          }
          break;
        case ACTION_HEADER_TABLE.SAVE_TEMPLATE:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
          } else {
            this.saveTemplateDataDialogPopup = true;
            this.isCloneTemplate = false;
          }
          break;
        case ACTION_HEADER_TABLE.DELETE_DATA:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = "現在、共有メンバーがこのテンプレートを開いているため削除できません。"
            break;
          }
          this.dialogQuestion = true;
          this.confirmQuestionText = 'يمسح';
          if (
            this.dataPattern?.workflow_data.id &&
            this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
          ) {
            // handle remove data to template detail
            this.messageQuestion =
              'このテンプレートを削除します。よろしいですか？ \n\n共有中のテンプレートを削除すると、共有中メンバーのテンプレート一覧からも表示されなくなります。';
          } else {
            this.messageQuestion = 'احذف بيانات التسجيل هذه. هل الامور على ما يرام؟';
          }
          break;
        case ACTION_HEADER_TABLE.SUBMIT_DATA:
          if (this.yearSelected === null && this.month === null) {
            this.dialogNotification = true;
            this.messageNotification =
              '排出量の登録/申請に必要な情報が入力されていないため、登録/申請を完了できません。登録年月、タイトルに未入力箇所がないかをご確認ください。';
            return;
          }
          this.validateDataTable();
          let submitData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
            this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
          );
          if (this.dataPattern.workflow_data?.id) {
            submitData = this.cloneDataHandler();
          }
          if(this.$route.query?.import) {
            submitData = this.getDataImport()
          }
          const isNotExistBranch = submitData.find((item) => !item.branch_id);
          if (isNotExistBranch) {
            const layerName = this.layer.map((layer) => {
              return layer.header;
            });
            this.isReloadPage = false;
            this.dialogNotification = true;
            this.messageNotification = `قسم المؤسسة الذي تم إدخاله ${layerName} / اسم الشركة / المجموعة غير موجودة.`;
            return;
          }
          if (!this.isHasErrorData && this.yearSelected !== null && this.month !== null) {
            this.submitDataDialogPopup = true;
          }
          break;
        case ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = "現在、共有メンバーがこのテンプレートを開いているため上書き保存できません。"
          } else {
            this.dialogQuestion = true;
            this.messageQuestion = '編集内容をこのテンプレートに上書き保存します。よろしいですか？';
            this.confirmQuestionText = 'يحفظ';
          }
          break;
        case ACTION_HEADER_TABLE.CLONE_TEMPLATE:
          this.saveTemplateDataDialogPopup = true;
          this.isCloneTemplate = true;
          break;
        case ACTION_HEADER_TABLE.REVIEWING_DATA:
          this.dialogApproval = true;
          if (this.dataPattern.workflow_data.is_approver === 1) {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name: '承認',
                description: '申請を承認します。',
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name: '申請差戻',
                description: '申請を却下し、申請者に差し戻します。',
              },
            ];
          } else {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name: '代理承認',
                description: '指定された承認者に代わって承認します。',
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name: '代理申請差戻',
                description: '指定された承認者に代わって申請を却下し、申請者に差し戻します。',
              },
            ];
          }
          break;
        case ACTION_HEADER_TABLE.CANCEL_SUBMITTED:
          this.dialogQuestion = true;
          this.messageQuestion = '承認申請を取り消します。よろしいですか？';
          this.confirmQuestionText = '申請を取り消す';
          break;
        case ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED:
          this.dialogAcceptCancel = true;
          this.listStatusCancel = [
            {
              id: CANCEL_TYPE.ACCEPT,
              name: '許可',
              description: '申請取消を許可します。',
            },
            {
              id: CANCEL_TYPE.REJECT,
              name: '却下',
              description: '申請取消を却下し、申請者に差し戻します。',
            },
          ];
          break;
        case ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA:
          this.validateDataTable();
          if (!this.isHasErrorData) {
            this.dialogQuestion = true;
            this.messageQuestion =
              '承認済みデータの変更内容を保存します。よろしいですか？\n保存した場合、管理者によって登録内容が変更された旨をデータを登録した申告者に通知します。';
            this.confirmQuestionText = 'يحفظ';
            this.closeText = 'يغلق'
          }
          break;
        default:
          break;
      }
    },
    validateDataTable() {
      this.dataTable.getError = this.getError;
      const itemsInView = this.pattern.cellRequireInView(this.itemsInView);
      let errorData = [];
      this.dataTable.sourceCollection.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexgrid.columns.forEach((column) => {
          errorData.push(this.getError(item, column.binding));
          return true;
        });
      });
      errorData = errorData.filter((item) => item !== null);
      if (errorData.length > 0) {
        this.isHasErrorData = true;
        this.isReloadPage = false;
        if (errorData.includes(MESSAGE_NOT_NULL) || errorData.includes(MESSAGE_INCORRECT_YEAR) || errorData.includes(MESSAGE_POSITIVE_NUMBER)) {
          this.dialogNotification = true;
          this.messageNotification = 'من بين بيانات الانبعاثات التي تحاول التسجيل / التقدم للحصول عليها ، هناك بيانات لم يتم إدخال عنصر مطلوب لها. يرجى التحقق من الصفوف المميزة وتصحيح بيانات الانبعاثات.';
        }
      } else {
        this.isHasErrorData = false;
      }
    },
    closeQuestionPopUp() {
      this.dialogQuestion = false
      this.isSaveTemplateButton = false;
    },

    submitAction() {
      if (this.confirmQuestionText === 'يمسح') {
        this.deleteAllDataHandler();
      } else if (this.confirmQuestionText === 'يحفظ') {
        if (
          (this.dataPattern.workflow_data?.id &&
            (this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_APPROVED ||
              this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED)) ||
          this.isGetAllData
        ) {
          this.updateApprovedDataHandler();
        } else {
          this.updateTemplateDataHandler();
        }
      } else if (this.confirmQuestionText === '申請を取り消す') {
        this.handleCancelSubmit();
      } else if (this.confirmQuestionText === 'عرض') {
        return this.$router.push({ path: '/emissions/list-templates' });
      } else if (this.confirmQuestionText === '登録') {
        this.saveDraftDataHandler();
      } else if (
        this.confirmQuestionText === 'اوكي' &&
        this.messageQuestion === 'تحتوي بيانات الانبعاثات التي تحاول التسجيل / التقدم للحصول عليها على نفس بيانات الانبعاثات التي تم حفظها بالفعل. تحقق من الصفوف المميزة وصحح بيانات الانبعاثات إذا لزم الأمر. إذا لم تكن هناك حاجة إلى تصحيحات ، فانقر فوق تسجيل / إرسال.' &&
        !this.isHasErrorData &&
        this.yearSelected !== null &&
        this.month !== null
      ) {
        this.dialogQuestion = false;
        this.isSubmitData = true;
        this.submitRegisterDataHandler();
      }
    },
    submitTemplateAction(templateTitle) {
      this.templateTitle = templateTitle;
      this.saveTemplateRegisterDataHandler();
    },
    updateApprovedDataHandler() {
      if (this.isGetAllData) {
        this.updateDataMonth(this.$store.state.registerData.month);
        this.updateYearSelected(this.yearSelected);
      } else {
        this.updateDataMonth(this.dataPattern?.workflow_data?.month);
        this.updateYearSelected(this.dataPattern?.workflow_data?.year);
      }
      const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView);
      if (updatedData.length) {
        updateListData(this.pattern.patternUrl, updatedData).then(() => {
          this.updateTotalEmissionsHandler();
          this.dialogQuestion = false;
        }).catch((errors) => {
          this.dialogQuestion = false;
          if (getErrorMessge(errors).length > 0) {
            this.dialogNotification = true;
            this.messageNotification = getErrorMessge(errors);
          }
        });
      }
    },
    saveDraftDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle,
        template_type: null,
        month: this.month,
        year: this.yearSelected,
      };
      this.updateDataMonth(parseInt(this.selectMonthYearChange));
      this.updateYearSelected(this.yearSelected);
      if (dataWorkflow.title.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      let payload = {};
      if (this.dataPattern.workflow_data?.id && this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_DRAFT) {
        // update draft
        const addedData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
          this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
        );
        const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView);
        const deletedData = this.removeDataRegisterOnTable(this.dataTable);
        payload = {
          data: {
            add: addedData,
            update: updatedData,
            delete: deletedData,
          },
          dataWorkflow: dataWorkflow,
        };
        updateTemplateData(payload).then(() => {
          this.updateIsEditData(false);
          this.isEditDataTable = false;
          this.dialogNotification = true;
          this.messageNotification = 'لقد قمت بحفظ التغييرات الخاصة بك';
        });
        return;
      }
      let draftData = this.$route.query?.import ? this.getDataImport() : this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
      );
      // clone draft from template
      if (
        this.dataPattern.workflow_data?.id &&
        this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
      ) {
        delete dataWorkflow.id;
        this.updateDataMonth(parseInt(this.selectMonthYearChange));
        this.updateYearSelected(this.yearSelected);
        dataWorkflow.month = parseInt(this.selectMonthYearChange);
        dataWorkflow.year = this.yearSelected;
        draftData = this.cloneDataHandler();
      } else if (
        this.dataPattern.workflow_data?.id &&
        (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
          this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_RETURN)
      ) {
        dataWorkflow.id = this.dataPattern.workflow_data?.id;
        draftData = this.cloneDataHandler();
      }
      payload = {
        action: 'saveDraft',
        data: draftData,
        dataWorkflow: dataWorkflow,
      };
      this.dialogQuestion = false;
      // Call API SUBMIT
      saveDraftAction(payload).then((res) => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        if (
          this.dataPattern?.workflow_data?.id &&
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE
        ) {
          this.messageNotification = '登録データとして、「排出量の登録」のデータ一覧に保存されました。';
          this.redirectUrl = '/emissions';
        }
        if (
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN
        ) {
          this.messageNotification =
            '修正内容が保存され、このデータのステータスが「申請差戻」から「登録中」に変更されました。';
        }
        if (!this.dataPattern?.workflow_data?.id) {
          this.messageNotification = 'لقد قمت بحفظ التغييرات الخاصة بك';
          this.redirectUrl = `/emissions/register/list-menu/${res?.latest_record?.workflow_data?.id}`;
        }
        this.dialogNotification = true;
      });
    },
    submitRegisterDataHandler(approvalFormData) {
      this.approvalForm = approvalFormData || this.approvalForm;
      let dataWorkflow = {
        ...this.registerWorkflow,
        ...this.approvalForm,
        month: this.month,
        year: this.yearSelected,
      };
      if (dataWorkflow.title.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      if (dataWorkflow?.comment?.length > 255) {
        dataWorkflow.comment = dataWorkflow.comment.substr(0, 255);
      }
      this.updateDataMonth(parseInt(this.selectMonthYearChange));
      this.updateYearSelected(this.yearSelected);
      let submitData = this.$route.query?.import ? this.getDataImport() : this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
      );

      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE) {
          delete dataWorkflow.id;
          this.updateDataMonth(parseInt(this.selectMonthYearChange));
          this.updateYearSelected(this.yearSelected);
          dataWorkflow.month = parseInt(this.selectMonthYearChange);
          dataWorkflow.year = this.yearSelected;
        }
        submitData = this.cloneDataHandler();
      }
      const payload = {
        action: ACTION_HEADER_TABLE.SUBMIT_DATA,
        data: submitData,
        dataWorkflow: dataWorkflow,
        check_duplicate: this.isSubmitData ? false : true,
      };
      // Call API SUBMIT
      this.submitDataDialogPopup = false;
      submitDataAction(payload)
        .then(() => {
          this.updateIsEditData(false);
          this.isEditDataTable = false;
          this.dialogNotification = true;
          this.redirectUrl = '/emissions/list-submitted';
          this.messageNotification =
            'لقد تلقينا طلبك للموافقة على بيانات التسجيل. سيتم إرسال نتائج المراجعة من قبل المعتمد إلى عنوان البريد الإلكتروني المسجل.';
          this.isSubmitData = false;
        })
        .catch((error) => {
          if (error.errors?.data_duplicate) {
            this.dialogQuestion = true;
            this.highlightDuplicatedRows(error.errors.data_duplicate);
            this.messageQuestion =
            'تحتوي بيانات الانبعاثات التي تحاول التسجيل / التقدم للحصول عليها على نفس بيانات الانبعاثات التي تم حفظها بالفعل. تحقق من الصفوف المميزة وصحح بيانات الانبعاثات إذا لزم الأمر. إذا لم تكن هناك حاجة إلى تصحيحات ، فانقر فوق تسجيل / إرسال.';
            this.confirmQuestionText = 'اوكي';
          }
          if (!error.errors?.data_duplicate && getErrorMessge(error).length > 0) {
            this.isReloadPage = false;
            this.dialogNotification = true;
            this.messageNotification = getErrorMessge(error);
          }
        });
    },
    saveTemplateRegisterDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        category: this.registerWorkflow.scope !== 3 ? null : this.registerWorkflow.category,
        title: this.templateTitle || this.dataTitle || sessionStorage.getItem('title'),
        template_type: 0,
        month: this.month || 1,
        year: this.yearSelected,
      };
      if (dataWorkflow.title.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      let payload = {
        action: 'saveTemplate',
        data: null,
        dataWorkflow: dataWorkflow,
      };
      // handle for clone template
      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
          delete dataWorkflow.id;
          this.updateDataMonth(parseInt(this.dataPattern.workflow_data.month));
          this.updateYearSelected(this.dataPattern.workflow_data.year);
          dataWorkflow.month = parseInt(this.dataPattern.workflow_data.month);
          dataWorkflow.year = this.dataPattern.workflow_data.year;
        }
        payload.data = this.cloneDataHandler(true);
      } else {
        if(this.$route.query?.import) {
          payload.data = this.getDataImport(true)
        } else {
          payload.data = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(
          this.addDataRegisterOnTable(this.dataTable, this.itemsInView, true),
        );
        }
      }
      // Call API SUBMIT
      this.addRegisterDataWorkFollow(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.isSaveTemplateButton = true;
        this.dialogQuestion = true;
        if (this.isCloneTemplate) {
          this.messageQuestion = '新規テンプレートとして保存しました。テンプレート一覧を表示しますか？';
        } else {
          this.messageQuestion = 'تم الحفظ كنموذج. إظهار قائمة القوالب؟';
        }
        this.confirmQuestionText = 'عرض';
        this.dialogNotification = false;
      });
    },
    handleCancelSubmit() {
      this.dialogQuestion = false;
      const payload = {
        action: ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataPattern.workflow_data.id,
        },
      };
      cancelSubmittedData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogNotification = true;
        this.redirectUrl = '/emissions';
        this.messageNotification = `承認申請の取り消しをリクエストしました。
承認者が許可しましたら登録メールアドレスに通知されます。`;
      })
      .catch((error) => {
        this.dialogQuestion = false;
        this.dialogNotification = true;
        this.redirectUrl = '/emissions';
        this.messageNotification = error.errors[0][0]
      });
    },
    handleAcceptRequestCancelSubmit(acceptCancelFormData) {
      let is_allow_cancel = null;
      if (acceptCancelFormData.status === CANCEL_TYPE.ACCEPT) {
        is_allow_cancel = CANCEL_TYPE.ACCEPT;
      } else {
        is_allow_cancel = CANCEL_TYPE.REJECT;
      }
      const payload = {
        action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataPattern.workflow_data.id,
          comment: acceptCancelFormData?.comment?.length > 255 ? acceptCancelFormData.comment.substr(0, 255) : acceptCancelFormData.comment,
        },
        is_allow_cancel: is_allow_cancel
      }

      cancelSubmittedData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogAcceptCancel = false;
        this.messageNotification = acceptCancelFormData.status === CANCEL_TYPE.ACCEPT ? '申請取消を許可しました。' : '申請取消を却下しました。';
        this.dialogNotification = true;
        this.redirectUrl = '/approval'
      }).catch((error) => {
        this.dialogAcceptCancel = false;
        this.dialogNotification = true;
        this.redirectUrl = '/approval';
        this.messageNotification = error.errors[0][0]
      });

    },
    updateTemplateDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      this.dialogQuestion = false;
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle || this.dataPattern.workflow_data.title,
        template_type: 0,
        month: this.dataPattern?.workflow_data?.month,
        year: this.dataPattern?.workflow_data?.year,
      };
      if (dataWorkflow.title.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      this.updateDataMonth(parseInt(this.dataPattern?.workflow_data?.month));
      this.updateYearSelected(this.dataPattern?.workflow_data?.year);
      dataWorkflow.month = parseInt(this.dataPattern?.workflow_data?.month);
      dataWorkflow.year = this.dataPattern?.workflow_data?.year;
      const addedData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView, true),
      );
      const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView, true);
      const deletedData = this.removeDataRegisterOnTable(this.dataTable);
      const payload = {
        data: {
          add: addedData,
          update: updatedData,
          delete: deletedData,
        },
        dataWorkflow: dataWorkflow,
      };

      // return;
      // Call API SUBMIT
      updateTemplateData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogNotification = true;
        this.messageNotification = 'テンプレートに変更内容を保存しました。';
      });
    },
    deleteAllDataHandler() {
      this.updateIsEditData(false);
      this.isEditDataTable = false;
      if (this.dataPattern.workflow_data) {
        deleteTemplate({
          workflow_id: this.dataPattern.workflow_data.id,
        }).then(() => {
          if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
            return this.$router.push({ path: '/emissions/list-templates' });
          }
          return this.$router.push({ path: '/emissions' });
        });
      } else {
        this.totalEmissions = 0;
        this.dataTable.sourceCollection = [];
        this.listDataPattern = [];
        this.pattern.addBlankItemsToView(this.dataTable, 10);
        this.checkTableHasData()
      }
      this.dialogQuestion = false;
    },
    approvalDataHandler(approvalFormData) {
      let status = null;
      if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
        status =
          this.dataPattern.workflow_data.is_approver === 1
            ? APPROVAL_STATUS.APPROVER_APPROVAL
            : APPROVAL_STATUS.APPROVAL;
      } else {
        status =
          this.dataPattern.workflow_data.is_approver === 1 ? APPROVAL_STATUS.APPROVER_RETURN : APPROVAL_STATUS.RETURN;
      }
      const payload = {
        id: this.dataPattern.workflow_data.id,
        status: status,
        comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
      };
      // call API to submit data
      this.updateIsEditData(false);
      this.isEditDataTable = false;
      approvalDataAction(payload)
        .then(() => {
          this.dialogApproval = false;
          if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
            this.messageNotification =
              this.dataPattern.workflow_data.is_approver === 1 ? '申請を承認しました。' : '申請を代理承認しました。';
          } else {
            this.messageNotification =
              this.dataPattern.workflow_data.is_approver === 1
                ? '申請を差し戻しました。'
                : '申請を代理で差し戻しました。';
          }
          this.redirectUrl = '/approval';
          this.dialogNotification = true;
        })
        .catch((error) => {
          this.dialogApproval = false;
          this.dialogNotification = true;
          this.redirectUrl = '/approval';
          this.messageNotification = error.errors[0][0]
        });
    },
    closeDialogSubmitData() {
      this.submitDataDialogPopup = false;
    },
    closeDialogSaveTemplate() {
      this.saveTemplateDataDialogPopup = false;
    },
    closeDialogWarningSaveTemplate() {
      this.warningTemplateDataDialogPopup = false;
    },
    handleActionAfterScopeIdFetched() {
      this.checkScopeToConvert();
      if (this.isConvert) {
        this.listNumberItems.push('wsu_source', 'wsu_value');
      }
    },
    checkScopeToConvert() {
      if (this.scope === SCOPE.SCOPE_3) {
        switch (this.category) {
          case 8:
          case 9:
          case 10:
          case 11:
          case 13:
          case 14:
          case 15:
            this.isConvert = true;
            break;
          case 16:
            this.isConvert = true;
            break;
          default:
            this.isConvert = false;
            break;
        }
      }
    },
    getHeaderFromScope() {
      // set method for get data by workflow id
      if(this.dataPattern.workflow_data?.id) {
        const category = this.dataPattern.workflow_data.category;
        const patternId = this.dataPattern.workflow_data.pattern_id;

        if(!['4', '9', '11']?.includes(category)) {
          this.method = this.dataPattern.workflow_data.pattern_id
        } else if(category === '4') {
          switch (patternId) {
            case 4:
              this.method = 1;
              this.methodLayer2 = 4;
              break;
            case 5:
              this.method = 1;
              this.methodLayer2 = 5;
              break;
            case 6:
              this.method = 1;
              this.methodLayer2 = 6;
              break;
            case 7:
              this.method = 2;
              this.methodLayer2 = 7;
              break;
            case 8:
              this.method = 2;
              this.methodLayer2 = 8;
              break;
            case 2:
              this.method = 3;
              this.methodLayer2 = null;
              break;
            default:
              break;
          }
        } else if(category === '9') {
          this.methodLayer2 = patternId;
          this.method = [1, 2, 3].includes(patternId) ? 1 : 2 
        } else if(category === '11') {
          if(patternId === 4) {
            this.method = 2;
            this.methodLayer2 = null;
          } else {
            this.methodLayer2 = patternId;
            this.method = 1;
          }
        }
      }
      this.pattern = null;
      if(this.dataPattern.workflow_data?.id) {
        const category = this.dataPattern.workflow_data.category;
        const patternId = this.dataPattern.workflow_data.pattern_id;

        if(!['4', '9', '11']?.includes(category)) {
          this.method = this.dataPattern.workflow_data.pattern_id
        } else if(category === '4') {
          switch (patternId) {
            case 4:
              this.method = 1;
              this.methodLayer2 = 4;
              break;
            case 5:
              this.method = 1;
              this.methodLayer2 = 5;
              break;
            case 6:
              this.method = 1;
              this.methodLayer2 = 6;
              break;
            case 7:
              this.method = 2;
              this.methodLayer2 = 7;
              break;
            case 8:
              this.method = 2;
              this.methodLayer2 = 8;
              break;
            case 2:
              this.method = 3;
              this.methodLayer2 = null;
              break;
            default:
              break;
          }
        } else if(category === '9') {
          this.methodLayer2 = patternId;
        } else if(category === '11') {
          if(patternId === 4) {
            this.method = 2;
            this.methodLayer2 = null;
          } else {
            this.methodLayer2 = patternId;
            this.method = 1;
          }
        }
      }
      
      switch (this.scope) {
        case 1:
          if (this.method === 1) {
            this.pattern = partern1;
          } else if (this.method === 2) {
            this.pattern = partern2;
          } else {
            this.pattern = partern3;
          }
          break;
        case 2:
          this.pattern = partern4;
          break;
        case 3:
          switch (this.category) {
            case 1:
              this.pattern = partern5;
              break;
            case 2:
              this.pattern = partern6;
              break;
            case 3:
              this.pattern = partern7;
              break;
            case 4:
              if (this.method === 3) {
                this.pattern = partern9method3;
              } else if (this.method === 2 && this.methodLayer2 === 7) {
                this.pattern = partern9method1;
              } else if (this.method === 2 && this.methodLayer2 === 8) {
                this.pattern = partern9method2;
              } else if (this.method === 1 && this.methodLayer2 === 4) {
                this.pattern = partern8method1;
              } else if (this.method === 1 && this.methodLayer2 === 5) {
                this.pattern = partern8method2;
              } else if (this.method === 1 && this.methodLayer2 === 6) {
                this.pattern = partern8method3;
              } else {
                this.pattern = partern9method1;
              }
              break;
            case 5:
              this.pattern = partern11;
              break;
            case 6:
              this.pattern = partern12;
              switch (this.method) {
                case 1:
                  this.pattern = partern12method1;
                  break;
                case 2:
                  this.pattern = partern12method2;
                  break;
                case 3:
                  this.pattern = partern12method3;
                  break;
                case 4:
                  this.pattern = partern12method4;
                  break;
                case 5:
                  this.pattern = partern12method5;
                  break;
                case 6:
                  this.pattern = partern12method6;
                  break;
                case 7:
                  this.pattern = partern12method7;
                  break;
                default:
                  this.pattern = partern12method1;
                  break;
              }
              break;
            case 7:
              if (this.method === 1) {
                this.pattern = partern14Method3;
                break;
              }
              if (this.method === 2) {
                this.pattern = partern14Method4;
                break;
              }
              if (this.method === 3) {
                this.pattern = partern14Method5;
                break;
              }
              if (this.method === 4) {
                this.pattern = partern13;
                break;
              }
              if (this.method === 5) {
                this.pattern = partern14;
                break;
              }
              break;
            case 8:
              this.pattern = partern15;
              break;
            case 9:
              if (this.methodLayer2 === 1) {
                this.pattern = partern16;
              } else if (this.methodLayer2 === 2) {
                this.pattern = partern16method2;
              } else if (this.methodLayer2 === 3) {
                this.pattern = partern16method3;
              } else if (this.methodLayer2 === 4) {
                this.pattern = partern16method4;
              } else if (this.methodLayer2 === 5) {
                this.pattern = partern16method5;
              }
              break;
            case 10:
              this.pattern = partern17;
              break;
            case 11:
              this.pattern = partern18;
              if (this.method === 2) {
                this.pattern = partern18method4;
              } else if (this.method === 1 && this.methodLayer2 === 1) {
                this.pattern = partern18method1;
              } else if (this.method === 1 && this.methodLayer2 === 2) {
                this.pattern = partern18method2;
              } else if (this.method === 1 && this.methodLayer2 === 3) {
                this.pattern = partern18method3;
              }
              break;
            case 12:
              this.pattern = partern19;
              break;
            case 13:
              this.pattern = partern20;
              break;
            case 14:
              this.pattern = partern21;
              break;
            case 15:
              if (this.method === 1) {
                this.pattern = partern22;
                break;
              }
              if (this.method === 2) {
                this.pattern = partern23;
                break;
              }
              if (this.method === 3) {
                this.pattern = partern24;
                break;
              }
              if (this.method === 4) {
                this.pattern = partern25;
                break;
              }
              break;
            case 16:
              this.pattern = partern26;
              break;
            default:
              this.header = partern5.header;
              return partern5.initializeGrid;
          }
          break;
        case 4:
          this.pattern = partern27;
          break;
        default:
          this.pattern = partern25;
          break;
      }
    },
    handleBindDbCustomize(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        if (parseInt(this.scope) > SCOPE.SCOPE_2) {
          item.wsu_source = item?.db_customize?.source;
          item.db_customize_id_1 = item?.db_customize1?.source;
          if (this.category === CATEGORY.CATEGORY_15) {
            item.db_id = item?.db_customize?.source;
          }
        } else {
          item.wsu_source = item?.db_customize?.item_name;
        }

        if (parseInt(this.category) === CATEGORY.CATEGORY_16) {
          item.db_customize_id = item?.db_customize?.source;
        }

        if (parseInt(this.category) === CATEGORY.CATEGORY_4 && this.method === METHOD_3) {
          item.db_customize_id_1 = item?.db_customize1?.source;
        }
      }
    },
    handleBindDbCustomize1(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        item.db_customize_id_1 = item?.db_customize1?.source;
      }
    },
    getDataFromScope() {
      this.listDataPattern = [];
      this.dataTable = null;
      this.lastedRow = null;
      this.$store.dispatch('registerData/updateDataList', []);
      this.actionUpdateIsClearUndoStack(false);
      this.timeoutUndoStack = false;
      this.actionUpdateStatusBtn({ undo: false, redo: false });
      this.updateUndoRedoClick('');
      if (this.scope === 3 && this.category === 4 && this.method === 1 && this.methodLayer2 === null) return;
      this.showListDataPattern();
    },
    showListDataPattern() {
      this.convertDataOnTable();

      this.$store.dispatch('registerData/updateTotalEmission', this.dataPattern.total_emissions);
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.dataPattern.total_duration_emissions);
      // this.$store.dispatch('registerData/updateLatestRecord', this.dataPattern.latest_record);
      if(this.$route.query?.import) {
        return
      }
      this.renderDataPatternOnTable();
    },
    convertDataOnTable() {
      const layerList = {};
      const layerResponse = this.dataPattern?.layer || this.layerData;
      this.itemsInView = [];
      const layers = layerResponse.map((item) => {
        let listBranch = this.$store.state.registerData.listBranch;
        this.itemsInView.push(this.layerIndex[item.layer_index]);
        layerList[this.layerIndex[item.layer_index]] = item.layer_name;
        return {
          header: item.layer_name,
          visible: true,
          binding: this.layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: 90,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          dataMap: new wjGrid.DataMap(listBranch[this.layerIndex[item.layer_index]].filter(item => item.status === true), 'key', 'value'),
          editor: new AutoComplete(document.createElement('div'), {
            itemsSource: listBranch[this.layerIndex[item.layer_index]].filter(item => item.status === true),
            selectedValuePath: 'key',
            displayMemberPath: 'value',
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1,
            dropDownCssClass: this.scope === 1 ? 'right-to-left' : ''
          }),
        };
      });
      this.layer = layers || [];

      store.dispatch('registerData/actionUpdateLayerFilter', layerList, { root: true });
      // this.updateOrderNumberDontHavePermissions(res.order_number_not_permissions)
      const listType = this.$store.state.registerData.listType.map((item) => item.key);
      const companyList = this.$store.state.registerData.listBranch?.company_name.map((item) => item.value);
      const businessList = this.$store.state.registerData.listBranch?.business_name.map((item) => item.value);
      const countryList = this.$store.state.registerData.listBranch?.country.map((item) => item.value);
      const layer3List = this.$store.state.registerData.listBranch?.layer_3.map((item) => item.value);
      const layer4List = this.$store.state.registerData.listBranch?.layer_4.map((item) => item.value);
      const layer5List = this.$store.state.registerData.listBranch?.layer_5.map((item) => item.value);
      const layer6List = this.$store.state.registerData.listBranch?.layer_6.map((item) => item.value);

      this.dataPattern.data?.forEach((itemDataPattern) => {
        let filterData = {
          organizational_division: itemDataPattern?.organizational_division,
          company_name: itemDataPattern?.company_name,
          business_name: itemDataPattern?.business_name,
          country: itemDataPattern?.country,
          layer_3: itemDataPattern?.layer_3,
          layer_4: itemDataPattern?.layer_4,
          layer_5: itemDataPattern?.layer_5,
          layer_6: itemDataPattern?.layer_6,
        };
        if (typeof itemDataPattern?.branch === 'object' && itemDataPattern?.branch !== null) {
          filterData = {
            organizational_division: itemDataPattern?.branch?.organizational_division,
            company_name: itemDataPattern?.branch?.company_name,
            business_name: itemDataPattern?.branch?.business_name,
            country: itemDataPattern?.branch?.country,
            layer_3: itemDataPattern?.branch?.layer_3,
            layer_4: itemDataPattern?.branch?.layer_4,
            layer_5: itemDataPattern?.branch?.layer_5,
            layer_6: itemDataPattern?.branch?.layer_6,
          };
        }
        let newItemDataPattern = {
          ...itemDataPattern,
          organizational_division: listType?.includes(filterData.organizational_division)
            ? filterData.organizational_division
            : null,
          company_name: companyList?.includes(filterData.company_name) ? filterData.company_name : null,
          business_name: businessList?.includes(filterData.business_name) ? filterData.business_name : null,
          country: countryList?.includes(filterData.country) ? filterData.country : null,
          layer_3: layer3List?.includes(filterData.layer_3) ? filterData.layer_3 : null,
          layer_4: layer4List?.includes(filterData.layer_4) ? filterData.layer_4 : null,
          layer_5: layer5List?.includes(filterData.layer_5) ? filterData.layer_5 : null,
          layer_6: layer6List?.includes(filterData.layer_6) ? filterData.layer_6 : null,
        };
        const organizationList = this.$store.state.registerData.listType;
        const indexOrganization = organizationList.findIndex(orgi => orgi.key === newItemDataPattern.organizational_division)
        if (indexOrganization >= 0) {
          newItemDataPattern.organizational_division = organizationList[indexOrganization].status === false ? organizationList[indexOrganization].value : parseInt(newItemDataPattern.organizational_division);
        }
        this.listNumberItems.forEach((itemDataPattern) => {
          if (itemDataPattern in newItemDataPattern) {
            newItemDataPattern[itemDataPattern] =
              newItemDataPattern[itemDataPattern] === null
                ? null
                : newItemDataPattern[itemDataPattern].toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
          }
        });
        newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : newItemDataPattern.emissions.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';

        const emission20Number = formatValue(newItemDataPattern.emissions)?.includes('-') ? 21 : 20;
        newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : formatValue(newItemDataPattern.emissions)?.substring(0, emission20Number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
        // newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : formatValue(newItemDataPattern.emissions)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
        newItemDataPattern.type_1 = itemDataPattern.type_1 ? 1 : 0; // type = 1 if you use master_db
        newItemDataPattern.type = itemDataPattern.type ? 1 : 0;
        newItemDataPattern.fuel= this.convertFuelOption(newItemDataPattern.fuel)
        newItemDataPattern['ie_year'] = formatValue(newItemDataPattern['ie_year']);
        if (itemDataPattern?.db_customize) {
          if (this.scope >= 3 && this.category < 16) {
            newItemDataPattern.wsu_source = Number(itemDataPattern?.db_customize?.id?.toString().split(',').join(''));
          } else {
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source?.toString().split(',').join('');
          }
          newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
          newItemDataPattern.wsu_value = Number(itemDataPattern?.db_customize?.value_source);
          newItemDataPattern.unit = itemDataPattern?.db_customize?.unit;

          newItemDataPattern.wsu_item_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_customize?.unit;

          // s1m1
          if (this.scope === 1 || this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
          }

          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }

          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }

          if (this.scope === 1 && this.method === 2) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }
          if (this.scope === 1 && this.method === 3) {
            newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
          }

          // s3c2 cpa_unit = unit
          if (this.scope === 3 && this.category === 2) {
            newItemDataPattern.cpa_unit = itemDataPattern?.db_customize?.unit;
          }

          // s3c3 energy_unit = unit,  energy_type = item_name
          if (this.scope === 3 && this.category === 3) {
            newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
          }

          // s3c4m1 qd_unit = unit
          if (this.scope === SCOPE.SCOPE_3 && this.category === 4) {
            newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
            // for m3
            newItemDataPattern.value_source1 = Number(itemDataPattern?.db_customize?.value_source);
            newItemDataPattern.unit_source1 = itemDataPattern?.db_customize?.unit_source;
            if (this.methodLayer2 === 4) {
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.methodLayer2 === 4) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            }
            if (this.methodLayer2 === 6) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }

            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 5) {
            newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 6) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.travel_unit = itemDataPattern?.db_customize?.unit;
            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.method === 3) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === CATEGORY.CATEGORY_7) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.te_unit = itemDataPattern?.db_customize?.unit;
            if (this.method === 2) {
              // newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.method === 1) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
            }

            if (this.method === 3) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 8) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.iss_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 9) {
            newItemDataPattern.activity_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
            if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.methodLayer2 === 3) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === 3 && this.category === 10) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === 3 && this.category === 11) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
          }

          if (this.scope === 3 && this.category === 12) {
            newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
          }

          if (this.scope === 3 && this.category === 13) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === 3 && this.category === 14) {
            newItemDataPattern.activity = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
          }

          // s3c1
          newItemDataPattern.supplier_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
          newItemDataPattern.wsu_value = itemDataPattern?.db_customize?.value_source;
          newItemDataPattern.db_id = itemDataPattern?.db_customize_id;

          //pt_s3c9
          newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
          this.handleBindDbCustomize(newItemDataPattern, itemDataPattern?.db_customize?.id);

          if (this.scope === 3 && this.category === 15) {
            newItemDataPattern.ie_company_unit = itemDataPattern?.db_customize?.unit;
            if (!this.idCustomizes.includes(newItemDataPattern.db_customize_id)) {
              newItemDataPattern.db_id = itemDataPattern?.db_customize?.source;
            }
          }
        }

        // handle for db master
        if (itemDataPattern?.db_master) {
          if (this.scope >= 3) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
            if (
              (this.category === 4 && this.methodLayer2 === 4) ||
              (this.category === 9 && (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5))
            ) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              if (this.methodLayer2 === 4) {
                newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              }
            }
          } else {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
          }

          newItemDataPattern.wsu_unit = itemDataPattern?.db_master?.unit_source;
          newItemDataPattern.wsu_value = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
          newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_master?.unit;
          newItemDataPattern.wsu_item_name = itemDataPattern?.db_master?.item_name;
          newItemDataPattern.unit = itemDataPattern?.db_master?.unit;
          if (itemDataPattern?.db_master_id) {
            newItemDataPattern.value_source1 = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
            newItemDataPattern.unit_source1 = itemDataPattern?.db_master?.unit_source;
            newItemDataPattern.db_id = itemDataPattern?.db_master_id;
          }

          if (this.scope === 1 || this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
          }
          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }
          if (this.scope === 1 && this.method === 2) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }
          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 1 && this.method === 3) {
            newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }
          // s3c4
          if (this.scope === 3 && this.category === 4) {
            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 1 && this.methodLayer2 === 6) {
                newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
                newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              }
          }
          if (this.scope === 3 && this.category === 6) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.travel_unit = itemDataPattern?.db_master?.unit;
            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 3) {
                newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
                newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              }
          }
          // s3c7
          if (this.scope === 3 && this.category === 7) {
            if (this.method === 2) {
              // newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 1) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
            }
            if (this.method === 3) {
              newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
          }

          if (this.scope === 3 && this.category === 9) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
            if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            }
            if (this.methodLayer2 === 3) {
              newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
          }
          if (this.scope === 3 && this.category === 10) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_master?.unit;
          }
          if (this.scope === 3 && this.category === 11) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
          }
        } else {
          delete itemDataPattern.db_master_id;
          delete newItemDataPattern.db_master_id;
        }

        if (itemDataPattern?.db_customize1) {
          // for pattern10
          newItemDataPattern.value_source2 = parseFloat(itemDataPattern?.db_customize1?.value_source);
          newItemDataPattern.unit_source2 = itemDataPattern?.db_customize1?.unit_source;
          this.handleBindDbCustomize1(newItemDataPattern, itemDataPattern?.db_customize1?.id);
        }

        if (itemDataPattern?.db_master1) {
          // for pattern10
          newItemDataPattern.value_source2 = this.formatValueSourceNumber(itemDataPattern?.db_master1?.value_source);
          newItemDataPattern.unit_source2 = itemDataPattern?.db_master1?.unit_source;
          newItemDataPattern.db_customize_id_1 = itemDataPattern?.db_master_id_1;
        }

        if (this.category === 16) {
          newItemDataPattern.wsu_source = itemDataPattern?.wsu_source;
          newItemDataPattern.wsu_unit = itemDataPattern?.wsu_unit;
          newItemDataPattern.wsu_value = Number(itemDataPattern?.wsu_value);
        }

        // convert wsu_source to number
        if (this.category === 2) {
          newItemDataPattern[itemDataPattern] = parseInt(newItemDataPattern[itemDataPattern]);
        }

        // newItemDataPattern.emissions = Number(itemDataPattern.emissions);
        newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
        if (this.scope === 1 && this.method === 2) {
          newItemDataPattern.wsu_source = itemDataPattern.db_customize_id || itemDataPattern.db_master_id;
        }

        // save by cell s3c1
        if (this.scope === 3 && this.category === 1) {
          newItemDataPattern.emissions = newItemDataPattern.emissions === null ? 'N/A' : newItemDataPattern.emissions;
        }
        // update format date for s4
        if (this.scope === 4 && this.category === 1) {
          if(this.dataPattern?.workflow_data?.status !== STATUS_FIELD.STATUS_DRAFT) {
            newItemDataPattern.dir_date = itemDataPattern.dir_date ? moment(new Date(itemDataPattern.dir_date)).format('YYYY/M/D') : ''
          }
        }
        if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE) {
          // reset emissions in template detail
          newItemDataPattern.emissions = null;
        }
        this.listDataPattern.push(newItemDataPattern);
      });
    },
    async renderDataPatternOnTable() {
      const uniqueIds = new Set();
      // case: don't use db
      if (this.category >= 14 || this.scope === 4) {
        setTimeout(() => {
          if(this.$route.query?.import === 'true') {
            const branchList = JSON.parse(JSON.stringify(this.$store.state.registerData));
            const header = this.pattern.header(branchList, this.layer);
            this.handleCsvNew(header)
          }
          this.originalData = this.listDataPattern.filter((element) => {
            const isDuplicate = uniqueIds.has(element.id) && element.id;
            uniqueIds.add(element.id);
            return !isDuplicate;
          });

          if(this.lengthCsv > 0) {
            this.isShowPopupCsv = true
            this.messagePopupCsv = `${this.lengthCsv}件のデータを取り込みました。`
            if(this.itemErrorCount > 0) {
              this.messagePopupCsv += `\n\n${this.itemErrorCount}件のデータに不具合があったため、取り込みができませんでした。`
            }
          }
          
          this.initialView();
          this.$nextTick(() => {
            this.scrollToTop();
            this.timeoutUndoStack = true;
          });
          return;
        }, 300);
        return
      }
      const branchList = JSON.parse(JSON.stringify(this.$store.state.registerData));
      const header = this.pattern.header(branchList, this.layer);

      await this.getMasterDb();
      if(this.$route.query?.import) {
        this.handleCsvNew(header)
      }
      if(!this.$route.query?.import) {
        this.originalData = this.listDataPattern?.filter((element) => {
        // if(this.$route.query?.import) return true
        if (this.scope === 3 && this.category === 13) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 1 && this.method === 1) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 1 && this.method === 2) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }


        if (this.scope === 1 && this.method === 3) {
          const newID = makeNewId(element.fuel, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.fuel = element.db_customize?.item_name;
          } else {
            element.fuel = newID;
          }
        }

        if (this.scope === 2 && this.method === 1) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 1) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 2) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 3) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 1 && this.methodLayer2 === 4) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 1 && this.methodLayer2 === 5) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 1 && this.methodLayer2 === 6) {
          const newID = makeNewId(element.fuel, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.fuel = element.db_customize?.item_name;
          } else {
            element.fuel = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 2 && this.methodLayer2 === 7) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 2 && this.methodLayer2 === 8) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 4 && this.method === 3) {
          const newID = makeNewId(element.db_id, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.db_id = element.db_customize?.source;
          } else {
            element.db_id = newID;
          }

          const newID1 = makeNewId(element.db_customize_id_1, element.type_1);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID1]) {
            element.db_customize_id_1 = element.db_customize1?.source;
          } else {
            element.db_customize_id_1 = newID1;
          }
        }

        if (this.scope === 3 && this.category === 5) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 1) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 2) {
          const newID = makeNewId(element.energy_type, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 3) {
          const newID = makeNewId(element.fuel, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.fuel = element.db_customize?.item_name;
          } else {
            element.fuel = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 4) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 5) {
          const newID = makeNewId(element.wsu_source, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 6) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 6 && this.method === 7) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 7 && this.method === 1) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 7 && this.method === 2) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 7 && this.method === 3) {
          const newID = makeNewId(element.fuel, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.fuel = element.db_customize?.source;
          } else {
            element.fuel = newID;
          }
        }


        if (this.scope === 3 && this.category === 7 && this.method === 4) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 7 && this.method === 5) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 8) {
          const newID = makeNewId(element.wsu_source, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 9 && this.method === 1 && this.methodLayer2 === 1) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 9 && this.method === 1 && this.methodLayer2 === 2) {
          const newID = makeNewId(element.wsu_source, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }
        if (this.scope === 3 && this.category === 9 && this.method === 1 && this.methodLayer2 === 3) {
          const newID = makeNewId(element.fuel, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.fuel = element.db_customize?.item_name;
          } else {
            element.fuel = newID;
          }
        }
        if (this.scope === 3 && this.category === 9 && this.method === 2 && this.methodLayer2 === 4) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 9 && this.method === 2 && this.methodLayer2 === 5) {
          const newID = makeNewId(element.energy_type, element.type);

          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.energy_type = element.db_customize?.source;
          } else {
            element.energy_type = newID;
          }
        }

        if (this.scope === 3 && this.category === 10) {
          const newID = makeNewId(element.wsu_source, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 11) {
          const newID = makeNewId(element.wsu_source, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        if (this.scope === 3 && this.category === 12) {
          const newID = makeNewId(element.wsu_source, element.type);
          if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
            element.wsu_source = element.db_customize?.source;
          } else {
            element.wsu_source = newID;
          }
        }

        const isDuplicate = uniqueIds.has(element.id);

        uniqueIds.add(element.id);

        return !isDuplicate;
      });
      } else {
        this.originalData = this.listDataPattern
        if(this.lengthCsv > 0) {
          this.isShowPopupCsv = true
          this.messagePopupCsv = `${this.lengthCsv}件のデータを取り込みました。`
          if(this.itemErrorCount > 0) {
            this.messagePopupCsv += `\n\n${this.itemErrorCount}件のデータに不具合があったため、取り込みができませんでした。`
          }
        }
      }


      this.initialView();
      this.$nextTick(() => {
        this.scrollToTop();
        this.timeoutUndoStack = true;
      });
    },
    handleCsvNew(header) {
      const data = sessionStorage.getItem('csvImportEvent');
      this.lengthCsv = 0
      this.itemErrorCount = 0
      if(!data) return 
      let branchList = JSON.parse(JSON.stringify(this.$store.state.registerData)); 
      // branchList.listType = branchList.listType.filter(item => item.status === true);
      // branchList.listBranch.company_name = branchList.listBranch.company_name.filter(item => item.status === true);
      const { csvData, error, itemErrorCount } = convertCsvToJsonNew({
        csvData: data,
        header,
        scope: this.scope, 
        category: this.category,
        numberItem: this.listNumberItems,
        calcEmissions: this.pattern.calcEmissions,
        listBranchObj: branchList.listBranchObj,
        method: this.method,
        methodLayer2: this.methodLayer2
        // listBranch: branchList.listBranch, 
      });
      this.itemErrorCount = itemErrorCount

      if(error.length > 0) {
        const query = this.$route.query;
        this.$router.push({
          path: '/emissions/list-menu',
          query: {
            isErrorCsv: true,
            csv: true,
            title: query.title,
            scope_select: query.scope_select,
            methods: query.methods,
            methods2: query.methods2,
            month: query.month,
            categoryName: query.categoryName
          }
        })
        return
      }
      this.listDataPattern = [ ...csvData || [] ];
      this.lengthCsv = csvData.length;
      sessionStorage.removeItem('csvImportEvent');
      return csvData
    },
    handleCsvImportEvent(data) {
      const jsonData = convertCsvToJson(data, this.flexgrid);
      this.listDataPattern = jsonData;
      // sessionStorage.removeItem('csvImportEvent');
    },
    validateNumberField(input) {
      if (input) {
        let numberData = input.replace(/[^\d.]/g, '');
        if (Number(input) < 0) {
          return '正の値で入力してください。';
        }
        if (numberData && !(input.length - numberData.length)) {
          return null;
        } else {
          return '数字で入力してください。';
        }
      } else {
        return 'هذا إلزامي.';
      }
    },
    getError(item, propName, parsing) {
      if (
        this.scope < 3 ||
        this.scope === 4 ||
        (this.scope === 3 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(this.category))
      ) {
        // validate row if has data
        const itemsInView = this.pattern.cellInputInView(this.itemsInView);
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return null;
        }
        return this.pattern?.getError(item, propName);
      } else {
        // for s1m2
        if (
          (propName === 'target_model' || propName === 'loading_data_maximum' || propName === 'loading_data_rate') &&
          this.scope === 1 &&
          this.method === 2
        ) {
          if (item[propName] === null || item[propName] === '') {
            return 'هذا إلزامي.';
          }
        }

        // for s1m3
        if ((propName === 'distance' || propName === 'fuel_efficiency') && this.scope === 1 && this.method === 3) {
          if (item[propName] === null || item[propName] === '') {
            return 'هذا إلزامي.';
          }
        }

        if (propName === 'fuel') {
          if (!item[propName]) {
            return 'هذا إلزامي.';
          }
        }

        // for s3c6
        if (propName === 'transportation_facilities' && this.scope === 3 && this.category === 6) {
          if (!item[propName]) {
            return 'هذا إلزامي.';
          }
        }

        // for s3c7
        const itemReqinS3c7 = [
          'employees_number_value',
          'working_days_number_value',
          'transportation',
          'distance',
          'passengers',
          'value',
          'fuel_efficiency',
          'nights',
          'business_trip_days',
        ];
        if (itemReqinS3c7.includes(propName) && this.scope === 3 && (this.category === 7 || this.category === 6)) {
          if (!item[propName] && item[propName] !== 0) {
            return 'هذا إلزامي.';
          }
        }

        // for s3c9
        if (propName === 'value' && this.scope === 3 && this.category === 9) {
          if (item[propName] === null || item[propName] === '') {
            return 'هذا إلزامي.';
          }
        }
        if (
          (propName === 'distance' ||
            propName === 'loading_data_maximum' ||
            propName === 'loading_data_rate' ||
            propName === 'fuel_efficiency') &&
          this.scope === 3 &&
          this.category === 9
        ) {
          if (item[propName] === null || item[propName] === '') {
            return 'هذا إلزامي.';
          }
        }

        // for s3c14
        if (propName === 'scale_index_source' && this.scope === 3 && this.category === 14) {
          if (item[propName] === null || item[propName] === '') {
            return 'هذا إلزامي.';
          }
        }
        if (propName === 'emissions' && this.scope === 3 && this.category === 14) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return 'هذا إلزامي.';
          }
        }

        // for s3cc15m1
        if (
          (propName === 'ie_company_value' || propName === 'ir_release_quantity' || propName === 'ir_company_hold') &&
          this.scope === 3 &&
          this.category === 15 &&
          this.method === 1
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return 'هذا إلزامي.';
          }
        }

        // for s3cc15m3
        if (
          (propName === 'ie_company_value' ||
            propName === 'ir_total_investment' ||
            propName === 'ir_internal_investment') &&
          this.scope === 3 &&
          this.category === 15 &&
          this.method === 3
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return 'هذا إلزامي.';
          }
        }

        // for s3cc15m4
        if (
          (propName === 'ie_company_value' ||
            propName === 'ir_calculated_denominator' ||
            propName === 'ir_calculation_numerator') &&
          this.scope === 3 &&
          this.category === 15 &&
          this.method === 4
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return 'هذا إلزامي.';
          }
        }

        // validate for only distance_value in  s3c3 &&  aoa_value in s3c16
        if (
          (propName === 'distance_value' && this.method === 3 && this.scope === 3 && this.category === 4) ||
          propName === 'aoa_value' ||
          (propName === 'wsu_value' && this.category === 16)
        ) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return 'هذا إلزامي.';
          }
        }

        // validate for only value in s1m2 m3
        if (propName === 'value' && this.scope === 1 && (this.method === 2 || this.method === 3)) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return 'هذا إلزامي.';
          }
        }

        // validate for only energy_value in s3c9
        if (propName === 'energy_value' && this.scope === 3 && this.category === 9) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return 'هذا إلزامي.';
          }
        }

        if (propName === 'activity' && this.category === 14) {
          return validateMaximumCharactor(item[propName]?.toString(), 128, propName);
        }

        if (propName === 'ie_year' && this.category === 15) {
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
        }

        if (listNumbers.includes(propName) && parsing) {
          if (item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return this.validateNumberField(String(item[propName]));
        }

        if (maximum10WithoutRequired.includes(propName) && item[propName]) {
          let numberData = String(item[propName]).replace(/[^\d.]/g, '');
          if (listNumbers.includes(propName)) {
            if (item[propName] && item[propName] < 0) {
              return '正の値で入力してください。';
            } else if (String(item[propName]).length > 10) {
              return `10文字以内で入力してください。`;
            } else if (numberData && String(item[propName]).length - numberData.length === 0) {
              return null;
            } else {
              return '数字で入力してください。';
            }
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
        }

        if (maximum20WithoutRequired.includes(propName) && item[propName]) {
          let numberData = String(item[propName]).replace(/[^\d.]/g, '');
          if (listNumbers.includes(propName)) {
            if (item[propName] && item[propName] < 0) {
              return '正の値で入力してください。';
            } else if (String(item[propName]).length > 20) {
              return `20文字以内で入力してください。`;
            } else if (numberData && String(item[propName]).length - numberData.length === 0) {
              return null;
            } else {
              return '数字で入力してください。';
            }
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
        }

        if (maximum128WithoutRequired.includes(propName)) {
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
        }

        if (listNumbers.includes(propName)) {
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return null;
        }

        if (maximum128.includes(propName)) {
          // activity wsu_source in s3c16 no validate
          if ((propName === 'activity' || propName === 'wsu_source') && this.category === 16) {
            return null;
          }
          if (this.scope === 4 && propName === 'dir_date' && !!isNaN(new Date(item[propName]).getTime())) {
            return '日付形式（YYYY/M/D）で入力してください。';
          }
          return validateMaximumCharactor(item[propName]?.toString(), 128, propName);
        }
        if (maximum20.includes(propName)) {
          // wsu_unit wsu_value in s3c16 no validate
          if (propName === 'wsu_unit' && this.category === 16) {
            return null;
          }
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return validateMaximumCharactor(item[propName]?.toString(), 20, propName);
        }
        if (maximum10.includes(propName)) {
          return validateMaximumCharactor(item[propName]?.toString(), 10, propName);
        }
        // special validate
        // for value with required in s1m2
        if (propName === 'value' && this.method === 2) {
          return validateMaximumCharactor(item[propName]?.toString(), 20, propName);
        }

        if (propName === 'distance_value' && this.method === 3 && this.scope === 3 && this.category === 4) {
          return this.validateNumberField(String(item[propName]));
        }

        return null;
      }
    },
    resetMonth() {
      this.updateDataMonth(this.startMonth);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.pattern.addBlankItemsToView(this.dataTable, 100);
        this.scrollToTop();
      } else {
        // this.initialView();
        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    initialView() {
      if (this.dataTable && !this.$route.query?.import) {
        this.originalData = this.dataTable.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      this.$store.dispatch('commonApp/actionUpdateIsSuccess', false, { root: true });
      this.dataTable = new CollectionView([...this.originalData || []], {
        trackChanges: true,
      });
      if (this.flexgrid !== null) {
        let branchList = JSON.parse(JSON.stringify(this.$store.state.registerData)); 
        branchList.listType = branchList.listType.filter(item => item.status === true);
        branchList.listBranch.company_name = branchList.listBranch.company_name.filter(item => item.status === true);
        this.flexgrid.columnGroups = this.pattern.header(branchList, this.layer);
        this.flexgrid.refresh();
      }
      this.pattern.addBlankItemsToView(this.dataTable, 10);
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      this.dataTable.collectionChanged.addHandler(() => {
        this.dataTable?.itemsAdded?.forEach((dataTableItemAdded) => {
          if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
            return;
          }
          
          // format number in itemAdd when pasted (not blank row)
          this.listNumberItems.forEach(key => {
            if(dataTableItemAdded[key]) {
              const isNumber = $_helper_isNumberType(dataTableItemAdded[key]);
              const range = this.getSubstringRange(key);
              const subStringRang = dataTableItemAdded[key]?.toString()?.includes('-') ? range + 1 : range;
              dataTableItemAdded[key] = isNumber ?  formatValue(dataTableItemAdded[key])?.substring(0, subStringRang).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : ''
            }
          })

          if(dataTableItemAdded.ie_year) {
            dataTableItemAdded.ie_year = formatValue(dataTableItemAdded.ie_year)
          }
          const addData = this.pattern.handlerDataOnTable(dataTableItemAdded, this.itemsInView, false);
          dataTableItemAdded['emissions'] = addData['emissions']?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
        })

        this.dataTable?.itemsEdited.forEach(dataItemEdited => {
          if(dataItemEdited.ie_year) {
            dataItemEdited.ie_year = formatValue(dataItemEdited.ie_year)
          }
        })
      })
      if (this.pattern !== null) {
        this.updateTotalEmissionsHandler();
      }
      this.isUpdated = false;
      this.checkTableHasData()
    },
    scrollToTop() {
      if (!this.flexgrid) {
        return;
      }

      let rc = this.flexgrid.cells.getCellBoundingRect(0, 0, true);
      this.flexgrid.scrollPosition = new wjcCore.Point(this.flexgrid.scrollPosition.x, -rc.top);
    },
    async getDbByType(type, scopeType) {
      await getListMasterDb({
        db_types: type,
        scope_type: scopeType || null,
        contractor_id: this.contractor,
        category_id: this.category,
        scope_id: this.scope,
        status: true,
      });
    },
    async getDataWithDbCustomize() {
      await getListDbCustomize({
        contractor_id: this.contractor,
        category_id: this.category,
        scope_id: this.scope,
        status: true,
      });
    },
    formatValueSourceNumber(number) {
      const subRange = number?.toString()?.includes('-') ? 21 : 20
      return number?.toString()?.slice(0, subRange)
    },
    formatValueSourceNumber1(number) {
      let formatNumber = '';
      if (number?.includes('.')) {
        const natural = number.split('.')[0].length;
        const decimals = number.split('.')[1].length;
        if (natural + decimals + 1 <= 20) {
          return number;
        }
        formatNumber = parseFloat(number).toFixed(20 - natural - 1);
        return formatNumber;
      }
      return number;
    },
    canEffectChangeDataTable(theRowData, currentUserSection) {
      let currentTableSheet = this.$store.state.registerData;
      return (
        currentUserSection !== this.$socket.id &&
        currentTableSheet.duration === theRowData.duration_id &&
        currentTableSheet.yearSelected === theRowData.year &&
        currentTableSheet.month === theRowData.month
      );
    },
    createUndoStack() {
      this.undoStack = new UndoStack('#undoable-table', {
        maxActions: 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        },
      });
    },
    onFlexGridInitialDone(flexgrid) {
      if (!flexgrid) {
        return;
      }
      this.flexgrid = flexgrid;      
      const formatItems = [
        'value', 'loading_data_maximum', 'loading_data_rate',
        'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
        'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
        'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
        'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
        'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
        'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
        'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','wsu_value', 'le_value','pj_investment_ratio','emissions'
      ];
      this.createUndoStack();
      this.flexgrid.cellEditEnded.addHandler((s, e) => {
        this.checkTableChangeData();
        this.checkTableHasData();
        const column = s.columns[e.col];
        if(formatItems.includes(column.binding)){
          const { row, col } = e.range
          let cellData = s.getCellData(row, col, false)
          const isNumber = $_helper_isNumberType(cellData);
          const range = this.getSubstringRange(column.binding)
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          s.setCellData(row, col, isNumber ?  formatValue(cellData)?.substring(0, subStringRang).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '', false, true);
        }
        this.updateTotalEmissionsHandler();
      });
      this.flexgrid.pasted.addHandler((s, e) => {
        this.checkTableHasData();
        this.checkTableChangeData();
        
        const { col, col2, row, row2 } = e.range
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) { 
            if (formatItems.includes(s.columns[colIndex].binding) || s.columns[colIndex].binding === 'value') { // formatItems.includes(s.columns[colIndex].binding)
              const cellData = s.getCellData(rowIndex, colIndex, false)
              const isNumber = $_helper_isNumberType(cellData);
              const range = this.getSubstringRange(s.columns[colIndex].binding);
              const subStringRang = cellData?.includes('-') ? range + 1 : range;
              s.setCellData(rowIndex, colIndex, isNumber ?  formatValue(cellData)?.substring(0, subStringRang).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '', false, true);
              const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
              cEvent.custom = true
              s.onCellEditEnded(cEvent)
            }
          }
        }

        this.updateTotalEmissionsHandler();
      });
      this.flexgrid.deletedRow.addHandler(() => {
        this.checkTableHasData();
        this.checkTableChangeData();
        this.updateTotalEmissionsHandler();
      })
      this.flexgrid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        if(formatItems.includes(column.binding)){
          const { row, col } = e.range
          const cellData = s.getCellData(row, col, false)
          s.setCellData(row, col, formatValue(cellData), false, true);
        }
      });
      this.highlightChangedRows();

      if(this.$route.query?.import) {
        setTimeout(() => {
          this.renderDataPatternOnTable()
        }, 2000)
      }
    },
    highlightChangedRows() {
      if (this.dataPattern.data !== undefined) {
        this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
          let accessRowIndex = eventHandler.row;
          let currentItem = this.dataPattern.data[accessRowIndex];
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged =
              currentItem !== undefined && currentItem['changed_columns'] !== undefined
                ? currentItem['changed_columns']
                : [];
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let column = handler.columns[eventHandler.col];
              if ('branch_id' in currentItemChanged) {
                Object.values(this.layerIndex).forEach((layer) => {
                  currentItemChanged[layer] = currentItemChanged['branch_id'];
                });
                currentItemChanged['organizational_division'] = currentItemChanged['branch_id'];
                currentItemChanged['company_name'] = currentItemChanged['branch_id'];
                delete currentItemChanged['branch_id'];
              }
              if (this.pattern.autoFields.includes(column.binding) && currentItemChanged[column.binding]) {
                delete currentItemChanged[column.binding];
              }
              if ("db_master_id" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_master_id"];
                });
              }
              if ("db_customize_id" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_customize_id"];
                });
              }
              if ("fuel" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["fuel"];
                });
              }
              if (
                Object.keys(currentItemChanged).length &&
                (Object.keys(currentItemChanged).includes(column.binding))
              ) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                let updatedAt = moment
                  .utc(currentItemChanged[column.binding].updated_at)
                  .add(9, 'hours')
                  .format('YYYY/MM/DD HH:mm:ss');
                let updatedBy = currentItemChanged[column.binding].updated_by;
                tooltip.setTooltip(
                  eventHandler.cell,
                  "<div class='wj-cell-changed'>管理者" +
                    updatedBy +
                    'により変更されました<br/>' +
                    updatedAt +
                    '</div>',
                );
              }
            }
          }
        });
      }
    },
    highlightDuplicatedRows(data) {
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        if (eventHandler.panel.cellType === wjGrid.CellType.Cell && data?.includes(accessRowIndex)) {
          eventHandler.cell.classList.add('wj-duplicated-data');
        }
      });
    },
    checkTableHasData() {
      if((this.$route.query?.import && this.listDataPattern.length > 0)) {
        this.isHasDataTable = true;
        return true
      }
      if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
        this.isHasDataTable = false;
        const itemsInView = this.pattern.cellInputInView(this.itemsInView);
        this.dataTable.sourceCollection.some((item) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          if (!everyColumnsAreNull) {
            this.isHasDataTable = true;
            return true;
          }
        });
      }
    },
    cloneDataHandler(isSaveTemplate = false) {
      let cloneData = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      this.dataTable.sourceCollection.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return;
        }
        let data = this.pattern.handlerDataOnTable(item, this.itemsInView, isSaveTemplate);
        // data.emissions = formatValue(data.emissions)?.substring(0, 20)
        delete data.id;
        cloneData.push(data);
      });

      return cloneData;
    },
    async redirectAfterClickBtn(action) {
      this.dialogNotification = false;
      if(!this.isReloadPage) {
        this.isReloadPage = true
        return
      }
      if (
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN ||
        (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT && action === '')
      ) {
        return this.$router.go(this.$router.currentRoute);
      }
      if (action !== '') {
        if (action.includes('/emissions/register/list-menu')) {
          await this.$router.push({ path: action });
          return this.$router.go(this.$router.currentRoute);
        } else {
          return this.$router.push({ path: action });
        }
      }
    },
    updateTotalEmissionsHandler() {
      this.totalEmissions = 0;
      this.totalTreatment = {
        incinerator: 0,
        landfill: 0,
        recycling: 0,
        unknown: 0,
      };
      this.dataTable.sourceCollection.forEach((item) => {
        if (!item['emissions']) return true;
        const emission20Number = formatValue(item['emissions']).includes('-') ? 21 : 20;
        const itemEmisstion = item['emissions'] !== 'N/A' && item['emissions'] !== null ? math.bignumber(formatValue(item['emissions']).substring(0, emission20Number)) : '0';
        // const itemEmisstion = item['emissions'] !== 'N/A' && item['emissions'] !== null ? math.bignumber(formatValue(item['emissions']).substring(0, 20)) : '0';
        this.totalEmissions = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalEmissions)).toString()} + ${itemEmisstion.toString()}`));
        item['emissions'] = formatValue(item['emissions'])?.substring(0, emission20Number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
        if (this.category === 5 || this.category === 12) {
          switch (item['processing_method']) {
            case '焼却':
              this.totalTreatment.incinerator = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalTreatment.incinerator)).toString()} + ${itemEmisstion.toString()}`));
              break;
            case '埋立':
              this.totalTreatment.landfill = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalTreatment.landfill)).toString()} + ${itemEmisstion.toString()}`));
              break;
            case 'リサイクル':
              this.totalTreatment.recycling = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalTreatment.recycling)).toString()} + ${itemEmisstion.toString()}`));
              break;
            case '不明':
              this.totalTreatment.unknown = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalTreatment.unknown)).toString()} + ${itemEmisstion.toString()}`));
              break;
            default:
              break;
          }
        }
      });
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.totalEmissions);
    },
    editDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
      let dataRegisterOnTable = [];
      dataTable.itemsEdited.forEach((dataTableItemEdited) => {
        if (Object.values(ORGANIZATIONAL_DATA).includes(dataTableItemEdited.organizational_division)) {
          dataTableItemEdited.organizational_division = parseInt(Object.keys(ORGANIZATIONAL_DATA).find(key => dataTableItemEdited.organizational_division === ORGANIZATIONAL_DATA[key]));
        }
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataTableItemEdited, bindingLayout, isSaveTemplate);
        if (dataTableItemCustom?.id && dataTableItemCustom?.id === BLANK_ID) {
          return;
        }
        // dataTableItemCustom['emissions'] = formatValue(dataTableItemCustom['emissions'])?.substring(0, 20);
        dataRegisterOnTable.push(dataTableItemCustom);
      });
      return dataRegisterOnTable;
    },
    addBlankDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
      let dataRegisterOnTable = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      dataTable.itemsEdited.forEach((dataTableItemEdited) => {
        if (itemsInView.every((field) => dataTableItemEdited[field] === null || dataTableItemEdited[field] === '' || dataTableItemEdited[field] === undefined)) {
          return;
        }
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataTableItemEdited, bindingLayout, isSaveTemplate);
        if (dataTableItemCustom.id === BLANK_ID) {
          delete dataTableItemCustom.id;
        }
        if (dataTableItemCustom?.id && dataTableItemCustom.id !== BLANK_ID) {
          return;
        }
        // dataTableItemCustom['emissions'] = formatValue(dataTableItemCustom['emissions'])?.substring(0, 20);
        dataRegisterOnTable.push(dataTableItemCustom);
      });
      
      return dataRegisterOnTable;
    },
    addDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
      let dataRegisterOnTable = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      dataTable.itemsAdded.forEach((dataTableItemAdded) => {
        if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
          return;
        }
        dataTableItemAdded['organizational_division'] = dataTableItemAdded['organizational_division'] === undefined ? null : dataTableItemAdded['organizational_division']
        dataTableItemAdded['company_name'] = dataTableItemAdded['company_name'] === undefined ? null : dataTableItemAdded['company_name']
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataTableItemAdded, bindingLayout, isSaveTemplate);
        delete dataTableItemCustom.id;
        // dataTableItemCustom['emissions'] = formatValue(dataTableItemCustom['emissions'])?.substring(0, 20);
        dataRegisterOnTable.push(dataTableItemCustom);
      });
      if (this.isFullScreen) {
        dataRegisterOnTable = [];
        dataTable.itemsAdded.forEach((dataTableItemAdded) => {
          if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
            return;
          }
          let dataTableItemCustom = this.pattern.handlerDataOnTable(dataTableItemAdded, bindingLayout, isSaveTemplate);
          delete dataTableItemCustom.id;
          // dataTableItemCustom['emissions'] = formatValue(dataTableItemCustom['emissions'])?.substring(0, 20);
          dataRegisterOnTable.push(dataTableItemCustom);
        });
      }
      return dataRegisterOnTable;
    },
    removeDataRegisterOnTable(dataTable) {
      let removeItems = [];
      dataTable.itemsRemoved.forEach((dataTableItemRemoved) => {
        if (dataTableItemRemoved?.id && dataTableItemRemoved?.id !== BLANK_ID) {
          removeItems.push(dataTableItemRemoved.id);
        }
      });
      return removeItems;
    },
    isCreateNewData() {
      return !(
        !this.dataPattern?.workflow_data?.id ||
        this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES ||
        this.isGetAllData
      );
    },
    checkSelectedDuration() {
      const isExist = this.durationList.findIndex(
        (duration) => duration.id === this.selectDurationChange && duration.is_selected === true,
      );
      return isExist >= 0 ? true : false;
    },

    checkTableChangeData() {
      // use timeout to waiting itemsEdited in first time
      setTimeout(() => {
        if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
          this.isEditDataTable = false;
          const itemsInView = this.pattern.cellInputInView(this.itemsInView);
          this.dataTable.itemsEdited.some((item) => {
            const everyColumnsAreNull = itemsInView.every(
              (field) => item[field] === null || item[field] === '' || item[field] === undefined,
            );
            if (!everyColumnsAreNull) {
              this.isEditDataTable = true;
              return true;
            }
          });
        } else {
          this.isEditDataTable = false
        }
      }, 100)
    },
    getDataImport(isSaveTemplate = false) {
      let importData = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      this.dataTable._view.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return;
        }
        let data = this.pattern.handlerDataOnTable(item, this.itemsInView, isSaveTemplate);
        data.emissions = formatValue(data.emissions)?.substring(0, 20)
        delete data.id;
        importData.push(data);
      });

      return importData;
    },
    onHandleShowExportPopup(value) {
      this.exportDialog = value;
      this.exportDialogKey++;
    },
  },
  computed: {
    ...mapState('registerData', [
      'errorMess',
      'totalDurationEmissions',
      'duration',
      'durationValue',
      'listDuration',
      'isLoading',
      'isShowPopup',
      'errorFilterData',
      'month',
      'isUpdateData',
      'idCustomizes',
      'totalData',
      'isClearUndoStack',
      'watchDeleted',
      'allowAddNew',
      'isFullScreen',
    ]),
    ...mapState('emission', ['durationId']),
    ...mapState('userData', ['contractor', 'currentUser','is_standard']),
    ...mapState('commonApp', ['statusAction', 'loadMore', 'isExpand']),
    ...mapState('approval', ['listApprovers']),
    ...mapState('actionsTable', ['startAction', 'idDelete']),
    ...mapState('newRegisterData', ['isApproved', 'methodLayer1Id', 'methodLayer2Id', 'patternId']),
    ...mapGetters('newRegisterData', ['isLoadingSubmitting', 'apiResponseObject']),
  
    workFlowStatus(){
      return this.workFlowData?.status;
    },
    breadcrumbOption(){
      const dashBoard= {
        text: 'الصفحة الرئيسية',
        disabled: false,
        href: ROUTES.DASHBOARD,
      }
      const emission={
        text: '排出量登録',
        disabled: false,
        href: ROUTES.EMISSIONS,
      }
      const scope={
        text: 'Scope ' + this.scope,
        disabled: true,
        href: ROUTES.EMISSIONS,
      }
      const position={
        text: this.getPosition,
          disabled: true,
        href: ROUTES.EMISSIONS,
      }
      const title={
        text: this.getTitle,
        disabled: true,
        href: ROUTES.EMISSIONS,
      }
      switch (this.$route.params?.scope) {
        case ROUTES.LIST_EMISSION_SUBMITTED:
          emission.text= '申請状況';
          emission.href= `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_SUBMITTED}`;
          break;
        case ROUTES.LIST_EMISSION_TEMPLATES:
          emission.text= 'テンプレート';
          emission.href= `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_TEMPLATES}`;
          break;
        case ROUTES.LIST_APPROVAL:         
          emission.href= ROUTES.APPROVAL;
          switch (this.workFlowStatus) {
            case STATUS_FIELD.STATUS_APPROVED:
            case STATUS_FIELD.STATUS_REPRENSENT_APPROVED:
              emission.text= '排出量の承認';
              break;
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED:
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER:
              emission.text= '申請状況';
              break;
            default:
              emission.text= '排出量の承認';
              break;
          }
          break;
        case ROUTES.LIST_MENU_EMISSION:
          emission.href= ROUTES.EMISSIONS;
          switch (this.workFlowStatus) {
            case STATUS_FIELD.STATUS_RETURN:
            case STATUS_FIELD.STATUS_REPRENSENT_RETURN:
              emission.text= '排出量の登録';
              break;
            case STATUS_FIELD.STATUS_SUBMIT:
              emission.text= '排出量の登録';
              emission.href= `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_SUBMITTED}`;
              break;
            case STATUS_FIELD.STATUS_APPROVED:
            case STATUS_FIELD.STATUS_REPRENSENT_APPROVED:
              emission.text= '排出量の登録';
              emission.href= `${ROUTES.EMISSIONS}/${ROUTES.APPROVAL}`;
              break;
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED:
              emission.text= '申請状況';
              break;
            default:
              emission.text= '排出量の登録';
              break;
          }
          break;
        default:
          break;
      }
      if(this.isRegisterNew){
        emission.text= 'تسجيل الانبعاثات';
      }
      return this.scope === 4
        ? 
        [dashBoard, emission, position, title]
        : 
        [dashBoard, emission, scope, position, title]
    },
    month: {
      set(month) {
        this.$store.state.registerData.month = month;
      },
      get() {
        return this.$store.state.registerData.month;
      },
    },
    isHasDataRegister() {
      // Handle condition to display dot
      return this.$route.fullPath.includes('/emissions/view/');
    },
    displayGetMethodName() {
      return !this.dataPattern?.workflow_data?.id || this.isGetAllData;
    },
    getMonthWhenSameYear() {
      let listMonth = [];
      for (let index = this.startMonth; index <= this.endMonth; index++) {
        listMonth.push(index);
      }
      return listMonth;
    },
    getMethodName() {
      if (!this.method) {
        return '選択してください';
      }

      let index = '';
      if (this.methodLayer2 !== null) {
        if (this.scope === 3 && this.category === 9 && this.currentSelected === 2) {
          if (this.currentSelectedLayer2 === 4) {
            index = 0;
          } else if (this.currentSelectedLayer2 === 5) {
            index = 1;
          } else {
            return '選択してください';
          }
        } else {
          index = this.methodListLayer2.findIndex((method) => method.value === this.methodLayer2);
        }
        return this.methodListLayer2[index]?.text || '選択してください';
      }

      index = this.methodList.findIndex((method) => method.value === this.method);
      return this.methodList[index]?.text || '選択してください';
    },
    getTitle() {
      if (this.scope > 2) {
        if (this.category === 16 || this.scope === 4) {
          return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        } else {
          return `${this.category}. ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        }
      } else {
        return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
      }
    },
    getPosition() {
      return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['position'];
    },
    getLastTimeUpdate() {
      return this.dateTimeFormat(this.latestRecord?.updated_at_latest);
    },
    getErrorFilterData() {
      if (this.errorFilterData !== '') {
        this.isErrorFilterData = true;
        return this.errorFilterData;
      }
      return '';
    },
    isReadOnlyDataIsApproved() {
      return (
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_APPROVED
      );
    },
    isEditData() {
      return this.dataTable?.itemsEdited.length || (this.$route.query?.import && this.listDataPattern.length > 0) ? 1 : 0;
    },
    isHasLatestRecord() {
      return this.latestRecord?.updated_at_latest;
    },
    isReadOnlyDataTable() {
      return (this.currentUser?.user?.role_id !== ROLE.ADMIN && (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_APPROVED)) ||  this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED
      || (this.currentUser?.user?.role_id !== ROLE.ADMIN && this.$route.fullPath.includes('/emissions/view/'))
      || (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_SUBMIT)
    },

    isReadOnlyTitle() {
      return [
          STATUS_FIELD.STATUS_SUBMIT,
          STATUS_FIELD.STATUS_REPRENSENT_APPROVED,
          STATUS_FIELD.STATUS_APPROVED,
          STATUS_FIELD.STATUS_CANCEL_SUBMMITED
        ].includes(this.dataPattern?.workflow_data?.status);
    },
    isResizeCustome() {
      return ['NewRegisterData', 'RegisterData'].includes(this.$route.name)
    }
  },
  watch: {
    method: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (
            (this.scope === 3 && this.category === 11 && oldVal !== 1 && newVal === 1) ||
            (this.scope === 3 && this.category === 9) ||
            (this.scope === 3 && this.category === 4 && newVal !== 3)
          ) {
          } else {
            if (
              (this.scope === 3 && this.category === 11 && oldVal === 1 && newVal !== 1) ||
              (this.scope === 3 && this.category === 4 && newVal === 3)
            ) {
              this.methodLayer2 = null;
            }
            // re-render table
            if (this.isUpdated) {
              if (this.isGetAllData) {
                await this.getUrlFromScopeCategory();
              }
              this.getListDurations(this.selectDurationChange);
              this.getHeaderFromScope();
              this.getDataFromScope();
              this.key++;
            }
          }
        }
      },
    },
    route: {
      handler() {
        this.scope = parseInt(this.$route.params?.scope) || 1;
        this.category = parseInt(this.$route.params?.category) || 1;
        this.updateDataScope(this.scope);
        this.updateDataCategory(this.category);
      },
    },
    methodLayer2: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // re-render table
          if (this.isUpdated && newVal !== null) {
            if (this.isGetAllData) {
              await this.getUrlFromScopeCategory();
            }
            this.getListDurations(this.selectDurationChange);
            this.getHeaderFromScope();
            this.getDataFromScope();
            this.key++;
          }
        }
      },
    },
    selectMonthYearChange: {
      async handler() {
        this.updateMonthYearSelected();
        if (this.isGetAllData) {
          await this.getUrlFromScopeCategory();
          await getListDetailBranch().then((res) => {
            this.layerData = res.layer;
          });
          this.getHeaderFromScope();
          this.getDataFromScope();
          this.$router.replace({ path: this.$route.path  })
          
          this.key++;
        }
      },
    },
    apiResponseObject(response) {
      if (response.message) {
        this.dialogNotification = true;
        this.submitDataDialogPopup = false;
        this.messageNotification = response.message;
      }
    },
    selectDurationChange: {
      async handler(value) {
        this.updateDuration(value);
        this.isReadOnly = !this.checkSelectedDuration();
        const selectedDuration = this.durationList.find((duration) => duration.id === value);
        this.getMonthSelectionList(
          selectedDuration.start_at,
          selectedDuration.end_at,
          selectedDuration.duration_registered,
        );
        if (this.selectMonthYearChange === new Date(selectedDuration.start_at).getMonth() + 1) {
          await this.getUrlFromScopeCategory();
          this.getListDurations(value);
          this.getDataFromScope();
        } else if (this.isGetAllData) {
          if (this.$route.query.month) {
            this.selectMonthYearChange = parseInt(this.$route.query.month)
            const newQuery = this.$route.query;
            delete newQuery.month
            this.$router.replace({ path: this.$route.path , query: { ...newQuery } })
          } else {
            this.selectMonthYearChange = new Date(selectedDuration.start_at).getMonth() + 1;
          }
        }
      },
    },
    // TODO: Undo/redo
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },
    canUndo(newValue) {
      this.actionUpdateStatusBtn({ undo: newValue, redo: this.canRedo });
    },
    canRedo(newValue) {
      this.actionUpdateStatusBtn({ undo: this.canUndo, redo: newValue });
    },
    isEditDataTable() {
      this.updateIsEditData(this.isEditDataTable)
    },
  },
  beforeDestroy() {
    this.updateStartMonth(this.startMonth);
    this.updateIsEditData(false);
    this.method = null;
    webStorage.set('startMonth', this.startMonth);
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.title-block {
  margin-top: 40px;
  .title-block--detail {
    &.has-opacity {
      opacity: 0;
    }
  }
}

.total-treatment-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 12px;

  .treatment-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .treatment-label {
      min-width: 1912x;
    }

    .treatment-value {
      color: $goldMid;
      line-height: 24px;
      text-align: left;
      margin-left: 10px;

      span {
        margin-left: 8px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
}

.data-is-registered::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #bfd4d9;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.duration-text {
  border: 1px solid rgba(42, 42, 48, 0.1);
  border-radius: 4px;
  min-width: 275px;
  height: 40px;
  display: flex;
  align-items: center;
  color: $monoBlack;
  justify-content: flex-end;

  span {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.durations-pulldown {
  margin-top: 48px;
  align-items: center;
  padding: 0px;
  gap: 16px;
  &.month-year-pulldown {
    margin-top: 20px;
  }
  .durations-label {
    font-family: $mainFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    min-width: 88px;

    letter-spacing: 0.03em;
    color: $monoBlack;
  }

  .select-item {
    margin-top: 8px;
  }
}

.update-block {
  margin-top: 48px;

  .item {
    height: 42px;

    .item-icon {
      margin-right: 12px;
    }

    .item-detail {
      height: 42px;

      .item-title {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        height: 18px;

        letter-spacing: 0.03em;
        color: $monoBlack;
      }

      .item-content {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        height: 24px;
        margin-bottom: 0;

        letter-spacing: 0.05em;
        color: $monoBlack;
      }
    }

    &:last-child {
      margin-top: 16px;
    }
  }
}

.total-result {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  background: $bgLight;
  border-radius: 4px;

  .emissions {
    width: 100%;

    .total-label {
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      color: $monoBlack;
    }

    .total-emission {
      margin-top: 8px;
      font-family: 'Century Gothic Pro';
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      color: $goldMid;
      width: 100%;
      text-align: right;
      word-break: break-all;

      span {
        font-size: 13px;
        line-height: 28px;
        min-width: 40px;
        display: inline-block;
      }
    }
  }

  .method {
    width: 100%;

    .method-label {
      margin-bottom: 8px;
      color: $monoBlack;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
    }

    .v-btn {
      width: 100%;
    }
  }
}

.selections {
  margin-bottom: 14px;
}

@include desktop {
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin-left: 40px;

    .treatment-item {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;
      align-items: flex-start;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 10px;
      }
    }
  }
  .title-block {
    display: flex;
    margin-top: 48px;
    // margin-bottom: 48px;
    .title-block--detail {
      width: 50%;
      padding-right: 0 !important;
      flex: 1 0;
      &.has-opacity {
        opacity: 0;
      }
    }
  }
  .register-page {
    .container {
      padding: 0 40px;
    }
  }
  .update-block {
    display: flex;
    justify-content: flex-end;
    // max-width: 50% !important;
    width: max-content;
    margin-top: 0;

    .item {
      &:last-child {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
  .selections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    .durations-pulldown {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 24px;

      .durations-select-item {
        max-width: 275px;
        &.new-register {
          max-width: 275px;
        }
      }
    }

    .total-result {
      margin-top: 24px;
      max-width: 520px;
      width: 100%;
      padding: 16px 20px 20px;
      flex-direction: row;

      .emissions {
        .total-emission {
          text-align: left;
          // margin-left: 8px;
          min-height: 38px;
          max-width: 250px;
          word-break: break-all;
          line-height: 36px;
        }

        span {
          margin-left: 8px;
        }
      }

      .method {
        max-width: 200px;

        .common-btn {
          max-width: 200px;

          &:disabled {
            background: transparent !important;
            box-shadow: unset;
            border: 1px solid rgba(42, 42, 48, 0.1);
            box-sizing: border-box;
            border-radius: 4px;
          }

          .v-btn__content {
            text-transform: lowercase !important;
          }
        }
      }

      &.max-content {
        .emissions {
          .total-emission {
            max-width: 100%;
          }
        }
      }
    }
  }
  .select-month {
    .month {
      width: 70.33px;
      height: 42px;
      flex: none;
      align-self: stretch;
      flex-grow: 1;
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 0px 0px;
      text-align: center;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border-radius: 0;

      letter-spacing: 0.03em;

      color: $monoMid;
    }
  }
}

@include large-desktop {
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: max-content;
    margin-left: 45px;

    .treatment-item {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 0;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .selections {
    .total-result {
      max-width: 350px;
    }
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }

  &.full-screen {
    position: fixed;
    top: 84px;
    right: 0;
    z-index: 99;
    height: 100vh;
    padding-right: 20 !important;
    width: 100%;
    animation: bottotop 0.2s ease-in 0s 1 normal none;
    -webkit-animation: bottotop 0.2s ease-in 0s 1 normal none;

    .category-table {
      height: 100%;
      height: calc(100vh - 84px);
      background: #fff;
    }

    &.expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }

    &.no-expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }
  }

  @include desktop {
    &.full-screen {
      position: fixed;
      top: 84px;
      right: 0;
      z-index: 99;
      height: 100vh;
      padding-right: 0 !important;
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;

        .table-toolbar {
          margin-bottom: 0 !important;
        }
      }

      &.expanded {
        width: calc(100% - 235px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }

      &.no-expanded {
        width: calc(100% - 73px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }
    }
  }
}

.category-table {
  .wj-cell-check {
    margin: unset !important;
  }
}

.main-table {
  @media (orientation: landscape) {
    @media (max-width: 1000px) {
      padding-left: 20px !important;
    }
  }
}

.method-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 64px;
  width: 100%;
  border-radius: 4px;
  height: 40px !important;
  padding: 7px 16px 9px !important;
  border: 1px solid rgba(42, 42, 48, 0.1);
  span {
    flex: unset !important;
    display: block !important;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: initial !important;
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }
}
</style>
<style lang="scss">
.durations-pulldown {
  .select-month {
    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          .v-select__selections {
            display: flex;
            flex-direction: row-reverse;
          }
        }
      }
    }
    &.v-input--is-disabled {
      .v-input__slot {
        background: #e4e6e7 !important;
      }
    }
  }
}
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  &.fullmode{
    margin-top : 34px !important;
    
  }
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
}
</style>
