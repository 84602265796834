<template>
  <div
    class="category-table main-table custom-table"
    :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
  >
    <data-table
      v-if="pattern"
      :data-source="dataTable"
      :init-grid="pattern.initializeGrid"
      :allowDelete="!(isReadOnly && isReadOnlyDataIsApproved)"
      :grid-columns="pattern.header($store.state.registerData, layer)"
      :key="key"
      :isDisabled="isDisabled"
      :rowFocus="1"
      :totalData="isReadOnly && isReadOnlyDataIsApproved ? totalData - 1 : totalData"
      :showFocus="!(isReadOnly && isReadOnlyDataIsApproved)"
      :isReadOnly="isReadOnly && isReadOnlyDataIsApproved"
      :allowAutoWidth="pattern.allowWidthAuto ? pattern.allowWidthAuto() : false"
      :lastedRow="lastedRow"
      :allowAddNew="allowAddNew"
      :isHasData="isHasDataTable"
      :dataPattern="dataPattern"
      @onHandleActionTable="onHandleActionTable"
      @changeFullScreen="onChangeFullScreen"
      @flexGridInitialDone="onFlexGridInitialDone"
    />
    <submit-data-popup
      v-if="pattern"
      :dialog="submitDataDialogPopup"
      :listApprovers="listApprovers"
      :isLoadingSubmitting="isLoadingSubmitting"
      :dataPattern="dataPattern"
      @submitHandler="submitRegisterDataHandler"
      @closeDialogSubmitData="closeDialogSubmitData"
    />
    <save-template-data-popup
      :dialog="saveTemplateDataDialogPopup"
      @submitHandler="submitTemplateAction()"
      @closeDialogSaveTemplate="closeDialogSaveTemplate"
    />
    <approval-popup
      :dialog="dialogApproval"
      :items="listStatusApproval"
      :confirmText="'決定'"
      @submit="approvalDataHandler"
      @close="dialogApproval = false"
    />
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="redirectAfterClickBtn(redirectUrl)" classes="register" />
    <question-popup :dialog="dialogQuestion" :confirm="true" :message="messageQuestion" :confirmText="confirmQuestionText" @close="dialogQuestion = false" @submit="submitAction()" classes="register-question" />
  </div>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.grid';
import '@grapecity/wijmo.input';
import * as wjcCore from '@grapecity/wijmo';
// import pattern
import partern1 from '@/concerns/registerData/scope1category1';
import partern2 from '@/concerns/registerData/scope1category1method2';
import partern3 from '@/concerns/registerData/scope1category1method3';
import partern4 from '@/concerns/registerData/scope2method1';
import partern5 from '@/concerns/newRegisterData/scope3category1';
import partern6 from '@/concerns/registerData/scope3category2';
import partern7 from '@/concerns/registerData/scope3category3';
import partern8method1 from '@/concerns/registerData/scope3category4method4';
import partern8method2 from '@/concerns/registerData/scope3category4method5';
import partern8method3 from '@/concerns/registerData/scope3category4method6';
import partern9method1 from '@/concerns/registerData/scope3category4method7';
import partern9method2 from '@/concerns/registerData/scope3category4method8';
import partern9method3 from '@/concerns/registerData/scope3category4method2';
import partern11 from '@/concerns/registerData/scope3category5';
import partern12 from '@/concerns/registerData/scope3category6method4';
import partern12method1 from '@/concerns/registerData/scope3category6method1';
import partern12method2 from '@/concerns/registerData/scope3category6method2';
import partern12method3 from '@/concerns/registerData/scope3category6method3';
import partern12method4 from '@/concerns/registerData/scope3category6method4';
import partern12method5 from '@/concerns/registerData/scope3category6method5';
import partern12method6 from '@/concerns/registerData/scope3category6method6';
import partern12method7 from '@/concerns/registerData/scope3category6method7';
import partern13 from '@/concerns/registerData/scope3category7method1';
import partern14 from '@/concerns/registerData/scope3category7method2';
import partern14Method3 from '@/concerns/registerData/scope3category7method3';
import partern14Method4 from '@/concerns/registerData/scope3category7method4';
import partern14Method5 from '@/concerns/registerData/scope3category7method5';
import partern15 from '@/concerns/registerData/scope3category8';
import partern16 from '@/concerns/registerData/scope3category9method1';
import partern16method2 from '@/concerns/registerData/scope3category9method2';
import partern16method3 from '@/concerns/registerData/scope3category9method3';
import partern16method4 from '@/concerns/registerData/scope3category9method4';
import partern16method5 from '@/concerns/registerData/scope3category9method5';
import partern17 from '@/concerns/registerData/scope3category10';
import partern18 from '@/concerns/registerData/scope3category11';
import partern18method1 from '@/concerns/registerData/scope3category11method1';
import partern18method2 from '@/concerns/registerData/scope3category11method2';
import partern18method3 from '@/concerns/registerData/scope3category11method3';
import partern18method4 from '@/concerns/registerData/scope3category11method4';
import partern19 from '@/concerns/registerData/scope3category12';
import partern20 from '@/concerns/registerData/scope3category13';
import partern21 from '@/concerns/registerData/scope3category14';
import partern26 from '@/concerns/registerData/scope3category16';
import partern22 from '@/concerns/registerData/scope3category15method1';
import partern23 from '@/concerns/registerData/scope3category15method2';
import partern24 from '@/concerns/registerData/scope3category15method3';
import partern25 from '@/concerns/registerData/scope3category15method4';
import partern27 from '@/concerns/registerData/scope4category1';
import {CollectionView} from '@grapecity/wijmo';
import DataTable from '@/components/category/data-table';
import {
  getListDbCustomize,
  getListMasterDb,
  getListDetailBranch,
} from '@/api/registerData';
import { mapGetters, mapState, mapActions} from 'vuex';
import {validateMaximumCharactor, validateMaximumCharactorOnlyFacility} from '@/utils/validate';
import { UndoStack } from '@grapecity/wijmo.undo';
import {
  maximum128,
  maximum20,
  maximum10,
  listNumbers,
  maximum20WithoutRequired,
  maximum128WithoutRequired,
  maximum10WithoutRequired,
  ACTION_HEADER_TABLE,
  SCOPE,
  CATEGORY,
  METHOD_3,
  BLANK_ID,
  CATEGORY_7,
  APPROVAL_TYPE,
  APPROVAL_STATUS,
} from '@/constants/registerData';
import { STATUS_FIELD } from '@/constants/status';
import webStorage from '@/utils/webStorage';
import {makeNewId} from '@/concerns/utils/master-or-customize-db';
import store from "@/store";
import * as wjGrid from '@grapecity/wijmo.grid';
import { AutoComplete } from '@grapecity/wijmo.input';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import SaveTemplateDataPopup from '@/components/dialogs/ActionHeaderTable/save-template-data-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ApprovalPopup from "@/components/dialogs/approval-popup";
import {updateTemplateData, deleteTemplate, approvalDataAction, cancelSubmittedData} from '@/api/newRegisterData';
import { updateListData } from '@/api/registerData';
import _ from 'lodash';
export default {
  components: { DataTable, SubmitDataPopup, SaveTemplateDataPopup, NotificationPopup, QuestionPopup, ApprovalPopup },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    initGrid: {
      type: Function,
      default: () => {
      },
    },
    gridColumns: {
      type: Array,
      default: () => [],
    },
    scopeId: {
      type: Number,
      default: 0,
    },
    categoryId: {
      type: Number,
      default: 1,
    },
    registerWorkflow: {
      type: Object,
      default: () => {},
    },
    method: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    methodLayer2: {
      type: Number,
      default: null,
    },
    dataPattern: {
      type: Object,
      default: () => {},
    },
    isHasData: {
      type: Boolean,
      default: false,
    },
    layerData: {
      type: Array,
      default: () => [],
    },
    durationText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      listDataPattern: [],
      header: null,
      pattern: null,
      dataTable: null,
      init: () => {
      },
      perPage: 5,
      order: 'id',
      listNumberItems: [
        'industrial_waste_value',
        'te_value',
        'value',
        'employees_number_value',
        'working_days_number_value',
        'loading_data_maximum',
        'loading_data_rate',
        'distance',
        'fuel_efficiency',
        'energy_value',
        'sales_volume_value',
        'scale_index_value',
        'dir_value',
        'qd_value',
        'energy_saving_value',
        'scope12_overlap',
        'aoa_value',
        'cpa_value',
        'transport_weight_value',
        'sea_distance_value',
        'travel_value',
        'iss_value',
        'ir_internal_investment',
        'ir_total_investment',
        'ie_company_value',
        'ir_calculated_denominator',
        'ir_calculation_numerator',
      ],
      scope_has_wsu_number: [3],
      energyTypes_list: [
        {
          id: 1,
          name: 'ガソリン',
          unit: 'KL',
          wsu_value: 2.5,
          wsu_unit: 't-CO2/KL',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 2,
          name: '経由',
          unit: 'KL',
          wsu_value: 2,
          wsu_unit: 't-CO2/KL',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 3,
          name: '都市ガス',
          unit: '千㎥',
          wsu_value: 3,
          wsu_unit: 't-CO2/千㎥',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
        {
          id: 4,
          name: 'type test',
          unit: '千㎥',
          wsu_value: 4,
          wsu_unit: 't-CO2/千㎥',
          wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
        },
      ],
      key: 0,
      isConvert: false,
      listDataActions: [],
      undoStack: null,
      canUndo: false,
      canRedo: false,
      actionCount: 0,
      timeoutUndoStack: false,
      lastedRow: null,
      flexgrid: null,
      originalData: [],
      listRowFields: [
        'business_name',
        'company_name',
        'db_master_id',
        'duration_id',
        'memo_1',
        'memo_2',
        'month',
        'organizational_division',
        'qd_source',
        'qd_unit',
        'qd_value',
        'supplier_name',
        'type',
        'wsu_source',
        'wsu_unit',
        'wsu_value',
        'year'
      ],
      layer: [],
      layerIndex: {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      },
      submitDataDialogPopup: false,
      saveTemplateDataDialogPopup: false,
      dialogNotification: false,
      messageNotification: '',
      isHasDataTable: false,
      itemsInView: [],
      dataTitle: null,
      isHasErrorData: false,
      dialogQuestion: false,
      messageQuestion: '編集内容をこのテンプレートに上書き保存します。よろしいですか？',
      confirmQuestionText: '保存',
      dialogApproval: false,
      dialogCancelSubmit: false,
      listStatusApproval: [],
      redirectUrl: '',
      dialogClone: false,
      isCloneTemplate: false,
      totalEmissions: 0,
    };
  },
  created() {
    this.getApproversList();
  },
  async mounted() {
    this.actionUpdateIsClearUndoStack(false);
    this.$emit('updateIsUpdated', false);
    this.updateIsUpdated(false);
    this.updateActionName(''); // update action name
    this.actionUpdateStatusBtn({undo: false, redo: false});
    this.updateUndoRedoClick('');
    this.updateUndoStack(null);
    this.updateUndoRedoStackData({
      undo: [],
      redo: [],
    });
    this.$store.dispatch('registerData/updateDataList', []);
    this.$store.dispatch('registerData/updateStatusIsUpdateData', false);
    this.$store.dispatch('registerData/updateTotalEmission', 0);
    this.$store.dispatch('registerData/updateTotalDurationEmission', 0);
    this.$store.commit('registerData/updateEnergyType', this.energyTypes_list);
    this.$store.dispatch('registerData/updateListDbCustomize', [], {root: true});
    this.$store.dispatch('registerData/updateLatestRecord', {});
    this.actionAllowAddNew(true);
    this.isHasDataTable = this.isHasData;
    webStorage.remove('duration');
  },
  methods: {
    ...mapActions('registerData', [
      'updateDataMonth',
      'updateStartMonth',
      'updateIsUpdated',
      'updateUndoRedoClick',
      'actionUpdateIsClearUndoStack',
      'updateUndoStack',
      'actionAllowAddNew',
      'updateUndoRedoStackData',
      'updateOrderNumberDontHavePermissions',
      'updateDataMethod',
      'updateYearSelected',
    ]),
    ...mapActions("approval", ["getApproversList"]),
    ...mapActions("newRegisterData", ["addRegisterDataWorkFollow"]),
    ...mapActions('commonApp', ['loadMoreAction']),
    ...mapActions('actionsTable', ['actionUpdate', 'actionUpdateStatusBtn', 'updateActionDelete', 'updateActionName']),
    onChangeSelected() {
    },
    onHandleActionTable(action, dataTitle) {
      this.dataTitle = dataTitle;
      console.log(action);
      // return;
      switch (action) {
        case ACTION_HEADER_TABLE.SAVE_TEMPRORA:
          if (this.yearSelected === null && this.month === null) {
            this.dialogNotification = true;
            this.messageNotification = '排出量の登録/申請に必要な情報が入力されていないため、登録/申請を完了できません。登録年月、タイトルに未入力箇所がないかをご確認ください。';
            return;
          }
          if (this.dataPattern.workflow_data?.id && this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
            // clone template from draft
            this.dialogQuestion = true;
            this.messageQuestion = '編集内容を排出量の新規登録データとして保存します。よろしいですか？';
            this.confirmQuestionText = '登録';
          } else {
            this.saveDraftDataHandler();
          }
          break;
        case ACTION_HEADER_TABLE.SAVE_TEMPLATE:
          this.saveTemplateDataDialogPopup = true;
          this.isCloneTemplate = false;
          break;
        case ACTION_HEADER_TABLE.DELETE_DATA:
          this.dialogQuestion = true;
          this.confirmQuestionText = '削除';
          if (this.dataPattern?.workflow_data.id && this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
            // handle remove data to template detail
            this.messageQuestion = 'このテンプレートを削除します。よろしいですか？\n共有中のテンプレートを削除すると、共有中メンバーのテンプレート一覧からも表示されなくなります。';
          } else {
            this.messageQuestion = 'このデータを削除します。よろしいですか？';
          }
          break;
        case ACTION_HEADER_TABLE.SUBMIT_DATA:
          if (this.yearSelected === null && this.month === null) {
            this.dialogNotification = true;
            this.messageNotification = '排出量の登録/申請に必要な情報が入力されていないため、登録/申請を完了できません。登録年月、タイトルに未入力箇所がないかをご確認ください。';
            return;
          }
          this.validateDataTable();
          let submitData = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView));
          if (this.dataPattern.workflow_data?.id) {
            submitData = this.cloneDataHandler();
          }
          const isNotExistBranch = submitData.find(item => !item.branch_id);
          if (isNotExistBranch) {
            const layerName = this.layer.map(layer => {return layer.header})
            this.dialogNotification = true;
            this.messageNotification = `入力された組織区分/法人名/${layerName}の組み合わせが存在しません。`;
            return;
          }
          if (this.checkDuplicateData(submitData)) {
            this.dialogNotification = true;
            this.messageNotification = '重複チェックでエラー';
            return;
          }
          if (!this.isHasErrorData && this.yearSelected !== null && this.month !== null && !this.checkDuplicateData(submitData)) {
            this.submitDataDialogPopup = true;
          }
          break;
        case ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE:
          this.dialogQuestion = true;
          this.messageQuestion = '編集内容をこのテンプレートに上書き保存します。よろしいですか？';
          this.confirmQuestionText = '保存';
          break;
        case ACTION_HEADER_TABLE.CLONE_TEMPLATE:
          this.saveTemplateDataDialogPopup = true;
          this.isCloneTemplate = true;
          break;
        case ACTION_HEADER_TABLE.REVIEWING_DATA:
          this.dialogApproval= true;
          if (this.dataPattern.workflow_data.is_approver === 1) {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name       : "承認",
                description: "申請を承認します。"
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name       : "申請差戻",
                description: "申請を却下し、申請者に差し戻します。"
              }
            ];
          } else {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name       : "代理承認",
                description: "指定された承認者に代わって承認します。"
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name       : "代理申請差戻",
                description: "指定された承認者に代わって申請を却下し、申請者に差し戻します。"
              }
            ];
          }
          break;
        case ACTION_HEADER_TABLE.CANCEL_SUBMITTED:
          this.dialogQuestion= true;
          this.messageQuestion = '承認申請を取り消します。よろしいですか？';
          this.confirmQuestionText = '申請を取り消す';
          break;
        case ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED:
          this.handleAcceptRequestCancelSubmit();
          break;
        case ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA:
            this.dialogQuestion = true;
            this.messageQuestion = '承認済みデータの変更内容を保存します。よろしいですか？\n保存した場合、管理者によって登録内容が変更された旨をデータを登録した申告者に通知します。';
            this.confirmQuestionText = '保存';
          break;
        default:
          break;
      }
    },
    validateDataTable() {
      this.dataTable.getError = this.getError;
      const itemsInView = this.pattern.notNullInView(this.itemsInView)
      let errorData = [];
      this.dataTable.sourceCollection.forEach(item => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexgrid.columns.forEach(column => {
          errorData.push(this.getError(item, column.binding));
          return true;
        })
      })

      errorData = errorData.filter(item => item !== null)
      if (errorData.length > 0) {
        this.isHasErrorData = true;
        this.dialogNotification = true;
        this.messageNotification = '登録/申請しようとしている排出量データのなかに、必須項目が入力されていないデータが存在します。ハイライトされた行を確認して、排出量データを修正してください。';
      } else {
        this.isHasErrorData = false;
      }
    },
    submitAction() {
      if (this.confirmQuestionText === '削除') {
        this.deleteAllDataHandler();
      } else if (this.confirmQuestionText === '保存') {
        if (this.dataPattern.workflow_data?.id && this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_APPROVED) {
          this.updateApprovedDataHandler();
        } else {
          this.updateTemplateDataHandler();
        }
      } else if (this.confirmQuestionText === '申請を取り消す') {
        this.handleCancelSubmit();
      } else if (this.confirmQuestionText === '表示') {
        this.saveTemplateRegisterDataHandler();
      } else if (this.confirmQuestionText === '登録') {
        this.saveDraftDataHandler();
      }
    },
    submitTemplateAction() {
      if (this.isCloneTemplate) {
        this.dialogClone = true;
      } else {
        this.dialogQuestion = true;
        this.messageQuestion = 'テンプレートとして保存しました。テンプレート一覧を表示しますか？';
        this.confirmQuestionText = '表示';
      }
    },
    updateApprovedDataHandler() {
      // this.dialogQuestion = false;
      const updatedData = this.pattern.editDataRegisterOnTable(this.dataTable, this.itemsInView);
      if (updatedData.length) {
        updateListData(this.pattern.patternUrl, updatedData)
      }
    },
    saveDraftDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle,
        template_type: null,
        month: this.month,
        year: this.yearSelected,
      };
      let payload = {}
      if (this.dataPattern.workflow_data?.id && this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_DRAFT) {
        // update draft
        const addedData = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView));
        const updatedData = this.pattern.editDataRegisterOnTable(this.dataTable, this.itemsInView);
        const deletedData = this.pattern.removeDataRegisterOnTable(this.dataTable);
        payload = {
          data: {
            add: addedData,
            update: updatedData,
            delete: deletedData,
          },
          dataWorkflow: dataWorkflow
        }
        updateTemplateData(payload).then(() => {
          this.dialogNotification = true;
          this.messageNotification = '変更内容を保存しました。';
        });
        return;
      }
      let draftData = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView));
      // clone draft from template
      if (this.dataPattern.workflow_data?.id && this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
        delete dataWorkflow.id;
        draftData = this.cloneDataHandler();
      } else if (this.dataPattern.workflow_data?.id && (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN || this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_RETURN)) {
        dataWorkflow.id = this.dataPattern.workflow_data?.id;
        draftData = this.cloneDataHandler();
      }
      payload = {
        action: 'saveDraft',
        data: draftData,
        dataWorkflow: dataWorkflow
      }
      this.dialogQuestion = false;
      // Call API SUBMIT
      this.addRegisterDataWorkFollow(payload).then(() => {
        this.dialogNotification = true;
        if (this.dataPattern.workflow_data?.id && this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
          this.messageNotification = '登録データとして、「排出量の登録」のデータ一覧に保存されました。';
        }
        if (this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN || this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_RETURN) {
          this.messageNotification = '修正内容が保存され、このデータのステータスが「申請差戻」から「登録中」に変更されました。';
        }
      });
    },
    submitRegisterDataHandler(approvalFormData) {
      let dataWorkflow = {
        ...this.registerWorkflow,
        ...approvalFormData,
        month: this.month,
        year: this.yearSelected,
      };
      let submitData = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView));
      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data?.template_type !== null) {
          delete dataWorkflow.id;
        }
        submitData = this.cloneDataHandler();
      }
      const payload = {
        action: ACTION_HEADER_TABLE.SUBMIT_DATA,
        data: submitData,
        dataWorkflow: dataWorkflow
      }
      // Call API SUBMIT
      this.addRegisterDataWorkFollow(payload).then(() => {
        this.dialogNotification = true;
        this.redirectUrl = '/emissions/list-submitted';
        this.messageNotification = '登録したデータの承認申請を受付ました。\n承認者による審査結果は、登録いただいたメールアドレス宛にお知らせします。';
      });
    },
    saveTemplateRegisterDataHandler(templateType) {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        category: this.registerWorkflow.scope !== 3 ? null : this.registerWorkflow.category,
        title: templateType || sessionStorage.getItem('title'),
        template_type: 0,
        month: this.month || 1,
        year: this.yearSelected,
      };
      let payload = {
        action: 'saveTemplate',
        data: null,
        dataWorkflow: dataWorkflow
      }
      // handle for clone template
      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
          delete dataWorkflow.id;
          const [year, month] = this.durationText.split('-') || this.durationText.start_at.split('-');
          this.updateDataMonth(parseInt(month));
          this.updateYearSelected(year);
          dataWorkflow.month = parseInt(month);
          dataWorkflow.year = year;
        }
        payload.data = this.cloneDataHandler();;
      } else {
        payload.data = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView, true));
      }
      // Call API SUBMIT
      this.addRegisterDataWorkFollow(payload).then(() => {
        return this.$router.push({path: '/emissions/list-templates'});
      });
    },
    handleCancelSubmit() {
      this.dialogQuestion = false;
      const payload = {
          action : ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
          dataWorkflow: {
            id: this.dataPattern.workflow_data.id
          }
      }

      cancelSubmittedData(payload).then(() => {
        this.dialogNotification = true;
        this.messageNotification = `承認申請の取り消しをリクエストしました。
承認者が許可しましたら登録メールアドレスに通知されます。`;
      });
    },
    handleAcceptRequestCancelSubmit() {
      const payload = {
          action : ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          dataWorkflow: {
            id: this.dataPattern.workflow_data.id
          }
      }
      cancelSubmittedData(payload);
    },
    updateTemplateDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      this.dialogQuestion = false;
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle || this.dataPattern.workflow_data.title,
        template_type: 0,
        month: this.month,
        year: this.yearSelected,
      };
      const [year, month] = this.durationText.split('-') || this.durationText?.start_at.split('-');
      this.updateDataMonth(parseInt(month));
      this.updateYearSelected(year);
      dataWorkflow.month = parseInt(month);
      dataWorkflow.year = year;
      const addedData = this.pattern.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(this.pattern.addDataRegisterOnTable(this.dataTable, this.itemsInView, true));
      const updatedData = this.pattern.editDataRegisterOnTable(this.dataTable, this.itemsInView, true);
      const deletedData = this.pattern.removeDataRegisterOnTable(this.dataTable);
      const payload = {
        data: {
          add: addedData,
          update: updatedData,
          delete: deletedData,
        },
        dataWorkflow: dataWorkflow
      }

      // return;
      // Call API SUBMIT
      updateTemplateData(payload).then(() => {
        this.dialogNotification = true;
        this.messageNotification = 'テンプレートに変更内容を保存しました。';
      });
    },
    deleteAllDataHandler() {
      if (this.dataPattern.workflow_data) {
        deleteTemplate({
          workflow_id: this.dataPattern.workflow_data.id
        }).then(() => {
          if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
            return this.$router.push({path: '/emissions/list-templates'});
          }
          return this.$router.push({path: '/emissions'});
        })
      } else {
        this.dataTable.sourceCollection = [];
        this.pattern.addBlankItemsToView(this.dataTable, 10);
      }
      this.dialogQuestion = false;
    },
    approvalDataHandler(approvalFormData) {
      let status = null;
      if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
        status = this.dataPattern.workflow_data.is_approver === 1 ? APPROVAL_STATUS.APPROVER_APPROVAL : APPROVAL_STATUS.APPROVAL;
      } else {
        status = this.dataPattern.workflow_data.is_approver === 1 ? APPROVAL_STATUS.APPROVER_RETURN : APPROVAL_STATUS.RETURN;
      }
      const payload = {
        id: this.dataPattern.workflow_data.id,
        status: status,
        comment: approvalFormData.comment,
      }
      // call API to submit data
      approvalDataAction(payload).then(() => {
        this.dialogApproval= false;
        if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
          this.messageNotification = this.dataPattern.workflow_data.is_approver === 1 ? '申請を承認しました。' : '申請を代理承認しました。';
        } else {
          this.messageNotification = this.dataPattern.workflow_data.is_approver === 1 ? '申請を差し戻しました。' : '申請を代理で差し戻しました。';
        }
        this.redirectUrl = '/approval';
        this.dialogNotification = true;
      })
    },
    closeDialogSubmitData() {
      this.submitDataDialogPopup = false;
    },
    closeDialogSaveTemplate() {
      this.saveTemplateDataDialogPopup = false;
    },
    handleActionAfterScopeIdFetched() {
      this.checkScopeToConvert();
      if (this.isConvert) {
        this.listNumberItems.push('wsu_source', 'wsu_value');
      }
    },
    getMasterDb() {
      if (this.scopeId === 1 || this.scopeId === 2) {
        getListMasterDb({
          db_types: [1, 2],
          contractor_id: this.contractor,
          scope_id: this.scopeId,
          status: true,
        });
      }
      if (this.scopeId === 3) {
        switch (this.categoryId) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 6:
          case 7:
          case 9:
            getListMasterDb({
              db_types: [3],
              scope_type: `${this.scopeId + '' + this.categoryId}`,
              contractor_id: this.contractor,
              category_id: this.categoryId,
              scope_id: this.scopeId,
              status: true,
            });
            break;
          case 10:
          // case 11:
          // eslint-disable-next-line no-fallthrough
          case 14:
            getListMasterDb({
              db_types: [1, 2],
              contractor_id: this.contractor,
              category_id: this.categoryId,
              scope_id: this.scopeId,
              status: true,
            });
            break;
          case 5:
          case 12:
            getListMasterDb({
              db_types: [3, 4],
              scope_type: `${this.scopeId + '' + this.categoryId}`,
              contractor_id: this.contractor,
              category_id: this.categoryId,
              scope_id: this.scopeId,
              status: true,
            });
            break;
          case 8:
          case 13:
            getListMasterDb({
              db_types: [1, 2, 3],
              scope_type: `${this.scopeId + '' + this.categoryId}`,
              contractor_id: this.contractor,
              category_id: this.categoryId,
              scope_id: this.scopeId,
              status: true,
            });
            break;
          case 15:
          case 16:
            getListDbCustomize({
              contractor_id: this.contractor,
              category_id: this.categoryId,
              scope_id: this.scopeId,
              status: true,
            });
            break;
          default:
            break;
        }
      }
    },
    checkScopeToConvert() {
      if (this.scopeId === SCOPE.SCOPE_3) {
        switch (this.categoryId) {
          case 8:
          case 9:
          case 10:
          case 11:
          case 13:
          case 14:
          case 15:
            this.isConvert = true;
            break;
          case 16:
            this.isConvert = true;
            break;
          default:
            this.isConvert = false;
            break;
        }
      }
    },
    getHeaderFromScope() {
      this.pattern = null;
      switch (this.scopeId) {
        case 1:
          if (this.method === 1) {
            this.pattern = partern1;
          } else if (this.method === 2) {
            this.pattern = partern2;
          } else {
            this.pattern = partern3;
          }
          break;
        case 2:
          this.pattern = partern4;
          break;
        case 3:
          switch (this.categoryId) {
            case 1:
              this.pattern = partern5;
              break;
            case 2:
              this.pattern = partern6;
              break;
            case 3:
              this.pattern = partern7;
              break;
            case 4:
              if (this.method === 3) {
                this.pattern = partern9method3;
              } else if (this.method === 2 && this.methodLayer2 === 7) {
                this.pattern = partern9method1;
              } else if (this.method === 2 && this.methodLayer2 === 8) {
                this.pattern = partern9method2;
              } else if (this.method === 1 && this.methodLayer2 === 4) {
                this.pattern = partern8method1;
              } else if (this.method === 1 && this.methodLayer2 === 5) {
                this.pattern = partern8method2;
              } else if (this.method === 1 && this.methodLayer2 === 6) {
                this.pattern = partern8method3;
              } else {
                this.pattern = partern9method1;
              }
              break;
            case 5:
              this.pattern = partern11;
              break;
            case 6:
              this.pattern = partern12;
              switch (this.method) {
                case 1:
                  this.pattern = partern12method1;
                  break;
                case 2:
                  this.pattern = partern12method2;
                  break;
                case 3:
                  this.pattern = partern12method3;
                  break;
                case 4:
                  this.pattern = partern12method4;
                  break;
                case 5:
                  this.pattern = partern12method5;
                  break;
                case 6:
                  this.pattern = partern12method6;
                  break;
                case 7:
                  this.pattern = partern12method7;
                  break;
                default:
                  this.pattern = partern12method1;
                  break;
              }
              break;
            case 7:
              if (this.method === 1) {
                this.pattern = partern14Method3;
                break;
              }
              if (this.method === 2) {
                this.pattern = partern14Method4;
                break;
              }
              if (this.method === 3) {
                this.pattern = partern14Method5;
                break;
              }
              if (this.method === 4) {
                this.pattern = partern13;
                break;
              }
              if (this.method === 5) {
                this.pattern = partern14;
                break;
              }
              break;
            case 8:
              this.pattern = partern15;
              break;
            case 9:
              if (this.methodLayer2 === 1) {
                this.pattern = partern16;
              } else if (this.methodLayer2 === 2) {
                this.pattern = partern16method2;
              } else if (this.methodLayer2 === 3) {
                this.pattern = partern16method3;
              } else if (this.methodLayer2 === 4) {
                this.pattern = partern16method4;
              } else if (this.methodLayer2 === 5) {
                this.pattern = partern16method5;
              }
              break;
            case 10:
              this.pattern = partern17;
              break;
            case 11:
              this.pattern = partern18;
              if (this.method === 2) {
                this.pattern = partern18method4;
              } else if (this.method === 1 && this.methodLayer2 === 1) {
                this.pattern = partern18method1;
              } else if (this.method === 1 && this.methodLayer2 === 2) {
                this.pattern = partern18method2;
              } else if (this.method === 1 && this.methodLayer2 === 3) {
                this.pattern = partern18method3;
              }
              break;
            case 12:
              this.pattern = partern19;
              break;
            case 13:
              this.pattern = partern20;
              break;
            case 14:
              this.pattern = partern21;
              break;
            case 15:
              if (this.method === 1) {
                this.pattern = partern22;
                break;
              }
              if (this.method === 2) {
                this.pattern = partern23;
                break;
              }
              if (this.method === 3) {
                this.pattern = partern24;
                break;
              }
              if (this.method === 4) {
                this.pattern = partern25;
                break;
              }
              break;
            case 16:
              this.pattern = partern26;
              break;
            default:
              this.header = partern5.header;
              return partern5.initializeGrid;
          }
          break;
        case 4:
          this.pattern = partern27;
          break;
        default:
          this.pattern = partern25;
          break;
      }
    },
    handleBindDbCustomize(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        if (parseInt(this.scopeId) > SCOPE.SCOPE_2) {
          item.wsu_source = item?.db_customize?.source;
          item.db_customize_id_1 = item?.db_customize1?.source;
          if (this.categoryId === CATEGORY.CATEGORY_15) {
            item.db_id = item?.db_customize?.source;
          }
        } else {
          item.wsu_source = item?.db_customize?.item_name;
        }

        if (parseInt(this.categoryId) === CATEGORY.CATEGORY_16) {
          item.db_customize_id = item?.db_customize?.source;
        }

        if (parseInt(this.categoryId) === CATEGORY_4 && this.method === METHOD_3) {
          item.db_customize_id_1 = item?.db_customize1?.source;
        }
      }
    },
    handleBindDbCustomize1(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        item.db_customize_id_1 = item?.db_customize1?.source;
      }
    },
    getWidthByText(string){
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = "Source Han Sans";
      text.style.fontSize = 13 + "px";
      text.innerHTML = string + '標準値';
      const width = text.clientWidth;
      document.body.removeChild(text);
      return (width + 12) < 90 ? 90 : width + 12
      return width + 30 + 24 + 34 // width of button in header
    },
    getDataFromScope() {
      this.listDataPattern = [];
      this.dataTable = null;
      this.lastedRow = null;
      this.$store.dispatch('registerData/updateDataList', []);
      this.actionUpdateIsClearUndoStack(false);
      this.timeoutUndoStack = false;
      this.actionUpdateStatusBtn({undo: false, redo: false});
      this.updateUndoRedoClick('');
      if (this.scopeId === 3 && this.categoryId === 4 && this.method === 1 && this.methodLayer2 === null) return;
      this.showListDataPattern();
    },
    showListDataPattern() {
      this.convertDataOnTable();

      this.$store.dispatch('registerData/updateTotalEmission', this.dataPattern.total_emissions);
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.dataPattern.total_duration_emissions);
      this.$store.dispatch('registerData/updateLatestRecord', this.dataPattern.latest_record);

      this.renderDataPatternOnTable();
    },
    convertDataOnTable() {
      const layerList = {};
      const layerResponse = this.dataPattern?.layer || this.layerData;
      const layers = layerResponse.map(item => {
        let listBranch = this.$store.state.registerData.listBranch
        this.itemsInView.push(this.layerIndex[item.layer_index]);
        layerList[this.layerIndex[item.layer_index]] = item.layer_name
        return {
          header: item.layer_name,
          visible: true,
          binding: this.layerIndex[item.layer_index],
          width: this.getWidthByText(item.layer_name),
          minWidth: 90,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          dataMap: new wjGrid.DataMap(listBranch[this.layerIndex[item.layer_index]], 'key', 'value'),
          editor: new AutoComplete(document.createElement('div'), {
            itemsSource: listBranch[this.layerIndex[item.layer_index]],
            selectedValuePath: 'key',
            displayMemberPath: 'value',
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1
          }),
        }
      })
      this.layer = layers || [];

      store.dispatch('registerData/actionUpdateLayerFilter', layerList, {root: true});
        // this.updateOrderNumberDontHavePermissions(res.order_number_not_permissions)
      const listType = this.$store.state.registerData.listType.map((item) => item.key);
      const companyList = this.$store.state.registerData.listBranch?.company_name.map((item) => item.value);
      const businessList = this.$store.state.registerData.listBranch?.business_name.map((item) => item.value);
      const countryList = this.$store.state.registerData.listBranch?.country.map((item) => item.value);
      const layer3List = this.$store.state.registerData.listBranch?.layer_3.map((item) => item.value);
      const layer4List = this.$store.state.registerData.listBranch?.layer_4.map((item) => item.value);
      const layer5List = this.$store.state.registerData.listBranch?.layer_5.map((item) => item.value);
      const layer6List = this.$store.state.registerData.listBranch?.layer_6.map((item) => item.value);

      (this.dataPattern?.data || []).forEach((itemDataPattern) => {
        let filterData = {
          organizational_division: itemDataPattern?.organizational_division,
          company_name: itemDataPattern?.company_name,
          business_name: itemDataPattern?.business_name,
          country: itemDataPattern?.country,
          layer_3: itemDataPattern?.layer_3,
          layer_4: itemDataPattern?.layer_4,
          layer_5: itemDataPattern?.layer_5,
          layer_6: itemDataPattern?.layer_6,
        };
      if (typeof itemDataPattern?.branch === 'object' && itemDataPattern?.branch !== null) {
        filterData = {
          organizational_division: itemDataPattern?.branch?.organizational_division,
          company_name: itemDataPattern?.branch?.company_name,
          business_name: itemDataPattern?.branch?.business_name,
          country: itemDataPattern?.branch?.country,
          layer_3: itemDataPattern?.branch?.layer_3,
          layer_4: itemDataPattern?.branch?.layer_4,
          layer_5: itemDataPattern?.branch?.layer_5,
          layer_6: itemDataPattern?.branch?.layer_6,
        }
      }
      let newItemDataPattern = {
        ...itemDataPattern,
        organizational_division: listType.includes(filterData.organizational_division) ? filterData.organizational_division : null,
        company_name: companyList.includes(filterData.company_name) ? filterData.company_name : null,
        business_name: businessList.includes(filterData.business_name) ? filterData.business_name : null,
        country: countryList.includes(filterData.country) ? filterData.country : null,
        layer_3: layer3List.includes(filterData.layer_3) ? filterData.layer_3 : null,
        layer_4: layer4List.includes(filterData.layer_4) ? filterData.layer_4 : null,
        layer_5: layer5List.includes(filterData.layer_5) ? filterData.layer_5 : null,
        layer_6: layer6List.includes(filterData.layer_6) ? filterData.layer_6 : null,
      };

      this.listNumberItems.forEach((itemDataPattern) => {
        if (itemDataPattern in newItemDataPattern && this.methodLayer2 !== 3 && this.methodLayer2 !== 4) {
          newItemDataPattern[itemDataPattern] =
            newItemDataPattern[itemDataPattern] === null ? null : Number(newItemDataPattern[itemDataPattern]?.toString().split(',').join('')) || 0;
        }
      });

      newItemDataPattern.type = itemDataPattern.type ? 1 : 0; // type = 1 if you use master_db
      newItemDataPattern.type_1 = itemDataPattern.type_1 ? 1 : 0; // type = 1 if you use master_db
      if (itemDataPattern?.db_customize) {
        if (this.scopeId >= 3 && this.categoryId < 16) {
          newItemDataPattern.wsu_source = Number(itemDataPattern?.db_customize?.id?.toString().split(',').join(''));
        } else {
          newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source?.toString().split(',').join('');
        }
        newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
        newItemDataPattern.wsu_value = Number(itemDataPattern?.db_customize?.value_source);
        newItemDataPattern.unit = itemDataPattern?.db_customize?.unit;

        newItemDataPattern.wsu_item_name = itemDataPattern?.db_customize?.item_name;
        newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_customize?.unit;

        // s1m1
        if (this.scopeId === 1 || this.scopeId === 2) {
          newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
        }

        if (this.scopeId === 1 && this.method === 1) {
          newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
        }

        if (this.scopeId === 2) {
          newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
        }

        if (this.scopeId === 1 && this.method === 2) {
          newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
        }

        // s3c2 cpa_unit = unit
        if (this.scopeId === 3 && this.categoryId === 2) {
          newItemDataPattern.cpa_unit = itemDataPattern?.db_customize?.unit;
        }

        // s3c3 energy_unit = unit,  energy_type = item_name
        if (this.scopeId === 3 && this.categoryId === 3) {
          newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
        }

        // s3c4m1 qd_unit = unit
        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === 4) {
          newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
          // for m3
          newItemDataPattern.value_source1 = Number(itemDataPattern?.db_customize?.value_source);
          newItemDataPattern.unit_source1 = itemDataPattern?.db_customize?.unit_source;
          if (this.methodLayer2 === 4) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
          if (this.methodLayer2 === 4) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
          }

          if (this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
        }

        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === 5) {
          newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.wsu_type = parseInt(itemDataPattern.wsu_type);
        }

        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === 6) {
          // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.travel_unit = itemDataPattern?.db_customize?.unit;
          if (this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
        }

        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === CATEGORY_7) {
          // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.te_unit = itemDataPattern?.db_customize?.unit;
          if (this.method === 2) {
            // newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
          if (this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
          }
        }

        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === 8) {
          newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.iss_unit = itemDataPattern?.db_customize?.unit;
        }

        if (this.scopeId === SCOPE.SCOPE_3 && this.categoryId === 9) {
          newItemDataPattern.activity_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
          if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
        }

        if (this.scopeId === 3 && this.categoryId === 10) {
          newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
        }

        if (this.scopeId === 3 && this.categoryId === 11) {
          newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
        }

        if (this.scopeId === 3 && this.categoryId === 12) {
          newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.wsu_type = parseInt(itemDataPattern.wsu_type);
        }

        if (this.scopeId === 3 && this.categoryId === 13) {
          newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
        }

        if (this.scopeId === 3 && this.categoryId === 14) {
          newItemDataPattern.activity = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
        }

        // s3c1
        newItemDataPattern.supplier_name = itemDataPattern?.db_customize?.item_name;
        newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
        newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
        newItemDataPattern.wsu_value = itemDataPattern?.db_customize?.value_source;
        newItemDataPattern.db_id = itemDataPattern?.db_customize_id;

        //pt_s3c9
        newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
        this.handleBindDbCustomize(newItemDataPattern, itemDataPattern?.db_customize?.id);

        if (this.scopeId === 3 && this.categoryId === 15) {
          newItemDataPattern.ie_company_unit = itemDataPattern?.db_customize?.unit;
          if (!this.idCustomizes.includes(newItemDataPattern.db_customize_id)) {
            newItemDataPattern.db_id = itemDataPattern?.db_customize?.source;
          }
        }
      }

      // handle for db master
      if (itemDataPattern?.db_master) {
        if (this.scopeId >= 3) {
          newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
          if (
            (this.categoryId === 4 && this.methodLayer2 === 4) ||
            (this.categoryId === 9 &&
              (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5))
          ) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            if (this.methodLayer2 === 4) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            }
          }
        } else {
          newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
        }

        newItemDataPattern.wsu_unit = itemDataPattern?.db_master?.unit_source;
        newItemDataPattern.wsu_value = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
        newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_master?.unit;
        newItemDataPattern.wsu_item_name = itemDataPattern?.db_master?.item_name;
        newItemDataPattern.unit = itemDataPattern?.db_master?.unit;
        if (itemDataPattern?.db_master_id) {
          newItemDataPattern.value_source1 = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
          newItemDataPattern.unit_source1 = itemDataPattern?.db_master?.unit_source;
          newItemDataPattern.db_id = itemDataPattern?.db_master_id;
        }

        if (this.scopeId === 1 || this.scopeId === 2) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
        }
        if (this.scopeId === 1 && this.method === 1) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
        }
        if (this.scopeId === 2) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
        }
        if (this.scopeId === 1 && this.method === 2) {
          newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id?.toString().split(',').join('');
        }
        if (this.scopeId === 1 && this.method === 1) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
        }
        if (this.scopeId === 2) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
        }
        // s3c4
        if (this.scopeId === 3 && this.categoryId === 4) {
          if (this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
          }
        }
        if (this.scopeId === 3 && this.categoryId === 6) {
          // newItemDataPattern.transportation_facilities = itemDataPattern?.db_master?.item_name;
          newItemDataPattern.travel_unit = itemDataPattern?.db_master?.unit;
          if (this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
          }
        }
        // s3c7
        if (this.scopeId === 3 && this.categoryId === 7) {
          if (this.method === 2) {
            // newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
          }
          if (this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
          }
        }

        if (this.scopeId === 3 && this.categoryId === 9) {
          newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
          if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
          }
        }
        if (this.scopeId === 3 && this.categoryId === 11) {
          newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
        }
      } else {
        delete itemDataPattern.db_master_id;
        delete newItemDataPattern.db_master_id;
      }

      if (itemDataPattern?.db_customize1) {
        // for pattern10
        newItemDataPattern.value_source2 = parseFloat(itemDataPattern?.db_customize1?.value_source);
        newItemDataPattern.unit_source2 = itemDataPattern?.db_customize1?.unit_source;
        this.handleBindDbCustomize1(newItemDataPattern, itemDataPattern?.db_customize1?.id);
      }

      if (itemDataPattern?.db_master1) {
        // for pattern10
        newItemDataPattern.value_source2 = this.formatValueSourceNumber(itemDataPattern?.db_master1?.value_source);
        newItemDataPattern.unit_source2 = itemDataPattern?.db_master1?.unit_source;
        newItemDataPattern.db_customize_id_1 = itemDataPattern?.db_master_id_1;
      }

      if (this.categoryId === 16) {
        newItemDataPattern.wsu_source = itemDataPattern?.wsu_source;
        newItemDataPattern.wsu_unit = itemDataPattern?.wsu_unit;
        newItemDataPattern.wsu_value = Number(itemDataPattern?.wsu_value);
      }

      // convert wsu_source to number
      if (this.categoryId === 2) {
        newItemDataPattern[itemDataPattern] = parseInt(newItemDataPattern[itemDataPattern]);
      }

      // newItemDataPattern.emissions = Number(itemDataPattern.emissions);
      newItemDataPattern.wsu_type = parseInt(itemDataPattern.wsu_type);
      if (this.scopeId === 1 && this.method === 2) {
        newItemDataPattern.wsu_source = itemDataPattern.db_customize_id || itemDataPattern.db_master_id;
      }

      // save by cell s3c1
      if (this.scopeId === 3 && this.categoryId === 1) {
        newItemDataPattern.emissions = newItemDataPattern.emissions === null ? 'N/A' : newItemDataPattern.emissions;
      }

      this.listDataPattern.push(newItemDataPattern);
    });
    },
    renderDataPatternOnTable() {
        const uniqueIds = new Set();
        // case: don't use db
        if (this.categoryId >= 14 || this.scopeId === 4) {
          this.originalData = this.listDataPattern.filter((element) => {
            const isDuplicate = uniqueIds.has(element.id);
            uniqueIds.add(element.id);
            return !isDuplicate;
          });
          this.initialView();
          this.$nextTick(() => {
            this.scrollToTop();
            this.timeoutUndoStack = true;
          });
          return;
        }

        let count = 0;
        let intervalCheckDbMaster = setInterval(() => {
          if ((count >= 300 || this.$store.getters['registerData/dbCustomizesOrDbMasters'].length) || (count >= 10 && this.listDataPattern.length === 0)) {
            this.originalData = this.listDataPattern.filter((element) => {
              if (this.scopeId === 3 && this.categoryId === 13 && this.method === 1) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 1 && this.categoryId === 1 && this.method === 1) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 1 && this.categoryId === 1 && this.method === 2) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 2 && this.categoryId === 1 && this.method === 1) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 1) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 2) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 3) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 4 && this.method === 1 && this.methodLayer2 === 4) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 4 && this.method === 1 && this.methodLayer2 === 5) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 4 && this.method === 2 && this.methodLayer2 === 7) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 4 && this.method === 2 && this.methodLayer2 === 8) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 4 && this.method === 3) {
                const newID = makeNewId(element.db_id, element.type);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.db_id = element.db_customize?.source;
                } else {
                  element.db_id = newID;
                }

                const newID1 = makeNewId(element.db_customize_id_1, element.type_1);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID1]) {
                  element.db_customize_id_1 = element.db_customize1?.source;
                } else {
                  element.db_customize_id_1 = newID1;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 5) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 1) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 2) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 4) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 5) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 6) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 6 && this.method === 7) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 7 && this.method === 1) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 7 && this.method === 2) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 7 && this.method === 4) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 7 && this.method === 5) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 8) {
                const newID = makeNewId(element.wsu_source, element.type);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 9 && this.method === 1 && this.methodLayer2 === 1) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 9 && this.method === 1 && this.methodLayer2 === 2) {
                const newID = makeNewId(element.wsu_source, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 9 && this.method === 2 && this.methodLayer2 === 4) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 9 && this.method === 2 && this.methodLayer2 === 5) {
                const newID = makeNewId(element.energy_type, element.type);

                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.energy_type = element.db_customize?.source;
                } else {
                  element.energy_type = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 10) {
                const newID = makeNewId(element.wsu_source, element.type);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 11) {
                const newID = makeNewId(element.wsu_source, element.type);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              if (this.scopeId === 3 && this.categoryId === 12) {
                const newID = makeNewId(element.wsu_source, element.type);
                if (!this.$store.getters['registerData/getDbCustomizeOrDbMasterById'][newID]) {
                  element.wsu_source = element.db_customize?.source;
                } else {
                  element.wsu_source = newID;
                }
              }

              const isDuplicate = uniqueIds.has(element.id);

              uniqueIds.add(element.id);

              return !isDuplicate;
            });

            this.initialView();
            this.$nextTick(() => {
              this.scrollToTop();
              this.timeoutUndoStack = true;
            });
            clearInterval(intervalCheckDbMaster);
            return;
          }

          count++;
        }, 100);
    },
    validateNumberField(input) {
      if (input) {
        let numberData = input.replace(/[^\d.]/g, '');
        if (Number(input) < 0) {
          return '正の値で入力してください。';
        }
        if (numberData && !(input.length - numberData.length)) {
          return null;
        } else {
          return '数字で入力してください。';
        }
      } else {
        return 'هذا إلزامي.';
      }
    },
    getError(item, propName, parsing) {
      if (
        this.scopeId < 3 ||
        this.scopeId === 4 ||
        (this.scopeId === 3 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(this.categoryId))
      ) {
        // validate row if has data
        const itemsInView = this.pattern.notNullInView(this.itemsInView)
        if (itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return null;
        }
        return this.pattern?.getError(item, propName);
      } else {
        // for s1m2
        if (
          (propName === "target_model" || propName === "loading_data_maximum" || propName === "loading_data_rate") &&
          this.scopeId === 1 &&
          this.method === 2
        ) {
          if (item[propName] === null || item[propName] === "") {
            return "هذا إلزامي.";
          }
        }

        // for s1m3
        if ((propName === "distance" || propName === "fuel_efficiency") && this.scopeId === 1 && this.method === 3) {
          if (item[propName] === null || item[propName] === "") {
            return "هذا إلزامي.";
          }
        }

        if (propName === "fuel") {
          if (!item[propName]) {
            return "هذا إلزامي.";
          }
        }

        // for s3c6
        if (propName === "transportation_facilities" && this.scopeId === 3 && this.categoryId === 6) {
          if (!item[propName]) {
            return "هذا إلزامي.";
          }
        }

        // for s3c7
        const itemReqinS3c7 = [
          "employees_number_value",
          "working_days_number_value",
          "transportation",
          "distance",
          "passengers",
          "value",
          "fuel_efficiency",
          "nights",
          "business_trip_days"
        ];
        if (
          itemReqinS3c7.includes(propName) &&
          this.scopeId === 3 &&
          (this.categoryId === 7 || this.categoryId === 6)
        ) {
          if (!item[propName] && item[propName] !== 0) {
            return "هذا إلزامي.";
          }
        }

        // for s3c9
        if (propName === "value" && this.scopeId === 3 && this.categoryId === 9) {
          if (item[propName] === null || item[propName] === "") {
            return "هذا إلزامي.";
          }
        }
        if (
          (propName === "distance" ||
            propName === "loading_data_maximum" ||
            propName === "loading_data_rate" ||
            propName === "fuel_efficiency") &&
          this.scopeId === 3 &&
          this.categoryId === 9
        ) {
          if (item[propName] === null || item[propName] === "") {
            return "هذا إلزامي.";
          }
        }

        // for s3c14
        if (propName === "scale_index_source" && this.scopeId === 3 && this.categoryId === 14) {
          if (item[propName] === null || item[propName] === "") {
            return "هذا إلزامي.";
          }
        }
        if (propName === "emissions" && this.scopeId === 3 && this.categoryId === 14) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return "هذا إلزامي.";
          }
        }

        // for s3cc15m1
        if (
          (propName === "ie_company_value" || propName === "ir_release_quantity" || propName === "ir_company_hold") &&
          this.scopeId === 3 &&
          this.categoryId === 15 &&
          this.method === 1
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return "هذا إلزامي.";
          }
        }

        // for s3cc15m3
        if (
          (propName === "ie_company_value" ||
            propName === "ir_total_investment" ||
            propName === "ir_internal_investment") &&
          this.scopeId === 3 &&
          this.categoryId === 15 &&
          this.method === 3
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return "هذا إلزامي.";
          }
        }

        // for s3cc15m4
        if (
          (propName === "ie_company_value" ||
            propName === "ir_calculated_denominator" ||
            propName === "ir_calculation_numerator") &&
          this.scopeId === 3 &&
          this.categoryId === 15 &&
          this.method === 4
        ) {
          if (item[propName] != null) {
            return this.validateNumberField(String(item[propName]));
          } else {
            return "هذا إلزامي.";
          }
        }

        // validate for only distance_value in  s3c3 &&  aoa_value in s3c16
        if (
          (propName === "distance_value" && this.method === 3 && this.scopeId === 3 && this.categoryId === 4) ||
          propName === "aoa_value" ||
          (propName === "wsu_value" && this.categoryId === 16)
        ) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return "هذا إلزامي.";
          }
        }

        // validate for only value in s1m2 m3
        if (propName === "value" && this.scopeId === 1 && (this.method === 2 || this.method === 3)) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return "هذا إلزامي.";
          }
        }

        // validate for only energy_value in s3c9
        if (propName === 'energy_value' && this.scopeId === 3 && this.categoryId === 9) {
          if (item[propName] != null && String(item[propName]).length <= 20) {
            return this.validateNumberField(String(item[propName]));
          } else if (item[propName] != null && String(item[propName]).length > 20) {
            return `20文字以内で入力してください。`;
          } else {
            return 'هذا إلزامي.';
          }
        }

        if (propName === 'activity' && this.categoryId === 14) {
          return validateMaximumCharactor(item[propName]?.toString(), 128, propName);
        }

        if (propName === 'ie_year' && this.categoryId === 15) {
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
        }

        if (listNumbers.includes(propName) && parsing) {
          if (item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return this.validateNumberField(String(item[propName]));
        }

        if (maximum10WithoutRequired.includes(propName) && item[propName]) {
          let numberData = String(item[propName]).replace(/[^\d.]/g, '');
          if (listNumbers.includes(propName)) {
            if (item[propName] && item[propName] < 0) {
              return '正の値で入力してください。';
            } else if (String(item[propName]).length > 10) {
              return `10文字以内で入力してください。`;
            } else if (numberData && String(item[propName]).length - numberData.length === 0) {
              return null;
            } else {
              return '数字で入力してください。';
            }
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
        }

        if (maximum20WithoutRequired.includes(propName) && item[propName]) {
          let numberData = String(item[propName]).replace(/[^\d.]/g, '');
          if (listNumbers.includes(propName)) {
            if (item[propName] && item[propName] < 0) {
              return '正の値で入力してください。';
            } else if (String(item[propName]).length > 20) {
              return `20文字以内で入力してください。`;
            } else if (numberData && String(item[propName]).length - numberData.length === 0) {
              return null;
            } else {
              return '数字で入力してください。';
            }
          }
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
        }

        if (maximum128WithoutRequired.includes(propName)) {
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
        }

        if (listNumbers.includes(propName)) {
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return null;
        }

        if (maximum128.includes(propName)) {
          // activity wsu_source in s3c16 no validate
          if ((propName === 'activity' || propName === 'wsu_source') && this.categoryId === 16) {
            return null;
          }
          if (this.scopeId === 4 && propName === 'dir_date' && !!isNaN(new Date(item[propName]).getTime())) {
            return '日付形式（YYYY/M/D）で入力してください。';
          }
          return validateMaximumCharactor(item[propName]?.toString(), 128, propName);
        }
        if (maximum20.includes(propName)) {
          // wsu_unit wsu_value in s3c16 no validate
          if (propName === 'wsu_unit' && this.categoryId === 16) {
            return null;
          }
          if (item[propName] && item[propName] < 0) {
            return '正の値で入力してください。';
          }
          return validateMaximumCharactor(item[propName]?.toString(), 20, propName);
        }
        if (maximum10.includes(propName)) {
          return validateMaximumCharactor(item[propName]?.toString(), 10, propName);
        }
        // special validate
        // for value with required in s1m2
        if (propName === 'value' && this.method === 2) {
          return validateMaximumCharactor(item[propName]?.toString(), 20, propName);
        }

        if (propName === 'distance_value' && this.method === 3 && this.scopeId === 3 && this.categoryId === 4) {
          return this.validateNumberField(String(item[propName]));
        }

        return null;
      }
    },
    resetMonth() {
      this.updateDataMonth(this.startMonth);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.pattern.addBlankItemsToView(this.dataTable, 100);
        this.scrollToTop();
      } else {
        this.initialView();
        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    initialView() {
      if (this.dataTable) {
        this.originalData = this.dataTable.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      this.$store.dispatch('commonApp/actionUpdateIsSuccess', false, {root: true});
      this.dataTable = new CollectionView([...this.originalData], {
        trackChanges: true,
      });
      this.pattern.addBlankItemsToView(this.dataTable, 10);
      this.flexgrid.columnGroups = this.pattern.header(this.$store.state.registerData, this.layer);
      if (this.pattern !== null) {
        this.updateTotalEmissionsHandler();
      }
    },
    scrollToTop() {
      if (!this.flexgrid) {
        return;
      }

      let rc = this.flexgrid.cells.getCellBoundingRect(0, 0, true);
      this.flexgrid.scrollPosition = new wjcCore.Point(this.flexgrid.scrollPosition.x, -rc.top);
    },
    getDbByType(type, scopeType) {
      getListMasterDb({
        db_types: type,
        scope_type: scopeType || null,
        contractor_id: this.contractor,
        category_id: this.categoryId,
        scope_id: this.scopeId,
        status: true,
      }).then(() => {
        this.getHeaderFromScope();
        this.getDataFromScope();
        this.key++;
        this.updateIsUpdated(false);
      });
    },
    getDataWithDbCustomize() {
      getListDbCustomize({
        contractor_id: this.contractor,
        category_id: this.categoryId,
        scope_id: this.scopeId,
        status: true,
      }).then(() => {
        this.getHeaderFromScope();
        this.getDataFromScope();
        this.key++;
        this.updateIsUpdated(false);
      });
    },
    formatValueSourceNumber(number) {
      let formatNumber = '';
      if (number.includes('.')) {
        const natural = number.split('.')[0].length;
        const decimals = number.split('.')[1].length;
        if (natural + decimals + 1 <= 20) {
          return number;
        }
        formatNumber = parseFloat(number).toFixed(20 - natural - 1);
        return formatNumber;
      }
      return number;
    },
    canEffectChangeDataTable(theRowData, currentUserSection) {
      let currentTableSheet = this.$store.state.registerData;
      return (
        currentUserSection !== this.$socket.id &&
        currentTableSheet.duration === theRowData.duration_id &&
        currentTableSheet.yearSelected === theRowData.year &&
        currentTableSheet.month === theRowData.month
      );
    },
    createUndoStack() {
      this.undoStack = new UndoStack('#undoable-table', {
        maxActions: 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        },
      });
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
      this.createUndoStack();
      this.flexgrid.cellEditEnded.addHandler(() => {
        this.checkTableHasData();
        this.updateTotalEmissionsHandler();
      })
      this.flexgrid.pasted.addHandler(() => {
        this.checkTableHasData();
      })
    },
    checkTableHasData() {
      if (typeof this.pattern.notNullInView(this.itemsInView) === "object") {
        this.isHasDataTable = false;
        const itemsInView = this.pattern.notNullInView(this.itemsInView)
        this.dataTable.sourceCollection.some((item) => {
          const isExistData = itemsInView.some((field) => item[field] !== null && item[field] !== '')
          if (isExistData) {
            this.isHasDataTable = true;
            return true;
          }
        })
      }
    },
    cloneDataHandler() {
      let cloneData = [];
      const itemsInView = this.pattern.notNullInView(this.itemsInView)
      this.dataTable.sourceCollection.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return;
        }
        const data = this.pattern.handlerDataOnTable(item, this.itemsInView);
        delete data.id;
        cloneData.push(data);
      });

      return cloneData;
    },
    checkDuplicateData(data) {
      let isDuplicate = false;
      data.forEach((item, dataIndex) => {
        if (isDuplicate) {
          return;
        }
        for (let nextDataIndex = dataIndex + 1; nextDataIndex < data.length; nextDataIndex++) {
          if (_.isEqual(item, data[nextDataIndex])) {
            isDuplicate = true;
            break;
          }
        }
      })
      return isDuplicate;
    },
    redirectAfterClickBtn(action) {
      this.dialogNotification = false
      if (this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN || this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_RETURN) {
        return this.$router.go(this.$router.currentRoute);
      }
      if (action !== '') {
        return this.$router.push({path: action});
      }
    },
    updateTotalEmissionsHandler() {
      this.totalEmissions = 0;
      this.dataTable.sourceCollection.forEach((item) => {
        this.totalEmissions += (item['emissions'] !== 'N/A' && item['emissions'] !== null) ? parseFloat(item['emissions']) : 0;
      });
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.totalEmissions);
    },
  },
  computed: {
    ...mapState('registerData', [
      'month',
      'isUpdateData',
      'duration',
      'idCustomizes',
      'startMonth',
      'isUpdated',
      'totalData',
      'isClearUndoStack',
      'watchDeleted',
      'allowAddNew',
      'isFullScreen',
      'yearSelected',
    ]),
    ...mapState('commonApp', ['loadMore', 'isExpand']),
    ...mapState('emission', ['durationId']),
    ...mapState('approval', ['listApprovers']),
    ...mapState('actionsTable', ['startAction', 'idDelete']),
    ...mapGetters("newRegisterData", ["isLoadingSubmitting", "apiResponseObject"]),
    getMethodAndMonth() {
      return this.method + this.month + this.startMonth + this.isUpdated.toString();
    },
    isReadOnlyDataIsApproved() {
      if(this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_APPROVED) {
        return true;
      }
      return false;
    }
  },
  watch: {
    apiResponseObject(response) {
      if(response.message) {
        this.dialogNotification = true;
        this.submitDataDialogPopup = false;
        this.messageNotification = response.message;
      }
    },
    scopeId(valueScopeId) {
      if(valueScopeId) {
        this.handleActionAfterScopeIdFetched();
      }
    },
    getMethodAndMonth: {
      handler() {
        if (this?.dataTable) {
          this.dataTable.itemsAdded.length = 0;
          this.dataTable.itemsRemoved.length = 0;
        }

        if (this.method && (this.month || this.dataPattern?.workflow_data?.template_type !== null) && this.isUpdated) {
          if (this.scopeId === 3 && this.categoryId === 4 && this.method === 1 && this.methodLayer2 === null) return;
          getListDetailBranch();

          if (this.scopeId === 3 && this.categoryId === 11) {
            // only s3c11
            if (this.method === 2 || (this.method === 1 && this.methodLayer2 === 1)) {
              const type = [1, 2, 3];
              this.getDbByType(type, '311');
            } else {
              this.getDataWithDbCustomize();
            }
          } else {
            this.getHeaderFromScope();
            this.getDataFromScope();
            this.key++;
            this.updateIsUpdated(false);
          }
        }
      },
    },
    durationId: {
      handler() {
        if (this.method && this.month && this.isUpdated) {
          this.getHeaderFromScope();
          this.getDataFromScope();
        }
      },
    },
    isUpdateData: {
      handler() {
        if (this.method) {
          if (this.$store.state.registerData.isUpdateData) {
            getListDetailBranch();
            this.getHeaderFromScope();
            this.getDataFromScope();
            this.key++;
            this.$store.dispatch('registerData/updateStatusIsUpdateData', false);
          }
        }
      },
    },
    // TODO: Undo/redo
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },
    canUndo(newValue) {
      this.actionUpdateStatusBtn({undo: newValue, redo: this.canRedo});
    },
    canRedo(newValue) {
      this.actionUpdateStatusBtn({undo: this.canUndo, redo: newValue});
    },
  },
  beforeDestroy() {
    webStorage.set('startMonth', this.startMonth);
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }

  &.full-screen {
    position: fixed;
    top: 84px;
    right: 0;
    z-index: 99;
    height: 100vh;
    padding-right: 20 !important;
    width: 100%;
    animation: bottotop 0.2s ease-in 0s 1 normal none;
    -webkit-animation: bottotop 0.2s ease-in 0s 1 normal none;
    .category-table {
      height: 100%;
      height: calc(100vh - 84px);
      background: #fff;
    }
    &.expanded {
      width: 100%;
      .category-table {
        height: 100%;
        background: #fff;
      }
    }
    &.no-expanded {
      width: 100%;
      .category-table {
        height: 100%;
        background: #fff;
      }
    }
  }

  @include desktop {
    &.full-screen {
      position: fixed;
      top: 84px;
      right: 0;
      z-index: 99;
      height: 100vh;
      padding-right: 0 !important;
      width: 100%;
      .category-table {
        height: 100%;
        background: #fff;
        .table-toolbar {
          margin-bottom: 0 !important;
        }
      }
      &.expanded {
        width: calc(100% - 235px);
        .category-table {
          height: 100%;
          background: #fff;
        }
      }
      &.no-expanded {
        width: calc(100% - 73px);
        .category-table {
          height: 100%;
          background: #fff;
        }
      }
    }
  }
}

.category-table {
  .wj-cell-check {
    margin: unset !important;
  }
}

.main-table {
  @media (orientation: landscape) {
    @media (max-width: 1000px) {
      padding-left: 20px !important;
    }
  }
}
</style>
