<template>
  <div>
    <v-dialog
      v-model="dialogSubmitData"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="reset-padding-card-text"
    >
      <v-card>
        <v-card-title>
          <span>حدد إرسال الموافق</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="approvalForm" class="submit-popup-form" @submit.prevent="submitHandler">
            <div class="input-select">
              <v-select
                :items="listApprovers"
                item-text="name"
                item-value="id"
                v-model="approvalFormData.selected_approver"
                label="يختار"
                placeholder="يختار"
                dense
                solo
                outlined
                hide-details
                ref="approver"
                class="rtl-select"
                :menu-props="{ contentClass: 'selecting select-durations rtl-menu' }"
                :rules="requiredRule"
            ></v-select>
            <div class="error-message" v-if="showMessage">هذا إلزامي.</div>
            </div>
            <v-divider></v-divider>
            <div class="input-common">
              <div class="input-label">عنوان</div>
              <v-text-field
                v-model="approvalFormData.title"
                label="タイトルが入ります"
                placeholder="タイトルが入ります"
                dense
                solo
                hide-details
                clearable
                class="clear-data-input right-to-left text-field"
                height="28"
                
              />
              <div class="input-description">سوف ينعكس في عنوان بيانات التسجيل هذه.</div>
            </div>
          <v-divider></v-divider>

          </v-form>
          <div class="dialog-comment">
            <div class="input-label">تعليق</div>
            <v-textarea
              no-resize
              v-model="approvalFormData.comment"
              hide-details
              class="right-to-left"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
        <common-button class="left-button-popup" @action="cancelDialog" :label="`يلغي`"/>
        <common-button type="redColor" @action="submitHandler" :label="`يُقدِّم`"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';

export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    listApprovers: {
      type: Array,
      default: () => [],
    },
    isLoadingSubmitting: {
      type: Boolean,
      default: false,
    },
    dataPattern: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      showMessage: false,
      approvalFormData: {
        selected_approver: null,
        comment: '',
        title: null,
      },
      requiredRule: [(valueInput) => !!valueInput || 'هذا إلزامي.'],
    };
  },
  mounted() {
    this.approvalFormData.title = this.dataPattern?.workflow_data?.title || sessionStorage.getItem('title');
  },
  computed: {
    dialogSubmitData: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  watch: {
    'approvalFormData.selected_approver' (val) {
      if(val) {
        this.showMessage = false;
      }
    }
  },
  methods: {
    cancelDialog() {
      this.$emit('closeDialogSubmitData');
    },
    submitHandler() {
      if(this.$refs['approver'].hasError) {
        this.showMessage = true;
      }
      if (this.$refs.approvalForm.validate()) {
        this.$emit('submitHandler', this.approvalFormData);
        // this.$refs.approvalForm.reset();
      }
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('closeDialogSubmitData');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
<style lang="scss">
.submit-popup-form {
  input, .v-text-field__slot label {
    font-size: 14px !important;
  }
  .v-icon {
    &.mdi-menu-down {
      color: $monoDark;
      &.primary--text {
        color: $monoDark !important;
      }
    }
  }
}
</style>