<template>
    <div class="container-fluid padding-top-layout">
        <div class="wrap">
            <div class="wrap__block">
                <div class="wrap__block__header wrap__header">
                    <div class="wrap__block__header__content">
                        <span>ユーザー管理</span>
                        <div class="helper-icon">
                            <img
                                :src="getSettingIcon('helper-icon.svg')"
                                @mouseover="changeTooltipImage($event)"
                                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                                alt=""
                            />
                            <div
                                v-if="showTooltip"
                                class="helper-icon tooltip"
                                v-html="'システムを利用する契約者ごとのユーザーを管理します。'"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="wrap__status">
                    <div class="wrap__status__item">
                        <img src="@/assets/icons/clock.svg" alt=""/>
                        <div class="wrap__status__item__wrap">
                            <p>Last Update</p>
                            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
                        </div>
                    </div>
                    <div class="wrap__status__item">
                        <img src="@/assets/icons/userTable.svg" alt=""/>
                        <div class="wrap__status__item__wrap">
                            <p>Updated By</p>
                            <p>{{ statusUpdate.user || "" }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="category-table main-table custom-table center-table user-management"
                :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
            >
                <data-table
                    :data-source="userGrid"
                    :grid-columns="userColumn"
                    :init-grid="initGrid"
                    :rowFocus="1"
                    :totalData="totalData"
                    :isHasData="isHasData"
                    :cellInput="cellInput"
                    @changeFullScreen="onChangeFullScreen"
                    @onHandleActionTable="checkValidationData"
                />
            </div>
            <dialog-popup
                :dialog="dialogPopup"
                :message="dialogMessage"
                @submit="() => checkboxHandler(false)"
                @close="closePopup()"
            />
            <dialog-popup-err :dialog="showPopupErr" :message="dialogErrMessage" @submit="handleCloseErrPopup()"/>
            <confirm-email-popup
                :dialog="dialogEnableConfirm"
                @close="dialogEnableConfirm = false"
                @submit="handleUpdateTypeEmail"
            />
            <notification-popup :dialog="dialogNotification" :message="'変更内容を保存しました。'" @submit="dialogNotification = false" />
        </div>
    </div>
</template>
<script>
import DialogPopup from "@/components/dialogs/question-popup";
import DialogPopupErr from "@/components/dialogs/error-popup";
import ConfirmEmailPopup from "@/components/dialogs/confirm-email-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import DataTable from "@/components/category/data-table";
import * as wjGrid from "@grapecity/wijmo.grid";
import * as wjcCore from "@grapecity/wijmo";
import {CollectionView, PopupPosition, Tooltip} from "@grapecity/wijmo";
import {CellMaker} from "@grapecity/wijmo.grid.cellmaker";
import {createUserApi, getListUserApi, updateUserApi, verifyEmail} from "@/api/userManagement";
import {ROUTES} from "@/router/constants";
import {mapActions, mapState} from "vuex";
import validate, {validateBlankRowData, validateMaximumCharactor} from "@/utils/validate";
import {formatDateTime} from "@/utils/datetimeFormat";
import {AutoComplete} from "@grapecity/wijmo.input";
import {toolTipCustom} from "@/utils/tooltipCustom";
import {KEYS_CODE} from "@/constants/keyboard";
import {BLANK_ID} from "@/constants/registerData";
import debounce from "lodash/debounce";
import {UndoStack} from "@grapecity/wijmo.undo";
import {GridEditAction} from "../managements/wijmo-extends";
import {batchReplacementViewCollection, emptyPromise} from "@/concerns/registerData/wijmo.helper";
import { EMAIL_VALID } from '@/constants/status';
import { ROLE } from '@/constants/role'

export default {
    components: {
        DataTable,
        DialogPopup,
        DialogPopupErr,
        ConfirmEmailPopup,
        NotificationPopup
    },
    data() {
        return {
            breadCrumb          : [
                {
                    text    : "ホーム",
                    disabled: false,
                    href    : ROUTES.HOME_DASHBOARD
                },
                {
                    text    : "設定",
                    disabled: false,
                    href    : ROUTES.SETTING
                },
                {
                    text    : "ユーザー管理",
                    disabled: true,
                    href    : ROUTES.USER_MANAGEMENT
                }
            ],
            userGrid            : null,
            data                : [],
            userRoles           : [],
            check_status        : null,
            userColumn          : [],
            dialogPopup         : false,
            dialogMessage       : "このユーザーのアクセス権を無効にします。\n よろしいですか？",
            dialogCancelBtn     : false,
            isChecked           : null,
            countErr            : 0,
            dataTableApiFailed  : null,
            apiErr              : [],
            hdrTips             : new Tooltip({
                position   : PopupPosition.Above,
                showAtMouse: true,
                showDelay  : 600,
                cssClass   : "hdr-tip"
            }),
            statusUpdate        : {
                time: null,
                user: null
            },
            totalData           : null,
            idsUpdate           : [],
            dataRedo            : [],
            restoreSelection    : null,
            pastingData         : null,
            undoStack           : null,
            canUndo             : false,
            canRedo             : false,
            actionCount         : 0,
            dataDisabledAction  : null,
            stackUndoWhenUncheck: null,
            eventChangeStatus   : null,
            idsUpdateFailed     : [],
            listEmailValidate   : [],
            flexGrid            : null,
            emailErrFormat      : false,
            showTooltip         : false,
            showPopupErr        : false,
            dialogErrMessage    : "",
            isHasAdmin          : true,
            dialogEnableConfirm : false,
            listOriginalData    : [],
            dialogNotification: false,
            cellInput: [
                'email',
                'name',
                'role_id',
            ]
        };
    },
    computed: {
      ...mapState("userData", ["currentUser"]),
      ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
      ...mapState("registerData", ["isFullScreen"]),
      ...mapState("actionsTable", ["startAction"]),
      ...mapState("actionsTable", ["dataAction"]),

      isHasData() {
        return this.canUndo || this.canRedo;
      },
    },

    mounted() {
        this.updateBreadCrumb(this.breadCrumb);
        this.userColumn = this.getUserColumns();

        getListUserApi({
            contractor_id: this.currentUser.user.contractor_id
        }).then((userData) => {
            this.totalData = userData.data.length;
            this.getRolesName(userData.roles);
            this.data = userData.data.map((user) => {
                return {
                    ...user,
                    valid_email: this.getValueEmail(user?.valid_email)
                };
            });

            this.defineTable(this.data);
            this.statusUpdate = {
                time: userData.updated_at_latest,
                user: userData.user_updated
            };
        });
        this.createUndoStack();
    },
    watch  : {
        startAction(newValue, _) {
            if (newValue.undo) {
                this.undoStack.undo();
            } else if (newValue.redo) {
                this.undoStack.redo();
            }
        },

        canUndo(newValue) {
            this.actionUpdateStatusBtn({
                undo: newValue,
                redo: this.canRedo
            });
        },

        canRedo(newValue) {
            this.actionUpdateStatusBtn({
                undo: this.canUndo,
                redo: newValue
            });
        },
        isHasAdmin() {
            setTimeout(() => {
                if (!this.isHasAdmin) {
                    this.showPopupErr     = true;
                    this.dialogErrMessage = `このユーザーを一般に変えると、アカウント上に管理ユーザーが存在しなくなるため変更できません。`;
                } else {
                    this.data     = [];
                    this.userGrid = null;
                    this.undoStack.clear();
                    this.getUsers();
                }
            }, 100);
        }
    },
    methods: {
        ...mapActions("commonApp", ["updateBreadCrumb"]),
        ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),

        initGrid(grid) {
            grid.scrollPositionChanged.addHandler(
                debounce((s, e) => {
                    if (!this.$store.state.registerData.isFullScreen) {
                        return;
                    }

                    if (s.viewRange.bottomRow >= s.rows.length - 1) {
                        s.deferUpdate(() => {
                            const lastClientId = grid.itemsSource.itemCount;

                            for (let index = 1; index <= 100; index++) {
                                s.itemsSource.addNew(this.blankData(lastClientId + index));
                            }

                            s.itemsSource.commitNew();
                            s.itemsSource.clearChanges();
                        });
                    }
                }, 100)
            );

            document.addEventListener("keydown", (e) => {
                if (
                    (e.metaKey || e.ctrlKey) &&
                    [
                        KEYS_CODE.DOWN_ARROW,
                        KEYS_CODE.UP_ARROW,
                        KEYS_CODE.LEFT_ARROW,
                        KEYS_CODE.RIGHT_ARROW,
                        KEYS_CODE.ENTER
                    ].includes(e.keyCode)
                ) {
                    e.preventDefault();
                }
            });

            grid.hostElement.addEventListener(
                "keydown",
                (e) => {
                    // console.log('keydown: ', e);
                    if (e.metaKey || e.ctrlKey) {
                        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
                            const currentSelection = grid.selection;
                            const cellRange        = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
                            grid.selection         = cellRange;

                            // re-select after add more
                            setTimeout(() => {
                                grid.selection = cellRange;
                            }, 200);
                        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
                            const currentSelection = grid.selection;
                            grid.selection         = new wjGrid.CellRange(0, currentSelection.col);
                        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
                            const currentSelection = grid.selection;
                            grid.selection         = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
                        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
                            const currentSelection = grid.selection;
                            grid.selection         = new wjGrid.CellRange(currentSelection.row, 1);
                        }
                    }

                    if (e.keyCode === KEYS_CODE.ENTER) {
                        if (grid.selection.row === grid.rows.length - 1) {
                            const lastClientId = grid.itemsSource.itemCount;

                            grid.deferUpdate(() => {
                                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                                grid.itemsSource.commitNew();
                                grid.itemsSource.clearChanges();
                            });
                        }
                    }
                },
                false
            );

            // add tooltip
            this.flexGrid = grid;
            this.flexGrid.onSelectionChanged(null);
            grid.onSelectionChanged(null);

            grid.pasted.addHandler((s, e) => {
                grid.autoSizeColumns();
                grid.autoSizeRows(0, 0, true);
                if (e.range.row === e.range.row2) {
                    // paste one row
                    s.onItemsSourceChanged();
                }
            });

            grid.formatItem.addHandler((s, e) => {
                const colBinding        = e.panel.columns[e.col].binding;
                const colBindingTooltip = ["email", "role_id", "initial_password", "status"];
                if (!colBindingTooltip.includes(colBinding)) {
                    return;
                }
                if (e.panel == s.columnHeaders) {
                    if (colBinding === "email") {
                        toolTipCustom(
                            e,
                            "t1",
                            "Carbonixを利用するユーザーのメールアドレスをユーザーIDとして設定します。",
                            this.hdrTips
                        );
                    }

                    if (colBinding === "role_id") {
                        toolTipCustom(
                            e,
                            "t2",
                            `
                            登録したユーザーの操作権限を指定します。<br/>
                            管理：すべての画面・機能を利用可能<br/>
                            承認：一般ユーザーが利用可能な操作に加えて、申請されたデータ登録の承認が可能<br/>
                            一般：ユーザー管理・拠点管理・閲覧権限管理・登録期間設定以外の画面や機能が利用可能
                            `,
                            this.hdrTips
                        );
                    }
                    if (colBinding === "initial_password") {
                        toolTipCustom(e, "t3", "Carbonixを利用するユーザーの初期設定に必要なパスワードです。", this.hdrTips);
                    }
                    if (colBinding === "status") {
                        toolTipCustom(e, "t4", "Carbonixを利用するユーザー単位でのアクセス権限有無を設定します。", this.hdrTips);
                    }
                }
                if (e.panel == s.cells && colBinding === 'status') {
                  const validEmailValue = s.rows[e.row]._data?.valid_email;
                  if (validEmailValue !== EMAIL_VALID.CONFIRMED_EMAIL) {
                    e.cell.innerHTML = '';
                  }
                  const roleId = s.rows[e.row]._data?.role_id; 
                  if(roleId === ROLE.ADMIN) {
                    wjcCore.addClass(e.cell, "is-admin-read-only");
                  }
                }

            });
            // Checkbox handler
            grid.beginningEdit.addHandler((s, e) => {
                let column        = s.columns[e.col];
                let rowValue      = s.rows[e.row]._data;
                this.check_status = rowValue;
                if (column.binding === 'email' && rowValue.valid_email !== EMAIL_VALID.INVALID_EMAIL && (rowValue.id && rowValue.id !== BLANK_ID)) {
                  e.cancel = true;
                }

                if (column.binding !== "status") {
                  return;
                } else {
                  if (rowValue.valid_email !== EMAIL_VALID.CONFIRMED_EMAIL) {
                    return;
                  }
                }

                this.stackUndoWhenUncheck = new GridEditAction(s, e);

                if (rowValue.role_id !== ROLE.ADMIN) {
                    this.handleCheckBox(rowValue);
                }
                e.cancel = true;
            });
            grid.autoSizeColumns();
            grid?.cellEditEnded.addHandler((s, e) => {
                let newValue = s.getCellData(e.row, e.col);
                if (s.columns[e.col].binding === "role_id" && newValue === ROLE.ADMIN) {
                    s.setCellData(e.row, 6, true, false, true); 
                }
            });
        },
        getValueEmail(value) {
            if (!value && value !== false) {
                return "確認する";
            } else if (value === false) {
                return "確認中";
            } else {
                return "確認済み";
            }
        },
        getNumberValueToParams(type) {
            if (type === "確認する") {
                return null;
            } else if (type === "確認中") {
                return 0;
            } else {
                return 1;
            }
        },
        handleAddNew(data) {
            createUserApi({data: data})
                .then((res) => {
                    this.emailErrFormat = false;
                    // Format status first row
                    if (this.userGrid._view.length === 1) {
                        this.defineTable();
                    }

                    this.userGrid.itemsAdded.length = 0;
                    this.apiErr                     = [];

                    this.totalData                  = this.data.length;
                    this.userGrid.itemsAdded.length = 0;

                    this.statusUpdate = {
                        time: res.latest_record.updated_at_latest,
                        user: res.latest_record.user_updated
                    };

                    // update data after add
                    this.userGrid.deferUpdate(() => {
                        const insertedData = res.record_insert || [];
                        let index          = insertedData.length - 1;
                        let gridIndex      = this.userGrid.itemCount - 1;
                        while (index >= 0) {
                            this.userGrid.sourceCollection[gridIndex].id               = insertedData[index].id;
                            this.userGrid.sourceCollection[gridIndex].initial_password = insertedData[index].initial_password;
                            this.userGrid.sourceCollection[gridIndex].status           = insertedData[index].status;
                            index--;
                            gridIndex--;
                        }
                    });
                    // Clear undo stack after added
                    this.undoStack.clear();
                })
                .catch((err) => {
                    this.showMessage(err);
                });
        },
        defineTable(data) {
            this.userColumn         = this.getUserColumns();
            this.dataTableApiFailed = data?.map((item, index) => item && {
                ...item,
                index: index + 1
            });
            this.initialView();

            this.listEmailValidate = this.userGrid.items.filter((item) => item?.id).map((item) => item.email);

            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
        async getUsers() {
            const userData = await getListUserApi({
                contractor_id: this.currentUser.user.contractor_id
            });
            this.data      = userData.data.map((user) => {
                return {
                    ...user,
                    valid_email: this.getValueEmail(user?.valid_email)
                };
            });

            this.totalData    = userData.data.length;
            this.statusUpdate = {
                time: userData.updated_at_latest,
                user: userData.user_updated
            };
            this.getRolesName(userData.roles);
            this.data = userData.data.map((user) => {
                return {
                    ...user,
                    valid_email: this.getValueEmail(user?.valid_email),
                    _lastUpdated: new Date().valueOf()
                };
            });
            this.defineTable(this.data);
        },
        updateTypeEmailToTable(id, newValue) {
            const indexUserEditing = this.userGrid._view.findIndex(user => user.id === id);
            this.userGrid.beginUpdate();
            this.userGrid._view[indexUserEditing].valid_email = newValue;
            this.userGrid.endUpdate();
        },
        getUserColumns() {
            return [
                {
                    header      : "#",
                    binding     : "id",
                    allowSorting: false,
                    isReadOnly  : true,
                    visible     : false
                },
                {
                    header      : "ユーザーID",
                    binding     : "email",
                    minWidth    : 200,
                    maxWidth    : 980,
                    allowSorting: false,
                    isRequired  : false,
                    wordWrap    : true,
                    cssClassAll : "single-row"
                },
                {
                    header      : "ユーザー名",
                    binding     : "name",
                    minWidth    : 200,
                    maxWidth    : 980,
                    allowSorting: false,
                    isRequired  : false,
                    wordWrap    : true,
                    cssClassAll : "no-tooltip single-row"
                },
                {
                    header      : "初期パスワード",
                    binding     : "initial_password",
                    minWidth    : 200,
                    maxWidth    : 980,
                    allowSorting: false,
                    isReadOnly  : true,
                    wordWrap    : true,
                    cssClassAll : "single-row"
                },
                {
                    header          : "権限",
                    binding         : "role_id",
                    minWidth        : 200,
                    maxWidth        : 980,
                    allowSorting    : false,
                    isRequired      : false,
                    dataMap         : new wjGrid.DataMap(this.userRoles, "id", "value"),
                    dropDownCssClass: "drop-down-custom",
                    editor          : new AutoComplete(document.createElement("div"), {
                        itemsSource      : this.userRoles,
                        selectedValuePath: "id",
                        displayMemberPath: "value"
                    }),
                    wordWrap        : true,
                    cssClassAll     : "single-row"
                },
                {
                    header      : "メール存在確認",
                    binding     : "valid_email",
                    minWidth    : 200,
                    maxWidth    : 300,
                    allowSorting: false,
                    wordWrap    : true,
                    cssClass    : "btn-db",
                    cssClassAll : "no-tooltip single-row email-verify",
                    cellTemplate: CellMaker.makeButton({
                        text: "<div class=${text === \"確認する\" ? \"btn_confirm\" : text === \"確認中\"? \"btn_confirming\" : text === \"確認済み\" ? \"btn_verified\" : \"btn_empty\"}>${text}</div>",
                        click: (event, context) => this.onConfirmEmailClicking(context)
                    })
                },
                {
                    header      : "有効/無効",
                    binding     : "status",
                    minWidth    : 200,
                    maxWidth    : 300,
                    allowSorting: false,
                    dataType    : "Boolean",
                    wordWrap    : true,
                    cssClassAll : "single-row"
                }
            ];
        },
        handleCheckBox(value) {
            if (value?.status) {
                this.dialogPopup     = true;
                this.dialogMessage   = `このユーザーのアクセス権を無効にします。\n よろしいですか？`;
                this.dialogCancelBtn = true;
            } else {
                this.checkboxHandler(true);
            }
        },

        onConfirmEmailClicking(rowData) {
            if (rowData?.item?.valid_email === '確認する') {
                this.dialogEnableConfirm = true;
                this.rowEditing          = rowData;
            }
        },

        checkboxHandler(check) {
            if (this.check_status.id === undefined || !this.check_status.email || !this.check_status.role_id) {
                return;
            }
            // check role before uncheck
            if (!check && this.check_status.role_id === 3 && this.userGrid.items.filter(item => item.role_id === 3 && item.status).length <= 1) {
                this.isHasAdmin = false;
                return;
            }
            this.userGrid.beginUpdate();
            this.userGrid.currentItem.status = check;
            this.userGrid.endUpdate();
            if (this.stackUndoWhenUncheck) {
                this.undoStack.pushAction(this.stackUndoWhenUncheck);
                this.stackUndoWhenUncheck = null;
            }
            this.closePopup();
        },

        validateEmail(email) {
            if (email?.length > 0) {
                if (!validate.regexMail.test(email)) {
                    // TODO
                    this.emailErrFormat = true;
                    return "メールアドレスの形式で入力してください。";
                }
            }
            this.emailErrFormat = false;
            return null;
        },

        validateApiFailed(name, item) {
            if (!item?.id && item?.email?.length >= 1) {
                if (this.apiErr.some((item) => item.keys.includes(name))) {
                    return this.apiErr.find((element) => element.keys.includes(name)).mess;
                }
            } else {
                if (this.idsUpdateFailed.includes(item.id)) {
                    if (this.apiErr.some((item) => item.keys.includes(name))) {
                        return this.apiErr.find((element) => element.keys.includes(name)).mess;
                    }
                }
            }
            return null;
        },

        getError(item, propName) {
          if (validateBlankRowData(item, ['name', 'email', 'role_id'])) {
            return null;
          }
          switch (propName) {
            case "name":
              return validateMaximumCharactor(item[propName], 50);
            case "email":
              return (
                this.validateEmail(item[propName]) ||
                validateMaximumCharactor(item[propName], 128) ||
                this.validateEmailAlreadyExists(item) ||
                this.validateApiFailed(propName, item)
              );
            case "role_id":
              return this.emptyCell(item[propName]);
          }
          return null;
        },
        emptyCell(item) {
          if (item === undefined || item === null) {
            return "هذا إلزامي.";
          }
          return null;
        },
        closePopup() {
            this.dialogPopup     = false;
            this.dialogCancelBtn = true;
            this.isHasAdmin      = true;
            this.dialogMessage   = "このユーザーのアクセス権を無効にします。\n よろしいですか？";
        },
        getRolesName(roleDatas) {
            this.userRoles.length = 0;
            roleDatas.forEach((item) => {
                item.name === "admin" ? (item.name = "管理") : (item.name = "一般");
                this.userRoles.push({
                    id   : item.id,
                    value: item.name_jp
                });
            });
        },
        dateTimeFormat(dateTime) {
            return formatDateTime(dateTime);
        },
        validateEmailAlreadyExists(item) {
            if ((!item?.id || item?.id === BLANK_ID) && this.listEmailValidate.includes(item.email)) {
                return "入力されたメールアドレスは既に登録されています。";
            } else {
                if (this.idsUpdate.includes(item.id) && this.listEmailValidate.includes(item.email)) {
                    return "入力されたメールアドレスは既に登録されています。";
                }
            }
            return null;
        },
        getSettingIcon(image) {
            if (image) {
                return require(`@/assets/icons/${image}`);
            }
            return "";
        },

        changeTooltipImage(event, type) {
            if (type === "mouseleave") {
                event.target.src                        = this.getSettingIcon("helper-icon.svg");
                event.target.parentElement.style.cursor = "default";
                this.showTooltip                        = false;
            } else {
                event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
                event.target.parentElement.style.cursor = "pointer";
                this.showTooltip                        = true;
            }
        },
        blankData(clientRowId) {
            return {
                clientRowId  : clientRowId,
                id           : BLANK_ID,
                contractor_id: null,
                role_id      : null,
                name         : null,
                email        : null,
                status       : null,
                valid_email  : ""
            };
        },
        addBlankItemsToView(count) {
            const lastClientId = this.userGrid.itemCount;
            for (let index = 1; index <= count; index++) {
                this.userGrid.addNew(this.blankData(lastClientId + index));
            }

            this.userGrid.commitNew();
            this.userGrid.clearChanges();
        },
        scrollToTop() {
            if (!this.flexGrid) {
                return;
            }

            let rc                       = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
            this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
        },
        initialView() {
            if (this.userGrid) {
                this.data = this.userGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
            }

            this.userGrid = new CollectionView([...this.data], {
                trackChanges: true,
            });
            this.addBlankItemsToView(10);
            this.flexGrid.columnGroups = this.getUserColumns();
            this.listOriginalData      = JSON.parse(JSON.stringify(this.userGrid.items));
        },
        isHasSomeAdmin(dataList) {
            return dataList.some(item => item.role_id === 3 && item.status === true && item.valid_email === '確認済み');
        },
        handleUpdateTypeEmail() {
            const rowData            = this.rowEditing.item;
            this.dialogEnableConfirm = false;
            if (!rowData) {
                return;
            }

            const params = {
                id           : rowData.id,
                contractor_id: rowData.contractor_id
            };
            verifyEmail(params).then(() => {
                this.updateTypeEmailToTable(rowData.id, "確認中");
            });
        },

        onChangeFullScreen(isFullScreen) {
            if (isFullScreen) {
                this.addBlankItemsToView(100);
                this.scrollToTop();
            } else {
                this.initialView();

                this.$nextTick(() => {
                    this.scrollToTop();
                });
            }
        },
        showMessage(errors) {
            this.showPopupErr     = true;
            this.dialogErrMessage = Object.keys(errors.errors)
                .map((key) => errors.errors[key])
                .join("\n");
        },
        handleCloseErrPopup() {
            this.showPopupErr = false;
            this.isHasAdmin   = true;
            this.dialogPopup  = false;
        },
        validateData(item) {
            let isValid = true;
            Object.keys(item).forEach((key) => {
                switch (key) {
                    case "name":
                        if (!item[key] || item[key].length > 50) {
                            isValid = false;
                        }
                        break;
                    case "email":
                        if (!item[key] || item[key].length > 128 || this.validateEmail(item[key]) !== null || this.validateEmailAlreadyExists(item) !== null || this.validateApiFailed(item[key], item) !== null) {
                            isValid = false;
                        }
                        break;
                    case "role_id":
                        if (this.emptyCell(item[key]) !== null) {
                            isValid = false;
                        }
                        break;
                }
            });

            return isValid;
        },
        createUndoStack() {
            this.undoStack = new UndoStack("#undoable-table", {
                maxActions  : 50,
                stateChanged: (s) => {
                    this.canUndo = s.canUndo;
                    this.canRedo = s.canRedo;
                }
            });
        },
        checkValidationData() {
            this.userGrid.getError = this.getError;
            this.submitData();
        },
        submitData() {
            let edited      = {};
            let editedBlank = {};
            let added       = {};

            let self = this;
            let indexBlank = 0;
            self.listOriginalData.forEach(function (item) {
                let newItemIndex = self.userGrid.items.findIndex(currentItem => currentItem.id === item.id);
                const indexWithBlankId = newItemIndex + indexBlank;
                let user         = self.userGrid.items[indexWithBlankId];
                if (user.status !== item.status ||
                    user.contractor_id !== item.contractor_id ||
                    user.role_id !== item.role_id ||
                    user.name !== item.name ||
                    user.email !== item.email
                ) {
                    let prepareUser = {
                        id           : user.id,
                        contractor_id: self.currentUser.user.contractor_id,
                        role_id      : user.role_id,
                        name         : user.name,
                        email        : user.email,
                        status       : user.status === null ? 1 : user.status ? 1 : 0
                    };
                    if (!self.validateData(prepareUser)) {
                        return;
                    }
                    if (!self.isHasSomeAdmin(self.userGrid.items)) {
                      self.isHasAdmin = false
                      self.userGrid.refresh()
                      return
                    }
                    if (user.id === BLANK_ID) {
                        delete prepareUser.id;
                        editedBlank[indexWithBlankId] = prepareUser;
                        indexBlank += 1
                    } else {
                        edited[indexWithBlankId] = prepareUser;
                    }
                }
            });

            this.userGrid.itemsAdded.forEach((user, index) => {
                if (!user.email || !user.name || !user.role_id) {
                    return;
                }
                let itemCustom = {
                    ...user,
                    status       : 1,
                    contractor_id: this.currentUser.user.contractor_id
                };
                if (!this.validateData(itemCustom)) {
                    return;
                }
                if (!this.isHasSomeAdmin(this.userGrid.items)) {
                  this.isHasAdmin = false
                  this.userGrid.refresh()
                  return
                }
                added[index] = itemCustom;
            });

            // call api
            const editedPromise = Object.values(edited).length ? updateUserApi({data: Object.values(edited)}).then((res) => {
                this.listOriginalData = JSON.parse(JSON.stringify(this.userGrid.items));
                this.dialogNotification = true;
                this.undoStack.clear();
            }).catch((error) => {
                this.showMessage(error);
            }) : emptyPromise();

            const editedBlankPromise = Object.values(editedBlank).length ? createUserApi({data: Object.values(editedBlank)}).then((res) => {
                this.afterDataSuccess(this.userGrid.itemsEdited, editedBlank, res);
            }).catch((error) => {
                this.showMessage(error);
            }) : emptyPromise();

            const addedPromise = Object.values(added).length ? createUserApi({data: Object.values(added)}).then((res) => {
                this.afterDataSuccess(this.userGrid.itemsAdded, added, res);
            }).catch((error) => {
                this.showMessage(error);
            }) : emptyPromise();

            Promise.all([editedPromise, editedBlankPromise, addedPromise]);
        },
        afterDataSuccess(collection, items, response) {
          const addedReplacement = {
              id              : "id",
              status          : "status",
              initial_password: "initial_password",
              valid_email     : "valid_email",
              contractor_id   : "contractor_id"
          };
          this.statusUpdate      = {
              time: response.latest_record.updated_at_latest,
              user: response.latest_record.user_updated
          };
          let recordInsert       = response.record_insert.map((user) => {
              return {
                  ...user,
                  valid_email: this.getValueEmail(user?.valid_email)
              };
          });
          this.userGrid.deferUpdate(() => {
              batchReplacementViewCollection(collection, recordInsert, Object.keys(items), addedReplacement);
              collection.length = 0;
          });
          this.listOriginalData = JSON.parse(JSON.stringify(this.userGrid.items));
          this.dialogNotification = true;
          this.undoStack.clear();
        },
    }
};
</script>

<style lang="scss" scoped>
@import './styles/index.scss';
</style>
