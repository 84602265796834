export const DESCRIPTIONS = {
  scope1: {
    category1: {
      title: 'الانبعاثات المباشرة',
      position: 'الشركة المبلغة',
      description: '自社での燃料の使用や工業プロセスによる直接的な大気中へのGHG排出量を登録します。',
      details: {
        method1: '燃料法で算定',
        method2: 'tkm法で算定',
        method3: '燃費法で算定',
      },
    },
  },
  scope2: {
    category1: {
      title: '他社から供給されたエネルギー使用に伴う間接排出',
      position: '自社',
      description:
        '他社から供給を受けた電気や熱・蒸気等のエネルギーの使用に伴い間接的に発生していると考えられる、電気や熱・蒸気の生成段階で発生したGHG排出量を登録します。',
      details: {
        method1: '購入した電力、熱の使用に伴う間接排出',
      },
    },
  },
  scope3: {
    category1: {
      title: '購入した製品・サービス',
      position: '上流',
      description:
        '自社が購入・取得した製品またはサービスに係る資源採集〜製造までのGHG排出量を登録します。より正確にはサプライヤーごとに製造段階までのGHG排出量を把握し積み上げで登録しますが、サプライヤ毎のデータが入手できない場合は、自社が購入・取得した製品またはサービスの物量・金額データを元に排出原単位を乗じて算出します。',
      details: {
        method1: 'グループ会社外から調達した製品の上流におけるCO2排出量（OEMサービス、OEM以外も含む）',
      },
    },
    category2: {
      title: '資本財',
      position: '上流',
      description:
        '算定対象期間に購入または取得した資本財の建設・製造・輸送に伴うGHG排出量を登録します。資本財とは、長期にわたる耐用期間を持ち、製品製造、サービス提供、商品の販売・保管・輸送等を行うために使用する最終製品であり、財務会計上は固定資産として扱われるものを指します。',
      details: {
        method1: '購入または取得した資本財の建設・製造及び輸送から発生する排出量',
      },
    },
    category3: {
      title: '燃料・エネルギー関連活動',
      position: '上流',
      description:
        '報告対象年度に、自社が他社から購入した燃料、電気や熱等のエネルギーの生成過程（資源採取、生産、輸送）に伴うGHG排出量を登録します。',
      details: {
        method1: '購入した燃料及びエネルギーの上流側（資源採取、精算及び輸送）の排出',
      },
    },
    category4: {
      title: '自社への輸送・配送',
      position: '上流',
      description:
        '報告対象年度に購入した製品・サービスのサプライヤーから、自社への物流（輸送、荷役、保管）に伴うGHG排出量、または上記以外の自社で費用負担している物流サービス（輸送、荷役、保管）に伴うGHG排出量を登録します。',
      details: {
        method1:
          '調達輸送に伴う排出量を算定。国内企業からの調達のみを行っており、該当する活動は調達に伴う国内陸上輸送のCO2排出量',
        method2: '改正省エネ法の荷主責任報告値を用いる方法',
        method3: '外部事業者に委託した物流（海外輸出における海上輸送及び国外陸上輸送に伴うCO2排出量）',
      },
    },
    category5: {
      title: '事業から出る廃棄物',
      position: '上流',
      description:
        '自社の事業活動から発生する有価のものを除いた廃棄物に対する、自社以外での廃棄や処理に伴うGHG排出量を登録します。なお本カテゴリには、廃棄物の輸送に係るGHG排出量も含むことが可能です。',
      details: {
        method1:
          '自社の事業活動から発生する有価のものを除いた廃棄物に対する、自社以外での廃棄や処理に伴うGHG排出量を登録します。なお本カテゴリには、廃棄物の輸送に係るGHG排出量も含むことが可能です。',
      },
    },
    category6: {
      title: '出張',
      position: '上流',
      description:
        '自社が常時使用する従業員の出張等、業務における従業員の移動の際に使用する交通機関において、消費される燃料や電力に伴うGHG排出量を登録します。ただし、自社保有の車両等による通勤はScope1,2側で計上します。なお本カテゴリには、出張者の宿泊に伴う、宿泊施設でのGHG排出も含むことが可能です。',
      details: {
        method1: '出張に際して用いる交通機関の稼働に伴うCO2排出量',
      },
    },
    category7: {
      title: '雇用者の通勤',
      position: '上流',
      description:
        '自社が常時使用する従業員の工場・事業所への通勤時に使用する交通機関において、消費される燃料や電力に伴うGHG排出量を登録します。ただし、自社保有の車両等による通勤はScope1,2側で計上します。',
      details: {
        method1: '通勤（支給額）ベースでの交通機関の稼働に伴うCO2排出量',
        method2: '通勤（従業員数・勤務日数）ベースでの交通機関の稼働に伴うCO2排出量',
      },
    },
    category8: {
      title: '賃借リース資産',
      position: '上流',
      description:
        '自社が賃借しているリース資産の操業に伴うGHG排出量を登録します。ただし、算定・報告・公表制度に基づき、既に全て自社の事業所（工場やオフィス等）における排出として算定している場合は、Scope1,2側で計上します。',
      details: {
        method1: '賃借している資産の稼働に伴うCO2排出量',
      },
    },
    category9: {
      title: '他社への輸送・配送',
      position: '下流',
      description:
        '自社では直接費用負担をしていない、自社が販売した製品の最終消費者までの物流（輸送、荷役、保管、販売）に伴うGHG排出量を登録します。',
      details: {
        method1: '販売店の運営に伴うCO2排出量',
      },
    },
    category10: {
      title: '販売した製品の加工',
      position: '下流',
      description:
        '自社で製造した中間製品が、さらに第三者（中間加工業者や最終製品製造者等）において加工される際に発生するGHG排出量を登録します。',
      details: {
        method1: '販売した製品の加工に伴うCO2排出量',
      },
    },
    category11: {
      title: '販売した製品の使用',
      position: '下流',
      description:
        '消費者や事業者等の最終消費者による、自社が製造または販売した製品の使用時に伴うGHG排出量を登録します。①自動車やエンジン、家電のようなエネルギーを消費する製品のほか、②ガソリンや天然ガス等の燃料、③ドライアイスや消化器、肥料等のGHGを排出する製品を登録します。',
      details: {
        method1: '販売した製品の使用に伴うCO2排出量',
      },
    },
    category12: {
      title: '販売した製品の廃棄',
      position: '下流',
      description:
        '消費者や事業者等の最終消費者による、自社が製造または販売した製品本体及び製品に付属する容器包装の廃棄と処理に伴うGHG排出量を登録します。',
      details: {
        method1: '廃棄物処理・リサイクル業者への委託費用や委託量に、排出原単位を乗じることによって排出量を推計',
      },
    },
    category13: {
      title: '賃貸リース資産',
      position: '下流',
      description: '貸し出しているリース資産の稼働に伴うGHG排出量を登録します。',
      details: {
        method1: '各リース資産についての規模等を表す指標に基づき算定',
      },
    },
    category14: {
      title: 'フランチャイズ',
      position: '下流',
      description: 'フランチャイズ加盟店におけるGHG排出量を登録します。',
      details: {
        method1: '各リース資産についての規模等を表す指標に基づき算定',
      },
    },
    category15: {
      title: '投資',
      position: '下流',
      description:
        '株式投資先におけるGHG排出量や、プロジェクトファイナンスにおけるプロジェクトの生涯期間中のGHG排出量を登録します。',
      details: {
        method1: '株式投資',
        method2: 'プロジェクトファイナンスによる融資',
        method3: '債券投資',
        method4: '管理投資他',
      },
    },
    category16: {
      title: 'その他のGHG排出量',
      position: 'その他',
      description:
        '従業員や消費者の日常生活に関するGHG排出量や、組織境界に含まれない資産の使用、会議・イベント参加者の交通機関からのGHG排出量など、カテゴリ1~15では範囲となっていないGHG排出量を自由に登録します。',
      details: {
        method1: '15Categoryのなかで評価できないその他GHG排出量の登録',
      },
    },
  },
  scope4: {
    category1: {
      title: '国内外の認証排出削減量',
      position: 'その他',
      description:
        '再エネ利用・省エネなど一般的なGHG排出削減策をとった上で、どうしても削減できないGHG排出量に対し、間接的に削減する手法としてカーボンオフセット取引を行なった場合の登録をします。',
      details: {
        method1: '国内認証排出削減量及び海外認証排出削減量',
      },
    },
  },
};
