<template>
  <div class="emissions-by-category-wrapper">
    <div class="chart">
      <chart-yearly @selectedCategory="selectedCategory($event)" @onSelectCategory="onSelectCategory"></chart-yearly>
    </div>

    <div class="chart-detail">
      <chart-detail></chart-detail>
    </div>

    <div class="dialog-chart-detail">
      <chart-detail-dialog :dialog="isShowChartDetailDialog" @close="closeDialog"> </chart-detail-dialog>
    </div>
  </div>
</template>

<style lang="scss">
.emissions-by-category-wrapper {
  display: flex;
}

.chart {
  flex: 0 0 100%;
}
.chart-detail {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .chart {
    flex: 0 0 73%;
  }

  .chart-detail {
    display: block;
    flex: 0 0 26%;
  }
}
</style>

<script>
import ChartYearly from './chart/index.vue';
import ChartDetail from './detail-chart/index.vue';
import ChartDetailDialog from './detail-chart-dialog/index.vue';

export default {
  components: { ChartYearly, ChartDetail, ChartDetailDialog },
  data() {
    return {
      isShowChartDetailDialog: false,
      windowWidth: window.outerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (!window) {
      return;
    }
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isShowChartDetailYearly() {
      return this.windowWidth >= 1366;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.outerWidth;
    },
    closeDialog() {
      this.isShowChartDetailDialog = false;
    },
    selectedCategory(_) {
      this.isShowChartDetailDialog = true;
    },
    onSelectCategory($event) {
      this.$store.dispatch('dashboard/changeSelectedEmissionsByCategoryWithYearly', $event.original);
      this.$store.dispatch('dashboard/requestEmissionsByCategoryDetail');
      this.$store.dispatch('dashboard/requestEmissionsByCategoryDetailTop5');
    },
  },
};
</script>
