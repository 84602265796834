<template>
  <div class="state-wrapper" :style="{ display: isShowContent ? 'block' : 'none' }">
    <div class="state">
      <h2 class="state__title">فترة</h2>

      <div class="duration">{{ getEmissionsByPeriodDetail.duration }}</div>
    </div>

    <div class="state-summary">
      <div class="summary">
        <div class="summary__title">إجمالي الانبعاثات</div>
        <div class="content">
          <div class="data mr-2">{{ totalEmissions | numberFormat }}</div>
          <div class="unit">t-CO2</div>
        </div>
      </div>

      <div class="line line-mobile"></div>

      <div class="state-compare">
        <div class="compare-prev-yearly">
          <div class="compare-prev-yearly__title">مقارنة بالسنة المالية السابقة</div>

          <div class="content">
            <div class="data">{{ getEmissionsByPeriodDetail.rate_last_year | numberFormat }}</div>
            <div class="unit" v-show="getEmissionsByPeriodDetail.rate_last_year">%</div>
            <div class="status" v-if="status">
              <img :src="status.src" :alt="status.alt"/>
            </div>
          </div>
        </div>

        <div class="line" style="margin-right: 12px; margin-left: -12px"></div>

        <div class="compare-all-data">
          <div class="compare-all-data__title">مشاركة الموقع</div>

          <div class="content">
            <div class="pie-chart">
              <v-progress-circular
                :value="getEmissionsByPeriodDetail.rate_all ? getEmissionsByPeriodDetail.rate_all : 0"
                color="#948810"
                size="28"
                width="2"
                rotate="270"
              ></v-progress-circular>
            </div>
            <div class="data">{{ getEmissionsByPeriodDetail.rate_all | numberFormat }}</div>
            <div class="unit" v-show="getEmissionsByPeriodDetail.rate_all">%</div>

            <div class="icon-right" @click="goToDetail()" @mouseover="right1 = true" @mouseleave="right1 = false">
              <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right"/>
              <img v-else width="18" src="img/icons/rightHover.svg" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="chart-detail">
      <div class="pie-chart">
        <chart-yearly-pie :scopeData="scopeDataForPieChart"/>
      </div>

      <div class="new-detail">
        <div class="title">
          <div class="scope__title title1">انبعاثات غازات الدفيئ</div>
          <div class="scope__title title3">مشاركة الموقع</div>
        </div>
        <div class="scope">
          <div class="content">
            <div class="color" :style="{ 'background-color': scope1Color  }"></div>
            <div class="name">Scope 1</div>
          </div>
          <div class="main-data">
            <div class="scope-data">
              <div class="data">{{ getEmissionsByPeriodDetail.emission_scope1 | numberFormat }}</div>
              <div class="unit" style="margin-bottom: -5px !important">t-CO2</div>
            </div>
            <div class="scope-ratio">
              <div class="pie-chart-unit">
                <v-progress-circular
                  :value="getEmissionsByPeriodDetail.rate_scope_1 ? getEmissionsByPeriodDetail.rate_scope_1 : 0"
                  color="#948810"
                  size="22"
                  width="2"
                  rotate="270"
                ></v-progress-circular>
              </div>

              <div class="data">{{ getEmissionsByPeriodDetail.rate_scope_1 | numberFormat }}</div>

              <div class="unit" v-show="getEmissionsByPeriodDetail.rate_scope_1">%</div>

              <div class="right-icon" @click="goToDetail(1)" @mouseover="right2 = true" @mouseleave="right2 = false">
                <img v-if="!right2" width="18" src="img/icons/right.svg" alt=""/>
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right"/>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="devider-my-8"></v-divider>
        <!-- SCOPE 2 -->
        <div class="scope">
          <div class="content">
            <div class="color" :style="{ 'background-color': scope2Color }"></div>
            <div class="name">Scope 2</div>
          </div>
          <div class="main-data">
            <div class="scope-data">
              <div class="data">{{ getEmissionsByPeriodDetail.emission_scope2 | numberFormat }}</div>
              <div class="unit" style="margin-bottom: -5px !important">t-CO2</div>
            </div>
            <div class="scope-ratio">
              <div class="pie-chart-unit">
                <v-progress-circular
                  :value="getEmissionsByPeriodDetail.rate_scope_2 ? getEmissionsByPeriodDetail.rate_scope_2 : 0"
                  color="#948810"
                  size="22"
                  width="2"
                  rotate="270"
                ></v-progress-circular>
              </div>

              <div class="data">{{ getEmissionsByPeriodDetail.rate_scope_2 | numberFormat }}</div>

              <div class="unit" v-show="getEmissionsByPeriodDetail.rate_scope_2">%</div>

              <div class="right-icon" @click="goToDetail(2)" @mouseover="right3 = true" @mouseleave="right3 = false">
                <img v-if="!right3" width="18" src="img/icons/right.svg" alt=""/>
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right"/>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="devider-my-8"></v-divider>

        <!-- SCOPE 3 -->
        <div v-if="isEnableScope3" class="scope">
          <div class="content">
            <div class="color" :style="{ 'background-color': scope3Color }"></div>
            <div class="name">Scope 3</div>
          </div>
          <div class="main-data">
            <div class="scope-data">
              <div class="data">{{ getEmissionsByPeriodDetail.emission_scope3 | numberFormat }}</div>
              <div class="unit" style="margin-bottom: -5px !important">t-CO2</div>
            </div>
            <div class="scope-ratio">
              <div class="pie-chart-unit">
                <v-progress-circular
                  :value="getEmissionsByPeriodDetail.rate_scope_3 ? getEmissionsByPeriodDetail.rate_scope_3 : 0"
                  color="#948810"
                  size="22"
                  width="2"
                  rotate="270"
                ></v-progress-circular>
              </div>

              <div class="data">{{ getEmissionsByPeriodDetail.rate_scope_3 | numberFormat }}</div>

              <div class="unit" v-show="getEmissionsByPeriodDetail.rate_scope_3">%</div>

              <div class="right-icon" @click="goToDetail(3)" @mouseover="right4 = true" @mouseleave="right4 = false">
                <img v-if="!right4" width="18" src="img/icons/right.svg" alt=""/>
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
</style>

<script>
import {mapGetters} from 'vuex';
import {SCOPE_1_COLOR, SCOPE_2_COLOR, SCOPE_3_COLOR} from '../../../chart-color';
import ChartYearlyPie from './pie-chart.vue';
import {ROUTES} from '@/router/constants';

export default {
  components: {ChartYearlyPie},
  data() {
    return {
      right1        : false,
      right2        : false,
      right3        : false,
      right4        : false,
      totalEmissions: 0
    };
  },
  props   : {
    isShowContent: {
      type   : Boolean,
      default: false,
    },
  },
  watch   : {
    getEmissionsByPeriodDetail: {
      handler(getEmissionsByPeriodDetail) {
        this.totalEmissions = 0
        if (getEmissionsByPeriodDetail.total_emission)
          this.totalEmissions = this.isEnableScope3 ? getEmissionsByPeriodDetail.total_emission : (getEmissionsByPeriodDetail.total_emission - getEmissionsByPeriodDetail.emission_scope3)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      getEmissionsByPeriodDetail: 'dashboard/getEmissionsByPeriodDetail',
      isEnableScope3            : 'dashboard/getIsEnableScope3',
    }),
    status() {
      if (this.getEmissionsByPeriodDetail.up === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else if (this.getEmissionsByPeriodDetail.up === -1) {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    scopeDataForPieChart() {
      let scopeData = [
        {
          brand: 'Scope 1',
          sales: this.getEmissionsByPeriodDetail.period_emission_scope1
        },
        {
          brand: 'Scope 2',
          sales: this.getEmissionsByPeriodDetail.period_emission_scope2
        }
      ];
      if (this.isEnableScope3) {
        scopeData.push({
          brand: 'Scope 3',
          sales: this.getEmissionsByPeriodDetail.period_emission_scope3
        })
      }
      return scopeData;
    },
    scope1Color() {
      return this.scopeDataForPieChart[0]?.sales < 0 ? '#404D50' : SCOPE_1_COLOR;
    },
    scope2Color() {
      return this.scopeDataForPieChart[1]?.sales < 0 ? '#99A6A9' : SCOPE_2_COLOR;
    },
    scope3Color() {
      return this.scopeDataForPieChart[2]?.sales < 0 ? '#7C898C' : SCOPE_3_COLOR;
    }
  },
  methods : {
    goToDetail(scope) {
      this.$router.push({
        path : ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD,
        query: {
          scope      : scope,
          type       : 1,
          duration_id: this.$store.state.dashboard.selectedEmissionsByPeriod.duration_id,
        },
      });
    },
  },
};
</script>
