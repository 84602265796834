<template>
    <div class="monitoring padding-left-layout padding-top-layout padding-right-layout">
        <div class="monitoring__block">
            <div class="monitoring__block__header">
                <div class="monitoring__block__header__content">
                    <page-title title="効率的運用の補足説明" />
                </div>
            </div>
            <div class="monitoring__block__body">
                <div class="monitoring__block__body__content">
                    <h2 class="monitoring-table-title">効率的運用 レベルの例</h2>
                    <div class="monitoring-outside-table">
                        <table class="monitoring-table">
                    <thead class="monitoring-table-header">
                        <tr class="header-table">
                            <th>取組</th>
                            <th>建物用途</th>
                            <th>モニタリング</th>
                            <th>運用管理体制</th>
                        </tr>
                    </thead>
                    <tbody class="monitorng-table-body">
                        <tr class="body-table">
                            <th rowspan="2">レベル４</th>
                            <th>集合住宅</th>
                            <th>エネルギー消費に関する表示機器、負荷低減装置などを採用</th>
                            <th>設備ごとの取り扱い説明書が居住者に手渡しされており、省エネに関する一般的な説明が住人になされている。</th>
                        </tr>
                        <tr class="body-table">
                            <th>集合住宅以外</th>
                            <th>建物で消費される各種エネルギー消費量を年間に渡って把握し、消費原単位を用いてのベンチマーク比較が可能。さらにエネルギー消費量の用途別エネルギー消費の内訳が把握でき、分析や妥当性が確認できること。</th>
                            <th>運用管理体制が組織化され、責任者が指名された状態されている。さらに、年間エネルギー消費量の目標値が計画され、建築主に提出されている。</th>
                        </tr>
                        <tr class="body-table">
                            <th rowspan="2">レベル5</th>
                            <th>集合住宅</th>
                            <th>エネルギーを管理する仕組みがあり、それにより消費エネルギーの削減が可能</th>
                            <th>レベル4に加え、当該住宅の建物・生活スタイルごとに対応した適切な説明が住人になされている。</th>
                        </tr>
                        <tr class="body-table">
                            <th>集合住宅以外</th>
                            <th>レベル4に加え、主要な設備システムに関しては、システム性能の評価が可能</th>
                            <th>レベル4に加え、運用時の定期的な設備性能検証、不具合等の具体的な実施方法が計画されている。（コミッショニング）</th>
                        </tr>
                    </tbody>
                        </table>
                    </div>
                </div>
                <div class="monitoring__block__body__content">
                    <h2 class="monitoring-table-title">効率的運用 効率評価の例</h2>
                    <div class="monitoring-outside-table">
                        <table class="monitoring-table">
                    <thead class="monitoring-table-header">
                        <tr class="header-table">
                            <th>設備項目</th>
                            <th>評価項目</th>
                            <th>評価概要</th>
                        </tr>
                    </thead>
                    <tbody class="monitorng-table-body">
                        <tr class="body-table table-efficiency">
                            <th rowspan="3">熱源設備</th>
                            <th>熱源機COP評価</th>
                            <th>製造熱量/熱源機消エネルギー（1次エネルギー基準）/蓄熱槽有効蓄熱量/蓄熱槽利用効率</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>熱源システムCOP評価</th>
                            <th>製造熱量/熱源機+補機消費エネルギー（1次エネルギー基準） ※地域冷暖房導入を含む</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>熱媒搬送WTF</th>
                            <th>搬送熱量/ポンプ消費エネルギー（2次エネルギー基準）</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="4">空調設備</th>
                            <th>空調機搬送ATF</th>
                            <th>搬送熱量/ファン消費エネルギー（2次エネルギー基準）</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>全熱交換器効果</th>
                            <th>削減熱量、エネルギー量</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>外気冷房効果</th>
                            <th>削減熱量、エネルギー量</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>ビル用マルチCOP評価</th>
                            <th>個別分散空調システムの効率評価</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>換気設備</th>
                            <th>変風量制御の評価</th>
                            <th></th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>照明設備</th>
                            <th>各種制御の評価</th>
                            <th>昼光利用、人感センサーなどによる削減エネルギー量</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="3">給湯設備</th>
                            <th>熱源機COP評価</th>
                            <th>製造熱量/熱源機消費エネルギー（1次エネルギー基準）</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>熱源システムCOP評価</th>
                            <th>製造熱量/熱源機+補機消費エネルギー（1次エネルギー基準）</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>熱媒搬送WTF</th>
                            <th>搬送熱量/ポンプ消費エネルギー（2次エネルギー基準）</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>昇降機</th>
                            <th>各種管制運転効果</th>
                            <th>削減エネルギー量</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="4">その他</th>
                            <th>太陽光発電設備評価</th>
                            <th>発電効率/定格効率/年間効率</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>CGS評価</th>
                            <th>発電効率/総合効率/省エネルギー率</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>各種連携制御</th>
                            <th>セキュリティ連動による消照効果/換気停止の効果等</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>その他</th>
                            <th>空調CO2制御効果、換気CO2制御効果、ﾀｽｸｱﾝﾋﾞｴﾝﾄ空調効果、ﾀｽｸｱﾝﾋﾞｴﾝﾄ照明効果など</th>
                        </tr>
                    </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="monitoring__block__note">
                ※効率評価に関しては、機器/器具付随の制御用センサーのデータを用いた評価も可とする。
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
    name: 'monitoring',
    data(){
        return{
            items : [
                {
                    text: 'ホーム',
                    disabled: false,
                    href: ROUTES.DASHBOARD,
                },
                {
                    text: '設定',
                    disabled: false,
                    href: ROUTES.SETTING,
                },
                {
                    text: 'カスタマイズDB管理',
                    disabled: false,
                    href: ROUTES.CREATE_DATA_CUSTOMIZE,
                },
                {
                    text: 'CASBEE対応原単位の追加',
                    disabled: false,
                    href: ROUTES.CASBEE,
                },
                {
                    text: '効率的運用の補足説明',
                    disabled: true,
                    href: ROUTES.MONITORING,
                },    
            ]
        }
    },
    components: { PageTitle },
    mounted(){
    this.updateBreadCrumb(this.items);
    },
    methods: {
        ...mapActions('commonApp', ['updateBreadCrumb']),
    }
}
</script>
<style lang="scss" scoped>
.monitoring {
    &__block {
        &__header {
            &__content {
                ::v-deep .page-title__text {
                    font-family: $notoSanFont;
                }
                //padding-left: 40px;
                .monitoring-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    font-family: $notoSanFont;
                    line-height: 36px;
                    letter-spacing: 0.05em;
                    color: $goldMid;
                }
            }
        }
        &__body {
            &__content {
                //padding-left: 40px;
                padding-top: 48px;
                &:first-child {
                    padding: 0;
                }
                .monitoring-table-title {
                    font-family: $notoSanFont;
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px; 
                    color: #000000;
                }
                .monitoring-outside-table {
                    overflow-x: auto;
                    .monitoring-table {
                        width: max-content;
                        border-collapse: collapse;
                        .monitoring-table-header {
                            background: $bgLight;
                            .header-table {
                                th {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    font-family: $notoSanFont;
                                    padding: 8px 19px;
                                    line-height: 20px;
                                    color: #000000;
                                    text-align: left;
                                }
                            }
                        }
                        .monitorng-table-body {
                            background: #FFFFFF;
                            .body-table {
                                th{
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    font-family: $notoSanFont;
                                    padding: 8px 19px;
                                    line-height: 20px;
                                    color: #000000;
                                    max-width: 360px;
                                    min-width: 96px;
                                    border-width: 0px 1px 1px 0px;
                                    border-style: solid;
                                    border-color: $monoExtraLight;
                                    text-align: left;
                                    vertical-align: top;
                                    &:nth-child(2) {
                                        min-width: 128px;
                                    }
                                }
                            }
                            .table-efficiency {
                                th:nth-child(2) {
                                    min-width: 200px;
                                }
                                th:nth-child(3) {
                                    min-width: 648px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__note {
            //margin-left: 40px;
            margin-top: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            font-family: $notoSanFont;
            line-height: 20px;
            color: #000000;
        }
    }
}
</style>