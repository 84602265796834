import moment from 'moment';

export const DASHBOARD_TYPES = {
  duration: 0,
  month   : 1,
};

export const defaultEmissionByCategory = {
  scope1: {
    1: {
      icon   : './img/icons/scope-1/c1.svg',
      title  : 'الانبعاثات المباشرة',
      value  : 0,
      default: true,
    },
  },
  scope2: {
    2: {
      icon   : './img/icons/scope-2/c1.svg',
      title  : 'الانبعاثات من الكهرباء المشتراة أو المكتسبة والبخار والتدفئة والتبريد',
      value  : 0,
      default: true,
    },
  },
  scope3: {
    3 : {
      icon      : './img/icons/scope-3/c1.svg',
      iconPrefix: './img/icons/scope-3/f-1.svg',
      title     : ' السلع والخدمات المشتراة',
      value     : 0,
      default   : true,
    },
    4 : {
      icon      : './img/icons/scope-3/c2.svg',
      iconPrefix: './img/icons/scope-3/f-2.svg',
      title     : 'السلع الرأسمالية',
      value     : 0,
      default   : true,
    },
    5 : {
      icon      : './img/icons/scope-3/c3.svg',
      iconPrefix: './img/icons/scope-3/f-3.svg',
      title     : 'الأنشطة المتعلقة بالوقود والطاقة غير المدرجة في النطاق 1 أو النطاق',
      value     : 0,
      default   : true,
    },
    6 : {
      icon      : './img/icons/scope-3/c4.svg',
      iconPrefix: './img/icons/scope-3/f-4.svg',
      title     : 'المنبع النقل والتوزيع',
      value     : 0,
      default   : true,
    },
    7 : {
      icon      : './img/icons/scope-3/c5.svg',
      iconPrefix: './img/icons/scope-3/f-5.svg',
      title     : 'النفايات المتولدة في العمليات',
      value     : 0,
      default   : true,
    },
    8 : {
      icon      : './img/icons/scope-3/c6.svg',
      iconPrefix: './img/icons/scope-3/f-6.svg',
      title     : 'سفر رجال الأعمال',
      value     : 0,
      default   : true,
    },
    9 : {
      icon      : './img/icons/scope-3/c7.svg',
      iconPrefix: './img/icons/scope-3/f-7.svg',
      title     : 'يذهب أرباب العمل إلى العمل',
      value     : 0,
      default   : true,
    },
    10: {
      icon      : './img/icons/scope-3/c8.svg',
      iconPrefix: './img/icons/scope-3/f-8.svg',
      title     : 'الأصول المؤجرة المنبع',
      value     : 0,
      default   : true,
    },
    11: {
      icon      : './img/icons/scope-3/c9.svg',
      iconPrefix: './img/icons/scope-3/f-9.svg',
      title     : 'نقل وتوزيع المصب',
      value     : 0,
      default   : true,
    },
    12: {
      icon      : './img/icons/scope-3/c10.svg',
      iconPrefix: './img/icons/scope-3/f-10.svg',
      title     : 'تجهيز المنتجات المباعة',
      value     : 0,
      default   : true,
    },
    13: {
      icon      : './img/icons/scope-3/c11.svg',
      iconPrefix: './img/icons/scope-3/f-11.svg',
      title     : 'استخدام المنتجات المباعة',
      value     : 0,
      default   : true,
    },
    14: {
      icon      : './img/icons/scope-3/c12.svg',
      iconPrefix: './img/icons/scope-3/f-12.svg',
      title     : 'معالجة نهاية العمر للمنتجات المباعة',
      value     : 0,
      default   : true,
    },
    15: {
      icon      : './img/icons/scope-3/c13.svg',
      iconPrefix: './img/icons/scope-3/f-13.svg',
      title     : 'الأصول المؤجرة النهائية',
      value     : 0,
      default   : true,
    },
    16: {
      icon      : './img/icons/scope-3/c14.svg',
      iconPrefix: './img/icons/scope-3/f-14.svg',
      title     : 'الامتياز التجاري',
      value     : 0,
      default   : true,
    },
    17: {
      icon      : './img/icons/scope-3/c15.svg',
      iconPrefix: './img/icons/scope-3/f-15.svg',
      title     : 'الاستثمارات',
      value     : 0,
      default   : true,
    },
    18: {
      icon      : './img/icons/scope-3/c16.svg',
      iconPrefix: './img/icons/scope-3/f-16.svg',
      title     : 'انبعاثات أخرى',
      value     : 0,
      default   : true,
    },
  },
  scope4: {
    19: {
      icon   : './img/icons/scope-3/c16.svg',
      title  : 'その他',
      value  : 0,
      default: true,
    },
  },
};

export const mappingCategoryRowNumToScope = {
  // scope 1
  1: 'scope1',
  // scope 2
  2: 'scope2',
  // scope 3
  3 : 'scope3',
  4 : 'scope3',
  5 : 'scope3',
  6 : 'scope3',
  7 : 'scope3',
  8 : 'scope3',
  9 : 'scope3',
  10: 'scope3',
  11: 'scope3',
  12: 'scope3',
  13: 'scope3',
  14: 'scope3',
  15: 'scope3',
  16: 'scope3',
  17: 'scope3',
  18: 'scope3',
  // scope 4
  19: 'scope4',
};

export const defaultEmissionByPeriodWithYearly = () => {
  const now = moment();

  let start  = now.clone().subtract(3, 'year');
  const data = [];

  for (let i = 0; i < 3; i++) {
    data.push({
      time_range     : `${start.clone().add(i, 'year').format('YYYY/MM')} - ${start
        .clone()
        .add(i + 1, 'year')
        .format('YYYY/MM')}`,
      emission_scope1: 0,
      emission_scope2: 0,
      emission_scope3: 0,
    });
  }

  return data;
};

export const defaultEmissionByPeriodWithMonthly = () => {
  const now = moment();

  let start  = now.clone().subtract(5, 'month');
  const data = [];

  for (let i = 0; i < 5; i++) {
    data.push({
      time_range     : `${start.clone().add(i, 'month').format('YYYY/MM')}`,
      emission_scope1: 0,
      emission_scope2: 0,
      emission_scope3: 0,
    });
  }

  return data;
};

const COUNT_MONTHS = 100;
const START_YEAR   = 2019;

export const defaultDurationEmissionByCategory = () => {
  let year = START_YEAR;

  return Array.from(Array(COUNT_MONTHS)).map((_, key) => {
    const month = ((key + 4) % 12) + 1;

    if (month === 1) {
      year++;
    }

    return {
      year,
      month,
    };
  });
};

export const FILTER_DATA = {
  ORGANIZATIONAL: 'organizational_select',
  COMPANY       : 'company_select',
  COUNTRY       : 'country_select',
  BUSINESS      : 'business_select',
  LAYER_3       : 'layer3_select',
  LAYER_4       : 'layer4_select',
  LAYER_5       : 'layer5_select',
  LAYER_6       : 'layer6_select',
}

export const ORGANIZATIONAL_DATA = {
  1: '単体',
  2: '子会社',
  3: '関連会社',
  4: 'その他関連会社',
}
