<template>
  <div class="main">
    <div class="wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>مرحبًا بك في</p>
        </div>
      </div>
      <div class="right_wrap">
        <v-form class="right_block log_frm" @submit.prevent="submitForm" ref="form" lazy-validation>
          <h1 class="title-text mb-45">
            <span class="title-text-style">تسجيل الدخول</span>
          </h1>
          <div class="input-block mb-48">
            <span class="input-title">معرف المستخدم</span>
            <input id="email" class="input-elic mb-0 input-rtl" v-model="email" />
            <span class="err-mess" style="display: none" id="email_error"></span>
            <img
              id="email_img"
              class="validWarning"
              src="/img/icons/warning.svg"
              style="position: absolute; right: 6px; display: none"
            />
          </div>
          <div class="input-password-block">
            <div class="input-password-block-parts mt-0 mb-0">
              <div class="input-block">
                <span class="input-title">كلمة المرور</span>
                <div class="passBox">
                  <input
                    id="password"
                    v-model="password"
                    class="input-elic mb-0 input-rtl input-passwords"
                    :type="!showPass ? 'password' : 'text'"
                  />
                  <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
                  <span class="err-mess" style="display: none" id="password_error"></span>
                  <img
                    id="password_img"
                    class="validWarning"
                    src="/img/icons/warning.svg"
                    style="position: absolute; right: 6px; display: none"
                  />
                </div>
              </div>
              <div class="forgot-block">
                <span class="forgot-block-link">
                  هل نسيت كلمة السر؟<a class="forgot-block-link-text" @click="handleCheckLockPass">هنا</a></span
                >
              </div>
            </div>
          </div>

          <input class="btn-submit" type="submit" value="تسجيل الدخول" />
        </v-form>
      </div>
    </div>
    <error-popup :dialog="dialog" :message="message" @submit="handleClose" />

    <reset-pass-popup :dialog="showCheckTokenError" :message="errorMessage" @submit="handleCloseReset()" />
  </div>
</template>

<script>
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import ResetPassPopup from '@/components/dialogs/reset-pass-token-popup.vue';
import validate from '@/utils/validate';
import { loginApi, checkBlockAccApi, handleVerifyEmail } from '@/api/auth';
import { ROUTES } from '@/router/constants';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import { getErrorMessge } from '@/utils/messageHandle';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    showPass: false,
    dialog: false,
    message: '',
    email: '',
    emailRules: [(v) => !!v || 'error', (v) => validate.regexMail.test(v)],
    password: '',
    passwordRules: [(v) => !!v, (v) => validate.regexPassword.test(v)],

    showCheckTokenError: false,
  }),
  computed: {
    ...mapGetters({
      errorMessage: 'userData/getMessage',
    }),
  },

  mounted() {
    if (this.errorMessage) {
      this.showCheckTokenError = true;
    }
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.handleClose();
      }
    });
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search))
    const id = urlParams.get('id')
    const email = urlParams.get('email')

    // handle verify email
    if (id && email) {
      const params = {
        id: id,
        email: email
      }
      handleVerifyEmail(params)
    }
  },

  methods: {
    submitForm() {
      if (!validateRequired(['email', 'password'])) {
        return;
      }

      loginApi({ email: this.email.toLowerCase(), password: this.password.trim() })
        .then((res) => {
          if (!res.user.status) {
            this.dialog = true;
          } else {
            this.$router.push({ path: ROUTES.DASHBOARD });
          }
        })
        .catch((err) => {
          if (err.code === 'block_account') {
            this.message = err.message;
          } else {
            this.message = getErrorMessge(err);
          }
          this.dialog = true;
        });
    },
    handleClose() {
      this.password = '';
      this.email = '';
      this.dialog = false;
    },
    handleCloseReset() {
      this.showCheckTokenError = false;
      this.$store.dispatch('userData/updateMessage', '');
    },

    handleCheckLockPass() {
      if (this.email && validate.regexMail.test(this.email)) {
        checkBlockAccApi({ email: this.email })
          .then(() => {
            this.$router.push({ path: '/auth/forgot-password' });
          })
          .catch((err) => {
            this.message = getErrorMessge(err);
            this.dialog = true;
          });
      } else {
        this.$router.push({ path: '/auth/forgot-password' });
      }
    },
  },
  components: {
    ErrorPopup,
    EyeIcon,
    ResetPassPopup,
  },
  beforeDestroy() {
    document.removeEventListener('keyup');
  },
};
</script>

<style lang="scss" scoped>
@import './LoginStyle/index.scss';

.while-color {
  background: #ffffff;
}
.none {
  display: none;
}

.block {
  display: block;
}
.forgot-block {
  margin-top: 16px;
}
.btn-submit {
  margin-top: 51px;
}
.wrap {
  min-height: 270px;
  overflow: auto;
}
</style>
