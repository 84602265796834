import request from "./base/request";

const DURATION_SERVICE_URL = "/duration-setting";

const durationSettingApi = (data, options = {}) => {
  return request
    .post(DURATION_SERVICE_URL, data, { headers: options })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const updateDurationApi = (data) => {
  return request.put(DURATION_SERVICE_URL, data);
};

const getDurations = (params) => {
  return request.get(DURATION_SERVICE_URL, { params });
};

const getSetDuration = (contractorId) => {
  return request.get(DURATION_SERVICE_URL + `/${contractorId}`);
};

export { durationSettingApi, getDurations, getSetDuration, updateDurationApi };
