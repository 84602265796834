export const LIST_LEVEL_EVALUATE = [
        {
            name:'水消費に係るCO2排出量を考慮しない',
            value:0,
        },
        {
            name: "0",
            value: 115,
        },
        {
            name: "1",
            value: 100,  
        },
        {
            name: "2",
            value: 85,   
        },
        {
            name: "3",
            value: 70,  
        },
]