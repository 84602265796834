import * as wjGrid from '@grapecity/wijmo.grid';
import store from '@/store';
import {AutoComplete} from '@grapecity/wijmo.input';
import {KEYS_CODE} from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
} from "@/constants/registerData";
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, validateNumberField, math, formatBigNumber, formatEmission20Number } from './wijmo.helper'
import {CellEditEndingEventArgs, CellRange} from '@grapecity/wijmo.grid'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, listeningEventWhenPasted, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import {prepareMasterOrCustomizeDB} from '@/concerns/utils/master-or-customize-db'
import { validateMaximumCharactorOnlyFacility } from "@/utils/validate";
const NA_VALUE = 'N/A';

// fake services
const services = [
  {
    item_name: 'OEMサービス',
  },
  {
    item_name: '非OEMサービス',
  },
];

export const blankData = (orderNumber) => {
  return {
    clientRowId: orderNumber,
    id: BLANK_ID,
    business_name: null,
    company_name: null,
    organizational_division: null,
    qd_unit: null,
    qd_source: null,
    qd_value: null,
    supplier_name: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    duration_id: null,
    month: null,
    emissions: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    order_number: orderNumber
  }
}

const calculatorEmissions = (valueSource, valueEmission) => {
  if($_helper_isNumberType(valueSource) && $_helper_isNumberType(valueEmission)){
    const valSource=  math.bignumber(formatValue(valueSource));
    const valSale= math.bignumber(formatValue(valueEmission))
    return  formatBigNumber(math.evaluate(`${valSource.toString()} * ${valSale.toString()}`))
  }

  return 0
}

const notNullList = [
  "organizational_division",
  "company_name",
  "supplier_name",
  "qd_unit",
  "wsu_value",
  "wsu_unit",
  "wsu_source",
  "qd_value"
];

const max20Charactor = [
  "qd_unit",
  "qd_value",
  // "wsu_value",
  "wsu_unit"
];
const max128Charactor = [
  "company_name",
  "business_name",
  "supplier_name",
  "qd_source",
  "memo_1",
  "memo_2"
];

const numberField = [
  "qd_value"
];
let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []
const partern5 = {
  initializeGrid(flexgrid) {
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((flexGrid) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (flexGrid.viewRange.bottomRow >= flexGrid.rows.length - 1) {
        flexGrid.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let numberBlankItem = 1; numberBlankItem <= 100; numberBlankItem++) {
            flexGrid.itemsSource.addNew(blankData(lastClientId + numberBlankItem));
          }

          flexGrid.itemsSource.commitNew();
          flexGrid.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (event) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(event.keyCode)
      ) {
        event.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (event) => {
      if (event.metaKey || event.ctrlKey) {
        if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (event.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }
    }, false)

    flexgrid.pasted.addHandler((flexGrid, event) => {
      const {col, col2, row, row2} = event.range
      const view = flexGrid.collectionView
      const source = view.sourceCollection
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(flexGrid.columns[colIndex].binding)) {
            if (flexGrid.getCellData(rowIndex, colIndex, false) !== null || flexGrid.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(flexGrid.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          const columnNumber = ['wsu_source', 'qd_value']
          if (columnNumber.includes(flexGrid.columns[colIndex].binding)) {
            const cellData = flexGrid.getCellData(rowIndex, colIndex, false)
            const cEvent = new CellEditEndingEventArgs(flexGrid, new CellRange(rowIndex, colIndex), cellData)
            cEvent.custom = true
            flexGrid.onCellEditEnded(cEvent)
          }
        }
      }

      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((flexGrid, event) => {
      let column = flexGrid.columns[event.col];
      const {row, col} = event.range
      const cellData = flexGrid.getCellData(row, col, false)
      const view = flexGrid.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (column.binding === 'qd_value') {
        flexGrid.deferUpdate(() => {
          const emissions = calculatorEmissions(currentItem.wsu_value, currentItem.qd_value)
          flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'emissions'), emissions, false, true)
        })
      }

      if (column.binding === 'wsu_source') {
        const dbIndex = store.getters['registerData/dbCustomizesOrDbMasters'].findIndex(item => item.id === cellData);
        flexGrid.deferUpdate(() => {
          if (dbIndex >= 0) {
            const dbItem = store.getters['registerData/dbCustomizesOrDbMasters'][dbIndex] || {}
            currentItem.db_index = dbIndex

            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_source'), dbItem.id, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'supplier_name'), dbItem.item_name, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'qd_unit'), dbItem.unit, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_value'), dbItem.value_source, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_unit'), dbItem.unit_source, false, true)

            const emissions = calculatorEmissions(currentItem.wsu_value, currentItem.qd_value)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'emissions'), emissions, false, true)
          } else {
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_source'), null, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'supplier_name'), null, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'qd_unit'), null, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_value'), null, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'wsu_unit'), null, false, true)
            flexGrid.setCellData(row, getColumnIndexByBinding(flexGrid, 'emissions'), calculatorEmissions(currentItem.wsu_value, currentItem.qd_value), false, true)
          }
        })
      }

      if (column.binding === 'supplier_name') {
        let cell_item = services.find((data) => data.name === currentItem.supplier_name);

        if (!cell_item && currentItem.supplier_name !== '') {
          // todo: handle api add supplier_name here
          services.push({
            id: services.length,
            name: flexGrid.activeEditor?.value,
          });
        }
      }

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, flexGrid, filterIndex, selectedFirst, previousCellData, isReverted);
      }

      // for (let index = 0; index < 1260; index++) {
      //   flexGrid.setCellData(index, getColumnIndexByBinding(flexGrid, 'qd_source'), 'qd_source_' + index, false, true)
      // }

    });

    flexgrid.beginningEdit.addHandler((flexGrid, event) => {
      let column = flexGrid.columns[event.col];
      let item = flexGrid.rows[event.row].dataItem;
      // auto ++ serial number
      if (!item.index) {
        item.index = flexgrid?.rows?.length - 1 || 1;
      }

      if (
        column.binding === 'qd_unit' ||
        column.binding === 'wsu_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'emissions' ||
        column.binding === 'supplier_name'
      ) {
        event.cancel = true;
      }

      const {row, col} = event.range
      const cellData = flexGrid.getCellData(row, col, false)
      const view = flexGrid.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, flexGrid, filterIndex, selectedFirst, store.state.registerData);
      }
    });

    listeningEventWhenPasted(flexgrid, filterIndex)
  },

  header(registerData, listLayout) {
    let companyMap = new wjGrid.DataMap(registerData.listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(registerData.listBranch.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
      },
      {
        header: '組織区分',
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 140,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      {
        header: '法人名',
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listBranch?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      ...listLayout,
      {
        header: '購入した製品・サービス名', // Tên service・ sản phẩm đã mua
        binding: 'supplier_name',
        minWidth: 200,
        maxWidth: 950,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClass: 'auto-increment',
        multiLine: true
      },
      {
        header: '備考1', // memo1
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '備考2', // memo2
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '物量・金額データ',
        align: 'center',
        columns: [
          {
            header: '数値',
            binding: 'qd_value',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
          },
          {
            header: '単位',
            binding: 'qd_unit',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: 'ソース',
            binding: 'qd_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
          },
        ],
      },
      {
        header: '排出原単位',
        align: 'center',
        columns: [
          {
            header: '数値',
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
          },
          {
            header: '単位',
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: '出典',
            binding: 'wsu_source',
            minWidth: 320,
            maxWidth: 620,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            dataMap: new wjGrid.DataMap(prepareMasterOrCustomizeDB(registerData.dbCustomizes), 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              itemsSource: prepareMasterOrCustomizeDB(registerData.dbCustomizes),
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            }),
          },
        ],
      },
      {
        header: '排出量',
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 210,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
    ];
  },

  handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
    let databaseTypeObject = {};
    const wsuSource = dataTable.wsu_source;
    if (wsuSource) {
      databaseTypeObject = store.getters['registerData/getDbCustomizeOrDbMasterById'][wsuSource] || {};
      dataTable.supplier_name = databaseTypeObject.item_name;
      dataTable.qd_unit = databaseTypeObject.unit;
      dataTable.wsu_value = databaseTypeObject.value_source || databaseTypeObject.wsu_value;
      dataTable.wsu_unit = databaseTypeObject.unit_source;
    } else {
      dataTable.supplier_name = null;
      dataTable.qd_unit = null;
      dataTable.wsu_value = null;
      dataTable.wsu_unit = null;
    }
    // dataTable.qd_value = dataTable.qd_value && formatValue(dataTable.qd_value);
    const emissions = calculatorEmissions(dataTable.wsu_value, dataTable.qd_value);
   // formatValue(emissions)?.substring(0, 20)formatValue(emissions)?.substring(0, 20);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    });

    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name,
      organizational_division: dataTable.organizational_division,
      qd_unit: dataTable.qd_unit,
      qd_source: dataTable.qd_source || null,
      qd_value: isSaveTemplate ? '' : formatValue(dataTable.qd_value) === 0 ? '0' : formatValue(dataTable.qd_value) || null,
      supplier_name: dataTable.supplier_name || null,
      wsu_source: databaseTypeObject.origin_id || null,
      wsu_unit: dataTable.wsu_unit,
      wsu_value: dataTable.wsu_value,
      duration_id: store.state.registerData.duration, // from store
      month: store.state.registerData.month, // from store
      emissions: formatEmission20Number(emissions),
      year: store.state.registerData.yearSelected,
      db_customize_id: databaseTypeObject.origin_id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      type: databaseTypeObject.type || 0,
      // index: indexEdit + index,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
    };
    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }
    return dataTableItemCustom;
  },

  editDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
    let dataRegisterOnTable = [];
    dataTable.itemsEdited.forEach((dataTableItemEdited) => {
      const dataTableItemCustom = this.handlerDataOnTable(dataTableItemEdited, bindingLayout, isSaveTemplate);
      if (dataTableItemCustom?.id && dataTableItemCustom?.id === BLANK_ID) {
        return;
      }
      dataRegisterOnTable.push(dataTableItemCustom);
    });

    return dataRegisterOnTable;
  },

  addBlankItemsToView: (view, numberBlankItems) => {
    const lastClientId = view.itemCount
    for (let numberBlankItem = 1; numberBlankItem <= numberBlankItems; numberBlankItem++) {
      view.addNew(blankData(lastClientId + numberBlankItem));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'supplier_name',
    'qd_unit',
    'wsu_unit',
    'wsu_source',
  ],

  // TODO
  getError(item, propName) {
    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === "" || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }
    if (numberField.includes(propName)) {
      if (item[propName] === null || item[propName] === undefined) {
        return null;
      }
      return validateNumberField(String(item[propName]));
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null;
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'qd_value',
      'qd_source',
      'wsu_source',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'qd_value',
      'qd_source',
      'wsu_source',
      ...bindingLayout
    ];
  },

  autoFields: [
    'supplier_name',
    'qd_unit',
    'wsu_value',
    'wsu_unit',
    'wsu_source',
  ],

  calcEmissions(rowData) {
    return calculatorEmissions(rowData.wsu_value, rowData.qd_value)
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']
    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]

        // check length
        const maxlength128 = 128;
        const maxlength20 = 20;
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max20Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength20) {
          const emission20Number = formatValue(rowData?.[column.binding])?.includes('-') ? 21 : 20;
          const newData = formatValue(rowData?.[column.binding])?.substring(0, emission20Number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength20
        }
        //end check length
        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData?.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView?.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];

      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview?.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      if(column.binding === 'organizational_division') {
        contentMaxWidth = ['単体', '子会社', '関連会社', 'その他関連会社'][dataMaxLengthInview.organizational_division - 1]
      }

      const widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true) 
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const column = theGrid.columnHeaders.columns[col];
    if(max128Charactor.includes(column) && cellData?.toString()?.length > 128) {
      theGrid.setCellData(row, getColumnIndexByBinding(theGrid, column), cellData?.toString()?.slice(0, 128), false, true)
    }
    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);
    if(column.binding === 'wsu_source') {
      widthCellEditing = callBackGetWidth(store.getters['registerData/getDbCustomizeOrDbMasterById'][cellData]?.source) + paddingCell
    }
    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = source[indexMaxLengthOfColumn]
        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview?.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },

  patternUrl: '/pattern-s3-c1/',
};

export default partern5;
