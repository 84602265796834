<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <span>閲覧権限管理</span>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            >
            <div v-if="showTooltip" class="helper-icon tooltip"
                 v-html="'システムを利用するユーザーが閲覧可能な法人、事業を管理します。'"></div>
          </div>
        </div>
      </div>
      <div class="wrap__status">
        <div class="wrap__status__item">
          <img src="@/assets/icons/clock.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Last Update</p>
            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
          </div>
        </div>
        <div class="wrap__status__item">
          <img src="@/assets/icons/userTable.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Updated By</p>
            <p>{{ statusUpdate.user || "" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        v-show="isGotLayer"
        :data-source="listData"
        :init-grid="initializeGrid"
        :grid-columns="gridColumns"
        :allowAddNew="false"
        :totalData="totalData - 1"
        :lastedRow="lastedRow"
        :showFocus="false"
        :isHasData="isHasData"
        @onHandleActionTable="checkValidationData"
      />
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="dialogMessage"
      @submit="() => checkboxHandler(false)"
      @close="closePopup"
    />
    <notification-popup :dialog="dialogNotification" :message="'変更内容を保存しました。'" @submit="dialogNotification = false" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getListPermissionApi, updatePermissionApi } from "@/api/viewer";
import { CollectionView, PopupPosition, Tooltip } from "@grapecity/wijmo";
import DialogPopup from "@/components/dialogs/question-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { formatDateTime } from "@/utils/datetimeFormat";
import { UndoStack } from "@grapecity/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getUserInfo } from "@/api/auth";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@grapecity/wijmo.grid";
import * as wjcCore from "@grapecity/wijmo";
import { ROLE } from '@/constants/role'

export default {
  data() {
    return {
      items               : [
        {
          text    : "ホーム",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "設定",
          disabled: false,
          href    : ROUTES.SETTING
        },
        {
          text    : "閲覧権限管理",
          disabled: true,
          href    : ROUTES.FACILITY_MANAGEMENT
        }
      ],
      flex                : null,
      data                : [],
      listData            : null,
      gridColumns         : [],
      dialogPopup         : false,
      dialogMessage       : "このユーザーの閲覧許可を無効にします。\n よろしいですか？",
      dialogCancelBtn     : false,
      check_status        : null,
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      countErr            : 0,
      testErr             : [],
      statusUpdate        : {
        time: null,
        user: null
      },
      totalData           : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      lastedRow           : {},
      contractorName      : "",
      showTooltip         : false,
      layers              : [],
      isGotLayer          : false,
      listOriginalData    : [],
      dialogNotification: false,
    };
  },

  components: {
    DataTable,
    DialogPopup,
    NotificationPopup
  },

  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isExpand"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("registerData", ["isFullScreen"]),

    isHasData() {
      return this.canUndo || this.canRedo;
    },
  },

  async mounted() {
    await this.updateBreadCrumb(this.items);
    this.gridColumns = this.getGridColumns();
    await this.checkUserInfo();
    this.isGotLayer             = false;
    const permissionApiResponse = await getListPermissionApi({ order: "user_id,branch_id" });
    this.layers                 = permissionApiResponse?.layer.map((layer) => {
      let binding = `layer_${layer.layer_index}`;
      if (layer.layer_index === 1) {
        binding = "business_name";
      } else if (layer.layer_index === 2) {
        binding = "country";
      }
      return {
        ...layer,
        binding
      };
    }).sort((a, b) => a.layer_index - b.layer_index);
    this.isGotLayer             = true;
    this.statusUpdate           = {
      time: permissionApiResponse.updated_at_latest,
      user: permissionApiResponse.user_updated
    };
    permissionApiResponse.data.map((item) => {
      this.data.push({
        ...item,
        branch_name: this.contractorName
      });
    });
    this.totalData = permissionApiResponse.data.length;

    this.defineTable();
    this.createUndoStack();
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("actionsTable", ["actionUpdateStatusBtn"]),

    getWidthByText(string) {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width      = "auto";
      text.style.position   = "absolute";
      text.style.whiteSpace = "no-wrap";
      text.style.font       = "Source Han Sans";
      text.style.fontSize   = 13 + "px";
      text.innerHTML        = string + "標準値";
      const width           = text.clientWidth;
      document.body.removeChild(text);
      return (width + 12) < 90 ? 90 : width + 12;
    },

    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView(this.data, {
        trackChanges: true
      });
      this.listOriginalData  = JSON.parse(JSON.stringify(this.listData.items));
      this.flex.columnGroups = this.getGridColumns();
    },

    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      flexgrid.autoSizeColumns();

      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.pasted.addHandler((s, e) => {
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
        if (e.range.row === e.range.row2) {
          // paste one row
          s.onItemsSourceChanged();
        }
      });

      flexgrid?.beginningEdit.addHandler((s, e) => {
        let column        = s.columns[e.col];
        let rowValue      = s.rows[e.row]._data;
        this.check_status = rowValue;

        if (column.binding !== "status") {
          return;
        }

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        if(rowValue.role_id !== ROLE.ADMIN) {
          this.handleCheckBox(rowValue);
        }

        e.cancel = true;
      });

      flexgrid?.formatItem.addHandler((s, e) => {
        const colBinding        = e.panel.columns[e.col].binding;
        const colBindingTooltip = ["status"];
        if (!colBindingTooltip.includes(colBinding)) {
          return;
        }
        if (e.panel == s.columnHeaders) {
          if (colBinding === "status") {
            toolTipCustom(e, "t1", "ユーザーごとに、閲覧編集可能な法人・事業を指定します。", this.hdrTips);
          }
        }
        if (e.panel == s.cells && colBinding === 'status') {
          const roleId = s.rows[e.row]._data?.role_id;
          if(roleId === ROLE.ADMIN) {
            wjcCore.addClass(e.cell, "is-admin-read-only"); 
          }
        }
      });
    },

    getGridColumns() {
      const mapLayers = this.layers.map((layer) => ({
        header      : layer.layer_name,
        binding     : layer.binding,
        minWidth    : 90,
        maxWidth    : 980,
        allowSorting: false,
        isReadOnly  : true,
        cssClassAll : "no-tooltip single-row",
        wordWrap    : true
      }));
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "#",
          binding     : "role_id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "ユーザーID",
          binding     : "user_email",
          minWidth    : 220,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          allowMerging: true,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        {
          header      : "契約者名",
          binding     : "user_id",
          minWidth    : 220,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          visible     : false,
          wordWrap    : true
        },
        {
          header      : "契約者名",
          binding     : "branch_name",
          minWidth    : 240,
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        {
          header      : "法人名",
          binding     : "company_name",
          minWidth    : 265,
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        ...mapLayers,
        {
          header      : "閲覧を許可",
          binding     : "status",
          minWidth    : 170,
          maxWidth    : 219,
          allowSorting: false,
          wordWrap    : true,
          cssClassAll : "single-row"
        }
      ];
    },

    handleCheckBox(value) {
      if (value.status) {
        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },

    checkboxHandler(check) {
      this.listData.beginUpdate();
      this.listData.currentItem.status = check;
      this.listData.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
      this.closePopup();
    },

    closePopup() {
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },

    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },

    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.contractorName = res.contractor.name;
        })
        .catch(() => {
        });
    },

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },

    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },

    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    checkValidationData() {
      this.submitData();
    },
    submitData() {
      let edited = {};
      let self   = this;
      self.listOriginalData.forEach(function(item, itemIndex) {
        let newItemIndex = self.listData.items.findIndex(currentItem => currentItem.id === item.id);
        let newItem      = self.listData.items[newItemIndex];
        if (newItem.status !== item.status) {
          edited[itemIndex] = {
            id    : item.id,
            status: newItem.status ? 1 : 0
          };
        }
      });
      // call api
      if (Object.values(edited).length) {
        updatePermissionApi({ data: Object.values(edited) }).then((response) => {
          this.statusUpdate     = {
            time: response.latest_record.updated_at_latest,
            user: response.latest_record.user_updated
          };
          this.listOriginalData = JSON.parse(JSON.stringify(this.listData.items));
          this.dialogNotification = true;
          this.undoStack.clear();
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  },
  watch  : {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import './styles/index.scss';
</style>
