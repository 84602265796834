import * as wjGrid from '@grapecity/wijmo.grid';
import { AutoComplete } from '@grapecity/wijmo.input';
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
}
  from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, $_helper_isNegativeNumber, validateNumberField, math, formatBigNumber, formatEmission20Number } from './wijmo.helper'

import { CellEditEndingEventArgs, CellRange } from '@grapecity/wijmo.grid'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { prepareMasterOrCustomizeDB } from '@/concerns/utils/master-or-customize-db'

export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    processing_method: null,
    item_source: null,
    industrial_waste_item: null,
    industrial_waste_value: null,
    wsu_type: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
  }
}
const processing_methods = [
  '焼却',
  '埋立',
  'リサイクル',
  '不明'
]

const types = [
  { key: 1, value: '産業廃棄物' },
  { key: 2, value: '一般廃棄物' },
  { key: 3, value: '不明'},
]

const calcEmissions = (valueSource, industrialWasteValue) => {
  if($_helper_isNumberType(valueSource) && $_helper_isNumberType(industrialWasteValue)){
    const val_valueSource = math.bignumber(formatValue(valueSource));
    const val_industrialWasteValue = math.bignumber(formatValue(industrialWasteValue));
    return  formatBigNumber(math.evaluate(`${val_valueSource.toString()} * ${val_industrialWasteValue.toString()}`))
  }
  return 0;
}

const notNullInView = [
  'unit',
]

const notNullList = [
  'organizational_division',
  'company_name',
  'processing_method',
  'unit',
  'wsu_type',
  'wsu_value',
  'wsu_unit',
  'wsu_source',
  'industrial_waste_value'
]

const max20Charactor = [
  'industrial_waste_value',
  'wsu_unit',
  // 'wsu_value'
]

const max128Charactor = [
  'company_name',
  'business_name',
  'processing_method',
  'industrial_waste_item',
  'wsu_type',
  'memo_1',
  'memo_2',
  'item_source'
]

const numberField = ['industrial_waste_value']
const NA_VALUE = 'N/A';

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const partern = {
  initializeGrid(flexgrid) {
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= 100; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }

      if ((e.keyCode === KEYS_CODE.BACKSPACE || e.keyCode === KEYS_CODE.DELETE) && !flexgrid.selection.isSingleCell){
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      }
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['wsu_type', 'wsu_source', 'industrial_waste_value'].includes(s.columns[colIndex].binding)) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
            cEvent.custom = true
            s.onCellEditEnded(cEvent)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      // update emissions when  wsu_source, energy_value change
      if (column.binding === 'industrial_waste_value') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(currentItem.wsu_value, currentItem.industrial_waste_value), false, true)
        })
      }

      if (column.binding === 'wsu_source') {
        const dbIndex = store.getters['registerData/dbCustomizesOrDbMasters'].findIndex(item => item.id === cellData);
        s.deferUpdate(() => {
          if (dbIndex >= 0) {
            const dbItem = store.getters['registerData/dbCustomizesOrDbMasters'][dbIndex] || {}
            currentItem.db_index = dbIndex

            s.setCellData(row, getColumnIndexByBinding(s,'unit'), dbItem.unit, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_value'), dbItem.value_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_unit'), dbItem.unit_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(dbItem.value_source, currentItem.industrial_waste_value), false, true)
          } else {
            currentItem.db_index = null
            s.setCellData(row, getColumnIndexByBinding(s,'unit'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_value'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_unit'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(currentItem.wsu_value, currentItem.industrial_waste_value), false, true)
          }
        })
      }

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let item = s.rows[e.row].dataItem;
      let column = s.columns[e.col];
      // auto ++ serial number
      if (!item.index) {
        item.index = flexgrid?.rows?.length - 1 || 1;
      }
      if (
        column.binding === 'emissions' ||
        column.binding === 'wsu_value'
      ) {
        e.cancel = true;
      }

      if (s.cells?._activeCell?.className?.includes('cell-disable')) {
        e.cancel = true
      }
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData);
      }
    });
    flexgrid.pasted.addHandler(() => {
      flexgrid.autoSizeColumns();
      flexgrid.autoSizeRows(0, 0, true);
    });
    flexgrid.autoSizeColumns();
  },

  header(registerData, listLayout) {
    let companyMap = new wjGrid.DataMap(registerData.listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(registerData.listBranch.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
      },
      {
        header: '組織区分',
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 140,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '法人名',
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listBranch?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: '処理方法',
        binding: 'processing_method',
        minWidth: 96,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        dataMap: processing_methods,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: processing_methods,
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '分類', // classification, will be change when has api
        binding: 'wsu_type',
        minWidth: 70,
        maxWidth: 950,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        dataMap: new wjGrid.DataMap(types, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          itemsSource: types,
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '品目', // Industrial waste
        binding: 'industrial_waste_item',
        minWidth: 70,
        maxWidth: 950,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true
      },
      {
        header: '備考1', // memo1
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '備考2', // memo2
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '活動量',
        align: 'center',
        columns: [
          {
            header: '数値', // Industrial waste (numerical value)
            binding: 'industrial_waste_value',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
          },
          {
            header: '単位',
            binding: 'unit',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: 'ソース', //source
            binding: 'item_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ],
      },
      {
        header: '排出原単位',
        align: 'center',
        columns: [
          {
            header: '数値', // (numerical value), auto select from classification
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
          },
          {
            header: '単位', // (unit), auto select from classification
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: '出典', // (source), auto select from classification
            binding: 'wsu_source',
            minWidth: 400,
            maxWidth: 950,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            dataMap: new wjGrid.DataMap(prepareMasterOrCustomizeDB(registerData.dbCustomizes), 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              itemsSource: prepareMasterOrCustomizeDB(registerData.dbCustomizes),
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
        ],
      },
      {
        header: '排出量', //(Industrial waste (numerical value) + general waste (numerical value))× Đơn vị nguồn thải  (numerical value)
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 210,
        allowSorting: false,
        isRequired: false,
      },
    ];
  },

  handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
    let databaseTypeObject = {};
    const wsuSource = dataTable.wsu_source;
    if (wsuSource) {
      databaseTypeObject = store.getters['registerData/getDbCustomizeOrDbMasterById'][wsuSource] || {};

      dataTable.unit = databaseTypeObject.unit;
      dataTable.wsu_unit = databaseTypeObject.unit_source;
      dataTable.wsu_value = databaseTypeObject.value_source || databaseTypeObject.wsu_value;
    } else {
      dataTable.unit = null;
      dataTable.wsu_unit = null;
      dataTable.wsu_value = null;
    }
    //dataTable.wsu_type = dataTable.wsu_type && formatValue(dataTable.wsu_type);
    //dataTable.industrial_waste_value = dataTable.industrial_waste_value && formatValue(dataTable.industrial_waste_value);
    const emissions = calcEmissions(dataTable.wsu_value, dataTable.industrial_waste_value);
   // formatValue(emissions)?.substring(0, 20) = formatValue(emissions)?.substring(0, 20);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    });

    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name,
      organizational_division: dataTable.organizational_division,
      emissions: formatEmission20Number(emissions),
      processing_method: dataTable.processing_method || null,
      item_source: dataTable.item_source || null,
      industrial_waste_item: dataTable.industrial_waste_item?.trim() || null,
      industrial_waste_value: isSaveTemplate ? '' : formatValue(dataTable.industrial_waste_value) === 0 ? '0' : formatValue(dataTable.industrial_waste_value) || null,
      wsu_source: dataTable.origin_id || null,
      wsu_unit: dataTable.wsu_unit,
      wsu_value: dataTable.wsu_value,
      wsu_type: formatValue(dataTable.wsu_type),
      duration_id: store.state.registerData.duration, // from store
      month: store.state.registerData.month, // from store
      year: store.state.registerData.yearSelected,
      db_customize_id: databaseTypeObject.origin_id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      type: databaseTypeObject.type || 0,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
    };

    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'intermediate_product',
    'sales_volume_source',
    'sales_volume_unit',
    'wsu_unit',
    'wsu_source',
  ],

  getError(item, propName) {

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]));
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      "unit",
      "wsu_source",
      'processing_method',
      'industrial_waste_item',
      'memo_1',
      'memo_2',
      'industrial_waste_value',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      "unit",
      "wsu_source",
      ...bindingLayout
    ];
  },

  autoFields: [
    'wsu_value',
    'wsu_unit',
    'unit',
    'wsu_source'
  ],

  patternUrl: '/pattern-s3-c12/',

  calcEmissions(rowData) {
    return calcEmissions(rowData.wsu_value, rowData.industrial_waste_value)
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]

        // check length
        const maxlength128 = 128;
        const maxlength20 = 20;
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max20Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength20) {
          const emission20Number = formatValue(rowData?.[column.binding])?.includes('-') ? 21 : 20;
          const newData = formatValue(rowData?.[column.binding])?.substring(0, emission20Number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength20
        }
        //end check length

        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(column.binding === 'wsu_type' && rowData?.wsu_type) {
          const content = types.find(item => item.key === rowData?.wsu_type)?.value
          const contentMax = types.find(item => item.key === curentDataInView.wsu_type)?.value
          dataLength = content?.length
          dataLengthInView = contentMax?.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      if(column.binding === 'organizational_division') {
        contentMaxWidth = ['単体', '子会社', '関連会社', 'その他関連会社'][dataMaxLengthInview.organizational_division - 1]
      }
      if(column.binding === 'wsu_type'){
        let content = types.find(item => item.key === dataMaxLengthInview?.wsu_type)?.value
        contentMaxWidth = content
      }

      const widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true) 
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const column = theGrid.columnHeaders.columns[col];
    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);
    if(column.binding === 'wsu_source') {
      widthCellEditing = callBackGetWidth(store.getters['registerData/getDbCustomizeOrDbMasterById'][cellData]?.source) + paddingCell
    }
    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = source[indexMaxLengthOfColumn]
        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview?.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
};

export default partern;
