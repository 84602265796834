import {
  MESSAGE_NOT_NULL,
  MESSAGE_INCORRECT_DATE,
  MESSAGE_IS_NUMBER 
} from '@/constants/registerData'
const regexMail =  /^(([^<>()\[\\,;:\s@"\u0080-\uFFFF]+(\.[^<>()\[\]\\,;:\s@"\u0080-\uFFFF]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPassword =
  /^(((?=.*[A-Za-z])(?=.*\d))|((?=.*[a-z])(?=.*[A-Z]))|((?=.*[A-Za-z])(?=.*[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?]))|((?=.*\d)(?=.*[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?])))[A-Za-z\d[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?]{8,}$/;
import { BLANK_ID } from "@/constants/registerData";

export const validateMaximumCharactor = (item, maximumCharactor) => {
  if (item) {
    if (item.replace(/\s+/g, '') === '') {
      return 'هذا إلزامي.';
    } else if (item.length > maximumCharactor) {
      // return `Maximum number of characters is ${maximumCharactor}!`;
      return `${maximumCharactor === 21 ? 20 : maximumCharactor}文字以内で入力してください。`;
    } else {
      return null;
    }
  } else {
    return 'هذا إلزامي.';
  }
};

export const validateMaximumCharactorOnlyFacility = (item, maximumCharactor) => {
  if (item) {
    if (item.length > maximumCharactor) {
      // return `Maximum number of characters is ${maximumCharactor}!`;
      return `${maximumCharactor}文字以内で入力してください。`;
    } else {
      return null;
    }
  }
  return null;
};

export const validateNumberField = (input) => {
  if (input) {
    let numberData = input.replace(/[^\d.]/g, '');
    if (Number(input) < 0) {
      return '正の値で入力してください。'
    }
    if (numberData && input.length - numberData.length == 0) {
      return null;
    } else {
      return '数字で入力してください。';
    }
  } else {
    return 'هذا إلزامي.';
  }
};

export const getErrorValidate = (item, propName, notNullList, numberField, max20Charactor, max128Charactor) => {

  if (notNullList.includes(propName)) {
    if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
      return MESSAGE_NOT_NULL;
    }
  }

  if (numberField.includes(propName)) {
    if(!item[propName] && item[propName] !== 0) {
      return MESSAGE_NOT_NULL
    } else {
      return validateNumberField(String(item[propName]));
    }
  }

  if(max20Charactor.includes(propName)) {
    if (numberField.includes(propName)) {
      return validateNumberField(String(item[propName]));
    }
    return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
  }

  if(max128Charactor.includes(propName)) {
    return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
  }
  return null
};

export const validateDate = (item) => {
  if (item) {
    const regExp = /^(\d{4})\/(\d\d?)\/(\d\d?)$/;
    let matches = item.match(regExp);
    let isValid = matches;
    let maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (matches) {
      const month = parseInt(matches[2]);
      const date = parseInt(matches[3]);
      const year = parseInt(matches[1]);

      isValid = month <= 12 && month > 0;
      isValid &= date <= maxDate[month] && date > 0;

      const leapYear = (year % 400 == 0)
         || (year % 4 == 0 && year % 100 != 0);
      isValid &= month != 2 || leapYear || date <= 28;
    }

    return isValid ? null : MESSAGE_INCORRECT_DATE;
  }
  return null;
};

export const validateBlankRowData = (item, itemsInView) => {
  if (item?.id === BLANK_ID && itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
    return true;
  }
  return false;
}

export default {
  regexMail,
  regexPassword,
};

export const isPositiveInteger = (value) => {
  if (value === null || value === '') return false;
  return value > -1;
}

export const validateInputFieldCasbee = (input) => {
  if (!(input == null || input == undefined  || input === '')) {
    // Type input always number
    if (isNaN(Number(input))) {
      return MESSAGE_IS_NUMBER
    }
  } else {
    return '値を入力してください';
  }
};