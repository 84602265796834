import store from '@/store';
import { sources, energyTypes } from '@/constants/registerData'
import { formatValue, formatBigNumber, $_helper_isNumberType, math } from '@/concerns/newRegisterData/wijmo.helper';
import {validateDate} from '@/utils/validate';
import { typeOf } from 'mathjs';
const errorCsv = 'データに不具合があるため、取り込みできませんでした。CSVファイルの中身を確認してください。'
const FIRST_FUEL = '自動車輸送 ガソリンの使用'
export const convertCsvToJson = (csvData, flexgrid) => {
  // convert csv data to json
  const array = csvData.split("\n");
  const csvToJsonResult = [];

  const headers = array[0].split(",");
  let headersBinding = [];
  flexgrid?.columns.forEach(column => {
    const headerIndex = headers.findIndex(header => column.header === header.trim());
    if (headerIndex >= 0) {
      headersBinding.push(column.binding);
    }
  })
  for (let i = 1; i < array.length - 1; i++) {
  /* Empty object to store result in key value pair */
  const jsonObject = {}
  /* Store the current array element */
  const currentArrayString = array[i]

  let jsonProperties = currentArrayString.split(",")
  for (let j in headersBinding) {
    jsonObject[headersBinding[j]] = jsonProperties[j]
  }
  /* Push the genearted JSON object to resultant array */
  csvToJsonResult.push(jsonObject)
  }
  return csvToJsonResult;
  /* Convert the final array to JSON */
  return JSON.stringify(csvToJsonResult);
}

const energyTypeS1m2 = ['ガソリン','軽油', 'その他']

const targetModel = ['軽貨物車', '小型貨物車', '普通貨物車', '船舶', '鉄道', '航空']

const types = [
  {key: 1, value: '産業廃棄物'},
  {key: 2, value: '一般廃棄物'},
  {key: 3, value: '不明'},
]


const autoFieldDbS1M1 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS1M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if(!energyTypeS1m2.includes(rowData?.fuel)) {
    rowData.fuel = null
  }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}

const autoFieldDbS1M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}

const autoFieldDbS2 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C1 = (rowData, dbSource) => {
  rowData.supplier_name = dbSource?.item_name || null
  rowData.qd_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C2 = (rowData, dbSource) => {
  rowData.cpa_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C3 = (rowData, dbSource) => {
  rowData.energy_type = dbSource?.item_name || null
  rowData.energy_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C4M1Sub4 = (rowData, dbSource) => {
  rowData.energy_type = dbSource?.id || null
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C4M1Sub5 = (rowData, dbSource) => {
  rowData.wsu_source = dbSource?.id || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  if(!energyTypeS1m2.includes(rowData?.fuel)) {
    rowData.fuel = null
  }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}
const autoFieldDbS3C4M1Sub6 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C4M2Sub7 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C4M2Sub8 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C4M3Sub2 = (rowData, dbSource) => {}

const processing_methods = [
  '焼却',
  '埋立',
  'リサイクル',
  '不明'
]

const autoFieldDbS3C5 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  let type = types.find(item => item.value === rowData.wsu_type);
  if(!processing_methods.includes(rowData.processing_method)){
    rowData.processing_method = null
  }
  if(type) {
    rowData.wsu_type = type.key
  } else {
    rowData.wsu_type = null
  }
}

const autoFieldDbS3C6M1 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M2 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C6M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C6M4 = (rowData, dbSource) => {
  rowData.travel_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M5 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M6 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M7 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C7M1 = (rowData, dbSource) => {
  rowData.te_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M4 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C7M5 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
}

const autoFieldDbS3C8 = (rowData, dbSource) => {
  rowData.lease_asset_name = dbSource?.item_name || null
  rowData.iss_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C9M1 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C9M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if(!energyTypeS1m2.includes(rowData?.fuel)) {
    rowData.fuel = null
  }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}
const autoFieldDbS3C9M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C9M4 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C9M5 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C10 = (rowData, dbSource) => {
  rowData.intermediate_product = dbSource?.item_name || null
  rowData.sales_volume_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C11M1 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M4 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C12 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  console.log(rowData.processing_method);
  if(!processing_methods.includes(rowData.processing_method)){
    rowData.processing_method = null
  }

  let type = types.find(item => item.value === rowData.wsu_type);
  if(type) {
    rowData.wsu_type = type.key
  } else {
    rowData.wsu_type = null
  }
}

const autoFieldDbS3C13 = (rowData, dbSource) => {
  rowData.lease_asset_name = dbSource?.item_name || null
  rowData.scale_index_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const calculationEmissionS3C15M1 = (rowData) => {
  rowData.ir_owned = calcS3C15M134(rowData.ir_company_hold, rowData.ir_release_quantity)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calculationEmissionS3C15M3 = (rowData) => {
  rowData.ir_investment_on_total = calcS3C15M134(rowData.ir_internal_investment, rowData.ir_total_investment)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}
const calculationEmissionS3C15M4 = (rowData) => {
  rowData.ir_ratio = calcS3C15M134(rowData.ir_calculation_numerator, rowData.ir_calculated_denominator)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calcS3C15M134 = (firstValue, secondValue) => {
  if(secondValue === '0') {
    return '0.00'
  }
  if($_helper_isNumberType(firstValue) && $_helper_isNumberType(secondValue)){
    const valFirstValue = math.bignumber(formatValue(firstValue));
    const valSecondValue = math.bignumber(formatValue(secondValue));
    return  formatBigNumber(math.evaluate(`${valFirstValue.toString()} / ${valSecondValue.toString()} `))
  }
  return null;
}

const bindDingValueSource = (rowData, scope, category, dbSource, method, methodLayer2) => {
  // bindding auto field for scope 1
  if(scope === 1) {
    if(method === 1) {
      autoFieldDbS1M1(rowData, dbSource)
    } else if(method === 2) {
      autoFieldDbS1M2(rowData, dbSource)
    } else {
      autoFieldDbS1M3(rowData, dbSource)
    }
  } else if(scope === 2) { // bindding auto field for scope 2
    autoFieldDbS2(rowData, dbSource)
  } else if(scope === 3) { // bindding auto field for scope 3
    switch (category) {
      case 1:
        autoFieldDbS3C1(rowData, dbSource)
        break;
      case 2:
        autoFieldDbS3C2(rowData, dbSource)
        break;
      case 3:
        autoFieldDbS3C3(rowData, dbSource)
        break;
      case 4:
        if(methodLayer2 === 4) {
          autoFieldDbS3C4M1Sub4(rowData, dbSource)
        } else if(methodLayer2 === 5) {
          autoFieldDbS3C4M1Sub5(rowData, dbSource)
        } else if(methodLayer2 === 6) {
          autoFieldDbS3C4M1Sub6(rowData, dbSource)
        } else if(methodLayer2 === 7) {
          autoFieldDbS3C4M2Sub7(rowData, dbSource)
        } else if(methodLayer2 === 8) {
          autoFieldDbS3C4M2Sub8(rowData, dbSource)
        }
        break
      case 5:
        autoFieldDbS3C5(rowData, dbSource)
        break;
      case 6:
        if(method === 1) {
          autoFieldDbS3C6M1(rowData, dbSource)
        } else if(method === 2) {
          autoFieldDbS3C6M2(rowData, dbSource)
        } else if(method === 3) {
          autoFieldDbS3C6M3(rowData, dbSource)
        } else if(method === 4) {
          autoFieldDbS3C6M4(rowData, dbSource)
        } else if(method === 5) {
          autoFieldDbS3C6M5(rowData, dbSource)
        } else if(method === 6) {
          autoFieldDbS3C6M6(rowData, dbSource)
        } else if(method === 7) {
          autoFieldDbS3C6M7(rowData, dbSource)
        }
        break;
      case 7:
        if(method === 1) {
          autoFieldDbS3C7M3(rowData, dbSource)
        } else if(method === 2) {
          autoFieldDbS3C7M4(rowData, dbSource)
        } else if(method === 3) {
          autoFieldDbS3C7M5(rowData, dbSource)
        } else if(method === 4) {
          autoFieldDbS3C7M1(rowData, dbSource)
        } else if(method === 5) {
          autoFieldDbS3C7M2(rowData, dbSource)
        }
        break;
      case 8:
        autoFieldDbS3C8(rowData, dbSource)
        break;
      case 9:
        if(methodLayer2 === 1) {
          autoFieldDbS3C9M1(rowData, dbSource)
        } else if(methodLayer2 === 2) {
          autoFieldDbS3C9M2(rowData, dbSource)
        } else if(methodLayer2 === 3) {
          autoFieldDbS3C9M3(rowData, dbSource)
        } else if(methodLayer2 === 4) {
          autoFieldDbS3C9M4(rowData, dbSource)
        } else if(methodLayer2 === 5) {
          autoFieldDbS3C9M5(rowData, dbSource)
        }
        break;
      case 10:
        autoFieldDbS3C10(rowData, dbSource)
        break;
      case 11:
        if(method === 2) {
          autoFieldDbS3C11M1(rowData, dbSource)
        } else if(methodLayer2 === 1) {
          autoFieldDbS3C11M2(rowData, dbSource)
        } else if(methodLayer2 === 2) {
          autoFieldDbS3C11M3(rowData, dbSource)
        } else if(methodLayer2 === 3) {
          autoFieldDbS3C11M4(rowData, dbSource)
        }
        break;
      case 12:
        autoFieldDbS3C12(rowData, dbSource)
        break;
      case 13:
        autoFieldDbS3C13(rowData, dbSource)
        break;
      case 15:
        if(method === 1) {
          calculationEmissionS3C15M1(rowData)
        } else if(method === 3) {
          calculationEmissionS3C15M3(rowData)
        } else if(method === 4) {
          calculationEmissionS3C15M4(rowData)
        }
        break;
      default:
        break;
    }
  } else {
    rowData.dir_unit = 't-CO2';
    if(validateDate(rowData.dir_date)) {
      rowData.dir_date = null
    }
  }
}

const getSubstringRange = (columnHeader) => {
  return ['燃費（km/l）', '平均積載率（%）', 'PJ投資割合（％）'].some(item => columnHeader.includes(item)) ? 10 : 20
}

// return object with key is item name or source, define for each pattern will difference
const getObjSource = (scope, category, method, methodLayer2, jsonObject) => {
  let objSource = {}
  const sourceKeyByItemName = store.getters['registerData/getDbCustomizeOrDbMasterByItemName']
  const sourceKeyByWsuSource = store.getters['registerData/getDbCustomizeOrDbMasterByWsuSource']
  // const listSources = energyTypes.map(item => item.value)

  if(scope === 1) {
    if(method === 1) {
      objSource = sourceKeyByItemName[jsonObject.energy_type]
    } else if(method === 2) {
      objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
    } else {
      objSource = sourceKeyByItemName[jsonObject.fuel]
    }
  } else if(scope === 2) {
    objSource = sourceKeyByItemName[jsonObject.energy_type]
  } else if(scope === 3) {
    switch (category) {
      case 1:
      case 2:
      case 3:
      case 5:
      case 8:
      case 10:
      case 11:
      case 12:
      case 13:
        objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        break;
      case 4:
        if([4, 7, 8].includes(methodLayer2)) {
          objSource = sourceKeyByItemName[jsonObject.energy_type]
        } else if(methodLayer2 === 5) {
          objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        } else if(methodLayer2 === 6) {
          objSource = sourceKeyByItemName[jsonObject.fuel]
        }
        break
      case 6:
        if([1, 4, 5, 6, 7].includes(method)) {
          objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        } else if(method === 2) {
          objSource = sourceKeyByItemName[jsonObject.energy_type]
        } else {
          objSource = sourceKeyByItemName[jsonObject.fuel]
        }
        break;
      case 7:
        if(method === 2) {
          objSource = sourceKeyByItemName[jsonObject.energy_type]
        } else if([1,4,5].includes(method)) {
          objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        } else  {
          objSource = sourceKeyByItemName[jsonObject.fuel]
        }
        break;
      case 9:
        if([1, 4, 5].includes(methodLayer2)) {
          objSource = sourceKeyByItemName[jsonObject.energy_type]
        } else if(methodLayer2 === 2) {
          objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        } else if(methodLayer2 === 3) {
          objSource = sourceKeyByItemName[jsonObject.fuel]
        }
        break;
      default:
        objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
        break;
    }
  }

  return objSource
}

// compare headerCsv & headerInPattern before check data csv
const compareHeader = (headerInPattern, headerCsv) => {
  if (headerInPattern.length !== headerCsv.length) {
    return false;
  }
  // slice length headerCsv to length headerInPattern
  headerCsv = headerCsv.slice(0, headerInPattern.length)
  for (let i = 0; i < headerInPattern.length; i++) {
    if (headerInPattern[i] !== headerCsv[i]?.trim()) {
      return false;
    }
  }

  return true;
}
const cleanArray = (arr) => {
  return arr.map((element) => {
    const trimmedElement = element.trim();
    if (trimmedElement.match(/^"\s*\d+,\d+\.\d+\s*"$/)) {
      const numericValue = parseFloat(trimmedElement.replace(/[",\s]/g, ''));
      return numericValue.toString();
    }
    return trimmedElement;
  }).filter((element) => element !== '');
}
const convertToArray = (text) => {
  const delimiter = ',';
  const specialDelimiter = '~';
  const regex = /""/g;
  const textWithDelimiters = text.replace(regex, specialDelimiter);
  return textWithDelimiters.split(delimiter).map((field) => field.replace(new RegExp(specialDelimiter, 'g'), '"').trim());
}

export const convertCsvToJsonNew = data => {
  const { csvData, header, scope, category, numberItem, calcEmissions, listBranchObj, method, methodLayer2 } = data
  if(!csvData) return
  const array = csvData.split("\n");
  const csvToJsonResult = [];
  const headers = convertStringToArray(array[0]);
  let error = '';
  let headersBinding = [];
  let headerMainSubCategory = []; // item will <main category>_<sub category> if has 2 header
  let removeColumns = ['id', 'emissions']
  let itemErrorCount = 0;
  if(scope === 3 && category === 14) {
    removeColumns = ['id']
  }
  header.forEach(column => {
    const headerIndex = headers.findIndex(header => column.header === header.trim());
    if(category === 14 && column.binding !== 'id' && headerIndex >= 0) {
      headersBinding.push(column.binding);
    } else {
      if (headerIndex >= 0 && !removeColumns.includes(column.binding) && !column.cssClass?.includes('auto-increment')) {
        headersBinding.push(column.binding);
      }
    }
    
    if(column?.columns) {
      column.columns.forEach(subColumn => {
        if(!subColumn.cssClass?.includes('auto-increment')) {
          headersBinding.push(subColumn.binding);
          headerMainSubCategory.push(`${column.header}_${subColumn.header}`)
        }
      })
    } else {
      if(category === 14 && column.binding !== 'id') {
        headerMainSubCategory.push(column.header)
      } else {
        if(!removeColumns.includes(column.binding) && !column.cssClass?.includes('auto-increment')) {
          headerMainSubCategory.push(column.header)
        }
      }
    }
  })

  const organizationalList_obj = listBranchObj.organizationalList_obj
  const company_name_obj = listBranchObj.company_name_obj
  const business_name_obj = listBranchObj.business_name_obj
  const country_obj = listBranchObj.country_obj
  const layer_3_obj = listBranchObj.layer_3_obj
  const layer_4_obj = listBranchObj.layer_4_obj
  const layer_5_obj = listBranchObj.layer_5_obj
  const layer_6_obj = listBranchObj.layer_6_obj
  // check header in pattern with header in csv
  const headerCsv = convertStringToArrayInHeader(array[0])?.map(item => item.replace('\r', ''))
  // console.log(array[1],convertToArray(array[1], cleanArray(convertToArray(array[1]))));
  console.log(headerMainSubCategory, headerCsv);
  if(!compareHeader(headerMainSubCategory, headerCsv)) {
    error = errorCsv;
    return { csvData: [], error };
  }
  for (let i = 1; i < array.length; i++) {
    /* Empty object to store result in key value pair */
    const jsonObject = {}
    /* Store the current array element */
    const currentArrayString = array[i];
    let jsonProperties = convertStringToArray(currentArrayString)?.map(item => item?.replace('\r', ''))

    // stop count row error in last item
    if(jsonProperties.length === 1 && i === array.length - 1) {
      continue;
    }
    
    // stop the current iteration of a loop and move on to the next iteration when length of header not match with length of row data
    if(headersBinding.length > jsonProperties.length) {
      itemErrorCount ++
      continue;
    }
    for (let j in headersBinding) {
      jsonObject[headersBinding[j]] = jsonProperties[j].replace('\r', '')
      // format number
      if(numberItem.includes(headersBinding[j])) {
        const isNumber = $_helper_isNumberType(jsonObject[headersBinding[j]]);
        let subStringRang = getSubstringRange(headerCsv[j])
        if(formatValue(jsonObject[headersBinding[j]])?.includes('-')) {
          subStringRang += 1
        }
        // isNumber ?  formatValue(cellData)?.substring(0, subStringRang).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : ''
        jsonObject[headersBinding[j]] = isNumber ? formatValue(jsonObject[headersBinding[j]])?.substring(0, subStringRang).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
      }
      
      // this code use continue to stop check curent iteration when any condition return true
      if(headersBinding[j] === 'organizational_division' && !organizationalList_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      } else if(headersBinding[j] === 'organizational_division' && organizationalList_obj[jsonProperties[j]]) {
        jsonObject[headersBinding[j]] = organizationalList_obj[jsonProperties[j]].key;  // set organizational_division to number value => 1,2,3,4
        continue;
      }
      
      if(headersBinding[j] === 'company_name' && !company_name_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'business_name' && !business_name_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'country' && !country_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_3' && !layer_3_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_4' && !layer_4_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_5' && !layer_5_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_6' && !layer_6_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
      }


    }
    // get obj source
    const objSource = getObjSource(scope, category,method, methodLayer2, jsonObject)

    // set data auto fill
    bindDingValueSource(jsonObject, scope, category, objSource, method, methodLayer2)

    jsonObject.emissions = calcEmissions(jsonObject)  // calc emission all row
    delete jsonObject.id
    /* Push the genearted JSON object to resultant array */
    csvToJsonResult.push(jsonObject)
  }
  return { csvData: csvToJsonResult, error, itemErrorCount };
}

const convertStringToArray = (stringText) => {
  let newArray = [];
  let replaceNumberTextArr = [];
  let replaceNumberText = stringText.replace(/"([^"]*)"/g, (match, p1, p2) => {
    const num = p1.replace(/,/g, '_');
    replaceNumberTextArr.push(num);
    return num;
  });
  newArray = replaceNumberText.split(',');
  replaceNumberTextArr.forEach(item => {
    newArray[newArray.indexOf(item)] = item.replace(/_/g, ',');
  })
  return newArray;
}

const convertStringToArrayInHeader = (stringText) => {
  let newArray = [];
  let currentStr = '';

  // iterate through each character in the string
  for (let i = 0; i < stringText.length; i++) {
    if (stringText[i] === ',' && currentStr !== '') {
      // if we encounter a comma and the current string is not empty, add it to the array
      newArray.push(currentStr);
      currentStr = '';
    } else if (stringText[i] === '"' && (i === 0 || stringText[i-1] !== '\\')) {
      // if we encounter a double quote and it is not escaped, add each character to the current string
      let j = i+1;
      while (j < stringText.length && stringText[j] !== '"') {
        currentStr += stringText[j];
        j += 1;
      }
      i = j;  // skip over the characters inside the quotes
    } else {
      // otherwise, add the current character to the current string
      currentStr += stringText[i];
    }
  }

  // add the final current string to the array
  if (currentStr !== '') {
    newArray.push(currentStr);
  }
  return newArray;
}