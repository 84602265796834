import { updateListData, addListData, deleteListData } from '@/api/registerData';
import * as wjGrid from '@grapecity/wijmo.grid';
import store from '@/store';
import { AutoComplete } from '@grapecity/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
} from '@/constants/registerData';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { formatValue, $_helper_isNumberType, $_helper_isNegativeNumber, validateNumberField, math, formatBigNumber, formatEmission20Number } from './wijmo.helper'
import { CellEditEndingEventArgs, CellRange } from '@grapecity/wijmo.grid'
import { batchReplacementViewCollection, emptyPromise } from './wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
const patternUrl = '/pattern-s3-c15/2';
const NA_VALUE = 'N/A';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    ides_industry: null,
    business_name: null,
    investment_destination: null,
    le_value: null,
    pj_investment_ratio: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    ie_source: null,
    ir_investment_source: null,
  }
}

const calcEmissions = (pjInvestmentRatio, leValue) => {
  if($_helper_isNumberType(pjInvestmentRatio) && $_helper_isNumberType(leValue)){
    const val_pjInvestmentRatio = math.bignumber(formatValue(pjInvestmentRatio));
    const val_leValue = math.bignumber(formatValue(leValue));
    return  formatBigNumber(math.evaluate(`${val_pjInvestmentRatio.toString()} * ${val_leValue.toString()} / 100`))
  }

  return 0;
}

const notNullList = [
  'organizational_division',
  'company_name',
]

const max20Charactor = [
  'le_value',
  'pj_investment_ratio',
]

const max128Charactor = [
  'company_name',
  'business_name',
  'ides_industry',
  'investment_destination',
  'memo_1',
  'memo_2',
  'ie_source',
  'ir_investment_source',
]

const numberField = [
  'le_value',
  'pj_investment_ratio',
]

const isValidData = (item) => {
  if (notNullList.some(field => !item[field] && item[field] !== 0)) {
    return false
  }
  if (max128Charactor.some(field => item[field]?.length > 128)) {
    return false
  }
  if (max20Charactor.some(field => item[field]?.length > 20)) {
    return false
  }
  if (numberField.some(field => validateNumberField(String(item[field])) !== null)) {
    return false
  }
  return true
}

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const partern23 = {
  initializeGrid(flexgrid) {
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= 100; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }

      if ((e.keyCode === KEYS_CODE.BACKSPACE || e.keyCode === KEYS_CODE.DELETE) && !flexgrid.selection.isSingleCell){
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      }
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['pj_investment_ratio', 'le_value'].includes(s.columns[colIndex].binding)) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
            cEvent.custom = true
            s.onCellEditEnded(cEvent)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (column.binding === 'pj_investment_ratio') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(cellData, currentItem.le_value), false, true)
        })
      }
      if (column.binding === 'le_value') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(currentItem.pj_investment_ratio, cellData), false, true)
        })
      }

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];

      if (column.binding === 'emissions') {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData);
      }
    });
    flexgrid.pasted.addHandler(() => {
      flexgrid.autoSizeColumns();
      flexgrid.autoSizeRows(0, 0, true);
    });
    flexgrid.autoSizeColumns();
  },

  allowWidthAuto() {
    return true;
  },

  // TODO: [TRUNG] update name binding
  header(registerData, listLayout) {
    let companyMap = new wjGrid.DataMap(registerData.listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(registerData.listBranch.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
      },
      {
        header: '組織区分',
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 140,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '法人名',
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listBranch?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: '融資先',
        align: 'center',
        columns: [
          {
            header: '企業名',
            binding: 'investment_destination',
            minWidth: 93,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
          {
            header: '業種',
            binding: 'ides_industry',
            minWidth: 70,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
        ]
      },
      {
        header: '備考1',
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: '備考2',
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: '融資先排出量',
        align: 'center',
        columns: [
          {
            header: '生涯排出量（t-CO2）',
            binding: 'le_value',
            minWidth: 171,
            maxWidth: 220,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
          },
          {
            header: 'ソース', //source
            binding: 'ie_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ]
      },
      {
        header: '投資割合',
        align: 'center',
        columns: [
          {
            header: 'PJ投資割合（％）',
            binding: 'pj_investment_ratio',
            minWidth: 149,
            maxWidth: 210,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            // format: 'p*'
          },
          {
            header: 'ソース', //source
            binding: 'ir_investment_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ]
      },
      {
        header: '排出量',
        binding: 'emissions',
        cssClassAll: 'auto-increment emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 210,
        allowSorting: false,
        isRequired: false,
        inputType: 'float',
        dataType: 'Number',
      },
    ];
  },

  handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false) {

    //dataTable.pj_investment_ratio = dataTable.pj_investment_ratio && formatValue(dataTable.pj_investment_ratio);
    //dataTable.le_value = dataTable.le_value && formatValue(dataTable.le_value);
    const emissions = calcEmissions(dataTable.pj_investment_ratio, dataTable.le_value);
   // formatValue(emissions)?.substring(0, 20) = formatValue(emissions)?.substring(0, 20);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    });

    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name,
      organizational_division: dataTable.organizational_division,
      emissions: formatEmission20Number(emissions),
      investment_destination: dataTable.investment_destination?.trim() || null,
      ides_industry: dataTable.ides_industry || null,
      pj_investment_ratio: isSaveTemplate ? '' : formatValue(dataTable.pj_investment_ratio) === 0 ? '0' : formatValue(dataTable.pj_investment_ratio) || null,
      le_value: isSaveTemplate ? '' : formatValue(dataTable.le_value) === 0 ? '0' : formatValue(dataTable.le_value) || null,
      ie_source: dataTable.ie_source || null,
      ir_investment_source: dataTable.ir_investment_source || null,
      duration_id: store.state.registerData.duration, // from store
      month: store.state.registerData.month, // from store
      year: store.state.registerData.yearSelected,
      type:  0,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
    };

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'investment_destination',
    'pj_investment_ratio',
  ],

  getError(item, propName) {

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]));
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'investment_destination',
      'ides_industry',
      'memo_1',
      'memo_2',
      'le_value',
      'ie_source',
      'pj_investment_ratio',
      'ir_investment_source',
      ...bindingLayout
    ];
  },
  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'investment_destination',
      'ides_industry',
      'memo_1',
      'memo_2',
      'le_value',
      'ie_source',
      'pj_investment_ratio',
      'ir_investment_source',
      ...bindingLayout
    ];
  },
  autoFields: [],
  patternUrl: '/pattern-s3-c15/2',

  calcEmissions(rowData) {
    return calcEmissions(rowData.pj_investment_ratio, rowData.le_value)
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];

    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]
        // check length
        const maxlength128 = 128;
        const maxlength20 = 20;
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max20Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength20) {
          const emission20Number = formatValue(rowData?.[column.binding])?.includes('-') ? 21 : 20;
          const newData = formatValue(rowData?.[column.binding])?.substring(0, emission20Number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength20
        }
        //end check length

        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      if(column.binding === 'organizational_division') {
        contentMaxWidth = ['単体', '子会社', '関連会社', 'その他関連会社'][dataMaxLengthInview.organizational_division - 1]
      }

      const widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true) 
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);

    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
};

export default partern23;
