import * as wjGrid from '@grapecity/wijmo.grid';
import { AutoComplete } from '@grapecity/wijmo.input';
import { updateListData, addListData, deleteListData } from '@/api/registerData';
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_POSITIVE_NUMBER
}
  from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { batchReplacementViewCollection, emptyPromise, formatValue } from './wijmo.helper'
import { CellEditEndingEventArgs, CellRange } from '@grapecity/wijmo.grid'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, listeningEventWhenPasted, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { prepareMasterOrCustomizeDB } from '@/concerns/utils/master-or-customize-db'

const patternUrl = '/pattern-s3-c13/';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    lease_asset_name: null,
    scale_index_unit: null,
    scale_index_value: null,
    scale_index_source: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
  }
}

const validateNumberField = (input) => {
  if (input) {
    let numberData = input.replace(/[^\d.]/g, '');
    if (Number(input) < 0) {
      return MESSAGE_POSITIVE_NUMBER;
    }
    if (input.length > 20) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
    if (numberData && input.length - numberData.length == 0) {
      return null;
    } else {
      return MESSAGE_IS_NUMBER;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
}

const notNullInView = [
  'lease_asset_name',
  'scale_index_unit',
  'wsu_value',
  'wsu_unit',
  'wsu_source',
]


const notNullList = [
  'organizational_division',
  'company_name',
  'scale_index_value',
  'lease_asset_name',
  'scale_index_unit',
  'wsu_value',
  'wsu_unit',
  'wsu_source',
]

const max20Charactor = [
  'organizational_division',
  'scale_index_value',
  'scale_index_unit',
  'wsu_value',
  'wsu_unit',
]

const max128Charactor = [
  'company_name',
  'business_name',
  'lease_asset_name',
  'scale_index_source',
  'memo_1',
  'memo_2',
  // 'wsu_source' TODO
]

const numberField = ['scale_index_value']

const isValidData = (item) => {
  if (notNullList.some(field => !item[field] && item[field] !== 0 && !notNullInView.includes(field))) {
    return false
  }
  if (max128Charactor.some(field => item[field]?.length > 128)) {
    return false
  }
  if (max20Charactor.some(field => item[field]?.length > 20)) {
    return false
  }
  if (numberField.some(field => validateNumberField(String(item[field])) !== null)) {
    return false
  }
  return true
}

const calcEmissions = (valueSource, scaleIndexValue) => {
  valueSource = Number(valueSource)
  scaleIndexValue = Number(formatValue(scaleIndexValue))
  // if (!valueSource && !scaleIndexValue) {
  //   return null
  // }

  if (!valueSource || !scaleIndexValue) {
    return 0
  }

  return valueSource * scaleIndexValue
}

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']

const partern = {
  initializeGrid(flexgrid) {
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= 100; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }

      if ((e.keyCode === KEYS_CODE.BACKSPACE || e.keyCode === KEYS_CODE.DELETE) && !flexgrid.selection.isSingleCell){
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      }
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (s.columns[colIndex].binding === 'wsu_source') {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
            cEvent.custom = true
            s.onCellEditEnded(cEvent)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      // update emissions when  wsu_source, energy_value change
      if (column.binding === 'scale_index_value') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s,'emissions' ), calcEmissions(currentItem.wsu_value, cellData), false, true)
        })
      }

      if (column.binding === 'wsu_source') {
        const dbIndex = store.getters['registerData/dbCustomizesOrDbMasters'].findIndex(item => item.id === cellData);
        s.deferUpdate(() => {
          if (dbIndex >= 0) {
            const dbItem = store.getters['registerData/dbCustomizesOrDbMasters'][dbIndex] || {}
            currentItem.db_index = dbIndex

            s.setCellData(row, getColumnIndexByBinding(s,'lease_asset_name' ), dbItem.item_name, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'scale_index_unit'), dbItem.unit, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_value'), dbItem.value_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_unit'), dbItem.unit_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(dbItem.value_source, currentItem.scale_index_value), false, true)
          } else {
            currentItem.db_index = null
            s.setCellData(row, getColumnIndexByBinding(s,'lease_asset_name' ), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'scale_index_unit'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_value'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'wsu_unit'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(currentItem.wsu_value, currentItem.scale_index_value), false, true)
          }
        })
      }

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let item = s.rows[e.row].dataItem;
      let column = s.columns[e.col];
      // auto ++ serial number
      if (!item.index) {
        item.index = flexgrid?.rows?.length - 1 || 1;
      }
      if (
        column.binding === 'emissions' ||
        column.binding === 'scale_index_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'wsu_unit' ||
        column.binding === 'lease_asset_name'
      ) {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData);
      }
    });
    flexgrid.pasted.addHandler(() => {
      flexgrid.autoSizeColumns();
      flexgrid.autoSizeRows(0, 0, true);
    });
    flexgrid.autoSizeColumns();
  },

  header(registerData, listLayout) {
    let companyMap = new wjGrid.DataMap(registerData.listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(registerData.listBranch.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
      },
      {
        header: '組織区分',
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 140,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '法人名',
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listBranch?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: 'リース資産名', // TSC.Thue
        binding: 'lease_asset_name',
        minWidth: 150,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClass: 'auto-increment',
      },
      {
        header: '備考1',
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: '備考2',
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: '規模を表す指標',
        align: 'center',
        columns: [
          {
            header: '数値', // numerical value
            binding: 'scale_index_value',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            inputType: 'float',
            dataType: 'Number',
          },
          {
            header: '単位', // unit
            binding: 'scale_index_unit',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: 'ソース',
            binding: 'scale_index_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ],
      },
      {
        header: '排出原単位', // numerical value
        align: 'center',
        columns: [
          {
            header: '数値', // numerical value
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
          },
          {
            header: '単位', // unit
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: '出典', // source
            binding: 'wsu_source',
            minWidth: 400,
            maxWidth: 950,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            dataMap: new wjGrid.DataMap(prepareMasterOrCustomizeDB(registerData.dbCustomizes), 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              itemsSource: prepareMasterOrCustomizeDB(registerData.dbCustomizes),
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
        ],
      },
      {
        header: '排出量', // scale_index_value × wsu_value
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 200,
        allowSorting: false,
        isRequired: false,
      },
    ];
  },

  handleEvent(view, bindingLayout,theGrid = null) {
    let isHandlingCollectionChange = false;
    let isSuccessAdd = true;
    let isSuccessEditBlank = true;

    view.collectionChanged.addHandler(throttle(async () => {
      isReverted = true
      let isSyncing = new Date().getTime()
      if (isHandlingCollectionChange) {
        return
      }
      const indexEdit = view._idx + 1
      let indexAdd = indexEdit + view.itemsEdited.length
      if (view.itemsEdited.length <= 0) {
        indexAdd = view.itemCount - view.itemsAdded.length + 1
      }

      isHandlingCollectionChange = true
      setTimeout(() => {
        isHandlingCollectionChange = false
      }, 100);

      let added = {}
      const addedReplacement = {
        id: 'id',
        duration_id: 'duration_id',
        month: 'month',
        year: 'year',
        type: 'type',
        db_customize_id: 'db_customize_id',
        db_master_id: 'db_master_id',
        emissions: 'emissions'
      }

      // handle update data
      let edited = {}
      let editedBlank = {}

      view.itemsEdited.forEach((itemEdited, index) => {

        if (itemEdited.isSyncing) {
          return
        }
        let dbItem = {}
        const dbId = itemEdited.wsu_source
        if (dbId) {
          dbItem = store.getters['registerData/getDbCustomizeOrDbMasterById'][dbId] || {};

          itemEdited.wsu_unit = dbItem?.unit_source;
          itemEdited.wsu_value = dbItem?.value_source;
          itemEdited.lease_asset_name = dbItem?.item_name;
          itemEdited.db_customize_id = dbItem?.origin_id;
          itemEdited.scale_index_unit = dbItem?.unit;
        } else {
          itemEdited.wsu_unit = null;
          itemEdited.wsu_value = null;
          itemEdited.lease_asset_name = null;
          itemEdited.db_customize_id = null;
          itemEdited.scale_index_unit = null;
        }


        itemEdited.scale_index_value = itemEdited.scale_index_value && formatValue(itemEdited.scale_index_value);
        itemEdited.emissions = calcEmissions(itemEdited.wsu_value, itemEdited.scale_index_value);

        // auto set data for layout
        const layoutsData = {}
        bindingLayout.forEach(key => {
          layoutsData[key] = itemEdited[key] || null
        })
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemEdited.organizational_division,
          company_name: itemEdited.company_name,
          ...layoutsData
        });


        let itemCustom = {
          id: itemEdited.id,
          organizational_division: itemEdited?.organizational_division,
          company_name: itemEdited?.company_name,
          lease_asset_name: itemEdited.lease_asset_name,
          scale_index_unit: itemEdited.scale_index_unit,
          scale_index_value: itemEdited.scale_index_value === 0 ? '0' : itemEdited.scale_index_value,
          scale_index_source: itemEdited.scale_index_source,
          emissions: itemEdited.emissions === null ? '0' : itemEdited.emissions,
          duration_id: store.state.registerData.duration, // from store
          month: store.state.registerData.month, // from store
          year: store.state.registerData.yearSelected,
          db_customize_id: dbItem?.origin_id,
          db_master_id: dbItem?.origin_id,
          type: dbItem?.type || 0,
          index: indexEdit + index,
          memo_1: itemEdited.memo_1 || null,
          memo_2: itemEdited.memo_2 || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };

        if (itemCustom.type > 0) {
          delete itemCustom.db_customize_id;
        } else {
          delete itemCustom.db_master_id;
        }
        // check before call api
        if (!isValidData(itemCustom) || (!itemCustom?.db_customize_id && !itemCustom?.db_master_id)) {
          return
        }
        delete itemEdited.db_index;

        if (itemCustom.id === BLANK_ID) {
          delete itemCustom.id
          editedBlank[index] = itemCustom
        }
        if(itemCustom.id && itemCustom.id !== BLANK_ID) {
          edited[index] = itemCustom
        }

        itemEdited.isSyncing = isSyncing
      });

      if (Object.values(edited).length || Object.values(editedBlank).length) {
        try {
          isSuccessEditBlank = false;
        isReverted = true

          const editedPromise = Object.values(edited).length ? updateListData(patternUrl, Object.values(edited)) : emptyPromise();
          let _ = await editedPromise
          const editedBlankPromise = Object.values(editedBlank).length ? addListData(patternUrl, Object.values(editedBlank)) : emptyPromise();

          let editedBlankRes = await editedBlankPromise
          isSuccessEditBlank = true;
          if (Object.keys(editedBlank).length) {
            let itemEdited = view.itemsEdited.filter((item) => item.isSyncing === isSyncing)
            batchReplacementViewCollection(itemEdited, editedBlankRes.record_insert, Object.keys(editedBlank), addedReplacement, true)
          }

          view.itemsEdited.forEach((item) => item.isSyncing = false)
          store.dispatch('commonApp/actionUpdateIsSuccess', true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch('commonApp/updateStatusAction', false, { root: true });
          }
          view.itemsEdited.length = 0
          isReverted = false
        } catch (_) {
          view.itemsEdited.forEach((item) => item.isSyncing = false)
          view.itemsEdited.length = 0
          isReverted = false
        }
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }

      view.itemsAdded.forEach((itemAdded, index) => {

        let db_item = {}
        if (itemAdded?.db_index) {
          db_item = store.getters['registerData/dbCustomizesOrDbMasters'][itemAdded.db_index] || {};
        } else {
          db_item = store.getters['registerData/getDbCustomizeOrDbMasterById'][itemAdded.wsu_source] || {};
        }

        itemAdded.lease_asset_name = db_item?.item_name;
        itemAdded.db_customize_id = db_item?.origin_id;
        itemAdded.scale_index_unit = db_item?.unit;
        itemAdded.wsu_unit = db_item?.unit_source;
        itemAdded.wsu_value = db_item?.value_source;
        itemAdded.scale_index_value = itemAdded.scale_index_value && formatValue(itemAdded.scale_index_value);
        itemAdded.emissions = calcEmissions(itemAdded.wsu_value, itemAdded.scale_index_value);

        // auto set data for layout
        const layoutsData = {}
        bindingLayout.forEach(key => {
          layoutsData[key] = itemAdded[key] || null
        })
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemAdded.organizational_division,
          company_name: itemAdded.company_name,
          ...layoutsData
        });


        let itemCustom = {
          organizational_division: itemAdded?.organizational_division,
          company_name: itemAdded?.company_name,
          lease_asset_name: itemAdded.lease_asset_name,
          scale_index_unit: itemAdded.scale_index_unit,
          scale_index_value: itemAdded.scale_index_value === 0 ? '0' : itemAdded.scale_index_value,
          scale_index_source: itemAdded.scale_index_source,
          emissions: itemAdded.emissions === null ? '0' : itemAdded.emissions,
          duration_id: store.state.registerData.duration, // from store
          month: store.state.registerData.month, // from store
          year: store.state.registerData.yearSelected,
          db_customize_id: db_item?.origin_id,
          db_master_id: db_item?.origin_id,
          type: db_item?.type || 0,
          index: indexAdd + index,
          memo_1: itemAdded.memo_1 || null,
          memo_2: itemAdded.memo_2 || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };

        if (itemCustom.type > 0) {
          delete itemCustom.db_customize_id;
        } else {
          delete itemCustom.db_master_id;
        }

        // checkk before call api
        if (!isValidData(itemCustom)) {
          return;
        }
        added[index] = itemCustom
      });

      if (Object.values(added).length) {
        view.itemsAdded.length = 0
        isSuccessAdd = false;
        isReverted = true
        addListData(patternUrl, Object.values(added)).then(res => {
          batchReplacementViewCollection(view.itemsAdded, res.record_insert, Object.keys(added), addedReplacement)
          // clear items added
          // view.itemsAdded.length = 0
          isSuccessAdd = true;

          store.dispatch('commonApp/actionUpdateIsSuccess', true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch('commonApp/updateStatusAction', false, { root: true });
          }
        })
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }
      // handle delete data
      let removed = {}
      view.itemsRemoved.forEach((itemRemoved, index) => {
        if (itemRemoved.id && itemRemoved.id !== BLANK_ID) {
          removed[index] = itemRemoved.id
        }
      });

      if (Object.values(removed).length) {
        const ids = Object.values(removed)
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
        deleteListData(`${patternUrl}?id[]=${ids.join('&id[]=')}`).then(() => {
          view.itemsRemoved.length = 0
        }).catch((_) => {
          view.itemsRemoved.length = 0
        })
      }

      // revert
      if (Array.isArray(view.itemsRevertDelete)) {
        let reverted = {}
        view.itemsRevertDelete.forEach((itemReverted, index) => {
          if (!isValidData(itemReverted) || (!itemReverted?.db_customize_id && !itemReverted?.db_master_id)) {
            return
          }
          reverted[index] = itemReverted
        })

        if (Object.values(reverted).length) {
          view.itemsRevertDelete.length = 0
          isReverted = true
          updateListData(patternUrl, Object.values(reverted), true).then(() => {
            isReverted = false
          })
        }
      }
    }, 300, false));
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'lease_asset_name',
    'scale_index_unit',
    'wsu_unit',
    'wsu_source',
  ],

  getError(item, propName) {
    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]));
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null
  },
};

export default partern;
