import { STATUS } from '@/constants/status';

export const getStatusNameById = (statusID) => {
  const status = STATUS.find(status => status.id === statusID);
  return status?.name
}

export const getStatusIdByName = (statusName) => {
  const status = STATUS.find(status => status.name === statusName);
  return status?.id
}
export const getColorStatusById = (statusID) => {
  const status = STATUS.find(status => status.id === statusID);
  return status?.color
}
