<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <h1 class="wrap-title"> 申請状況</h1>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div v-if="showTooltip" class="helper-icon tooltip"
                 v-html="'申請中のデータ登録の状況を確認できます。詳細ボタンをクリックすると、データ登録の内容が確認できます。'"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="category-table main-table custom-table color-column-status-table submited-table">
      <data-table
        :data-source="listSubmitGrid"
        :grid-columns="listSubmitColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        class="table-list-data"
        @changeFullScreen="onChangeFullScreen"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>

<script>
import DataTable from "@/components/category/data-table";
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import LogHistoryPopup from "@/components/dialogs/history-popup.vue";
import { getListCommentHistory, getListLogHistory, getListWorkflowApi } from "@/api/registerData";
import { getCategoryName, getDataLayer, getScopeName } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";

import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import { mapActions, mapState } from "vuex";
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import * as wjGrid from "@grapecity/wijmo.grid";
import { getColorStatusById, getStatusNameById } from "@/utils/status";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";

export default {
  name: "ListSubmitted",
  components: { DataTable, CommentLogPopup, LogHistoryPopup },
  data() {
    return {
      breadCrumb: [
        {
          text: "ホーム",
          disabled: false,
          href: ROUTES.HOME_DASHBOARD
        },
        {
          text: "申請状況",
          disabled: true,
          href: ROUTES.LIST_EMISSION_TEMPORARY
        }
      ],
      listSubmitColumn: [],
      listSubmitGrid: null,
      flexGrid: null,
      listSubmitData: [],
      dialog: false,
      logContents: [],
      showTooltip: false,
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: []
    };
  },
  async mounted() {
    await this.updateBreadCrumb(this.breadCrumb);
    await this.handleGetListSubmitData();
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration"]),

    initGrid(grid) {
      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }

          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );
      grid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      // add tooltip
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      await updateDataBeforeRedirect(rowData.item);
      this.$router.push({ path: `/emissions/register/list-submitted/${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.created_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.updated_at),
          name: listHistoryChanged.content,
          status: getStatusNameById(listHistoryChanged.status),
          color: getColorStatusById(listHistoryChanged.status)
        };
      });
    },
    async handleGetListSubmitData() {
      // TODO: call API here
      const payload = {
        action: "listSubmit"
      };
      const listSubmitResponse = await getListWorkflowApi(payload);
      if (listSubmitResponse.data.length > 0) {
        this.listSubmitData = listSubmitResponse.data.map(listSubmitItem => {
          return {
            ...listSubmitItem,
            data_id: listSubmitItem.data_id,
            title: listSubmitItem.title,
            status: getStatusNameById(listSubmitItem.status),
            contractor_name: listSubmitItem.contractor?.name,
            log_history: "ログ表示",
            scope: getScopeName(parseInt(listSubmitItem.scope)),
            category: getCategoryName(parseInt(listSubmitItem.scope), parseInt(listSubmitItem.category)),
            layer1: getDataLayer(listSubmitItem.scope, listSubmitItem.category, listSubmitItem.pattern_id)?.method,
            layer2: getDataLayer(listSubmitItem.scope, listSubmitItem.category, listSubmitItem.pattern_id)?.sub_method,
            date_submit: formatDateTimeDataTable(listSubmitItem.submitted_at),
            month_year_registration: `${listSubmitItem.year}/${String(listSubmitItem.month).padStart(2, "0")}`,
            last_modified: formatDateTimeDataTable(listSubmitItem.updated_at),
            iconComment: listSubmitItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : "",
            created_at: formatDateTimeDataTable(listSubmitItem.submitted_at),
            return_at: formatDateTimeDataTable(listSubmitItem.return_at),
            approver: listSubmitItem.selected_approver?.name,
            approved_by: listSubmitItem.approved_by,
            scope_id: parseInt(listSubmitItem.scope),
            category_id: parseInt(listSubmitItem.category),
            duration_id: listSubmitItem.duration_id
          };
        });
      }
      this.defineTable();
    },
    handleEvent() {
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;

      if (!flexgrid) {
        return;
      }
      const templateResponse = getListWorkflowApi(payload);
      if (templateResponse.data.length > 0) {
        this.listSubmitData = templateResponse.data.map(templateItem => {
          return {
            ...templateItem,
            template_id: templateItem.id,
            scope: getScopeName(parseInt(templateItem.scope)),
            category: getCategoryName(parseInt(templateItem.scope), parseInt(templateItem.category)),
            layer1: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.method,
            layer2: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.sub_method,
            last_modified: formatDateTimeDataTable(templateItem.updated_at),
            created_at: formatDateTimeDataTable(templateItem.created_at),
            author: templateItem.submitter?.name,
            approver: templateItem.selected_approver?.name
          };
        });
      }
      this.defineTable();
    },
    initialView() {
      if (this.listSubmitGrid) {
        this.listSubmitData = this.listSubmitGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.listSubmitGrid = new CollectionView([...this.listSubmitData], {
        trackChanges: true
      });
      // this.addBlankItemsToView(10);
      this.handleEvent();
      this.flexGrid.columnGroups = this.getListSubmitColumn();
      this.markUnreadRows();
    },
    markUnreadRows() {
      this.flexGrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.listSubmitGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread = currentItem !== undefined && currentItem["unread_flg"] !== undefined ? currentItem["unread_flg"] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add("wj-has-unread-mark");
            }
          }
        }
      });
    },
    addBlankItemsToView(count) {
      const lastClientId = this.listSubmitGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.listSubmitGrid.addNew(this.blankData(lastClientId + index));
      }

      this.listSubmitGrid.commitNew();
      this.listSubmitGrid.clearChanges();
    },
    getListSubmitColumn() {
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: " ",
          binding: "data_id",
          minWidth: 46,
          maxWidth: 46,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: "template-detail-header",
          cellTemplate: CellMaker.makeButton({
            text: "詳細",
            click: (event, context) => this.onIdClicking(context)
          })
        },
        {
          header: "ステータス",
          binding: "status",
          minWidth: 105,
          maxWidth: 120,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cellTemplate: "<span class=\"${(item.status === \"代理承認済み\" || item.status === \"承認済み\") ? \"mono-text\" : (item.status === \"申請取消中\" || item.status === \"申請中\" || item.status === \"申請取消要請\") ? \"blue-text\" : (item.status === \"申請差戻\" || item.status === \"代理申請差戻\") ? \"red-text\" : \"normal\"}\">${item.status}</span>"
        },
        {
          header: " ",
          binding: "iconComment",
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll: "approval-cell_comment hide-filter comment-icon",
          multiLine: true,
          wordWrap: true
        },
        {
          header: "タイトル",
          binding: "title",
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "申請日",
          binding: "created_at",
          minWidth: 196,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "差戻日",
          binding: "return_at",
          minWidth: 165,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "承認者",
          binding: "approver",
          minWidth: 80,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "法人名",
          binding: "contractor_name",
          minWidth: 86,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "スコープ",
          binding: "scope",
          minWidth: 86,
          maxWidth: 100,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: "",
          multiLine: true,
          wordWrap: true
        },
        {
          header: "カテゴリ",
          binding: "category",
          minWidth: 86,
          maxWidth: 100,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "算定方法",
          align: "center",
          columns: [
            {
              header: "1段目",
              binding: "layer1",
              minWidth: 74,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true
            },
            {
              header: "2段目",
              binding: "layer2",
              minWidth: 74,
              width: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true
            }
          ]
        },
        {
          header: "登録年月",
          binding: "month_year_registration",
          minWidth: 92,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "最終更新日",
          binding: "last_modified",
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: "変更履歴",
          binding: "log_history",
          minWidth: 72,
          maxWidth: 72,
          allowSorting: false,
          isRequired: false,
          cssClassAll: "submit-log",
          cellTemplate: CellMaker.makeButton({
            text: "${text? text : \"選択\" }",
            click: (e, ctx) => this.onHistoryClicking(ctx)
          })
        }
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    defineTable() {
      this.listSubmitColumn = this.getListSubmitColumn();
      this.initialView();

    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    }
  },
  computed: {
    ...mapState("commonApp", ["isExpand"]),
    ...mapState("registerData", ["isFullScreen"])
  }
};
</script>


<style lang="scss">
.submited-table {
  .template-detail-header {
    padding: 2px 4px !important;

    .wj-btn {
      display: none;
    }
  }

  .wj-flexgrid .wj-cell[aria-readonly] {
    background: #F7F7F2 !important;
    color: #404d50 !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].submit-log {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].wj-state-active {
    background: #F7F7F2 !important;
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #404D50;
    font-family: 'Source Han Sans JP';
    padding: 0;
  }

  .wj-cell {
    .wj-cell-maker {
      justify-content: start;
    }

    .wj-cell-maker:hover {
      color: #ffffff;
      background: #0072a3;
    }

  }

  .template-detail-header {
    .wj-cell-maker {
      // margin-top: 2px;
      font-style: normal;
      font-family: 'Source Han Sans';
      width: 100%;
      color: #948810 !important;
      position: unset !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid #A4A184;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .hide-filter {
    .wj-btn {
      display: none;
    }
  }

  .submit-log.wj-cell {
    .wj-btn {
      display: none;
    }

    .wj-cell-maker {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 6px 3px;
      background: #E4E6E7;
      font-family: 'Source Han Sans';
      border-radius: 4px;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #404D50 !important;
      min-width: 64px;
      min-height: 24px;
      position: unset !important;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .approval-pending {
    color: #0072A3;
  }

  .approval-send-back {
    color: #E45B58;
  }

  .approval-complete {
    color: #99A6A9;
  }
}
</style>
<style scoped lang="scss">
.custom-table {
  padding-left: 20px !important;
}

.wrap {
  width: 100%;
  height: 100%;

  .wrap__block__header__content {
    display: flex;
    align-items: center;
    // tooltip icon
    .helper-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;

      img {
        z-index: 1;
      }

      &.tooltip {
        display: block;
        justify-content: left;
        background: $monoWhite;
        border-radius: 4px;
        color: $monoBlack;
        margin: 0 auto;
        font-size: 15px;
        padding: 10px;
        height: unset;
        // width: 315px;
        max-width: max-content;
        // position: absolute;
        right: unset;
        left: 0px;
        bottom: unset;
        z-index: 9999;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
        0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
        0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        position: fixed;
        width: 100%;
      }
    }
  }

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 45px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      @media (max-width: 768px) {
        padding-top: 40px;
      }

      &__content {
        flex-direction: row;
      }

      span, .wrap-title {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .wrap__block {
    margin-left: 40px;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
