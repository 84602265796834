<template>
  <div>
    <v-dialog
      v-model="getDialog"
      ref="dialog"
      :width="boxWidth"
      content-class="dialog-popup radio-dialog checkbox-popup"
      @click:outside="handleClickOutSite"
    >
      <div class="head-dialog-popup">
        <span class="head-image-dialog-popup__text">{{descriptionText}}</span>
        <div class="selected-count">{{ getSelectedData.length }}項目選択中</div>
      </div>
      <hr class="divider" />
      <div class="content-dialog-popup">
        <div
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse']"
        > 
          <div v-if="hasSearch" class="search-text">
            <input v-model="searchText" type="text" class="input-elic" placeholder="法人を検索" style="width: 100%;">
            <img :src="require('@/assets/icons/pulldown/search-icon.svg')" alt="search icon" class="search-icon">
          </div>
          <div
            v-for="(item, index) in methodsList"
            :key="index"
            class="item pull-down-text-color"
          >
            <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
              <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
              <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
              <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
            </div>
            <div v-else class="toggle-wrapper layer1">
              <div v-if="isCollapsedProp" class="toggle-label" @click="toggleCollapse(index)" :class="isActiveMethod(item.text) && 'is-checked'">
                <span>{{ item.text }}</span>
                <img :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsed[index]}" />
              </div>
              <div class="content-container" v-if="isCollapsed[index] || !isCollapsedProp">
                <div
                  class="item pull-down-text-color"
                  v-for="(itemLayer1, layer1Index) in getMethodLayer1(index)"
                  :key="layer1Index"
                >
                <div v-if="itemLayer1.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text)}">
                  <input v-model="getSelectedData" type="checkbox" :label="itemLayer1.row_num_pattern" :value="item.text + '_' + itemLayer1.text" :id="`${item.text}_${itemLayer1.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer1.text }}</label>
                </div>
                <div v-else class="toggle-wrapper layer2">
                  <div class="toggle-label" @click="toggleCollapseLayer1(index + layer1Index)" :class="isActiveMethod(item.text + '_' + itemLayer1.text) && 'is-checked ' + item.text + '_' + itemLayer1.text">
                    <span>{{ itemLayer1.text }}</span>
                    <img v-if="isCollapsedProp" :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsedLayer1[index + layer1Index]}" />
                  </div>
                  <div class="content-container" v-if="isCollapsedLayer1[index + layer1Index] || !isCollapsedProp">
                    <div
                      class="item pull-down-text-color"
                      v-for="(itemLayer2, layer2Index) in getMethodLayer2(layer1Index, index)"
                      :key="layer2Index"
                    >
                    <div v-if="itemLayer2.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text + '_' + itemLayer2.text)}">
                      <input v-model="getSelectedData" type="checkbox" :label="itemLayer2.row_num_pattern" :value="item.text + '_' + itemLayer1.text + '_' + itemLayer2.text" :id="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer2.text }}</label>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action-dialog-popup button-bottom-dialog-popup">
        <common-button class="cancle-btn" @action="close" label="キャンセル"></common-button>
        <common-button class="confirm" @action="submit" type="colored" :label="confirmText"></common-button>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import {GET_ALL} from '@/constants/export-report.js'
import CommonButton from '@/components/utils/button.vue';
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'OK',
    },
    methods: {
      type: Array,
      default: () => [],
    },
    descriptionText: {
      type: String,
      default: '',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%'
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    itemCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      isCollapsed: [],
      isCollapsedLayer1: [],
      searchText: '',
      selectionShow: false,
      getSelectedData : [],
      methodsList: [],
      getAllData: false,
      isChangeGetAll: false
    };
  },
  mounted() {
    this.methodsList = this.methods;
    this.getSelectedData = [...this.selectedData];
    this.boxWidth = this.getWidth();
    this.onWindowResize();
    if(this.getSelectedData.length === this.itemCount) {
      this.getAllData = true
    }
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  watch: {
    searchText: {
      handler(value) {
        this.methodsList = this.methods.filter(item => item.text.toLowerCase().includes(value.toLowerCase()));
      }
    },
    getAllData: {
      handler(newValue) {
        if (newValue) {
          this.getSelectedData.length = 0;
          this.methodsList.map(item => {
            if (!item?.methods) {
              this.getSelectedData.push(`${item.text}`);
            } else {
              item?.methods?.map(itemLayer1 => {
                if (!itemLayer1?.methods) {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
                } else {
                  itemLayer1?.methods?.map(itemLayer2 => {
                    this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                  })
                }
              })
            }
          })
          const existIndex = this.getSelectedData.indexOf(GET_ALL);
          if (existIndex !== -1) {
            this.getSelectedData.splice(existIndex, 1);
          }
        } else {
          if(!this.isChangeGetAll) {
            this.getSelectedData.length = 0;
          }
        }
      }
    },
    getSelectedData: {
      handler(newValue, oldValue) {
        if (newValue.length < oldValue.length) { // remove getAll when uncheck item
          this.isChangeGetAll = true;
          this.getAllData = false;
        } else {
          this.isChangeGetAll = false;
          // if(this.getSelectedData.length === this.methods.length - 1) {
          //   this.getAllData = true
          // }
        }
      }
    },
  },
  methods: {
    handleClickOutSite() {
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit', this.getSelectedData);
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth >= 1024) {
        return '464px';
      } else if (window.innerWidth >= 375) {
        return '335px';
      }
      return '100%';
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.getWidth();
      }, 100);
    },
    getMethodLayer1(index) {
      return this.methods[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.methods[index]?.methods[layer1Index]?.methods;
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    toggleCollapseLayer1(index) {
      this.$set(this.isCollapsedLayer1, index, !this.isCollapsedLayer1[index]);
    },
    isGetAll(text) {
      return text === GET_ALL;
    },
    isCheckedBox(text) {
      return this.getSelectedData.indexOf(text) !== -1;
    },
    isActiveMethod(text) {
      return this.getSelectedData.filter(item => item.includes(text)).length > 0
    }
  },
};
</script>
<style lang="scss">
.checkbox-popup {
  width: 560px !important;
  max-width: 560px !important;
  max-height: 90vh !important;
  background: $monoOffWhite;
  .divider {
    width: 100% !important;
  }
  @media only screen and (min-width: 375px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        // padding-bottom: 0 !important;
        width: 100%;
        gap: 8px;
        .common-btn {
          width: calc((100% - 8px) / 2) !important;
          max-width: unset !important;
          margin-inline: 0 !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
  @media only screen and (min-width: 1024px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        .common-btn {
          width: 100%;
          max-width: 268px !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
@import '@/components/dialogs/DialogPopupStyle/index.scss';
.checkbox-popup {
  .head-dialog-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  .item-scroll-none {
    overflow: hidden !important;
  }
  .selection-pulldown {
    max-height: calc(90vh - 120px);
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;

    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        white-space: normal;
        cursor: pointer;
        position: relative;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
          position: relative;

          &[type=checkbox]:checked {
            appearance: none;
            box-shadow: none;
            text-align: center;
            width: 16px !important;
            height: 16px !important;
            background: $blueMid;
          }
          &[type=checkbox]:checked:before {
            content: url('../../assets/icons/checkedbox.svg');
            color: $monoWhite;
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
        label {
          width: calc(100% - 40px);
          cursor: pointer;
          word-wrap: break-word;
        }
        
        &:hover {
          background: $bgLight;
        }
        &.is-checked {
          &::before {
            content: '';
            height: 100%;
            border: 2px solid $blueMid;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .toggle-wrapper {
        width: 100%;
        cursor: pointer;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          white-space: normal;
          &.is-checked {
            &::before {
              content: "";
              height: 40px;
              border: 2px solid $blueMid;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .toggle-icon {
            width: 10px;
            height: 10px;
            &.arrow-top {
              rotate: (180deg);
            }
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
          }
        }
        .content-container {
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
        }
      }
      &:first-child {
        border-top: 0;
      }
    }
    .search-text {
      position: relative;
      background: $monoWhite;
      .input-elic {
        width: 100% !important;
        margin: unset;
        box-shadow: unset;
        background: $monoWhite;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }

    &.dont-collapse {
      .item {
        .export-checkbox {
          padding: 7px 0px 9px 16px;
        }
        .layer1 {
          .export-checkbox {
            padding: 7px 0px 9px 16px;
          }
          .layer2 {
            .toggle-label {
              display: block;
              padding: 7px 14px 9px 16px;
              font-size: 13px;
              font-weight: 700;
              letter-spacing: 0.05em;
              color: $monoBlack;
              background: rgba(121, 134, 134, 0.12);
              &.is-checked {
                &::before {
                  content: "";
                  height: 40px;
                  border: 2px solid $blueMid;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
            .export-checkbox {
              padding: 7px 14px 9px 16px;
            }
          }
        }
      }
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
