<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <span>カスタマイズDB管理</span>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div
              v-if="showTooltip"
              class="helper-icon tooltip"
              v-html="'独自で使用している原単位やIDEA、CFP DBなどの原単位をまとめたDBを作成します。'"
            ></div>
          </div>
        </div>
      </div>
      <div class="wrap__status">
        <div class="wrap__status__item">
          <img src="@/assets/icons/clock.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Last Update</p>
            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
          </div>
        </div>
        <div class="wrap__status__item">
          <img src="@/assets/icons/userTable.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>Updated By</p>
            <p>{{ statusUpdate.user || "" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="casbee-menu">
      <default-pull-down-menu
        v-if="isCasbee"
        v-model="casbeeModel"
        :items="casbeeList"
        :initialValue="''"
        placeHolderText="計算式を利用して原単位を追加"
        class="casbee-pulldown casbee-type"
        :width="'256px'"
      />
    </div>
    <div
      class="category-table main-table custom-table center-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="dbCustomViews"
        :init-grid="flexInitialized"
        :grid-columns="gridColumns"
        :totalData="totalData"
        :rowFocus="2"
        :isHasData="isHasData"
        :cellInput="cellInput"
        @changeFullScreen="onChangeFullScreen"
        @onHandleActionTable="checkValidationData"
      />
    </div>
    <question-popup :dialog="dialogPopup" :message="popupMessage" @submit="checkboxHandler" @close="closePopup" />
    <radio-popup
      :dialog="radioDialog"
      :radioType="radioType"
      :items="items"
      :showDescription="false"
      :currentSelect.sync="currentSelected"
      @onChange="onChangeRadio"
      @close="closeRadioPopup"
      style="z-index: 9999"
    />
    <notification-popup :dialog="dialogNotification" :message="'変更内容を保存しました。'" @submit="dialogNotification = false" />
    <notification-popup :dialog="isShowMessageCasbe" :message="messageCase" @submit="handleClosePopupCasbee" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ROUTES } from "@/router/constants";
import * as wjGrid from "@grapecity/wijmo.grid";
import DataTable from "@/components/category/data-table";
import RadioPopup from "@/components/dialogs/radio-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import {
  Tooltip,
  PopupPosition,
  CollectionView
} from "@grapecity/wijmo";
import * as wjcCore from "@grapecity/wijmo";
import { createDbCustom, getDbCustom, updateDbCustom } from "@/api/dbCustomization";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import {
  validateMaximumCharactor as validateMaximumCharacter,
  validateMaximumCharactorOnlyFacility,
  validateBlankRowData
} from "@/utils/validate";
import { formatDateTime, formatDateTimeDataTable } from "@/utils/datetimeFormat";
import QuestionPopup from "@/components/dialogs/question-popup.vue";
import { AutoComplete } from "@grapecity/wijmo.input";
import { UndoStack } from "@grapecity/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getUserInfo } from "@/api/auth";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import debounce from "lodash/debounce";
import { batchReplacementViewCollection, emptyPromise } from "@/concerns/registerData/wijmo.helper";
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { CASBEE, CASBEE_TYPE } from '@/constants/casbee';

export default {
  components: {
    DataTable,
    QuestionPopup,
    RadioPopup,
    NotificationPopup,
    DefaultPullDownMenu
  },
  data() {
    return {
      currentSelected    : null,
      diffText           : "その他",
      popupMessage       : "この原単位を無効にします。\nよろしいですか?",
      confirmText        : "変更する",
      selectionButtonText: "選択",
      addedSource        : [],
      totalData          : null,
      statusUpdate       : {
        time: null,
        user: null
      },
      context            : null,
      items              : null,
      activeRow          : null,
      radioType          : null,
      radioDialog        : false,
      types              : {
        category: "Categories",
        scope   : "Scopes"
      },
      dialogPopup        : false,
      dialogCancelBtn    : false,
      dialogMessage      : "チェックを外します。\n よろしいですか。",
      dialogBaseMessage  : "チェックを外します。\n よろしいですか。",
      // item
      dbCustomsFlex       : null,
      selectedItem        : "",
      gridColumns         : [],
      dbCustoms           : null,
      dbCustomViews       : null,
      scopes              : null,
      categories          : null,
      sources             : ["IDEA"],
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      idsUpdate           : [],
      dataRedo            : [],
      restoreSelection    : null,
      pastingData         : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      checkAction         : false,
      dataDisabledAction  : null,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      stackUndoScope      : null,
      stackUndoCategory   : null,
      buttonEvent         : null,
      bindings            : {
        name        : "name",
        item_name   : "item_name",
        unit        : "unit",
        value_source: "value_source",
        unit_source : "unit_source",
        source      : "source",
        scope_id    : "scope_id",
        category_id : "category_id",
        note        : "note",
        updated_at  : "updated_at",
        status      : "status"
      },
      userName            : "",
      showTooltip         : false,
      listOriginalData    : [],
      dialogNotification: false,
      cellInput: ['name', 'item_name', 'unit', 'value_source', 'unit_source', 'source', 'note'],
      isCasbee: false,
      casbeeList: [],
      casbeeModel: null,
      isShowMessageCasbe: false,
      messageCase: ''
    };
  },
  async mounted() {
    this.actionUpdateIsFullScreen(false);
    let items = [
      {
        text    : "ホーム",
        disabled: false,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : "設定",
        disabled: false,
        href    : ROUTES.SETTING
      },
      {
        text    : "カスタマイズDB管理",
        disabled: true,
        href    : ROUTES.CREATE_DATA_CUSTOMIZE
      }
    ];
    this.casbeeList.push(
      {
        name: CASBEE.CASBEE,
        value: CASBEE.CASBEE
      },
    );
    this.updateBreadCrumb(items);
    if(this.$route.query?.registeCasbe) {
      this.isShowMessageCasbe = true;
      this.messageCase = `CASBEE対応原単位が${this.$route.query.registeCasbe}行追加されました。`
    }
    await this.checkUserInfo();
    await this.fetchDbCustoms();
    this.createUndoStack();
  },
  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand", "statusAction"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("actionsTable", ["dataAction"]),
    ...mapState("registerData", ["isFullScreen"]),

    isHasData() {
      return this.canUndo || this.canRedo;
    },
  },
  methods : {
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen"]),
    ...mapActions("commonApp", ["updateBreadCrumb", "updateStatusAction"]),

    getGridColumn() {
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false,
          minWidth    : 0
        },
        {
          header      : "契約者名",
          binding     : "name",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "項目名",
          binding     : "item_name",
          allowSorting: false,
          isRequired  : false,
          minWidth    : 110,
          maxWidth    : 980,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : "活動量（単位）",
          binding     : "unit",
          allowSorting: false,
          isRequired  : false,
          align       : "left",
          minWidth    : 153,
          maxWidth    : 200,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : "原単位（数値）",
          binding     : "value_source",
          allowSorting: false,
          isRequired  : false,
          inputType   : "float",
          align       : "right",
          minWidth    : 153,
          maxWidth    : 200,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : "原単位（単位）",
          binding     : "unit_source",
          allowSorting: false,
          isRequired  : false,
          align       : "left",
          minWidth    : 153,
          maxWidth    : 200,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header       : "出典",
          binding      : "source",
          allowSorting : false,
          editor       : new AutoComplete(document.createElement("div"), {
            itemsSource: this.sources,
            maxItems   : 1000,
            minLength  : 1,
            lostFocus: (sender) => {
              let item = sender.text;
              if (item && this.sources.indexOf(item) < 0) {
                this.sources.push(item);
              }
            }
          }),
          isRequired   : false,
          dataMapEditor: "DropDownList",
          align        : "left",
          minWidth     : 343,
          maxWidth     : 980,
          wordWrap     : true,
          cssClassAll  : "single-row"
        },
        {
          header      : "スコープ",
          binding     : "scope_id",
          allowSorting: false,
          align       : "center",
          cssClass    : "btn-db",
          cellTemplate: CellMaker.makeButton({
            text : "${text? text : \"選択\" }",
            click: (e, ctx) => this.onScopeClicking(ctx)
          }),
          isRequired  : false,
          minWidth    : 114,
          maxWidth    : 125,
          wordWrap    : true,
          cssClassAll : "single-row dbCustomize-row"
        },
        {
          header      : "カテゴリ",
          binding     : "category_id",
          allowSorting: false,
          align       : "center",
          cellTemplate: CellMaker.makeButton({
            text : "${text? text : \"選択\" }",
            click: (e, ctx) => this.onCategoryClicking(ctx)
          }),
          cssClass    : "btn-db",
          minWidth    : 114,
          maxWidth    : 125,
          wordWrap    : true,
          cssClassAll : "single-row dbCustomize-row"
        },
        {
          header      : "メモ",
          binding     : "note",
          allowSorting: false,
          align       : "left",
          isRequired  : false,
          minWidth    : 88,
          maxWidth    : 980,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : "最終更新日時",
          binding     : "updated_at",
          allowSorting: false,
          isReadOnly  : true,
          align       : "center",
          minWidth    : 150,
          maxWidth    : 160,
          wordWrap    : true,
          cssClassAll : "no-tooltip single-row"
        },
        {
          header      : "有効/無効",
          binding     : "status",
          allowSorting: false,
          align       : "center",
          dataType    : "Boolean",
          minWidth    : 120,
          maxWidth    : 150,
          wordWrap    : true,
          cssClassAll : "single-row"
        },
        {
          header      : " ",
          binding     : "view_detail",
          allowSorting: false,
          align       : "center",
          maxWidth    : 46,
          wordWrap    : true,
          cssClassAll : "single-row casbee-detail",
          cellTemplate: CellMaker.makeButton({
            text : "${text? text : \"詳細\" }",
            click: (e, ctx) => this.onViewDetailClicking(ctx)
          }),
        }
      ];
    },
    defineTable() {
      this.gridColumns = this.getGridColumn();

      this.initialView();
    },

    isVerifiedData(data) {
      let isVerified = true;
      data?.forEach((item) => {
        let {
              note,
              category_id,
              status,
              id,
              ...restItem
            }     = item;
        let check = Object.values(restItem).every((value) => value);
        if (!check) {
          isVerified = false;
        }

        if (item.scope_id == "1" || item.scope_id == "2" || item.scope_id == "その他") {
          return;
        }

        // Category or scope_id is undefined when pasting
        if (!category_id || !item.scope_id) {
          isVerified = false;
        }

        // Select scope 3 and not select category
        if (item.scope_id == 3 && category_id == this.selectionButtonText) {
          this.undoStack.pushAction(this.stackUndoScope);
          this.stackUndoScope = null;
          isVerified          = false;
        }
      });

      return isVerified;
    },
    flexInitialized(flexgrid) {
      this.dbCustomsFlex = flexgrid;

      flexgrid.scrollPositionChanged.addHandler(
        debounce((s, e) => {
          if (!this.$store.state.registerData.isFullScreen) {
            return;
          }

          if (s.viewRange.bottomRow >= s.rows.length - 1) {
            s.deferUpdate(() => {
              const lastClientId = flexgrid.itemsSource.itemCount;

              for (let index = 1; index <= 100; index++) {
                s.itemsSource.addNew(this.blankData(lastClientId + index));
              }

              s.itemsSource.commitNew();
              s.itemsSource.clearChanges();
            });
          }
        }, 100)
      );

      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 2);
              flexgrid.selection     = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (flexgrid.selection.row === flexgrid.rows.length - 1) {
              const lastClientId = flexgrid.itemsSource.itemCount;

              flexgrid.deferUpdate(() => {
                flexgrid.itemsSource.addNew(this.blankData(lastClientId + 1));

                flexgrid.itemsSource.commitNew();
                flexgrid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );

      flexgrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (s.cells === e.panel && s.columns[e.col].binding === this.bindings.category_id) {
          if (!s.rows[e.row].dataItem || (s.rows[e.row].dataItem && s.rows[e.row].dataItem.scope_id != 3)) {
            wjcCore.addClass(e.cell, "btn-disabled");
          } else {
            wjcCore.removeClass(e.cell, "btn-disabled");
          }
        }
        if (s.cells === e.panel && s.columns[e.col].binding === this.bindings.scope_id) {
          if (!s.rows[e.row].dataItem) {
            wjcCore.addClass(e.cell, "btn-disabled");
          }
        }
        if (e.panel == s.cells && colBinding === 'source') {
          if (Object.values(CASBEE_TYPE).includes(s.rows[e.row]._data?.pattern_type)) {
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-down"></span>', '');
          }
        }
        if (e.panel == s.cells && colBinding === 'view_detail') {
          if (s.rows[e.row]._data?.pattern_type === null || !s.rows[e.row]._data?.pattern_type) {
            e.cell.innerHTML = '';
          }
        }
        const colBindingTooltip = [
          "name",
          "item_name",
          "unit",
          "value_source",
          "unit_source",
          "value_unit",
          "source",
          "note",
          "status",
          "scope_id",
          "category_id",
          "view_detail"
        ];
        if (!colBindingTooltip.includes(colBinding)) {
          return;
        }
        if (e.panel === s.columnHeaders) {
          if (colBinding === "item_name") {
            toolTipCustom(
              e,
              "t1",
              " デフォルトで用意されている環境省DB以外の原単位DB、サプライヤから提供されたLCA情報等として登録したい項目名を入力します",
              this.hdrTips
            );
          }
          if (colBinding === "unit") {
            toolTipCustom(e, "t2", "項目名に対応する「活動量の単位」を設定します。", this.hdrTips);
          }
          if (colBinding === "value_source") {
            toolTipCustom(e, "t3", " 項目名に対応する「原単位の数値」を登録します。", this.hdrTips);
          }
          if (colBinding === "value_unit") {
            toolTipCustom(e, "t4", " 項目名に対応する「原単位の単位」を登録します。", this.hdrTips);
          }
          if (colBinding === "unit_source") {
            toolTipCustom(e, "t5", " 項目名に対応する「原単位の単位」を登録します。", this.hdrTips);
          }
          if (colBinding === "source") {
            toolTipCustom(
              e,
              "t6",
              " 原単位の数字の出典元を記載します。GHG排出量算定結果について第三者検証を受ける際にも活用します。",
              this.hdrTips
            );
          }
          if (colBinding === "scope_id") {
            toolTipCustom(e, "t7", "登録した項目・原単位を利用するScopeを指定します。", this.hdrTips);
          }
          if (colBinding === "category_id") {
            toolTipCustom(e, "t8", "登録した項目・原単位を利用するCategoryを指定します。", this.hdrTips);
          }
          if (colBinding === "note") {
            toolTipCustom(
              e,
              "t9",
              "原単位の設定経緯や根拠の記録、複数の担当者間での連携目的等、ご自由に活用ください。",
              this.hdrTips
            );
          }
          if (colBinding === "status") {
            toolTipCustom(
              e,
              "t10",
              "登録した項目・原単位を利用するか指定します。「無効」とするとデータ登録画面のプルダウン選択肢に表示されなくなりますが、既に登録済みのデータへの影響はありません。",
              this.hdrTips
            );
          }
          if (colBinding === "view_detail") {
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-filter"></span>', '');
          }
        }
      });

      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];

        let rowValue            = s.rows[e.row]._data;

        if(Object.values(CASBEE_TYPE).includes(rowValue.pattern_type) && column.binding !== "status") {
          e.cancel = true;
          return;
        }
        if (column.binding !== "status") {
          return;
        }

        this.dataDisabledAction = rowValue;

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        this.stackUndoScope       = new GridEditAction(s, e);
        this.stackUndoCategory    = new GridEditAction(s, e);
        e.cancel                  = true;

        if (!rowValue.status) {
          this.updateStatus(rowValue);
          return;
        }

        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      });

      flexgrid.pastingCell.addHandler((s, e) => {
        // The handle while pasting value is only found once in the source.
        // Add into source before checking in editing cell handler.
        // Khi coppy rows trường hợp data dán vào chứa field source(dropdown) chưa tồn tại trong list -> Handle nó
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding == this.bindings.source) {
          let pasteData                                   = e.data;
          s._items.items[e.row][s.columns[e.col].binding] = pasteData;
          this.addedSource.push(pasteData);
        }
      });
      flexgrid.pasted.addHandler((s, e) => {
        const pasteData = e.data.split("\n").map((rawData) => rawData.split("\t"));
        pasteData.forEach((rowData, index) => {
          const scopeValueData = rowData[5];
          if (scopeValueData) {
            flexgrid.setCellData(e.range.row + index, 7, scopeValueData);
          }

          const categoryData = rowData[6];

          if (categoryData) {
            flexgrid.setCellData(e.range.row + index, 8, categoryData);
          } else {
            if (scopeValueData == "1" || scopeValueData == "2" || scopeValueData == "その他") {
              flexgrid.setCellData(e.range.row + index, 8, "選択");
            }
          }
        });

        if (e.range.row === e.range.row2) {
          // paste one row
          s.onItemsSourceChanged();
        }

        const { row, row2 } = e.range;
        const dataPasted = s.collectionView.sourceCollection.filter((item, index) => index >= row && index <= row2);
        dataPasted.forEach(rowItem => {
          if(this.isItemActive(rowItem)) {
            rowItem.status = true
          }
        })
      });
      flexgrid.pasted.addHandler(() => {
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      });
      flexgrid.autoSizeColumns();

      flexgrid?.cellEditEnded.addHandler((s, e) => {
        const { row } = e.range;
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};
        if(this.isItemActive(currentItem)) {
          currentItem.status = true
        }

      });
    },
    preprocessFetchedData(data) {
      let preprocessedData = [];
      data.forEach((element) => {
        let itemData = {
          id          : element.id,
          name        : this.userName,
          scope_id    : element.scope_id == 4 ? this.diffText : element.scope_id,
          category_id : element.category_id == 16 ? this.diffText : element.category_id,
          item_name   : element.item_name,
          unit        : element.unit,
          value_source: element.value_source,
          unit_source : element.unit_source,
          source      : element.source,
          status      : element.status ? true : false,
          note        : element.note,
          updated_at  : formatDateTimeDataTable(element.updated_at),
          pattern_type: element.pattern_type,
          db_customize_detail_id: element.db_customize_detail_id,
        };
        if (element.scope_id != 3) {
          itemData.category_id = this.selectionButtonText;
        }
        preprocessedData.push(itemData);
      });
      return preprocessedData;
    },
    preprocessAddData(data) {
      // Preprocess data for creating new custom DB.
      if (data.scope_id === undefined || data.category_id === undefined) return;
      let itemData = {
        scope_id     : data.scope_id === this.diffText ? 4 : data.scope_id,
        category_id  : data.category_id === this.diffText ? 16 : data.category_id,
        contractor_id: this.contractor,
        item_name    : data.item_name,
        unit         : data.unit,
        value_source : data.value_source,
        unit_source  : data.unit_source,
        source       : data.source,
        status       : data.status === false ? 0 : 1,
        note         : data.note === undefined ? "" : data.note
      };
      if (parseInt(data.scope_id) === 1 || parseInt(data.scope_id) === 2) {
        itemData.category_id = null;
      }
      if (data.scope_id === this.diffText) {
        itemData.category_id = 1;
      }
      return itemData;
    },
    preprocessUpdateData(data) {
      // Preprocess data for updating custom DB.
      if (!data || data?.scope_id === null) return;
      if (parseInt(data?.scope_id) === 3 && data?.category_id === null) return;
      let itemData = {
        id           : data?.id,
        scope_id     : data?.scope_id === this.diffText ? 4 : data?.scope_id,
        category_id  : data?.category_id === this.diffText ? 16 : data?.category_id,
        contractor_id: this.contractor,
        item_name    : data?.item_name,
        unit         : data?.unit,
        value_source : data?.value_source,
        unit_source  : data?.unit_source,
        source       : data?.source,
        status       : data.status === null ? 1 : data.status ? 1 : 0,
        note         : data?.note
      };
      if (parseInt(data?.scope_id) === 1 || parseInt(data?.scope_id) === 2) {
        itemData.category_id = null;
      }
      if (data?.scope_id === this.diffText) {
        itemData.category_id = 1;
      }
      return itemData;
    },
    isValidData(item) {
      if (!item) return false;
      let isValid = true;
      Object.keys(item).forEach((key) => {
        switch (key) {
          case "item_name":
            if (!item[key] || item[key].length > 128) {
              isValid = false;
            }
            break;
          case "unit":
          case "unit_source":
            if (!item[key] || item[key].length > 20) {
              isValid = false;
            }
            break;
          case "value_source":
            const maxLengthForValueSource = item[key]?.includes('-') ? 21 : 20
            if (!item[key] || item[key].length > maxLengthForValueSource || this.validateNumberField(item[key]) !== null) {
              isValid = false;
            }
            break;
          case "source":
            if (!item[key] || item[key].length > 255) {
              isValid = false;
            }
            break;
          case "scope_id":
            if (item[key].length > 10 || this.validateScope(item[key]) !== null) {
              isValid = false;
            }
            break;
          case "category_id":
            if (item[key]?.length > 10 || this.validateCategory(item) !== null) {
              isValid = false;
            }
            break;
          case "note":
            if (item[key]?.length > 255) {
              isValid = false;
            }
            break;
        }
      });

      return isValid;
    },
    async fetchDbCustoms() {
      await getDbCustom({
        contractor_id: this.contractor
      }).then((res) => {
        this.isCasbee = res.is_casbee;
        this.totalData    = res.data.length;
        this.dbCustoms    = this.preprocessFetchedData(res.data);
        this.statusUpdate = {
          time: res.updated_at_latest,
          user: res.user_updated
        };
        // Update source from table with status is true
        // Update source according to update time
        let activeSources = this.dbCustoms
          .filter(value => !Object.values(CASBEE_TYPE).includes(value?.pattern_type))
          .map((value) => {
            return value.source;
          });
        this.sources      = Array.from(new Set(activeSources.concat(this.sources)));
        this.categories   = res.categories;
        this.scopes       = res.scopes.map((index) => {
          return {
            id  : index,
            name: `${index != 4 ? "Scope " + index : this.diffText}`
          };
        });
        this.defineTable();
      });
    },
    updateStatus(currentRow) {
      // if (!currentRow.id || currentRow.id === BLANK_ID) {
      //   return;
      // }
      let currentStatus = currentRow.status;
      this.changeCheckBoxStatus(currentStatus);
      this.closePopup();
    },
    checkboxHandler() {
      const rowData = this.dbCustomsFlex.rows[this.dbCustomsFlex.selection.row].dataItem;
      this.updateStatus(rowData);
    },
    changeCheckBoxStatus(isChecked) {
      this.dbCustomsFlex.beginUpdate();
      this.dbCustomsFlex.rows[this.dbCustomsFlex.selection.row].dataItem.status = !isChecked;
      this.dbCustomsFlex.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
    },
    closePopup() {
      this.dialogMessage   = this.dialogBaseMessage;
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },
    closeRadioPopup() {
      this.radioDialog = false;
      this.radioType   = null;
    },
    onChangeRadio(newValue) {
      // Get data when change
      if (this.radioType == this.types.category && newValue != this.activeRow.category_id) {
        this.dbCustomsFlex.beginUpdate();
        this.dbCustomsFlex.rows[this.context.row._idx].dataItem.category_id = newValue == 16 ? this.diffText : newValue;
        this.dbCustomsFlex.endUpdate();
        if (this.stackUndoCategory) {
          this.undoStack.pushAction(this.stackUndoCategory);
          this.stackUndoCategory = null;
        }
      }
      if (this.radioType == this.types.scope && newValue != this.activeRow.scope_id) {
        this.dbCustomsFlex.beginUpdate();
        this.dbCustomsFlex.rows[this.context.row._idx].dataItem.scope_id = newValue == 4 ? this.diffText : String(newValue);
        this.dbCustomsFlex.endUpdate();
        if (this.stackUndoScope) {
          this.undoStack.pushAction(this.stackUndoScope);
          this.stackUndoScope = null;
        }
      }
      if(this.isItemActive(this.activeRow)) {
        this.dbCustomsFlex.beginUpdate();
        this.dbCustomsFlex.rows[this.context.row._idx].dataItem.status = true;
        this.dbCustomsFlex.endUpdate();
      }

      this.context = null;
      this.closeRadioPopup();
    },
    onDoubleButtonEventScope(context) {
      let scopeEvent         = {};
      let categoryEvent      = {};
      scopeEvent.col         = context.col.index;
      scopeEvent.col2        = context.col.index;
      scopeEvent.row         = context.row.index;
      scopeEvent.row2        = context.row.index;
      categoryEvent.col      = context.col.index + 1;
      categoryEvent.col2     = context.col.index + 1;
      categoryEvent.row      = context.row.index;
      categoryEvent.row2     = context.row.index;
      this.stackUndoScope    = new GridEditAction(this.dbCustomsFlex, scopeEvent);
      this.stackUndoCategory = new GridEditAction(this.dbCustomsFlex, categoryEvent);
    },
    onDoubleButtonEventCategory(context) {
      let scopeEvent         = {};
      let categoryEvent      = {};
      scopeEvent.col         = context.col.index - 1;
      scopeEvent.col2        = context.col.index - 1;
      scopeEvent.row         = context.row.index;
      scopeEvent.row2        = context.row.index;
      categoryEvent.col      = context.col.index;
      categoryEvent.col2     = context.col.index;
      categoryEvent.row      = context.row.index;
      categoryEvent.row2     = context.row.index;
      this.stackUndoScope    = new GridEditAction(this.dbCustomsFlex, scopeEvent);
      this.stackUndoCategory = new GridEditAction(this.dbCustomsFlex, categoryEvent);
    },
    onScopeClicking(context) {
      if (Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return; // close event if item is casbee
      this.doubleButtonEvent = true;
      if (context.item && context.item.scope_id == this.diffText) {
        this.currentSelected = 4;
      } else {
        this.currentSelected = Number(context?.item?.scope_id) || null;
      }
      this.context     = context;
      this.radioType   = this.types.scope;
      this.activeRow   = context.item;
      this.items       = this.scopes;
      this.radioDialog = true;
      this.onDoubleButtonEventScope(this.context);
    },
    onCategoryClicking(context) {
      if (Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return; // close event if item is casbee
      this.doubleButtonEvent = true;
      if (context?.item?.category_id == this.diffText) {
        this.currentSelected = 16;
      } else {
        this.currentSelected = Number(context?.item?.category_id) || null;
      }
      this.context    = context;
      this.activeRow  = context.item;
      this.radioType  = this.types.category;
      let validScopes = Object.keys(this.categories);
      if (validScopes.includes(String(context?.item?.scope_id))) {
        this.items = this.categories[context.item.scope_id].map((category) => {
          return {
            id  : category,
            name: `${category != 16 ? "Category " + category : this.diffText}`
          };
        });
      } else {
        this.items = [
          {
            id  : 0,
            name: `Category 1`
          }
        ];
      }
      this.onDoubleButtonEventCategory(this.context);
      this.radioDialog = true;
    },
    formatValue(valueInput) {
      if (!valueInput && valueInput !== 0) {
        return null;
      }
      return (valueInput || 0).toString().trim().replace(/,/g, '');
    },
    validateNumberField(input) {
      if (input) {

        if(this.formatValue(input).includes('-') && this.formatValue(input).length < 2) {
          return "数字で入力してください。"
        }

        let numberData = input.replace(/[^0-9\.\-]+/g, ''); // old regex: replace(/[^\d.]/g, "")
        
        if (numberData && input.length - numberData.length == 0) {
          return null;
        } else {
          return "数字で入力してください。";
        }
      }
    },
    validateScope(item) {
      if (item === undefined || item === null) {
        return "هذا إلزامي.";
      }
      return null;
    },
    validateCategory(item) {
      if (item.scope_id === null || item.scope_id === undefined) {
        return "هذا إلزامي.";
      }
      if (item.scope_id == 3 && (item.category_id === null || item.category_id === undefined)) {
        return "هذا إلزامي.";
      }
      return null;
    },
    validateData(item, propName) {
      // Update name validation.
      if (validateBlankRowData(item, ['item_name', 'unit', 'value_source', 'unit_source', 'source', 'scope_id', 'category_id', 'note'])) {
        return null;
      }
      switch (propName) {
        case this.bindings.item_name:
          return validateMaximumCharacter(item[propName], 128);
        case this.bindings.unit:
          return validateMaximumCharacter(item[propName], 20);
        case this.bindings.value_source:
          return validateMaximumCharacter(item[propName], item[propName]?.includes('-') ? 21 : 20) || this.validateNumberField(item[propName]);
        case this.bindings.unit_source:
          return validateMaximumCharacter(item[propName], 20);
        case this.bindings.source:
          return this.emptyCell(item[propName]) || validateMaximumCharacter(String(item[propName]), 255);
        case this.bindings.scope_id:
          return validateMaximumCharacter(String(item[propName]), 10) || this.validateScope(item[propName]);
        case this.bindings.category_id:
          return validateMaximumCharacter(String(item[propName]), 10) || this.validateCategory(item);
        case this.bindings.note:
          return validateMaximumCharactorOnlyFacility(item[propName], 255);
        case this.bindings.updated_at:
          return validateMaximumCharacter(String(item[propName]), 30);
      }
      return null;
    },
    emptyCell(item) {
      if (item === undefined || item === null) {
        return "هذا إلزامي.";
      }
      return null;
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.userName = res.name;
        })
        .catch(() => {
        });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    blankData(clientRowId) {
      return {
        clientRowId  : clientRowId,
        id           : BLANK_ID,
        scope_id     : null,
        category_id  : null,
        contractor_id: null,
        item_name    : null,
        unit         : null,
        value_source : null,
        unit_source  : null,
        source       : null,
        status       : null,
        note         : null
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = this.dbCustomViews.itemCount;
      for (let index = 1; index <= count; index++) {
        this.dbCustomViews.addNew(this.blankData(lastClientId + index));
      }

      this.dbCustomViews.commitNew();
      this.dbCustomViews.clearChanges();
    },
    scrollToTop() {
      if (!this.dbCustomsFlex) {
        return;
      }

      let rc                            = this.dbCustomsFlex.cells.getCellBoundingRect(0, 0, true);
      this.dbCustomsFlex.scrollPosition = new wjcCore.Point(this.dbCustomsFlex.scrollPosition.x, -rc.top);
    },
    initialView() {
      if (this.dbCustomViews) {
        this.dbCustoms = this.dbCustomViews.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.dbCustomViews = new CollectionView([...this.dbCustoms], {
        trackChanges: true
      });

      this.addBlankItemsToView(10);
      this.dbCustomsFlex.columnGroups = this.gridColumns;
      this.listOriginalData           = JSON.parse(JSON.stringify(this.dbCustomViews.items));
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.addBlankItemsToView(100);
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    addNewSourceToSources() {
      if (this.addedSource.length !== 0) {
        this.addedSource.forEach((item) => {
          const isExist = this.sources.findIndex((source) => source === item);
          if (isExist < 0) {
            this.sources.push(item);
          }
        });
        // this.dbCustomsFlex.setCellData(2, 6, '123aaa', false, true)
        this.dbCustomsFlex.refreshCells(false, false, false);
        // this.dbCustomsFlex.select(new wjGrid.CellRange(4, 6, 4, 6), true);
        // this.dbCustomsFlex.startEditing(true, 4, 6)
        // console.log(this.dbCustomsFlex);
        // this.dbCustomsFlex.startEditing(true, 4, 6)
      }
    },
    checkValidationData() {
      this.dbCustomViews.getError = this.validateData;
      this.submitData();
    },
    async submitData() {
      let edited      = {};
      let editedBlank = {};
      let added       = {};
      let self        = this;
      let indexBlank = 0;
      self.listOriginalData.forEach(function(item) {
        let newItemIndex = self.dbCustomViews.items.findIndex(currentItem => currentItem.id === item.id);
        const indexWithBlankId = newItemIndex + indexBlank;
        let dbCustom     = self.dbCustomViews.items[indexWithBlankId];
        if (dbCustom?.scope_id !== item?.scope_id ||
          dbCustom?.category_id !== item?.category_id ||
          dbCustom?.contractor !== item?.contractor ||
          dbCustom?.item_name !== item?.item_name ||
          dbCustom?.unit !== item?.unit ||
          dbCustom?.value_source !== item?.value_source ||
          dbCustom?.unit_source !== item?.unit_source ||
          dbCustom?.source !== item?.source ||
          dbCustom?.status !== item?.status ||
          dbCustom?.note !== item?.note
        ) {
          const prepareItem = self.preprocessUpdateData(dbCustom);

          if (!prepareItem) {
            return;
          }
          if (!self.isValidData(prepareItem)) {
            return;
          }
          if (item.id === BLANK_ID) {
            delete prepareItem.id;
            editedBlank[indexWithBlankId] = prepareItem;
            indexBlank += 1;
          } else {
            edited[indexWithBlankId] = prepareItem;
          }
        }
      });
      this.dbCustomViews.itemsAdded.forEach((item, index) => {
        if (this.preprocessAddData(item) && this.isValidData(item)) {
          added[index] = this.preprocessAddData(item);
        }
      });

      // call api
      const editedPromise = Object.values(edited).length ? updateDbCustom({ data: Object.values(edited) }).then((res) => {
        this.listOriginalData = JSON.parse(JSON.stringify(this.dbCustomViews.items));
        this.dialogNotification = true;
        this.undoStack.clear();
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      const editedBlankPromise = Object.values(editedBlank).length ? createDbCustom({ data: Object.values(editedBlank) }).then((res) => {
        this.afterDataSuccess(this.dbCustomViews.itemsEdited, editedBlank, res);
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      const addedPromise = Object.values(added).length ? createDbCustom({ data: Object.values(added) }).then((res) => {
        this.afterDataSuccess(this.dbCustomViews.itemsAdded, added, res);
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      Promise.all([editedPromise, editedBlankPromise, addedPromise]);
    },
    afterDataSuccess(collection, items, response, isEdited = false) {
      const addedReplacement = {
        id        : "id",
        status    : "status",
        updated_at: "updated_at"
      };
      this.statusUpdate      = {
        time: response.latest_record.updated_at_latest,
        user: response.latest_record.user_updated
      };
      let recordInsert       = null;
      if (isEdited) {
        recordInsert = Object.values(items).map((item) => {
          return {
            ...item,
            updated_at: formatDateTimeDataTable(response.latest_record?.updated_at_latest)
          };
        });
      } else {
        recordInsert = response.record_insert.map((item) => {
          return {
            ...item,
            updated_at: formatDateTimeDataTable(item.updated_at)
          };
        });
      }
      this.dbCustomViews.deferUpdate(() => {
        batchReplacementViewCollection(collection, recordInsert, Object.keys(items), addedReplacement);
        collection.length = 0;
      });
      this.listOriginalData = JSON.parse(JSON.stringify(this.dbCustomViews.items));
      this.dialogNotification = true;
      this.undoStack.clear();
    },
    isItemActive(item) {
      const listCellRequire = ['item_name', 'scope_id', 'source', 'unit', 'value_source']
      let activeByScope = true
      if(parseInt(item.scope_id) === 3 && !item.category_id) {
        activeByScope = false
      }
      return listCellRequire.every(key => item[key]) && (!item.id || item.id === BLANK_ID) && activeByScope
    },
    handleClosePopupCasbee() {
      this.isShowMessageCasbe = false;
      this.$router.replace({ path: ROUTES.CREATE_DATA_CUSTOMIZE }).catch(() => {})
    },
    onViewDetailClicking(context) {
      if (!Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return;
      return this.$router.push({ name: 'CasbeeDetail', params: {id: context?.item?.db_customize_detail_id} });
    },
  },
  watch   : {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    },

    casbeeModel(newValue) {
      if (newValue === CASBEE.CASBEE) {
        return this.$router.push({ path: ROUTES.CASBEE });
      }
    }
  }
};
</script>
<style lang="scss">
.dbCustomize-row.wj-cell {
  .wj-cell-maker {
    display: flex;
    max-height: 23px;
    justify-content: center;
    background: $monoLight;
    color: $monoBlack !important;
    border-radius: 4px;
    padding: 1px 6px 3px;
    text-align: center;
    font-size: 13px;
    font-family: "Source Han Sans";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    position: unset !important;
    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
}
.wj-flexgrid .casbee-detail.wj-cell{
  padding: 4px !important;
  .wj-cell-maker {
    color: $goldMid !important;
    background: unset;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 1px solid $goldLight;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    // min-height: 24px;
    min-width: 38px;
    position: unset !important;
    button {
      margin: auto;
    }
    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
}
</style>
<style scoped lang="scss">
@import '../managements/styles/index.scss';
.casbee-menu {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
}
@include desktop {
  .casbee-menu {
    justify-content: flex-end;
  }
}
</style>
