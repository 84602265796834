import axios from 'axios';
import webStorage from '@/utils/webStorage';
import router from '@/router';
import store from '@/store';

const listUrlDisableLoading = ['/branch-attribute']
// error, config, code, request, response
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 5000
  // withCredentials: true // send cookies when cross-domain requests
});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    if(!listUrlDisableLoading?.includes(config.url)) {
      store.dispatch('commonApp/updateStatusAction', false, { root: true });
    }
    const accessToken = webStorage.getToken();
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    store.dispatch('commonApp/updateStatusAction', false, { root: true });
    Promise.reject(error);
  },
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    store.dispatch('commonApp/updateStatusAction', true, { root: true });
    if (res.code >= 400) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return response.data;
    }
  },
  (error) => {
    const res = error.response;
    store.dispatch('commonApp/updateStatusAction', false, { root: true });

    if (!res) {
      return Promise.reject(error);
    }

    let throwable = {
      message: res.data.message,
      status: 0,
      code: res.data.code,
    };
    if (res.status === 401) {
      // TODO: handle logout
      //throwable.message = 'Unauthorization!';
      webStorage.removeAll();
      router.push({ path: '/auth/login' });
    } else if (res.status === 422) {
      throwable.message = res.data.message;
      throwable = res.data;
      if (router.history.current.name === 'RegisterData') {
        // show popup
        let arrError = [];
        Object.keys(res.data.errors).forEach((err) => {
          arrError.push(...res.data.errors[err]);
        });
        store.commit('registerData/updateError', true);
        store.commit('registerData/updateErrorMess', arrError);
      }
    } else if (res.status === 400 && res.data.code === 'errors.forbidden_permission') {
      store.commit('registerData/updateError', true);
      store.commit('registerData/updateErrorMess', [res.data.message]);
    }

    throwable.status = res.status;

    return Promise.reject(throwable);
  },
);

export default service;
