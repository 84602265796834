import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import commonApp from './commonApp';
import userData from './userData';
import registerData from './registerData';
import newRegisterData from './newRegisterData';
import branch from './branch';
import users from './userManage';
import emission from './emission';
import actionsTable from './actionsTable';
import dashboard from './dashboard';
import approval from './approval';
import workflowData from './workflowData';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: { commonApp, userData, branch, registerData, users, emission, actionsTable, dashboard, approval, newRegisterData, workflowData },
  plugins: [createPersistedState()],
});
