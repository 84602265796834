<template>
  <div class="duration-wrapper">
    <div class="prev">
      <common-button class="prev__pc" @action="prev" :disabled="isDisablePrev" label="السنة المالية السابقة">
        <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.51563 11L11.5156 16L11.5156 6L4.51563 11Z" stroke="currentColor" stroke-linejoin="round" />
          <path d="M8.48438 11L15.4844 16L15.4844 6L8.48438 11Z" stroke="currentColor" stroke-linejoin="round" />
        </svg>
      </common-button>
      <common-button class="prev__sp" @action="prev" :disabled="isDisablePrev" label="前月">
        <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.51563 11L11.5156 16L11.5156 6L4.51563 11Z" stroke="currentColor" stroke-linejoin="round" />
          <path d="M8.48438 11L15.4844 16L15.4844 6L8.48438 11Z" stroke="currentColor" stroke-linejoin="round" />
        </svg>
      </common-button>
    </div>

    <div class="durations-desktop">
      <div class="year">
        <span
          v-for="(year, index) in years"
          :key="index + '-year'"
          :style="{ flex: `0 0 ${(year.itemsCount / durationCategoryMonth.length) * 100}%` }"
          class="text"
        >
          {{ year.value }}
        </span>
      </div>

      <div class="month">
        <span
          v-for="(item, index) in durationCategoryMonth"
          :key="index + '-month'"
          :class="{ selected: index === selectedIndexMonth }"
          @click="onSelectedMonth(index)"
          class="text"
          >{{ item.month | numberFormat }}</span
        >
      </div>
    </div>

    <div class="durations-mobile">
      <span class="durations-mobile__text"
        >{{ selectedMonthValue.year }}年{{ selectedMonthValue.month | numberFormat }}月</span
      >
    </div>

    <div class="next">
      <common-button class="prev__pc" :isDisable="isDisableNext" @action="next" label="السنة القادمة">
        <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="currentColor" stroke-linejoin="round" />
          <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="currentColor" stroke-linejoin="round" />
        </svg>
      </common-button>
      <common-button class="prev__sp" :isDisable="isDisableNext" @action="next" label="翌月">
        <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="currentColor" stroke-linejoin="round" />
          <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="currentColor" stroke-linejoin="round" />
        </svg>
      </common-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
</style>

<script>
import { TABLET_WIDTH } from '@/constants/screen-size';
import { mapGetters } from 'vuex';
import CommonButton from '@/components/utils/button.vue';
import moment from 'moment';

const MAX_ITEMS = 12;
const STEP = 1;

export default {
  components: { CommonButton },
  data() {
    return {
      startIndex: 0,
      years: [],
      months: [],
      selectedIndexMonth: 0,
      selectedIndexYear: 0,
      isDisablePrev: false,
      isDisableNext: false,
      durationCategoryMonth: [],
      boxWidth: 0,
      resizeTimer: null,
    };
  },
  computed: {
    ...mapGetters({
      getDurationByCategoryWithMonthly: 'dashboard/getDurationByCategoryWithMonthly',
      getDurationByCategoryWithYearly: 'dashboard/getDurationByCategoryWithYearly',
      getKeepSelectedDurationByCategoryWithMonthly: 'dashboard/getKeepSelectedDurationByCategoryWithMonthly',
      getKeepSelectedDurationByCategoryWithYearly: 'dashboard/getKeepSelectedDurationByCategoryWithYearly',
    }),
    MAX_ITEMS: () => MAX_ITEMS,
    selectedMonthValue() {
      return this.getDurationByCategoryWithMonthly[this.startIndex];
    },
    prevIcon() {
      if (this.isDisablePrev) {
        return 'img/icons/prev-duration-disable.svg';
      }

      return 'img/icons/prev-duration.svg';
    },
    nextIcon() {
      if (this.isDisableNext) {
        return 'img/icons/next-duration-disable.svg';
      }

      return 'img/icons/next-duration.svg';
    },
  },
  async mounted() {
    if (this.getKeepSelectedDurationByCategoryWithMonthly.startIndex >= 0) {
      this.startIndex = this.getKeepSelectedDurationByCategoryWithMonthly.startIndex;
    }
    if (this.getKeepSelectedDurationByCategoryWithMonthly.selectedIndexMonth >= 0) {
      this.selectedIndexMonth = this.getKeepSelectedDurationByCategoryWithMonthly.selectedIndexMonth;
    }
    if (this.getKeepSelectedDurationByCategoryWithMonthly.selectedIndexYear >= 0) {
      this.selectedIndexYear = this.getKeepSelectedDurationByCategoryWithMonthly.selectedIndexYear;
    }

    this.boxWidth = window.innerWidth;

    this.setDefaultDisableNextPrev();

    if (window.innerWidth >= TABLET_WIDTH) {
      if (this.getDurationByCategoryWithYearly.length) {
        this.selectedIndexYear = this.selectedIndexYear > 0 ? this.selectedIndexYear : 0;
        const payload = { selectedIndexYear: this.selectedIndexYear };
        await this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);

        this.prepareMonthList();

        if (this.durationCategoryMonth.length) {
          this.selectedIndexMonth =
            this.selectedIndexMonth >= 0 ? this.selectedIndexMonth : this.durationCategoryMonth.length - 1;
          const payload = { selectedIndexMonth: this.selectedIndexMonth };
          await this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
          this.getShowingDurations();
          this.emitChangeSelectedMonth();
        }
      }
    } else {
      const item = this.getDurationByCategoryWithYearly.find((item) => item.is_selected);
      const itemMonth = item.start_at.split('/')[1];
      const itemYear = item.start_at.split('/')[0];
      const index = this.getDurationByCategoryWithMonthly.findIndex(
        (item) => item.month == itemMonth && item.year == itemYear,
      );

      this.startIndex = index;
      const payload = {
        startIndex: this.startIndex,
      };
      await this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
      await this.$store.dispatch('dashboard/changeQueryParams', {
        month: itemMonth,
        year: itemYear,
      });
    }

    this.onWindowResize();
  },
  watch: {
    //
  },
  methods: {
    setDefaultDisableNextPrev() {
      if (window.innerWidth >= TABLET_WIDTH) {
        if (this.selectedIndexYear <= 0) {
          this.isDisableNext = true;
        }
        if (this.selectedIndexYear >= this.getDurationByCategoryWithYearly.length - 1) {
          this.isDisablePrev = true;
        }
      } else {
        if (this.startIndex >= this.getDurationByCategoryWithMonthly.length - 1) {
          this.isDisableNext = true;
        }
        if (this.startIndex <= 0) {
          this.isDisablePrev = true;
        }
      }
    },
    getShowingDurations() {
      let countYear = 0;
      this.years = [];
      this.months = [];
      let lastYear = this.durationCategoryMonth[0].year;
      for (let i = 0; i < this.durationCategoryMonth.length; i++) {
        const item = this.durationCategoryMonth[i];
        this.months.push(item.month);

        if (lastYear != item.year && countYear > 0) {
          this.years.push({
            value: lastYear,
            itemsCount: countYear,
          });
          lastYear = item.year;
          countYear = 0;
        }
        countYear++;
      }
      this.years.push({
        value: lastYear,
        itemsCount: countYear,
      });
    },
    next() {
      this.isDisablePrev = false;
      this.isDisableNext = false;

      if (this.boxWidth < TABLET_WIDTH) {
        this.nextMobile();
      } else {
        this.nextDesktop();
      }
    },
    nextDesktop() {
      if (this.selectedIndexYear <= 0) {
        this.isDisableNext = true;
        return;
      }

      this.selectedIndexYear -= STEP;

      if (this.selectedIndexYear <= 0) {
        this.isDisableNext = true;
      }

      this.prepareMonthList();
      this.selectedIndexMonth = 0;
      const payload = { selectedIndexMonth: this.selectedIndexMonth, selectedIndexYear: this.selectedIndexYear };
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);

      this.getShowingDurations();
      this.emitChangeSelectedMonth();
    },
    nextMobile() {
      if (this.startIndex >= this.getDurationByCategoryWithMonthly.length - 1) {
        this.isDisableNext = true;
        return;
      }

      this.startIndex = this.startIndex + 1;
      const payload = { startIndex: this.startIndex };
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);

      if (this.startIndex >= this.getDurationByCategoryWithMonthly.length - 1) {
        this.isDisableNext = true;
      }
      this.emitChangeSelectedMonth();
    },
    prev() {
      this.isDisableNext = false;
      this.isDisablePrev = false;

      if (this.boxWidth < TABLET_WIDTH) {
        this.prevMobile();
      } else {
        this.prevDesktop();
      }
    },
    async prevDesktop() {
      if (this.selectedIndexYear >= this.getDurationByCategoryWithYearly.length - 1) {
        this.isDisablePrev = true;
        return;
      }

      this.selectedIndexYear += STEP;

      if (this.selectedIndexYear >= this.getDurationByCategoryWithYearly.length - 1) {
        this.isDisablePrev = true;
      }

      await this.prepareMonthList();
      this.selectedIndexMonth = this.durationCategoryMonth.length - 1;
      const payload = { selectedIndexMonth: this.selectedIndexMonth, selectedIndexYear: this.selectedIndexYear };
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
      this.getShowingDurations();
      this.emitChangeSelectedMonth();
    },
    prevMobile() {
      if (this.startIndex <= 0) {
        this.isDisablePrev = true;
        return;
      }
      this.startIndex = this.startIndex - 1;
      const payload = { startIndex: this.startIndex };
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);

      if (this.startIndex <= 0) {
        this.isDisablePrev = true;
      }

      this.emitChangeSelectedMonth();
    },
    onSelectedMonth(index) {
      this.selectedIndexMonth = index;
      const payload = { selectedIndexMonth: this.selectedIndexMonth, selectedIndexYear: this.selectedIndexYear };
      this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);

      this.emitChangeSelectedMonth();
    },
    emitChangeSelectedMonth() {
      if (this.boxWidth >= TABLET_WIDTH) {
        this.$store.dispatch('dashboard/changeQueryParams', {
          year: Number(this.durationCategoryMonth[this.selectedIndexMonth].year),
          month: Number(this.durationCategoryMonth[this.selectedIndexMonth].month),
        });
      } else {
        this.$store.dispatch('dashboard/changeQueryParams', {
          year: Number(this.getDurationByCategoryWithMonthly[this.startIndex].year),
          month: Number(this.getDurationByCategoryWithMonthly[this.startIndex].month),
        });
      }
      this.$store.dispatch('dashboard/requestEmissionByCategory');
    },
    prepareMonthList() {
      const data = this.getDurationByCategoryWithYearly[this.selectedIndexYear].original;

      const tempData = [];
      const startAt = moment(data.start_at, 'YYYY-MM');
      const endAt = moment(data.end_at, 'YYYY-MM');
      const range = moment.range(startAt, endAt);

      for (const month of range.by('month')) {
        const y = month.format('YYYY');
        const m = month.format('MM');
        tempData[y] = {
          ...tempData[y],
          [m]: m,
        };
      }

      const result = [];

      Object.keys(tempData).forEach((year) => {
        const tempMonth = tempData[year];

        Object.keys(tempMonth)
          .sort()
          .forEach((month) => {
            result.push({
              month,
              year,
            });
          });
      });

      this.durationCategoryMonth = result;
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(async () => {
        await this.getWidth();
      }, 100);
    },
    async getWidth() {
      if (window.innerWidth < TABLET_WIDTH && this.boxWidth >= TABLET_WIDTH) {
        const item = this.durationCategoryMonth[this.selectedIndexMonth];
        for (let i in this.getDurationByCategoryWithMonthly) {
          if (
            this.getDurationByCategoryWithMonthly[i].month === item.month &&
            this.getDurationByCategoryWithMonthly[i].year === item.year
          ) {
            this.startIndex = i;
            const payload = { startIndex: this.startIndex };
            this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
            break;
          }
        }
        this.boxWidth = window.innerWidth;
      } else if (window.innerWidth >= TABLET_WIDTH && this.boxWidth < TABLET_WIDTH) {
        const item = this.getDurationByCategoryWithMonthly[this.startIndex];
        const itemDate = new Date(item.year, item.month, 1);

        for (let i in this.getDurationByCategoryWithYearly) {
          const startTemp = this.getDurationByCategoryWithYearly[i].original.start_at.split('-');
          const startDate = new Date(startTemp[0], startTemp[1], 1);

          const endTemp = this.getDurationByCategoryWithYearly[i].original.end_at.split('-');
          const endDate = new Date(endTemp[0], endTemp[1], 1);
          if (startDate.getTime() <= itemDate.getTime() && endDate.getTime() >= itemDate.getTime()) {
            this.selectedIndexYear = Number(i);
            const payload = { selectedIndexYear: this.selectedIndexYear };
            this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
            break;
          }
        }
        await this.prepareMonthList();
        for (let i in this.durationCategoryMonth) {
          if (this.durationCategoryMonth[i].month === item.month && this.durationCategoryMonth[i].year === item.year) {
            this.selectedIndexMonth = Number(i);
            const payload = { selectedIndexMonth: this.selectedIndexMonth };
            this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithMonthly', payload);
            break;
          }
        }

        this.getShowingDurations();
        this.boxWidth = window.innerWidth;
      }
      this.boxWidth = window.innerWidth;
    },
  },
};
</script>
