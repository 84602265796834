<template>
  <div class="chart__wrapper">
    <div class="chart">
      <!-- header -->
      <div class="chart__header">
        <div class="chart__header--left">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="isMouseOverPrev = true"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
          >
            <img :src="prevIcon" alt="Prev"/>
          </button>
        </div>

        <div class="chart__header--center">
          <div class="chart-legend">
            <div class="legend scope1">
              <div class="bar-color"></div>
              <div class="bar-name">Scope 1</div>
            </div>

            <div class="legend scope2">
              <div class="bar-color"></div>
              <div class="bar-name">Scope 2</div>
            </div>

            <div class="legend scope3" v-if="isEnableScope3">
              <div class="bar-color"></div>
              <div class="bar-name">Scope 3</div>
            </div>
          </div>
        </div>

        <div class="chart__header--right">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="isMouseOverNext = true"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
          >
            <img :src="nextIcon" alt="Next"/>
          </button>
        </div>
      </div>

      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="yearLeft" :style="{'min-width': minWidth}">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === 6 }"
              :style="{bottom: `${item.percent - 3}%`}"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="chart__body--center">
          <div class="transition" :style="{ width: transitionWidth }"></div>

          <div
            class="chart-bar"
            @click="onClickChartItem(item)"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            :class="{ selected: item.time_range === selectedTimeRange, hovered: item.time_range === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
            <div class="active"></div>
            <div class="hover"></div>
            <div v-if="hasNegativeNumber" class="number-wrapper">
              <div class="positive-number" :style="{'height': `calc(100% - ${item.zero_value_percent})`, bottom: item.zero_value_percent}">
                <div class="bar bar--scope3 animation" :style="{ height: item.emission_scope3_percent }"
                 v-if="isEnableScope3 && item.emission_scope3 >= 0"></div>
                <div class="bar bar--scope2 animation" :style="{ height: item.emission_scope2_percent }"
                v-if="item.emission_scope2 >= 0"></div>
                <div class="bar bar--scope1 animation" :style="{ height: item.emission_scope1_percent }"
                v-if="item.emission_scope1 >= 0"></div>
              </div>
              <div class="zero-crossline" :style="{'height': item.zero_value_percent}"></div>
              <div class="negative-number" :style="{'height': item.zero_value_percent}">
                <div class="bar bar--scope3 animation" :style="{ height: item.emission_scope3_percent }"
                 v-if="isEnableScope3 && item.emission_scope3 < 0"></div>
                <div class="bar bar--scope2 animation" :style="{ height: item.emission_scope2_percent }"
                v-if="item.emission_scope2 < 0"></div>
                <div class="bar bar--scope1 animation" :style="{ height: item.emission_scope1_percent }"
                v-if="item.emission_scope1 < 0"></div>
              </div>
            </div>
            <div v-else class="number-wrapper">
              <div class="bar bar--scope3 animation" :style="{ height: item.emission_scope3_percent }"
                 v-if="isEnableScope3"></div>
              <div class="bar bar--scope2 animation" :style="{ height: item.emission_scope2_percent }"></div>
              <div class="bar bar--scope1 animation" :style="{ height: item.emission_scope1_percent }"></div>
            </div>
          </div>
          <div class="chart__body--right"></div>
      </div>
      </div>
      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{width: footerWidth+ 'px'}">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="isMouseOverPrev = true"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
          >
            <img :src="prevIcon" alt="Prev"/>
          </button>
        </div>

        <div class="chart__footer--center">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: item.value === selectedTimeRange }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <!-- {{ item.label }} -->
              <span>{{ item.start_at }}</span>
              <span>&nbsp;-&nbsp;</span>
              <span>{{ item.end_at }}</span>
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="isMouseOverNext = true"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
          >
            <img :src="nextIcon" alt="Next"/>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex';
import {prepareChartAxisY} from '@/concerns/chart';
import {TABLET_WIDTH} from '@/constants/screen-size';
import { formatValue, $_helper_isNumberType,$_helper_isNegativeNumber, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper'

const MAX_CHART_ITEMS = 3;

export default {
  data() {
    return {
      indexAtFirstBar  : 0,
      min              : 0,
      max              : 500,
      axisYItemsSource : [
        {
          value: 0,
          text : '0'
        },
        {
          value: 100,
          text : '100'
        },
        {
          value: 200,
          text : '200'
        },
        {
          value: 300,
          text : '300'
        },
        {
          value: 400,
          text : '400'
        },
        {
          value: 500,
          text : 't-CO2'
        },
      ],
      axisXItemsSource : [],
      chartValue       : [],
      selectedTimeRange: '',
      hoverTimeRange   : '',
      isDisabledPrev   : false,
      isDisabledNext   : false,
      isMouseOverNext  : false,
      isMouseOverPrev  : false,
      isBlurNext       : false,
      isBlurPrev       : false,
      isFirstRender    : true,
      transitionWidth  : '100%',
      footerWidth      : 0,
      minWidth: 0,
    };
  },
  async mounted() {
    await this.transformChartData();

    if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.getEmissionsByPeriodWithYearly.length) {
      this.isDisabledNext = true;
    } else {
      this.isDisabledNext = false;
    }

    if (this.indexAtFirstBar <= 0) {
      this.isDisabledPrev = true;
    } else {
      this.isDisabledPrev = false;
    }

    this.footerWidth = this.$refs.yearLeft.clientWidth;
  },
  computed: {
    ...mapGetters({
      getEmissionsByPeriodWithYearly      : 'dashboard/getEmissionsByPeriodWithYearly',
      getIndexAtFirstBarByPeriodWithYearly: 'dashboard/getIndexAtFirstBarByPeriodWithYearly',
      isEnableScope3                      : 'dashboard/getIsEnableScope3',
    }),
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext      = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev      = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(item => item.emission_scope1 < 0 || item.emission_scope2 < 0 || item.emission_scope3 < 0);
      return existNegativeNumber ? true : false;
    }
  },
  watch   : {
    getEmissionsByPeriodWithYearly() {
      this.transformChartData();
      this.selectedFirstChartItem();
    },
  },
  methods : {
    transformChartData() {
      const summary = this.getEmissionsByPeriodWithYearly.map(
        (item) => {
          let negativeTotal = 0;
          let positiveTotal = 0;

          if ($_helper_isNumberType(item.emission_scope1) && $_helper_isNumberType(item.emission_scope2) && $_helper_isNumberType(item.emission_scope3)) {
            $_helper_isNegativeNumber(item.emission_scope1) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope1))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope1))}`));
            $_helper_isNegativeNumber(item.emission_scope2) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope2))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope2))}`));
            $_helper_isNegativeNumber(item.emission_scope3) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope3))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope3))}`));
            return [negativeTotal, positiveTotal];
          }
          return item.emission_scope1 + item.emission_scope2 + item.emission_scope3;
        }
      );

      if (this.getIndexAtFirstBarByPeriodWithYearly >= 0) {
        this.indexAtFirstBar = this.getIndexAtFirstBarByPeriodWithYearly;
      } else if (this.getEmissionsByPeriodWithYearly.length > MAX_CHART_ITEMS) {
        const indexSelected  = this.$store.state.dashboard.durationByCategoryWithYearly.findIndex((item) => {
          return item.is_selected;
        });
        this.indexAtFirstBar =
          this.getEmissionsByPeriodWithYearly.length -
          MAX_CHART_ITEMS -
          (indexSelected < MAX_CHART_ITEMS ? 0 : indexSelected);
        this.indexAtFirstBar = this.indexAtFirstBar < 0 ? 0 : this.indexAtFirstBar;
      }

      const max = Math.max(...[].concat(...summary));
      const min = Math.min(...[].concat(...summary));
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(max, min);

      this.prepareChartDataValue();
    },
    selectedFirstChartItem() {
      if (!this.getEmissionsByPeriodWithYearly.length) {
        return;
      }

      const oldSelectedDurationId = this.$store.state.dashboard.selectedEmissionsByPeriod.duration_id;

      let index = this.getEmissionsByPeriodWithYearly.findIndex((item) => {
        return item.original && item.original.duration_id === oldSelectedDurationId;
      });

      if (index < 0) {
        const selectedYear = this.$store.state.dashboard.durationByCategoryWithYearly.find((item) => {
          return item.is_selected;
        });
        index              = this.getEmissionsByPeriodWithYearly.findIndex((item) => {
          return item.original && item.original.duration_id === selectedYear.value;
        });
      }

      if (index >= 0) {
        const item = this.getEmissionsByPeriodWithYearly[index];
        this.$emit('onUpdateStateDetail', item);
        this.selectedTimeRange = item.time_range;
      } else {
        const latestDurationIndex = this.getEmissionsByPeriodWithYearly.length - 1;
        const item                = this.getEmissionsByPeriodWithYearly[latestDurationIndex];
        this.$emit('onUpdateStateDetail', item);
        this.selectedTimeRange = item.time_range;
      }

      if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.getEmissionsByPeriodWithYearly.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    prepareChartDataValue() {
      if (!this.getEmissionsByPeriodWithYearly.length) {
        return;
      }

      let index              = 0;
      let chartValueOriginal = [];

      do {
        chartValueOriginal.push(this.getEmissionsByPeriodWithYearly[index + this.indexAtFirstBar]);
        index++;
      } while (index < MAX_CHART_ITEMS);

      chartValueOriginal = chartValueOriginal.filter((item) => !!item);

      this.axisXItemsSource = chartValueOriginal.map((item) => {
        return {
          label   : item.time_range,
          value   : item.time_range,
          start_at: item.start_at,
          end_at  : item.end_at,
        };
      });

      this.chartValue = chartValueOriginal.map((item) => {
        return {
          ...item,
          emission_scope1_percent: '0%',
          emission_scope2_percent: '0%',
          emission_scope3_percent: '0%',
        };
      });

      const timeout = this.isFirstRender ? 1000 : 0;

      setTimeout(() => {
        const summary = chartValueOriginal.map(
          (item) => {
            let negativeTotal = 0;
            let positiveTotal = 0;
            if ($_helper_isNumberType(item.emission_scope1) && $_helper_isNumberType(item.emission_scope2) && $_helper_isNumberType(item.emission_scope3)) {
              $_helper_isNegativeNumber(item.emission_scope1) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope1))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope1))}`));
              $_helper_isNegativeNumber(item.emission_scope2) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope2))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope2))}`));
              $_helper_isNegativeNumber(item.emission_scope3) ? negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(item.emission_scope3))}`)) : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(item.emission_scope3))}`));
              return [negativeTotal, positiveTotal];
            }
            return item.emission_scope1 + item.emission_scope2 + item.emission_scope3;
          }
        );
        const max = Math.max(...[].concat(...summary));
        const min = Math.min(...[].concat(...summary));
        [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(max, min);
        this.getWidthOfText();
        this.chartValue = chartValueOriginal.map((item) => {
          return {
            ...item,
            emission_scope1_percent: this.calcScopeEmission(item.emission_scope1),
            emission_scope2_percent: this.calcScopeEmission(item.emission_scope2),
            emission_scope3_percent: this.calcScopeEmission(item.emission_scope3),
            zero_value_percent: (Math.abs(Number(this.min) / (this.max - this.min)) * 100) + '%',
          };
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;

        this.footerWidth = this.$refs.yearLeft.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
        }, timeout + 2000);
      }
    },
    onClickChartItem(item) {
      this.selectedTimeRange = item.time_range;

      this.$emit('onUpdateStateDetail', item);

      if (window.outerWidth < TABLET_WIDTH) {
        this.$emit('openDialogStateDetail', item);
        return;
      }
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    next() {
      if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.getEmissionsByPeriodWithYearly.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext  = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);

      if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.getEmissionsByPeriodWithYearly.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    prev() {
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar + MAX_CHART_ITEMS < this.getEmissionsByPeriodWithYearly.length) {
        this.isDisabledNext = false;
      }
    },
    isPositiveNumber(item) {
      return item.emission_scope1 >= 0 && item.emission_scope2 >= 0 && item.emission_scope3 >= 0;
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Math.abs((Number(emissionsValue) / (this.min))) * 100 + '%';
      } else {
        return Math.abs((Number(emissionsValue) / (this.max))) * 100 + '%';
      }
    },
    getWidthOfText() {
      const text = this.axisYItemsSource[0]?.text.length > this.axisYItemsSource[6]?.text.length ? this.axisYItemsSource[0]?.text : this.axisYItemsSource[6]?.value.toString();
      const width = Math.round(text.length * 57 / 7);
      this.minWidth = width < 57 ? '57px' : width + 'px';
    }
  },
};

</script>
<style lang="scss" scoped>
@import "./index.scss";
.chart__wrapper {
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
        }
      }
    }
    .chart__body--center {
      .number-wrapper {
        width: 100%; 
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
        }
        .negative-number {
          flex: 1;
          position: absolute;
        }
        .zero-crossline {
          border-top: 1px solid #ddd;
          height: 1px;
          width: 100%;
          position: absolute;
        }
      }
    }
  }
}
</style>

