<template>
  <div class="button-action-table" :class="{ disabled: disabledUndoRedoAction() }">
    <div class="button-submit-area action-left " v-if="leftSideActionCount > 0">
      <v-btn v-if="displayWithDataApproved" depressed class="button-save save-register-button" :class="{'disabled':!isEditedData }" @click="handleUpdateApprovedData">
        <img src="@/assets/images/emissions/button/save.svg" alt="" class="button-icon"/>يحفظ
      </v-btn>
      <v-btn v-if="showSaveRegister" depressed class="button-save save-register-button" :class="{'disabled':!isHasData }" @click="handleSaveData">
        <img src="@/assets/images/emissions/button/save.svg" alt="" class="button-icon" />يحفظ
      </v-btn>
      <v-btn v-if="$route.path.includes('emissions/view') && windowWidth >= 1024" depressed class="button-save export-btn" @click="handleShowExportPopup">
        <img src="@/assets/images/emissions/button/export.svg" alt="" class="saveicon" style="margin-right: 6px;" />
        エクスポート
      </v-btn>
      <v-btn v-if="displayAsNewRegisterDataAndDraft" depressed class="button-save-template" :disabled="!isHasData" @click="handleSaveTemplate">حفظ النموذج</v-btn>
      <v-btn v-if="displayAsTemplateDetails && displayAsOwnerTemplate" depressed class="button-save" :disabled="!isHasData" @click="handleOverrideTemplate"><img src="@/assets/images/emissions/button/save.svg" alt="" />上書き保存</v-btn>
      <v-btn v-if="displayAsTemplateDetails && !displayAsOwnerTemplate" depressed class="button-save" :disabled="!isHasData" @click="handleCloneTemplate">新規テンプレートとして保存</v-btn>
      <v-btn v-if="showDelete" depressed class="button-delete" @click="handleDeleteData"><img src="@/assets/images/emissions/button/delete.svg" alt="" />احذف</v-btn>
    </div>
    <div class="button-submit-area action-right" v-if="rightSideActionCount">
        <v-btn v-if="displayAsTemplateDetails" depressed class="button-save template-btn" :disabled="!isHasData" @click="handleSaveData">登録</v-btn>
        <v-btn v-if="showSubmitRegister"  depressed class="button-submit submit-register-button" :disabled="!isHasData" @click="handleSubmitData">يُقدِّم</v-btn>
        <v-btn v-if="displayAsReviewer() && unCheckedPermission" depressed class="button-submit" :disabled="!isHasData" @click="handleReviewingData">申請内容を審査</v-btn>
        <v-btn v-if="displayAsOwner" depressed class="button-submit" :disabled="isCancelSubmitted" @click="handleCancelSubmitted">申請を取り消す</v-btn>
        <v-btn v-if="displayAsAccepterCancelSubmitted  && unCheckedPermission" depressed class="button-submit" @click="handleAcceptCancelSubmitted">申請取り消しを許可</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import { ROLE } from '@/constants/role';
import { STATUS_SUBMIT, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ACTION_HEADER_TABLE } from '@/constants/registerData';
export default {
  data() {
    return {
      socketAccessInterval: null,
      listUsersAccessTemplateData: [],
      isCurrentUser: false,
      isCurrentAdmin: false,
      isCurrentApprover: false,
      disabledUndo: true,
      disabledRedo: true,
      enableInRoute: [
        ROUTES.USER_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA,
      ],
      enableActionRegisterData: [ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA],
      listRoutesDetails: [
        ROUTES.LIST_EMISSION_SUBMITTED,
        ROUTES.LIST_EMISSION_TEMPLATES,
        ROUTES.LIST_EMISSION_TEMPORARY,
        ROUTES.LIST_APPROVAL,
      ],
      windowWidth: window.innerWidth,
    };
  },
  props: {
    isHasData: {
      type: Boolean,
      default: false,
    },
    workFlowData: {
      type: Object,
      default: () => {},
    },
    unCheckPermission: {
      type: Boolean,
      default: false,
    },
    isEditedData: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('commonApp', ['statusAction']),
    ...mapState('actionsTable', ['statusBtn']),
    ...mapState('userData', ['currentUser']),
    showSubmitRegister(){
      return !this.displayAsReviewer() &&
        !this.displayAsSettingScreen() &&
        !this.displayAsOwner &&
        !this.displayAsAccepterCancelSubmitted &&
        !this.displayAsDataApproved &&
        !this.displayAsDataBeingReturned
    },
    showDelete(){
      return !this.displayAsReviewer() &&
        !this.displayAsSettingScreen() &&
        this.displayAsOwnerTemplate &&
        !this.displayAsOwner &&
        !this.displayAsAccepterCancelSubmitted &&
        !this.displayAsDataApproved
    },
    showSaveRegister(){
      return this.displayAsNewRegisterDataAndDraft ||
        this.displayAsSettingScreen() || 
        this.displayAsDataBeingReturned
    },
    isNeedCompressed(){
      return (this.leftSideActionCount + this.rightSideActionCount) == 1
    },
    leftSideActionCount(){ 
     const actionsDisplay = [
      Boolean(this.displayWithDataApproved), 
      Boolean(this.showSaveRegister), 
      Boolean(this.displayAsNewRegisterDataAndDraft),
      Boolean(this.displayAsTemplateDetails),
      Boolean(this.showDelete),
      Boolean(this.$route.path.includes('emissions/view') && this.windowWidth >= 1024),
      ].filter(Boolean).length;
      return actionsDisplay;
    },
   
    rightSideActionCount(){
     const actionsDisplay = [
      Boolean(this.displayAsTemplateDetails), 
      Boolean(this.showSubmitRegister), 
      Boolean(this.displayAsReviewer()),
      Boolean(this.displayAsOwner),
      Boolean(this.displayAsAccepterCancelSubmitted)
      ].filter(Boolean).length;
      return actionsDisplay ;
    },
    checkHasUserAccessTemplateDetail() {
      return this.listUsersAccessTemplateData.length > 0;
    },
    displayAsNewRegisterDataAndDraft() {
      return (
        (!this.listRoutesDetails.includes(this.$route.params?.scope) &&
          this.$route.name === 'NewRegisterData' &&
          Object.keys(this.workFlowData).length === 0) ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_DRAFT
      );
    },
    displayAsDataBeingReturned() {
      return (
        this.workFlowData?.status === STATUS_FIELD.STATUS_RETURN ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN
      );
    },
    displayAsOwner() {
      return !!(
        this.workFlowData?.is_owner &&
        (this.workFlowData?.status === STATUS_SUBMIT || this.workFlowData?.status === STATUS_CANCEL_SUBMMITED)
      );
    },
    isCancelSubmitted() {
      return this.workFlowData?.status === STATUS_CANCEL_SUBMMITED;
    },
    displayAsAccepterCancelSubmitted() {
      return !!(
        this.workFlowData?.status === STATUS_CANCEL_SUBMMITED &&
        (this.workFlowData?.is_approver || this.currentUser?.user?.role_id === ROLE.ADMIN)
      );
    },
    displayWithDataApproved() {
      return (
        (this.currentUser?.user?.role_id === ROLE.ADMIN &&
          (this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
            this.workFlowData?.status === STATUS_FIELD.STATUS_APPROVED)) ||
        (this.currentUser?.user?.role_id === ROLE.ADMIN && this.$route.fullPath.includes('/emissions/view/'))
      );
    },
    displayAsDataApproved() {
      return (
        this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_APPROVED ||
        this.$route.fullPath.includes('/emissions/view/')
      );
    },
    displayAsTemplateDetails() {
      return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES;
    },
    displayAsOwnerTemplate() {
      return !(this.workFlowData?.template_type && this.workFlowData?.is_shared);
    },
    theTemplateIsShared() {
      return this.workFlowData?.template_type === 1;
    },
    unCheckedPermission() {
      return !this.unCheckPermission
    }
  },
  mounted() {
    this.disabledUndoRedoAction();
    this.displayAsSettingScreen();
    this.displayAsReviewer();
    if (this.theTemplateIsShared && !this.workFlowData.is_owner ) {
      this.socketAccessInterval = setInterval(() => {
        this.$socket.emit('accessedDetailTemplate', this.workFlowData.id);
      }, 1000);
    }
    this.$emit('needCompressed', this.isNeedCompressed);
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  beforeDestroy() {
    if (this.theTemplateIsShared) {
      this.$socket.emit('leavingTemplateDetail', this.workFlowData?.id);
    }

    clearInterval(this.socketAccessInterval);
  },
  watch:{
    isNeedCompressed (val){
      this.$emit('needCompressed', val)
    }
  },
  methods: {
    ...mapActions('actionsTable', ['actionStartUpdate']),
    allowActionUndo() {
      return this.statusBtn.undo;
    },
    allowActionRedo() {
      return this.statusBtn.redo;
    },
    disabledUndoRedoAction() {
      return !(
        this.enableInRoute.includes(this.$route.path) ||
        this.$route.name === 'RegisterData' ||
        this.$route.name === 'NewRegisterData'
      );
    },
    displayAsReviewer() {
      return (
        ((this.currentUser?.user?.role_id === ROLE.APPROVAL && this.workFlowData?.is_approver) || this.currentUser?.user?.role_id === ROLE.ADMIN) &&
        this.workFlowData?.status === STATUS_SUBMIT
      );
    },
    displayAsSettingScreen() {
      return this.enableInRoute.includes(this.$route.path);
    },
    handleSaveData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SAVE_TEMPRORA);
    },
    handleSaveTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SAVE_TEMPLATE);
    },
    handleDeleteData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.DELETE_DATA, this.checkHasUserAccessTemplateDetail);
    },
    handleSubmitData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SUBMIT_DATA);
    },
    handleOverrideTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE, this.checkHasUserAccessTemplateDetail);
    },
    handleCancelSubmitted() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.CANCEL_SUBMITTED);
    },
    handleCloneTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.CLONE_TEMPLATE);
    },
    handleReviewingData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.REVIEWING_DATA);
    },
    handleAcceptCancelSubmitted() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED);
    },
    handleUpdateApprovedData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA);
    },
    handleShowExportPopup() {
      this.$emit('onHandleShowExportPopup', true);
    },
  },
  sockets: {
    accessedDetailTemplate(socketData) {
      if (
        socketData.template === this.workFlowData.id &&
        this.theTemplateIsShared &&
        this.$socket.id !== socketData.socketId
      ) {
        if (this.listUsersAccessTemplateData) {
          this.listUsersAccessTemplateData = [socketData.socketId];
        } else {
          if(!this.listUsersAccessTemplateData.includes(socketData.socketId)) {
            this.listUsersAccessTemplateData.push(socketData.socketId);
          }
        }
      }
    },
    leavingTemplateDetail(socketData) {
      if (socketData.template === this.workFlowData?.id && this.theTemplateIsShared) {
        this.listUsersAccessTemplateData = this.listUsersAccessTemplateData.filter((user) => {
          return user !== socketData.socketId;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button-action-table {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;

  .v-btn {
    background: #f7f7f2;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;

    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 28px;
    padding: 4px 8px 6px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .button-save {
    background: #f7f7f2;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    font-family: "Source Han Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    padding: 4px 8px 6px;
    &.disabled{
      pointer-events: none;
      cursor: default;
      color: $monoMid;
      background: $monoLight !important;
      .button-icon{
       filter: grayscale(1) opacity(0.5);
      }
    }
    &.export-btn {
      width: 106px;
      display: flex;
      padding: 4px 8px 6px;
      gap: 6px;
    }
  }
  .save-register-button {
    min-width: 61px;
  }
  .button-save-template {
    max-width: 106px;
  }
  .button-delete {
    min-width: 61px;
  }
  .button-submit-area {
    flex-grow: 1;
    .submit-register-button {
      max-width: 39px;
      min-width: 39px;
      border: none;
    }
    .button-submit {
      color: white;
      background: $goldMid;
    }

    .button-save {
      &.template-btn {
        color: white;
        background: $goldMid;
        max-width: 39px;
        min-width: 39px;
      }
    }
    &.action-left{
      display: flex;
      grid-gap: 8px;
      flex-wrap: wrap;
      justify-content: left;
    }
    &.action-right{
      display: flex;
      grid-gap: 8px;
      flex-wrap: wrap;
      justify-content: right;
    }
  }

  &.disabled {
    display: none;
  }
}

@media (max-width: $desktop) {
  .button-action-table {
    width: 100%;
  }
}
</style>
