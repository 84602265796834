<template>
  <div class="pulldown-wrapper" v-click-outside="handleClickOutSite" :class="{'disabled' : disabled || itemCount === 0}">
    <div id="dropdownInputText" @click="toggleDropdown()">
      <div v-if="!dropdownInputText()[0]" class="placeholder" :class="{'center-text': !arrowDownBtn}">{{ placeholder }}</div>
      <div v-if="dropdownInputText()[0]" class="input-text">{{ dropdownInputText()[0] }}</div>
      <div v-if="dropdownInputText()[1] > 0" class="count-hide-text">{{ dropdownInputText()[1] }}</div>
      <img v-if="arrowDownBtn" :src="getArrowImage()" alt="" class="arrow-down" :class="{'arrow-top': selectionShow}" />
    </div>
    <div v-if="arrowDownBtn">
      <div
        v-if="selectionShow"
        class="selection-pulldown"
        id="style-scroll"
        :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list']"
      >
        <div
          v-for="(item, index) in methodsList"
          :key="index"
          class="item pull-down-text-color"
        >
          <div class="export-checkbox">
            <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="item.text" />
            <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="item.text" />
            <label :for="item.text">{{ item.text }}</label>
          </div>
        </div>
      </div>
    </div>
    <CheckboxPopup
      v-else
      :dialog.sync="selectionShow"
      :confirmText="'決定'"
      :descriptionText="placeholder"
      :methods="dataList"
      :width="width"
      :hasSearch="hasSearch"
      :isCollapsedProp="isCollapsedProp"
      :selectedData="getSelectedData"
      :key="checkboxKey"
      :itemCount="itemCount"
      @close="onCancelSelectData"
      @submit="onHandleSelectData"
    />
  </div>
</template>
<script>
import { calcText } from '@/utils/calcText';
import CheckboxPopup from '@/components/dialogs/checkbox-popup';
import {GET_ALL} from '@/constants/export-report.js';
import { mapState } from "vuex";
export default {
  components: { CheckboxPopup },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'スコープ/カテゴリ/算定方法を選択',
    },
    width: {
      type: String,
      default: '100%'
    },
    arrowDownBtn: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemCount: {
      type: Number,
      default: 0
    },
    showFullText: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    selectionShow: false,
    boxWidth     : 0,
    resizeTimer  : null,
    leftPosition : 0,
    isCollapsed: [],
    isCollapsedLayer1: [],
    maxInputLength: 435,
    methodsList: [],
    checkboxKey: 0,
    isChangeGetAll: false,
    getAllData: false,
  }),
  mounted() {
    this.maxInputLength = document.getElementById('dropdownInputText').clientWidth;
    this.methodsList = this.dataList;
    if(this.getSelectedData.length === this.itemCount) {
      this.getAllData = true
    }
    if(this.is_standard && !this.$route.path.includes('emissions/view')){
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    getSelectedData: {
      get() {
        return this.selectedData;
      },
      set(value) {
        if (value.length < this.itemCount) {
          this.getAllData = false;
          this.isChangeGetAll = true;
        } else {
          this.isChangeGetAll = false;
        }
        this.$emit('update:selectedData', value);
      }
    },
    ...mapState("userData", ["currentUser","is_standard"]),
  },
  watch: {
    getAllData: {
      handler(newValue) {
        if (newValue) {
          this.getSelectedData.length = 0;
          this.methodsList.map(item => {
            this.getSelectedData.push(`${item.text}`);
          })
          const existIndex = this.getSelectedData.indexOf(GET_ALL);
          if (existIndex !== -1) {
            this.getSelectedData.splice(existIndex, 1);
          }
          this.isChangeGetAll = false;
        } else {
          if(!this.isChangeGetAll) {
            this.getSelectedData = [];
          }
        }
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.maxInputLength = document.getElementById('dropdownInputText').clientWidth
      if (this.disabled || this.itemCount === 0) return;
      this.selectionShow = !this.selectionShow;
    },
    handleClickOutSite() {
      if (this.arrowDownBtn) {
        this.selectionShow = false;
      }
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    },
    getMethodLayer1(index) {
      return this.methodsList[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.methodsList[index]?.methods[layer1Index]?.methods;
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    toggleCollapseLayer1(index) {
      this.$set(this.isCollapsedLayer1, index, !this.isCollapsedLayer1[index]);
    },
    onHandleSelectData(selectedValue) {
      this.getSelectedData = selectedValue;
      this.dropdownInputText();
    },
    onCancelSelectData() {
      this.selectionShow= false;
      this.checkboxKey++;
    },
    dropdownInputText() {
      if (!this.showFullText) {
        let lessTextArr = this.getSelectedData.map(item => {
          let stringToArrText = item.split('_');
          if (this.disabled) {
            stringToArrText = item.split('.');
          }
          return stringToArrText[stringToArrText.length - 1].trim();
        })
        return calcText(lessTextArr, this.maxInputLength);
      }
      return calcText(this.getSelectedData, this.maxInputLength);
    },
    isGetAll(text) {
      return text === GET_ALL;
    },
  },
}
</script>
<style lang="scss" scoped>
.pulldown-wrapper {
  width: 100%;
  &.box-shadow-pull-down{
    #dropdownInputText {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  &.disabled {
    #dropdownInputText {
      background: $monoLight;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  .item-scroll-none {
    overflow: hidden !important;
  }
  .description-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 32px;
    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    position: absolute;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  .selection-pulldown {
    position: absolute;
    max-height: 370px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    margin-top: 2px;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 429.5px;
    font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    &.method-list {
      width: calc((100% - 80px));
    }

    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        cursor: pointer;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
        }
        label {
          width: 100%;
          cursor: pointer;
        }
        
        &:hover {
          background: $bgLight;
        }
        input[type=checkbox]:checked {
          appearance: none;
          box-shadow: none;
          text-align: center;
          width: 16px !important;
          height: 16px !important;
          background: $blueMid;
        }
        input[type=checkbox]:checked:after {
          content: url('../../assets/icons/checkedbox.svg');
          color: $monoWhite;
          position: absolute;
          top: 6px;
          left: 17px;
        }
        input[type=checkbox]:checked:before {
          content: "";
          height: 41px;
          border: 2px solid $blueMid;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .toggle-wrapper {
        width: 100%;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          .toggle-icon {
            width: 10px;
            height: 10px;
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
            input[type=checkbox]:checked:after {
              content: url('../../assets/icons/checkedbox.svg');
              color: $monoWhite;
              position: absolute;
              top: 6px;
              left: 41px;
            }
          }
        }
      }
    }
    .search-text {
      position: relative;
      .input-elic {
        width: 100% !important;
        margin: unset;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  #dropdownInputText {
    height: 40px;
    background: $monoOffWhite;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
    border-radius: 4px;
    padding: 7px 16px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }
    .count-hide-text {
      color: $monoWhite;
      background: $bgDark;
      border-radius: 10px;
      font-size: 12px;
      line-height: 8px;
      width: 19px;
      height: 19px;
      padding: 5px 6px 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;
      letter-spacing: 0.03em;
      font-weight: 500;
    }
    .placeholder {
      color: $monoMid;
      font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 0.03em;
      &.center-text {
        text-align: center;
      }
    }
    .arrow-down {
      position: absolute;
      right: 14px;
    }
  }
  .arrow-top {
    rotate: (180deg);
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@include desktop {
  .pulldown-wrapper {
    .selection-pulldown {
      width: 238px;

      &.method-list {
        width: 496px;
      }
    }
  }
}
</style>