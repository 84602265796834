<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <h1 class="wrap-title">テンプレート一覧</h1>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div
              v-if="showTooltip"
              class="helper-icon tooltip"
              v-html="'テンプレートを使用して排出量の登録データを新規作成します。'"
            ></div>
          </div>
        </div>
      </div>
      <common-button v-ripple="false" class="return__header" @action="gotoListRegistered()" label="登録一覧に戻る">
        <div class="list-emission-return"
        >
          <img
            class="mr-0 return"
            src="@/assets/images/dialog/return.svg"
          />
          <img
            class="mr-0 return-active"
            src="@/assets/images/dialog/return_active.svg"
          />
        </div>
      </common-button>
    </div>
    <div class="category-table main-table custom-table view-table template-table">
      <data-table
        :data-source="listData"
        :init-grid="initializeGrid"
        :grid-columns="gridColumns"
        :allowAddNew="false"
        :showFocus="false"
        :isShowToolbar="false"
        class="table-list-data"
      />
    </div>
    <div class="return__button-responsive">
      <common-button v-ripple="false" class="return__header-responsive" @action="gotoListRegistered()" label="登録一覧に戻る">
        <div class="list-emission-return-responsive"
        >
          <img
            class="mr-0 return"
            src="@/assets/images/dialog/return.svg"
          />
          <img
            class="mr-0 return-active"
            src="@/assets/images/dialog/return_active.svg"
          />
        </div>
      </common-button>
    </div>
    <dialog-popup-share
      :dialog="dialogShare"
      :message="dialogMessageShare"
      classes="register"
      @submit="handleDialogShare()"
      @close="closePopup"
    />
    <dialog-popup-private
      :dialog="dialogPrivate"
      :message="dialogMessagePrivate"
      :confirmText="'共有を解除'"
      classes="register"
      isShowCancelBtn
      :confirm="true"
      @submit="handleDialogPrival()"
      @close="closePrivatePopup"
    />
    <dialog-popup-error
      :dialog="warningTemplateDataDialogPopup"
      message="現在、共有メンバーがこのテンプレートを開いているため共有を解除できません。"
      :isErrorImg="true"
      @submit="closeDialogWarningSaveTemplate"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Tooltip, PopupPosition, CollectionView } from '@grapecity/wijmo';
import DataTable from '@/components/category/data-table';
import { ROUTES } from '@/router/constants';
import { toolTipCustom } from '@/utils/tooltipCustom';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { AutoComplete } from '@grapecity/wijmo.input';
import DialogPopupShare from '@/components/dialogs/notification-popup.vue';
import DialogPopupPrivate from '@/components/dialogs/question-popup.vue';
import DialogPopupError from '@/components/dialogs/error-popup.vue';
import { TEMPLATE_ROLE } from '@/constants/template';

import { getListWorkflowApi } from '@/api/registerData';
import CommonButton from '@/components/utils/button.vue';
import { getDataLayer, getScopeName, getCategoryName } from '@/utils/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { updateDataBeforeRedirect } from '@/utils/updateDataBeforeRedirect';
export default {
  name: 'ListTemplates',
  data() {
    return {
      warningTemplateDataDialogPopup: false,
      listUsersAccessTemplateData: [],
      items: [
        {
          text: 'ホーム',
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: 'テンプレート',
          disabled: true,
          href: ROUTES,
        },
      ],
      flex: null,
      listTemplateData: [],
      listData: null,
      listType: [TEMPLATE_ROLE.PRIVATE, TEMPLATE_ROLE.SHARE],
      gridColumns: [],
      dialogPrivate: false,
      dialogMessagePrivate:
        'このテンプレートの共有を解除します。よろしいですか？ \n\n 共有を解除すると、共有中メンバーのテンプレート一覧からも表示されなくなります。',
      dialogShare: false,
      dialogMessageShare:
        'テンプレートを共有しました。\n他のメンバーもこのテンプレートを使用できるようになりました。',
      dialogCancelBtn: false,
      hdrTips: new Tooltip({
        position: PopupPosition.Above,
        showAtMouse: true,
        showDelay: 600,
        cssClass: 'hdr-tip',
      }),
      showTooltip: false,
      dialogError: false,
      dialogMessageError: '現在、共有メンバーがこのテンプレートを開いているため共有を解除できません。',
      rowIdFromShareToPrivate: null,
      dataUpdate: {},
      rowSelected: {},
      indexSelected: 0,
    };
  },
  components: {
    DataTable,
    DialogPopupShare,
    DialogPopupPrivate,
    DialogPopupError,
    CommonButton,
  },
  mounted() {
    this.updateBreadCrumb(this.items);
    this.gridColumns = this.getGridColumns();
    this.getTemplateData();
  },
  computed: {
    ...mapState('userData', ['currentUser']),
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('newRegisterData', ['updateRegisterDataWorkFollow', 'updateIsApproved']),
    ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),
    closeDialogWarningSaveTemplate() {
      this.flex.deferUpdate(() => {
        this.listData._view[this.indexSelected].template_type = TEMPLATE_ROLE.SHARE;
      });
      this.warningTemplateDataDialogPopup = false;
    },
    gotoListRegistered(){
      return this.$router.push({
        path: `${ROUTES.EMISSIONS}`
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });
      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid?.formatItem.addHandler((sender, event) => {
        const colBinding = event.panel.columns[event.col].binding;
        if (colBinding === 'template_type') {
          let rowValue = sender.rows[event.row]?._data;
          if (event.panel === sender.columnHeaders) {
            toolTipCustom(event, 't1', 'テンプレートを個人所有とするか、共有とするか選択できます。', this.hdrTips);
          }
          if (event.panel.cellType === wjGrid.CellType.Cell && rowValue.is_shared === 1) {
            event.cell.classList.add('template-disable');
          }
        }
      });
      flexgrid?.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        let rowValue = s.rows[e.row]?._data;
        if (column.binding !== 'template_type' || (column.binding === 'template_type' && rowValue.is_shared === 1)) {
          e.cancel = true;
          return;
        }
      });
      flexgrid?.cellEditEnding.addHandler((s, e) => {
        const newVal = s.activeEditor.value;
        const oldVal = s.getCellData(e.row, e.col);
        const rowValue = s.rows[e.row]?._data;
        this.rowSelected = s.rows[e.row]?._data;
        this.rowIdFromShareToPrivate = rowValue.id;
        this.indexSelected = e.row;

        if (newVal === oldVal) return;
        if (newVal === TEMPLATE_ROLE.PRIVATE) {
          if (
            this.listUsersAccessTemplateData[this.rowIdFromShareToPrivate] !== undefined &&
            this.listUsersAccessTemplateData[this.rowIdFromShareToPrivate].length > 0
          ) {
            setTimeout(() => {
              this.warningTemplateDataDialogPopup = true;
            }, 300);
          } else {
            setTimeout(() => {
              this.showConfirmPopup(newVal);
            }, 300);
            return;
          }
        } else {
          setTimeout(() => {
            this.dialogShare = true;
          }, 300);
        }
      });
      flexgrid.pastingCell.addHandler((sender, event) => {
        event.cancel = true;
      });
      flexgrid?.autoSizeColumns();
    },
    async getTemplateData() {
      const payload = {
        action: 'listTemplate',
        contractor_id: this.currentUser.user.contractor_id,
      };
      const templateResponse = await getListWorkflowApi(payload);
      if (templateResponse.data.length !== 0) {
        this.listTemplateData = templateResponse.data.map((templateItem) => {
          return {
            ...templateItem,
            template_id: templateItem.id,
            scope: getScopeName(parseInt(templateItem.scope)),
            category: getCategoryName(parseInt(templateItem.scope), parseInt(templateItem.category)),
            layer1: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.method,
            layer2: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.sub_method,
            contractor_name: templateItem.contractor?.name,
            last_Modified: formatDateTimeDataTable(templateItem.updated_at),
            author: templateItem.submitter?.name,
            template_type: templateItem.template_type === 0 ? TEMPLATE_ROLE.PRIVATE : TEMPLATE_ROLE.SHARE,
            approved_by: templateItem.approved_by,
            scope_id: parseInt(templateItem.scope),
            category_id: parseInt(templateItem.category),
            duration_id: templateItem.duration_id,
          };
        });
      }
      this.defineTable();
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.listData = new CollectionView(this.listTemplateData);
      this.flex.columnGroups = this.getGridColumns();
    },
    closePopup() {
      // case: update data if close popup (please don't remove)
      // this.flex.deferUpdate(() => {
      //    this.listData._view[this.indexSelected].template_type = TEMPLATE_ROLE.PRIVATE
      //  })
      this.dialogShare   = false;
      this.dialogError   = false;
    },
    handleEvent() {
      this.listData.collectionChanged.addHandler(() => {
        let edited = [];
        this.listData.itemsEdited.forEach((itemEdited) => {
          edited.push({
            id: itemEdited.id,
            template_type: itemEdited.template_type === TEMPLATE_ROLE.PRIVATE ? 0 : 1,
          });
        });
        if (edited.length > 0 || edited.length < 2) {
          const payload = {
            action: 'editTemplate',
            dataWorkflow: edited,
          };
          this.dataUpdate = payload;
          // this.updateRegisterDataWorkFollow(payload);
        }
      });
    },
    getGridColumns() {
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'template_id',
          minWidth: 46,
          maxWidth: 46,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: 'template-detail-header',
          cellTemplate: CellMaker.makeButton({
            text: '詳細',
            click: (event, context) => this.onIdClicking(context),
          }),
        },
        {
          header: '種類',
          binding: 'template_type',
          minWidth: 95,
          maxWidth: 120,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true,
          dataMap: new wjGrid.DataMap(this.listType),
          editor: new AutoComplete(document.createElement('div'), {
            itemsSource: this.listType,
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1,
          }),
        },
        {
          header: 'タイトル',
          binding: 'title',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: '法人名',
          binding: 'contractor_name',
          minWidth: 86,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: 'スコープ',
          binding: 'scope',
          minWidth: 86,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: 'カテゴリ',
          binding: 'category',
          minWidth: 86,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: '算定方法',
          align: 'center',
          columns: [
            {
              header: '１段目',
              binding: 'layer1',
              minWidth: 74,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
            },
            {
              header: '２段目',
              binding: 'layer2',
              minWidth: 74,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
            },
          ],
        },
        {
          header: '作成者',
          binding: 'author',
          minWidth: 165,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: '最終更新日',
          binding: 'last_Modified',
          minWidth: 165,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
      ];
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    showConfirmPopup(value) {
      if (value === null) {
        return;
      }
      if (value === TEMPLATE_ROLE.SHARE) {
        this.dialogShare = true;
      } else if (value === TEMPLATE_ROLE.PRIVATE) {
        this.dialogPrivate = true;
      }
    },
    handleDialogShare() {
      const payload = {
        action: 'editTemplate',
        dataWorkflow: [
          {
            id: this.rowIdFromShareToPrivate,
            template_type: 1,
          },
        ],
      };
      this.updateRegisterDataWorkFollow(payload);
      this.dialogShare = false;
    },
    handleDialogPrival() {
      this.dialogPrivate = false;
      const payload = {
        action: 'editTemplate',
        dataWorkflow: [
          {
            id: this.rowIdFromShareToPrivate,
            template_type: 0,
          },
        ],
      };
      this.updateRegisterDataWorkFollow(payload);
    },
    closePrivatePopup() {
      this.dialogPrivate = false;
      this.flex.deferUpdate(() => {
        this.listData._view[this.indexSelected].template_type = TEMPLATE_ROLE.SHARE;
      });
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.listData.itemsEdited.length = 0;
      await updateDataBeforeRedirect(rowData.item);
      await this.$router.push({ path: `/emissions/register/list-templates/${rowData.item.id}` });
    },
  },
  sockets: {
    accessedDetailTemplate(socketData) {
      if (this.listUsersAccessTemplateData[socketData.template]) {
        this.listUsersAccessTemplateData[socketData.template].push(socketData.socketId);
      } else {
        this.listUsersAccessTemplateData[socketData.template] = [socketData.socketId];
      }
    },
    leavingTemplateDetail(socketData) {
      this.listUsersAccessTemplateData = this.listUsersAccessTemplateData.map((template) => {
        return template.filter((user) => {
          return user !== socketData.socketId;
        });
      });
    },
  },
};
</script>
<style lang="scss">
.template-table {
  .template-detail-header {
    padding: 2px 4px !important;

    .wj-btn {
      display: none;
    }
  }

  .wj-flexgrid .wj-cell[aria-readonly] {
    background: #f7f7f2 !important;
  }
  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].wj-state-active {
    background: #f7f7f2 !important;
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #404d50;
    font-family: 'Source Han Sans JP';
  }

  .wj-cell {
    .wj-cell-maker {
      background: none;
      justify-content: start;
    }

    .wj-cell-maker:hover {
      background: none;
      color: #404d50;
    }
  }

  .template-detail-header {
    .wj-cell-maker {
      // margin-top: 2px;
      font-style: normal;
      font-family: 'Source Han Sans';
      width: 100%;
      color: #948810 !important;
      position: unset !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid #a4a184;
    }

    .wj-cell-maker {
      &:hover {
        background: #0072a3;
        border: unset;
        color: #ffffff !important;
        button {
          color: #ffffff;
        }
      }
    }
  }
}

</style>
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;

  .wrap__block__header__content {
    display: flex;
    align-items: center;
    margin-left: 40px;
    // tooltip icon
    .helper-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;

      img {
        z-index: 1;
      }

      &.tooltip {
        display: block;
        justify-content: left;
        background: $monoWhite;
        border-radius: 4px;
        color: $monoBlack;
        margin: 0 auto;
        font-size: 15px;
        padding: 10px;
        height: unset;
        width: 315px;
        max-width: max-content;
        position: absolute;
        right: unset;
        left: 0px;
        bottom: unset;
        z-index: 9999;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        position: fixed;
        width: 100%;
      }
    }
  }

  &__header {
    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 45px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      @media (max-width: 768px) {
        padding: 0 0 45px;
      }

      &__content {
        flex-direction: row;
      }

      span,
      .wrap-title {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .return__listSubmitted {
      margin-right: 40px !important;

      .return__header {
        background: $monoOffWhite;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        flex: none;
        order: 1;
        flex-grow: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 161px;
        height: 40px;
        cursor: pointer;

        &:hover {
          background: #a4a184;

          svg path {
            stroke: #ffffff;
          }

          .return__text,
          .return__button-pc {
            color: $monoOffWhite;
          }
        }

        .action {
          cursor: pointer;
        }

        .return__text {
          font-family: 'Source Han Sans JP';
          color: #404d50;
          font-size: 14px;
          letter-spacing: 0.03em;
          font-style: normal;
          line-height: 24px;
          font-weight: 500;
        }
      }
    }
  }
}
.custom-table {
  padding-left: 20px !important;
}
@include desktop {
  .return {
    &__header {
      margin-top: unset;
      margin-right: 40px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 !important;
      width: 161px;
      .list-emission-return {
        height: 20px;
        .return-active {
          display: none;
        }
      }
      &:hover {
        .list-emission-return {
          .return {
            display: none;
          }
          .return-active {
            display: block;
          }
        }
      }
      &:active {
        .list-emission-return {
          .return {
            display: block;
          }
          .return-active {
            display: none;
          }
        }
      }
    }

    &__button-responsive {
      display: none;
    }
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #inspire-mobile .content-main {
    padding: 0 !important;
  }
  .wrap {
    .wrap__block__header__content {
      padding-top: 40px;
      margin-left: 0;
    }
  }
  .wrap__block {
    align-items: flex-start;
    position: relative;

    padding-bottom: 0;

    .return__header {
      display: none !important;
    }
  }
  .return {
    &__button-responsive {
      width: calc(100% + 40px);
      height: 72px;
      background: #bfd4d9;
      box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
      padding-top: 16px;
      position: relative;
      top: 80px;
      margin-left: -20px;
    }
  }
  .return__header-responsive {
    width: 90%;
    height: 40px;
    background: #f7f7f2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .list-emission-return-responsive {
      height: 20px;
      padding-right: 8px;
      .return-active {
        display: none;
      }
    }
    &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
    &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
  }
  .return__button-responsive-text {
    font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #404d50;
    margin-left: 10px;
  }
}
</style>
