<template>
  <div class="chart-detail-wrapper">
    <div class="category-info mt-0">
      <div class="category-title">فئة</div>
      <div class="scope-name">{{ scopeName }}</div>
      <div class="category-name">
        {{ getEmissionsByCategoryDetail.category ? getEmissionsByCategoryDetail.category + '. ' : '' }}
        {{ getEmissionsByCategoryDetail.categoryName }}
      </div>
    </div>

    <div class="category-summary">
      <div class="category-title">إجمالي الانبعاثات (تعويض)</div>
      <div class="content">
        <div class="value">{{ totalValue | numberFormat }}</div>
        <div class="unit">t-CO2</div>
      </div>
    </div>

    <div class="line"></div>

    <div class="compare-parent">
      <div class="category-compare">
        <div class="category-title">مقارنة بالسنة المالية السابقة</div>

        <div class="content">
          <div class="value">{{ getEmissionsByCategoryDetail.rate_last_year | numberFormat }}</div>
          <div class="unit" v-show="getEmissionsByCategoryDetail.rate_last_year">%</div>
          <div class="up" v-if="status">
            <img :src="status.src" :alt="status.alt" />
          </div>
        </div>
      </div>

      <div class="line"></div>

      <div class="compare-with-all">
        <div class="category-title">مشاركة الموقع</div>

        <div class="content">
          <div class="icon">
            <v-progress-circular
              :value="getEmissionsByCategoryDetail.rate_all ? getEmissionsByCategoryDetail.rate_all : 0"
              color="#948810"
              size="28"
              width="2"
              rotate="270"
            />
          </div>
          <div class="value">{{ getEmissionsByCategoryDetail.rate_all | numberFormat }}</div>
          <div class="unit" v-show="getEmissionsByCategoryDetail.rate_all">%</div>
          <div class="next" @click="goToDetail('全拠点比')" @mouseover="right1 = true" @mouseleave="right1 = false">
            <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right" />
            <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
          </div>
        </div>
      </div>
    </div>

    <div class="item-high-emission">
      <div class="item-high-emission__title">العناصر ذات الانبعاثات العالية</div>

      <div class="items">
        <div class="item" v-for="(item, index) in getEmissionsByCategoryDetailTop5" :key="index">
          <div class="item__title">{{ item.name }}</div>

          <div class="content">
            <div class="data">
              <div class="value">{{ item.value | numberFormat }}</div>
              <div class="unit">t-CO2</div>
            </div>

            <div class="compare">
              <div class="icon">
                <v-progress-circular
                  :value="item.rate_all ? item.rate_all : 0"
                  color="#948810"
                  size="22"
                  width="2"
                  rotate="270"
                  class="mr-0"
                ></v-progress-circular>
              </div>

              <div class="value mr-1">{{ item.rate_all | numberFormat }}</div>
              <div class="unit" v-show="item.rate_all">%</div>
              <div
                class="right"
                @click="goToDetail(item.name)"
                @mouseover="rightMouseOver(index)"
                @mouseleave="rightMouseLeave()"
              >
                <img v-if="index !== rightIndex" width="18" src="img/icons/right.svg" alt="Right" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!canRedirectToRegisterData" class="link-to-register">
        <common-button class="mt-4" :isDisable="true" label="التفاصيل" />
      </div>

      <router-link v-else class="link-to-register" :to="{ path: getUrlToRegisterData.path, query: getUrlToRegisterData.query }"
        ><common-button class="mt-4" type="colored" label="التفاصيل"></common-button
      ></router-link>
    </div>
  </div>
</template>

<style lang="scss">
@import 'mobile';
@import 'desktop';
</style>

<script>
import { mapGetters } from 'vuex';
import { ROUTES } from '@/router/constants';
import webStorage from '@/utils/webStorage';
import CommonButton from '@/components/utils/button.vue';

export default {
  components: { CommonButton },
  data() {
    return {
      right1: false,
      rightIndex: -1,
    };
  },
  computed: {
    ...mapGetters({
      getEmissionsByCategoryDetail: 'dashboard/getEmissionsByCategoryDetail',
      getEmissionsByCategoryDetailTop5: 'dashboard/getEmissionsByCategoryDetailTop5',
    }),
    totalValue() {
      return this.getEmissionsByCategoryDetail.value;
    },
    scopeName() {
      switch (this.getEmissionsByCategoryDetail.scopeName) {
        case 'scope1':
          return 'لنطاق 1';
        case 'scope2':
          return 'النطاق 2';
        case 'scope3':
          return 'النطاق 3';
        default:
          return '1 لنطاق ';
      }
    },
    status() {
      if (this.getEmissionsByCategoryDetail.up === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else if (this.getEmissionsByCategoryDetail.up === -1) {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    getUrlToRegisterData() {
      const scope = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.scope;
      const category = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.category;
      const duration = this.$store.state.dashboard.queryParams.duration_id;
      let path =
        ROUTES.EMISSIONS +
        '/view' +
        '/' +
        (scope === undefined ? '1' : scope) +
        '/' +
        (category === undefined ? '1' : category);
      let query = {
        duration,
      };

      return { path, query };
    },
    canRedirectToRegisterData() {
      return this.$store.state.dashboard.queryParams.duration_id;
    }
  },
  methods: {
    goToDetail(title) {
      const scope = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.scope;
      const category = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.category;
      const up = this.$store.state.dashboard.emissionsByCategoryDetail.up;
      const categoryName = this.$store.state.dashboard.emissionsByCategoryDetail.categoryName;
      webStorage.set('title', title);
      this.$router.push({
        path: ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        query: {
          scope: scope,
          type: 1,
          category: category,
          duration_id: this.$store.state.dashboard.queryParams.duration_id,
          categoryName,
          up,
        },
      });
    },
    goToRegister() {
      const scope = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.scope;
      const category = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.category;
      const duration = this.$store.state.dashboard.queryParams.duration_id;

      this.$router.push({
        path:
          ROUTES.EMISSIONS +
          '/' +
          ROUTES.REGISTER +
          '/' +
          (scope === undefined ? '1' : scope) +
          '/' +
          (category === undefined ? '1' : category),
        query: {
          duration,
        },
      });
    },
    rightMouseOver(value) {
      this.rightIndex = value;
    },

    rightMouseLeave() {
      this.rightIndex = -1;
    },
  },
};
</script>
