import store from '@/store';

export const updateDataBeforeRedirect = (rowDataItem) => {
  store.dispatch('registerData/updateDataScope', rowDataItem.scope_id);
  store.dispatch('registerData/updateDataCategory', rowDataItem.category_id);
  store.dispatch('registerData/updateDataMonth', rowDataItem.month);
  store.dispatch('registerData/updateDuration', rowDataItem.duration_id);
  store.dispatch('newRegisterData/updateIsApproved', rowDataItem.approved_by === null ? false : true);
  getMethodsId(rowDataItem);
}

const getMethodsId = (rowDataItem) => {
  let methodLayer1Id = null;
  let methodLayer2Id = null;

  if (rowDataItem.scope_id === 1) {
    methodLayer1Id = rowDataItem.pattern_id;
  } else if ([2, 4].includes(rowDataItem.scope_id)) {
    methodLayer1Id = 1;
  } else if (rowDataItem.scope_id === 3) {
    if (rowDataItem.category_id === 4) {
      if (rowDataItem.pattern_id === 2) {
        methodLayer1Id = 3;
      } else if ([4, 5, 6].includes(rowDataItem.pattern_id)) {
        methodLayer1Id = 1;
        methodLayer2Id = rowDataItem.pattern_id;
      } else if ([7, 8].includes(rowDataItem.pattern_id)) {
        methodLayer1Id = 2;
        methodLayer2Id = rowDataItem.pattern_id;
      }
    } else if ([6, 7, 15].includes(rowDataItem.category_id)) {
      methodLayer1Id = rowDataItem.pattern_id;
    } else if (rowDataItem.category_id === 9) {
      if ([1, 2, 3].includes(rowDataItem.pattern_id)) {
        methodLayer1Id = 1;
        methodLayer2Id = rowDataItem.pattern_id;
      } else {
        methodLayer1Id = 2;
      }
      methodLayer2Id = rowDataItem.pattern_id;
    } else if (rowDataItem.category_id === 11) {
      if ([1, 2, 3].includes(rowDataItem.pattern_id)) {
        methodLayer1Id = 1;
        methodLayer2Id = rowDataItem.pattern_id;
      } else {
        methodLayer1Id = 2;
      }
    } else {
      methodLayer1Id = rowDataItem.pattern_id;
    }
  }
  store.dispatch('newRegisterData/updateMethodLayer1Id', methodLayer1Id);
  store.dispatch('newRegisterData/updateMethodLayer2Id', methodLayer2Id);
}

export const getDefaultMethods = (scope, cateId) => {
  let methodLayer1Id = null;
  let methodLayer2Id = null;

  if ([1, 2, 4].includes(scope)) {
    methodLayer1Id = 1;
  } else {
    switch (cateId) {
      case 4:
        methodLayer1Id = 1;
        methodLayer2Id = 4;
        break;
      case 9:
      case 11:
        methodLayer1Id = 1;
        methodLayer2Id = 1;
        break;
      default:
        methodLayer1Id = 1;
        break;
    }
  }

  store.dispatch('newRegisterData/updateMethodLayer1Id', methodLayer1Id);
  store.dispatch('newRegisterData/updateMethodLayer2Id', methodLayer2Id);
}
