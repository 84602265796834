<template>
  <div class="home-freely mt-12">
    <div class="freely mt-12">
      <div class="freely_item">
        <div class="freely_item--label">電力の排出係数</div>
        <div class="freely_item--description">電気の排出係数を変更したい場合は、数値を入力してください。</div>
        <div class="freely-input">
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="electricEmission"
            :value="electricEmission"
            :label="'t-CO2/kWh'"
            width="115"
            :disabled="isViewMode"
            :errorMessage="inputErrors.electricEmission"
            @removeError="inputErrors.electricEmission = ''"
          />
          <span class="freely-item-unit"></span>
        </div> 
      </div>
    </div>
    <div class="freely mt-12">
      <div class="freely_item">
        <div class="freely_item--label">1㎡あたり1次エネルギー消費量</div>
        <div class="freely_item--description">以下の項目に、1次エネルギー消費量を直接入力してください。</div>
        <div class="freely-input">
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="consumption"
            :value="consumption"
            :label="'t-CO2/年㎡'"
            width="115"
            :disabled="isViewMode"
            :errorMessage="inputErrors.consumption"
            @removeError="inputErrors.consumption= ''"
          />
        </div> 
      </div>
    </div>

    <div class="freely mt-12">
      <div class="freely_item">
        <div class="freely_item--label">節水型設備の評価レベル</div>
        <div class="freely_item--description">以下の項目のうち、取り組んでいるものの数を選択してください。</div>
        <div class="freely_item--description">・節水トイレの設置</div>
        <div class="freely_item--description">・節水水栓の設置</div>
        <div class="freely_item--description">・食器用洗浄機の設置</div> 
        <div class="level-evaluate">
          <default-pull-down-menu
              v-model="valueLevelEvaluate"
              :items="listLevelEvaluate"
              :initialValue="valueLevelEvaluate"
              :width="'100%'"
              :boxWidth="'100%'"
              :height="'32px'"
              :isCasbeePulldown="true"
              class="casbee-pulldown"
              :disabled="isViewMode"
        />
        </div>
      </div>
    </div>

    <common-button v-if="!isViewMode" class="mt-12 casbee-btn" v-ripple="false" type="colored" :isDisable="isViewMode" :label="'入力した値で計算'" @action="handlerCalculationData" />
  </div>
</template>
<script>
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { LIST_LEVEL_EVALUATE } from '@/constants/casbee/homeEnterFreely';
import CommonButton from "@/components/utils/button.vue";
import CasbeeInput from '@/components/casbee/input';
import { validateInputFieldCasbee } from '@/utils/validate';
import { CASBEE_TYPE } from '@/constants/casbee/';
import { INPUT_TYPE } from '@/constants/casbee';
import { evaluate } from 'mathjs';
export default {
  components: { DefaultPullDownMenu, CommonButton, CasbeeInput},
  props: {
    detailData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      inputType: INPUT_TYPE.ENTER_FREELY,
      electricEmission: 0.00043,
      consumption: 0,
      valueLevelEvaluate: 0,
      listLevelEvaluate: LIST_LEVEL_EVALUATE,
      casbeeData: {
        total: null,
        patternType: CASBEE_TYPE.HOME_ENTER_FREELY,
        source: '',
      },
      electricEmissionError:'',
      consumptionError: '',
      inputErrors: {
        electricEmission: '',
        consumption: ''
      },
    }
  },
  mounted() {
    this.prepareDetailData();
  },
  methods: {
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
      if(!this.isValidData) {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
      }

      let electric = evaluate(`(${this.electricEmission} * 1000 / 9.76) ( ${this.consumption} * 49.8 / 100)`);
      let gas = evaluate(`0.0499 * (${this.consumption} * 22.3 / 100)`);
      let LPG = evaluate(`0.0590 * (${this.consumption} * 11.2 / 100)`);
      let fuel = evaluate(`0.0678 * (${this.consumption} * 16.2 / 100)`);
      let heat = evaluate(`0.0570 * (${this.consumption} * 0.1 / 100)`);

      let emissionsPer1m2 = evaluate(`(${electric} + ${gas} + ${LPG} + ${fuel} + ${heat}) / 1000`);

      let emissionsRelatedWaterConsumption = evaluate(`${this.valueLevelEvaluate} * 0.91 / 1000`);  

      let totalResult = evaluate(`${emissionsPer1m2} + ${emissionsRelatedWaterConsumption}`).toFixed(4);

      let levelIndex = this.listLevelEvaluate.findIndex((levelEvaluate) => levelEvaluate.value === this.valueLevelEvaluate);

      let source = `CASBEE 戸建(新築) カテゴリ11&13 自由入力 節水:  ${this.listLevelEvaluate[levelIndex].name}`;  
      let casbeeData = {
        ... this.casbeeData,
        total: totalResult,
        source: source,
        content_json: {
          electricEmission: this.electricEmission,
          consumption: this.consumption,
          valueLevelEvaluate: this.valueLevelEvaluate,
        }
      }

      this.$emit('onHandlerCalculationData', casbeeData); 
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
  },

  computed: {
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    isViewMode() {
      return this.detailData.hasOwnProperty('dataDetail');
    },
  },
}
</script>

<style lang="scss" scoped>
  .home-freely {
    .freely {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      &_item {
        .freely-input {
          display: flex;
          align-items: center;
          gap: 8px;
            .input-elic {
              width: 80px;
              height: 32px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 0px 8px;
              background: #FFFFFF;
              border: 1px solid #999999;
              border-radius: 6px;
            }
        }
        &--label {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: $monoBlack;
          margin-bottom: 8px;
        }
        &--description {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          margin-bottom: 8px;
        }
      }
    }
  }
  @include desktop {
    .home-freely {
    .freely {
      &_item {
        &--label {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.05em;
          margin-bottom: 12px;
        }
        &--description {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: unset;
          margin-bottom: 12px;
        }
      }
      .level-evaluate {
        width: 320px;
      }
    }
  }
}
.home-freely {
::v-deep .common-btn.colored{
  width: 100% !important;
  height: 56px !important;
}
@include desktop {
  ::v-deep .common-btn.colored{
  width: 240px !important;
}
}
}
</style>
