<template>
  <v-app class="other-layout" :id="$vuetify.breakpoint.width < 1024 ? 'inspire-mobile' : ''">
    <v-app-bar app style="height: 48px !important">
      <v-toolbar-title class="header header-sp">
        <div @click="redirectToHome('')" class="header-wrapper">
          <img class="logo-img" src="@/assets/icons/User.svg" alt="" />
        </div>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <div class="content-main">
        <router-view />
      </div>
    </v-main>
    <footer class="wrap__footer">
      <div v-if="$vuetify.breakpoint.width >= 1024" class="copyright">©︎2022 Sustech Inc.</div>
      <div v-if="$vuetify.breakpoint.width < 1024" class="copyright">
        <p>©︎2022 Sustech Inc.</p>
        <div class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
      </div>
      <div class="wrap__footer--right">
        <a href="https://www.sustech-inc.com/" target="_blank">運営会社</a>
        <a :href="link.termOfUse" target="_blank">利用規約</a>
        <a :href="link.privacy" target="_blank">プライバシーポリシー</a>
        <div v-if="$vuetify.breakpoint.width >= 1024" class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
      </div>
    </footer>
    <dialog-popup
      :dialog="dialog"
      :message="`ログアウトします。\nよろしいですか？`"
      :logout="true"
      @close="dialog = false"
      @logout="handleLogout"
    />
  </v-app>
</template>
<script>
import { ROUTES } from "@/router/constants";
import DialogPopup from "@/components/dialogs/question-popup.vue";
import { logoutApi } from "@/api/auth";
import webStorage from "@/utils/webStorage";

export default {
  components: { DialogPopup },
  data() {
    return {
      dialog: false,
      isLogin: false,
      link: {
        termOfUse: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
        privacy  : `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`
      }
    };
  },
  mounted() {
    const token = webStorage.getToken();
    if (token) {
      this.isLogin = true;
    }
  },
  methods: {
    redirectToHome() {
      this.$router.push({ path: "/" });
    },
    handleLogout() {
      logoutApi().then(() => this.$router.push({ path: "/auth/login" }));
      this.dialog = false;
    },
    handleToTop() {
      window.scrollTo({
        top     : 0,
        behavior: "smooth"
      });
    },
    linkToTrusty() {
      window.open("https://www.truste.or.jp/hssl/validate/01632.php");
    }
  }
};
</script>
<style lang="scss">
.other-layout {
  // background: linear-gradient(180deg, #efdfdc 7.72%, #eff5f5 16.84%, #eff5f5 99.93%) !important;
  .v-toolbar__content {
    height: 48px !important;
    padding: unset;

    .v-toolbar__title {
      padding: 15px 20px;
    }

    .header-wrapper {
      cursor: pointer;
    }
  }

  .theme--light.v-app-bar--fixed {
    background: $monoOffWhite;
    box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
    0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
    0 65px 110px 0 rgba(160, 181, 186, 0.08) !important;
  }

  .v-main {
    padding: 88px 0 0 !important;

    .content-main {
      padding: 0 20px;
    }
  }

  .wrap__footer {
    background-color: $bgDark;
    color: $monoMid;
    font-style: normal;
    letter-spacing: 0.05em;
    display: flex;
    flex-flow: column;
    margin-top: 80px;

    .wrap__footer--right {
      display: flex;
      flex-flow: row;
      color: $monoMid;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin-left: 12px;
      height: 44px;
      align-items: center;

      > div {
        padding: 0 8px;
        cursor: pointer;
      }

      > div:first-child {
        padding-left: unset;
      }

      a {
        color: #7c898c;
        background: #132d39;
        cursor: pointer;
        text-decoration: none;
        padding: 0 8px;

        &:hover {
          color: $monoLight;
          background: $bgDark;
        }
      }
    }

    .cross-line {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
    .copyright {
      font-size: 13px;
    }
  }
}
#inspire-mobile {
  .copyright {
    margin-left: 0px;
    width: 100%;
    height: 44px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 13px;
    justify-content: space-between;
    p {
      margin-left: 20px;
      margin-bottom: unset;
    }
    .just-logo{
      height: 35px;
      padding-right: 5px;
      .truste-logo {
        height: 100%;
      }
    }
  }
}
.just-logo{
  margin: 0 !important;
  cursor: pointer;
  padding: unset;
}
@include desktop {
  .other-layout {
    background: linear-gradient(90deg, #efdfdc 0%, #eff5f5 24.82%, #eff5f5 99.93%) !important;
    .wrap__footer {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 10px 40px;
      margin-top: unset;


      > div:first-child,
      .wrap__footer--right {
        padding: unset;
      }
      .cross-line {
        display: none;
      }
      .wrap__footer--right {
        align-items: center;
        height: unset;
        margin-left: 12px;
        a {
          padding: unset;
          margin-right: 14px;
        }
      }
      .just-logo {
        padding: unset !important;
      }
    }
    .v-main {
      padding: 128px 0 0 !important;
      .content-main {
        padding: unset !important;
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
}

.privacy-logo {
  position: relative;
  top: 8px;
}
</style>
