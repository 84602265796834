export const GET_ALL = 'すべて選択';
export const scopeCategoriesData = [
  {
    text: 'すべて選択',
    methods: [],
    hasCheckbox: true,
  },
  {
    scope : 1,
    categories : 1,
    text: 'Scope 1. 事業者自らによる温室効果ガスの直接排出',
    hasCheckbox: false,
    methods: [
      {
        text: '燃料法で算定',
        hasCheckbox: true,
        row_num_pattern : 1,
        method: 1,
      },
      {
        text: 'tkm法で算定',
        hasCheckbox: true,
        row_num_pattern : 2,
        method: 2,
      },
      {
        text: '燃費法で算定',
        hasCheckbox: true,
        row_num_pattern : 3,
        method: 3,
      },
    ]
  },
  {
    scope : 2,
    categories : 1,
    text: 'Scope 2. 他社から供給されたエネルギー使用に伴う間接排出',
    hasCheckbox: true,
    row_num_pattern : 4,
  },
  {
    scope : 3,
    categories : 1,
    text: 'Scope 3-1. 購入した製品・サービス',
    hasCheckbox: true,
    row_num_pattern : 5,
  },
  {
    scope : 3,
    categories : 2,
    text: 'Scope 3-2. 資本財',
    hasCheckbox: true,
    row_num_pattern : 6,
  },
  {
    scope : 3,
    categories : 3,
    text: 'Scope 3-3. 燃料・エネルギー関連活動',
    hasCheckbox: true,
    row_num_pattern : 7,
  },
  {
    scope : 3,
    categories : 4,
    text: 'Scope 3-4. 自社への輸送・配送',
    hasCheckbox: false,
    methods: [
      {
        text: '輸送',
        hasCheckbox: false,
        methods: [
          {
            text: '燃料法・配送料金で算定',
            hasCheckbox: true,
            row_num_pattern : 9,
            method: 1,
            methodLayer2: 4,
          },
          {
            text: 'tkm法で算定',
            hasCheckbox: true,
            row_num_pattern : 10,
            method: 1,
            methodLayer2: 5,
          },
          {
            text: '燃費法で算定',
            hasCheckbox: true,
            row_num_pattern : 11,
            method: 1,
            methodLayer2: 6,
          }
        ]
      },
      {
        text: '拠点',
        hasCheckbox: false,
        methods: [
          {
            text: '燃料',
            hasCheckbox: true,
            row_num_pattern : 12,
            method: 2,
            methodLayer2: 7,
          },
          {
            text: '電気',
            hasCheckbox: true,
            row_num_pattern : 13,
            method: 2,
            methodLayer2: 8,
          },
        ]
      },
      {
        text: '荷主責任報告値を用いる方法',
        hasCheckbox: true,
        row_num_pattern : 8,
        method: 3,
        methodLayer2: null,
      },
    ]
  }, 
  {
    scope : 3,
    categories : 5,
    text: 'Scope 3-5. 事業から出る廃棄物',
    hasCheckbox: true,
    row_num_pattern : 14,
  },
  {
    scope : 3,
    categories : 6,
    text: 'Scope 3-6. 出張',
    hasCheckbox: false,
    methods : [
      {
        text: '旅客人キロから算定',
        hasCheckbox: true,
        row_num_pattern : 15,
        method: 1,
      },
      {
        text: '燃料法で算定',
        hasCheckbox: true,
        row_num_pattern : 16,
        method: 2,
      },
      {
        text: '燃費法で算定',
        hasCheckbox: true,
        row_num_pattern : 17,
        method: 3,
      },
      {
        text: '支給額から算定',
        hasCheckbox: true,
        row_num_pattern : 18,
        method: 4,
      },
      {
        text: '宿泊数から算定',
        hasCheckbox: true,
        row_num_pattern : 19,
        method: 5,
      },
      {
        text: '出張日数から算定',
        hasCheckbox: true,
        row_num_pattern : 20,
        method: 6,
      },
      {
        text: '従業員数から算定',
        hasCheckbox: true,
        row_num_pattern : 21,
        method: 7,
      },
    ]
  },
  {
    scope : 3,
    categories : 7,
    text: 'Scope 3-7. 雇用者の通勤',
    hasCheckbox: false,
    methods: [
      {
        text : '旅客人キロから算定',
        hasCheckbox: true,
        row_num_pattern : 22,
        method: 1,
      },
      {
        text : '燃料法で算定',
        hasCheckbox: true,
        row_num_pattern : 23,
        method: 2,
      },
      {
        text : '燃費法で算定',
        hasCheckbox: true,
        row_num_pattern : 24,
        method: 3,
      },
      {
        text : '支給額から算定',
        hasCheckbox: true,
        row_num_pattern : 25,
        method: 4,
      },
      {
        text : '従業員数・営業日数から算定',
        hasCheckbox: true,
        row_num_pattern : 26,
        method: 5,
      },
    ]
  },
  {
    scope : 3,
    categories : 8,
    text: 'Scope 3-8. 賃借リース資産',
    hasCheckbox: true,
    row_num_pattern : 27,
  },
  {
    scope : 3,
    categories : 9,
    text: 'Scope 3-9. 他社への輸送・配送',
    hasCheckbox: false,
    methods : [
      {
        text : '輸送',
        hasCheckbox : false,
        methods : [
          {
            text : '燃料法・配送料金で算定',
            hasCheckbox : true,
            row_num_pattern : 28,
            method: 1,
            methodLayer2: 1,
          },
          {
            text : 'tkm法で算定',
            hasCheckbox : true,
            row_num_pattern : 29,
            method: 1,
            methodLayer2: 2,
          },
          {
            text : '燃費法で算定',
            hasCheckbox : true,
            row_num_pattern : 30,
            method: 1,
            methodLayer2: 3,
          },
        ]
      },
      {
        text : '拠点',
        hasCheckbox : false,
        methods : [
          {
            text : '燃料',
            hasCheckbox : true,
            row_num_pattern : 31,
            method: 2,
            methodLayer2: 4,
          },
          {
            text : '電気',
            hasCheckbox : true,
            row_num_pattern : 32,
            method: 2,
            methodLayer2: 5,
          },
        ]
      },    
    ]
  },
  {
    scope : 3,
    categories : 10,
    text: 'Scope 3-10. 販売した製品の加工',
    hasCheckbox: true,
    row_num_pattern : 33,
  },
  {
    scope : 3,
    categories : 11,
    text: 'Scope 3-11. 販売した製品の使用',
    hasCheckbox: false,
    methods : [
      {
        text : '直接使用段階の排出量から算定',
        hasCheckbox : false,
        methods : [
          {
            text : 'エネルギー使用製品',
            hasCheckbox : true,
            row_num_pattern : 34,
            method: 1,
            methodLayer2: 1,
          },
          {
            text : '燃料・フィードストック',
            hasCheckbox : true,
            row_num_pattern : 35,
            method: 1,
            methodLayer2: 2,
          },
          {
            text : 'GHG含有製品で、使用時にGHGを排出するもの',
            hasCheckbox : true,
            row_num_pattern : 36,
            method: 1,
            methodLayer2: 3,
          },
        ]
      },
      {
        text : '間接使用段階の排出量から算定',
        hasCheckbox : true,
        row_num_pattern : 37,
        method: 2,
      },  
    ]
  },
  {
    scope : 3,
    categories : 12,
    text: 'Scope 3-12. 販売した製品の廃棄',
    hasCheckbox: true,
    row_num_pattern : 38,
  },
  {
    scope : 3,
    categories : 13,
    text: 'Scope 3-13. 賃貸リース資産',
    hasCheckbox: true,
    row_num_pattern : 39,
  },
  {
    scope : 3,
    categories : 14,
    text: 'Scope 3-14. フランチャイズ',
    hasCheckbox: true,
    row_num_pattern : 40,
  },
  {
    scope : 3,
    categories : 15,
    text: 'Scope 3-15. 投資',
    hasCheckbox: false,
    methods: [
      {
        text : '株式投資',
        hasCheckbox: true,
        row_num_pattern : 41,
        method: 1,
      },
      {
        text : 'プロジェクトファイナンスによる融資',
        hasCheckbox: true,
        row_num_pattern : 42,
        method: 2,
      },
      {
        text : '債券投資',
        hasCheckbox: true,
        row_num_pattern : 43,
        method: 3,
      },
      {
        text : '管理投資他',
        hasCheckbox: true,
        row_num_pattern : 44,
        method: 4,
      },
    ]
  },
  {
    scope : 3,
    categories : 16,
    text : 'その他. その他のGHG排出量',
    hasCheckbox: true,
    row_num_pattern : 45,
  },
  {
    scope : 4,
    categories : 1,
    text : 'その他. 国内外の認証排出削減量',
    hasCheckbox: true,
    row_num_pattern : 46,
  }
]