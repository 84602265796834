<template>
  <div class="design">
    <span class="basis">設備</span>
    <div class="design-item">
      <div class="design-item-hot">
        <span class="design-item-hot-name">暖房設備</span>
        <default-pull-down-menu v-model="headEquipment" :items="headEquipmentList" isCasbeePulldown :disabled="isViewMode"
          :initialValue="headEquipment" class="casbee-pulldown design-item-hot-input" />
      </div>
      <div class="design-item-col">
        <span class="design-item-col-name">冷房設備</span>
        <default-pull-down-menu :disabled="isViewMode" isCasbeePulldown v-model="refrigerationEquipment"
          :items="refrigerationEquipmentList" :initialValue="refrigerationEquipment"
          class="casbee-pulldown design-item-col-input" />
      </div>
    </div>
    <div class="design-bei">
      <div class="design-bei-item">
        <span class="design-bei-item-number">BEI</span>
        <default-pull-down-menu :disabled="isViewMode" :width="'80px'" isCasbeePulldown v-model="beiNumber"
          :items="beiNumberList" :initialValue="beiNumber" class="casbee-pulldown design-bei-input" />
      </div>
      <div class="design-bei-item">
        <span class="design-bei-item-number">地域区分</span>
        <default-pull-down-menu :disabled="isViewMode" :width="'80px'" isCasbeePulldown v-model="areaNumber"
          :items="areaNumberList" :initialValue="areaNumber" class="casbee-pulldown design-bei-input" />
      </div>
    </div>
    <div class="electricity-emissions">
      <p class="electricity-emissions-title">電力の排出係数</p>
      <p class="electricity-emissions-dec">電気の排出係数を変更したい場合は、数値を入力してください。</p>
      <div class="result-electric">
        <v-form ref="entryForm" >
          <div class="result-emissions">
            <casbee-input :disabled="isViewMode" v-model="numberOfPower" :value="numberOfPower" :label="'t-CO2/kWh'"
              :errorMessage="inputErrors.numberOfPower" @removeError="inputErrors.numberOfPower = ''" />
          </div>
          <div class="evaluate">
            <span class="evaluate-text">節水型設備の評価レベル</span>
            <span class="evaluate-dec">以下の項目のうち、取り組んでいるものの数を選択してください。</span>
            <ul class="evaluate-list">
              <li>節水トイレの設置</li>
              <li>節水水栓の設置</li>
              <li>食器用洗浄機の設置</li>
            </ul>
          </div>
          <div class="level">
            <default-pull-down-menu :disabled="isViewMode" width="320px" isCasbeePulldown height="32px"
              v-model="levelRatingOfWater" :items="levelRatingOfWaterList" :initialValue="levelRatingOfWater"
              class="casbee-pulldown level-input" />
          </div>
        </v-form>
        <common-button v-if="!isViewMode" class="casbee-btn mt-12" v-ripple="false" type="colored" :isDisable="isViewMode"
          :label="'入力した値で計算'" @action="handlerCalculationData()" />
      </div>
      <div>
      </div>
    </div>
  </div>
</template>
<script>


import DefaultPullDownMenu from "@/components/pulldown/DefaultPullDownMenu";
import { HOME_CHOOSE_CONDITION, HOT_EQUIQMENT, VALUE_CALCULATION_DEFAULT, COEFFICIENT, REFRIGERATION_EQUIQMENT, BEI_NUMBER, AREA_NUMBER, LEVEL_RATING_OF_WATER, WATER_SAVING_MECHANISM } from '@/constants/casbee/homeChooseCondition'
import { CASBEE_TYPE } from "@/constants/casbee.js";
import CasbeeInput from '@/components/casbee/input';
import { validateInputFieldCasbee } from '@/utils/validate';
import isEmpty from 'lodash/isEmpty';
import CommonButton from "@/components/utils/button.vue";
import { evaluate, floor } from 'mathjs';

export default {
  components: {
    DefaultPullDownMenu, CasbeeInput, CommonButton
  },
  props: {
    detailData: {
      type: Object,
      default: () => { },
    }
  },
  data() {
    return {
      headEquipment: HOT_EQUIQMENT.HOT_EQUIQMENT_1,
      headEquipmentList: this.formatDataToList(HOT_EQUIQMENT),
      refrigerationEquipment: REFRIGERATION_EQUIQMENT.REFRIGERATION_EQUIQMENT_1,
      refrigerationEquipmentList: this.formatDataToList(REFRIGERATION_EQUIQMENT),
      beiNumber: BEI_NUMBER.NUMBER_1,
      beiNumberList: this.formatDataToList(BEI_NUMBER),
      areaNumber: AREA_NUMBER.AREA_NUMBER1,
      areaNumberList: this.formatDataToList(AREA_NUMBER),
      levelRatingOfWaterList: this.formatDataToList(LEVEL_RATING_OF_WATER),
      numberOfPower: 0.00043,
      levelRatingOfWater: LEVEL_RATING_OF_WATER.LEVEL_1,
      inputErrors: {
        numberOfPower: '',
      },
    }
  },
  computed: {
    isViewMode() {
      return !isEmpty(this.detailData)
    },
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
  },
  mounted() {
    this.prepareDetailData();
  },
  methods: {
    formatDataToList(data) {
      return Object.keys(data).map((item) => {
        return { name: data[item], value: data[item] };
      });
    },
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
      if (this.isValidData) {
        let totalA = this.calcTotalA();
        let totalB = this.calcTotalB();
        let totalResult = evaluate(`${totalA.toString()} + ${totalB.toString()}`);
        totalResult = floor(totalResult, 4), {notation: 'fixed', precision: 4};
        let source = `CASBEE 戸建(新築) カテゴリ11&13 ${this.headEquipment} ${this.refrigerationEquipment} BEI${this.beiNumber} 地域${this.areaNumber} 節水: ${this.levelRatingOfWater}`;
        let casbeeData = {
          total: totalResult,
          label: '1㎡あたりCO2排出量',
          labelSource: `原単位を計算する際に、「出典」の内容ごとに計算結果を表示します。\n同じCASBEEファミリーの原単位を追加する場合でも、出典の内容は分けて記載してください。`,
          source: source,
          patternType: CASBEE_TYPE.HOME_CHOOSE_CONDITION,
          content_json: {
            headEquipment: this.headEquipment,
            refrigerationEquipment: this.refrigerationEquipment,
            beiNumber: this.beiNumber,
            areaNumber: this.areaNumber,
            numberOfPower: this.numberOfPower,
            levelRatingOfWater: this.levelRatingOfWater
          }
        }
        this.$emit('onHandlerCalculationData', casbeeData)
      } else {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
      }
    },
    calcTotalA() {
      let findObjectByCondition = HOME_CHOOSE_CONDITION.find(item => item.typeHeadEquipment === this.headEquipment && item.typeRefrigerationEquipment === this.refrigerationEquipment && item.bei === this.beiNumber);
      let powerSourceUnit = findObjectByCondition[`area${this.areaNumber}`];
      let electric = evaluate(`${this.numberOfPower} * ${VALUE_CALCULATION_DEFAULT.VALUE_1000} / ${VALUE_CALCULATION_DEFAULT.VALUE_976} * ${powerSourceUnit} * ${COEFFICIENT.ELECTRIC}`);
      let gas = evaluate(`${VALUE_CALCULATION_DEFAULT.GAS_VALUE} * ${powerSourceUnit} * ${COEFFICIENT.GAS}`);
      let lPG = evaluate(` ${VALUE_CALCULATION_DEFAULT.LPG_VALUE} *${ powerSourceUnit} * ${COEFFICIENT.LPG}`);
      let petroleum = evaluate(`${VALUE_CALCULATION_DEFAULT.FUEL_VALUE} * ${powerSourceUnit} * ${COEFFICIENT.FUEL}`);
      let amountOfHeat = evaluate(`${VALUE_CALCULATION_DEFAULT.HEART_VALUE} * ${powerSourceUnit}* ${COEFFICIENT.HEART}`);
      let energyConsumption1m2 = evaluate(`(${electric} + ${gas} + ${lPG} + ${petroleum} + ${amountOfHeat}) / ${VALUE_CALCULATION_DEFAULT.VALUE_1000}`);
      return energyConsumption1m2;
    },
    calcTotalB() {
      let waterSavingMechanism = WATER_SAVING_MECHANISM.find(item => item.type === this.levelRatingOfWater).value;
      let waterSavingResults = evaluate(`${waterSavingMechanism} * ${VALUE_CALCULATION_DEFAULT.VALUE_091} / ${VALUE_CALCULATION_DEFAULT.VALUE_1000}`);
      return waterSavingResults
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.design {
  margin-top: 56px;
  color: $monoBlack;

  .basis {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    display: block;
    margin-bottom: 8px;

    @include desktop {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
      margin-bottom: 12px;
    }
  }

  &-item {
    margin-bottom: 48px;

    @include desktop {
      display: flex;
    }

    &-hot {
      @include desktop {
        margin-right: 20px;
      }

      &-name {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $monoMid;
        display: block;
        margin-bottom: 8px;

        @include desktop {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $monoDark;
          margin-bottom: 12px;
        }
      }

      &-input {
        color: $monoBlack;
        background: $monoWhite;
      }
    }

    &-col {
      &-name {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $monoMid;
        display: block;
        margin: 8px 0;

        @include desktop {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $monoDark;
          margin: 0 0 12px 0;
        }
      }

      &-input {
        color: $monoBlack;
        background: $monoWhite;
        width: 320px;
      }
    }
  }

  &-bei {
    display: flex;
    justify-content: flex-start;
    gap: 48px;
    margin-bottom: 48px;

    @include desktop {
      gap: 40px;
    }

    &-item {
      &-number {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        margin-bottom: 8px;

        @include desktop {
          margin-bottom: 12px;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.05em;
        }
      }
    }
  }

  .electricity-emissions {

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;

      @include desktop {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
      }
    }

    &-dec {
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      margin-bottom: 8px;

      @include desktop {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
      }
    }

    .result-electric {
      .result-emissions {
        display: flex;
        align-items: center;
        margin-bottom: 48px;
        gap: 10px;

        @include desktop {
          gap: 20px;
        }

      }

      .evaluate {
        &-text {
          color: $monoBlack;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          display: block;

          @include desktop {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.05em;
          }
        }

        &-dec {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          display: block;
          margin-top: 8px;

          @include desktop {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: 12px;
          }
        }

        &-list {
          margin-bottom: 8px;

          @include desktop {
            margin-bottom: 12px;
          }

          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: $monoBlack;

            @include desktop {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .level {
    }
  }
}

.design-item {
  &-hot {
    ::v-deep .design-item-hot-input {
      width: 100% !important;
      height: 32px !important;

      .selection-box-bridge {
        height: 32px !important;

        .selection-box {
          height: 32px !important;
          display: flex;
          justify-content: space-between;
          padding: 7px 6px 9px 16px !important;
        }
      }
    }

    @include desktop {
      ::v-deep .design-item-hot-input {
        width: 320px !important;


        .selection-box-bridge {

          .selection-box {
            padding: 5px 10px 5px 7px !important;

            .input-icon {
              padding: 5px 5px 5px 3px !important;
            }
          }
        }
      }
    }
  }
}

.design-item {
  &-col {
    ::v-deep .design-item-col-input {
      width: 100% !important;
      height: 32px !important;

      .selection-box-bridge {
        height: 32px !important;

        .selection-box {
          height: 32px !important;
          display: flex;
          justify-content: space-between;
          padding: 7px 6px 9px 16px !important;
        }
      }
    }

    @include desktop {
      ::v-deep .design-item-col-input {
        width: 320px !important;

        .selection-box-bridge {

          .selection-box {
            padding: 5px 10px 5px 7px !important;

            .input-icon {
              padding: 5px 5px 5px 3px !important;
            }
          }
        }
      }
    }
  }
}

.design-bei {
  &-item {
    ::v-deep .design-bei-input {
      height: 32px !important;
      border-radius: 6px;

      .selection-box-bridge {
        height: 32px !important;

        .selection-box {
          height: 32px !important;
          padding: 6px 12px 8px 15px !important;

        }
      }
    }

    @include desktop {
      ::v-deep .design-bei-input {

        .selection-box-bridge {

          .selection-box {
            padding: 5px 10px 5px 7px !important;

            .input-box {
              justify-content: end;
            }

            .input-icon {
              padding: 5px 3px 5px 5px !important;
            }
          }
        }
      }
    }
  }
}

.result-electric {
  ::v-deep .common-btn.casbee-btn {
    width: 100% !important;
  }
  @include desktop {
    ::v-deep .common-btn.casbee-btn {
    width: 240px !important;
  }
  }
  .result-emissions {
    .casbee-input {

      ::v-deep .input-wrapper {
        .input {
          width: 200px !important;

          .input-elic {
            height: 32px !important;
            width: 200px !important;
            padding: 7px 16px 9px 16px;
          }
        }

        .label-des {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          margin-left: 8px !important;
        }
      }

      @include desktop {
        ::v-deep .input-wrapper {
          .input {
            width: 100px !important;

            .input-elic {
              width: 100px !important;
              padding: 6px 6px 6px 34px;
            }
          }

          .label-des {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.level {
  ::v-deep .level-input {
    width: 100% !important;
    height: 32px !important;

    .selection-box-bridge {
      width: 100% !important;
      height: 32px !important;

      .selection-box {
        height: 32px !important;
        display: flex;
        justify-content: space-between;
        padding: 6px 12px 8px 15px !important;

        .input-icon {}

      }
    }
  }

  @include desktop {
    ::v-deep .level-input {
      width: 320px !important;

      .selection-box-bridge {
        width: 320px !important;

        .selection-box {
          display: flex;
          justify-content: space-between;
          padding: 5px 10px 5px 7px !important;

          .input-icon {
            padding: 5px 3px 5px 5px !important;
          }

        }
      }
    }
  }
}
</style>
