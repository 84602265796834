import Vue from "vue";
import VueRouter from "vue-router";
import EmptyLayout from "@/layouts/Empty.vue";
import AuthLayout from "@/layouts/Auth.vue";
import OtherLayout from "@/layouts/Other.vue";
import DefaultLayout from "@/layouts/Default.vue";
import DBCustomize from "@/views/emissions/db-customize.vue";
import webStorage from "@/utils/webStorage";
import { ROUTES } from "./constants";
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { getDurations } from "@/api/duration";
import { getListDataPattern } from "@/api/newRegisterData";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { logoutApi } from '@/api/auth';
import { getDbCustom, getCasbeeDetail } from '@/api/dbCustomization';
import store from '@/store';
Vue.use(VueRouter);

const routes = [
  {
    path     : ROUTES.AUTH,
    component: AuthLayout,
    children : [
      {
        path     : ROUTES.LOGIN,
        name     : "Login",
        component: () => import("../views/auth/Login.vue"),
        meta     : {
          noRequiresAuth: true
        }
      },
      {
        path     : ROUTES.RESET_PASSWORD,
        name     : "Resetpassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta     : {
          noRequiresAuth: true
        }
      },
      {
        path     : ROUTES.FORGOT_PASSWORD,
        name     : "ForgotPassord",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta     : {
          noRequiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.DASHBOARD,
    component: DefaultLayout,
    children : [
      {
        path     : "/",
        name     : "Dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : "/managements",
    name     : "Managements",
    component: DefaultLayout,
    children : [
      {
        path     : ROUTES.USER_MANAGEMENT,
        name     : "User",
        component: () => import("@/views/managements/users.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.VIEWER_MANAGEMENT,
        name     : "Viewer",
        component: () => import("@/views/managements/viewer.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.SETTING,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "adminSetting",
        component: () => import("@/views/settingDashboard/Setting.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.EXPORT_REPORT,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "exportReport",
        component: () => import("@/views/export-report/index.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.EMISSIONS,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "EmissionsIndex",
        component: () => import("@/views/emissions/NewRegister/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSIONS,
        name     : "Emissions",
        component: () => import( "@/views/emissions/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.REGISTER_DATA,
        name     : "NewRegisterData",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/RegisterData"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSION_TEMPLATES,
        name     : "EmissionsListTemplates",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListTemplates"),
        meta     : {
          requiresAuth: true
        }
      }, {
        path     : ROUTES.LIST_EMISSION_SUBMITTED,
        name     : "EmissionsListSubmitted",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListSubmitted"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSION_TEMPORARY,
        name     : "EmissionsListTemporary",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListTemporaries"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_MENU_EMISSION,
        name     : 'EmissionsListMenu',
        component: () => import(/* webpackChunkName: "register data" */ '@/views/emissions/NewRegister/ListMenu'),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.APPROVAL,
        name     : "Approval",
        component: () => import(/* webpackChunkName: "approval data" */ "@/views/approval"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.VIEW_DATA,
        name     : "RegisterData",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/RegisterData.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CALCULATION_TERM,
        name     : "Calculation Term",
        component: () => import("@/views/emissions/calculation-term.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.FACILITY_MANAGEMENT,
        name     : "Facility",
        component: () => import("@/views/managements/facility.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CREATE_DATA_CUSTOMIZE,
        name     : "CreateDataCustomize",
        component: DBCustomize,
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.EMISSIONS_BY_BASE_PERIOD,
        name     : "EmissionsByBasePeriod",
        component: () =>
          import(/* webpackChunkName: "create data customize" */ "@/views/emissions/emission-by-base-period.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        name     : "EmissionsByBaseCategory",
        component: () =>
          import(/* webpackChunkName: "create data customize" */ "@/views/emissions/emission-by-base-category.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CASBEE,
        name     : "CreateCasbee",
        component: () => import("@/views/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CASBEE_DETAIL,
        name     : "CasbeeDetail",
        component: () => import("@/views/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.MONITORING,
        name     : "minitoringCasbee",
        component: () => import("@/views/emissions/dbCustomize/monitoring/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : "test",
        name     : "EmissionsIndex123123123213",
        component: () => import("@/views/export-report/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path     : ROUTES.CHANGE_PASSWORD_USER,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "Change password",
        component: () => import("@/views/auth/ChangePasswordAdmin.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.OTHERS,
    component: OtherLayout,
    children : [
      {
        path     : ROUTES.PRIVACY,
        name     : "Privacy",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/privacy.vue")
      },
      {
        path     : ROUTES.TERM_OF_USER,
        name     : "Term Of Use",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/term-of-use.vue")
      }
    ]
  },
  {
    path     : ROUTES.OTHERS,
    component: DefaultLayout,
    children : [
      {
        path     : ROUTES.OFFSET_TRANSACTION,
        name     : "Offset transaction app",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/offset-transaction-app.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.CONTACT,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "Contact",
        component: () => import("@/views/contact/index.vue")
      }
    ]
  },
  {
    path     : "/404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/error-page/404.vue")
  },
  {
    path    : "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x       : 0,
      y       : 0,
      behavior: "smooth"
    };
  }
});

// define list router approval can't access
const listRouterApprovalBlocked = [
  ROUTES.USER_MANAGEMENT,
  ROUTES.VIEWER_MANAGEMENT,
  ROUTES.FACILITY_MANAGEMENT,
  ROUTES.CALCULATION_TERM,
]

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    let token = webStorage.getToken();
    if (!token) {
      webStorage.removeAll();
      next({ name: "Login" });
    } else {
      //check permission approval role
      const roleId = webStorage.getRoleId()
      if (roleId === 4 && to.path === '/approval') {
        next({path: '/'});
      }
      if(listRouterApprovalBlocked.includes(to.path) && roleId === 5) {
        next({ path: "/404" });
      }
      if (to.matched.some(rec => rec.name === "RegisterData") && to.params?.scope === "3") {
        let isEnableScope3     = true;
        let durations          = await getDurations();
        const selectedDuration = durations.data.find((item) => item.is_selected === true);
        if (selectedDuration?.id) {
          await getScopesAndCategories({ duration_id: selectedDuration?.id }).then((res) => {
            let displayScope3 = res.find((object) => {
              return object.hasOwnProperty("display_scope_3");
            });
            isEnableScope3    = displayScope3.display_scope_3;
            return isEnableScope3;
          });
        } else {
          isEnableScope3 = false;
        }
        if (isEnableScope3) {
          next();
        } else {
          next({ path: "/404" });
        }
      } else if (to.path === '/emissions/list-menu') {
        let durations          = await getDurations();
        if (durations.data.length === 0) {
          next({ path: "/emissions" });
        }
      } else if (['approval', 'list-submitted', 'list-menu'].includes(to.params?.scope)) {
        try {
          const responseData = await getListDataPattern({id: to.params?.category});
          if (responseData?.workflow_data) {
            const workflowData = {
              ...responseData.workflow_data,
              scope_id: parseInt(responseData.workflow_data.scope),
              category_id: parseInt(responseData.workflow_data.category),
              approved_by: responseData.workflow_data.approved_by,
            };
            await updateDataBeforeRedirect(workflowData);
          }
        } catch (error) {
          next({ path: "/approval" });
        }
      } else if (["CreateCasbee", "minitoringCasbee"].includes(to.name)) {
        const dbCustomizeRes = await getDbCustom({
          contractor_id: store.state.userData.currentUser.user.contractor_id,
        });
        if (!dbCustomizeRes.is_casbee) {
          next({name: 'CreateDataCustomize'})
        }
      } else if (to.name === "CasbeeDetail") {
        if (Number.isInteger(parseInt(to.params.id))) {
          await getCasbeeDetail(parseInt(to.params.id))
            .then(() => {
              next();
            })
            .catch(() => {
              next({name: 'CreateDataCustomize'})
            });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }

  // check redirect to home in auth layout
  if (to.matched.some(rec => rec.meta.noRequiresAuth)) {
    let token = webStorage.getToken();
    if (token) {
      if(to.path === '/auth/login' && to.query?.email && to.query?.id) {
        await logoutApi();
        next({ name: "Login", query: to.query });
      } else {
        next({ name: "Dashboard" });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
