<template>
  <div>
    <v-dialog
      v-model="dialogSaveTemplateData"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="reset-padding-card-text"
    >
      <v-card>
        <v-card-title>
          <span v-if="isCloneTemplate" class="">新規テンプレートとして保存</span>
          <span v-else>حفظ النموذج</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="selectTemplateForm" @submit.prevent="submitHandler">
            <div class="input-common">
              <div class="input-label">عنوان</div>
              <v-text-field
                v-model="templateType"
                dense
                solo
                hide-details
                clearable
                class="clear-data-input"
              />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <common-button class="left-button-popup" @action="cancelDialog" label="يغلق"></common-button>
          <common-button @action="submitHandler" label="يحفظ" type="redColor"></common-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import { TEMPLATE_TYPES } from '@/constants/templateData'

export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isCloneTemplate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      templateType: null,
      requiredRule: [(valueInput) => !!valueInput || 'هذا إلزامي.'],
      templateTypes: TEMPLATE_TYPES,
    };
  },
  mounted() {
  },
  computed: {
    dialogSaveTemplateData: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    cancelDialog() {
      this.$refs.selectTemplateForm.reset();
      this.$emit('closeDialogSaveTemplate');
    },
    submitHandler() {
      if (this.$refs.selectTemplateForm.validate()) {
        this.$emit('closeDialogSaveTemplate');
        this.$emit('submitHandler', this.templateType);
        this.$refs.selectTemplateForm.reset();
      }
    },
    handleClickOutSite() {
      this.$emit('closeDialogSaveTemplate');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
  form.v-form {
    width: 100%;
  }
</style>
