<template>
  <div class="main">
    <div class="wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
        </div>
      </div>
      <div class="right_wrap" v-if="!status">
        <v-form class="right_block forgetPass_frm" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text">
            <span class="title-text-style">パスワードをお忘れですか？</span>
          </h1>
          <div class="instruction forgetPass_inst mt-48">
            <span
              >登録しているメールアドレスを入力してください。<br />新しいパスワードを設定するためのリンクをお送りします。</span
            >
          </div>
          <div class="input-block mt-47">
            <span class="input-title">メールアドレス</span>
            <input id="email" class="input-elic mb-0" v-model="email" />
            <span class="err-mess" style="display: none" id="email_error"></span>
            <img
              id="email_img"
              class="validWarning"
              src="/img/icons/warning.svg"
              style="position: absolute; right: 6px; display: none"
            />
          </div>

          <div class="input-block mt-48">
            <input class="btn-submit" :disabled="disabled" type="submit" value="送信" />
          </div>
        </v-form>
      </div>

      <div class="right_wrap" v-if="status">
        <v-form class="right_block forgetPass_frm2" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text">
            <span class="title-text-style">送信が完了しました。</span>
          </h1>
          <div class="instruction forgetPass_inst mt-48">
            <span
              >パスワード再設定のリンクを送信しました。<br />15分以内に届かない場合は、迷惑メールフォルダをご確認ください。</span
            >
          </div>
          <div class="input-block mt-46">
            <input
              class="btn-submit"
              @click="$router.push({ name: 'Login' })"
              type="submit"
              value="ログイン画面に戻る"
            />
          </div>
        </v-form>
      </div>
    </div>
    <error-popup :dialog="dialog" :message="message" @submit="handleClose" />
  </div>
</template>

<script>
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import validate from '@/utils/validate';
import { forgotPasswordApi } from '@/api/auth';
import { validateRequired } from '@/api/others';
import { getErrorMessge } from '@/utils/messageHandle';

export default {
  data() {
    return {
      dialog: false,
      dialogLockPass: false,
      dialogCheck: false,
      email: '',
      message: '',
      emailRules: [(v) => validate.regexMail.test(v) || ''],
      status: false,
      disabled: false,
    };
  },
  components: {
    ErrorPopup,
  },
  methods: {
    submit() {
      if (!validateRequired(['email'])) {
        return;
      }
      this.disabled = true;
      forgotPasswordApi({ email: this.email, redirect_url: `${window.location.origin}/auth/reset-password` })
        .then(() => {
          this.status = true;
          this.disabled = false;
        })
        .catch((err) => {
          this.dialog = true;
          this.message = getErrorMessge(err);
          this.disabled = false;
        });
    },
    handleClose() {
      this.email = '';
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './LoginStyle/index.scss';

.while-color {
  background: #ffffff;
}
.wrap {
  min-height: 270px;
  overflow: auto;
}
.title-text {
  span {
    position: relative !important;
    top: 1px !important;
  }
}
</style>
