import {FILTER_DATA, ORGANIZATIONAL_DATA} from '@/constants/registerData'
let filterTypes = [FILTER_DATA.ORGANIZATIONAL, FILTER_DATA.COMPANY, FILTER_DATA.BUSINESS, FILTER_DATA.COUNTRY, FILTER_DATA.LAYER3, FILTER_DATA.LAYER4, FILTER_DATA.LAYER5, FILTER_DATA.LAYER6];
let dynamicFilterTypes = [FILTER_DATA.BUSINESS, FILTER_DATA.COUNTRY, FILTER_DATA.LAYER3, FILTER_DATA.LAYER4, FILTER_DATA.LAYER5, FILTER_DATA.LAYER6];
import {BLANK_ID} from '@/constants/registerData';
const TEXT_NULL = '(فارغ)';

export const handlerFilterData = (column, row, cellData, currentItem, filterData, source, filterIndex, selectedFirst, previousCellData, isReverted) => {
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  if (previousCellData === cellData) return;
  addFilterIndex(column, filterIndex[row])
  updateFilter(column, cellData, currentItem, filterData, source, filterIndex[row], selectedFirst, row, isReverted)
  if (!cellData) {
    addFilterIndex(column, filterIndex[row], true)
  }
  if (cellData === TEXT_NULL && dynamicFilterTypes.includes(column.binding)) {
    source.setCellData(row, source.columns.getColumn(column.binding).index, null, false, true)
  }
}

export const handlerAddFilterData = (column, row, cellData, currentItem, filterPatternList, source, filterIndex, selectedFirst, registerData) => {
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  if (filterIndex[row].length === 0) {
    if (currentItem.id !== BLANK_ID) {
      resetFilter([column.binding], source, false, selectedFirst)
      const columnIndex = source.columns.findIndex((item) => item.binding === column.binding)
      source.columns[columnIndex].editor.itemsSource = registerData.listBranch[column.binding].filter(item => item.status === true);
    } else if (currentItem.id === BLANK_ID) {
      const allFieldsEmpty = filterTypes.every((item) => !currentItem[item])
      if (allFieldsEmpty) {
        resetFilter([FILTER_DATA.ORGANIZATIONAL, FILTER_DATA.COMPANY, FILTER_DATA.BUSINESS, FILTER_DATA.COUNTRY, FILTER_DATA.LAYER3, FILTER_DATA.LAYER4, FILTER_DATA.LAYER5, FILTER_DATA.LAYER6], source, false, selectedFirst)
        beginningEditGetAll(source, registerData, selectedFirst, currentItem)
      }
    }
  } else {
    const selectedIndex = filterIndex[row].findIndex((item) => item === column.binding);
    if (selectedIndex >= 0) {
      switch (selectedIndex) {
        case 0:
          resetFilter([filterIndex[row][0]], source, false, selectedFirst, row)
          beginningEditLayer1(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 1:
          resetFilter([filterIndex[row][1]], source, false, selectedFirst)
          beginningEditLayer2(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 2:
          resetFilter([filterIndex[row][2]], source, false, selectedFirst)
          beginningEditLayer3(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 3:
          resetFilter([filterIndex[row][3]], source, false, selectedFirst)
          beginningEditLayer4(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 4:
          resetFilter([filterIndex[row][4]], source, false, selectedFirst)
          beginningEditLayer5(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 5:
          resetFilter([filterIndex[row][5]], source, false, selectedFirst)
          beginningEditLayer6(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 6:
          resetFilter([filterIndex[row][6]], source, false, selectedFirst)
          beginningEditLayer7(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
        case 7:
          resetFilter([filterIndex[row][7]], source, false, selectedFirst)
          beginningEditLayer8(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
          break;
      }
    } else {
      if (filterIndex[row].length === 1) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer2(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 2) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer3(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 3) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer4(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 4) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer5(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 5) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer6(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 6) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer7(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      } else if (filterIndex[row].length === 7) {
        resetFilter([column.binding], source, false, selectedFirst)
        beginningEditLayer8(column, source, registerData, filterIndex, row, currentItem, selectedFirst)
      }
    }
  }
}

export const listeningEventWhenPasted = (tableData, filterIndex) => {
  tableData.itemsSourceChanged.addHandler((sender, event) => {
    sender.rows.forEach((row, rowIndex) => {
      const rowData = row.dataItem;
      if (rowData?.id && rowData.id !== BLANK_ID) {
        sender.columns.forEach((column, columnIndex) => {
          if (filterTypes.includes(column.binding) && sender.getCellData(rowIndex, columnIndex, false) !== null && sender.getCellData(rowIndex, columnIndex, false) !== undefined) {
            // addFilterByPasted(sender.columns[columnIndex], filterIndex, rowIndex)
          }
        })
      }
    })
  })
}

export const addFilterByPasted = (column, filterIndex, row) => {
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  addFilterIndex(column, filterIndex[row])
}

const addFilterIndex = (column, filterIndex, isRemove = false) => {
  const existIndex = filterIndex?.findIndex((item) => {
    return item === column.binding;
  });
  if (isRemove) {
    filterIndex.splice(existIndex, filterIndex.length)
  }
  if (existIndex < 0) {
    filterIndex.push(column.binding)
  } else {
    filterIndex.splice(existIndex + 1, filterIndex.length)
  }
}

const updateFilter = (column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted) => {
  const allFieldsEmpty = filterTypes.every((item) => !currentItem[item])
  if (allFieldsEmpty) {
    updateGetAllData(filterData, source, selectedFirst, column)
    selectedFirst = null
    return
  }
  filterCondition(column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted)
  if (!selectedFirst) {
    selectedFirst = column.binding
  }
}

const updateGetAllData = (filterData, source, selectedFirst, column) => {
  resetFilterIndex([FILTER_DATA.ORGANIZATIONAL, FILTER_DATA.COMPANY, FILTER_DATA.BUSINESS, FILTER_DATA.COUNTRY, FILTER_DATA.LAYER3, FILTER_DATA.LAYER4, FILTER_DATA.LAYER5, FILTER_DATA.LAYER6], source)
  resetFilter([FILTER_DATA.ORGANIZATIONAL, FILTER_DATA.COMPANY, FILTER_DATA.BUSINESS, FILTER_DATA.COUNTRY, FILTER_DATA.LAYER3, FILTER_DATA.LAYER4, FILTER_DATA.LAYER5, FILTER_DATA.LAYER6], source, false, selectedFirst)

  let filterList = {};
  filterData.forEach((item) => {
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterCondition = (column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted) => {
  const selectedIndex = filterIndex.findIndex((item) => item === column.binding);
  if (selectedIndex < 0) return;
  let bindingColumns = []
  switch (selectedIndex) {
    case 0:
      bindingColumns = [column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer1(column, row, cellData, source, selectedFirst, filterData, isReverted, currentItem)
      break;
    case 1:
      bindingColumns = [filterIndex[0], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer2(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 2:
      bindingColumns = [filterIndex[0], filterIndex[1], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer3(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 3:
      bindingColumns = [filterIndex[0], filterIndex[1],filterIndex[2], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer4(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 4:
      bindingColumns = [filterIndex[0], filterIndex[1],filterIndex[2], filterIndex[3], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer5(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 5:
      bindingColumns = [filterIndex[0], filterIndex[1],filterIndex[2], filterIndex[3], filterIndex[4], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer6(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 6:
      bindingColumns = [filterIndex[0], filterIndex[1],filterIndex[2], filterIndex[3], filterIndex[4], filterIndex[5], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer7(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
    case 7:
      bindingColumns = [filterIndex[0], filterIndex[1],filterIndex[2], filterIndex[3], filterIndex[4], filterIndex[5], filterIndex[6], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, row)
      filterLayer8(column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted)
      break;
  }
}

const filterLayer1 = (column, row, cellData, source, selectedFirst, filterData, isReverted, currentItem) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (currentItem?.id === BLANK_ID && item.status === false) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer2 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer3 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer4 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, filterIndex[2], currentItem[filterIndex[2]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer5 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, filterIndex[2], currentItem[filterIndex[2]])) return;
    if (!checkItemExist(item, filterIndex[3], currentItem[filterIndex[3]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer6 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, filterIndex[2], currentItem[filterIndex[2]])) return;
    if (!checkItemExist(item, filterIndex[3], currentItem[filterIndex[3]])) return;
    if (!checkItemExist(item, filterIndex[4], currentItem[filterIndex[4]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer7 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, filterIndex[2], currentItem[filterIndex[2]])) return;
    if (!checkItemExist(item, filterIndex[3], currentItem[filterIndex[3]])) return;
    if (!checkItemExist(item, filterIndex[4], currentItem[filterIndex[4]])) return;
    if (!checkItemExist(item, filterIndex[5], currentItem[filterIndex[5]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer8 = (column, row, cellData, source, selectedFirst, filterData, filterIndex, currentItem, isReverted) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[1], currentItem[filterIndex[1]])) return;
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, filterIndex[2], currentItem[filterIndex[2]])) return;
    if (!checkItemExist(item, filterIndex[3], currentItem[filterIndex[3]])) return;
    if (!checkItemExist(item, filterIndex[4], currentItem[filterIndex[4]])) return;
    if (!checkItemExist(item, filterIndex[5], currentItem[filterIndex[5]])) return;
    if (!checkItemExist(item, filterIndex[6], currentItem[filterIndex[6]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const resetSelectedData = (items, source, row) => {
  let types = [];
  types = filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
  types.forEach((type) => {
    const columnIndex = source.columns.findIndex((column) => column.binding === type)
    if (columnIndex >= 0) {
      source.setCellData(row, columnIndex, null, false, true)
    }
  });
}

const resetFilter = (items, source, isRemove = false, selectedFirst) => {
  if (isRemove) {
    items = filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
  }
  items.forEach((type) => {
    const columnIndex = source.columns.findIndex((column) => column.binding === type)
    if (columnIndex >= 0 && selectedFirst !== type) {
      source.columns[columnIndex].editor.itemsSource = []
    }
  })
}

const filterOrganizational = (item, cellData) => {
  if ((cellData !== null && item.organizational_division === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterCompanies = (item, cellData) => {
  if ((cellData !== null && item.company_name === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterBusiness = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.business_name === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterCountry = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.country === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterLayer3Item = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.layer_3 === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterLayer4Item = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.layer_4 === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterLayer5Item = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.layer_5 === cellData) || cellData === null) {
    return true
  }
  return false
}

const filterLayer6Item = (item, cellData) => {
  if (cellData === null) {
    cellData = TEXT_NULL
  }
  if ((cellData !== null && item.layer_6 === cellData) || cellData === null) {
    return true
  }
  return false
}

const resetFilterIndex = (items, source) => {
  items.forEach((item) => {
    source[item] = null
  })
}

const addOrganizationalBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.COMPANY) return;
  // check company exist
  const existIndex = list?.findIndex((organiItem) => {
    return organiItem.key === item.organizational_division;
  });
  if (existIndex < 0 && item.organizational_division) {
    list.push({
      key: item.organizational_division,
      value: ORGANIZATIONAL_DATA[item.organizational_division],
    })
  }
  organiListSort(list);
}

const addCompanyBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.COMPANY) return;
  // check company exist
  const existIndex = list.findIndex((company) => {
    return company.key === item.company_name;
  });

  if (existIndex < 0 && item.company_name) {
    list.push({
      key: item.company_name,
      value: item.company_name,
    })
  }
}

const addBusinessBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.COMPANY) return;
  // check company exist
  const existIndex = list?.findIndex((business) => {
    return business.key === item.business_name;
  });
  if (existIndex < 0 && item.business_name) {
    list.push({
      key: item.business_name,
      value: item.business_name,
    })
  }
}

const addCountryBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.COUNTRY) return;
  // check company exist
  const existIndex = list?.findIndex((country) => {
    return country.key === item.country;
  });
  if (existIndex < 0 && item.country) {
    list.push({
      key: item.country,
      value: item.country,
    })
  }
}

const addLayer3Beginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.LAYER3) return;
  // check company exist
  const existIndex = list?.findIndex((layer3) => {
    return layer3.key === item.layer_3;
  });
  if (existIndex < 0 && item.layer_3) {
    list.push({
      key: item.layer_3,
      value: item.layer_3,
    })
  }
}

const addLayer4Beginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.LAYER4) return;
  // check company exist
  const existIndex = list?.findIndex((layer4) => {
    return layer4.key === item.layer_4;
  });
  if (existIndex < 0 && item.layer_4) {
    list.push({
      key: item.layer_4,
      value: item.layer_4,
    })
  }
}

const addLayer5Beginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.LAYER5) return;
  // check company exist
  const existIndex = list?.findIndex((layer5) => {
    return layer5.key === item.layer_5;
  });
  if (existIndex < 0 && item.layer_5) {
    list.push({
      key: item.layer_5,
      value: item.layer_5,
    })
  }
}

const addLayer6Beginning = (item, selectedFirst, list) => {
  if (selectedFirst === FILTER_DATA.LAYER6) return;
  // check company exist
  const existIndex = list?.findIndex((layer6) => {
    return layer6.key === item.layer_6;
  });
  if (existIndex < 0 && item.layer_6) {
    list.push({
      key: item.layer_6,
      value: item.layer_6,
    })
  }
}

const addItemToList = (column, item, selectedFirst, filterList) => {
  if (typeof filterList[column.binding] === 'undefined') {
    filterList[column.binding] = []
  }
  switch (column.binding) {
    case FILTER_DATA.ORGANIZATIONAL:
      addOrganizationalBeginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.COMPANY:
      addCompanyBeginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.BUSINESS:
      addBusinessBeginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.COUNTRY:
      addCountryBeginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.LAYER3:
      addLayer3Beginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.LAYER4:
      addLayer4Beginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.LAYER5:
      addLayer5Beginning(item, selectedFirst, filterList[column.binding]);
      break;
    case FILTER_DATA.LAYER6:
      addLayer6Beginning(item, selectedFirst, filterList[column.binding]);
      break;
  }
}

const addItemsSource = (filterList, source, column) => {
  for (const key in filterList) {
    if (filterList[key].length > 0) {
      const columnIndex = source.columns.findIndex((item) => item.binding === column.binding)
      source.columns[columnIndex].editor.itemsSource = moveElementToLastPosition(filterList[key])
    }
  }
}

const beginningEditGetAll = (source, registerData, selectedFirst, currentItem) => {
  let filterList = {};
  filterTypes.forEach((type) => {
    filterList[type] = [];
  })
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (disabledFacilityCondition(currentItem, item)) return;
    addOrganizationalBeginning(item, selectedFirst, filterList[FILTER_DATA.ORGANIZATIONAL])
    addCompanyBeginning(item, selectedFirst, filterList[FILTER_DATA.COMPANY])
    addBusinessBeginning(item, selectedFirst, filterList[FILTER_DATA.BUSINESS])
    addCountryBeginning(item, selectedFirst, filterList[FILTER_DATA.COUNTRY])
    addLayer3Beginning(item, selectedFirst, filterList[FILTER_DATA.LAYER3])
    addLayer4Beginning(item, selectedFirst, filterList[FILTER_DATA.LAYER4])
    addLayer5Beginning(item, selectedFirst, filterList[FILTER_DATA.LAYER5])
    addLayer6Beginning(item, selectedFirst, filterList[FILTER_DATA.LAYER6])
  })
  filterTypes.forEach((type) => {
    const columnIndex = source.columns.findIndex((item) => item.binding === type)
    if (columnIndex >= 0) {
      source.columns[columnIndex].editor.itemsSource = moveElementToLastPosition(filterList[type])
    }
  })
}

const beginningEditLayer1 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  const columnIndex = source.columns.findIndex((item) => item.binding === column.binding)
  let filterList = [];
  registerData.listBranch[column.binding].forEach(item => {
    if (currentItem?.id === BLANK_ID && item.status === false) return;
    // condition check if row data added and "organizational_division" data field is not registered in this row
    if (currentItem?.id && currentItem?.branch?.id && item.status === false
      && currentItem?.branch.status === true && item.key !== currentItem?.branch?.organizational_division) return;
    if (currentItem?.id && currentItem?.branch?.id && item.status === false && item.key !== currentItem?.branch?.organizational_division) return;
    filterList.push(item);
  })
  source.columns[columnIndex].editor.itemsSource = filterList;
}

const beginningEditLayer2 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer3 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer4 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (!checkItemExist(item, filterIndex[row][2], currentItem[filterIndex[row][2]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer5 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (!checkItemExist(item, filterIndex[row][2], currentItem[filterIndex[row][2]])) return;
    if (!checkItemExist(item, filterIndex[row][3], currentItem[filterIndex[row][3]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer6 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (!checkItemExist(item, filterIndex[row][2], currentItem[filterIndex[row][2]])) return;
    if (!checkItemExist(item, filterIndex[row][3], currentItem[filterIndex[row][3]])) return;
    if (!checkItemExist(item, filterIndex[row][4], currentItem[filterIndex[row][4]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer7 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (!checkItemExist(item, filterIndex[row][2], currentItem[filterIndex[row][2]])) return;
    if (!checkItemExist(item, filterIndex[row][3], currentItem[filterIndex[row][3]])) return;
    if (!checkItemExist(item, filterIndex[row][4], currentItem[filterIndex[row][4]])) return;
    if (!checkItemExist(item, filterIndex[row][5], currentItem[filterIndex[row][5]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer8 = (column, source, registerData, filterIndex, row, currentItem, selectedFirst) => {
  let filterList = {};
  registerData.listBranch.filterPatternList.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (!checkItemExist(item, filterIndex[row][1], currentItem[filterIndex[row][1]])) return;
    if (!checkItemExist(item, filterIndex[row][2], currentItem[filterIndex[row][2]])) return;
    if (!checkItemExist(item, filterIndex[row][3], currentItem[filterIndex[row][3]])) return;
    if (!checkItemExist(item, filterIndex[row][4], currentItem[filterIndex[row][4]])) return;
    if (!checkItemExist(item, filterIndex[row][5], currentItem[filterIndex[row][5]])) return;
    if (!checkItemExist(item, filterIndex[row][6], currentItem[filterIndex[row][6]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const checkItemExist = (item, filterType, filterItem) => {
  switch (filterType) {
    case FILTER_DATA.ORGANIZATIONAL:
      if (!filterOrganizational(item, parseInt(filterItem))) return false
      break;
    case FILTER_DATA.COMPANY:
      if (!filterCompanies(item, filterItem)) return false
      break;
    case FILTER_DATA.BUSINESS:
      if (!filterBusiness(item, filterItem)) return false
      break;
    case FILTER_DATA.COUNTRY:
      if (!filterCountry(item, filterItem)) return false
      break;
    case FILTER_DATA.LAYER3:
      if (!filterLayer3Item(item, filterItem)) return false
      break;
    case FILTER_DATA.LAYER4:
      if (!filterLayer4Item(item, filterItem)) return false
      break;
    case FILTER_DATA.LAYER5:
      if (!filterLayer5Item(item, filterItem)) return false
      break;
    case FILTER_DATA.LAYER6:
      if (!filterLayer6Item(item, filterItem)) return false
      break;
  }
  return true
}

export const getColumnIndexByBinding = (source, binding) => {
  return source.columns.findIndex((column) => column.binding === binding);
}

export const getBranchId = (filterPatternList, filterData) => {
  return filterPatternList.find((item) => {
    for (const key in filterData) {
      if (dynamicFilterTypes.includes(key)) {
        filterData[key] = filterData[key] === null ? '(فارغ)' : filterData[key]
      }
      if (key === "organizational_division" && parseInt(filterData[key]) !== item[key]) {
        return null
      }
      if (key !== "organizational_division" && filterData[key] !== item[key]) {
        return null
      }
    }
    return item
  })
}

export const checkFilterData = (filterPatternList, layerFilter, filterData) => {
  let errorMessage = '';
  return errorMessage;
  for (const rowIndex in filterData) {
    const processFilterData = {}
    processFilterData[FILTER_DATA.ORGANIZATIONAL] = filterData[rowIndex][FILTER_DATA.ORGANIZATIONAL]
    processFilterData[FILTER_DATA.COMPANY] = filterData[rowIndex][FILTER_DATA.COMPANY]
    for (const colName in layerFilter) {
      processFilterData[colName] = filterData[rowIndex][colName] || null
    }
    const isExist = filterPatternList.findIndex((item) => {
      for (const key in processFilterData) {
        if (processFilterData[key] !== item[key]) {
          return null
        }
      }
      return item
    })
    if (isExist < 0) {
      let colNames = ''
      for (const colName in layerFilter) {
        colNames += `/${layerFilter[colName]}`
      }
      errorMessage += `${Number(rowIndex) + 1}行目の入力された組織区分/法人名${colNames}の組み合わせが存在しません <br>`
    }
  }
  return errorMessage;
}

const moveElementToLastPosition = (arr) => {
  const fromIndex = arr.findIndex((item) => item.key === '(فارغ)')
  if (fromIndex >= 0) {
    const arrLength = arr.length
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(arrLength - 1, 0, element);
  }
  return arr
}

const disabledFacilityCondition = (currentItem, item) => {
  if ((currentItem?.id === BLANK_ID || currentItem?.id) && item.status === false) return true;
  // if (currentItem?.id && item.status === false && currentItem?.branch?.id !== item.id) return true;
  return false;
}

const organiListSort = (list) => {
  const sorter = '単体子会社関連会社その他関連会社';
  list.sort((a, b) => {
    const indexA = sorter.indexOf(a.value);
    const indexB = sorter.indexOf(b.value);

    return indexA - indexB;
  })
}
