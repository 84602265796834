import * as wjGrid from '@grapecity/wijmo.grid';
import store from '@/store';
import { updateListData, addListData, deleteListData } from '@/api/registerData';
import { AutoComplete } from '@grapecity/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_10_CHARACTOR,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_POSITIVE_NUMBER
 } from '@/constants/registerData';
 import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { batchReplacementViewCollection, emptyPromise, formatValue } from './wijmo.helper'
import { CellEditEndingEventArgs, CellRange } from '@grapecity/wijmo.grid'
const patternUrl = '/pattern-s3-c9/3';
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, listeningEventWhenPasted, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    fuel: null,
    target_model: null,
    distance: null,
    fuel_efficiency: null,
    unit: null,
    value: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    fuel_efficiency_source: null,
    distance_source: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
  }
}

const energyTypes = [
  {
    key: 'ガソリン',
    value: 'ガソリン'
  },
  {
    key: '軽油',
    value: '軽油'
  }
]

const sources = [
  {
    type: 'gasoline',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '算定･報告･公表制度における排出係数 【エネルギー起源CO2】 燃料の使用 ガソリン',
    value_source: 2.32
  },
  {
    type: 'oil',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '算定･報告･公表制度における排出係数 【エネルギー起源CO2】 燃料の使用 軽油',
    value_source: 2.58
  },
]

const validateNumberField = (input, propName = '') => {
  if (input) {
    let numberData = input.replace(/[^\d.]/g, '');
    if (Number(input) < 0) {
      return MESSAGE_POSITIVE_NUMBER;
    }
    if (input.length > 10 && propName === 'fuel_efficiency') {
      return MESSAGE_MAX_10_CHARACTOR;
    }
    if (input.length > 20) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
    if (numberData && input.length - numberData.length == 0) {
      return null;
    } else {
      return MESSAGE_IS_NUMBER;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
}

const notNullInView = [
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const notNullList = [
  'organizational_division',
  'company_name',
  'fuel',
  'distance',
  'fuel_efficiency',
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const max10Charactor = [
  'fuel_efficiency',
]

const max20Charactor = [
  'distance',
  'wsu_value',
  'wsu_unit',
]

const max128Charactor = [
  'company_name',
  'business_name',
  'fuel',
  'target_model',
  'memo_1',
  'memo_2',
  'fuel_efficiency_source',
  'distance_source'
]

const numberField = [
  'distance',
  'fuel_efficiency',
]

const isValidData = (item) => {
  if (notNullList.some(field => !item[field] && item[field] !== 0 && !notNullInView.includes(field))) {
    return false
  }
  if (max128Charactor.some(field => item[field]?.length > 128)) {
    return false
  }
  if (max20Charactor.some(field => item[field]?.length > 20)) {
    return false
  }
  if (max10Charactor.some(field => item[field]?.length > 10)) {
    return false
  }
  if (numberField.some(field => validateNumberField(String(item[field])) !== null)) {
    return false
  }
  if (item.fuel_efficiency > 9999999999) {
    return false
  }
  return true
}

const calcEmissions = (valueSource, distance, fuelEfficiency) => {
  valueSource = Number(valueSource);
  distance = Number(distance);
  fuelEfficiency = Number(fuelEfficiency);

  // if (!valueSource && !distance && !fuelEfficiency) {
  //   return null
  // }

  if (!valueSource || !distance || !fuelEfficiency) {
    return 0
  }

  return Math.abs(valueSource * distance / fuelEfficiency)
}

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']

const partern2 = {
  initializeGrid(flexgrid) {
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= 100; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }

      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            flexgrid.itemsSource.clearChanges();
          });
        }
      }

      if ((e.keyCode === KEYS_CODE.BACKSPACE || e.keyCode === KEYS_CODE.DELETE) && !flexgrid.selection.isSingleCell){
        flexgrid.autoSizeColumns();
        flexgrid.autoSizeRows(0, 0, true);
      }
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (s.columns[colIndex].binding === 'fuel') {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
            cEvent.custom = true
            s.onCellEditEnded(cEvent)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(store.state.registerData.listBranch.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const item = source[row] || {}
      const currentItem = source[row] || {}
      // check row editing

      if (column.binding === 'distance') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), calcEmissions(item.wsu_value, cellData, item.fuel_efficiency), false, true)
        })
      }

      if (column.binding === 'fuel_efficiency') {
        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), calcEmissions(item.wsu_value, item.distance, cellData), false, true)
        })
      }

      if (column.binding === 'fuel') {
        let newEnergy = null;
        if (cellData === '軽油') {
          newEnergy = sources[1];
        }
        if (cellData === 'ガソリン') {
          newEnergy = sources[0];
        }

        s.deferUpdate(() => {
          s.setCellData(row, getColumnIndexByBinding(s, 'wsu_value'), newEnergy?.value_source, false, true)
          s.setCellData(row, getColumnIndexByBinding(s, 'wsu_unit'), newEnergy?.unit_source, false, true)
          s.setCellData(row, getColumnIndexByBinding(s, 'wsu_source'), newEnergy?.source, false, true)

          s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), calcEmissions(item.wsu_value, item.distance, item.fuel_efficiency), false, true)
        })
      }
      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, store.state.registerData?.listBranch?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];


      if (
        column.binding === 'unit' ||
        column.binding === 'wsu_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'emissions'
      ) {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, store.state.registerData.listBranch?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData);
      }
    });
    flexgrid.pasted.addHandler(() => {
      flexgrid.autoSizeColumns();
      flexgrid.autoSizeRows(0, 0, true);
    });
    flexgrid.autoSizeColumns();
  },

  header(registerData, listLayout) {
    let companyMap = new wjGrid.DataMap(registerData.listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = registerData.listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(registerData.listBranch.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = registerData.listBranch?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
      },
      {
        header: '組織区分',
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 140,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: '法人名',
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: registerData.listBranch?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: '燃料',
        binding: 'fuel',
        minWidth: 95,
        maxWidth: 210,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: new wjGrid.DataMap(energyTypes, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          itemsSource: energyTypes,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      {
        header: '車種', // Loại xe thuộc đối tượng
        binding: 'target_model',
        minWidth: 95,
        maxWidth: 480,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true,
      },
      {
        header: '備考1', // memo1
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '備考2', // memo2
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: '輸送データ',
        align: 'center',
        columns: [
          {
            header: '輸送距離（km）', //
            binding: 'distance',
            minWidth: 142,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            inputType: 'float',
            dataType: 'Number',
          },
          {
            header: 'ソース',
            binding: 'distance_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
        ]
      },
      {
        header: '燃費データ',
        align: 'center',
        columns: [
          {
            header: '燃費（km/l）', //
            binding: 'fuel_efficiency',
            minWidth: 125,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            inputType: 'float',
            dataType: 'Number',
          },
          {
            header: 'ソース',
            binding: 'fuel_efficiency_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
        ]
      },
      {
        header: '排出原単位',
        align: 'center',
        columns: [
          {
            header: '数値', // Đơn vị nguồn thải
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClass: 'auto-increment',
          },
          {
            header: '単位', // Đơn vị nguồn thải (unit)
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
          },
          {
            header: '出典', // Đơn vị nguồn thải (source)
            binding: 'wsu_source',
            minWidth: 350,
            maxWidth: 950,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            // dataMap: new wjGrid.DataMap(registerData.dbCustomizes, 'id', 'source'),
            // editor: new AutoComplete(document.createElement('div'), {
            //   itemsSource: registerData.dbCustomizes,
            //   selectedValuePath: 'id',
            //   displayMemberPath: 'source',
            //   maxItems: 1000,
            //   minLength: 1
            // }),
          },
        ],
      },
      {
        header: '排出量',
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 200,
        allowSorting: false,
        isRequired: false,
        inputType: 'float',
        dataType: 'Number',
      },
    ];
  },

  handleEvent(view, bindingLayout,theGrid = null) {
    let isHandlingCollectionChange = false;
    let isSuccessAdd = true;
    let isSuccessEditBlank = true;

    view.collectionChanged.addHandler(throttle(async () => {
      isReverted = true
      let isSyncing = new Date().getTime()
      if (isHandlingCollectionChange) {
        return
      }
      const indexEdit = view._idx + 1
      let indexAdd = indexEdit + view.itemsEdited.length
      if (view.itemsEdited.length <= 0) {
        indexAdd = view.itemCount - view.itemsAdded.length + 1
      }

      isHandlingCollectionChange = true
      setTimeout(() => {
        isHandlingCollectionChange = false
      }, 100);

      const addedReplacement = {
        id: 'id',
        duration_id: 'duration_id',
        month: 'month',
        year: 'year',
        emissions: 'emissions'
      }

      // handle update data
      let edited = {}
      let editedBlank = {}

      view.itemsEdited.forEach((itemEdited, index) => {

        if (itemEdited.isSyncing) {
          return
        }
        if (itemEdited.fuel === null) {
          itemEdited.wsu_value = null
          itemEdited.wsu_unit = null
          itemEdited.wsu_source = null
        }

        let newEnergy = {};
        if (itemEdited.fuel === 'ガソリン') {
          newEnergy = sources[0];
          itemEdited.wsu_value = newEnergy?.value_source || itemEdited.wsu_value;
          itemEdited.wsu_unit = newEnergy?.unit_source;
          itemEdited.wsu_source = newEnergy?.source;
        } else if (itemEdited.fuel === '軽油')  {
          newEnergy = sources[1];
          itemEdited.wsu_value = newEnergy?.value_source || itemEdited.wsu_value;
          itemEdited.wsu_unit = newEnergy?.unit_source;
          itemEdited.wsu_source = newEnergy?.source;
        } else {
          itemEdited.wsu_value = null;
          itemEdited.wsu_unit = null;
          itemEdited.wsu_source = null;
        }

        // update data for undo auto field cell

        itemEdited.fuel_efficiency = itemEdited.fuel_efficiency && formatValue(itemEdited.fuel_efficiency);
        itemEdited.distance = itemEdited.distance && formatValue(itemEdited.distance);
        itemEdited.emissions = calcEmissions(itemEdited.wsu_value, itemEdited.distance, itemEdited.fuel_efficiency);

        // auto set data for layout
        const layoutsData = {}
        bindingLayout.forEach(key => {
          layoutsData[key] = itemEdited[key] || null
        })
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemEdited.organizational_division,
          company_name: itemEdited.company_name,
          ...layoutsData
        });


        let itemCustom = {
          id: itemEdited.id,
          organizational_division: itemEdited?.organizational_division,
          company_name: itemEdited?.company_name,
          fuel: itemEdited.fuel?.trim(),
          target_model: itemEdited.target_model,
          distance: itemEdited.distance === 0 ? '0' : itemEdited.distance,
          fuel_efficiency: itemEdited.fuel_efficiency === 0 ? '0' : itemEdited.fuel_efficiency,
          wsu_source: itemEdited.wsu_source,
          wsu_unit: itemEdited.wsu_unit,
          wsu_value: itemEdited.wsu_value,
          emissions: itemEdited.emissions === null ? '0' : itemEdited.emissions,
          duration_id: store.state.registerData.duration, // from store
          month: store.state.registerData.month, // from store
          year: store.state.registerData.yearSelected,
          type: 0,
          index: indexEdit + index,
          memo_1: itemEdited.memo_1 || null,
          memo_2: itemEdited.memo_2 || null,
          fuel_efficiency_source: itemEdited.fuel_efficiency_source || null,
          distance_source : itemEdited.distance_source || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };

        // check before call api
        if (!isValidData(itemCustom)) {
          return;
        }
        delete itemEdited.db_index;

        if (itemCustom.id === BLANK_ID) {
          delete itemCustom.id
          editedBlank[index] = itemCustom
        }
        if(itemCustom.id && itemCustom.id !== BLANK_ID) {
          edited[index] = itemCustom
        }

        itemEdited.isSyncing = isSyncing
      });

      if (Object.values(edited).length || Object.values(editedBlank).length) {
        isSuccessEditBlank = false;
        isReverted = true

        try {
          const editedPromise = Object.values(edited).length ? updateListData(patternUrl, Object.values(edited)) : emptyPromise();
          let _ = await editedPromise
          const editedBlankPromise = Object.values(editedBlank).length ? addListData(patternUrl, Object.values(editedBlank)) : emptyPromise();
          let editedBlankRes = await editedBlankPromise
          isSuccessEditBlank = true;
          if (Object.keys(editedBlank).length) {
            let itemEdited = view.itemsEdited.filter((item) => item.isSyncing === isSyncing)
            batchReplacementViewCollection(itemEdited, editedBlankRes.record_insert, Object.keys(editedBlank), addedReplacement, true)
          }

          view.itemsEdited.forEach((item) => item.isSyncing = false)
          store.dispatch('commonApp/actionUpdateIsSuccess', true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch('commonApp/updateStatusAction', false, { root: true });
          }
          view.itemsEdited.length = 0
        } catch (error) {
          view.itemsEdited.forEach((item) => item.isSyncing = false)
          view.itemsEdited.length = 0
          isReverted = false
        }
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }

      let added = {};

      view.itemsAdded.forEach((itemAdded, index) => {

        if (itemAdded.fuel === 'ガソリン') {
          let newEnergy = sources[0];
          itemAdded.wsu_value = newEnergy.value_source || itemAdded.wsu_value;
          itemAdded.wsu_unit = newEnergy.unit_source;
          itemAdded.wsu_source = newEnergy.source;
        } else if (itemAdded.fuel === '軽油') {
          let newEnergy = sources[1];
          itemAdded.wsu_value = newEnergy.value_source || itemAdded.wsu_value;
          itemAdded.wsu_unit = newEnergy.unit_source;
          itemAdded.wsu_source = newEnergy.source;
        } else {
          itemAdded.wsu_value = null;
          itemAdded.wsu_unit = null;
          itemAdded.wsu_source = null;
        }

        itemAdded.fuel_efficiency = itemAdded.fuel_efficiency && formatValue(itemAdded.fuel_efficiency);
        itemAdded.distance = itemAdded.distance && formatValue(itemAdded.distance);
        itemAdded.emissions = calcEmissions(itemAdded.wsu_value, itemAdded.distance, itemAdded.fuel_efficiency);

        // auto set data for layout
        const layoutsData = {}
        bindingLayout.forEach(key => {
          layoutsData[key] = itemAdded[key] || null
        })
        const branchId = getBranchId(store.state.registerData.listBranch.filterPatternList, {
          organizational_division: itemAdded.organizational_division,
          company_name: itemAdded.company_name,
          ...layoutsData
        });


        let itemCustom = {
          organizational_division: itemAdded?.organizational_division,
          company_name: itemAdded?.company_name,
          fuel: itemAdded.fuel?.trim(),
          target_model: itemAdded.target_model,
          distance: itemAdded.distance === 0 ? '0' : itemAdded.distance,
          fuel_efficiency: itemAdded.fuel_efficiency === 0 ? '0' : itemAdded.fuel_efficiency,
          // unit: item.unit,
          wsu_source: itemAdded.wsu_source,
          wsu_unit: itemAdded.wsu_unit,
          wsu_value: itemAdded.wsu_value,
          duration_id: store.state.registerData.duration, // from store
          month: store.state.registerData.month, // from store
          emissions: itemAdded.emissions === null ? '0' : itemAdded.emissions,
          year: store.state.registerData.yearSelected,
          type: 0,
          index: indexAdd + index,
          memo_1: itemAdded.memo_1 || null,
          memo_2: itemAdded.memo_2 || null,
          fuel_efficiency_source: itemAdded.fuel_efficiency_source || null,
          distance_source : itemAdded.distance_source || null,
          ...layoutsData,
          branch_id: branchId?.id || null
        };

        // check before add to array
        if (!isValidData(itemCustom)) {
          return;
        }
        added[index] = itemCustom;
      });

      if (Object.values(added).length) {
        view.itemsAdded.length = 0
        isSuccessAdd = false;
        isReverted = true
        addListData(patternUrl, Object.values(added)).then(res => {
          batchReplacementViewCollection(view.itemsAdded, res.record_insert, Object.keys(added), addedReplacement)
          // clear items added
          // view.itemsAdded.length = 0
          isSuccessAdd = true;

          store.dispatch('commonApp/actionUpdateIsSuccess', true, { root: true });
          isReverted = false;
          if (!isSuccessAdd || !isSuccessEditBlank) {
            store.dispatch('commonApp/updateStatusAction', false, { root: true });
          }
        })
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
      }
      // handle delete data
      let removeData = [];
      view.itemsRemoved.forEach((item) => {
        if (item.id && item.id !== BLANK_ID) {
          removeData.push(item?.id);
        }
      });
      if (Object.values(removeData).length) {
        const ids = Object.values(removeData)
        theGrid.autoSizeColumns();
        theGrid.autoSizeRows(0, 0, true);
        deleteListData(`${patternUrl}?id[]=${ids.join('&id[]=')}`).then(() => {
          view.itemsRemoved.length = 0
        }).catch((_) => {
          view.itemsRemoved.length = 0
        })
      }

      // revert
      if (Array.isArray(view.itemsRevertDelete)) {
        let reverted = {}
        view.itemsRevertDelete.forEach((itemReverted, index) => {
          if (!isValidData(itemReverted)) {
            return
          }
          reverted[index] = itemReverted
        })

        if (Object.values(reverted).length) {
          view.itemsRevertDelete.length = 0
          isReverted = true
          updateListData(patternUrl, Object.values(reverted), true).then(() => {
            isReverted = false
          })
        }
      }
    }, 300));
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'fuel',
    'target_model',
    // 'unit',
    'wsu_unit',
    'wsu_source',
  ],

  getError(item, propName) {

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]), propName);
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if (max10Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
    }
    return null
  },
};

export default partern2;
