export const STATUS = [
  {
    id: 0, //draft
    name: 'التسجيل قيد التقدم',
    color: 'mono-black'
  },
  {
    id: 1, //submit
    name: 'إرسال',
    color: 'blue'
  },
  {
    id: 2, //return
    name: 'إرسال مرة أخرى',
    color: 'red'
  },
  {
    id: 3, //dai dien return
    name: '代理申請差戻',
    color: 'red'
  },
  {
    id: 4, //approved
    name: 'موافقة',
    color: 'light-green'
  },
  {
    id: 5, // đại diện approved
    name: 'تمت الموافقة على التمثيل',
    color: 'light-green'
  },
  {
    id: 6, // Is cancel submitted
    name: '申請取消中',
    color: 'blue'
  },
  // Van la submit nhung view cho approver/admin
  {
    id: 8, // Dang cho approval
    name: '承認待ち',
    color: 'red'
  },
  {
    id: 9, // template
    name: 'テンプレート',
    color: 'light-green'
  },
  // Dang cancel submit nhung view cho approver/admin
  {
    id: 10,
    name: '申請取消要請',
    color: 'blue'
  }

];
export const STATUS_DRAFT = 0;
export const STATUS_SUBMIT = 1;
export const STATUS_RETURN = 2;
export const STATUS_REPRENSENT_RETURN = 3;
export const STATUS_CANCEL_SUBMMITED = 6;
export const STATUS_SUBMIT_ADMIN = 7;
export const STATUS_SUBMIT_TO_APPROVAL = 8;
export const STATUS_FOR_TEMPLATE = 9;

export const STATUS_FIELD = {
  STATUS_DRAFT: 0,
  STATUS_SUBMIT: 1,
  STATUS_RETURN: 2,
  STATUS_REPRENSENT_RETURN: 3,
  STATUS_APPROVED: 4,
  STATUS_REPRENSENT_APPROVED: 5,
  STATUS_CANCEL_SUBMMITED: 6,
  STATUS_SUBMIT_NON_USER: 8,
  STATUS_CANCEL_SUBMMITED_NON_USER: 10,
  STATUS_TEMPLATE: null,
}

export const EMAIL_VALID = {
  INVALID_EMAIL: '確認する',
  CONFIRMING_EMAIL: '確認中',
  CONFIRMED_EMAIL: '確認済み'
}
