<template>
  <div>
    <div class="chart-yearly">
      <div class="yearly">
        <chart-yearly-2
          @openDialogStateDetail="openDialogStateDetail"
          @onUpdateStateDetail="onUpdateStateDetail"
        ></chart-yearly-2>
      </div>
      <div class="yearly-detail">
        <chart-detail-yearly :isShowContent="isShowChartDetailYearly"></chart-detail-yearly>
      </div>
    </div>
    <div class="dialog-state-detail">
      <chart-detail-yearly-dialog :dialog="isShowChartDetailDialog" @close="closeDialog"></chart-detail-yearly-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-yearly {
  .yearly-detail {
    display: none;
  }
}

.dialog-state-detail {
  display: none;
}

// desktop
@include desktop {
  .dialog-state-detail {
    display: block;
  }

  .chart-yearly {
    display: flex;

    .yearly {
      display: block;
      flex: 0 0 52.82%;
    }
    .yearly-detail {
      display: block;
      flex: 0 0 47.18%;
    }
    .yearly-detail {
      background: $monoWhite;
      border-radius: 8px;
      padding: 24px;
      padding-bottom: 15px;
    }
  }
}
</style>

<script>
import ChartYearly from './chart/index.vue';
import ChartYearly2 from './chart-2/index.vue';

import ChartDetailYearly from './detail-chart/index.vue';
import ChartDetailYearlyDialog from './detail-chart-dialog/index.vue';

export default {
  components: { ChartYearly, ChartDetailYearly, ChartYearly2, ChartDetailYearlyDialog },
  data() {
    return {
      isShowChartDetailDialog: false,
      windowWidth: window.outerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (!window) {
      return;
    }
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isShowChartDetailYearly() {
      if (this.windowWidth === 0) {
        return true;
      }
      return this.windowWidth >= 1024;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.outerWidth;
    },
    openDialogStateDetail($event) {
      this.isShowChartDetailDialog = true;
    },
    closeDialog() {
      this.isShowChartDetailDialog = false;
    },
    async onUpdateStateDetail($event) {
      if (!$event) {
        return;
      }

      await this.$store.dispatch('dashboard/changeSelectedEmissionsByPeriod', $event.original);
      await this.$store.dispatch('dashboard/requestEmissionsByPeriodDetail');
    },
  },
};
</script>
