<template>
  <div :class="classList">
    <div class="pl-16-px box" @click="toggleDropdown()" v-click-outside="handleClickOutSite"
         :style="'min-width: '+minWidth"
         :class="{'active': selectionShow}">
      <div class="box-title" v-html="label" />
      <div class="pull-down-adjust pulldown" :style="'width: ' + width">
        <div class="selection-box-bridge" ref="box">
          <div class="selection-box">
            <div v-if="showName(value)" class="input-box pull-down-text-color">
              <span class="helper-txt" v-if="suffixInOption && showSuffixInOption(value)">{{ suffixInOption }}</span>
              {{ showName(value) }}

            </div>
            <div v-else class="input-box pull-down-text-color">{{ placeHolderText }}</div>
            <div class="input-icon">
              <img class="img" src="@/assets/images/pulldown/pulldown-default.svg" />
              <img class="none-image" src="@/assets/images/pulldown/pulldown-default-hover.svg" />
              <img class="active-image" src="@/assets/images/pulldown/pull-down-default-active.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="selectionShow"
      class="selection-pulldown"
      :style="'width: ' + (boxWidth + 17) + 'px; border-radius: 8px; left: '+leftPosition+'px;'"
      id="style-scroll"
      :class="items.length > 4 ? '' : 'item-scroll-none'"
    >
      <div
        v-for="(item, index) in items"
        v-bind:key="index"
        :class="[compareItem(item.value) ? 'selected-background' : '']"
        class="item pull-down-text-color"
        @click="selectedItem(item.value)"
      >
        <span class="helper-txt" v-if="suffixInOption && item.is_selected">{{ suffixInOption }}</span>
        {{ item.name || "No Name" }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    minWidth         : {
      type   : String,
      default: "unset"
    },
    classList        : {
      type   : String,
      default: ""
    },
    label            : {
      type   : String,
      default: "&nbsp;"
    },
    items            : Array,
    width            : {
      type   : String,
      default: "150px"
    },
    model            : String,
    initialValue     : [Object, String, Number, null],
    placeHolderText  : {
      type   : String,
      default: "アイテムを選択"
    },
    isSelectFirst    : {
      type   : Boolean,
      default: false
    },
    suffixInOption   : {
      type   : String,
      default: ""
    },
    defaultIsSelected: {
      type   : Boolean,
      default: false
    },
    parentScrolled   : {
      type   : Boolean,
      default: false
    }
  },
  data() {
    return {
      selectionShow: false,
      value        : "",
      boxWidth     : 0,
      resizeTimer  : null,
      isSelected   : false,
      leftPosition : 0
    };
  },
  mounted() {
    this.isSelected = this.defaultIsSelected;
    this.boxWidth   = this.$refs.box.clientWidth;
    this.onWindowResize();
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  methods: {
    toggleDropdown() {
      this.selectionShow = !this.selectionShow;
      let parentLeft     = document.getElementById("headFix").getBoundingClientRect().left;
      this.leftPosition  = this.$refs.box.getBoundingClientRect().left - parentLeft - 17;
      this.boxWidth      = this.$refs.box.clientWidth;
    },
    handleClickOutSite() {
      this.selectionShow = false;
    },
    showName(value) {
      const returnObj = this.items.filter((obj) => {
        if (typeof value === "object") {
          if (JSON.stringify(obj.value).replaceAll("\"", "") == JSON.stringify(value).replaceAll("\"", "")) {
            return obj;
          }
        }
        if (obj.value == value) {
          return obj;
        }
      });
      return returnObj.length > 0 ? returnObj[0].name || "No Name" : "";
    },
    selectedItem(item) {
      if (this.suffixInOption) {
        const itemOriginal = this.items.find((i) => i.value === item);

        if (itemOriginal && Object.prototype.hasOwnProperty.call(itemOriginal, "is_selected")) {
          this.isSelected = itemOriginal.is_selected;
        } else {
          this.isSelected = false;
        }
      }
      this.value         = item;
      this.selectionShow = !this.selectionShow;
      this.$emit("input", item);
    },
    showSuffixInOption(value) {
      const result = this.items.filter((obj) => {
        if (value === obj.value) {
          return true;
        }
        return false;
      });

      if (result.length) {
        return result[0].is_selected;
      }
      return false;
    },
    compareItem(item) {
      if (typeof item === "object") {
        if (JSON.stringify(item).replaceAll("\"", "") == JSON.stringify(this.value).replaceAll("\"", "")) {
          return true;
        }
      }
      if (item == this.value) {
        return true;
      }
      return false;
    },
    closeSelection() {
      this.selectionShow = false;
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    }
  },
  watch  : {
    initialValue() {
      if (this.initialValue) {
        this.value = this.initialValue;
      }
    },
    items() {
      if (this.isSelectFirst && this.items.length) {
        this.value = this.items[0].value;
      }
    },
    defaultIsSelected() {
      if (this.defaultIsSelected) {
        this.isSelected = true;
      }
    },
    parentScrolled: {
      handler(parentScrolled) {
        if (parentScrolled) {
          this.selectionShow = false;
        }
      }
    }
  },
  destroyed() {
    this.offWindowResize();
  }
};
</script>

<style lang="scss" scoped>
.helper-txt {
  color: #df4949;
  margin-left: 5px;
}

.pl-16-px {
  padding-left: 16px;
}

.pull-down-adjust {
  .selection-box-bridge {
    .selection-box {
      .input-box {
        width: calc(100vw - 175px);
      }
    }
  }
}

#style-scroll::-webkit-scrollbar {
  width: 16px;
}

#style-scroll::-webkit-scrollbar-thumb {
  background-color: #bfd4d9;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.selected-background {
  background: #e3eded;
}

.item-scroll-none {
  overflow: hidden !important;
  .item {
    &.pull-down-text-color {
      padding-right: 34px;
    }
  }
}

.placeholder-color {
  color: $monoMid;
}

.pull-down-text-color {
  color: $monoBlack;
}

.selection-pulldown {
  // display: flex;
  position: absolute;
  max-height: 160px;
  background: #f7f7f2;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  margin-top: 2px;
  z-index: 99;
  overflow-y: scroll;
  overflow-x: hidden;

  .item {
    height: 34px;
    padding: 7px 16px 9px 0px;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    overflow: hidden;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: right;
  }

  .item:hover {
    background: #e3eded;
  }
}

.box:not(.active):hover {
  .pull-down-adjust {
    .selection-box-bridge {
      .selection-box {
        .input-icon {
          .img {
            display: none;
          }

          .none-image {
            display: block;
          }

          .active-image {
            display: none;
          }
        }
      }
    }
  }
}

.box {
  justify-content: flex-start;
  text-align: start;
  display: block;
  margin-bottom: 0;

  .pulldown {
    display: block;

    .selection-box-bridge {
      justify-content: space-between;
      padding-bottom: 5px;

      .selection-box {
        display: flex;
        width: 100%;
        margin-bottom: 0;
        padding: 0;
        height: 15px;
        margin-right: 10px;

        .input-box {
          width: 100%;
          font-size: 11px;
          line-height: 18px;
          align-items: center;
          display: flex;
          justify-content: flex-end;
        }

        .input-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 5px;
          margin-right: 15px;
          position: relative;
          top: -10px;
          right: -5px;

          .img {
            display: block;
          }

          .none-image {
            display: none;
          }

          .active-image {
            display: none;
          }
        }
      }
    }
  }

  .box-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    font-family: 'Source Han Sans';
    line-height: 18px;
    flex-grow: unset;
    width: 100%;
    padding-right: 35px;
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
  }

  &.active {
    background: #A4A184;

    .box-title, .pull-down-text-color {
      color: $monoWhite;
    }

    .input-icon {
      .img {
        display: none !important;
      }

      .none-image {
        display: none !important;
      }

      .active-image {
        display: block !important;
      }
    }
  }
}


.item-scroll-none {
  overflow: hidden !important;
}
</style>
