<template>
  <div id="btn-focus" :class="className" @click="handleBtnClick">
    <img id="default" :src="getIcon" alt="" />
    <img id="hover" :src="getIconHover" alt="" />
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconHover: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },

  computed: {
    getIcon() {
      return require(`@/assets/icons/${this.icon}`);
    },

    getIconHover() {
      return require(`@/assets/icons/${this.iconHover}`);
    },
  },

  methods: {
    handleBtnClick() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
$tablet: 1024px;

#btn-focus {
  cursor: pointer;
  position: fixed;
  z-index: 99;
  background: $monoOffWhite;
  border: 1px solid $goldMid;
  box-sizing: border-box;
  box-shadow: 0px 2.5px 4px rgba(56, 48, 46, 0.08), 0px 1px 8px rgba(56, 48, 46, 0.1),
    0px 5px 10px rgba(56, 48, 46, 0.15);
  border-radius: 8px;
  width: 70px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: $monoBlack;
  transition: all 0.3s;

  img {
    max-width: 28px;
    height: auto;
  }

  #hover {
    display: none;
  }

  &:hover {
    background: $goldMid;
    color: $monoWhite;

    #hover {
      display: block;
    }

    #default {
      display: none;
    }
  }

  @media (max-width: $tablet) {
    width: 50px;
    height: 50px;

    .text {
      display: none;
    }
  }
}
</style>
