<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    @click:outside="handleClickOutSite"
    :persistent="true"
    ref="dialog"
    content-class="modal-fullscreen width-100pc"
  >
    <div class="content-main">
      <div class="head-category-popup">
        <span class="log-confirm_text mr-auto">حدد الفئة</span>
        <div class="list-emission-close" @click="close">
          <img
            class="mr-0 close"
            src="@/assets/images/dialog/close.svg"
          />
          <img
            class="mr-0 close-active"
            src="@/assets/images/dialog/close_active.svg"
          />
          <div class="list-emission-close__text">يغلق</div>
        </div>
      </div>
      <div class="head-emission-category-border"></div>
      <div class="emissions-page">
        <div class="page-header">حدد الفئة التي ترغب في تسجيل بياناتك</div>
        <div class="summary">
          <div class="summary-area">
            <div class="summary-area__scope">
              <div class="summary-area__scope-left" :class="!isEnableScope3 && 'disabled'">
                <scope-component :scopes="leftScopeContainer" :header="header[0]" @selectedScope="selectedScope" />
              </div>
              <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt="" /></div>
              <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt="" /></div>
              <div class="summary-area__scope-middle">
                <scope-component :scopes="middleScope1Container" :isCategory="false" :header="header[1]"
                                 @selectedScope="selectedScope" />
                <scope-component :scopes="middleScope2Container" :isCategory="false" :header="header[1]"
                                 @selectedScope="selectedScope" />
              </div>
              <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt="" /></div>
              <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt="" /></div>
              <div class="summary-area__scope-right" :class="!isEnableScope3 && 'disabled'">
                <scope-component :scopes="rightScopeContainer" :header="header[2]" @selectedScope="selectedScope" />
              </div>
            </div>
            <div class="summary-area__scope">
              <scope-component :scopes="bottomScopeContainer" :header="header[3]" @selectedScope="selectedScope" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { getDurations } from "@/api/duration";
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import ScopeComponent from "@/components/emissionScope/Scope-Category.vue";
import webStorage from "@/utils/webStorage";

export default {
  components: { ScopeComponent },
  props     : {
    dialog       : {
      type   : Boolean,
      default: true
    },
    method       : {
      type   : Number,
      default: null
    },
    currentSelect: {
      type   : Number,
      default: null
    }
  },
  data() {
    return {
      items                : [
        {
          text    : "ホーム",
          disabled: true,
          href    : "/"
        }
      ],
      itemSelect           : null,
      header               : ["المنبع", "الشركة المبلغة", "المصب", "آحرون"],
      leftScopeContainer   : {},
      middleScope1Container: {},
      middleScope2Container: {},
      rightScopeContainer  : {},
      bottomScopeContainer : {},
      focusElement         : null,
      isEnableScope3       : false
    };
  },
  mounted() {
    this.fetchDurations();
  },
  watch  : {
    windowWidth: {
      handler() {
        this.windowWidth = window.innerWidth;
      }
    }
  },
  methods: {
    ...mapActions("emission", ["selectDuration", "setScopes"]),
    ...mapActions("registerData", ["updateDuration", "updateListDuration"]),
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    selectedScope(getScopesAndCategories, categoryName) {
      this.$emit("selectedScope", getScopesAndCategories, categoryName);
    },
    async fetchDurations() {
      await getDurations()
        .then((response) => {
          this.updateListDuration(response.data);
          const selectedDuration = response.data.find((item) => item.is_selected === true);
          const startAt          = moment(selectedDuration.start_at).format("YYYY年M月");
          const endAt            = moment(selectedDuration.end_at).format("YYYY年M月");
          this.duration          = `${startAt} - ${endAt}`;
          this.durationId        = selectedDuration.id;
          webStorage.set("duration", this.durationId);
          this.getScopeStatus();
        })
        .catch(() => {
        });
    },
    async getScopeStatus() {
      await getScopesAndCategories({ duration_id: this.durationId })
        .then((response) => {
          console.log(response);
          this.updateContainer(response);
          let isEnableScope3 = response.find((object) => {
            return object.hasOwnProperty('display_scope_3')
          })
          this.isEnableScope3 = isEnableScope3.display_scope_3
          this.$store.dispatch('dashboard/setIsEnableScope3', isEnableScope3.display_scope_3)

        })
        .catch(() => {
        });
    },
    updateContainer(scopes) {
      const leftScope3           = scopes[2].categories.filter((category) => category.id < 9);
      const rightScope3          = scopes[2].categories.filter((category) => category.id > 8 && category.id < 16);
      this.middleScope1Container = {
        scope     : "1",
        scope_icon: "scope1.svg",
        categories: [
          {
            id     : "1",
            name   : scopes[0].description,
            status : scopes[0].status,
            tooltip: this.tooltipContent()[0]
          }
        ]
      };
      this.middleScope2Container = {
        scope     : "2",
        scope_icon: "scope2.svg",
        categories: [
          {
            id     : "1",
            name   : scopes[1].description,
            status : scopes[1].status,
            tooltip: this.tooltipContent()[1]
          }
        ]
      };
      this.bottomScopeContainer  = {
        scope     : "4",
        scope_icon: "",
        categories: [
          {
            id     : scopes[2].categories[15].id,
            name   : scopes[2].categories[15].name,
            status : scopes[2].categories[15].status,
            tooltip: this.tooltipContent()[17]
          },
          {
            id     : "1",
            name   : scopes[3].categories[0].name,
            status : scopes[3].categories[0].status,
            tooltip: this.tooltipContent()[18]
          }
        ]
      };
      this.leftScopeContainer    = {
        scope     : "3",
        scope_icon: "scope3.svg",
        categories: this.formatScopeData(leftScope3),
        align     : "left"
      };
      this.rightScopeContainer   = {
        scope     : "3",
        scope_icon: "scope3.svg",
        categories: this.formatScopeData(rightScope3),
        align     : "right"
      };
    },
    formatScopeData(data) {
      let formatData = [];
      data.map((category) => {
        formatData.push({
          id     : category.id,
          name   : category.name,
          status : category.status,
          tooltip: this.tooltipContent()[category.id + 1]
        });
      });
      return formatData;
    },
    tooltipContent() {
      return [
        "<u><b>燃料の燃焼や工業プロセスなど</b></u>を通じた、事業者自らによる温室効果ガスの「直接排出」を登録します。",
        "<u><b>他社から供給された電気、熱・蒸気の使用</b></u>に伴う温室効果ガスの「間接排出」を登録します。",
        "<u><b>原材料・部品、容器・包装等が製造されるまでの活動</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>自社の資本財の建設・製造</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>調達している燃料の上流工程（採掘、精製等）、調達している電力等の上流工程（発電に使用する燃料の採掘、精製等）</b></u>に伴う温室効果ガスの排出を登録します。",
        "①報告対象年度に購入した製品・サービスの、<u><b>サプライヤーから自社への物流（輸送、荷役、保管）</b></u>に伴う排出、及び②報告対象年度に購入した<u><b>①以外の物流サービス（輸送、荷役、保管）（＝自社が費用負担している物流に伴う支出）</b></u>に伴う温室効果ガスの排出を登録します。",
        "自社で発生した<u><b>廃棄物の輸送、処理</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>従業員の出張</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>従業員が通勤する際の移動</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>自社が賃借しているリース資産の操業</b></u>に伴う温室効果ガスの排出を登録します。（ただしScope1,2で算定する場合を除く）",
        "自社が<u><b>販売した製品の最終消費者までの物流（輸送、荷役、保管、販売）</b></u>に伴う温室効果ガスの排出を登録します。（ただし、自社が費用負担していないものに限ります。）",
        "<u><b>事業者による中間製品の加工</b></u>に伴うに温室効果ガスの排出を登録します。",
        "<u><b>使用者（消費者・事業者）による製品の使用</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>使用者（消費者・事業者）による製品の廃棄時の処理</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>自社が賃貸事業者として所有し</b></u>、他者に賃貸しているリース資産の運用に伴う温室効果ガスの排出を登録します。（ただしScope1,2で算定する場合を除く）",
        "<u><b>フランチャイズ加盟者</b></u>における温室効果ガスの排出を登録します。",
        "<u><b>投資の運用</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>【任意】従業員や消費者の日常生活</b></u>に伴う温室効果ガスの排出を登録します。",
        "<u><b>非化石証書やJクレジット、グリーン電力証書</b></u>等の認証された排出削減貢献量を登録します。"
      ];
    },
    addTooltip(event) {
      this.focusElement = event.target.parentElement;
      this.focusElement.classList.add("tooltip-icon");
    },
    removeTooltip() {
      if (this.focusElement) {
        this.focusElement.classList.remove("tooltip-icon");
      }
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("question.svg");
      } else {
        event.target.src = this.getSettingIcon("question-active.svg");
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/images/emissions/status/${image}`);
      }
      return "";
    },
    close() {
      this.$emit("close");
      this.itemSelect = null;
    },
    submit() {
      this.$emit("submit", this.itemSelect);
      this.itemSelect = null;
    },
    handleClickOutSite() {
      this.$emit("close");
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.removeTooltip);
  },
  computed: {
    ...mapState("userData", ["currentUser"]),
  }
};
</script>

<style lang="scss" scoped>
.v-dialog__content--active  {
  .content-main {
    background-color: rgba(239, 245, 245, 1);
  }
}

.width-100pc {
  max-width: 100%;
  width: 100%;
}

.desktop img {
  width: 20px;
  height: 36px;
}

// .component-scope-4 {
//   display: flex;
//   align-items: center;
//   width: 100%;
// }

.log-confirm_text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: $goldMid;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.list-emission-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 66px;
  height: 62px;
  background: #F7F7F2;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  cursor: pointer;
  &__text {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $monoBlack;
   }
  .close-active {
    display: none;
  }
  .close {
    display: block;
  }
  &:hover {
    background: $goldLight;
    .close-active {
      display: block;
    }
    .close {
      display: none;
    }
    .list-emission-close__text  {
      color: $monoWhite;
    }
  }
  &:active {
    background: $monoOffWhite;
    border: 2px solid $blueMid;
    .close-active {
      display: none;
    }
    .close {
      display: block;
    }
    .list-emission-close__text  {
      color: $monoBlack;
    }
  }
}
.summary {
  margin-top: 30px;
}
</style>

<style lang="scss" scoped>
.content-main {
  .head-category-popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
  }
  .emissions-page {
    padding: 0 20px 80px;
  }
}
.head-emission-category-border {
  width: 100%;
  height: 1px;
  background: rgba(121, 134, 134, 0.12);
}

.emissions-page {

  .summary-area {
    margin-top: 48px;

    .summary-area__scope {
      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;

        &.desktop {
          display: none;
        }
      }

      .disabled {
        opacity: 0.3;
      }

      .component-scope-4 {
        display: flex;
        flex-flow: column;
      }
    }

    &.disabled {
      display: none;
    }
  }

  .page-header {
    margin-top: 48px;
    margin-bottom: 48px;
    color: $monoBlack;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
    display: flex;
  }
}

@media (max-width: 1131px) {
  .padding-left-layout {
    padding-left: 0px !important;
  }
  #inspire-mobile .content-main {
    padding: 0 !important;
    min-height: 100vh !important;
  }
}

@media (min-width: 1131px) {
  .emissions-page {
    padding-right: 40px;

    .summary-area {
      margin-top: 48px;

      .summary-area__scope {
        display: flex;

        .disabled {
          opacity: 0.3;
        }

        .arrow {
          padding: 0 16px;

          &.mobile {
            display: none;
          }

          &.desktop {
            display: flex;
          }
        }

        .summary-area__scope-left,
        .summary-area__scope-middle,
        .summary-area__scope-right {
          width: calc((100% - 106px) / 3);
        }

        .summary-area__scope-middle,
        .summary-area__scope-right {
          background-color: $bgLight;
        }
      }
    }
  }
}

@include desktop {
  .content-main {
    .head-category-popup {
      padding: 16px 40px;
    }
    .emissions-page {
      padding: 0 40px;
      .summary-area {
        .summary-area__scope {
          .component-scope-4 {
            flex-flow: row;
          }
        }
      }
    }
  }
}

.divider {
  margin-bottom: 40px !important;
}
</style>
