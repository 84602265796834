<template>
  <div class="duration-wrapper padding-top-layout">
    <div class="padding-left-40">
      <h1>登録期間設定</h1>
      <p>排出量についてデータ登録する年度と期間を設定してください。</p>
    </div>
    <v-form ref="form">
      <div class="duration-form" ref="form">
        <div class="duration-form__year">
          <p>登録年度</p>
          <div class="duration-selection__item">
            <v-select
              class="select-item"
              :menu-props="{ contentClass: 'selecting select-durations' }"
              solo
              dense
              placeholder="未選択"
              :items="policyYear"
              v-model="selectedYear"
              :rules="valueRule"
              @change="checkPreviousSetting"
            ></v-select>
          </div>
        </div>
        <div class="duration-form-detail">
          <p>期間</p>
          <div class="duration-selection">
            <div class="duration-selection__top">
              <v-select
                class="select-item"
                :menu-props="{ contentClass: 'selecting select-durations', maxHeight: 240 }"
                solo
                dense
                placeholder="未選択"
                :items="year"
                v-model="timeData.startYear"
                :rules="valueRule"
              ></v-select>
              <v-select
                class="select-item"
                :menu-props="{ contentClass: 'selecting select-durations', maxHeight: 240 }"
                solo
                dense
                placeholder="未選択"
                :items="month"
                v-model="timeData.startMonth"
                :rules="valueRule"
              ></v-select>
            </div>

            <div class="subtracted-divider">ー</div>
            <div class="duration-selection__bottom">
              <v-select
                class="select-item"
                :menu-props="{ contentClass: 'selecting select-durations', maxHeight: 240 }"
                solo
                dense
                placeholder="未選択"
                :items="endYears"
                v-model="timeData.endYear"
                :rules="valueRule"
              ></v-select>
              <v-select
                class="select-item"
                :menu-props="{ contentClass: 'selecting select-durations', maxHeight: 240 }"
                solo
                dense
                placeholder="未選択"
                :items="month"
                v-model="timeData.endMonth"
                :rules="valueRule"
              ></v-select>
            </div>
          </div>
        </div>
        <input class="submit-btn" type="button" value="登録期間を設定する" @click="validateForm" />
        <ul class="duration-note">
          <li>設定した登録期間以外の期間については編集ができなくなります。</li>
          <li>登録期間を変更しても、今まで入力された内容は消去されず引き続き閲覧可能です。</li>
        </ul>
      </div>
    </v-form>
    <question-popup
      :dialog="questionDialog"
      :message="popupMessage"
      @submit="onSubmit"
      :isShowCancelBtn="isShowCancelBtn"
      @close="handleClose"
      :confirm="true"
      :confirmText="confirmText"
    />
    <error-popup
      :dialog="errorDialog"
      :message="popupMessage"
      @submit="handleClose"
      :isShowCancelBtn="isShowCancelBtn"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { durationSettingApi, getDurations, updateDurationApi } from '@/api/duration';
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import QuestionPopup from '@/components/dialogs/question-popup.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';

export default {
  name: 'SettingCalcPeriod',
  components: { QuestionPopup, ErrorPopup },
  data() {
    return {
      breadcrumb: [
        {
          text: 'ホーム',
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: '設定',
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: '登録期間設定',
          disabled: false,
          href: ROUTES.CREATE_DATA_CUSTOMIZE,
        },
      ],
      endYears: [],
      confirmText: '設定する',
      setYearsArr: [],
      beginYear: 2017,
      durations: null,
      month: [],
      year: [],
      questionDialog: false,
      errorDialog: false,
      defaultMessage: '登録期間を設定します。\nよろしいですか？',
      popupMessage: '',
      valueRule: [(v) => !!v || ''],
      valid: true,
      numberPattern: /\d+/g,
      selectedYear: '2017年度',
      isShowCancelBtn: true,
      isValidated: false,
      errorMessages: {
        isNotWithin12Months: '期間は12ヶ月以内で設定してください。',
        endMonthPriorToStartMonth: '終了月が開始月より過去の月になっています。',
        overlap: '期間は、過去登録された期間と重複がないよう設定ください。',
      },
      defaultDuration: {
        year: '',
        start_at: '',
        end_at: '',
      },
      setDurations: null,
      timeData: {
        startYear: '',
        endYear: '',
        startMonth: '',
        endMonth: '',
        policyYear: '',
      },
    };
  },

  mounted() {
    this.updateBreadCrumb(this.breadcrumb);
    // Get durations which has been set from db
    this.getDurations();
    this.monthArr(), this.yearArr();
  },

  methods: {
    ...mapActions('registerData', ['updateDuration', 'updateDurationValue', 'resetDuration',"actionUpdateIsChangedDurationSuccess","actionCheckIvalueDurationSuccess"]),
    ...mapActions('commonApp', ['updateBreadCrumb']),

    handleClose() {
      this.errorDialog = false;
      this.questionDialog = false;
    },

   async onSubmit() {
      if (this.isValidated) {
        let NewData = {
          year: this.selectedYear.slice(0, -2),
          start_at: [
            this.timeData.startYear.slice(0, -1),
            this.formatMonth(this.timeData.startMonth.slice(0, -1)),
          ].join('-'),
          end_at: [this.timeData.endYear.slice(0, -1), this.formatMonth(this.timeData.endMonth.slice(0, -1))].join('-'),
          contractor_id: this.contractor,
        };
        if (this.setYearsArr.includes(Number(this.selectedYear.slice(0, -2)))) {
          // Update duration
          let yearIndex = this.getIndexOfYear();
          if (yearIndex) {
            let updateData = { ...NewData, id: yearIndex };
            await updateDurationApi(updateData).then(() => {
              this.getDurations();
            });
              
            this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithYearly', updateData.id);
            this.$store.dispatch('dashboard/changeSelectedEmissionsByPeriod', {
              duration_id: updateData.id,
              start_at: updateData.start_at,
              end_at: updateData.end_at,
              year: updateData.year,
            });
          }
        } else {
        await  durationSettingApi(NewData)
            .then((res) => {
              this.updateDuration(res.data.id);
              this.updateDurationValue({
                year: res.data.year,
                start_at: res.data.start_at,
                end_at: res.data.end_at,
                id: res.data.id,
              });
              this.getDurations();
              if(!this.checkIvalueDurationSuccess){
                return this.$router.push({ path: ROUTES.SETTING});
              } 
              this.$store.dispatch('dashboard/changeKeepSelectedDurationByCategoryWithYearly', res.data.id);
              this.$store.dispatch('dashboard/changeSelectedEmissionsByPeriod', {
                duration_id: res.data.id,
                start_at: res.data.start_at,
                end_at: res.data.end_at,
                year: res.data.year,
              });
            })
            .catch(() => {
              this.questionDialog = false;
            });
        }
      }
      if(this.checkIvalueDurationSuccess){
        this.actionCheckIvalueDurationSuccess(false)
        this.actionUpdateIsChangedDurationSuccess(true)
        return this.$router.push({ path: ROUTES.EMISSIONS });
      }else{
        this.closePopup();
      }
    },

    getIndexOfYear() {
      let indexOfSelectedYear = null;
      this.durations.forEach((element) => {
        if (element.year == this.selectedYear.slice(0, -2)) {
          indexOfSelectedYear = element.id;
        }
      });
      return indexOfSelectedYear;
    },

    monthArr() {
      for (let idx = 1; idx < 13; idx++) {
        this.month.push(idx + '月');
      }
    },

    yearArr() {
      let currentYear = new Date().getFullYear();
      for (let idx = this.beginYear; idx <= currentYear; idx++) {
        this.year.push(idx + '年');
      }
      this.endYears = [...this.year];
      this.endYears.push(currentYear + 1 + '年');
    },

    formatMonth(month) {
      return ('0' + month).slice(-2);
    },

    calcTotalMonth(timeData) {
      let startTime = new Date(timeData.startYear, timeData.startMonth);
      let endTime = new Date(timeData.endYear, timeData.endMonth);
      // Diff method meaning calculating month between two times
      // Should be add 1 to adapt into our requirement
      return moment(endTime).diff(startTime, 'months', true) + 1;
    },

    handleValidatedErrorPopup(errorMessage) {
      this.isShowCancelBtn = false;
      this.isValidated = false;
      this.popupMessage = errorMessage;
      this.errorDialog = true;
    },

    validateForm() {
      if (this.$refs.form.validate() && !this.questionDialog) {
        let inputTime = { ...this.timeData };
        // Update value in the input time
        for (const field in inputTime) {
          inputTime[field] = Number(inputTime[field].match(this.numberPattern)[0]);
        }
        if (!this.validateOverlap(inputTime)) {
          this.validateMonth(inputTime);
        } else {
          this.handleValidatedErrorPopup(this.errorMessages.overlap);
        }
      }
    },
    validateMonth(timeData) {
      let totalMonth = this.calcTotalMonth(timeData);
      if (totalMonth > 12) {
        this.handleValidatedErrorPopup(this.errorMessages.isNotWithin12Months);
      } else if (
        ((timeData.startYear == timeData.endYear) & (timeData.startMonth > timeData.endMonth)) |
        (timeData.startYear > timeData.endYear)
      ) {
        this.handleValidatedErrorPopup(this.errorMessages.endMonthPriorToStartMonth);
      } else {
        this.questionDialog = true;
        this.isValidated = true;
        this.popupMessage = this.defaultMessage;
      }
    },
    validateOverlap(inputTime) {
      let isOverlap = false;
      let start_check = moment(String(inputTime.startYear) + '-' + String(inputTime.startMonth), 'yyyy-mm');
      let end_check = moment(String(inputTime.endYear) + '-' + String(inputTime.endMonth), 'yyyy-mm');
      // check if null
      if (this.setDurations === null) {
        return false;
      }
      this.setDurations.forEach((item) => {
        if (this.selectedYear.slice(0, 4) != item?.year) {
          if (start_check.isSameOrBefore(item.end_at) && end_check.isSameOrAfter(item.start_at)) {
            isOverlap = true;
          }
        }
      });
      return isOverlap;
    },
    checkPreviousSetting(year) {
      let selectedYear = Number(year.slice(0, -2));
      if (this.setYearsArr.includes(selectedYear)) {
        let duration = null;
        this.durations.map((item) => {
          if (item.year == selectedYear) {
            duration = item;
          }
        });
        this.updatePullDown(duration);
      } else {
        this.updatePullDown(this.defaultDuration);
      }
    },
    getDurations() {
      getDurations({ contractor_id: this.contractor }).then((res) => {
        if (!res.data.length) {
          this.updatePullDown(this.defaultDuration);
          this.selectedYear = this.beginYear + '年度';
        } else {
          this.durations = res.data;
          this.setYearsArr = [...new Set(res.data.map((item) => item.year))];
          let selectedDuration = res.data.filter((item) => item.is_selected == true);
          this.timeData = {
            startYear: selectedDuration[0].start_at.split('-')[0] + '年',
            endYear: selectedDuration[0].end_at.split('-')[0] + '年',
            startMonth: +selectedDuration[0].start_at.split('-')[1] + '月',
            endMonth: +selectedDuration[0].end_at.split('-')[1] + '月',
          };
          this.setDurations = res.data.map((item) => {
            return {
              year: item.year,
              start_at: moment(item.start_at, 'yyyy-mm'),
              end_at: moment(item.end_at, 'yyyy-mm'),
            };
          });
          if (selectedDuration) {
            // Update duration time according to the response
            this.updatePullDown(selectedDuration[0]);
            this.selectedYear = selectedDuration[0].year + '年度';
          }
        }
      });
    },
    getDurationId() {
      let duration = this.durations.map((item) => (item.year = Number(this.selectedYear.slice(0, -1))));
      if (duration[0] && duration[0].id) {
        return duration[0].id;
      }
      return;
    },
    updatePullDown(data) {
      this.timeData = {
        startYear: data.start_at.split('-')[0] + '年',
        endYear: data.end_at.split('-')[0] + '年',
        startMonth: +data.start_at.split('-')[1] + '月',
        endMonth: +data.end_at.split('-')[1] + '月',
      };
      // Add into store
      this.updateDurationValue({
        year: data.year,
        start_at: data.start_at,
        end_at: data.end_at,
      });
    },
    closePopup() {
      this.questionDialog = false;
      this.popupMessage = this.defaultMessage;
      this.isShowCancelBtn = true;
    },
  },
  computed: {
    ...mapState('userData', ['contractor']),
    ...mapState('registerData', ['durationValue',"checkIvalueDurationSuccess"]),

    policyYear() {
      return this.year.map((item) => item + '度');
    },
  },
};
</script>
<style lang="scss">
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  &.fullmode{
    margin-top : 34px !important;
    
  }
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.duration-wrapper {
  h1 {
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    margin: 40px 0px 48px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;

    font-family: 'Source Han Sans';
    font-style: normal;
    color: $monoBlack;
    margin-bottom: 80px;
  }
  .duration-form {
    p {
      width: 335px;
      height: 24px;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;
    }
    .duration-form__year {
      margin-bottom: 48px;
    }
    .duration-form-detail {
      .duration-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .duration-selection__top {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
        .subtracted-divider {
          height: 28px;
          width: 15px;
          margin: 9px 0px;
          align-items: center;
          transform: rotate(90deg);
        }
        .duration-selection__bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
      }
    }
    .submit-btn {
      margin: 48px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 2px 20px;
      background: $goldMid;
      width: 100%;
      height: 56px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      color: $monoWhite;
      &:hover {
        /* Gold/Light */

        background: $goldLight;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
          0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }
      &:focus-within {
        border: 2px solid $blueMid;
        padding: 14px 0px 18px;
        color: $monoWhite;
      }
    }
    .duration-note {
      position: static;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $monoDark;
      margin-top: 48px;
      li {
        &::marker {
          font-size: 9px !important;
        }
      }
    }
  }
}
@include desktop {
  .duration-wrapper {
    h1 {
      margin: 0 0 48px;
    }
    form {
      width: 600px;
      margin: 0 auto;
    }
    .duration-selection {
      height: 40px;
      width: 100%;
      margin: unset !important;
      display: flex;
      flex-direction: row !important;
      .duration-selection__top,
      .duration-selection__bottom {
        > div {
          width: 134.25px;
        }
      }
      .duration-selection__top {
        flex-direction: column;
      }
      .subtracted-divider {
        margin: 16px !important;
        flex-direction: column;
        transform: rotate(0) !important;
        align-items: center;
      }
      .duration-selection__bottom {
        flex-direction: column;
      }
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .submit-btn:focus {
    background-color: #948810 !important;
    opacity: 1;
  }
}
</style>
