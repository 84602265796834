<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">تسجيل الانبعاثات</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html="'データ登録の状況を確認できます。詳細ボタンをクリックすると、データ登録の内容が確認できます。'"
              ></div>
            </div>
          </div>
        </div>
        <div class="wrap__status">
          <div class="wrap__status__item register-btn">
            <common-button class="register-button" v-ripple="false" @action="showRegisterPulldownEvent()" :disabled="!isRedirectDuration">
              <span>يسجل</span>
              <img src="@/assets/icons/addregistericon.svg" alt="" class="addregistericon" />
              <img src="@/assets/icons/addregistericon_active.svg" alt="" class="addregistericon_active" />
            </common-button>
            <div v-if="showRegisterPulldown" class="register-pulldown">
              <div class="pulldown-item" :class="(disabledTemplateButton && i === 1) ? 'disabled' : ''" v-for="(item, i) in listPullDown" :key="i" @click="redirectHandler(item.text)">
                <div class="pulldown-text">{{ item.text }}</div>
                <img v-if="disabledTemplateButton && i === 1" :src="getSettingIcon('csv-input/template-disable.svg')" alt="" class="pulldown-icon" />
                <img v-else :src="getSettingIcon(item.icon)" alt="" class="pulldown-icon" />
              </div>
            </div> 
          </div>
          <!-- <div class="wrap__status__item">
            <common-button v-ripple="false" @action="redirectToTemplate()" class="template-register-button" :class="disabledTemplateButton ? 'disabled' : ''">
              <img src="@/assets/icons/addregistericon.svg" alt="" class="addregistericon" />
              <img src="@/assets/icons/addregistericon_active.svg" alt="" class="addregistericon_active" />
              <img src="@/assets/icons/addregistericon_disabled.svg" alt="" class="addregistericon_disabled" />
              <span>テンプレートから登録</span>
            </common-button>
          </div> -->
          <div class="duration-wrapper">
            <div class="duration">
              <div class="calculation-page">
                <common-button v-ripple="false" v-if="isAdmin" label="يتغير" @action="redirectToCalculationTerm()" />
                <div>
                  <div class="duration-content">مصطلح التسجيل الحالي</div>
                  <div class="duration-text">{{ duration }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          @changeFullScreen="onChangeFullScreen"
        />
      </div>
      <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
      <history-popup :dialog="historyPopup" :historyLogList="historyLogList" @close="closePopup" />
    </div>
    <div class="return__button-responsive">
      <div class="item-responsive register-btn">
        <common-button v-ripple="false" @action="showRegisterPulldownEvent()">
          <img src="@/assets/icons/addregistericon.svg" class="addregistericon" />
          <img src="@/assets/icons/addregistericon_active.svg" alt="" class="addregistericon_active" />
          <span class="common-btn-text">يسجل</span>
        </common-button>
        <div v-if="showRegisterPulldown" class="register-pulldown">
          <!-- <span v-for="item in registerPulldown" :key="item" class="item-pulldown" @click="redirectHandler(item)">{{ item }}</span> -->
          <div class="pulldown-item" :class="(disabledTemplateButton && i === 1) ? 'disabled' : ''" v-for="(item, i) in listPullDownSp" :key="i"  @click="redirectHandler(item.text)">
            <img v-if="disabledTemplateButton && i === 1" :src="getSettingIcon('csv-input/template-disable.svg')" alt="" class="pulldown-icon" />
            <img v-else :src="getSettingIcon(item.icon)" alt="" class="pulldown-icon" />
            <div class="pulldown-text">{{ item.text }}</div>
          </div>
        </div> 
      </div>
      <!-- <div class="item-responsive">
        <common-button v-ripple="false" @action="redirectToTemplate()" class="template-register-button" :class="disabledTemplateButton ? 'disabled' : ''">
          <img src="@/assets/icons/addregistericon.svg" alt="" class="addregistericon" />
          <img src="@/assets/icons/addregistericon_active.svg" alt="" class="addregistericon_active" />
          <img src="@/assets/icons/addregistericon_disabled.svg" alt="" class="addregistericon_disabled" />
          <span class="common-btn-text">テンプレートから登録</span>
        </common-button>
      </div> -->
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="dialogNotification = false" />
    <notification-popup :dialog="showPopupDurationSuccess" :message="message" @submit="showPopupDurationSuccess = false"/>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CollectionView } from "@grapecity/wijmo";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@grapecity/wijmo.grid";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import CommonButton from "@/components/utils/button.vue";
import HistoryPopup from "@/components/dialogs/history-popup.vue";
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getListWorkflowApi, getListLogHistory, getListCommentHistory } from "@/api/registerData";
import { getDurations } from "@/api/duration";
import { getStatusNameById, getColorStatusById } from "@/utils/status";
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import moment from "moment";
import { getDataLayer, getScopeName, getCategoryName } from "@/utils/registerData";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
export default {
  name: "ListTemplates",
  data() {
    return {
      items           : [
        {
          text    : "الصفحة الرئيسية",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "تسجيل الانبعاثات",
          disabled: false,
          href    : ROUTES
        }
      ],
      message:"登録期間の設定を変更しました。",
      duration        : "",
      flex            : null,
      listEmissionData: [],
      listData        : null,
      durationId      : null,
      undoStack       : null,
      canUndo         : false,
      canRedo         : false,
      commentPopup    : false,
      historyPopup    : false,
      gridColumns     : [],
      showTooltip     : false,
      historyLogList     : [],
      commentHistoryList: [],
      dialogNotification: false,
      messageNotification: 'يلاحظ:قبل تسجيل البيانات ، يرجى تحديد "فترة التسجيل" أولاً من شاشة "الإعداد".',
      isRedirectDuration: false,
      disabledTemplateButton: false,
      showPopupDurationSuccess: false,
      showRegisterPulldown: false,
    };
  },
  components: {
    DataTable,
    CommonButton,
    CommentLogPopup,
    HistoryPopup,
    NotificationPopup
  },
  async mounted() {
    await this.updateBreadCrumb(this.items);
    await Promise.all([this.getTemplateData(), this.getListDuration(), this.getTemplateList()]);
    this.showPopupDurationSuccess = this.isChangedDurationSuccess;
    this.actionUpdateIsChangedDurationSuccess(false)
    window.addEventListener('click', this.handleClickWindown)
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen",'isChangedDurationSuccess']),
    ...mapState("userData", ["currentUser"]),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2)
    }
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration", "updateListDuration","actionUpdateIsChangedDurationSuccess","actionCheckIvalueDurationSuccess"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),
    handleClickWindown() {
      if(this.showRegisterPulldown) {
        this.showRegisterPulldown = false
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    redirectToCalculationTerm() {
      this.actionCheckIvalueDurationSuccess(true);
      return this.$router.push({ path: ROUTES.CALCULATION_TERM });
    },
    redirectToTemplate() {
      return this.$router.push({ path: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup    = true;
      this.dialogCancelBtn = true;
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId  = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.historyPopup    = true;
      this.dialogCancelBtn = true;
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      }
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
          return {
            ...listHistoryChanged,
            date: formatDateTimeDataTable(listHistoryChanged.updated_at),
            name: listHistoryChanged.content,
            status: getStatusNameById(listHistoryChanged.status),
            color: getColorStatusById(listHistoryChanged.status)
          }
        })
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      }
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
          return {
            ...commentHistory,
            date: formatDateTimeDataTable(commentHistory.created_at),
            name: commentHistory.user.name,
            content: commentHistory.content,
          }
        })
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    async getTemplateData() {
      const payload = {
        action: "listAll"
      };
      getListWorkflowApi(payload).then((res) => {
      res.data.forEach((item) => {
        this.listEmissionData.push({
          id               : item.id,
          data_id      : item.id,
          status           : item.status === null ? "" :getStatusNameById(item.status),
          title            : item.title,
          comment          : "",
          company_name     : item.contractor?.name,
          scope            :getScopeName(parseInt(item.scope)),
          category         :getCategoryName(parseInt(item.scope), parseInt(item.category)),
          month_year_registration: `${item.year}/${String(item.month).padStart(2, '0')}`,
          last_modified    : formatDateTimeDataTable(item.updated_at),
          iconComment      : item.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
          date_submit      : formatDateTimeDataTable(item.submitted_at),
          date_approval    : formatDateTimeDataTable(item.approved_at),
          layer1           : getDataLayer(item.scope, item.category, item.pattern_id)?.method,
          layer2           : getDataLayer(item.scope, item.category, item.pattern_id)?.sub_method,
          log_history      : "سجل عرض",
          approved_by: item.approved_by,
          duration_id: item.duration_id,
          scope_id: parseInt(item.scope),
          category_id: parseInt(item.category),
          month: item.month,
          pattern_id: item.pattern_id,
        });
      });
      this.defineTable();
      });
    },
    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView(this.listEmissionData);
      this.flex.columnGroups = this.getGridColumns();
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    getGridColumns() {
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : " ",
          binding     : "data_id",
          minWidth    : 46,
          maxWidth    : 46,
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db table-id hide-filter",
          cellTemplate: CellMaker.makeButton({
            text : "تفاصيل",
            click: (event, context) => this.onIdClicking(context)
          })
        },
        {
          header      : "حالة",
          binding     : "status",
          minWidth    : 55,
          maxWidth    : 120,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
          align: 'right',
          cssClassAll: 'btn-db right-to-left',
          cellTemplate: "<span class=\"${(item.status === \"تمت الموافقة على التمثيل\" || item.status === \"موافقة\") ? \"history-btn mono-text\" : (item.status === \"申請取消中\" || item.status === \"إرسال\" || item.status === \"申請取消要請\") ? \"history-btn blue-text\" : (item.status === \"إرسال مرة أخرى\" || item.status === \"代理申請差戻\") ? \"history-btn red-text\" : \"normal\"}\">${item.status}</span>"
        },
        {
          header      : " ",
          binding     : "iconComment",
          minWidth    : 32,
          maxWidth    : 32,
          allowSorting: false,
          isRequired  : false,
          cssClass    : "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          align: 'right',
          cssClassAll : "right-to-left height34 cell-comment hide-filter comment-icon"
        },
        {
          header      : "عنوان",
          binding     : "title",
          minWidth    : 196,
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left"
        },
        {
          header      : "اسم الشركة",
          binding     : "company_name",
          minWidth    : 92,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "نطاق",
          binding     : "scope",
          minWidth    : 92,
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "فئة",
          binding     : "category",
          minWidth    : 92,
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header : "طرق الحساب",
          align  : "center",
          columns: [
            {
              header      : "خطوة أولى",
              binding     : "layer1",
              minWidth    : 74,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              align: 'right',
              cssClassAll : "btn-db right-to-left",
              wordWrap    : true,
            },
            {
              header      : "خطوة ثانية",
              binding     : "layer2",
              minWidth    : 74,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              align: 'right',
              cssClassAll : "btn-db right-to-left",
              wordWrap    : true,
            }
          ]
        },
        {
          header      : "تاريخ التسجيل",
          binding     : "month_year_registration",
          minWidth    : 92,
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "اخر تحديث",
          binding     : "last_modified",
          minWidth    : 148,
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "تاريخ التقديم",
          binding     : "date_submit",
          minWidth    : 148,
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "btn-db right-to-left",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "تاريخ الموافقة",
          binding     : "date_approval",
          minWidth    : 148,
          maxWidth    : 200,
          allowSorting: false,
          isReadOnly  : true,
          align: 'right',
          cssClassAll : "right-to-left",
          cssClass    : "btn-db",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : "مراجعة التاريخ",
          binding     : "log_history",
          minWidth    : 72,
          maxWidth    : 100,
          allowSorting: false,
          cssClass    : "btn-db",
          align: 'right',
          cssClassAll : "cell_comment-confirm hide-filter right-to-left",
          multiLine   : true,
          wordWrap    : true,
          cellTemplate: CellMaker.makeButton({
            text : "${text? text : \"選択\" }",
            click: (e, ctx) => this.onHistoryClicking(ctx)
          })
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc                       = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    async getListDuration() {
      await getDurations({ contractor_id: this.$route.query.duration }).then((res) => {
        this.isRedirectDuration = true
        const selectedDuration = res.data.find((duration) => duration.is_selected === true);
        this.duration          = `${moment.utc(selectedDuration.start_at).format("YYYY/M")} - ${moment.utc(selectedDuration.end_at).format("YYYY/M")}` || '';
      });
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      // return
      // await Promise.all([
      //   this.updateDataScope(rowData.item.scope_id),
      //   this.updateDataCategory(rowData.item.category_id),
      //   this.updateDataMonth(rowData.item.month),
      //   this.updateIsApproved(rowData.item.approved_by === null ? false : true),
      //   this.updateDuration(rowData.item.duration_id),
      // ]);
      await updateDataBeforeRedirect(rowData.item);
      this.$router.push({ path: `/emissions/register/list-menu/${rowData.item.id}` });
    },
    async getTemplateList() {
      const payload          = {
        action       : "listTemplate",
        contractor_id: this.currentUser.user.contractor_id
      };
      const templateResponse = await getListWorkflowApi(payload);
      this.disabledTemplateButton = templateResponse.data.length > 0 ? false : true;
    },
    showRegisterPulldownEvent() {
      if (this.duration === '') {
        this.dialogNotification = true;
        return;
      }
      this.showRegisterPulldown = !this.showRegisterPulldown;
    },
    redirectHandler(item) {
      this.showRegisterPulldown = false;
      console.log(item);
      switch (item) {
        case REGISTER_PULLDOWN.CREATE_NEW:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_MENU_EMISSION }).catch(() => {});
          break;
        case REGISTER_PULLDOWN.CREATE_FROM_TEMPLATE:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {});
          break;
        default:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_MENU_EMISSION + '/?csv=true' }).catch(() => {});
          break;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickWindown)
  }
};
</script>
<style scoped lang="scss">
@import './styles/index.scss';
.template-detail-header {
  .wj-btn {
    display: none;
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $monoBlack;
  font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-left: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
        text-align: end;
      }
    }
  }
}

.template-detail-header {
  .wj-cell-maker {
    margin-top: 2px;
    width: 26px;
    height: 20px;
    font-style: normal;
    font-family: 'Source Han Sans JP';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: $goldMid;
    text-align: center;
    background: none;
    border: 1px solid $goldLight;
  }

  .wj-cell-maker:hover {
    color: $monoWhite;
    background: #0072a3;
  }
}
.hide-filter {
  .wj-btn {
    display: none;
  }
}
.custom-table {
  padding-left: 20px !important;
}
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: none;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .duration-wrapper {
      width: 100%;

      .duration {
        .calculation-page {
          justify-content: space-between;
        }
      }
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 0 52px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      padding-bottom: 58px;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      span {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }

    .wrap__status__item {
      span {
        margin-left: 10px;
      }
    }
  }
}
.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
    .item-responsive {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 16px;
      .common-btn {
        width: 100%;
        .common-btn-text {
          margin-left: 10px;
        }
        .addregistericon_active {
          display: none;
        }
        .addregistericon_disabled {
          display: none;
        }
        // &:hover {
        //   .v-btn__content {
        //     .addregistericon {
        //       display: none !important;
        //     }
        //     .addregistericon_active {
        //       display: block !important;
        //     }
        //     .addregistericon_disabled {
        //       display: none;
        //     }
        //   } 
        // }
        // &:active {
        //   .addregistericon {
        //     display: block;
        //   }
        //   .addregistericon_active {
        //     display: none;
        //   }
        //   .addregistericon_disabled {
        //     display: none;
        //   }
        // }
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none !important;
            }
            .addregistericon_active {
              display: block !important;
            }
            .addregistericon_disabled {
              display: none;
            }
          }    
        }
      }
    }
  }
}

.register-btn {
  position: relative;
  .register-pulldown {
    position: absolute;
    width: 238px;
    max-height: 160px;
    background: $monoOffWhite;
    margin-top: 2px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    z-index: 99;
    right: 0;
    .item-pulldown {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      color: $monoBlack;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Source Han Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-left: unset !important;
      &:hover {
        background: $bgLight;
      }
    }
    .pulldown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      width: 238px;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      justify-content: flex-end;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background: $bgLight;
      }
      .pulldown-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: $monoLight !important;
        .pulldown-text {
          color: $monoMid;
          font-weight: 500;
        }
      }
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }

    &__status {
      padding-right: 40px;

      &__item {
        display: block;
      }
    }

    &__block {
      flex-direction: row;

      &__header {
        padding-bottom: 0;
      }
    }

    .wrap__block__header__content {
      display: flex;

      span {
        margin: 8px 10px 0px 0px;
      }

      // .helper-icon {
      //   margin: 16px 10px 0px 0px;
      // }
    }
    .wrap__status__item {
      .addregistericon_active {
        display: none;
      }
      .addregistericon_disabled {
        display: none;
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none;
            }
            .addregistericon_active {
              display: block;
            }
            .addregistericon_disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}

.return__listSubmitted {
  margin-right: 40px !important;

  .return__header {
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 161px;
    height: 40px;

    &:hover {
      background: $goldLight;

      svg path {
        stroke: #ffffff;
      }

      .return__text {
        color: $monoOffWhite;
      }
    }

    .action {
      cursor: pointer;
    }

    .return__text {
      font-family: 'Source Han Sans JP';
      color: #404d50;
      font-size: 14px;
      letter-spacing: 0.03em;
      font-style: normal;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
.template-register-button.disabled {
  pointer-events: none;
  cursor: default;
  background: $monoLight !important;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24) !important;

  .v-btn__content {
    span {
      color: $monoMid !important;
    }
    .addregistericon {
      display: none !important;
    }
    .addregistericon_active {
      display: none;
    }
    .addregistericon_disabled {
      display: block;
    }
  }
}
@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .return__button-responsive {
    display: none;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}

//custome style
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .wrap {
    &__block {
      flex-direction: column;

      &__header {
        padding-bottom: 58px;
      }
    }

    &__status {
      padding-right: 0;
    }
  }
}
</style>
