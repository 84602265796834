import axios from 'axios';
import request from './base/request';

let requests = {};

export const getEmissionsByPeriod = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  if (query.hasOwnProperty('branch_id')) {
    const { branch_id } = query
    query.business_name = branch_id
    delete query.branch_id
  }
  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionsByPeriod) {
    requests.getEmissionsByPeriod.cancel('Cancel getEmissionsByPeriod');
    requests.getEmissionsByPeriod = null;
  }

  requests = {
    ...requests,
    getEmissionsByPeriod: cancelTokenSource,
  };

  // if (query.type === 1) {
  //   return dashboardMonthlyFaker();
  // }

  // if (query.type === 0) {
  //   return dashboardFaker()
  // }

  return request
    .get('dashboard', config)
    .catch((errors) => {
      // console.warn(errors);
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionsByPeriod: null,
      };
    });
};

export const getEmissionByPeriodDetail = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionByPeriodDetail) {
    requests.getEmissionByPeriodDetail.cancel('Cancel getEmissionByPeriodDetail');
    requests.getEmissionByPeriodDetail = null;
  }

  requests = {
    ...requests,
    getEmissionByPeriodDetail: cancelTokenSource,
  };

  return request
    .get('dashboard/detail-period', config)
    .catch((errors) => {
      console.warn(errors);
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionByPeriodDetail: null,
      };
    });
};

export const getEmissionsByCategory = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionsByCategory) {
    requests.getEmissionsByCategory.cancel('Cancel getEmissionsByCategory');
    requests.getEmissionsByCategory = null;
  }

  requests = {
    ...requests,
    getEmissionsByCategory: cancelTokenSource,
  };

  return request
    .get('dashboard/ghg-emissions-category', config)
    .catch((errors) => {
      console.warn(errors);
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionsByCategory: null,
      };
    });
};

export const getEmissionByCategoryDetail = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionByCategoryDetail) {
    requests.getEmissionByCategoryDetail.cancel('Cancel getEmissionByCategoryDetail');
    requests.getEmissionByCategoryDetail = null;
  }

  requests = {
    ...requests,
    getEmissionByCategoryDetail: cancelTokenSource,
  };

  return request
    .get('dashboard/detail-ghg-emissions-category', config)
    .catch((errors) => {
      // console.warn(errors);
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionByCategoryDetail: null,
      };
    });
};

export const getDetailScopeBranch = (query = {}) => {
  const config = {
    params: query,
  };

  return request.get('dashboard/detail-scope-branch', config).catch((errors) => {
    console.warn(errors);
    throw errors;
  });
};

export const getEmissionByCategoryDetailTop5 = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionByCategoryDetailTop5) {
    requests.getEmissionByCategoryDetailTop5.cancel('Cancel getEmissionByCategoryDetail');
    requests.getEmissionByCategoryDetailTop5 = null;
  }

  requests = {
    ...requests,
    getEmissionByCategoryDetailTop5: cancelTokenSource,
  };

  return request
    .get('dashboard/list-item-most-emission', config)
    .catch((errors) => {
      console.warn(errors);
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionByCategoryDetailTop5: null,
      };
    });
};
