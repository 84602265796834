export const TEMPLATE_TYPES = [
    {
      name: ' 個人テンプレート',
      value: 1,
    },
    {
      name: '共有テンプレート',
      value: 2,
    }
];
