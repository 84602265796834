<template>
  <div class="main">
    <div class="wrap reset_wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
        </div>
      </div>
      <div class="right_wrap" v-if="!status">
        <div class="rs-80"></div>

        <v-form class="right_block reset_frm" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text mb-48 reset_inst">
            <span class="title-text-style">パスワードの再設定</span>
          </h1>
          <div class="instruction mb-48 reset_inst">
            <span>パスワードを再設定します。<br />新しいパスワードを入力してください。</span>
          </div>
          <div class="input-block">
            <span class="input-title">パスワード</span>
            <div class="passBox">
              <input id="password" v-model="password" class="input-elic" :type="!showPass ? 'password' : 'text'" />
              <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
              <span class="err-mess" style="display: none" id="password_error"></span>
              <img
                id="password_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>

          <div class="input-block mt-40">
            <span class="input-title">パスワード（確認）</span>
            <div class="passBox">
              <input
                id="confirmPassword"
                v-model="confirmPassword"
                class="input-elic"
                :type="!showPassConfirm ? 'password' : 'text'"
              />
              <eye-icon id="confirmPassword_eye" :changeIcon="() => (this.showPassConfirm = !this.showPassConfirm)" />
              <span class="err-mess" style="display: none" id="confirmPassword_error"></span>
              <img
                id="confirmPassword_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>
          <div class="instruction reset_inst mt-44">
            <ul>
              パスワードは以下の条件を満たす必要があります。
              <li>8文字以上20文字以内</li>
              <li>＜半角英字／大文字＞＜半角英字／小文字＞＜半角数字＞＜半角記号＞から2つ以上を組み合わせたもの</li>
            </ul>
          </div>
          <input class="btn-submit mt-48" type="submit" value="パスワードを再設定する" />
        </v-form>
      </div>
      <div class="reset_boxRight" v-if="status">
        <div class="reset_right">
          <h1 class="title-text reset_inst">
            <span class="title-text-style">パスワード再設定が完了しました。</span>
          </h1>
          <div class="instruction mt-48 reset_inst">
            <span>新しいパスワードでログインしてください。</span>
          </div>
          <input
            @click="$router.push({ name: 'Login' })"
            class="btn-submit mt-47"
            type="submit"
            value="ログイン画面に戻る"
          />
        </div>
      </div>

      <error-popup :dialog="showError" :message="message" @submit="handleClose" />
      <question-popup
        :dialog="showConfirm"
        :message="confirmMessage"
        @submit="handleResetPass"
        @close="showConfirm = false"
      />
    </div>
  </div>
</template>

<script>
import validate from '@/utils/validate';
import { resetPasswordApi, checkResetPasswordTokenApi } from '@/api/auth';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { getErrorMessge } from '@/utils/messageHandle';
import QuestionPopup from '@/components/dialogs/question-popup.vue';

export default {
  name: 'ChangePassword',
  components: {
    EyeIcon,
    ErrorPopup,
    QuestionPopup,
  },
  data() {
    return {
      title: '',
      desc: '',
      password: '',
      confirmPassword: '',
      passRules: [
        (v) => !!v,
        (value) => value && value.length >= 8 && value.length <= 20,
        (v) => validate.regexPassword.test(v),
      ],
      confirmPasswordRules: [(value) => !!value, (value) => value && value === this.password],
      showConfirm: false,
      message: '',
      messageCheckToken: '',
      showPass: false,
      showPassConfirm: false,
      status: false,
      match: false,
      getVars: {},
      showError: false,
      confirmMessage: 'パスワードを再設定します。\nよろしいですか？',
    };
  },
  methods: {
    submit() {
      if (!validateRequired(['password', 'confirmPassword'])) {
        return;
      }
      if (
        this.password != this.confirmPassword ||
        this.password.length > 20 ||
        !validate.regexPassword.test(this.password)
      ) {
        this.message = 'パスワード入力内容が正しくありません。\n内容を確認して再度入力をお願いします。'
        this.showError = true;
      } else {
        this.showConfirm = true;
      }
    },
    handleClose() {
      this.showError = false;
    },
    handleTogglePassword() {
      this.showPass = !this.showPass;
    },
    handleTogglePasswordConfirm() {
      this.showPassConfirm = !this.showPassConfirm;
    },
    handleResetPass() {
      resetPasswordApi({
        email: this.getVars.email,
        token: this.getVars.token,
        password: this.password.trim(),
        password_confirmation: this.confirmPassword.trim(),
      })
        .then(() => {
          this.showConfirm = false;
          this.status = true;
          this.title = 'パスワードの再設定が完了しました。';
          this.desc = '新しいパスワードでログインしてください。';
        })
        .catch((err) => {
          this.showConfirm = false;
          this.title = err.message;
          this.message = getErrorMessge(err);
          this.showError = true;
        });
    },
  },
  mounted() {
    const email = this.$route.query.email;
    const token = this.$route.query.token;
    this.getVars = {
      email,
      token,
    };

    checkResetPasswordTokenApi({
      token: this.getVars.token,
    }).catch((err) => {
      this.$store.dispatch('userData/updateMessage', getErrorMessge(err));
      this.$router.push({ name: 'Login' });
    });
  },
};
</script>

<style scoped lang="scss">
@import './LoginStyle/index.scss';

.main {
  // min-height: 550px;
  min-height: calc(100vh - 88px);
  height: unset;
}
.none {
  display: none;
}

.block {
  display: block;
}

.passBox {
  position: relative;
  .input-icon {
    position: absolute;
    display: flex;
    top: 14px;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 0;
    height: 40px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
  }
  .input-icon-eye {
    width: 36px;
    height: 31px;
    padding: 5px;
  }
}
.right_wrap {
  height: 727px !important;
}
</style>
