<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      @keydown="handleKeydownClick"
      ref="dialog"
      max-width="464px"
    >
    <v-card>
      <v-card-title v-if="isSaveTemplateButton">
        <span v-if="isCloneTemplate" class="">新規テンプレートとして保存</span>
        <span v-else>حفظ النموذج</span>
      </v-card-title>
      <v-card-title v-else>
        <img class="" src="@/assets/images/dialog/question.svg" />
        <span class="" tabindex="0">يتأكد</span>
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <common-button v-if="isSaveTemplateButton" class="left-button-popup" @action="close" :label="`يغلق`"/>
        <common-button v-else class="left-button-popup" @action="close" :label="closeText"/>
        <common-button v-if="logout" class="right-button-popup btn-red-mid" @action="doLogout" :label="`ログアウト`"/>
        <common-button v-if="confirm" class="right-button-popup" :class="!isSaveTemplateButton && 'btn-red-mid'" @action="submit" :label="confirmText"/>
        <common-button v-if="!logout && !confirm" class="right-button-popup" @action="submit" :label="confirmText"/>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {KEYS_CODE} from '@/constants/keyboard'
import CommonButton from '@/components/commonApp/Button.vue';

export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isSaveTemplateButton: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'OK',
    },
    message: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
    logout: {
      type: Boolean,
      required: false,
    },
    confirm: {
      type: Boolean,
      required: false,
    },
    isCloneTemplate: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: 'キャンセル',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    doLogout() {
      this.$emit('logout');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    handleKeydownClick(event) {
      if(event.keyCode === KEYS_CODE.ESCAPE) {
        this.close()
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
