import request from './base/request';

export const getExportReport = (payload, isCsv = false) => {
  if (isCsv) {
    return request
    .get(`/export-report/csv${payload}`, { responseType: 'blob' })
    .then(res => {
      return res;
    }).catch((error) => {
      throw error;
    });
  } else {
    return request
    .get(`/export-report${payload}`, { responseType: 'blob' })
    .then(res => {
      return res;
    }).catch((error) => {
      throw error;
    });
  }
}

export const getExportTemplateCsv = (patternNum, contractor) => {
  return request
  .get(`/download-template/?row_num_pattern=${patternNum}&contractor_id=${contractor}`, { responseType: 'blob' })
  .then(res => {
    return res;
  }).catch((error) => {
    throw error;
  });
}